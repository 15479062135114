import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Box, CircularProgress, Typography } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { OutlinedTextField } from "../Components/CustomTextField";
import { SetPassChangedSuccess } from "../Actions";
import { useDispatch } from "react-redux";

import { sendGetRequestforProfilePassReset } from "../Api/Api";
import "./Login.css";

import { ThemeProperties } from "../Theme/ThemeProperties";
import CoulombLogo from "../Assets/Icons/CoulombLogo.svg";

export default function ResetPassword(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [emailID, setEmailID] = useState("");
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passError, setPassError] = useState();
  const [credError, setCredError] = useState();

  const [loading, setLoading] = useState(false);

  const handleChangePassword = () => {
    if (!oldPassword || !emailID) {
      setCredError("Credentials must not be empty");
      setPassError("");
      return;
    }
    if (!password || !confirmPassword) {
      setPassError("Password must not be empty");
      setCredError("");
      return;
    }
    if (password !== confirmPassword) {
      setPassError("Passwords doesn't match");
      setCredError("");

      return;
    } else {
      if (!validatePassword(password)) {
        setPassError("Entered password isn't valid");
      } else {
        setPassError("");
        setCredError("");
        setLoading(true);
    
        sendGetRequestforProfilePassReset(emailID, oldPassword, password).then(
          (res) => {
            if (res.responseStatus.code === 200) {
              dispatch(SetPassChangedSuccess(true));
              navigate("/");
              setLoading(false);
            } else if (
              res.responseStatus.code === 1998 ||
              res.responseStatus.code === 404
            ) {
              setLoading(false);
              setCredError("Please enter correct credentials");
            } else if (res.responseStatus.code === 1996) {
              setLoading(false);
              setCredError(true);
              setPassError("Old password and new password can't be same");
            } else {
              setPassError("Something went wrong! Please try again later");
              setLoading(false);
            }
          }
        );
      }
    }
  };

  const handleResetErrorState = () => {
    setPassError("");
    setCredError("");
  };

  const validatePassword = (password) => {
    return String(password).match(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "table",
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          bgcolor: "#F2F2F4",
        }}
      >
        <Box
          sx={{
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >

<Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 2,
              ml: -0.8,
            }}
          >
            <img src={CoulombLogo} alt="not found" height={"40px"} />
          </Box>

          <Box
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "450px",
              border: "1px solid #DDD7FF",
              borderRadius: "1.2rem",
              backgroundColor: "#fff",
              padding: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar> */}
              {/* <img src={CoulombLogo} alt="not found" height={"40px"} /> */}

              <>
              <Typography
              variant="paperHeader"
                        sx={{
                          fontSize: "20px",

                        }}
                      >
                        Reset your password
                      </Typography>
                <br></br>

                <FormControl
                  className="formBox"
                  sx={{ m: 1, width: "25ch" }}
                  variant="outlined"
                  style={{ width: "100%" }}
                >
                  <OutlinedTextField
                    // margin="normal"
                    id="emailId-3"
                    type={"text"}
                    value={emailID}
                    onChange={(e) => {
                      setEmailID(e.target.value.trim());
                      handleResetErrorState();
                    }}
                    error={credError}
                    autoComplete="off"
                    label="Email ID"
                  />
                </FormControl>

                <FormControl
                  className="formBox"
                  sx={{ m: 1, width: "100%" }}
                  variant="outlined"
                >
                  <OutlinedTextField
                    className="input"
                    id="new-password-7"
                    type={showOldPassword ? "text" : "password"}
                    value={oldPassword}
                    error={credError}
                    onChange={(e) => {
                      setOldPassword(e.target.value.trim());
                      handleResetErrorState();
                    }}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowOldPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showOldPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label=" Old Password"
                  />
                </FormControl>
                <div className="forgot">
                  <Typography variant="textSubtitleRed">{credError}</Typography>
                </div>

                <FormControl
                  className="formBox"
                  sx={{ m: 1, width: "100%" }}
                  variant="outlined"
                >
                  <OutlinedTextField
                    className="input"
                    id="new-password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    error={passError}
                    onChange={(e) => {
                      setPassword(e.target.value.trim());
                      handleResetErrorState();
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label=" New Password"
                  />
                </FormControl>

                <FormControl
                  className="formBox"
                  sx={{ m: 1, width: "100%" }}
                  variant="outlined"
                >
                  <OutlinedTextField
                    className="input"
                    error={passError}
                    id="outlined-adornment-password"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value.trim());
                      handleResetErrorState();
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label=" Confirm Password"
                  />
                </FormControl>
                <div className="forgot">
                  <Typography variant="textSubtitleRed">{passError}</Typography>
                </div>

                <div className={`messageBox`}>
                  <InfoIcon
                    sx={{ fontSize: "20px", color: ThemeProperties.purple }}
                  />
                  <br></br>
                  <Typography variant="textPasswordReset" align="center">
                    Make sure your new password has atleast 8 characters which
                    includes an upper case letter, a lower case letter, a number
                    and a special character
                  </Typography>
                </div>

                {loading ? (
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 3, mb: 2, fontSize: 13, borderRadius: "1rem" }}
                    disabled
                  >
                    <CircularProgress />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 3, mb: 2, fontSize: 13, borderRadius: "1rem" }}
                    onClick={() => handleChangePassword()}
                  >
                    Reset Password
                  </Button>
                )}

<Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      mt: 0.5,
                      mb: 2,
                      fontSize: 13,
                      borderRadius: "1rem",
                    }}
                    onClick={() => navigate("/")}
                  >
                    Go back to log in
                  </Button>
              </>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
