import { SvgIcon } from "@mui/material"
import React from "react"

const SortSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 4H19"
          stroke={stroke}
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M6 8H16"
          stroke={stroke}
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M6 12H14"
          stroke={stroke}
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </SvgIcon>
  )
}

SortSvg.defaultProps = {
  width: "25",
  height: "16",
  viewBox: "0 0 25 16",
  fill: "#444B51",
  stroke: "#444B51"
}

export default SortSvg
