import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import PoweredBy from "../../Assets/Images/Sidebar/PoweredBy.svg";
import PoweredByCircle from "../../Assets/Images/Sidebar/PoweredByCircle.svg";
import MyNavLink from "./Links/MyNavLink";

import Cookies from "universal-cookie";
import removeAccessToken from "../../Helper/RemoveAccessToken/RemoveAccessToken";
import { Backdrop } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";

const drawerWidth = 199;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)})`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function MiniDrawer(props) {
  const theme = useTheme();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const handleDrawerClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const cookies = new Cookies();

  return (
    <>
      <Drawer
        variant="permanent"
        open={sidebarOpen}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            border: "0px, 1px, 0px, 0px",
            borderColor: "#E9EFF5",
          },
        }}
        ModalProps={{ onBackdropClick: () => setSidebarOpen(false) }}
        theme={theme}
      >
        <List style={{
          paddingTop: "49px",
          paddingLeft: sidebarOpen ? "11px" : null,
          paddingRight: sidebarOpen ? "11px" : null,
        }}>
          {props.appbarLinks.map((item, index) => (
            cookies.get("pages") !== undefined ? (
              <MyNavLink
                to={item.to}
                mykey={item.mykey}
                textPrimary={item.textPrimary}
                iconName={item.iconName}
                icon={
                  <img
                    src={item.icon}
                    alt="not found"
                    height={"15px"}
                    style={{
                      marginLeft: sidebarOpen ? 12 : -13,
                    }}
                  />
                }
                icon_white={
                  <img
                    src={item.icon_white}
                    alt="not found"
                    height={"15px"}
                    style={{
                      marginLeft: sidebarOpen ? 12 : -13,
                    }}
                  />
                }
                key={index}
                open={sidebarOpen}
              />
            ) : (
              removeAccessToken()
            )
          ))}
          <IconButton
            onClick={handleDrawerClick}
            sx={{ ml: sidebarOpen ? "80%" : "20%" }}
          >
            {sidebarOpen ? (
              <ArrowBackIcon
                fontSize="12px"
                sx={{
                  color: ThemeProperties.darkGreyBlue
                }}
              />
            ) : (
              <ArrowForwardIcon
                fontSize="12px"
                sx={{
                  color: ThemeProperties.darkGreyBlue
                }}
              />
            )}
          </IconButton>
        </List>
        {props.hideLogo || <DrawerHeader open={sidebarOpen}>
          {sidebarOpen ? (
            <img src={PoweredBy} alt="not found" />
          ) : (
            <img src={PoweredByCircle} alt="not found" />
          )}
        </DrawerHeader>}
      </Drawer>
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer - 1,
          color: "#fff",
        }}
        open={sidebarOpen}
        onClick={() => setSidebarOpen(false)}
      ></Backdrop>
    </>
  );
}

export default MiniDrawer;
