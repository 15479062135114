import { getCurrentMonthStartAndEndDate, getDatePickerDate, getPreviousMonthDate } from "../Helper/DatePicker/DateConverter";
import { decryptTheParams } from "../Helper/QueryParams/EncryptDecrypt";

const initialStartDate = {
  value: decryptTheParams()?.startDate || getPreviousMonthDate(getDatePickerDate(undefined, -1))
}
const initialEndDate = {
  value: decryptTheParams()?.endDate || getDatePickerDate(undefined, -1),
};

const AlertAnalyticsStartDate = (state = initialStartDate, action) => {
  switch (action.type) {
    case "AlertAnalyticsStartDate":
      return {
        value: action.start,
      };
    default:
      return state;
  }
};
const AlertAnalyticsEndDate = (state = initialEndDate, action) => {
  switch (action.type) {
    case "AlertAnalyticsEndDate":
      return {
        value: action.end,
      };
    default:
      return state;
  }
};

export {
  AlertAnalyticsStartDate,
  AlertAnalyticsEndDate
}