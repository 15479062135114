import { styled } from "@mui/material/styles";
import { Autocomplete } from "@mui/material";

const CustomAutocomplete = styled(Autocomplete)({
  "& .MuiOutlinedInput-root": {
    fontSize: 12,
    // borderRadius:"25px"
  },
  "& ..MuiAutocomplete-endAdornment": {
    top: 0,
  },
});

export { CustomAutocomplete };
