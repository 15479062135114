import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { SetSessionExpired } from "../../Actions";
import { getSelectAllBatteries } from "../../Api/CartBatteriesApi";
import NoData from "../../Helper/Loaders/NoData";
import CODE from "../../Static/Constants/StatusCodes";
import Events from "../../Analytics/Events";
import Loader from "../../Helper/Loaders/Loader";
import GenericTable from "../../Features/Table/GenericTable";

export default function CartTableSection(props) {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.DialogBoxTableColumns.value);
  const [list, setList] = useState({
    data: [],
    total: 0,
    responseStatus: { code: CODE.LOADING, msg: "" },
  });
  const pageNumber = useSelector((state) => state.DialogTablePageNumber.value);
  const rowsPerPage = useSelector((state) => state.DialogTableRowsPerPage.value);

  const rowSelection = {
    selectedRowKeys: props.selectedBatteries.map(data => {
      return `${data.batteryID}-${data.alertStatus}-${data.alertMetaID}`.toLowerCase().replace(/ /g, "");
    }),
    onSelectAll: function (selected, selectedRows, changeRows) {
      clickedSelectAllCheckbox(selected);
      Events("clicked global select Selected Batteries For Action");
    },
    onSelect: function (selected, selectedRows, changeRows) {
      const alertMetaID = props.alertTypeList?.find(item => item.alertDescription === selected.alertDescription)?.id;
      if (selectedRows) {
        props.handleSelectedBatteries([
          ...props.selectedBatteries,
          {
            batteryID: selected.batteryID,
            configBOPID: selected.configBOPID,
            alertMetaID: alertMetaID,
            alertStatus: selected.alertStatus,
            alertStateIds: selected.alertStateIds
          }
        ])
      } else {
        props.handleSelectedBatteries(
          props.selectedBatteries.filter(
            (item) =>
              `${item.batteryID}-${item.alertStatus}-${alertMetaID}`.toLowerCase().replace(/ /g, "") !==
              `${selected.batteryID}-${selected.alertStatus}-${alertMetaID}`.toLowerCase().replace(/ /g, "")
            )
        );
      }
    },
    getCheckboxProps: (record) => ({
      name: record.id,
    }),
  };

  function clickedSelectAllCheckbox(selectAll) {
    if (selectAll) {
      let itemIds = [];

      getSelectAllBatteries().then((res) => {
        if (res.responseStatus.code === 200) {
          itemIds = res.response.data;
          props.handleSelectedBatteries(itemIds);
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          props.handleSelectedBatteries([]);
        }
      });
    } else {
      props.handleSelectedBatteries([]);
    }
  }

  const generateRowID = (record) => {
    const alertMetaID = props.alertTypeList?.find(item => item.alertDescription === record.alertDescription)?.id
    return `${record.batteryID}-${record.alertStatus}-${alertMetaID}`.toLowerCase().replace(/ /g, "")
  }

  useEffect(() => {
    if (props.data && props.data.batteries?.length > 0) {
      let newData = props.data.batteries.map((row) => ({
        ...row,
        checked: false,
      }));

      setList({
        data: newData,
        total: props.data.total,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    } else {
      setList({
        data: [],
        total: 0,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [props.data]);

  return list.responseStatus.code === CODE.SUCCESS || props.addToCartInProgress ? (
    <GenericTable 
      type="dialogTable"
      key={`alertCartTable${list.batteries?.data?.length}${columns.length}`}
      emptyTextBox={<span><p style={{ minHeight: "300px", color: "white" }}></p></span>}
      selectedRowKeys={rowSelection.selectedRowKeys}
      tableData={list}
      generateRowId={generateRowID}
      columns={columns}
      totalRecords={list.total}
      isRowSelectable={true} 
      onClickSelectAll={rowSelection.onSelectAll}
      onRowSelect={rowSelection.onSelect}
      getCheckboxProps={rowSelection.getCheckboxProps}
      pageSizeOptions={[10, 20, 30, 100]}
      pageNumber={pageNumber}
      rowsPerPage={rowsPerPage}
      spinning={list.responseStatus.code === CODE.LOADING || props.addToCartInProgress}
    />
  ) : (
    <Table
      locale={{
        emptyText: (
          <div style={{ minHeight: "400px", height: "400px" }}>
            {props.data.responseStatus.code === 404 ||
            props.data.responseStatus.code === 1999 ? (
              <div style={{ height: "100%" }}>
                <NoData text={"No batteries found"} />
              </div>
            ) : (
              <p style={{ height: "100%", color: "white" }}></p>
            )}
          </div>
        ),
      }}
      columns={columns}
      rowSelection={false}
      rowKey={(record) => record.itemID}
      rowClassName="rowClass"
      dataSource={[]}
      pagination={false}
      loading={{
        spinning:
          props.data.responseStatus.code === CODE.LOADING
            ? true
            : props.data.responseStatus.code === CODE.NODATA
              ? false
              : false,
        indicator: (
          <>
            <div style={{ height: "130px" }}></div>
            <Loader />
          </>
        ),
      }}
      className="CartTable"
      style={{ height: "500px" }}
      scroll={{ y: 440 }}
      bordered
      size="middle"
    />
  )
}
