export const SetAllBatteriesBatteryPartnerSelect = (select) =>
{
    return {
        type: "AllBatteriesBatteryPartnerSelect",
        select: select
    }
}
export const SetAllBatteriesUserInputBatteries = (batteries) =>
{
    return {
        type: "AllBatteriesUserInputBatteries",
        batteries: batteries
    }
}
export const SetAllBatteriesFoundBatteries = (batteries) =>
{
    return {
        type: "AllBatteriesFoundBatteries",
        batteries: batteries
    }
}
export const SetAllBatteriesNotFoundBatteries = (batteries) =>
{
    return {
        type: "AllBatteriesNotFoundBatteries",
        batteries: batteries
    }
}
export const SetAllBatteriesMultiplePartners = (partners) =>
{
    return {
        type: "AllBatteriesMultiplePartners",
        partners: partners
    }
}
export const SetAllBatteriesMultiplePartnersDropdown = (partnersDropdown) =>
{
    return {
        type: "AllBatteriesMultiplePartnersDropdown",
        partnersDropdown: partnersDropdown
    }
}
export const SetAllBatteriesMultiplePartnersSelectAll = (partnersSelectAll) =>
{
    return {
        type: "AllBatteriesMultiplePartnersSelectAll",
        partnersSelectAll: partnersSelectAll
    }
}
export const SetAllBatteriesState = (state) =>
{
    return {
        type: "AllBatteriesState",
        state: state
    }
}
export const SetAllBatteriesVolt = (volt) =>
{
    return {
        type: "AllBatteriesVolt",
        volt: volt
    }
}
export const SetAllBatteriesSeverity = (severity) =>
{
    return {
        type: "AllBatteriesSeverity",
        severity: severity
    }
}
export const SetAllBatteriesCity = (city) =>
{
    return {
        type: "AllBatteriesCity",
        city: city
    }
}
export const SetAllBatteriesZone = (zone) =>
{
    return {
        type: "AllBatteriesZone",
        zone: zone
    }
}
export const SetAllBatteriesStatus = (status) =>
{
    return {
        type: "AllBatteriesStatus",
        status: status
    }
}
export const SetAllBatteriesBusiness = (business) =>
{
    return {
        type: "AllBatteriesBusiness",
        business: business
    }
}
export const SetAllBatteriesIOT = (iot) =>
{
    return {
        type: "AllBatteriesIOT",
        iot: iot
    }
}
export const SetAllBatteriesPack = (pack) =>
{
    return {
        type: "AllBatteriesPack",
        pack: pack
    }
}
export const SetAllBatteriesPageNumber = (page) =>
{
    return {
        type: "AllBatteriesPageNumber",
        page: page
    }
}

export const SetAllBatteriesStack = (batteries) =>
{
    return{
        type: "AllBatteriesStack",
        batteries:batteries
    }
}

export const SetKPIColumnSort = (sort) =>
{
    return{
        type: "KPIColumnSort",
        sort:sort
    }
}
export const SetAllBatteriesColumns = (columns) =>
{
    return {
        type: "AllBatteriesColumns",
        value: columns
    }
}
export const SetAllBatteriesHiddenColumns = (hidden) =>
{
    return {
        type: "AllBatteriesHiddenColumns",
        value: hidden
    }
}
export const SetAllBatteriesRowsPerPage = (rows) =>
{
    return {
        type: "AllBatteriesRowsPerPage",
        rows: rows
    }
}