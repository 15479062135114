export const SetDigitalTwinsColumns = (columns) => {
  return {
    type: "DigitalTwinsColumns",
    value: columns,
  };
};
export const SetDigitalTwinsHiddenColumns = (hidden) => {
  return {
    type: "DigitalTwinsHiddenColumns",
    value: hidden,
  };
};
export const SetDigitalTwinsBatteryID = (batteryID) => {
  return {
    type: "DigitalTwinsBatteryID",
    batteryID: batteryID,
  };
};

export const SetDigitalTwinsIOT = (Iot) => {
  return {
    type: "DigitalTwinsIOT",
    Iot: Iot,
  };
};

export const SetDigitalTwinsOrg = (org) => {
  return {
    type: "DigitalTwinsOrg",
    org: org,
  };
};

export const SetDigitalTwinsApp = (app) => {
  return {
    type: "DigitalTwinsApp",
    app: app,
  };
};
export const SetDigitalTwinsBMS = (bms) => {
  return {
    type: "DigitalTwinsBMS",
    bms: bms,
  };
};

export const SetDigitalTwinsPack = (pack) => {
  return {
    type: "DigitalTwinsPack",
    pack: pack,
  };
};

export const SetDigitalTwinsTabValue = (tab) => {
  return {
    type: "DigitalTwinsTabValue",
    tab: tab,
  };
};

export const SetDigitalTwinsTotal = (total) => {
  return {
    type: "DigitalTwinsTotal",
    total: total,
  };
};

export const SetDigitalTwinsStartDate = (start) => {
  return {
    type: "DigitalTwinsStartDate",
    start: start,
  };
};

export const SetDigitalTwinsEndDate = (end) => {
  return {
    type: "DigitalTwinsEndDate",
    end: end,
  };
};

export const SetDigitalTwinsListOfBatteries = (list) => {
  return {
    type: "DigitalTwinsListOfBatteries",
    list: list,
  };
};

export const SetDigitalTwinsStack = (batteries) => {
  return {
    type: "DigitalTwinsStack",
    batteries: batteries,
  };
};

export const SetDigitalTwinsColumnSort = (sort) => {
  return {
    type: "DigitalTwinsColumnSort",
    sort: sort,
  };
};

export const SetDigitalTwinsPageNumber = (page) => {
  return {
    type: "DigitalTwinsPageNumber",
    page: page,
  };
};

export const SetDigitalTwinsRowsPerPage = (rows) => {
  return {
    type: "DigitalTwinsRowsPerPage",
    rows: rows,
  };
};

export const SetDigitalTwinsColumnsSelect = (columns) => {
  return {
    type: "DigitalTwinsColumnsSelect",
    value: columns,
  };
};

export const SetDigitalTwinsConfigurationType = (config) => {
  return {
    type: "DigitalTwinsConfigurationType",
    value: config,
  };
};

export const SetDigitalTwinsConfigFilters = (filters) => {
  return {
    type: "DigitalTwinsConfigFilters",
    filters: filters,
  };
};
