export const pages_info = {
  response: {
    response: {
      textInfo: {
        pageHeader: "Alerts",
      },
      filter: {
        listFilter: {
          items: [
            {
              id: "Safety Risk",
              value: "Safety Risk",
            },
            {
              id: "Operational Issue",
              value: "Operational Issue",
            },
            {
              id: "Violations",
              value: "Violation",
            },
          ],
          itemName: "Alert Type",
        },
        filtersWithData: [
          {
            items: [
              {
                id: "Super Critical",
                value: "4",
              },
              {
                id: "Critical",
                value: "3",
              },
              {
                id: "High",
                value: "2",
              },
              {
                id: "Low",
                value: "1",
              },
            ],
            itemName: "Severity",
          },
          {
            items: [
              {
                id: "Idle",
                value: "0",
              },
              {
                id: "Charging",
                value: "4",
              },
              {
                id: "Discharging",
                value: "2",
              },
            ],
            itemName: "Status",
          },
          {
            items: [
              {
                id: "Texas",
                value: "Texas",
              },
              {
                id: "Quebec",
                value: "Quebec",
              },
              {
                id: "Utah",
                value: "Utah",
              },
              {
                id: "California",
                value: "California",
              },
            ],
            itemName: "City",
          },
          {
            items: [
              {
                id: "Mayur Vihar",
                value: "Mayur Vihar",
              },
              {
                id: "Noida",
                value: "Noida",
              },
              {
                id: "South Delhi",
                value: "South Delhi",
              },
              {
                id: "Jankapuri Kirtinagar",
                value: "Jankapuri Kirtinagar",
              },
              {
                id: "Rohini",
                value: "Rohini",
              },
              {
                id: "Jahagirpuri",
                value: "Jahagirpuri",
              },
              {
                id: "Bawana",
                value: "Bawana",
              },
              {
                id: "Central Delhi",
                value: "Central Delhi",
              },
              {
                id: "Faridabad",
                value: "Faridabad",
              },
              {
                id: "Shahadra",
                value: "Shahadra",
              },
              {
                id: "Anand Vihar",
                value: "Anand Vihar",
              },
              {
                id: "Gurgaon",
                value: "Gurgaon",
              },
            ],
            itemName: "Zone",
          },
          // {
          //   items: [
          //     {
          //       id: "Wynncom",
          //       value: "Wynncom",
          //     },
          //     {
          //       id: "Intellicar",
          //       value: "INTELLICAR",
          //     },
          //   ],
          //   itemName: "IOT",
          // },
          {
            items: [
              {
                id: "Livguard",
                value: "livguard",
              },
            ],
            itemName: "Product",
          },
        ],
        searchTypeFilter: {
          items: ["Device ID", "Occupant"],
          itemName: "Select",
        },
        filtersWithoutData: [
          {
            itemName: "Search battery or partner",
          },
        ],
      },
      metaInfo: {
        allAlerts: [
          {
            items: [
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_temp_chrg",
                textColor: "#fff",
                alertDescription: "High temperature during charging",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_temp_drv",
                textColor: "#fff",
                alertDescription: "High temperature inside vehicle",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_temp_idl",
                textColor: "#fff",
                alertDescription: "High temperature in warehouse",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_volt",
                textColor: "#fff",
                alertDescription: "Over voltage protection breach",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_cc",
                textColor: "#fff",
                alertDescription: "Cold charging",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_cur_chrg",
                textColor: "#fff",
                alertDescription: "Abnormally high current during charging",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_cur_drv",
                textColor: "#fff",
                alertDescription: "Abnormally high current during driving",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_cur_fluc_chrg",
                textColor: "#fff",
                alertDescription: "High current fluctuation during charging",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_cur_high_soc",
                textColor: "#fff",
                alertDescription:
                  "High current at full voltage during charging",
              },
              {
                type: "Operational Issue",
                color: "#FFD168",
                alertCode: "ops_low_volt_vehicle",
                textColor: "#000",
                alertDescription: "Low voltage in vehicle",
              },
              {
                type: "Operational Issue",
                color: "#FFD168",
                alertCode: "ops_low_volt_warehouse",
                textColor: "#000",
                alertDescription: "Low voltage in warehouse",
              },
              {
                type: "Operational Issue",
                color: "#FFD168",
                alertCode: "ops_ml_dd",
                textColor: "#000",
                alertDescription: "Potential deep discharge",
              },
              {
                type: "Operational Issue",
                color: "#FFD168",
                alertCode: "ops_not_ping",
                textColor: "#000",
                alertDescription: "Battery not pinging",
              },
              {
                type: "Violations",
                color: "#A1E9FF",
                alertCode: "vln_fc",
                textColor: "#000",
                alertDescription: "Field charging",
              },
              {
                type: "Violations",
                color: "#A1E9FF",
                alertCode: "vln_nc",
                textColor: "#000",
                alertDescription: "Night charging",
              },
              {
                type: "Violations",
                color: "#A1E9FF",
                alertCode: "vln_uc",
                textColor: "#000",
                alertDescription: "Unauthorised charging",
              },
              {
                type: "Violations",
                color: "#A1E9FF",
                alertCode: "vln_ufc",
                textColor: "#000",
                alertDescription: "Unauthorised field charging",
              },
            ],
            itemName: "All",
          },
          {
            items: [
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_temp_chrg",
                textColor: "#fff",
                alertDescription: "High temperature during charging",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_temp_drv",
                textColor: "#fff",
                alertDescription: "High temperature inside vehicle",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_temp_idl",
                textColor: "#fff",
                alertDescription: "High temperature in warehouse",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_volt",
                textColor: "#fff",
                alertDescription: "Over voltage protection breach",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_cc",
                textColor: "#fff",
                alertDescription: "Cold charging",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_cur_chrg",
                textColor: "#fff",
                alertDescription: "Abnormally high current during charging",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_cur_drv",
                textColor: "#fff",
                alertDescription: "Abnormally high current during driving",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_cur_fluc_chrg",
                textColor: "#fff",
                alertDescription: "High current fluctuation during charging",
              },
              {
                type: "Safety Risk",
                color: "#CF0000",
                alertCode: "sft_cur_high_soc",
                textColor: "#fff",
                alertDescription:
                  "High current at full voltage during charging",
              },
            ],
            itemName: "Safety Risk",
          },
          {
            items: [
              {
                type: "Operational Issue",
                color: "#FFD168",
                alertCode: "ops_low_volt_vehicle",
                textColor: "#000",
                alertDescription: "Low voltage in vehicle",
              },
              {
                type: "Operational Issue",
                color: "#FFD168",
                alertCode: "ops_low_volt_warehouse",
                textColor: "#000",
                alertDescription: "Low voltage in warehouse",
              },
              {
                type: "Operational Issue",
                color: "#FFD168",
                alertCode: "ops_ml_dd",
                textColor: "#000",
                alertDescription: "Potential deep discharge",
              },
              {
                type: "Operational Issue",
                color: "#FFD168",
                alertCode: "ops_not_ping",
                textColor: "#000",
                alertDescription: "Battery not pinging",
              },
            ],
            itemName: "Operational Issue",
          },
          {
            items: [
              {
                type: "Violations",
                color: "#A1E9FF",
                alertCode: "vln_fc",
                textColor: "#000",
                alertDescription: "Field charging",
              },
              {
                type: "Violations",
                color: "#A1E9FF",
                alertCode: "vln_nc",
                textColor: "#000",
                alertDescription: "Night charging",
              },
              {
                type: "Violations",
                color: "#A1E9FF",
                alertCode: "vln_uc",
                textColor: "#000",
                alertDescription: "Unauthorised charging",
              },
              {
                type: "Violations",
                color: "#A1E9FF",
                alertCode: "vln_ufc",
                textColor: "#000",
                alertDescription: "Unauthorised field charging",
              },
            ],
            itemName: "Violation",
          },
        ],
      },
      orgmetaInfo: null,
    },
  },
  responseStatus: {
    code: 200,
    msg: "Data successfully fetched",
  },
};

export const filters = [
  {
    itemName: "Severity",
    items: [
      {
        value: "Super Critical",
        id: "4",
      },
      {
        value: "Critical",
        id: "3",
      },
      {
        value: "High",
        id: "2",
      },
      {
        value: "Low",
        id: "1",
      },
    ],
  },
  {
    itemName: "Status",
    items: [
      {
        value: "Idle",
        id: "0",
      },
      {
        value: "Charging",
        id: "4",
      },
      {
        value: "Discharging",
        id: "2",
      },
    ],
  },
  {
    itemName: "City",
    items: [
      {
        id: "Texas",
        value: "Texas",
      },
      {
        id: "Quebec",
        value: "Quebec",
      },
      {
        id: "Utah",
        value: "Utah",
      },
      {
        id: "California",
        value: "California",
      },
    ],
  },
  {
    itemName: "Zone",
    items: [
      {
        id: "Mayur Vihar",
        value: "Mayur Vihar",
      },
      {
        id: "Noida",
        value: "Noida",
      },
      {
        id: "South Delhi",
        value: "South Delhi",
      },
      {
        id: "Jankapuri Kirtinagar",
        value: "Jankapuri Kirtinagar",
      },
      {
        id: "Rohini",
        value: "Rohini",
      },
      {
        id: "Jahagirpuri",
        value: "Jahagirpuri",
      },
      {
        id: "Bawana",
        value: "Bawana",
      },
      {
        id: "Central Delhi",
        value: "Central Delhi",
      },
      {
        id: "Faridabad",
        value: "Faridabad",
      },
      {
        id: "Shahadra",
        value: "Shahadra",
      },
      {
        id: "Anand Vihar",
        value: "Anand Vihar",
      },
      {
        id: "Gurgaon",
        value: "Gurgaon",
      },
    ],
  },

  // {
  //   itemName: "IOT",
  //   items: [
  //     {
  //       id: "TrakMate",
  //       value: "TrakMate",
  //     },
  //   ],
  // },
  {
    itemName: "Product",
    items: [
      {
        id: "AAUAAD-29-100080-001",
        value: "AAUAAD-29-100080-001",
      },
    ],
  },
];

export const filterResponse = {
  response: {
    filterData: [
      {
        itemName: "severityName",
        items: [
          {
            key: "Super Critical",
            value: "4",
          },
          {
            key: "Critical",
            value: "3",
          },
          {
            key: "High",
            value: "2",
          },
          {
            key: "Low",
            value: "1",
          },
        ],
      },
      {
        itemName: "batteryStatus",
        items: [
          {
            key: "Idle",
            value: "0",
          },
          {
            key: "Charging",
            value: "4",
          },
          {
            key: "Discharging",
            value: "2",
          },
        ],
      },
      {
        itemName: "location",
        items: [
          {
            key: "Delhi",
            value: "delhi",
          },
          {
            key: "Faridabad",
            value: "faridabad",
          },
          {
            key: "Ghaziabad",
            value: "ghaziabad",
          },
          {
            key: "Gurgaon",
            value: "gurgaon",
          },
          {
            key: "Jaipur",
            value: "jaipur",
          },
          {
            key: "Kanpur",
            value: "Kanpur",
          },
          {
            key: "Karnal",
            value: "Karnal",
          },
          {
            key: "Lucknow",
            value: "Lucknow",
          },
          {
            key: "Meerut",
            value: "Meerut",
          },
          {
            key: "Noida",
            value: "Noida",
          },
          {
            key: "Panipat",
            value: "Panipat",
          },
          {
            key: "Sonipat",
            value: "Sonipat",
          },
        ],
      },
      {
        itemName: "iotOEM",
        items: [
          {
            key: "Trakmate",
            value: "Trakmate",
          },
        ],
      },
      {
        itemName: "bmsOEM",
        items: [
          {
            key: "dally",
            value: "Dally",
          },
        ],
      },
      {
        itemName: "applicationOEM",
        items: [
          {
            key: "samsar",
            value: "Samsar",
          },
        ],
      },
      {
        itemName: "packOEM",
        items: [
          {
            key: "samsar",
            value: "Samsar",
          },
        ],
      },
      {
        itemName: "cellOEM",
        items: [
          {
            key: "nissan",
            value: "Nissan",
          },
        ],
      },
      {
        itemName: "pingStatus",
        items: [
          {
            key: "active",
            value: "Active",
          },
          {
            key: "inactive",
            value: "In-Active",
          },
        ],
      },
      {
        itemName: "fleetName",
        items: [
          {
            value: "0",
            key: "Samsar",
          },
          {
            value: "1",
            key: "Ver-Mac",
          },
          {
            value: "2",
            key: "Liveview",
          },
          {
            value: "3",
            key: "NSI",
          },
        ],
      },
      {
        itemName: "cellModelNumber",
        items: [
          {
            key: "AAUAAD-29–100080-001",
            value: "AAUAAD-29–100080-001",
          },
        ],
      },
      {
        itemName: "alertType",
        items: [
          {
            key: "Safety Risk",
            value: "Safety Risk",
          },
          {
            key: "Operational Issue",
            value: "Operational Issue",
          },
          {
            key: "Violations",
            value: "Violations",
          },
        ],
      },
    ],
  },
  responseStatus: {
    code: 200,
    msg: "",
  },
};

export const stack = [
  {
    alertCode: "sft_temp_chrg",
    alertDescription: "High temperature during charging",
    itemIDs: [],
  },
  {
    alertCode: "sft_temp_drv",
    alertDescription: "High temperature inside vehicle",
    itemIDs: [],
  },
  {
    alertCode: "sft_temp_idl",
    alertDescription: "High temperature in warehouse",
    itemIDs: [],
  },
  {
    alertCode: "sft_volt",
    alertDescription: "Over voltage protection breach",
    itemIDs: [],
  },
  {
    alertCode: "sft_cc",
    alertDescription: "Cold charging",
    itemIDs: [],
  },
  {
    alertCode: "sft_cur_chrg",
    alertDescription: "Abnormally high current during charging",
    itemIDs: [],
  },
  {
    alertCode: "sft_cur_drv",
    alertDescription: "Abnormally high current during driving",
    itemIDs: [],
  },
  {
    alertCode: "sft_cur_fluc_chrg",
    alertDescription: "High current fluctuation during charging",
    itemIDs: [],
  },
  {
    alertCode: "sft_cur_high_soc",
    alertDescription: "High current at full voltage during charging",
    itemIDs: [],
  },

  {
    alertCode: "ops_low_volt_vehicle",
    alertDescription: "Low voltage in vehicle",
    itemIDs: [],
  },
  {
    alertCode: "ops_low_volt_warehouse",
    alertDescription: "Low voltage in warehouse",
    itemIDs: [],
  },
  {
    alertCode: "ops_ml_dd",
    alertDescription: "Potential deep discharge",
    itemIDs: [],
  },
  {
    alertCode: "ops_not_ping",
    alertDescription: "Battery not pinging",
    itemIDs: [],
  },

  {
    alertCode: "vln_fc",
    alertDescription: "Field charging",
    itemIDs: [],
  },
  {
    alertCode: "vln_nc",
    alertDescription: "Night charging",
    itemIDs: [],
  },
  {
    alertCode: "vln_uc",
    alertDescription: "Unauthorised charging",
    itemIDs: [],
  },
  {
    alertCode: "vln_ufc",
    alertDescription: "Unauthorised field charging",
    itemIDs: [],
  },
  // {
  //   alertCode: "vln_ic",
  //   alertDescription: "Incomplete Charging",
  //   itemIDs: [],
  // },
];

const alertTypes = [
  {
    itemName: "All",
    items: [
      {
        alertCode: "sft_temp_chrg",
        alertDescription: "High temperature during charging",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_temp_drv",
        alertDescription: "High temperature inside vehicle",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_temp_idl",
        alertDescription: "High temperature in warehouse",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_volt",
        alertDescription: "Over voltage protection breach",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_cc",
        alertDescription: "Cold charging",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_cur_chrg",
        alertDescription: "Abnormally high current during charging",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_cur_drv",
        alertDescription: "Abnormally high current during driving",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_cur_fluc_chrg",
        alertDescription: "High current fluctuation during charging",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_cur_high_soc",
        alertDescription: "High current at full voltage during charging",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },

      {
        alertCode: "ops_low_volt_vehicle",
        alertDescription: "Low voltage in vehicle",
        type: "Operational Issue",
        color: "#FFD168",
        textColor: "#000",
      },
      {
        alertCode: "ops_low_volt_warehouse",
        alertDescription: "Low voltage in warehouse",
        type: "Operational Issue",
        color: "#FFD168",
        textColor: "#000",
      },
      {
        alertCode: "ops_ml_dd",
        alertDescription: "Potential deep discharge",
        type: "Operational Issue",
        color: "#FFD168",
        textColor: "#000",
      },
      {
        alertCode: "ops_not_ping",
        alertDescription: "Battery not pinging",
        type: "Operational Issue",
        color: "#FFD168",
        textColor: "#000",
      },
      {
        alertCode: "vln_fc",
        alertDescription: "Field charging",
        type: "Violationn",
        color: "#A1E9FF",
        textColor: "#000",
      },
      {
        alertCode: "vln_nc",
        alertDescription: "Night charging",
        type: "Violationn",
        color: "#A1E9FF",
        textColor: "#000",
      },
      {
        alertCode: "vln_uc",
        alertDescription: "Unauthorised charging",
        type: "Violationn",
        color: "#A1E9FF",
        textColor: "#000",
      },
      {
        alertCode: "vln_ufc",
        alertDescription: "Unauthorised field charging",
        type: "Violationn",
        color: "#A1E9FF",
        textColor: "#000",
      },
      // {
      //   alertCode: "vln_ic",
      //   alertDescription: "Incomplete Charging",
      //   type: "Violations",
      //   color: "#A1E9FF",
      //   textColor: "#000",
      // },
    ],
  },
  {
    itemName: "Safety Risk",
    items: [
      {
        alertCode: "sft_temp_chrg",
        alertDescription: "High temperature during charging",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_temp_drv",
        alertDescription: "High temperature inside vehicle",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_temp_idl",
        alertDescription: "High temperature in warehouse",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_volt",
        alertDescription: "Over voltage protection breach",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_cc",
        alertDescription: "Cold charging",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_cur_chrg",
        alertDescription: "Abnormally high current during charging",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_cur_drv",
        alertDescription: "Abnormally high current during driving",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_cur_fluc_chrg",
        alertDescription: "High current fluctuation during charging",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
      {
        alertCode: "sft_cur_high_soc",
        alertDescription: "High current at full voltage during charging",
        type: "Safety Risk",
        color: "#CF0000",
        textColor: "#fff",
      },
    ],
  },
  {
    itemName: "Operational Issue",
    items: [
      {
        alertCode: "ops_low_volt_vehicle",
        alertDescription: "Low voltage in vehicle",
        type: "Operational Issue",
        color: "#FFD168",
        textColor: "#000",
      },
      {
        alertCode: "ops_low_volt_warehouse",
        alertDescription: "Low voltage in warehouse",
        type: "Operational Issue",
        color: "#FFD168",
        textColor: "#000",
      },
      {
        alertCode: "ops_ml_dd",
        alertDescription: "Potential deep discharge",
        type: "Operational Issue",
        color: "#FFD168",
        textColor: "#000",
      },
      {
        alertCode: "ops_not_ping",
        alertDescription: "Battery not pinging",
        type: "Operational Issue",
        color: "#FFD168",
        textColor: "#000",
      },
    ],
  },
  {
    itemName: "Violations",
    items: [
      {
        alertCode: "vln_fc",
        alertDescription: "Field charging",
        type: "Violations",
        color: "#A1E9FF",
        textColor: "#000",
      },
      {
        alertCode: "vln_nc",
        alertDescription: "Night charging",
        type: "Violations",
        color: "#A1E9FF",
        textColor: "#000",
      },
      {
        alertCode: "vln_uc",
        alertDescription: "Unauthorised charging",
        type: "Violations",
        color: "#A1E9FF",
        textColor: "#000",
      },
      {
        alertCode: "vln_ufc",
        alertDescription: "Unauthorised field charging",
        type: "Violations",
        color: "#A1E9FF",
        textColor: "#000",
      },
      // {
      //   alertCode: "vln_ic",
      //   alertDescription: "Incomplete Charging",
      //   type: "Violations",
      //   color: "#A1E9FF",
      //   textColor: "#000",
      // },
    ],
  },
];

export const alertTypeResponse = {
  response: {
    alertList: [
      {
        alertCode: "sft_temp_chrg",
        alertDescription: "High temperature during charging",
        alertCount: 1,
        alertPriority: 1,
        severity: 4,
      },
      {
        alertCode: "sft_temp_drv",
        alertDescription: "High temperature inside vehicle",
        alertCount: 25,
        alertPriority: 2,
        severity: 4,
      },
      {
        alertCode: "sft_temp_idl",
        alertDescription: "High temperature in warehouse",
        alertCount: 3,
        alertPriority: 3,
        severity: 4,
      },
      {
        alertCode: "sft_volt",
        alertDescription: "Over voltage protection breach",
        alertCount: 21,
        alertPriority: 4,
        severity: 3,
      },
      {
        alertCode: "sft_cur_chrg",
        alertDescription: "Abnormally high current during charging",
        alertCount: 6,
        alertPriority: 6,
        severity: 4,
      },
      {
        alertCode: "ops_low_volt_vehicle",
        alertDescription: "Low voltage in vehicle",
        alertCount: 2,
        alertPriority: 11,
        severity: 3,
      },
      {
        alertCode: "ops_ml_dd",
        alertDescription: "Potential deep discharge",
        alertCount: 299,
        alertPriority: 13,
        severity: 2,
      },
      {
        alertCode: "ops_not_ping",
        alertDescription: "Battery not pinging",
        alertCount: 29183,
        alertPriority: 15,
        severity: 2,
      },
    ],
  },
  responseStatus: {
    code: 200,
    msg: "Data successfully fetched",
  },
};

export const alertListReponse = {
  response: {
    alertList: [
      {
        batteryID: "862430057593531",
        assetID: "B170611",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D30928",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Gurgaon",
        itemID: 910475,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 05:46:21",
        latitude: 28.447277,
        longitude: 77.01608,
        socBMS: 38,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:54:08",
        temperature: 78.0,
        currentPack: 0.0,
        voltagePack: 50600.0,
        location: "Gurgaon",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866897050570227",
        assetID: "B24169",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: null,
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Noida",
        itemID: 910474,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 05:44:51",
        latitude: 28.591898,
        longitude: 77.329956,
        socBMS: 33,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:56:30",
        temperature: 77.0,
        currentPack: 0.0,
        voltagePack: 50000.0,
        location: "Noida",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        lastPinged: false,
      },
      {
        batteryID: "866897051364844",
        assetID: "B111484",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D18744",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Jankapuri Kirtinagar",
        itemID: 910460,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 05:38:10",
        latitude: 28.645494,
        longitude: 77.08806,
        socBMS: 90,
        batteryStatus: "Charging",
        lastPingedTime: "2023-07-28 05:56:16",
        temperature: 81.0,
        currentPack: 6.1,
        voltagePack: 55800.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "862843049660756",
        assetID: "B10820",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D21685",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "No Matching Zone",
        itemID: 910459,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 05:37:37",
        latitude: 28.6814,
        longitude: 77.32892,
        socBMS: 54,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:33:01",
        temperature: 75.0,
        currentPack: 0.0,
        voltagePack: 52000.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        lastPinged: false,
      },
      {
        batteryID: "866897050514290",
        assetID: "B100871",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D33906",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Mayur Vihar",
        itemID: 910441,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 05:29:23",
        latitude: 28.614677,
        longitude: 77.32004,
        socBMS: 18,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:56:22",
        temperature: 75.0,
        currentPack: 0.0,
        voltagePack: 50200.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866567063596515",
        assetID: "B26622",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "P0071",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Central Delhi",
        itemID: 910438,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 05:26:36",
        latitude: 28.65332,
        longitude: 77.18618,
        socBMS: 17,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:54:51",
        temperature: 73.0,
        currentPack: 0.0,
        voltagePack: 49700.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "862843049663412",
        assetID: "B171389",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D32685",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Jankapuri Kirtinagar",
        itemID: 910430,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 05:20:26",
        latitude: 28.608171,
        longitude: 77.09397,
        socBMS: 42,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:21:18",
        temperature: 72.0,
        currentPack: 0.0,
        voltagePack: 51000.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866897050546078",
        assetID: "B10538",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D19044",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Shahadra",
        itemID: 910425,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 05:18:28",
        latitude: 28.672249,
        longitude: 77.25669,
        socBMS: 39,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:56:27",
        temperature: 74.0,
        currentPack: 0.0,
        voltagePack: 50800.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866897050441106",
        assetID: "B69108",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D9452",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Rohini",
        itemID: 910402,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 05:01:40",
        latitude: 28.686459,
        longitude: 77.04959,
        socBMS: 0,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 04:55:24",
        temperature: 75.0,
        currentPack: 0.0,
        voltagePack: 51300.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "862430057558369",
        assetID: "B64255",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "P0428",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Shahadra",
        itemID: 910351,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 04:34:47",
        latitude: 28.698261,
        longitude: 77.25556,
        socBMS: 64,
        batteryStatus: "Charging",
        lastPingedTime: "2023-07-28 05:56:35",
        temperature: 76.0,
        currentPack: 9.6,
        voltagePack: 52900.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866567063398847",
        assetID: "B64075",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D28764",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Jahagirpuri",
        itemID: 910341,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 04:28:13",
        latitude: 28.692482,
        longitude: 77.20538,
        socBMS: 50,
        batteryStatus: "Charging",
        lastPingedTime: "2023-07-28 05:56:17",
        temperature: 74.0,
        currentPack: 22.0,
        voltagePack: 50500.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "862843049611528",
        assetID: "B75275",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D21851",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "No Matching Zone",
        itemID: 910314,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 04:13:05",
        latitude: 28.764809,
        longitude: 77.26798,
        socBMS: 71,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-27 18:00:03",
        temperature: 78.0,
        currentPack: 0.0,
        voltagePack: 53400.0,
        location: "Ghaziabad",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866897050547779",
        assetID: "B111102",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D7767",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Jahagirpuri",
        itemID: 910131,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-28 01:50:24",
        latitude: 28.699085,
        longitude: 77.16571,
        socBMS: 83,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:43:58",
        temperature: 73.0,
        currentPack: 0.0,
        voltagePack: 55300.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866897050446089",
        assetID: "B172418",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D19002",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Shahadra",
        itemID: 909973,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-27 20:36:25",
        latitude: 28.679193,
        longitude: 77.276794,
        socBMS: 71,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:55:59",
        temperature: 74.0,
        currentPack: 0.0,
        voltagePack: 54800.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866567063418165",
        assetID: "B24964",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D9317",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Rohini",
        itemID: 909866,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-27 17:24:14",
        latitude: 28.686954,
        longitude: 77.14684,
        socBMS: 84,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:56:11",
        temperature: 72.0,
        currentPack: 0.0,
        voltagePack: 55200.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866897050442922",
        assetID: "B133992",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D14575",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Noida",
        itemID: 909854,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-27 17:13:33",
        latitude: 28.556293,
        longitude: 77.342354,
        socBMS: 50,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:56:29",
        temperature: 72.0,
        currentPack: 0.0,
        voltagePack: 51200.0,
        location: "Noida",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866567063783824",
        assetID: "B26658",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D26076",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "No Matching Zone",
        itemID: 909814,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-27 16:44:07",
        latitude: 28.631762,
        longitude: 77.0177,
        socBMS: 61,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:56:34",
        temperature: 72.0,
        currentPack: 0.0,
        voltagePack: 52300.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "862430056878362",
        assetID: "B133707",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D3803",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Rohini",
        itemID: 909764,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-27 16:04:49",
        latitude: 28.74412,
        longitude: 77.099464,
        socBMS: 49,
        batteryStatus: "Charging",
        lastPingedTime: "2023-07-28 05:56:22",
        temperature: 74.0,
        currentPack: 8.5,
        voltagePack: 50900.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "862843049736499",
        assetID: "B106439",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "D10351",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "No Matching Zone",
        itemID: 909534,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-27 13:13:27",
        latitude: 28.574451,
        longitude: 77.07889,
        socBMS: 35,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 02:03:31",
        temperature: 71.0,
        currentPack: 0.0,
        voltagePack: 50500.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
      {
        batteryID: "866897051372680",
        assetID: "B146733",
        alertStatus: "Active",
        alertType: "Safety Risk",
        severity: 4,
        partner: "P1046",
        name: null,
        statusChangedTime: null,
        actionTime: null,
        zone: "Shahadra",
        itemID: 909325,
        voltageConf: "48",
        deltaTime: 0,
        generatedAt: "2023-07-27 11:17:58",
        latitude: 28.698914,
        longitude: 77.31091,
        socBMS: 91,
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:36:52",
        temperature: 73.0,
        currentPack: 0.0,
        voltagePack: 56700.0,
        location: "Delhi",
        iotVendor: "intellicar",
        batteryVendor: "Livguard",
        pingStatus: true,
      },
    ],
    total: {
      start: 1,
      end: 20,
      total: 25,
    },
  },
  responseStatus: {
    code: 200,
    msg: "Data successfully fetched",
  },
};

export const stackResponse = {
  response: {
    alertList: [
      {
        batteryID: "862430057479962",
        assetID: "B74644",
        batteryFlag: 0,
        alertCode: "sft_temp_chrg",
        alertStatus: "Active",
        alertDescription: "High temperature during charging",
        action: null,
        alertType: "Safety Risk",
        alertPriority: 0,
        partner: "D35378",
        zone: "Central Delhi",
        itemID: 910476,
        voltageConf: "48",
        insertionTime: "2023-07-31 14:53:02",
        latitude: 28.650114,
        longitude: 77.185455,
        socBMS: 27,
        severity: 4,
        location: "Delhi",
        batteryStatus: "Idle",
        lastPingedTime: "2023-07-28 05:54:28",
        alertTime: "2023-07-28 05:46:57",
      },
    ],
    total: {
      start: 1,
      end: 1,
      total: 1,
    },
    mail: [
      "samarth.bajaj@coulomb.ai",
      "vaidehi.shrivastava@coulomb.ai",
      "khushboo@coulomb.ai",
      "santanu@coulomb.ai",
      "ashish.panda@batterysmart.in",
      "hardik.vyas@batterysmart.in",
      "bhavya.verma@batterysmart.in",
      "amit.batra@batterysmart.in",
      "atul.rai@batterysmart.in",
      "ravi.pande@batterysmart.in",
      "amit.kumar@batterysmart.in",
      "tejasav.khattar@batterysmart.in",
      "gurpreet.singh@batterysmart.in",
      "shivam@batterysmart.in",
      "vikesh.tiwari@batterysmart.in",
      "abhay.nain@batterysmart.in",
      "utkarsh.kumar@batterysmart.in",
      "sanchit.garg@batterysmart.in",
      "siddharth@batterysmart.in",
      "samanvaya.jain@batterysmart.in",
      "pulkit.khurana@batterysmart.in",
    ],
  },
  responseStatus: {
    code: 200,
    msg: "Data successfully fetched",
  },
};

export const tabCountsResponse = {
  data: [
    {
      alertStatus: "active",
      count: 20,
    },
    {
      alertStatus: "open",
      count: 22,
    },
    {
      alertStatus: "resolved",
      count: 23,
    },
    {
      alertStatus: "expired",
      count: 24,
    },
  ],
  responseStatus: {
    code: 200,
    msg: "Success.",
  },
};
