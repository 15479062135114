import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import Events from "../../../Analytics/Events";
import AnalyticsColumnPlusLine from "../../../Charts/Mixed/AnalyticsColumnPlusLine";
import CustomIcon from "../../../Components/CustomIcon";
import CardWithHeader from "../../../Features/Cards/CardWithHeader";
import { useNotification } from "../../../Hooks/useNotification";
import { getMetricTrendData } from "../../../Api/ChargingAnalytics";
import CODE from "../../../Static/Constants/StatusCodes";
import { findYAxisPartitionSizeForNegativeAxis, findYAxisPartitionSizeForPositiveAxis, getXAxisDateString } from "../../../Helper/Charts/ChartHelper";
import { toTitleCase } from "../../../Helper/Formatters/TextFormatter";

const defaultChartState = {
  series: {
    line: [], 
    column:[]
  },
  statusCode: CODE.LOADING
}

const barPlotYAxisSetting = {
  title: {
    text: 'Energy Consumed (kWh)',
    style: {
      color: "#87939D",
      fontSize: '12px',
      fontFamily: 'Inter, Helvetica, Arial, sans-serif',
      fontWeight: 500,
    },
  },
  showAlways: true,
  tickAmount: 5,
  decimalsInFloat: 0,
  axisBorder: {
    show: false,
    color: '#87939D',
    offsetX: 2.5,
    offsetY: 0
  },
  labels: {
    style: {
        colors: '#87939D',
        fontSize: '12px',
        fontFamily: 'Inter, Helvetica, Arial, sans-serif',
        fontWeight: 400,
    },
  },
  max: function(max) { 
    const threshold = 50;
    const numberOfPartitions = 5;

    // Determine partition size based on the value of max
    const partitionSize = findYAxisPartitionSizeForPositiveAxis(max)

    // Calculate the nearest larger number that is a multiple of partitionSize
    const nearestLargerPartition = Math.ceil(max / (numberOfPartitions * partitionSize)) * numberOfPartitions * partitionSize;

    // Ensure the result is at least as large as the threshold
    const result = Math.max(threshold, nearestLargerPartition);

    if (Number.isFinite(result)) {
      return result
    }
    return numberOfPartitions
  },
  min: function(min) { 
    const partitionBy = findYAxisPartitionSizeForNegativeAxis(min)
    const numberOfPartition = 5
    const threshold = 0

    if (min >= threshold) { 
      return 0 
    }

    const nearestPartitionedNumber = Math.floor(min / partitionBy) * partitionBy;
    
    const lowerBound = nearestPartitionedNumber - (partitionBy * (numberOfPartition - 1));

    if (Number.isFinite(lowerBound)) {
      return lowerBound
    }
    return 0
  }
}

const linePlotYAxisSetting = {
  title: {
    text: 'Number of vehicles',
    style: {
      color: "#87939D",
      fontSize: '12px',
      fontFamily: 'Inter, Helvetica, Arial, sans-serif',
      fontWeight: 500,
    },
  },
  showAlways: true,
  opposite: true,
  tickAmount: 5,
  decimalsInFloat: 0,
  axisBorder: {
    show: false,
    color: '#87939D',
    offsetX: -4,
    offsetY: 0
  },
  labels: {
    style: {
        colors: '#87939D',
        fontSize: '12px',
        fontFamily: 'Inter',
        fontWeight: 400,
    },
  },
  max: (max) => {
    const calculatedMax = findYAxisPartitionSizeForPositiveAxis(max) * 5

    if (Number.isFinite(calculatedMax)) {
      return Math.max(calculatedMax, 5)
    }
    return 5
  },
  min: (min) => {
    if (Number.isFinite(min)) {
      return min
    }
    return 0
  }
}

const EnergyConsumedChart = ({ filters, startDate, endDate, granularity, cardSubtitleTimePeriodText }) => {
  const { openNotification, closeNotification } = useNotification();
  const [chartState, setChartState] = useState(defaultChartState);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })

  useEffect(() => {
    if (Object.keys(filters).length) {
      fetchData();
    }
  }, [
    filters,
    granularity
  ])

  const fetchData = async () => {
    setChartState(defaultChartState);
    const res = await getMetricTrendData(["energyConsumed", "vehiclesCount"], startDate, endDate, granularity.granularity, filters, defaultFilterValues)
    const { responseStatus, response } = res;
    
    if (responseStatus.code !== CODE.SUCCESS) {
      setChartState({
        ...defaultChartState,
        statusCode: CODE.NODATA
      });
      return
    }

    generateChartData(response, responseStatus);
  }

  const generateChartData = (response, responseStatus) => {
    const dataLength = response.data?.length;
    const series =  {
      column: {
        name: 'Energy Consumed (kWh)',
        type: 'column',
        color: "#AEDF5B",
        data: response.data?.map(data => {
          const {x, dateRangeString } = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity)
          return {
            x,
            y: data.metricsArray.find(item => item.type == 'energyConsumed')?.value,
            dateRangeString
          }
        })
      },
      line: {
        name: "Number of vehicles",
        type: 'line',
        color: "#7957FF",
        data: response.data?.map(data => {
          const {x, dateRangeString } = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity)
          return {
            x,
            y: data.metricsArray.find(item => item.type == 'vehiclesCount')?.value,
            dateRangeString
          }
        })
      },
    }

    setChartState({
      series: series, 
      statusCode: responseStatus.code
    })
  }

  const generateCsvData = () => {
    const formatItem = (energyUsedData, numberOfvehicleData) => ({
      "Date Range": energyUsedData.dateRangeString,
      "Energy Used (kWh)": energyUsedData.y?.toFixed(2),
      "Number of Vehicles": numberOfvehicleData.y
    });

    if (chartState.statusCode !== CODE.SUCCESS) {
      return
    }
    const data = [
      ...chartState.series.column.data.map((energyUsedRow, index) => formatItem(energyUsedRow, chartState.series.line.data[index])),
    ];

    setCsvData({
      data,
      responseStatus: { code: CODE.SUCCESS }
    });
  }
  
  return (
    <CardWithHeader
      id='energyConsumedChart'
      title="Energy Consumed"
      subtitle={`${cardSubtitleTimePeriodText}`}
      iconElement={<CustomIcon name="chargerPlug" style={{ width: 21, height: 21 }}/>}
      pageLabel="Charging Analytics"
      showCSVDownload
      csvData={csvData}
      csvName={"EnergyConsumed"}
      generateCsvData={generateCsvData}
      onMouseEnter={() =>
        Events("Charging Analytics: Hover over Energy consumed chart")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
      cardStyle={{ height: "464px" }}
      bodyStyle={{ height:"358px", position: "relative", paddingLeft: "3px", paddingRight: "3px" }}
    >
      <AnalyticsColumnPlusLine
        state={chartState.statusCode}
        granularity={toTitleCase(granularity.granularity)}
        columnPlotDataSeries={chartState.series.column}
        linePlotDataSeries={chartState.series.line}
        barPlotYAxisSetting={barPlotYAxisSetting}
        linePlotYAxisSetting={linePlotYAxisSetting}
      />
    </CardWithHeader>
  )
}

export default EnergyConsumedChart;
