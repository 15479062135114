import { SvgIcon } from "@mui/material"
import React from "react"

const ClockSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 17.4166C13.8723 17.4166 17.4167 13.8722 17.4167 9.49998C17.4167 5.12773 13.8723 1.58331 9.5 1.58331C5.12774 1.58331 1.58333 5.12773 1.58333 9.49998C1.58333 13.8722 5.12774 17.4166 9.5 17.4166Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.5 4.75V9.5L12.6667 11.0833" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </SvgIcon>
  )
}

ClockSvg.defaultProps = {
  width: "19",
  height: "19",
  viewBox: "0 0 19 19",
  fill: "none",
  stroke: "#444B51"
}

export default ClockSvg
