import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Box, Typography } from "@mui/material";
import { DateFormatString } from "../../Helper/DatePicker/DateFormatters";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import "./charts.css";
import moment from "moment";

const DigitalTwinChargerVitalsChart = ({
  dataSeries,
  setXAxis,
  xAxis,
  selectedMetrics,
  optionData,
  startDate,
  isFetchingChartData,
  isChartEmpty,
}) => {
  const { dataArray } = dataSeries || [];
  const [zoomXAxis, setZoomXAxis] = useState(null);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);


  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const swapIndexes = (arr) => {
    if (!arr || arr.length < 2) {
      return arr;
    }

    const temp = arr[0];
    arr[0] = arr[1];
    arr[1] = temp;

    return arr;
  };

  const getRange = (item) => {
    let yMin = -10;
    let yMax = 50;

    if (item) {
      yMin = item.yMin;
      yMax = item.yMax;
    }
    return { yMin, yMax };
  };

  const getYAxisSettings = (matrix, settings = {}, range = {}) => {
    if (!matrix) {
      return {
        axisBorder: {
          show: true,
          color: "#87939D",
          width: 1.5,
          offsetX: -2,
        },
        labels: {
          formatter: function (value) {
            return value?.toFixed(0);
          },
        },
      };
    }

    return {
      seriesName: matrix.label || "NA",
      opposite: settings.opposite,
      axisBorder: {
        show: true,
        color: matrix.checked ? matrix.color : "#87939D",
        width: 1.5,
        offsetX: settings.borderOffsetX,
      },
      floating: settings.floating || false,
      labels: {
        offsetX: settings.labelOffsetX,
        maxWidth: 170,
        style: {
          colors: [matrix.checked ? "#87939D" : "transparent"],
          fontSize: "12px",
          fontWeight: "500",
          LineHeight: "16px",
          fontFamily: "Inter",
        },
        formatter: function (value) {
          return value?.toFixed(0);
        },
      },
      title: {
        text: matrix.unit || "NA",
        offsetX: settings.titleOffsetX,
        rotate: 0,
        offsetY: optionData.dateType === "Weekly" && dataArray[0]['data'].length > 10 ? -170 : -185,
        style: {
          color: matrix.label
            ? matrix.checked
              ? matrix.color
              : "transparent"
            : "transparent",
          fontSize: "12px",
          fontWeight: "500",
          LineHeight: "16px",
          fontFamily: "Inter",
        },
      },

      min: range.yMin,
      max: range.yMax,

      axisTicks: {
        show: false,
        borderType: "solid",
        color: ThemeProperties.c_axis_labels_color,
        offsetX: 5.5,
      },
      tickAmount: 5,
    };
  };

  const filterUndefined = (arr = []) =>
    arr.filter((item) => item !== undefined);

  const activeMatrixes = selectedMetrics.filter(
    (matrix) => matrix?.status === "active"
  );

  const filteredDataArray = filterUndefined(dataArray);

  const swappedDataArray = swapIndexes(filteredDataArray);
  const swappedActiveMatrixes = swapIndexes(activeMatrixes);

  const reformatMatrixes = () => {
    const predefinedYAxisSettings = [
      {
        borderOffsetX: -8,
        floating: false,
        labelOffsetX: -1,
        titleOffsetX: 36,
        opposite: false,
      },
      {
        borderOffsetX: -7,
        floating: true,
        labelOffsetX: 28,
        titleOffsetX: 36,
        opposite: false,
      },
      {
        borderOffsetX: -7,
        floating: false,
        labelOffsetX: -1,
        titleOffsetX: -32,
        opposite: true,
      },
      {
        borderOffsetX: -5,
        floating: true,
        labelOffsetX: 29,
        titleOffsetX: -36,
        opposite: true,
      },
    ];

    return swappedActiveMatrixes.map((activeMatrix, index) => {
      const range = getRange(swappedDataArray[index]);
      return getYAxisSettings(
        activeMatrix,
        predefinedYAxisSettings[index],
        range
      );
    });
  };

  const yAxisSettings = reformatMatrixes();

  const xAxisType =
    optionData.dateType && optionData.dateType !== "Raw Data"
      ? "category"
      : "datetime";

  const xAxisSetting = {
    type: xAxisType,
    ...(xAxisType === "datetime" && { tickPlacement: "on" }),
    tooltip: { enabled: false },
    title: { text: undefined },
    labels: {
      show: true,
      datetimeUTC: false,
      rotate: 0,
      rotateAlways: false,
      hideOverlappingLabels: false,
      style: {
        colors: "#87939D",
        fontSize: "12px",
        fontFamily: "Roboto",
        fontWeight: "500",
        lineHeight: "16px",
      },
      offsetX: 0,
      offsetY: -2,
      format: optionData.dateType === "Raw Data" ? zoomXAxis?.xaxis?.labels?.format || "h TT" : undefined,
    },
    axisTicks: { show: true, height: 3, borderType: "solid", color: "#87939D" },
    axisBorder: { show: true, color: "#87939D" },
    min: xAxis ? xAxis.min :
      optionData.dateType === "Raw Data"
        ? zoomXAxis?.xaxis?.min || new Date(DateFormatString(startDate)).getTime()
        : undefined,
    max: xAxis ? xAxis.max :
      optionData.dateType === "Raw Data"
        ? zoomXAxis?.xaxis?.max || new Date(DateFormatString(startDate)).getTime() + 86400000
        : undefined,
    ...(xAxisType !== "datetime" && {
      categories: optionData?.xAxisCategories || [],
    }),
  };

  const chartOptions = {
    chart: {
      animations: {
        enabled: false,
        easing: "easeinout",
        speed: 800,
        animateGradually: { enabled: true, delay: 150 },
        dynamicAnimation: { enabled: false, speed: 350 },
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
        offsetX: -100,
      },
      offsetX: 0,
      events: {
        beforeZoom: function (chart, { xaxis }) {
          if (optionData.dateType === "Raw Data") {
            const MIN_ZOOM_RANGE = 15 * 60 * 1000; // 15 minutes in milliseconds
            let min = xaxis.min;
            let max = xaxis.max;

            let newXAxis = {
              min,
              max
            }

            if (max - min >= 86400000) {
              newXAxis = {
                ...newXAxis,
                labels: {
                  format: "h TT",
                }
              }
              setXAxis(newXAxis)
              return newXAxis;
            }

            if (max - min < MIN_ZOOM_RANGE) {
              let newMax = min + MIN_ZOOM_RANGE;
              newXAxis = {
                ...newXAxis,
                max: newMax,
                labels: {
                  format: "h:mm TT",
                }
              }
              setXAxis(newXAxis)
              return newXAxis;
            } else {
              newXAxis = {
                ...newXAxis,
                labels: {
                  format: "h:mm TT",
                },
              }
              setXAxis(newXAxis)
              return newXAxis;
            }
          }
        },

        beforeResetZoom: function (chartContext, opts) {
          setXAxis(null);
          if (optionData.dateType === "Raw Data")
            return {
              xaxis: {
                min: new Date(startDate).getTime() - 19800000,
                max:
                  new Date(startDate).getTime() + 86400000 - 19800000,
                labels: {
                  show: true,
                  datetimeUTC: false,
                  rotate: 0,
                  rotateAlways: false,
                  style: {
                    colors: ThemeProperties.c_axis_labels_color,
                    fontSize: ThemeProperties.c_axis_labels,
                    fontFamily: "Roboto",
                    fontWeight: ThemeProperties.c_axis_labels_weight,
                  },
                  offsetX: 0,
                  offsetY: 0,
                  format: "h TT",
                },
              },
            };
        },
      }
    },
    grid: {
      show: true,
      borderColor: "#E9EFF5",
      strokeDashArray: 5,
      position: "back",
      xaxis: { lines: { show: optionData.dateType !== "Raw Data" } },
      yaxis: { lines: { show: true } },
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    events: {
      beforeZoom: function (chart, { xaxis }) {
        if (optionData.dateType === "Raw Data") {
          const MIN_ZOOM_RANGE = 15 * 60 * 1000; // 15 minutes in milliseconds
          let min = xaxis.min;
          let max = xaxis.max;
          let newXAxis

          if (max - min >= 86400000) {
            newXAxis = {
              xaxis: {
                min: new Date(startDate).getTime() - 19800000,
                max:
                  new Date(startDate).getTime() +
                  86400000 -
                  19800000,
                labels: {
                  format: "h TT",
                },
              },
            };
            setZoomXAxis(newXAxis)
            return newXAxis
          }

          if (max - min < MIN_ZOOM_RANGE) {
            let newMax = min + MIN_ZOOM_RANGE;

            newXAxis = {
              xaxis: {
                min: min,
                max: newMax,
                labels: {
                  format: "h:mm TT",
                },
              },
            };
            setZoomXAxis(newXAxis)
            return newXAxis
          } else {
            newXAxis = {
              xaxis: {
                min: min,
                max: max,
                labels: {
                  format: "h:mm TT",
                },
              },
            };
            setZoomXAxis(newXAxis)
            return newXAxis
          }
        }
      },

      beforeResetZoom: function (chartContext, opts) {
        if (optionData.dateType === "Raw Data")
          setZoomXAxis(null);
        return {
          xaxis: {
            min: new Date(startDate).getTime() - 19800000,
            max:
              new Date(startDate).getTime() + 86400000 - 19800000,
            labels: {
              show: true,
              datetimeUTC: false,
              rotate: 0,
              rotateAlways: false,
              style: {
                colors: ThemeProperties.c_axis_labels_color,
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              offsetX: 0,
              offsetY: 0,
              format: "h TT",
            },
          },
        };
      },
    },
    yaxis: yAxisSettings,
    xaxis: xAxisSetting,
    tooltip: {
      enabled: !isChartEmpty,
      followCursor: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
        const hoverIndexes = w.globals.seriesX.map(seriesX => {
          return seriesX.findIndex(xData => xData === hoverXaxis);
        });
        let hoverListHtml = '';
        let hoverItems = [];
        hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
          let value = series[seriesEachIndex][hoverIndex];
          const seriesColor = w.config.series[seriesEachIndex].color;

          if (Number.isFinite(value)) {
            value = value.toFixed(2)
          }

          if (hoverIndex >= 0 && value !== null) {
            hoverItems.push(`
              <div class="tooltip-item-container">
                <span class="dot" style="background-color: ${seriesColor};"></span>
                <span class="tooltip-item-text">
                  ${w.globals.seriesNames[seriesEachIndex]}: <strong>${value}</strong>
                </span>
              </div>
            `);
          }
        });

        hoverItems = swapIndexes(hoverItems)
        const data = w.config.xaxis.type === "datetime"
          ? moment(new Date(hoverXaxis)).format("DD MMM'YY, hh:mm:ss A")
          : w.globals.categoryLabels[hoverXaxis - 1];

        const useTwoColumns = hoverItems.length > 8;
        const columns = useTwoColumns ? 2 : 1;
        const rows = Math.ceil(hoverItems.length / columns);

        for (let row = 0; row < rows; row++) {
          for (let col = 0; col < columns; col++) {
            const itemIndex = row + col * rows;
            if (hoverItems[itemIndex]) {
              hoverListHtml += hoverItems[itemIndex];
            }
          }
        }
        return (`
          <div>
            <span>
              <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">
                ${data}
              </div>
            </span>
          </div>
          <div class="tooltip-container ${useTwoColumns ? 'tooltip-two-column' : ''}">
            ${hoverListHtml}
          </div>
        `)
      },
    },
    legend: { show: false },
  };

  const renderNoDataMessage = () => {
    const noChartDataTextLine1 = "No data found for selected date";
    const noChartDataTextLine2 = "";

    if (isFetchingChartData) {
      return null;
    }

    if (!isChartEmpty) {
      return null;
    }

    return (
      <div
        style={{
          position: "absolute",
          left: viewportWidth < 1200 ? "35%" : "41.5%",
          top: "35%",
          zIndex: 2
        }}
      >
        <div
          style={{
            backgroundColor: "#ececec",
            padding: "10px",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="textSubtitle">{noChartDataTextLine1}</Typography>
          <Typography variant="textSubtitle">{noChartDataTextLine2}</Typography>
        </div>
      </div>
    );
  };

  return (
    <Box style={{ position: "relative" }}>
      {renderNoDataMessage()}
      <Chart
        id={
          optionData.dateType === "Raw Data" ? "hideAlternateLabel" : "donthide"
        }
        type="line"
        height="400px"
        width="100%"
        style={{ paddingLeft: "15px", opacity: isFetchingChartData ? 0 : 1 }}
        options={chartOptions}
        series={swappedDataArray}
      />
    </Box>
  );
};

export default DigitalTwinChargerVitalsChart;
