import { SvgIcon } from "@mui/material"
import React from "react"

const OnlineIcon = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M5 12.55a11 11 0 0 1 14.08 0" />
        <path d="M1.42 9a16 16 0 0 1 21.16 0" />
        <path d="M8.53 16.11a6 6 0 0 1 6.95 0" />
        <line x1="12" y1="20" x2="12.01" y2="20" />
      </svg>
    </SvgIcon>
  )
}

OnlineIcon.defaultProps = {
  width: "14px",
  height: "10px",
  viewBox: "0 0 20 20",
  fill: "none",
  stroke: "#3CA967"
}

export default OnlineIcon
