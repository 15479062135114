import React from "react"
import { SvgIcon } from "@mui/material"

const NavbarDiagnosticsIcon = (props) => {
  const { height, width, viewBox, fill, stroke, style, ...other } = props
  return (
    <SvgIcon
      style={style}
    >
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
      >
        <path d="M7.6875 15.375C3.45 15.375 0 11.925 0 7.6875C0 3.45 3.45 0 7.6875 0C11.925 0 15.375 3.45 15.375 7.6875C15.375 11.925 11.925 15.375 7.6875 15.375ZM7.6875 1.125C4.065 1.125 1.125 4.0725 1.125 7.6875C1.125 11.3025 4.065 14.25 7.6875 14.25C11.31 14.25 14.25 11.3025 14.25 7.6875C14.25 4.0725 11.31 1.125 7.6875 1.125Z" fill={fill} />
        <path d="M16.1445 16C15.927 16 15.7096 15.9199 15.5379 15.7484L13.2489 13.461C12.917 13.1294 12.917 12.5804 13.2489 12.2487C13.5808 11.9171 14.1302 11.9171 14.4621 12.2487L16.7511 14.5361C17.083 14.8678 17.083 15.4167 16.7511 15.7484C16.5794 15.9199 16.3619 16 16.1445 16Z" fill={fill} />
        <path d="M3 8L4.16691 9.16691C4.32211 9.32211 4.58597 9.26357 4.66098 9.0573L6.19656 4.83446C6.29466 4.56467 6.67922 4.5735 6.76484 4.8475L8.7078 11.065C8.79604 11.3473 9.19648 11.3451 9.28149 11.0617L10.3557 7.48108C10.4209 7.26365 10.6946 7.19464 10.8552 7.35516L12 8.5" stroke={fill} stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </SvgIcon>

  )
}

NavbarDiagnosticsIcon.defaultProps = {
  width: "17",
  height: "16",
  viewBox: "0 0 17 16",
  fill: "#8D9FB2",
  stroke: ""
}

export default NavbarDiagnosticsIcon
