import React from "react"
import BatteryHealth from "../../../Assets/Icons/BatteryHealth/HeartWithBattery.png"

const HeartWithBattery = (props) => {
  return (
    <img 
      src={BatteryHealth} 
      alt="Description" 
      style={{ width: props.width, height: props.height }}
    />
  )
}

HeartWithBattery.defaultProps = {
  width: "19px",
  height: "19px",
}

export default HeartWithBattery
