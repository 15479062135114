import { decryptQueryParams } from "query-string-hash";
const ScrollToTheElement = () => {
  let paramObj = {};
  let temp = window.location.search.slice(1);
  const queryParams = decryptQueryParams(temp);
  if (queryParams.id == null && temp) {
    return;
  }
  for (var value of Object.keys(queryParams)) {
    if (value === "id") {
      paramObj[value] = queryParams[value];
    }
  }

  if (paramObj.id) {
    document.getElementById(paramObj.id)?.classList.add("view");
    document.getElementById(paramObj.id)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }
};
export default ScrollToTheElement;
