import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { InnerBox, OuterBox, SimpleBox } from "../Components/CustomBox";
import LandingComponent from "../PagesContent/Reports/Landing/LandingComponent";
import PageHeaderKPI from "../Features/PageHeader/PageHeaderKPI";
import { MapNonClickablekpis } from "../Static/Data/Map/MapKPIS";
import { getConnectedBatteries } from "../Api/Api";
import { getAllBatteriesCount } from "../Api/Reports/AlertAnalytics";
import { formatDateNumeric, returnLastSunday } from "../Helper/DatePicker/DateFormatters";
import { groupByKey } from "../Helper/Operations/GroupByKey";

import { useDispatch,useSelector } from "react-redux";
import { SetSessionExpired } from "../Actions";

import moment from "moment";
import { getNonPingingBatteries } from "../Api/Reports/DataQuality";

function Reports(props) {
  const dispatch = useDispatch();

  const [devicesDistribution, setDevicesDistribution] = useState({
    count: [],
    categories: [],
    responseStatus: { code: -1, msg: "" },
  });

  const [nonPingingDevices, setNonPingingDevices] = useState({
    count: [],
    categories: [],
    responseStatus: { code: -1, msg: "" },
  });

    const [connectedBatteries, setConnectedBatteries] = useState(0);

  useEffect(() => {
    getAllBatteriesCount(
      [
        formatDateNumeric(
          new Date(new Date().setDate(new Date().getDate() - 34))
        ),
        formatDateNumeric(new Date(returnLastSunday())),
      ],
      "",
      "",
      "",
      "Weekly"
    ).then((res) => {
      if (res.responseStatus.code === 200) {
        let tempAlertList = [],
          tempCategories = [],
          tempCount = [];
        res.response.alertList.map((item) => {
          tempAlertList.push({
            deviceCount: item.deviceCount,
            alertCount: item.alertCount,
            severity: item.severity,
            startDate: item.range.startDate,
            endDate: item.range.endDate,
          });
        });

        const gy = groupByKey(tempAlertList, "startDate");
        console.log(gy);

        let newData = [];
        gy.map((item) => {
          let tempCount = 0;
          item["values"].map((item2) => {
            tempCount += item2["deviceCount"];
          });
          newData.push({
            key: item["key"],
            values: item["values"],
            count: tempCount,
          });
        });

        newData.map((item) => {
          tempCategories.push(
            moment(new Date(item["values"][0]["startDate"])).format("DD MMM") +
              " - " +
              moment(new Date(item["values"][0]["endDate"])).format("DD MMM")
          );
          tempCount.push(item["count"]);
        });

        setDevicesDistribution({
          count: tempCount,
          categories: tempCategories,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
      else {
        if (res.responseStatus.code === -2) {
          dispatch(SetSessionExpired(true));
        }
        setDevicesDistribution({
          count: [],
          categories: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });

    getNonPingingBatteries(
      [
        formatDateNumeric(
          new Date(new Date().setDate(new Date().getDate() - 34))
        ),
        formatDateNumeric(new Date(returnLastSunday())),
      ],
      "",
      "",
      "",
      "Weekly"
    ).then((res) => {
      if (res.responseStatus.code === 200) {
        console.log(res.response)
        let tempAlertList = [],
          tempCategories = [],
          tempCount = [];
        res.response.alertList.map((item) => {
          tempAlertList.push({
            notPingedDevicesCount: item.notPingedDevicesCount,
            startDate: item.range.startDate,
            endDate: item.range.endDate,
          });
        });

        const gy = groupByKey(tempAlertList, "startDate");
        console.log(gy);

        // let newData = [];
        gy.map((item) => {
          // console.log(item["values"][0])
            tempCategories.push(
            moment(new Date(item["values"][0]["startDate"])).format("DD MMM") +
              " - " +
              moment(new Date(item["values"][0]["endDate"])).format("DD MMM")
          );
          tempCount.push(item["values"][0]["notPingedDevicesCount"]);
        });

        setNonPingingDevices({
          count: tempCount,
          categories: tempCategories,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        })
      }
      else {
        if (res.responseStatus.code === -2) {
          dispatch(SetSessionExpired(true));
        }
        setNonPingingDevices({
          count: [],
          categories: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });

    getConnectedBatteries().then((res) => {
      if (res.responseStatus.code === 200) {
        setConnectedBatteries(res.response.data[0]["count"]);
      } else if (res.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
  }, []);

  return (
    <OuterBox>
      <InnerBox sx={{ minHeight: "800px",overflowX:"hidden" }}>
        <Box sx={{ mt: 0.5 }}></Box>
        <PageHeaderKPI
          name={"Reports"}
          nonClickableKpis={MapNonClickablekpis}
          connectedBatteries={connectedBatteries}
        />
        <Box sx={{ mt: 2 }}></Box>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <LandingComponent
              name={"Alert Analytics"}
              id={"Alert_Analytics"}
              navigate={"/_Reports/AlertAnalytics"}
              keyName={"reports_alerts_analytics"}
              data={devicesDistribution}
              yAxisTitle={"Number of batteries"}
              description={"Number of batteries which generated alerts in last 5 weeks"}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <LandingComponent
              name={"Data Quality"}
              id={"Data_Quality"}
              navigate={"/_Reports/DataQuality"}
              keyName={"reports_alerts_analytics"}
              data={nonPingingDevices}
              yAxisTitle={"Non pinging batteries"}
              description={"Number of batteries which are not pinging in last 5 weeks"}
            />
          </Grid>
         
        </Grid>
      </InnerBox>
    </OuterBox>
  );
}

export default Reports;

