import { PTypography } from "../../../Components/CustomP";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import { Typography } from "@mui/material";
import { Tooltip } from "antd";
import { getColumnTitleSort } from "../../../Features/Table/AntDesign/TableFunctions";
import { getChartTimeStamp } from "../../../Helper/DatePicker/DateConverter";
import moment from "moment";
import TextHyperLinkWithCopyIcon from "../../../Helper/Operations/TextHyperLinkWithCopyIcon";

function GetSpecificBatteryAlertsColumns() {
  function onBatteryIDClick(record) {}

  const columnsNew = [
    {
      title: <Typography variant="tableHeadBold">Pack ID</Typography>,
      sorter: false,
      uniqueKey: "packID",
      ellipsis: {
        showTitle: false,
      },
      filterSearch: false,
      width: 190,
      // maxWidth:"18px",
      render: (record) => (
        <Tooltip placement="top" title={record.packID}>
          <Typography variant="tableRow">
            {TextHyperLinkWithCopyIcon(
              record.packID,
              "Pack ID",
              onBatteryIDClick,
              record
            )}
          </Typography>
        </Tooltip>
      ),
    },
    {
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "errorTime", "Alert First Seen"),
      dataIndex: "errorTime",
      key: "errorTime",
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
      showSorterTooltip: false,
      uniqueKey: "errorTime",
      filterSearch: false,
      width: 180,
      sorter: (a, b) => new Date(a.errorTime) - new Date(b.errorTime),
      // maxWidth:"18px",
      render: (errorTime) => (
        <Typography variant="tableRow">
          {moment(getChartTimeStamp(errorTime)).format("DD MMM'YY hh:mm A")}
        </Typography>
      ),
    },
    {
      title: <Typography variant="tableHeadBold">Alert Description</Typography>,
      dataIndex: "errorDescription",
      key: "errorDescription",
      sorter: false,
      uniqueKey: "errorDescription",
      ellipsis: {
        showTitle: false,
      },
      filterSearch: false,
      width: 180,
      // maxWidth:"18px",
      render: (errorDescription) => (
        <Tooltip placement="top" title={errorDescription}>
          <Typography variant="tableRow">{errorDescription}</Typography>
        </Tooltip>
      ),
    },
    {
      title: <Typography variant="tableHeadBold">Status</Typography>,
      dataIndex: "errorStatus",
      key: "errorStatus",
      sorter: false,
      align: "left",
      uniqueKey: "errorStatus",
      filterSearch: false,
      width: 100,
      // maxWidth:"18px",
      render: (errorStatus) => (
        <PTypography
          mycolor={
            errorStatus === "Active"
              ? ThemeProperties.red_100
              : errorStatus === "Open"
                ? ThemeProperties.blue_100
                : errorStatus === "Resolved"
                  ? ThemeProperties.green_100
                  : errorStatus === "Ignored"
                    ? ThemeProperties.pink_100
                    : ThemeProperties.blueGrey_100
          }
        >
          <Typography variant="tableRow">{errorStatus}</Typography>
        </PTypography>
      ),
    },
    {
      title: <Typography variant="tableHeadBold">Current (A)</Typography>,
      dataIndex: "currentPack",
      key: "currentPack",
      sorter: false,
      align: "center",
      uniqueKey: "currentPack",
      filterSearch: false,
      width: 120,
      // maxWidth:"18px",
      render: (currentPack) => (
        <Typography variant="tableRow">{currentPack?.toFixed(1)}</Typography>
      ),
    },
    {
      title: <Typography variant="tableHeadBold">Voltage (V)</Typography>,
      dataIndex: "voltagePack",
      key: "voltagePack",
      sorter: false,
      align: "center",
      uniqueKey: "voltagePack",
      filterSearch: false,
      width: 120,
      // maxWidth:"18px",
      render: (voltagePack) => (
        <Typography variant="tableRow">{voltagePack?.toFixed(3)}</Typography>
      ),
    },
    {
      title: <Typography variant="tableHeadBold">Temperature (°C)</Typography>,
      dataIndex: "temperature",
      key: "temperature",
      sorter: false,
      align: "center",
      uniqueKey: "temperature",
      filterSearch: false,
      width: 160,
      // maxWidth:"18px",
      render: (temperature) => (
        <Typography variant="tableRow">{temperature}</Typography>
      ),
    },
    {
      title: <Typography variant="tableHeadBold">Lat/Long</Typography>,
      dataIndex: "latitude",
      key: "latitude",
      sorter: false,
      align: "left",
      uniqueKey: "latitude",
      filterSearch: false,
      width: 170,
      // maxWidth:"18px",
      render: (text, record) => (
        <Typography variant="tableRow">
          {record.latitude?.toFixed(6)}/{record.longitude?.toFixed(6)}
        </Typography>
      ),
    },
    {
      title: <Typography variant="tableHeadBold">Location</Typography>,
      name: "City",
      dataIndex: "location",
      sorter: false,
      showSorterTooltip: false,
      filterMode: "tree",
      filterSearch: false,
      uniqueKey: "cityResolved",
      width: 170,
      render: (location) => (
        <Typography variant="tableRow">{location}</Typography>
      ),
    },
  ];
  return columnsNew;
}
export default GetSpecificBatteryAlertsColumns;
