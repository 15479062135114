import React from "react";
import Check from "../../Assets/Gif/Check.gif";
import { Typography } from "@mui/material";

function Confirm(props) {

  return (
    <div className="mytable">
      <div className="cell">
        <img src={Check} alt="not found" height={"110px"} />
        <Typography
          style={{ color: "#000", marginTop: "10px",fontSize:"14px" }}
        >
          {props.text}
        </Typography>
      </div>
    </div>
  );
}

export default Confirm;
