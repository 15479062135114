const initialUserState = {
    deleted: "no",
  };
  
  const DeletedFromCart = (state = initialUserState, action) => {
      
    switch (action.type) {
      case "IsDeletedFromCart":
        return {
          ...state,
          arr: action.isDeleted,
      };
      default:
        return state;
    }
  };
  
  
  export default DeletedFromCart;
  