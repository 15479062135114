import { Box, styled, Typography } from "@mui/material"
import { CustomText, FlexBox, FlexCenterBox, FullWidthBox } from "../../../Components/CustomBox"

const HealthTabHierarchyContainer = styled(Box)({
  width: "261px",
  borderRight: "1px solid #CDDEEF",
  height: "100%",
  position: "fixed",
  overflowY: 'auto',
  zIndex: 100
})

const HealthTabHierarchyHeaderBox = styled(Box)({
  padding: "8px 23px",
  borderBottom: "1px solid #CDDEEF",
})

const HealthTabHierarchyHeaderText = styled(Box)({
  fontFamily: "Roboto",
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: "600",
  color: "#444B51"
})

const HealthTabHierarchyEntityCountContainer = styled(FlexBox)({
  alignItems: 'center',
  padding: "6px 33px",
  borderBottom: "1px solid #CDDEEF",
})

const HealthTabHierarchyEntityCountBox = styled(FlexBox)({
  alignItems: 'center'
})

const HealthTabHierarchyHeaderEntityLabel = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "140x",
  lineHeight: "16px",
  fontWeight: "500",
  color: "#87939D",
  textAlign: 'center'
})

const HealthTabHierarchyHeaderEntityCount = styled(Typography)({
  fontFamily: "Roboto",
  fontSize: "12px",
  lineHeight: "20px",
  fontWeight: "500",
  color: "#444B51",
  marginLeft: "6px"
})

const HealthTabKPIsContainer = styled(Box)({
  padding: "14px 38px",
  marginBottom: "4px"
})

const HealthTabKPIsBodyContainer = styled(FlexBox)({
  paddingLeft: "22px"
})

const HealthTabKPIsBox = styled(FlexBox)({
  marginLeft: "48px",
  borderLeft: "1px solid #CDDEEF"
})

const HealthTabKPIsHeader = styled(CustomText)({
  fontWeight: "500px",
  fontSize: "14px",
  color: "#444B51",
  lineHeight: "16px",
  marginBottom: "14px"
})

const HealthTabChartSection = styled(FullWidthBox)({
  paddingLeft: "261px"
})

const SOHProgressBarPercentageText = styled(CustomText)({
  fontSize: "13px",
  fontWeight: "800",
  lineHeight: "15px",
  color: "#444B51"
})

const SOHProgressBarText = styled(CustomText)({
  fontSize: "10px",
  fontWeight: "500",
  lineHeight: "16px",
  color: "#444B51"
})

const SOHProgressBarTextBox = styled(FlexCenterBox)({
  flexDirection: "column",
})

export {
  SOHProgressBarText,
  HealthTabKPIsBox,
  SOHProgressBarTextBox,
  SOHProgressBarPercentageText,
  HealthTabHierarchyContainer,
  HealthTabHierarchyEntityCountBox,
  HealthTabHierarchyHeaderText,
  HealthTabHierarchyHeaderBox,
  HealthTabHierarchyEntityCountContainer,
  HealthTabHierarchyHeaderEntityLabel,
  HealthTabHierarchyHeaderEntityCount,
  HealthTabKPIsContainer,
  HealthTabKPIsHeader,
  HealthTabKPIsBodyContainer,
  HealthTabChartSection
}
