import React, { useEffect } from "react";
import { OuterBox, InnerBox, DownloadCSVBox } from "../../Components/CustomBox";
import { Box, Paper, Typography, IconButton, Button } from "@mui/material";
import "../../Features/Table/AntDesign/table.css";
import { useNavigate } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

import GenericSelectTest from "../../Features/Menu/GenericSelectKeyValue";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import BasicTableAllBatteries from "../../Features/Table/AntDesign/BasicTableAllBatteries";
// import { Checkbox as AntCheckbox } from "antd";

import useState from "react-usestateref";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import {
  getKPIBatteries,
  getCSVOfBatteries,
} from "../../Api/AllBatteriesApi";
import {
  // SetAllBatteriesColumns,
  // SetAllBatteriesHiddenColumns,
  SetAllBatteriesState,
  SetAllBatteriesVolt,
  SetAllBatteriesCity,
  SetAllBatteriesZone,
  SetAllBatteriesStatus,
  SetAllBatteriesPageNumber,
  SetAllBatteriesStack,
  SetAllBatteriesBusiness,
  SetAllBatteriesIOT,
  SetAllBatteriesPack,
  SetAllBatteriesSeverity,
} from "../../Actions/AllBatteries";
import { SetSessionExpired } from "../../Actions";
// import { theme } from "antd";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";

import DownloadCSV from "../../Helper/CSVOperations/DownloadCSV";
import NameTheCSV from "../../Helper/CSVOperations/NameTheCSV";
import Events from "../../Analytics/Events";

import {
  decryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTheFilterValues,
  setTheFilterValues,
} from "../../Helper/QueryParams/EncryptDecrypt";
import FindTheSeverity from "../../Helper/Operations/FindTheSeverity";
// const { useToken } = theme;

function AllBatteriesMain(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { token } = useToken();

  const universal = useSelector((state) => state.AllBatteriesStack);

  const [resetButton, setResetButton] = useState(false);

  const orderChangedTo = useSelector((state) => state.CustomOrder);
  const sortChangedTo = useSelector((state) => state.KPISort);

  const userInputBatteries = useSelector(
    (state) => state.AllBatteriesUserInputBatteries.value
  );

  const multipePartners = useSelector(
    (state) => state.AllBatteriesMultiplePartners.value
  );
  const batteryPartnerSelect = useSelector(
    (state) => state.AllBatteriesBatteryPartnerSelect.value
  );

  const batteryState = useSelector((state) => state.AllBatteriesState.value);
  const volt = useSelector((state) => state.AllBatteriesVolt.value);
  const city = useSelector((state) => state.AllBatteriesCity.value);
  const zone = useSelector((state) => state.AllBatteriesZone.value);
  const iotVendor = useSelector((state) => state.AllBatteriesIOT.value);
  const packVendor = useSelector((state) => state.AllBatteriesPack.value);
  const batterystatus = useSelector((state) => state.AllBatteriesStatus.value);
  const business = useSelector((state) => state.AllBatteriesBusiness.value);
  const severity = useSelector((state)=>state.AllBatteriesSeverity.value);

  // const [open, setOpen] = useState(false);
  const newColumns = useSelector((state) => state.AllBatteriesColumns.value);

  // const columnHide = useSelector(
  //   (state) => state.AllBatteriesHiddenColumns.value
  // );

  // const contentStyle = {
  //   backgroundColor: token.colorBgElevated,
  //   borderRadius: token.borderRadiusLG,
  //   boxShadow: token.boxShadowSecondary,
  // };
  // const menuStyle = {
  //   boxShadow: "none",
  // };
  // console.log("hidden columns going into items ", columnHide);
  // const items = [
  //   ...newColumns[0]["columns"].map((d, i) => {
  //     if (d.name !== "")
  //       if (
  //         d.dataIndex === "batteryID" ||
  //         d.dataIndex === "soc" ||
  //         d.dataIndex === "socBMS"
  //       ) {
  //         return {
  //           key: i + 1,
  //           label: (
  //             <AntCheckbox
  //               // color="primary"
  //               key={i + 1}
  //               checked={true}
  //               disabled
  //               // onChange={() => onFilterCheckboxClick(d.uniqueKey)}
  //             >
  //               {d.name}
  //             </AntCheckbox>
  //           ),
  //         };
  //       } else {
  //         return {
  //           key: i + 1,
  //           label: (
  //             <AntCheckbox
  //               // color="primary"
  //               key={i + 1}
  //               checked={!columnHide.includes(d.uniqueKey)}
  //               onChange={() => onFilterCheckboxClick(d.uniqueKey)}
  //             >
  //               {d.name}
  //             </AntCheckbox>
  //           ),
  //         };
  //       }
  //   }),
  // ];

  const filters = {
    Volt: volt,
    City: city,
    Zone: zone,
    IOT: iotVendor,
    Pack: packVendor,
    Status: batterystatus,
    State: batteryState,
    Select: batteryPartnerSelect,
    Business: business,
    Severity: severity
  };

  const pageNumber = useSelector((state) => state.AllBatteriesPageNumber.value);
  const rowsPerPage = useSelector(
    (state) => state.AllBatteriesRowsPerPage.value
  );

  const [batteriesInTable, setBatteriesInTable] = useState({
    batteries: [],
    responseStatus: { code: -1, msg: "" },
  });

  const existingBatteries = [];
  const [total, setTotal] = useState(0);
  const [mountedCSVLoader, setMountedCSVLoader] = useState(false);

  // const onClickApply = () => {
  //   const tempColumns = [...newColumns];
  //   const tempHiddenColumns = [...columnHide];

  //   tempColumns[0]["columns"].forEach((column, i) => {
  //     if (column.name !== "") {
  //       if (columnHide.includes(column.uniqueKey)) {
  //         column.hidden = true;
  //         // tempHiddenColumns.push(column.uniqueKey)
  //       } else {
  //         column.hidden = false;
  //       }
  //     }
  //   });
  //   // console.log("onClickApply called");
  //   // console.log("columns are ", tempColumns);
  //   dispatch(SetAllBatteriesColumns([...tempColumns]));
  //   // dispatch(SetAllBatteriesHiddenColumns([...tempHiddenColumns]));
  //   setOpen(false);
  // };

  // const onClickReset = () => {
  //   const tempColumns = [...newColumns];
  //   const tempHiddenColumns = [...columnHide];

  //   tempColumns[0]["columns"].forEach((column, i) => {
  //     if (column.name !== "") {
  //       column.hidden = false;
  //       const index = tempHiddenColumns.indexOf(column.uniqueKey);
  //       if (index > -1) {
  //         // only splice array when item is found
  //         tempHiddenColumns.splice(index, 1); // 2nd parameter means remove one item only
  //       }
  //     }
  //   });
  //   // const arr = newColumns[0]["columns"];
  //   // setColumns([...arr]);
  //   // console.log("onClickReset called");
  //   // console.log("columns are ", tempColumns);
  //   // console.log("hiden columns are ", tempHiddenColumns);
  //   dispatch(SetAllBatteriesColumns([...tempColumns]));

  //   dispatch(SetAllBatteriesHiddenColumns([...tempHiddenColumns]));
  //   setOpen(false);
  // };

  // const handleOpenChange = () => {
  //   setOpen(!open);
  // };

  // const onFilterCheckboxClick = (colName) => {
  //   if (columnHide.includes(colName)) {
  //     // setColumnHide([...columnHide.filter((ele) => ele != colName)]);
  //     dispatch(
  //       SetAllBatteriesHiddenColumns([
  //         ...columnHide.filter((ele) => ele != colName),
  //       ])
  //     );
  //   } else {
  //     // setColumnHide([...columnHide, colName]);
  //     dispatch(SetAllBatteriesHiddenColumns([...columnHide, colName]));
  //   }
  // };

  // const handleOnOpenChange = () => {
  //   // console.log("handleOnOpenChange called");
  //   // console.log("column Hide includes ", columnHide);

  //   const arr = [];

  //   newColumns[0]["columns"].forEach((col) => {
  //     if (col.hidden === true) {
  //       arr.push(col.uniqueKey);
  //     }
  //   });
  //   // setColumnHide([...arr])
  //   dispatch(SetAllBatteriesHiddenColumns([...arr]));

  //   setOpen(!open);
  // };

  function CreateCSV() {
    const newBatteries = [];
    let newLastPingedTime;
    setMountedCSVLoader(true);
    getCSVOfBatteries(universal.arr, props.pagesContent.batteryType).then(
      (res) => {
        if (res.responseStatus.code === 200) {
          res.response.listOfBatteries.forEach((row, index) => {
            if ("lastPingedTime" in row)
              newLastPingedTime = convertUTCtoZone(row.lastPingedTime).replace(
                /,/g,
                ""
              );

            newBatteries.push({
              "Device ID": row.batteryID,
              "Battery ID": row.assetID,
              "Operating State":row.batteryFlag === 0 ?"Operational" : row.batteryFlag === 1 ?  "Out of Service" : row.batteryFlag === 2 ? "Under Maintenance": "Isolated",
              "SOC (%)": row.socBMS,
              "Severity": FindTheSeverity(row.severity),
              "SOH (%)": row.sohBMS?.toFixed(0),
              "Eq. Cycles": row.eqCycleBMS?.toFixed(0),

              "Volt (V)": (row.voltagePack / 1000).toFixed(1),
              "Last Pinged": newLastPingedTime,
              "Lat/Long": row.latitude?.toFixed(6)+"/"+row.longitude?.toFixed(6),
              
              "Occupant": row.partner,
              "State": row.batteryState,

            });
          });
          DownloadCSV(
            NameTheCSV("All Batteries", "CSV"),
            newBatteries
          );
          setMountedCSVLoader(false);
          dispatch(SetAllBatteriesStack([]));
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setMountedCSVLoader(false);
          dispatch(SetAllBatteriesStack([]));
        }
      }
    );
  }

  function showResetButton() {
    if (
      batteryState !== "" ||
      volt !== "" ||
      city !== "" ||
      zone !== "" ||
      batterystatus !== "" ||
      iotVendor !== "" ||
      packVendor !== "" ||
      business !== "" || 
      severity !== ""
    ) {
      setResetButton(true);
    } else {
      setResetButton(false);
    }
  }

  function resetTheFilters() {
    dispatch(SetAllBatteriesPageNumber(1));
    dispatch(SetAllBatteriesState(""));
    dispatch(SetAllBatteriesVolt(""));
    dispatch(SetAllBatteriesCity(""));
    dispatch(SetAllBatteriesZone(""));
    dispatch(SetAllBatteriesStatus(""));
    dispatch(SetAllBatteriesBusiness(""));
    dispatch(SetAllBatteriesIOT(""));
    dispatch(SetAllBatteriesPack(""));
    dispatch(SetAllBatteriesSeverity(""));

    resetTheFilterValues(props.pagesContent.filteredArray, navigate);
  }

  function handleBack() {
    let queryParams = decryptTheParams();
    navigate(`${queryParams["prevLinkAllBatteries"]}`)
  }

  function handleSetFilter(filterName, value) {
    setTheFilterValues(filterName, value, navigate);

    dispatch(SetAllBatteriesPageNumber(1));
    if (filterName === "State") {
      dispatch(SetAllBatteriesState(value));
    } else if (filterName === "Volt") {
      dispatch(SetAllBatteriesVolt(value));
    } else if (filterName === "City") {
      dispatch(SetAllBatteriesCity(value));
    } else if (filterName === "Zone") {
      dispatch(SetAllBatteriesZone(value));
    } else if (filterName === "IOT") {
      dispatch(SetAllBatteriesIOT(value));
    } else if (filterName === "Pack") {
      dispatch(SetAllBatteriesPack(value));
    } else if (filterName === "Status") {
      dispatch(SetAllBatteriesStatus(value));
    } else if (filterName === "Business") {
      dispatch(SetAllBatteriesBusiness(value));
    } else if (filterName === "Severity") {
      dispatch(SetAllBatteriesSeverity(value));
    }
  }

  function Heading() {
    return (
      <Box sx={{ display: "inline" }}>
        <Box>
          <Typography variant="textSubtitleBold">Advance Search</Typography>
        </Box>
      </Box>
    );
  }
  function FilterBox() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "felx-start",
          }}
        >
          <Typography
            variant="textSubtitle"
            sx={{
              pr: 1,
              borderRight: `1px solid ${ThemeProperties.greyTextColor}`,
            }}
          >
            Total Batteries : <b>{total}</b>
          </Typography>

          <Typography variant="textSubtitle" sx={{ pl: 1 }}>
            Selected Batteries : <b>{universal.arr.length}</b>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {resetButton && (
            <Button
              sx={{ fontSize: "12px", height: "30px", mt: 1.5, mr: 0.5 }}
              onClick={() => resetTheFilters()}
            >
              Reset
            </Button>
          )}
          {props.pagesContent.filtersWithData.map((item, index) => {
            
              return (
                <Box key={index}>
                  <GenericSelectTest
                    filter={item}
                    // [{key:"All",value:""},{key:"48V",value:48},{key:"60V",value:60}]
                    handleSetFilter={handleSetFilter}
                    selected={filters[item["itemName"]]} // All,48V,60V
                    maxWidth={"100px"}
                    showClearButton={true}
                    id="overflow-text-1"
                    pageName={"Advance Search"}
                  />
                </Box>
              );
            
          })}
          <Box
            sx={{
              border: `1px solid ${
                universal.arr.length > 0 ? ThemeProperties.purple : "#b0b0b0"
              }`,
              p: 0.4,
              borderRadius: "4px",
            }}
          >
            {universal.arr.length > 0 ? (
              <DownloadCSVBox
                onClick={() => {
                  const eventName = "Advance Search download csv";
                  Events(eventName);
                  CreateCSV();
                }}
              >
                <Box
                  sx={{ width: "200px", display: "flex", alignItems: "center" }}
                >
                  {mountedCSVLoader ? (
                    <Box sx={{ ml: 1,mr:1.5 }}>
                      <CircularProgress size="18px" />
                    </Box>
                  ) : (
                    <FileDownloadOutlinedIcon
                      sx={{
                        color: ThemeProperties.purple,
                        fontSize: "22px",
                        ml: 1,
                        mr: 1,
                      }}
                    />
                  )}
                  <Typography variant="textSubtitlePurple">
                    Download CSV
                  </Typography>
                </Box>
              </DownloadCSVBox>
            ) : (
              <DownloadCSVBox>
                <Box
                  sx={{ width: "500px", display: "flex", alignItems: "center" }}
                >
                  <FileDownloadOutlinedIcon
                    sx={{
                      color: ThemeProperties.greyTextColor,
                      fontSize: "22px",
                      ml: 1,
                      mr: 1,
                    }}
                  />
                  <Typography variant="textSubtitleGrey">
                    Download CSV
                  </Typography>
                </Box>
              </DownloadCSVBox>
            )}
            {/* <ConfigProvider
            theme={{
              token: {
                colorPrimary: ThemeProperties.purple,
              },
            }}
          >
            <Dropdown
              menu={{
                items,
              }}
              className="dropDowndDiv"
              // disabled
              open={open}
              onOpenChange={handleOnOpenChange}
              trigger={["click"]}
              dropdownRender={(menu) => (
                <div>
                  <div className="dropDown" style={contentStyle}>
                    <div className="dropDownTop">
                      <Typography variant="tableHead">
                        Select columns to show
                      </Typography>
                    </div>

                    <div className="dropDownMiddle">
                      {React.cloneElement(menu, {
                        style: menuStyle,
                      })}
                    </div>

                    <div className="dropDownBottom">
                      <AntButton onClick={() => onClickReset()}>
                        Reset
                      </AntButton>
                      <AntButton type="primary" onClick={() => onClickApply()}>
                        Apply
                      </AntButton>
                    </div>
                  </div>
                </div>
              )}
            >
              <Space>
                <Avatar
                  variant="square"
                  sx={{
                    bgcolor: "white",
                    border: "1px solid #cecece",
                    borderRadius: "4px",
                    width: 35,
                    height: 35,
                    mt: 0.5,
                    ml: 1.8,
                  }}
                >
                  <ViewWeekOutlinedIcon
                    onClick={handleOpenChange}
                    sx={{
                      cursor: "pointer",
                      fontSize: "25px",
                      color:
                        newColumns[0]["columns"].filter(
                          (item) => item.hidden === true
                        ).length > 0
                          ? ThemeProperties.purple
                          : ThemeProperties.greyTextColor,
                    }}
                  />
                </Avatar>
              </Space>
            </Dropdown>
          </ConfigProvider> */}
          </Box>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    showResetButton();
    setBatteriesInTable({
      batteries: [],
      responseStatus: {
        code: -1,
        msg: "",
      },
    });

    if (userInputBatteries.length > 0) {
      getKPIBatteries(
        severity,
        batteryState,
        volt,
        city,
        zone,
        batterystatus,
        iotVendor,
        packVendor,
        userInputBatteries,
        [],
        props.pagesContent.batteryType,
        pageNumber,
        sortChangedTo.sortBy,
        orderChangedTo.orderBy,
        rowsPerPage
      ).then((res) => {
        if (res.responseStatus.code === 200) {
          setBatteriesInTable({
            batteries: res.response.listOfBatteries,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
          setTotal(res.response.total.total);
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setBatteriesInTable({
            batteries: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
          setTotal(0);
        }
      });
    }
    else if (multipePartners.length>0) {
      getKPIBatteries(
        severity,
        batteryState,
        volt,
        city,
        zone,
        batterystatus,
        iotVendor,
        packVendor,
        [],
        multipePartners,
        props.pagesContent.batteryType,
        pageNumber,
        sortChangedTo.sortBy,
        orderChangedTo.orderBy,
        20
      ).then((res) => {
        if (res.responseStatus.code === 200) {
          setBatteriesInTable({
            batteries: res.response.listOfBatteries,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
          setTotal(res.response.total.total);
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          setBatteriesInTable({
            batteries: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
          setTotal(0);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // batteryPartnerSelect,
    // userInputBatteries,
    // multipePartners,
    window.location.search,
    batteryState,
    severity,
    volt,
    city,
    zone,
    batterystatus,
    iotVendor,
    packVendor,
    business,
    pageNumber,
    sortChangedTo.sortBy,
    orderChangedTo.orderBy,
    rowsPerPage,
  ]);

  return (
    <OuterBox>
      <InnerBox sx={{ minHeight: "800px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <IconButton onClick={handleBack} fontSize="small" sx={{ mr: 1.5 }}>
              <KeyboardBackspace
                fontSize="small"
                cursor="pointer"
                sx={{
                  fontWeight: 200,
                  color: ThemeProperties.purple,
                  fontSize: "18px",
                }}
              />
            </IconButton>
            {Heading()}
          </Box>
        </Box>
        <Paper
          elevation={0}
          sx={{
            height: "100%",
            borderRadius: "12px",
            p: 1,
            mt: 2.5,
            border: "1px solid #ececec",
          }}
        >
          {FilterBox()}
          <hr style={{ borderTop: "1px solid #ececec" }}></hr>
          <Box sx={{ mt: 1.5 }}>
            {/* {!noData ? ( */}
            <BasicTableAllBatteries
              data={batteriesInTable}
              columns={newColumns}
              existingBatteries={existingBatteries}
              rowSelection={true}
              total={total}
              // handleRequestPage={handleRequestPage}
              city={city}
              zone={zone}
              volt={volt}
              batterystatus={batterystatus}
              iotVendor={iotVendor}
              packVendor={packVendor}
              batteryState={batteryState}
              severity={severity}
              batteryID={userInputBatteries}
              partner={multipePartners}
              batteryType={props.pagesContent.batteryType}
            />
          </Box>
        </Paper>
      </InnerBox>
    </OuterBox>
  );
}

export default AllBatteriesMain;
