import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import { CustomTextField } from "../../Components/CustomTextField";
import { CustomAutocomplete } from "../../Components/CustomAutocomplete";
import { IconButton, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";

import {
  SetAllBatteriesMultiplePartners,
  SetAllBatteriesPageNumber,
} from "../../Actions/AllBatteries";
import { SetMapCity, SetMapMultiplePartners } from "../../Actions/Map";
import { SetSessionExpired } from "../../Actions";
import { getPartnerList } from "../../Api/AllBatteriesApi";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const icon = <CheckBoxOutlineBlankIcon sx={{ fontSize: "19px" }} />;
const checkedIcon = <CheckBoxIcon sx={{ fontSize: "19px" }} />;

function MultipeSearchCheckbox(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [partnerList, setPartnerList] = useState([]);

  const autoCompleteValue = useSelector(
    (state) => state.AllBatteriesMultiplePartners.value
  );
  const autoCompleteValueMap = useSelector(
    (state) => state.MapMultiplePartners.value
  );

  const [isOpen, setIsOpen] = React.useState(false);
  const [typedValue, setTypedValue] = useState("");

  const handleOpen = () => {
    if (typedValue.length > 0) setIsOpen(true);
  };

  const onTagsChange = (value) => {
    let temp;
    let queryParams = decryptTheParams();

    dispatch(SetAllBatteriesPageNumber(1));
    if (props.type === "Advance Search") {
      dispatch(SetAllBatteriesMultiplePartners(value));
    } else {
      dispatch(SetMapMultiplePartners(value));
      temp = {
        ...queryParams,
        multipleMapPartners: value,
      };
      encryptTheParams(temp, navigate);
    }
    // Events(props.type + " Searched Multiple Partners");
  };

  useEffect(() => {
    if (typedValue.length > 0) {
      getPartnerList("partner", typedValue, 10).then((res) => {
        if (res.responseStatus.code === 200) {
          setPartnerList(res.response.data);
          setIsOpen(true);

          // setBatteryList(res.response.data);
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          setPartnerList([]);
          // setBatteryList([]);
        }
      });
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedValue]);

  return (
    <CustomAutocomplete
      multiple
      className="multiple_Auto_Checkbox"
      options={partnerList && partnerList.map((option) => option)}
      disableCloseOnSelect
      limitTags={4}
      value={
        props.type === "Advance Search" ? autoCompleteValue : autoCompleteValueMap
      }
      getOptionLabel={(option) => option}
      onChange={(e, value) => onTagsChange(value)}
      onInputChange={(event, value) => {
        setTypedValue(value);
        // setErrorTextField(false);
      }}
      open={isOpen} // Manually control
      onOpen={handleOpen} // Manually control
      onClose={() => setIsOpen(false)} // Manually control
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            color={"primary"}
            label={
              <Typography
                sx={{
                  fontSize: 13,
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  color: "black",
                }}
              >
                {option}
              </Typography>
            }
            {...getTagProps({ index })}
          />
        ))
      }
      sx={{ width: "100%" }}
      ListboxProps={{
        sx: {
          fontSize: 13,
          fontWeight: 500,
          fontFamily: "Roboto",
          ml: 1,
          pr: -1,
          width: 230,
        },
      }}
      componentsProps={{
        paper: {
          sx: {
            width: 240,
          },
        },
      }}
      renderInput={(params) => {
        const { InputProps, ...restParams } = params;
        const { startAdornment, ...restInputProps } = InputProps;
        return (
          <CustomTextField
            {...restParams}
            label="Search multiple occupant"
            placeholder="Start typing an occupant name"
            InputProps={{
              ...restInputProps,
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  setIsOpen(false);
                  e.stopPropagation();
                }
              },
              startAdornment: (
                <div
                  style={{
                    maxHeight: 200,
                    overflowY: "auto",
                  }}
                >
                  {startAdornment}
                </div>
              ),
              endAdornment: (
                <IconButton
                  size="small"
                  onClick={() => {
                    if (props.type === "Advance Search") {
                      dispatch(SetAllBatteriesMultiplePartners([]));
                    } else {
                      dispatch(SetMapMultiplePartners([]));
                      // dispatch(SetMapCity(""));
                      // let queryParams = decryptTheParams();
                      // let temp = {
                      //   ...queryParams,
                      //   City: "",
                      // };
                      // encryptTheParams(temp, navigate);
                    }
                  }}
                  sx={{ mr: 0.5 }}
                >
                  <CloseIcon
                    fontSize="medium"
                    sx={{ color: "grey", fontSize: "18.5px" }}
                  />
                </IconButton>
              ),
            }}
          />
        );
      }}
    />
  );
}

export default MultipeSearchCheckbox;
