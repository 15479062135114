import React from "react";
import info from "../../Assets/Images/Common/Info.jpeg"
import { Typography, Box } from "@mui/material";
function Error(props) {
  return (
    <div className="mytable">
      <div className="cell">
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <img src={info} alt="not found" height={"110px"} />
        </Box>
        <Typography style={{ color: "#B2AFC3", marginTop: "10px", fontSize:"14px"  }}>
          Oops!! Server issue...please try again after some time{" "}
        </Typography>
      </div>
    </div>
  );
}

export default Error;
