import { convertZonetoUTCEnd, convertZonetoUTCStart } from "../Helper/DatePicker/DateConverter"
import { nonMatchingFilters } from "../Helper/Operations/GetFilterKeyValue";
import { GetData } from "./Functions/SettingData"
import qs from "qs";

const getMetricInfo = async (metricName, startDate, endDate, selectedFilters, defaultFilterValues) => {
  var config = {
    method: "get",
    url: `/api/charging_analytics/metrics_info`,
    headers: {
      "Content-Type": "application/json"
    },
    params: {
      metricName,
      startDate: convertZonetoUTCStart(startDate),
      endDate: convertZonetoUTCEnd(endDate),
      filters: nonMatchingFilters(selectedFilters, defaultFilterValues)
    },
  }

  let data = GetData(config)
  return data
}

const getMetricTrendData = async (metricName, startDate, endDate, granularity, selectedFilters, defaultFilterValues) => {
  var config = {
    method: "get",
    url: `/api/charging_analytics/metrics_trend`,
    headers: {
      "Content-Type": "application/json"
    },
    params: {
      metricName: Array.isArray(metricName) ? metricName : [metricName],
      granularity,
      startDate: convertZonetoUTCStart(startDate),
      endDate: convertZonetoUTCEnd(endDate),
      filters: JSON.stringify(nonMatchingFilters(selectedFilters, defaultFilterValues))
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  }

  let data = GetData(config)
  return data
}

export {
  getMetricInfo,
  getMetricTrendData
}
