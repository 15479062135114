import {
  Box,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
  TextField,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import ConfigFormCellDetails from "./ConfigurationForms/ConfigFormCellDetails";
import ConfigFormBatteryStructure from "./ConfigurationForms/ConfigFormBatteryStructure";
import ConfigFormPackDetails from "./ConfigurationForms/ConfigFormPackDetails";
import ConfigFormEnergyStorage from "./ConfigurationForms/ConfigFormEnergyStorage";
import ConfigFormIosDetails from "./ConfigurationForms/ConfigFormIosDetails";
import ConfigFormBMS from "./ConfigurationForms/ConfigFormBMS";

import DigitalTwinConfirmDialog from "../../Features/Dialog/DigitalTwin/DigitalTwinConfimDialog";
import { SecondaryButton, OutlinedButton } from "../../Components/CustomButton";

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { StyledListItemButton } from "../../Components/CustomListItemButton";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import "./Form.css";
import CODE from "../../Static/Constants/StatusCodes";
import {
  addConfig,
  getDigitalTwinConfigList,
  updateConfig,
} from "../../Api/DigitalTwinListApi";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { SetSessionExpired } from "../../Actions";
import { SetDigitalTwinsConfigurationType } from "../../Actions/DigitalTwinsList";
import { batteryConfigurations } from "../../Static/Data/DigitalTwinList/BatteryConfigurations";

function ConfigurationForm(props) {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = decryptTheParams();
  const search = queryParams["isEdit"] === "true" ? true : false;
  const isView = queryParams["isView"] === "true" ? true : false;
  const cookies = new Cookies();
  const configID = queryParams["configID"];
  const configName = queryParams["configName"];
  const applicationName = queryParams["application"] || batteryConfigurations[0].value;

  const batteryList = useSelector(
    (state) => state.DigitalTwinsListOfBatteries.value
  );

  const configurationType = useSelector(
    (state) => state.DigitalTwinsConfigurationType
  );

  const configFilters = useSelector(
    (state) => state.DigitalTwinsConfigFilters.value
  );

  const [selectedConfig, setSelectedConfig] = useState({});

  const [isEdit, setIsEdit] = useState(search && configID ? true : false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);

  const [showCellOEMTextField, setShowCellOEMTextField] = useState(false);
  const [showCellModelNumberTextField, setShowCellModelNumberTextField] =
    useState(false);
  const [showCellChemistryTextField, setShowCellChemistryTextField] =
    useState(false);
  const [showCellFormFactorTextField, setShowCellFormFactorTextField] =
    useState(false);
  const [showPackOEMTextField, setShowPackOEMTextField] = useState(false);
  const [showApplicationOEMTextField, setShowApplicationOEMTextField] =
    useState(false);
  const [showIotOEMTextField, setShowIotOEMTextField] = useState(false);
  const [showBmsOEMTextField, setShowBmsOEMTextField] = useState(false);

  const [form, setForm] = useState({
    cellOEM: "",
    cellModelNumber: "",
    cellChemistry: "",
    formFactorCell: "",
    buildingBlockType: "",
    iotOEM: "",
    applicationOEM: "",
    bmsOEM: "",
    application: configurationType.value,
    id: configID ?? null,
    configName: configurationType.name,
  });

  const [formValidated, setFormValidated] = useState({
    buildingBlockType: true,
    cellCount: true,
    moduleCount: true,
    packCount: true,
    seriesPacks: true,
    parallelPacks: true,
    seriesModulesInPack: true,
    parallelModulesInPack: true,
    seriesCellsInModule: true,
    parallelCellsInModule: true,
    applicationOEM: true,
    ratedCapacitySystem: true,
    ratedVoltageSystem: true,
    iotOEM: true,
    bmsOEM: true,
    packOEM: true,
    cellOEM: true,
    cellModelNumber: true,
    cellChemistry: true,
    cellFormFactor: true,
  });

  const convertableTextFields = {
    cellOEM: showCellOEMTextField,
    cellModelNumber: showCellModelNumberTextField,
    cellChemistry: showCellChemistryTextField,
    formFactorCell: showCellFormFactorTextField,
    packOEM: showPackOEMTextField,
    applicationOEM: showApplicationOEMTextField,
    iotOEM: showIotOEMTextField,
    bmsOEM: showBmsOEMTextField,
  };

  const [listOfBatteries, setListOfBatteries] = useState({
    batteries: [],
  });

  useEffect(() => {
    if (isEdit) {
      GetConfigList();
    }
  }, [isEdit]);

  useEffect(() => {
    const batteryConfig = batteryConfigurations?.find(item => item?.value === applicationName)
    dispatch(SetDigitalTwinsConfigurationType(batteryConfig?.value || batteryConfigurations[0].value));
  }, [])

  useEffect(() => {
    if (isEdit && listOfBatteries?.batteries?.length > 0) {
      let selected = listOfBatteries?.batteries?.find((x) => x.id == configID);
      setSelectedConfig(selected);

      setForm({
        ...selected,
        buildingBlockType: selected?.buildingBlockType === "pc" ? "pc" : "pmc",
        application: selected?.application || batteryConfigurations[0].value,
      });
    }
  }, [isEdit, listOfBatteries]);

  const GetConfigList = async () => {
    await getDigitalTwinConfigList({
      filters: {configName},
      pageNumber: 1,
      rowsPerPage: 10,
      sortBy: "id",
      orderBy: "asc",
    }).then((res) => {
      if (res.responseStatus.code === 200) {
        setListOfBatteries({
          batteries: res.response.data,
          responseStatus: res.responseStatus,
        });
        return res?.response?.data;
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
      }
    });
  };

  const handleClickNext = (e) => {
    e.preventDefault();
    // Validation
    const invalidArr = validateForm();
    if (invalidArr.length === 0) {
      if (selectedIndex < 6) {
        setSelectedIndex(selectedIndex + 1);
      } else {
        setShowSaveDialog(true);
      }
    }
  };

  const handleClickEdit = () => {
    setIsEdit(true);
    let temp = {
      ...queryParams,
      isEdit: true,
      isView: false,
    };
    encryptTheParams(temp, navigate, false, "/DigitalTwin/add-config");
  };

  const validateForm = () => {
    let step = configSteps.find((x) => x.id === selectedIndex);
    let invalidArr = [];
    if (step && step.validationFields) {
      invalidArr = step?.validationFields?.reduce((acc, curr, index) => {
        const ingnoreValidation =
          (curr === "seriesModulesInPack" ||
            curr === "parallelModulesInPack" ||
            curr === "moduleCount") &&
          form["buildingBlockType"] === "pc";
        if ((!form[curr] || form[curr] == 0) && !ingnoreValidation) {
          if (index === 0) {
            acc = { [curr]: false };
          } else {
            acc = { ...acc, [curr]: false };
          }
        }

        return acc;
      }, []);
    }
    if (step && step.existsValidation) {
      step.existsValidation.forEach((field) => {
        if (convertableTextFields[field]) {
          if (!formValidated[field]) {
            invalidArr = { ...invalidArr, [field]: false };
          }
        }
      });
    }
    setFormValidated({ ...formValidated, ...invalidArr });
    return invalidArr;
  };

  const handleClickBack = () => {
    if (selectedIndex > 1) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const handleDiscardToDropdown = (name) => {
    switch (name) {
      case "cellOEM":
        setShowCellOEMTextField(false);
        break;
      case "cellModelNumber":
        setShowCellModelNumberTextField(false);
        break;
      case "cellChemistry":
        setShowCellChemistryTextField(false);
        break;
      case "formFactorCell":
        setShowCellFormFactorTextField(false);
        break;
      case "packOEM":
        setShowPackOEMTextField(false);
        break;
      case "applicationOEM":
        setShowApplicationOEMTextField(false);
        break;
      case "iotOEM":
        setShowIotOEMTextField(false);
        break;
      case "bmsOEM":
        setShowBmsOEMTextField(false);
        break;
      default:
        break;
    }
    setForm({ ...form, [name]: "" });
    setFormValidated({ ...formValidated, [name]: true });
  };

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    let convertables = Object.keys(convertableTextFields);
    if (value === "%add%" && convertables.includes(name)) {
      setForm({ ...form, [e.target.name]: "" });
      switch (name) {
        case "cellOEM":
          setShowCellOEMTextField(true);
          break;
        case "cellModelNumber":
          setShowCellModelNumberTextField(true);
          break;
        case "cellChemistry":
          setShowCellChemistryTextField(true);
          break;
        case "formFactorCell":
          setShowCellFormFactorTextField(true);
          break;
        case "packOEM":
          setShowPackOEMTextField(true);
          break;
        case "applicationOEM":
          setShowApplicationOEMTextField(true);
          break;
        case "iotOEM":
          setShowIotOEMTextField(true);
          break;
        case "bmsOEM":
          setShowBmsOEMTextField(true);
          break;
        default:
          break;
      }
      setFormValidated({ ...formValidated, [name]: true });
    } else {
      if (
        !isEdit &&
        (name === "packCount" || name === "moduleCount" || name === "cellCount")
      ) {
        setForm({
          ...form,
          configName: generateConfigName(name, value),
          [name]: value,
        });
        setFormValidated({ ...formValidated, [name]: true });
      } else {
        if (name === "buildingBlockType" && value === "pc") {
          setForm({
            ...form,
            [name]: value,
            seriesModulesInPack: 1,
            parallelModulesInPack: 1,
            moduleCount: 1,
          });
        } else {
          setForm({ ...form, [name]: value });
        }
        let isFieldConverted = convertableTextFields[name];
        if (isFieldConverted) {
          const valueExists = configFilters
            .find((c) => c.type === name)
            ?.value?.includes(value);
          if (valueExists) {
            setFormValidated({ ...formValidated, [name]: false });
            return;
          }
        }
        setFormValidated({ ...formValidated, [name]: true });
      }
    }
  };

  const generateConfigName = (name, value) => {
    let configName;
    const addModule = form?.buildingBlockType !== "pc";
    switch (name) {
      case "packCount":
        configName = addModule
          ? `${configurationType.value}-${value}-${form?.moduleCount}-${form?.cellCount}`
          : `${configurationType.value}-${value}-${form?.cellCount}`;
        break;
      case "moduleCount":
        configName = `${configurationType.value}-${form?.packCount}-${value}-${form?.cellCount}`;
        break;
      case "cellCount":
        configName = addModule
          ? `${configurationType.value}-${form?.packCount}-${form?.moduleCount}-${value}`
          : `${configurationType.value}-${form?.packCount}-${value}`;
        break;
    }
    return configName;
  };

  const configSteps = [
    {
      id: 1,
      name: "Battery Structure",
      component: (
        <ConfigFormBatteryStructure
          isEdit={isEdit ? isEdit && !isView : true}
          selectedConfig={selectedConfig}
          form={form}
          handleChangeSelect={handleChangeSelect}
          formValidated={formValidated}
        />
      ),
      validationFields: [
        "buildingBlockType",
        "cellCount",
        "packCount",
        "moduleCount",
        "seriesCellsInModule",
        "parallelCellsInModule",
        "seriesModulesInPack",
        "parallelModulesInPack",
        "seriesPacks",
        "parallelPacks",
      ],
    },
    {
      id: 2,
      name: "Cell details",
      component: (
        <ConfigFormCellDetails
          isEdit={isEdit ? isEdit && !isView : true}
          selectedConfig
          form={form}
          formValidated={formValidated}
          handleChangeSelect={handleChangeSelect}
          showCellChemistryTextField={showCellChemistryTextField}
          showCellFormFactorTextField={showCellFormFactorTextField}
          showCellOEMTextField={showCellOEMTextField}
          showCellModelNumberTextField={showCellModelNumberTextField}
          handleDiscardToDropdown={handleDiscardToDropdown}
        />
      ),
      existsValidation: [
        "cellOEM",
        "cellModelNumber",
        "cellChemistry",
        "formFactorCell",
      ],
    },
    {
      id: 3,
      name: "Pack details",
      component: (
        <ConfigFormPackDetails
          isEdit={isEdit ? isEdit && !isView : true}
          selectedConfig={selectedConfig}
          form={form}
          handleChangeSelect={handleChangeSelect}
          showPackOEMTextField={showPackOEMTextField}
          formValidated={formValidated}
          handleDiscardToDropdown={handleDiscardToDropdown}
        />
      ),
      validationFields: ["packOEM"],
      existsValidation: ["packOEM"],
    },
    {
      id: 4,
      name: configurationType.name,
      component: (
        <ConfigFormEnergyStorage
          isEdit={isEdit ? isEdit && !isView : true}
          selectedConfig={selectedConfig}
          form={form}
          handleChangeSelect={handleChangeSelect}
          showApplicationOEMTextField={showApplicationOEMTextField}
          handleDiscardToDropdown={handleDiscardToDropdown}
          formValidated={formValidated}
        />
      ),
      validationFields: [
        "applicationOEM",
        "ratedCapacitySystem",
        "ratedVoltageSystem",
      ],
      existsValidation: ["applicationOEM"],
    },
    {
      id: 5,
      name: "IoT details",
      component: (
        <ConfigFormIosDetails
          isEdit={isEdit ? isEdit && !isView : true}
          selectedConfig={selectedConfig}
          form={form}
          handleChangeSelect={handleChangeSelect}
          showIotOEMTextField={showIotOEMTextField}
          handleDiscardToDropdown={handleDiscardToDropdown}
          formValidated={formValidated}
        />
      ),
      validationFields: ["iotOEM"],
      existsValidation: ["iotOEM"],
    },
    {
      id: 6,
      name: "BMS",
      component: (
        <ConfigFormBMS
          isEdit={isEdit ? isEdit && !isView : true}
          selectedConfig={selectedConfig}
          form={form}
          handleChangeSelect={handleChangeSelect}
          showBmsOEMTextField={showBmsOEMTextField}
          handleDiscardToDropdown={handleDiscardToDropdown}
          formValidated={formValidated}
        />
      ),
      validationFields: ["bmsOEM"],
      existsValidation: ["bmsOEM"],
    },
  ];

  const handleSubmit = async () => {
    let response;
    if (isEdit) {
      response = await updateConfig(form);
      if (response?.responseStatus?.code === CODE.SUCCESS) {
        setShowSaveDialog(false);
        navigate("/DigitalTwin");
      }
    } else {
      response = await addConfig(form);
      if (response?.responseStatus?.code === CODE.URL_CREATED) {
        setShowSaveDialog(false);
        navigate("/DigitalTwin");
      }
    }
  };

  return (
    <Grid
      container
      spacing={1}
      columns={14}
      sx={{
        "--Grid-borderWidth": "1px",
        borderTop: "var(--Grid-borderWidth) solid",
        borderLeft: "var(--Grid-borderWidth) solid",
        borderColor: "divider",
        "& > div": {
          borderRight: "var(--Grid-borderWidth) solid",
          borderBottom: "var(--Grid-borderWidth) solid",
          borderColor: "divider",
        },
      }}
    >
      {/* HEADINGS */}
      <Grid fontSize={16} item xs={3}>
        <p style={{ fontWeight: "bold", marginLeft: 10 }}>Steps</p>
      </Grid>
      <Grid
        fontSize={16}
        item
        xs={11}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <p style={{ fontWeight: "bold", marginLeft: 10 }}>
          Define {configSteps.find((x) => x.id === selectedIndex)?.name}
        </p>
        {isView && props?.permissions?.includes("edit-config") && (
          <Box paddingRight={3}>
            <SecondaryButton
              variant="outlined"
              onClick={handleClickEdit}
              sx={{ fontSize: "small", display: "flex", gap: 1 }}
            >
              <EditOutlinedIcon />
              Edit
            </SecondaryButton>
          </Box>
        )}
      </Grid>

      {/* STEPS */}
      <Grid item xs={3}>
        <Box sx={{ width: "98%", bgcolor: "background.paper" }}>
          <List component="step" aria-label="add-config-steps">
            {configSteps.map((step) => (
              <StyledListItemButton
                myColor={ThemeProperties.purpleSelected}
                selected={selectedIndex === step.id}
                selectedTextColor={ThemeProperties.black}
              >
                <ListItemIcon style={{ minWidth: "3rem" }}>
                  <CheckCircleIcon
                    color={
                      selectedIndex > step.id || isEdit ? "success" : "disabled"
                    }
                    className="svg_icons"
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: 15,
                  }}
                  primary={step.name}
                />
              </StyledListItemButton>
            ))}
          </List>
        </Box>
      </Grid>

      {/* FORM */}
      <Grid fontSize={15} item xs={11}>
        <form onSubmit={handleClickNext}>
          {configSteps.map((step) =>
            selectedIndex === step.id ? <>{step.component}</> : <></>
          )}
          <Box
            display="flex"
            flexDirection="row"
            paddingBottom={2}
            gap={2}
            ml={3}
          >
            {selectedIndex > 1 && (
              <SecondaryButton
                variant="outlined"
                onClick={handleClickBack}
                sx={{ fontSize: "small" }}
              >
                Back
              </SecondaryButton>
            )}
            {!(isView && selectedIndex === 6) && (
              <SecondaryButton
                variant="contained"
                // onClick={handleClickNext}
                sx={{ fontSize: "small" }}
                type="submit"
              >
                {selectedIndex < 6
                  ? isEdit && !isView
                    ? "Save & Next"
                    : "Next"
                  : "Save"}
              </SecondaryButton>
            )}
          </Box>
        </form>
      </Grid>

      <DigitalTwinConfirmDialog
        open={showSaveDialog}
        onConfirm={handleSubmit}
        handleCloseDialog={() => setShowSaveDialog(false)}
        message={
          !isEdit ? (
            <Box display="flex" gap={1} alignItems="center">
              <>Create Config Name</>
              <TextField
                size="small"
                value={form?.configName}
                onChange={handleChangeSelect}
                name="configName"
                inputProps={{
                  style: { fontSize: 14, padding: 7, marginLeft: 3 },
                }}
              />
            </Box>
          ) : (
            "Update Details"
          )
        }
        confirmButtonText="Save"
        confirmButtonColor="primary"
        discardButtonText="Cancel"
      />
    </Grid>
  );
}

export default ConfigurationForm;
