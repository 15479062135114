

const inititalColumns = {
  value:[],
}

const inititalHiddenColumns = {
  value:[],
}
const initialIsBatteryIsPartnerIsNullAnomaly = {
  value: null,
};
const initialBatteryID = {
  value: null,
};
const initialPartner = {
  value: null,
};

const initialVolt = {
  value: "",
};
const initialCell = {
  value: "",
};
const initialCity = {
  value: "",
};
const initialStatus = {
  value: "",
};

const initialZone = {
  value: "",
};

const initialVersion = {
  value: "",
};


const initialTab = {
  value: 0,
};
const initialAnomalyIndex = {
  value: 0,
};
const initialAnomalyCode = {
  value: "",
};
const initialTotal = {
  value:0
}
const initialStartDate = {
    value:""
}
const initialEndDate = {
    value:""
}
const initialAnomalyList = {
    value:[]
}
const initialAnomalyListOfBatteries = {
    value:[]
}
const initialBuildNo = {
  value: []
};

const initialAnomalyStack = {
  arr: [],
};

const initialAnomalyColumnSort = {
  arr: [
    {
      sortBy: "anomalySeen",
    },
    {
      sortBy: "expiredTime",
    }
  ],
  // orderBy: "asc"
};

const initialPageNumber = {
value: 1,
};

const initialRowsPerPage = {
  value: 20,
};

const AnomalyColumns = (
  state = inititalColumns,
  action
) => {
  switch (action.type) {
    case "AnomalyColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const AnomalyHiddenColumns = (
  state = inititalHiddenColumns,
  action
) => {
  switch (action.type) {
    case "AnomalyHiddenColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};
const IsBatteryIsPartnerIsNullAnomaly = (
  state = initialIsBatteryIsPartnerIsNullAnomaly,
  action
) => {
  switch (action.type) {
    case "IsBatteryIsPartnerIsNullAnomaly":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const AnomalyBatteryID = (state = initialBatteryID, action) => {
  switch (action.type) {
    case "AnomalyBatteryID":
      return {
        value: action.batteryID,
      };
    default:
      return state;
  }
};

const AnomalyPartner = (state = initialPartner, action) => {
  switch (action.type) {
    case "AnomalyPartner":
      return {
        value: action.partner,
      };
    default:
      return state;
  }
};

const AnomalyVolt = (state = initialVolt, action) => {
  switch (action.type) {
    case "AnomalyVolt":
      return {
        value: action.volt,
      };
    default:
      return state;
  }
};
const AnomalyCell = (state = initialCell, action) => {
  switch (action.type) {
    case "AnomalyCell":
      return {
        value: action.cell,
      };
    default:
      return state;
  }
};
const AnomalyCity = (state = initialCity, action) => {
  switch (action.type) {
    case "AnomalyCity":
      return {
        value: action.city,
      };
    default:
      return state;
  }
};
const AnomalyZone = (state = initialZone, action) => {
  switch (action.type) {
    case "AnomalyZone":
      return {
        value: action.zone,
      };
    default:
      return state;
  }
};
const AnomalyVersion = (state = initialVersion, action) => {
  switch (action.type) {
    case "AnomalyVersion":
      return {
        value: action.version,
      };
    default:
      return state;
  }
};
const AnomalyBuildNo  = (state = initialBuildNo, action) => {
  switch (action.type) {
    case "AnomalyBuildNo":
      return {
        // ...state,
        value: action.payload,
      };
    default:
      return state;
  }
};
const AnomalyStatus = (state = initialStatus, action) => {
  switch (action.type) {
    case "AnomalyStatus":
      return {
        value: action.status,
      };
    default:
      return state;
  }
};
const AnomalyTabValue = (state = initialTab, action) => {
  switch (action.type) {
    case "AnomalyTabValue":
      return {
        value: action.tab,
      };
    default:
      return state;
  }
};

const AnomalyIndex = (
  state = initialAnomalyIndex,
  action
) => {
  switch (action.type) {
    case "AnomalyIndex":
      return {
        value: action.index,
      };
    default:
      return state;
  }
};

const AnomalyCode = (
  state = initialAnomalyCode,
  action
) => {
  switch (action.type) {
    case "AnomalyCode":
      return {
        value: action.code,
      };
    default:
      return state;
  }
};

const AnomalyTotal = (state = initialTotal, action) => {
    switch (action.type) {
      case "AnomalyTotal":
        return {
          value: action.total,
        };
      default:
        return state;
    }
  };
const AnomalyStartDate  = (state = initialStartDate, action) => {
    switch (action.type) {
      case "AnomalyStartDate":
        return {
          value: action.start,
        };
      default:
        return state;
    }
  };
  const AnomalyEndDate  = (state = initialEndDate, action) => {
    switch (action.type) {
      case "AnomalyEndDate":
        return {
          value: action.end,
        };
      default:
        return state;
    }
  };
  const AnomalyList  = (state = initialAnomalyList, action) => {
    switch (action.type) {
      case "AnomalyList":
        return {
          value: action.list,
        };
      default:
        return state;
    }
  };
  const AnomalyListOfBatteries  = (state = initialAnomalyListOfBatteries, action) => {
    switch (action.type) {
      case "AnomalyListOfBatteries":
        return {
          value: action.list,
        };
      default:
        return state;
    }
  };

  const AnomalyStack = (state = initialAnomalyStack, action) => {
      
    switch (action.type) {
      case "AnomalyStack":
        return {
          ...state,
          arr: action.batteries,
      };
      default:
        return state;
    }
  };

  const AnomalyColumnSort = (state = initialAnomalyColumnSort, action) => {
      
    switch (action.type) {
      case "AnomalyColumnSort":
        return {
          ...state,
          arr: action.sort,
      };
      default:
        return state;
    }
  };

  const AnomalyPageNumber = (state = initialPageNumber, action) => {
    switch (action.type) {
      case "AnomalyPageNumber":
        return {
          value: action.page,
        };
      default:
        return state;
    }
  };

  const AnomalyRowsPerPage = (state = initialRowsPerPage, action) => {
    switch (action.type) {
      case "AnomalyRowsPerPage":
        return {
          value: action.rows,
        };
      default:
        return state;
    }
  };

export {
  AnomalyColumns,
  AnomalyHiddenColumns,
  IsBatteryIsPartnerIsNullAnomaly,
  AnomalyBatteryID,
  AnomalyPartner,
  AnomalyVolt,
  AnomalyCell,
  AnomalyCity,
  AnomalyZone,
  AnomalyBuildNo,
  AnomalyVersion,
  AnomalyStatus,
  AnomalyTabValue,
  AnomalyIndex,
  AnomalyCode,
  AnomalyTotal,
  AnomalyStartDate,
  AnomalyEndDate,
  AnomalyList,
  AnomalyListOfBatteries,
  AnomalyStack,
  AnomalyColumnSort,
  AnomalyPageNumber,
  AnomalyRowsPerPage
};
