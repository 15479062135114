const dashboardClickablekpis = [
  {
    name: "All Batteries",
  },
];
const dashboardNonClickablekpis = [
  {
    name: "Total Batteries Connected",
  }
];
export { dashboardClickablekpis, dashboardNonClickablekpis };
