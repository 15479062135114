import * as React from "react";
const DocumentIcon = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="None"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.4999 1.5H4.49999C4.10217 1.5 3.72064 1.65803 3.43934 1.93934C3.15803 2.22064 3 2.60216 3 2.99999V14.9999C3 15.3977 3.15803 15.7792 3.43934 16.0605C3.72064 16.3418 4.10217 16.4999 4.49999 16.4999H13.4999C13.8977 16.4999 14.2793 16.3418 14.5606 16.0605C14.8419 15.7792 14.9999 15.3977 14.9999 14.9999V5.99996L10.4999 1.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.0007 12.7495H6" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.0007 9.74951H6" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.50017 6.74951H6.75009H6" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 1.49951V6.00003H15.0005" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
DocumentIcon.defaultProps = {
  width: "18",
  height: "18",
  viewBox: "0 0 18 18",
  fill: null,
  stroke: "#444B51",
};
export default DocumentIcon;

