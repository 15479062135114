export const SetAlertAnalyticsStartDate = (start) => {
  return {
    type: "AlertAnalyticsStartDate",
    start: start,
  };
};

export const SetAlertAnalyticsEndDate = (end) => {
  return {
    type: "AlertAnalyticsEndDate",
    end: end,
  };
};