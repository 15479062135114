import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import { Typography, Box } from "@mui/material";
import { Brightness1 } from "@mui/icons-material";
import { useDispatch } from "react-redux";

import {
  DateFormatString,
  monthDictionary,
} from "../../Helper/DatePicker/DateFormatters";
import {
  convertToZoneDate,
  getChartTimeStamp,
} from "../../Helper/DatePicker/DateConverter";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { SetSessionExpired } from "../../Actions";
import { formatDateWithoutYear } from "../../Helper/DatePicker/DateFormatters";

import Loader from "../../Helper/Loaders/Loader";
import "./ApexMultiYAxis.css";
import "./charts.css";
import {
  getChargerGranularityData,
  getChargerVitals,
} from "../../Api/DigitalTwin";

function Apex3YAxis(props) {
  const dispatch = useDispatch();

  const statusClicked = props.statusClicked;
  const setStatusClicked = props.setStatusClicked;

  const [batteryPackMetrics, setBatteryPackMetrics] = useState({
    temperature: [],
    timestamp: [],
    voltage: [],
    current: [],

    responseStatus: { code: -1, msg: "" },
  });

  const [chartState, setChartState] = useState({
    text: "",
    series: [],
    options: {},
  });

  const [mounted, setMounted] = useState(false);

  function handleStatusClicked(status) {
    if (
      statusClicked.Voltage &&
      statusClicked.Current &&
      statusClicked.Temperature
    ) {
      if (status === "Voltage (V)") {
        setStatusClicked({
          Voltage: statusClicked.Voltage,
          Current: !statusClicked.Current,
          Temperature: !statusClicked.Temperature,
        });
      } else if (status === "Current (A)") {
        setStatusClicked({
          Voltage: !statusClicked.Voltage,
          Current: statusClicked.Current,
          Temperature: !statusClicked.Temperature,
        });
      } else if (status === "Power (W)") {
        setStatusClicked({
          Voltage: !statusClicked.Voltage,
          Current: !statusClicked.Current,
          Temperature: statusClicked.Temperature,
        });
      }
    } else {
      if (status === "Voltage (V)") {
        if (statusClicked.Current || statusClicked.Temperature)
          setStatusClicked({
            Voltage: !statusClicked.Voltage,
            Current: statusClicked.Current,
            Temperature: statusClicked.Temperature,
          });
      } else if (status === "Current (A)") {
        if (statusClicked.Voltage || statusClicked.Temperature)
          setStatusClicked({
            Voltage: statusClicked.Voltage,
            Current: !statusClicked.Current,
            Temperature: statusClicked.Temperature,
          });
      } else if (status === "Power (W)") {
        if (statusClicked.Voltage || statusClicked.Current)
          setStatusClicked({
            Voltage: statusClicked.Voltage,
            Current: statusClicked.Current,
            Temperature: !statusClicked.Temperature,
          });
      }
    }
  }

  function Legends() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 1,
        }}
      >
        {[
          {
            status: "Current (A)",
            color: ThemeProperties.Current,
            target: statusClicked.Current,
          },
          {
            status: "Voltage (V)",
            color: ThemeProperties.Voltage,
            target: statusClicked.Voltage,
          },
          {
            status: "Power (W)",
            color: ThemeProperties.Power,
            target: statusClicked.Temperature,
          },
        ].map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => handleStatusClicked(item.status)}
          >
            <Brightness1
              style={{
                fontSize: "14px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: item.color,
                opacity: item.target ? 1 : 0.4,
              }}
            />

            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Roboto",
                fontWeight: 400,
                color: "#22105C",
                opacity: item.target ? 1 : 0.6,
              }}
            >
              &nbsp;{item.status}&nbsp;&nbsp;
            </Typography>
          </div>
        ))}
      </Box>
    );
  }

  function mountTheChart(current, voltage, temperature, timestamp) {
    var my_date = DateFormatString(props.startDate);

    console.log(timestamp);

    const new_series = [
      {
        name: "Current (A)",
        data: [],
      },
      {
        name: "Voltage (V)",
        data: [],
      },
      {
        name: "Temperature (°C)",
        data: [],
      },
    ];

    if (statusClicked.Current) new_series[0]["data"] = current;
    // if (!statusClicked.Current) new_series[0]["data"] = [null];

    if (statusClicked.Voltage) new_series[1]["data"] = voltage;
    // if (!statusClicked.Voltage) new_series[1]["data"] = [null];

    if (statusClicked.Temperature) new_series[2]["data"] = temperature;
    // if (!statusClicked.Temperature) new_series[2]["data"] = [null];

    setChartState({
      text: "",
      series: new_series,
      options: {
        chart: {
          animations: {
            enabled: false,
          },
          type: "line",
          zoom: {
            enabled: true,
            type: "x",
            minZoom: 10,
          },
          resetIcon: {
            offsetX: -10,
            offsetY: 5,
            fillColor: "#fff",
            strokeColor: "#37474F",
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ",",
                headerCategory: "category",
                headerValue: "value",
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              },
            },
            autoSelected: "zoom",
            // autoSelected: false,
          },
          events: {
            beforeZoom: function (chart, { xaxis }) {
              if (props.granular === "Raw Data") {
                const MIN_ZOOM_RANGE = 15 * 60 * 1000; // 15 minutes in milliseconds
                let min = xaxis.min;
                let max = xaxis.max;

                if (max - min >= 86400000) {
                  return {
                    xaxis: {
                      tickPlacement: "on",
                      min: new Date(props.startDate).getTime() - 19800000,
                      max:
                        new Date(props.startDate).getTime() +
                        86400000 -
                        19800000,
                      labels: {
                        format: "h TT",
                      },
                    },
                  };
                }

                if (max - min < MIN_ZOOM_RANGE) {
                  let newmax = min + MIN_ZOOM_RANGE;

                  return {
                    xaxis: {
                      tickPlacement: "on",
                      min: min,
                      max: newmax,
                      labels: {
                        format: "h:mm TT",
                      },
                    },
                  };
                } else {
                  return {
                    xaxis: {
                      tickPlacement: "on",
                      min: min,
                      max: max,
                      labels: {
                        format: "h:mm TT",
                      },
                    },
                  };
                }
              }
            },

            beforeResetZoom: function (chartContext, opts) {
              if (props.granular === "Raw Data") {
                return {
                  xaxis: {
                    tickPlacement: "on",
                    min: new Date(props.startDate).getTime() - 19800000,
                    max:
                      new Date(props.startDate).getTime() + 86400000 - 19800000,
                    labels: {
                      format: "h TT",
                    },
                  },
                };
              }
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2.5,
          curve: "smooth",
          // dashArray: [0, 2, 5, 8],
        },
        grid: {
          padding: {
            top: 10,
            left: -26,
            right: -21,
          },
        },
        title: {
          text: undefined,
        },
        colors: [
          ThemeProperties.Current,
          ThemeProperties.Voltage,
          ThemeProperties.Power,
        ],
        markers: {
          size: 0,
          strokeWidth: 0,
          hover: {
            size: 4,
            sizeOffset: 1,
          },
        },
        yaxis: [
          {
            seriesName: "Current (A)",
            axisTicks: {
              show: true,
              borderType: "solid",
              color: ThemeProperties.c_axis_labels_color,
              offsetX: 3,
            },
            min: 0,
            max: 25,
            tickAmount: 5,
            axisBorder: {
              show: true,
              color: statusClicked.Current
                ? ThemeProperties.c_axis_labels_color
                : ThemeProperties.grey,
              offsetX: -3,
            },
            labels: {
              style: {
                colors: statusClicked.Current
                  ? [ThemeProperties.c_axis_labels_color]
                  : [ThemeProperties.grey],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value);
              },
              offsetX: -36,
            },
            title: {
              text: "Current (A)",
              offsetX: 4,
              style: {
                color: statusClicked.Current
                  ? ThemeProperties.Current
                  : "rgba(79, 68, 224,0.2)",
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          {
            seriesName: "Voltage (V)",
            axisTicks: {
              show: true,
              borderType: "solid",
              color: ThemeProperties.c_axis_labels_color,
            },
            min: 0,
            max: 100,
            tickAmount: 5,
            axisBorder: {
              show: true,
              color: statusClicked.Voltage
                ? ThemeProperties.c_axis_labels_color
                : ThemeProperties.grey,
              // offsetX:-10
            },
            labels: {
              style: {
                colors: statusClicked.Voltage
                  ? [ThemeProperties.c_axis_labels_color]
                  : [ThemeProperties.grey],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value);
              },
              offsetX: -28,
            },
            title: {
              text: "Voltage (V)",
              offsetX: 2,
              style: {
                color: statusClicked.Voltage
                  ? ThemeProperties.Voltage
                  : "rgba(255, 117, 87,0.2)",
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
            },
            tooltip: {
              enabled: false,
            },
          },
          {
            seriesName: "Temperature (°C)",
            max: 400,
            min: 0,
            tickAmount: 5,
            opposite: true,
            axisTicks: {
              show: true,
              borderType: "solid",
              color: ThemeProperties.c_axis_labels_color,
            },
            axisBorder: {
              show: true,
              color: statusClicked.Temperature
                ? ThemeProperties.c_axis_labels_color
                : ThemeProperties.grey,
            },
            labels: {
              style: {
                colors: statusClicked.Temperature
                  ? [ThemeProperties.c_axis_labels_color]
                  : [ThemeProperties.grey],
                fontSize: ThemeProperties.c_axis_labels,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_labels_weight,
              },
              formatter: function (value) {
                return Math.round(value);
              },
              offsetX: -31,
            },
            title: {
              text: "Power (W)",
              style: {
                color: statusClicked.Temperature
                  ? ThemeProperties.Power
                  : "rgba(34, 144, 135,0.2)",
                fontSize: ThemeProperties.c_axis_title,
                fontFamily: "Roboto",
                fontWeight: ThemeProperties.c_axis_title_weight,
              },
              offsetX: -3.5,
            },
          },
        ],
        xaxis: {
          type: props.granular === "Raw Data" ? "datetime" : "category",
          // tickAmount: 12,
          tickPlacement: "on",
          categories: timestamp,
          tooltip: {
            enabled: false,
          },
          title: {
            text: undefined,
          },
          labels: {
            show: true,
            datetimeUTC: false,
            rotateAlways: false,
            rotate: 0,
            // align: "center",
            style: {
              colors: ThemeProperties.c_axis_labels_color,
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            offsetX: 0,
            offsetY: 0,
            format: props.granular === "Raw Data" ? "h TT" : undefined,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: ThemeProperties.c_axis_labels_color,
          },
          axisBorder: {
            show: true,
            color: ThemeProperties.c_axis_labels_color,
            height: 0.8,
          },

          min:
            props.granular === "Raw Data"
              ? new Date(my_date).getTime()
              : undefined,
          max:
            props.granular === "Raw Data"
              ? new Date(my_date).getTime() + 86400000
              : undefined,
        },
        tooltip: {
          followCursor: true,

          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            console.log(w.globals.lastXAxis);
            var data =
              props.granular === "Raw Data"
                ? moment(w.globals.lastXAxis.categories[dataPointIndex]).format(
                    "DD MMM'YY, hh:mm:ss A"
                  )
                : w.globals.lastXAxis.categories[dataPointIndex];

            let current, volt, temp;
            let currentClass, voltClass, tempClass;
            let currentFlexClass, voltFlexClass, tempFlexClass;
            let currentDotClass, voltDotClass, tempDotClass;
            let currentBrClass, voltBrClass, tempBrClass;

            if (series[0][dataPointIndex] === undefined) {
              current = "- ";
              currentClass = "displaying_None";
              currentDotClass = "displaying_None";
              currentFlexClass = "displaying_None";
              currentBrClass = "displaying_Br_None";
            } else {
              current =
                series[0][dataPointIndex] !== null
                  ? series[0][dataPointIndex].toFixed(2)
                  : " ";
              currentClass = "text_container";
              currentDotClass = "dot_purple";
              currentFlexClass = "flex_center";
              currentBrClass = "displaying_Br";
            }
            if (series[1][dataPointIndex] === undefined) {
              volt = "- ";
              voltClass = "displaying_None";
              voltDotClass = "displaying_None";
              voltFlexClass = "displaying_None";
              voltBrClass = "displaying_Br_None";
            } else {
              volt =
                series[1][dataPointIndex] !== null
                  ? series[1][dataPointIndex].toFixed(3)
                  : " ";
              voltClass = "text_container";
              voltDotClass = "dot_red";
              voltFlexClass = "flex_center";
              voltBrClass = "displaying_Br";
            }
            if (series[2][dataPointIndex] === undefined) {
              temp = "- ";
              tempClass = "displaying_None";
              tempDotClass = "displaying_None";
              tempFlexClass = "displaying_None";
              tempBrClass = "displaying_Br_None";
            } else {
              temp =
                series[2][dataPointIndex] !== null
                  ? series[2][dataPointIndex].toFixed(1)
                  : " ";
              tempClass = "text_container";
              tempDotClass = "dot_power";
              tempFlexClass = "flex_center";
              tempBrClass = "displaying_Br";
            }

            return (
              "<div >" +
              "<span>" +
              '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">' +
              data +
              "</div>" +
              "</span>" +
              // "</br>" +

              '<div class="tooltip_container2">' +
              `<span class=${currentBrClass}></span>` +
              `<span class=${currentFlexClass}>` +
              `<span class=${currentDotClass}></span>` +
              `<span class=${currentClass}>&nbsp; Current (A)<b>: ` +
              current +
              "</b></span>" +
              "</span>" +
              `<span class=${voltBrClass}></span>` +
              `<span class=${voltFlexClass}>` +
              `<span class=${voltDotClass}></span>` +
              `<span class=${voltClass}>&nbsp; Voltage (V)<b>: ` +
              volt +
              "</b></span>" +
              "</span>" +
              `<span class=${tempBrClass}></span>` +
              `<span class=${tempFlexClass}>` +
              `<span class=${tempDotClass}></span>` +
              `<span class=${tempClass}>&nbsp; Power (W)<b>: ` +
              temp +
              "</b></span>" +
              "</span>" +
              "</div>" +
              "</div>"
            );
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        legend: {
          show: false,
        },
      },
    });

    setMounted(true);
  }

  useEffect(() => {
    setBatteryPackMetrics({
      temperature: [],
      timestamp: [],
      voltage: [],
      current: [],

      responseStatus: { code: -1, msg: "" },
    });
    if (
      props.granular === "Raw Data" &&
      props.startDate.toLowerCase().indexOf(":") === -1
    ) {
      getChargerVitals(
        props.batteryID,
        ["solarInputVoltage", "solarInputPower", "solarInputCurrent"],
        [props.startDate, props.startDate]
      ).then((res) => {
        if (res?.responseStatus?.code === 200) {
          let tempTemperature = [],
            tempTimestamp = [],
            tempVoltage = [],
            tempCurrent = [];

          res?.response?.batteryData?.forEach((item) => {
            tempCurrent.push(
              item.metricsArray.find((i) => i.type === "solarInputCurrent")[
                "value"
              ]
            );
            tempVoltage.push(
              item.metricsArray.find((i) => i.type === "solarInputVoltage")[
                "value"
              ]
            );
            tempTemperature.push(
              item.metricsArray.find((i) => i.type === "solarInputPower")[
                "value"
              ]
            );
            tempTimestamp.push(getChartTimeStamp(item.batteryDateTime));
          });

          setBatteryPackMetrics({
            temperature: tempTemperature,
            timestamp: tempTimestamp,
            voltage: tempVoltage,
            current: tempCurrent,
            responseStatus: {
              code: res?.responseStatus?.code,
              msg: res?.responseStatus?.msg,
            },
          });
        } else {
          if (res?.responseStatus?.code === -2)
            dispatch(SetSessionExpired(true));
          setBatteryPackMetrics({
            temperature: [],
            timestamp: [],
            voltage: [],
            current: [],

            responseStatus: {
              code: res?.responseStatus?.code,
              msg: res?.responseStatus?.msg,
            },
          });
        }
      });
    } else if (
      props.granular !== "Raw Data" &&
      props.startDate.toLowerCase().indexOf(":") !== -1
    ) {
      let date = props.startDate.split(":");
      let columnsArr = [];
      if (statusClicked.Current) columnsArr.push("solarInputCurrent");
      if (statusClicked.Voltage) columnsArr.push("solarInputVoltage");
      if (statusClicked.Temperature) columnsArr.push("solarInputPower");
      getChargerGranularityData(
        props.batteryID,
        // ["solarInputVoltage", "solarInputPower", "solarInputCurrent"],
        columnsArr,
        [date[0], date[1]],
        props.granular,
        props.operationType
      ).then((res) => {
        let tempTemperature = [],
          tempTimestamp = [],
          tempVoltage = [],
          tempCurrent = [];

        if (res?.responseStatus?.code === 200) {
          res?.response?.batteryData?.forEach((item) => {
            let tempX =
              props.granular === "Daily"
                ? formatDateWithoutYear(
                    convertToZoneDate(item["range"]["startDate"])
                  )
                : props.granular === "Monthly"
                  ? monthDictionary.find(
                      (o) => o.name === item["range"]["startDate"].split("-")[1]
                    )["value"]
                  : [
                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["startDate"])
                        ) + " - ",
                      ],

                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["endDate"])
                        ),
                      ],
                    ];

            tempCurrent.push({
              x: tempX,
              y:
                item["metricsArray"].find((i) => i.type === "solarInputCurrent")
                  ?.value === null
                  ? null
                  : item["metricsArray"].find(
                      (i) => i.type === "solarInputCurrent"
                    )?.value,
            });
            tempTemperature.push({
              x: tempX,
              y:
                item["metricsArray"].find((i) => i.type === "solarInputPower")
                  ?.value === undefined
                  ? null
                  : item["metricsArray"].find(
                      (i) => i.type === "solarInputPower"
                    )?.value,
            });
            tempVoltage.push({
              x: tempX,
              y:
                item["metricsArray"].find((i) => i.type === "solarInputVoltage")
                  ?.value === undefined
                  ? null
                  : item["metricsArray"].find(
                      (i) => i.type === "solarInputVoltage"
                    )?.value,
            });
            tempTimestamp.push(tempX);
          });

          setBatteryPackMetrics({
            temperature: tempTemperature,
            timestamp: tempTimestamp,
            voltage: tempVoltage,
            current: tempCurrent,
            responseStatus: {
              code: res?.responseStatus?.code,
              msg: res?.responseStatus?.msg,
            },
          });
        } else {
          if (res?.responseStatus?.code === -2)
            dispatch(SetSessionExpired(true));

          res?.response?.batteryData?.forEach((item) => {
            let tempX =
              props.granular === "Daily"
                ? formatDateWithoutYear(
                    convertToZoneDate(item["range"]["startDate"])
                  )
                : props.granular === "Monthly"
                  ? monthDictionary.find(
                      (o) => o.name === item["range"]["startDate"].split("-")[1]
                    )["value"]
                  : [
                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["startDate"])
                        ) + " - ",
                      ],

                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["endDate"])
                        ),
                      ],
                    ];

            tempCurrent.push({
              x: tempX,
              y: null,
            });
            tempVoltage.push({
              x: tempX,
              y: null,
            });
            tempTemperature.push({
              x: tempX,
              y: null,
            });

            tempTimestamp.push(tempX);

            // tempLat.push(item.latitude);
            // tempLong.push(item.longitude);
          });
          setBatteryPackMetrics({
            temperature: tempTemperature,
            timestamp: tempTimestamp,
            voltage: tempVoltage,
            current: tempCurrent,
            responseStatus: {
              code: res?.responseStatus?.code,
              msg: res?.responseStatus?.msg,
            },
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startDate, props.granular, props.operationType]);

  useEffect(() => {
    setMounted(false);
    if (
      batteryPackMetrics.responseStatus.code === 200 ||
      batteryPackMetrics.responseStatus.code === 1999
    ) {
      mountTheChart(
        batteryPackMetrics.current,
        batteryPackMetrics.voltage,
        batteryPackMetrics.temperature,
        batteryPackMetrics.timestamp
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    batteryPackMetrics.responseStatus.code,
    statusClicked, // eslint-disable-next-line react-hooks/exhaustive-deps
  ]);

  useEffect(
    () => () => {
      setMounted(false);
      setChartState({
        text: "",
        series: [],
        options: {},
      });
      setBatteryPackMetrics({
        temperature: [],
        timestamp: [],
        voltage: [],
        current: [],

        responseStatus: { code: -1, msg: "" },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      {(batteryPackMetrics.responseStatus.code === 200 ||
        batteryPackMetrics.responseStatus.code === 1999) &&
      mounted ? (
        <div>
          <div style={{ position: "relative", height: "400px" }}>
            {batteryPackMetrics.responseStatus.code === 1999 && (
              <div
                style={{
                  position: "absolute",
                  left: "41.5%",
                  top: "46%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ececec",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                >
                  <Typography variant="textSubtitle">
                    No data found for current selection
                  </Typography>
                </div>
              </div>
            )}
            <Chart
              options={chartState.options}
              series={chartState.series}
              type="line"
              height="100%"
              width="100%"
              id={
                props.granular === "Raw Data"
                  ? "hideAlternateLabel"
                  : "donthide"
              }
            />
          </div>
          <Box>{Legends()}</Box>
        </div>
      ) : (
        <div style={{ height: "400px" }}>
          {batteryPackMetrics.responseStatus.code === -1 ? (
            <Loader />
          ) : (
            <>
              {(batteryPackMetrics.responseStatus.code === 1999 &&
                props.granular === "Raw Data") ||
                (batteryPackMetrics.responseStatus.code === 404 && (
                  <>
                    <div style={{ position: "relative", height: "400px" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: "41.5%",
                          top: "46%",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#ececec",
                            padding: "10px",
                            borderRadius: "20px",
                          }}
                        >
                          <Typography variant="textSubtitle">
                            No data found for selected date
                          </Typography>
                        </div>
                      </div>
                      <Chart
                        options={{
                          chart: {
                            type: "line",
                            zoom: {
                              enabled: false,
                            },
                            toolbar: {
                              show: false,
                              offsetY: -20,
                              tools: {
                                download: false,
                                selection: true,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: true,
                                customIcons: [],
                              },
                            },
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            width: 0,
                            curve: "smooth",
                            // dashArray: [0, 2, 5, 8],
                          },
                          grid: {
                            padding: {
                              top: 10,
                              left: -26,
                              right: -21,
                            },
                          },
                          title: {
                            text: undefined,
                          },
                          colors: [
                            ThemeProperties.Current,
                            ThemeProperties.Voltage,
                            ThemeProperties.Power,
                          ],
                          markers: {
                            size: 0,
                          },
                          yaxis: [
                            {
                              seriesName: "Current (A)",
                              axisTicks: {
                                show: true,
                                borderType: "solid",
                                color: ThemeProperties.c_axis_labels_color,
                                offsetX: 3,
                              },
                              min: 0,
                              max: 25,
                              tickAmount: 5,
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                                offsetX: -3,
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value);
                                },
                                offsetX: -36,
                              },
                              title: {
                                text: "Current (A)",
                                offsetX: 4,
                                style: {
                                  color: ThemeProperties.Current,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                              },
                              tooltip: {
                                enabled: false,
                              },
                            },
                            {
                              seriesName: "Voltage (V)",
                              axisTicks: {
                                show: true,
                                borderType: "solid",
                                color: ThemeProperties.c_axis_labels_color,
                              },
                              min: 0,
                              max: 100,
                              tickAmount: 5,
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                                // offsetX:-10
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value);
                                },
                                offsetX: -28,
                              },
                              title: {
                                text: "Voltage (V)",
                                offsetX: 2,
                                style: {
                                  color: ThemeProperties.Voltage,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                              },
                              tooltip: {
                                enabled: false,
                              },
                            },
                            {
                              seriesName: "Temperature (°C)",
                              max: 400,
                              min: 0,
                              tickAmount: 5,
                              opposite: true,
                              axisTicks: {
                                show: true,
                                borderType: "solid",
                                color: ThemeProperties.c_axis_labels_color,
                              },
                              axisBorder: {
                                show: true,
                                color: ThemeProperties.c_axis_labels_color,
                              },
                              labels: {
                                style: {
                                  colors: [ThemeProperties.c_axis_labels_color],
                                  fontSize: ThemeProperties.c_axis_labels,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_labels_weight,
                                },
                                formatter: function (value) {
                                  return Math.round(value);
                                },
                                offsetX: -31,
                              },
                              title: {
                                text: "Power (W)",
                                style: {
                                  color: ThemeProperties.Power,
                                  fontSize: ThemeProperties.c_axis_title,
                                  fontFamily: "Roboto",
                                  fontWeight:
                                    ThemeProperties.c_axis_title_weight,
                                },
                                offsetX: -3.5,
                              },
                            },
                          ],
                          xaxis: {
                            categories: [
                              "12 AM",
                              "1 AM",
                              "2 AM",
                              "3 AM",
                              "4 AM",
                              "5 AM",
                              "6 AM",
                              "7 AM",
                              "8 AM",
                              "9 AM",
                              "10 AM",
                              "11 AM",
                              "12 PM",
                              "1 PM",
                              "2 PM",
                              "3 PM",
                              "4 PM",
                              "5 PM",
                              "6 PM",
                              "7 PM",
                              "8 PM",
                              "9 PM",
                              "10 PM",
                              "11 PM",
                              "12 AM",
                            ],

                            // tickAmount:7,

                            tooltip: {
                              enabled: false,
                            },
                            title: {
                              text: undefined,
                            },
                            labels: {
                              show: true,
                              rotate: 0,
                              datetimeUTC: false,
                              rotateAlways: false,
                              align: "center",
                              style: {
                                colors: ThemeProperties.c_axis_labels_color,
                                fontSize: ThemeProperties.c_axis_labels,
                                fontFamily: "Roboto",
                                fontWeight:
                                  ThemeProperties.c_axis_labels_weight,
                              },
                              offsetX: 0,
                              offsetY: 0,
                              format: "h TT",
                            },
                            axisTicks: {
                              show: true,
                              borderType: "solid",
                              color: ThemeProperties.c_axis_labels_color,
                            },
                            axisBorder: {
                              show: true,
                              color: ThemeProperties.c_axis_labels_color,
                              height: 0.8,
                            },
                          },
                          tooltip: {
                            enabled: false,
                            enabledOnSeries: undefined,
                            shared: true,
                            followCursor: false,
                            intersect: false,
                            inverseOrder: false,
                            custom: undefined,
                            fillSeriesColor: false,
                            theme: false,
                            style: {
                              style: {
                                colors: [ThemeProperties.c_axis_labels_color],
                                fontSize: ThemeProperties.c_axis_labels,
                                fontFamily: "Roboto",
                                fontWeight:
                                  ThemeProperties.c_axis_labels_weight,
                              },
                            },
                            onDatasetHover: {
                              highlightDataSeries: false,
                            },
                            x: {
                              show: true,
                              format: "dd MMM yyyy hh:mm:ss TT",
                              formatter: undefined,
                              title: {
                                formatter: (seriesName) => seriesName,
                              },
                            },
                            y: [
                              {
                                seriesName: "Current (A)",
                                formatter: function (value) {
                                  return ": " + value;
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                              {
                                seriesName: "Voltage (V)",
                                formatter: function (value) {
                                  return ": " + Math.round(value);
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                              {
                                seriesName: "Temperature (°C)",
                                formatter: function (value) {
                                  return ": " + value;
                                },
                                title: {
                                  formatter: (seriesName) => seriesName,
                                },
                              },
                            ],
                            z: {
                              formatter: undefined,
                              title: "Size: ",
                            },
                            marker: {
                              show: true,
                            },

                            fixed: {
                              enabled: false,
                              position: "topRight",
                              offsetX: 0,
                              offsetY: 0,
                            },
                          },
                          axisBorder: {
                            show: false,
                          },
                          axisTicks: {
                            show: false,
                          },
                          legend: {
                            show: false,
                          },
                        }}
                        series={[
                          {
                            name: "Current (A)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                          {
                            name: "Voltage (V)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                          {
                            name: "Temperature (°C)",
                            data: [
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                            ],
                          },
                        ]}
                        type="line"
                        height="100%"
                        width="100%"
                        id="hideAlternateLabel"
                      />
                    </div>
                  </>
                ))}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Apex3YAxis;
