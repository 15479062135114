import React, { useEffect } from "react";
import SlickCarousel from "../../Features/Carousel/SlickCarousel";
import { PaperHighlight } from "../../Components/CustomPaper";
import Header from "../../Features/Header/Header";

import useState from "react-usestateref";
import {
  formatDateNumeric,
  formatDate,
  DateDiffInDays,
  getLastSundayYearMonth,
  getWeekNumber,
  getArrayOfNumericDates,
  getArrayOfFormattedDates,
} from "../../Helper/DatePicker/DateFormatters";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import Loader from "../../Helper/Loaders/Loader";
import { Box } from "@mui/material";
import Events from "../../Analytics/Events";
import CODE from "../../Static/Constants/StatusCodes";
import ComparisionChart from "../DigitalTwin/TabsSection/BatteryVitals/ComparisionChart";
import { ChargerVitals } from "../DigitalTwin/TabsSection/_DownloadCSV/CSVDownload";

import Apex3YAxis from "../../Charts/Line/Apex3YAxis";
import KeyMetrics from "../DigitalTwin/TabsSection/BatteryVitals/KeyMetrics";
import CellImbalance from "../DigitalTwin/TabsSection/BatteryVitals/CellImbalance";
import Capacity from "../DigitalTwin/TabsSection/BatteryVitals/Capacity";
import { getGranularityData } from "../../Api/DigitalTwin";
import GenericSelectKeyValue from "../../Features/Menu/GenericSelectKeyValue";

function lastSunday(year, month) {
  var date = new Date(year, month, 1, 12);
  let weekday = date.getDay();
  let dayDiff = weekday === 0 ? 7 : weekday;
  date.setDate(date.getDate() - dayDiff);
  return date;
}

const BatteryPackCarousel = (props) => {
  const navigate = useNavigate();
  const queryParams = decryptTheParams();

  let granularity = queryParams["granularity"]
    ? queryParams["granularity"]
    : "Raw Data";

  const [mountTheSlick, setMountTheSlick] = useState(false);
  const [arrayOfDates, setArrayOfDatesFormatted] = useState([]);
  const [arrayOfDatesNumeric, setArrayOfDatesNumeric] = useState([]);
  const [startDate, setStartDate, refStartDate] = useState("");
  const [granular, setGranular] = useState(granularity);

  // const level = hierarchy.level;
  const hierarchy = props.hierarchy;

  const [chargerCSV, setChargerCSV] = useState({
    data: [],
    responseStatus: { code: 0 },
  });

  const setTheDate = (date) => {
    setStartDate(date);
    // console.log(date)
    let temp;
    temp = {
      ...queryParams,
      batteryPackMetrics: date,
    };
    encryptTheParams(temp, navigate, true);
  };

  const setTheQuery = (date) => {
    let temp;
    temp = {
      ...queryParams,
      batteryPackMetrics: date,
    };
    encryptTheParams(temp, navigate, true);
  };

  function handleSetFinalStartDate(value) {
    setTheQuery(value);

    let queryParams = decryptTheParams();
    if (queryParams && queryParams["batteryPackMetrics"]) {
      setStartDate(queryParams["batteryPackMetrics"]);
    } else {
      setStartDate(value);
    }
  }

  const handleSetFilter = (name, value) => {
    setGranular(value);
    let temp;
    let queryParams = decryptTheParams();
    delete queryParams.batteryPackMetrics;

    temp = {
      ...queryParams,
      granularity: value,
    };
    encryptTheParams(temp, navigate, true);
  };

  function GetChargerCSV() {
    setChargerCSV({
      data: [],
      responseStatus: {
        code: -1,
      },
    });
    let obj = ChargerVitals(
      props.batteryID,
      ["solarInputVoltage", "solarInputPower", "solarInputCurrent"],
      [props.dateString[0], props.dateString[1]]
    );
    obj.then((res) => {
      if (res.responseStatus.code === CODE.SUCCESS)
        setChargerCSV({
          data: res.data,
          responseStatus: {
            code: res.responseStatus.code,
          },
        });
      else
        setChargerCSV({
          data: [],
          responseStatus: {
            code: res.responseStatus.code,
          },
        });
    });
  }

  const generateHeading = (name) => {
    if (hierarchy.level === "cell")
      return (
        name +
        " > " +
        hierarchy.selectedPack.value?.toUpperCase() +
        " > " +
        hierarchy.selectedModule.value?.toUpperCase() +
        " > " +
        hierarchy.selectedCell.value?.toUpperCase()
      );
    else if (hierarchy.level === "module")
      return (
        name +
        " > " +
        hierarchy.selectedPack.value?.toUpperCase() +
        " > " +
        hierarchy.selectedModule.value?.toUpperCase()
      );
    else if (hierarchy.level === "pack")
      return name + " > " + hierarchy.selectedPack.value?.toUpperCase();
  };

  useEffect(() => {
    setMountTheSlick(false);

    if (granular === "Raw Data") {
      if (props.dateString[0] !== "" || props.dateString[1] !== "") {
        // if (arrayOfDatesNumeric.length > 0) {
        //   let temp;
        //   temp = {
        //     ...queryParams,
        //     batteryPackMetrics: arrayOfDatesNumeric[0],
        //   };
        //   encryptTheParams(temp, navigate, true);
        // }

        // handleSetDates();

        var tempFormatted = getArrayOfFormattedDates(
          props.dateString[0],
          props.dateString[1]
        );
        var tempNumeric = getArrayOfNumericDates(
          props.dateString[0],
          props.dateString[1]
        );

        setArrayOfDatesFormatted(tempFormatted);
        setArrayOfDatesNumeric(tempNumeric);
        handleSetFinalStartDate(tempNumeric[0]);
      }
    }

    if (granular !== "Raw Data") {
      if (granular === "Daily") {
        var tempFormatted = getArrayOfFormattedDates(
          props.dateString[0],
          props.dateString[1]
        );
        var tempNumeric = getArrayOfNumericDates(
          props.dateString[0],
          props.dateString[1]
        );

        var buckets = [],
          tempDatesToShow = [],
          tempDatesToSave = [];
        for (var i = 0; i < tempFormatted.length; i = i + 15) {
          var tempStart = tempFormatted[i],
            tempEnd =
              tempFormatted[i + 14] === undefined
                ? tempFormatted[tempFormatted.length - 1]
                : tempFormatted[i + 14],
            tempStartSave = tempNumeric[i],
            tempEndSave =
              tempNumeric[i + 14] === undefined
                ? tempNumeric[tempNumeric.length - 1]
                : tempNumeric[i + 14];

          buckets.push({
            start: tempStart,
            end: tempEnd,
            textToShow: tempStart + " to " + tempEnd,
            textToSave: tempStartSave + ":" + tempEndSave,
          });
          tempDatesToShow.push(tempStart + " to " + tempEnd);
          tempDatesToSave.push(tempStartSave + ":" + tempEndSave);
        }

        setArrayOfDatesFormatted(tempDatesToShow);
        setArrayOfDatesNumeric(tempDatesToSave);
        handleSetFinalStartDate(tempDatesToSave[0]);
      } else if (granular === "Weekly") {
        let diffInDays = DateDiffInDays(
          props.dateString[0],
          props.dateString[1]
        );
        if (diffInDays > 105) {
          var myStart = formatDate(new Date(props.dateString[0]));
          var myEnd = formatDate(new Date(props.dateString[1]));

          var tempDateRanges = [];

          var tempStart = new Date(myStart);
          let tempEnd = new Date(tempStart.setDate(tempStart.getDate() + 105));

          tempDateRanges.push({
            start: new Date(myStart),
            end: lastSunday(
              tempEnd.getUTCFullYear(),
              tempEnd.getUTCMonth() + 1
            ),
            startWeek: getWeekNumber(new Date(myStart)),
            endWeek: getWeekNumber(
              lastSunday(tempEnd.getUTCFullYear(), tempEnd.getUTCMonth() + 1)
            ),
          });

          var chi = new Date(tempDateRanges[0]["end"]);
          var nextStartDate = new Date(chi.setDate(chi.getDate() + 1));

          var i = 1;
          while (nextStartDate < new Date(myEnd)) {
            let confusious = new Date(
              nextStartDate.setDate(nextStartDate.getDate() + 105)
            );

            tempDateRanges.push({
              start: new Date(
                tempDateRanges[i - 1]["end"].getTime() + 24 * 60 * 60 * 1000
              ),
              end: lastSunday(
                confusious.getUTCFullYear(),
                confusious.getUTCMonth() + 1
              ),
            });

            i++;
          }

          tempDateRanges[tempDateRanges.length - 1]["end"] = new Date(myEnd);

          let finalRanges = [];
          tempDateRanges.map((item) => {
            let tempStart = getWeekNumber(new Date(item.start)),
              tempEnd = getWeekNumber(new Date(item.end));
            finalRanges.push({
              start: formatDateNumeric(item.start),
              end: formatDateNumeric(item.end),
              startWeek: tempStart,
              endWeek: tempEnd,
              textToShow: "week " + tempStart + " to week " + tempEnd,
              textToSave:
                formatDateNumeric(item.start) +
                ":" +
                formatDateNumeric(item.end),
            });
          });
          let tempDatesToShow = [],
            tempDatesToSave = [];

          finalRanges.map((item) => {
            tempDatesToShow.push(item.textToShow);
            tempDatesToSave.push(item.textToSave);
          });

          setArrayOfDatesFormatted(tempDatesToShow);
          setArrayOfDatesNumeric(tempDatesToSave);
          handleSetFinalStartDate(tempDatesToSave[0]);
        } else {
          let tempStartWeek = getWeekNumber(new Date(props.dateString[0])),
            tempEndWeek = getWeekNumber(new Date(props.dateString[1]));

          setArrayOfDatesFormatted([
            "week " + tempStartWeek + " to week " + tempEndWeek,
          ]);
          setArrayOfDatesNumeric([
            formatDateNumeric(new Date(props.dateString[0])) +
              ":" +
              formatDateNumeric(new Date(props.dateString[1])),
          ]);
          handleSetFinalStartDate(
            formatDateNumeric(new Date(props.dateString[0])) +
              ":" +
              formatDateNumeric(new Date(props.dateString[1]))
          );

          // console.log(tempStartWeek, tempEndWeek);
        }
      } else if (granular === "Monthly") {
        let tempStartYear = new Date(props.dateString[0]).getFullYear();
        let tempEndYear = new Date(props.dateString[1]).getFullYear();
        let tempYears = [];
        for (var i = tempStartYear; i <= tempEndYear; i++) tempYears.push(i);

        let tempDateRanges = [];
        tempYears.map((item) => {
          var theFirst = new Date(item, 0, 1);
          var theLast = new Date(item, 11, 31);
          tempDateRanges.push({
            start: theFirst,
            end: theLast,
            year: item,
          });
        });

        tempDateRanges[0]["start"] = new Date(props.dateString[0]);
        tempDateRanges[tempDateRanges.length - 1]["end"] = new Date(
          props.dateString[1]
        );

        let finalRanges = [],
          tempDatesToShow = [],
          tempDatesToSave = [];
        tempDateRanges.map((item) => {
          var tempYear = item.year,
            tempShow =
              formatDateNumeric(new Date(item.start)) +
              ":" +
              formatDateNumeric(new Date(item.end));

          finalRanges.push({
            start: item.start,
            end: item.end,
            textToShow: tempYear,
            textToSave: tempShow,
          });

          tempDatesToShow.push(tempYear);
          tempDatesToSave.push(tempShow);
        });
        console.log(
          "SETTING month",
          tempDatesToShow,
          tempDatesToSave,
          tempDatesToSave[0]
        );
        setArrayOfDatesFormatted(tempDatesToShow);
        setArrayOfDatesNumeric(tempDatesToSave);
        handleSetFinalStartDate(tempDatesToSave[0]);
      }
    }
    setTimeout(() => {
      setMountTheSlick(true);
    }, 10);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateString[0], granular]);

  useEffect(
    () => () => {
      setMountTheSlick(false);

      if (props.dateString[0] !== "" || props.dateString[1] !== "") {
        setMountTheSlick(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [refStartDate.current, props.dateString]
  );

  return (
    <>
      <div style={{ marginTop: "-5px" }}>
        {mountTheSlick ? (
          <>
            <div
              className="section"
              style={{
                marginBottom: "5px",
                width: "100%",
                position: "relative",
              }}
            >
              <Box sx={{ width: "90%" }}>
                <SlickCarousel
                  Data={arrayOfDates}
                  actualDates={arrayOfDatesNumeric}
                  setTheDate={setTheDate}
                  startDate={refStartDate.current}
                  slidesToShow={granular === "Daily" ? 5 : 10}
                />
              </Box>
              <div style={{ position: "absolute", right: 10, top: 0 }}>
                <GenericSelectKeyValue
                  filter={props.filter}
                  handleSetFilter={handleSetFilter}
                  selected={granular} // All,48V,60V
                  maxWidth={"80px"}
                  date={[props.date[0], props.date[1]]}
                  showClearButton={false}
                  // id="overflow-text-1"
                  pageName={"DataQuality"}
                />
              </div>
            </div>
            {props.tab === "Battery Vitals" && (
              <>
                {hierarchy.level === "battery" && (
                  <div className="section" id="Battery_Pack_Metrics">
                    {/* {console.log(refStartDate.current)} */}
                    <ComparisionChart
                      startDate={refStartDate.current}
                      hierarchy={hierarchy}
                      dateString={props.dateString}
                      batteryID={props.batteryID}
                      granular={granular}
                      tab={"Battery Vitals"}
                    />
                  </div>
                )}
                {(hierarchy.level === "pack" ||
                  hierarchy.level === "module" ||
                  hierarchy.level === "cell") && (
                  <>
                    <KeyMetrics
                      hierarchy={hierarchy}
                      queryParams={queryParams}
                      batteryID={props.batteryID}
                      startDate={refStartDate.current}
                      dateString={[props.dateString[0], props.dateString[1]]}
                      generateHeading={generateHeading}
                      granular={granular}
                    />
                  </>
                )}
                <Box sx={{ mt: 2 }}></Box>
                {(hierarchy.level === "pack" ||
                  hierarchy.level === "module") && (
                  <>
                    <CellImbalance
                      hierarchy={hierarchy}
                      queryParams={queryParams}
                      batteryID={props.batteryID}
                      startDate={refStartDate.current}
                      dateString={[props.dateString[0], props.dateString[1]]}
                      generateHeading={generateHeading}
                      granular={granular}
                    />
                  </>
                )}
                <Box sx={{ mt: 2 }}></Box>
                {hierarchy.level === "pack" && (
                  <>
                    <Capacity
                      hierarchy={hierarchy}
                      queryParams={queryParams}
                      batteryID={props.batteryID}
                      startDate={refStartDate.current}
                      dateString={[props.dateString[0], props.dateString[1]]}
                      generateHeading={generateHeading}
                      granular={granular}
                    />
                  </>
                )}
              </>
            )}
            {props.tab === "Charger Vitals" && (
              <>
                <PaperHighlight
                  elevation={0}
                  onMouseEnter={() => Events("SB Hovered Key Metrics Chart")}
                >
                  <Header
                    heading={"Key Metrics"}
                    description="Showing how battery signals (Voltage, Current, Temperature, SOC) are fluctuating over time within a day"
                    showIButton={true}
                    showThreeDots={false}
                    showCSVDownload={true}
                    csvData={chargerCSV}
                    SetCSVData={GetChargerCSV}
                    setStatusBack={setChargerCSV}
                    id="Charger_Data"
                    page="specificBattery"
                  />
                  <div className="section" id="Charger_Data">
                    {/* <Apex3YAxis
                      startDate={refStartDate.current}
                      hierarchy={hierarchy}
                      batteryID={props.batteryID}
                      alertFirstSeenOn={queryParams["alertFirstSeenOn"]}
                    /> */}
                  </div>
                </PaperHighlight>
              </>
            )}
          </>
        ) : (
          <Box sx={{ height: "400px" }}>
            <Loader />
          </Box>
        )}
      </div>
    </>
  );
};
export default BatteryPackCarousel;
