import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  IconButton,
  Typography,
  Grid,
  Box,
  Radio,
  Divider,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { OutlinedButton } from "../../../Components/CustomButton";
import { StyledListItemButton } from "../../../Components/CustomListItemButton";
import { SetDigitalTwinsConfigurationType } from "../../../Actions/DigitalTwinsList";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import { batteryConfigurations } from "../../../Static/Data/DigitalTwinList/BatteryConfigurations";
import { encryptTheParams } from "../../../Helper/QueryParams/EncryptDecrypt";

function AddBatteryDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selected, setSelected] = useState(1);
  const [selectedApplicationName, setSelectedApplicationName] = useState(null);

  useEffect(() => {
    if (selected === 1) {
      dispatch(
        SetDigitalTwinsConfigurationType(batteryConfigurations[0]?.value)
      );
      setSelectedApplicationName(batteryConfigurations[0]?.value)
    }
  }, []);

  const handleSelect = (config) => {
    setSelected(config.id);
    dispatch(SetDigitalTwinsConfigurationType(config.value));
    setSelectedApplicationName(config.value)
  };

  const handleOnClickProceed = () => {
    let temp = {application: selectedApplicationName}
    encryptTheParams(temp, navigate, false, "/DigitalTwin/add-config");
  }

  return (
    <div>
      <Dialog
        onClose={props.handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 12,
              marginLeft: 10,
            }}
          >
            <Typography variant="paperHeader" fontSize={20}>
              Add a new battery configuration
            </Typography>
            <IconButton onClick={props.handleCloseDialog} fontSize="small">
              <CloseIcon style={{ fontSize: "18px" }} />
            </IconButton>
          </div>

          <Divider />

          <div style={{ padding: 12 }}>
            <Typography
              style={{
                width: "600px",
                fontSize: 15,
                paddingTop: 4,
                marginLeft: 14,
              }}
            >
              Define a Battery Configuration for
            </Typography>
          </div>

          <Box>
            <Grid
              container
              spacing={0}
              columns={12}
              sx={{
                marginTop: 0,
                marginBottom: 1,
                px: 2,
              }}
            >
              {batteryConfigurations.map((config) => (
                <Grid item xs={6}>
                  <StyledListItemButton
                    value={config.id}
                    variant="Outlined"
                    selected={selected === config.id}
                    onClick={() => handleSelect(config)}
                    myColor={ThemeProperties.purpleSelected}
                    border={true}
                  >
                    <Radio checked={selected === config.id} />
                    <Typography
                      variant="textSubtitle"
                      sx={{
                        display: "flex",
                        flexDirection: "flex-row",
                      }}
                    >
                      <img width={16} src={config.icon} />
                      <Typography sx={{ paddingLeft: 1, fontSize: 14 }}>
                        {config.configName}
                      </Typography>
                    </Typography>
                  </StyledListItemButton>
                </Grid>
              ))}
            </Grid>
          </Box>
          <br></br>
          <Divider />

          <Box sx={{ display: "flex", justifyContent: "end", py: 3, pr: 3 }}>
            <OutlinedButton
              variant="contained"
              onClick={handleOnClickProceed}
              endIcon={<ArrowForwardIcon />}
              size="large"
            >
              <Typography variant="textSubtitleWhite">Proceed</Typography>
            </OutlinedButton>
          </Box>
        </div>
      </Dialog>
    </div>
  );
}

export default AddBatteryDialog;
