import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import "./Assembly.css";
import CODE from "../../../Static/Constants/StatusCodes";
import Loader from "../../../Helper/Loaders/Loader";
import NoData from "../../../Helper/Loaders/NoData";
import Events from "../../../Analytics/Events";
import ColorScale from "../../../Assets/Icons/DigitalTwin/ColorScale.svg";

const findTheText = (value) => {
  if (value === "voltage") return ["Voltage (V)", "", "", ""];
  else if (value === "temperature")
    return ["Temperature (°C)", ">45", "30", "<15"];
  else if (value === "soc") return ["SOC (%)", "<30", "40", ">50"];
  else return ["", "", "", ""];
};

function AssemblyDetails(props) {
  const packAssembly = props.hierarchy;
  const height = 75 + 62.25 * packAssembly.packHierarchy.length + "%";

  const [finalDisplay, setFinalDisplay] = useState({
    responseStatus: {
      code: CODE.LOADING,
    },
  });

  const getCellColor = (p, m, c, color) => {
    if (props.hierarchy.selectedKPI.value === null) {
      if (
        !packAssembly.selectedPack.value &&
        !packAssembly.selectedModule.value &&
        !packAssembly.selectedCell.value
      )
        return "#666";
      else if (
        packAssembly.selectedPack.value === p &&
        packAssembly.selectedModule.value === m &&
        packAssembly.selectedCell.value === c
      )
        return ThemeProperties.purple; //
      else if (
        packAssembly.selectedPack.value === p &&
        packAssembly.selectedModule.value === m &&
        packAssembly.selectedCell.value === null
      )
        return "#666";
      else if (
        packAssembly.selectedPack.value === p &&
        packAssembly.selectedModule.value === null &&
        packAssembly.selectedCell.value === null
      )
        return "#666";
      else return ThemeProperties.backgroundPurple;
    } else {
      if (
        !packAssembly.selectedPack.value &&
        !packAssembly.selectedModule.value &&
        !packAssembly.selectedCell.value
      )
        return "#666";
      else if (
        packAssembly.selectedPack.value === p &&
        packAssembly.selectedModule.value === m &&
        packAssembly.selectedCell.value === c
      )
        return color; //
      else if (
        packAssembly.selectedPack.value === p &&
        packAssembly.selectedModule.value === m &&
        packAssembly.selectedCell.value === null
      )
        return color;
      else if (
        packAssembly.selectedPack.value === p &&
        packAssembly.selectedModule.value === null &&
        packAssembly.selectedCell.value === null
      )
        return "#666";
      else return ThemeProperties.backgroundPurple;
    }
  };

  useEffect(() => {
    if (
      props.hierarchy.responseStatus.code === 200 &&
      props.hierarchyColorCoding.responseStatus.code === 200
    ) {
      if (props.hierarchy?.level === "battery") {
        props.handleSetHierarchy(
          { key: null, value: null },
          { key: null, value: null },
          { key: null, value: null },
          "battery",
          {
            value: "soc",
          }
        );
      }
      setFinalDisplay({
        responseStatus: {
          code: CODE.SUCCESS,
        },
      });
    }
    if (props.hierarchyColorCoding.responseStatus.code === 404) {
      setFinalDisplay({
        responseStatus: {
          code: CODE.SUCCESS,
        },
      });
    }
  }, [
    props.hierarchy.responseStatus.code,
    props.hierarchyColorCoding.responseStatus.code,
  ]);

  return (
    <>
      {finalDisplay.responseStatus.code === CODE.SUCCESS &&
      packAssembly.responseStatus.code === CODE.SUCCESS ? (
        <>
          <Box
            sx={{
              // width: "100%",
              height: height,
              borderRadius: "10px",
              border: "1px solid #ececec",
            }}
            className={
              packAssembly.selectedPack.value === null &&
              packAssembly.selectedModule.value === null &&
              packAssembly.selectedCell.value === null
                ? "assembly_selected"
                : "assembly"
            }
            onClick={(e) => {
              Events("Assembly battery level clicked");
              props.handleSetHierarchy(
                { key: null, value: null },
                { key: null, value: null },
                { key: null, value: null },
                "battery",
                {
                  value: null,
                }
              );
            }}
          >
            {packAssembly.packHierarchy.map((pack, index) => (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                <Box sx={{ margin: 1.5 }}>
                  <div
                    className={
                      packAssembly.selectedPack.value === pack.value &&
                      packAssembly.selectedModule.value === null &&
                      packAssembly.selectedCell.value === null
                        ? "BS1Style_selected"
                        : "BS1Style"
                    }
                    style={{
                      border:
                        packAssembly.selectedPack.value === null &&
                        packAssembly.selectedModule.value === null &&
                        packAssembly.selectedCell.value === null
                          ? `5px solid ${pack.color}`
                          : "5px solid #E0E0E0",
                      borderRadius: "4px",
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      Events("Assembly clicked pack");
                      props.handleSetHierarchy(
                        pack,
                        { key: null, value: null },
                        { key: null, value: null },
                        "pack",
                        {
                          value: null,
                        }
                      );
                    }}
                  >
                    <Grid container spacing={0} sx={{ padding: "0px 4px" }}>
                      {pack.content.map((module, index) => (
                        <Grid
                          item
                          xs={3 * 4/props.hierarchy.m}
                          sm={3 * 4/props.hierarchy.m}
                          md={3 * 4/props.hierarchy.m}
                          lg={3 * 4/props.hierarchy.m}
                          xl={3 * 4/props.hierarchy.m}
                          key={index}
                        >
                          {/* P1 */}
                          <Box sx={{ margin: "8px 4px" }}>
                            <Box
                              sx={{
                                border: "1px dashed #666",
                                borderRadius: "4px",
                                backgroundColor:
                                  (props.hierarchy.selectedKPI.value !== null &&
                                    packAssembly.selectedPack.value ===
                                      pack.value &&
                                    packAssembly.selectedModule.value ===
                                      null &&
                                    packAssembly.selectedCell.value === null) ||
                                  (props.hierarchy.selectedKPI.value === null &&
                                    packAssembly.selectedPack.value ===
                                      pack.value &&
                                    packAssembly.selectedModule.value ===
                                      module.value &&
                                    packAssembly.selectedCell.value === null)
                                    ? module.color
                                    : props.hierarchy.selectedKPI.value !==
                                          null &&
                                        packAssembly.selectedPack.value ===
                                          pack.value &&
                                        packAssembly.selectedModule.value ===
                                          module.value &&
                                        packAssembly.selectedCell.value === null
                                      ? "#e0e0e0"
                                      : "#fff",
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor:
                                    props.hierarchy.level === "battery"
                                      ? "rgb(184, 184, 255)"
                                      : props.hierarchy.selectedKPI.value !==
                                            null &&
                                          packAssembly.selectedPack.value ===
                                            pack.value &&
                                          packAssembly.selectedModule.value ===
                                            module.value &&
                                          packAssembly.selectedCell.value ===
                                            null
                                        ? "#e0e0e0"
                                        : module.color,
                                },
                              }}
                              onMouseDown={(e) => e.stopPropagation()}
                              onClick={(e) => {
                                e.stopPropagation();
                                Events("Assembly module clicked");
                                props.handleSetHierarchy(
                                  pack,
                                  module,
                                  {
                                    key: null,
                                    value: null,
                                  },
                                  "module",
                                  {
                                    value: null,
                                  }
                                );
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                {module.content.map((item3, index) => (
                                  // <Grid item xs={6} sm={6} md={6} lg={6} xl={6} key={index}>
                                  <div
                                    style={{
                                      margin: "9px 0px",
                                    }}
                                    key={index}
                                  >
                                    <Box
                                      className={
                                        packAssembly.selectedPack.value ===
                                          pack.value &&
                                        packAssembly.selectedModule.value ===
                                          module.value &&
                                        packAssembly.selectedCell.value ===
                                          item3.value
                                          ? "batteryBox_selected"
                                          : "batteryBox"
                                      }
                                      sx={{
                                        height: "22px",
                                        width: "22px",
                                        backgroundColor: getCellColor(
                                          pack.value,
                                          module.value,
                                          item3.value,
                                          item3.color
                                        ),
                                        cursor: "pointer",
                                      }}
                                      onMouseDown={(e) => e.stopPropagation()}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        Events("Assembly cell clicked");
                                        props.handleSetHierarchy(
                                          pack,
                                          module,
                                          item3,
                                          "cell",
                                          {
                                            value: null,
                                          }
                                        );
                                      }}
                                    ></Box>
                                  </div>
                                ))}
                              </div>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Box>
              </Grid>
            ))}
          </Box>
          {props.hierarchy.selectedKPI.value !== null && (
            <>
              <br></br>
              <div style={{ position: "relative" }}>
                <img src={ColorScale} alt="color scale" width={"100%"} />
                <div style={{ position: "absolute", top: 4, width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: "0px 5px",
                    }}
                  >
                    {[1, 2, 3].map((item, index) => (
                      <Box key={index}>
                        <Typography variant="textSubtitle">
                          {findTheText(props.hierarchy.selectedKPI.value)[item]}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
              <Typography
                sx={{ textAlign: "center", fontSize: "14px", fontWeight: 600 }}
              >
                {findTheText(props.hierarchy.selectedKPI.value)[0]}
              </Typography>
            </>
          )}
        </>
      ) : (
        <Box sx={{ height: 300 }}>
          {packAssembly.responseStatus.code === CODE.LOADING ||
          finalDisplay.responseStatus.code === CODE.LOADING ? (
            <Loader />
          ) : (
            <NoData />
          )}
        </Box>
      )}
    </>
  );
}

export default AssemblyDetails;
