import * as Amplitude from "@amplitude/node";
import Cookies from "universal-cookie";

const Events = (value) => {
  const cookies = new Cookies();
  const client = Amplitude.init(cookies.get("amplitudeID"));

  const getSessionId = () => {
    const previousTimestamp = cookies.get("sessionId",{ path: '/' });
    const currentTimestamp = new Date().getTime() / 1000;

    let difference = Math.round(currentTimestamp - previousTimestamp);
    if (difference >= 1800) {
      cookies.set("sessionId", currentTimestamp);
    }
    return Math.round(cookies.get("sessionId"));
  };
//   return client.logEvent({
//     event_type: value,
//     user_id: cookies.get("name"),
//     session_id: getSessionId(),
//     location_lat: 12.9716,
//     location_lng: 77.5946,
//     ip: "127.0.0.1",
//     event_properties: {
//       keyString: "valueString",
//       keyInt: 11,
//       keyBool: true,
//     },
//   });
};

export default Events;