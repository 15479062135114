import { getDatePickerDate } from "../Helper/DatePicker/DateConverter";
import { formatDateNumeric } from "../Helper/DatePicker/DateFormatters";
import { decryptTheParams } from "../Helper/QueryParams/EncryptDecrypt";
import {
  newCity,
  newIOT,
  newPack,
  newSeverity,
  newStatus,
  newZone,
} from "../Static/Data/Dashboard/DashboardFilters";
const timezoneChanged = {
  value: false,
};
const sessionExpired = {
  value: false,
};

const refreshAPI = {
  value: false,
};

const initialDashboardType = {
  value: "",
};

const inititalColumns = {
  value: [],
};

const inititalHiddenColumns = {
  value: [],
};

const initialIsBatteryIsPartnerIsNull = {
  value: null,
};
const initialBatteryPartnerSelect = {
  value: "Battery ID",
};
const initialBatteryID = {
  value: "",
};
const initialPartner = {
  value: "",
};

const initialOrg = {
  value: [],
};

const initialCategory = {
  value: decryptTheParams()?.['category'] || "",
};
const initialLocation = {
  value: [],
};
const initialStatus = {
  value: [],
};
const initialSeverity = {
  value: [],
};

const initialIot = {
  value: [],
};

const initialPack = {
  value: [],
};

const queryParamsTabValue = decryptTheParams()?.['tabValue'] 
const initialTab = {
  value: queryParamsTabValue ? parseInt(queryParamsTabValue) || 0 : 0,
};
const initialDashboardAlertTypeIndex = {
  value: decryptTheParams()?.alertTypeIndex ? parseInt(decryptTheParams()?.alertTypeIndex) : 0,
};
const initialDashboardAlertTypeCode = {
  value: decryptTheParams()?.alertCode || "",
};
const initialTotal = {
  value: 0,
};
const initialStartDate = {
  value: decryptTheParams()?.['startDate'] || getDatePickerDate(undefined, -14),
};
const initialEndDate = {
  value: decryptTheParams()?.['endDate'] || getDatePickerDate(),
};
const initialDashboardAlertTypeList = {
  value: [],
};
const initialDashboardListOfBatteries = {
  value: [],
};

const initialUserState = {
  arr: [],
};

const initialDashboardExisting = {
  arr: [],
};

const initialDashboardColumnSort = {
  arr: [
    {
      sortBy: "alertTime",
    },
    {
      sortBy: "actionTime",
    },
    {
      sortBy: "statusChangedTime",
    },
    {
      sortBy: "statusChangedTime",
    },
    {
      sortBy: "statusChangedTime",
    },
  ],
  // orderBy: "asc"
};

const initialPageNumber = {
  value: 1,
};

const initialRowsPerPage = {
  value: 20,
};

const initialOpenSelectedBatteries = {
  value: false,
};
const initialSelectedBatteriesAction = {
  value: "",
};

const initialTabCounts = {
  value: [],
  active: 0,
  open: 0,
  resolved: 0,
  expired: 0,
};

const initialFilters = {
  value: [],
};

const DashboardFilters = (state = initialFilters, action) => {
  switch (action.type) {
    case "DashboardFilters":
      return {
        value: action.filters,
        severityName:
          action.filters?.severityName || [],
        location:
          Object.values(action.filters).find((x) => x.itemName === "location")?.items ?? [],
        alertType:
          Object.values(action.filters).find((x) => x.itemName === "alertType")?.items ?? [],
        batteryStatus:
          action.filters?.batteryStatus || [],
        fleetName:
          action.filters?.fleetName || [],
        cellModelNumber:
          Object.values(action.filters).find((x) => x.itemName === "cellModelNumber")?.items ??
          [],
        iotOEM:
          Object.values(action.filters).find((x) => x.itemName === "iotOEM")?.items ?? [],
      };
    default:
      return state;
  }
};

const SessionExpired = (state = sessionExpired, action) => {
  switch (action.type) {
    case "SessionExpired":
      return {
        value: action.isExpired,
      };
    default:
      return state;
  }
};

const TimeZoneChanged = (state = timezoneChanged, action) => {
  switch (action.type) {
    case "TimeZoneChanged":
      return {
        value: action.changed,
      };
    default:
      return state;
  }
};

const RefreshAPICalled = (state = refreshAPI, action) => {
  switch (action.type) {
    case "RefreshAPICalled":
      return {
        value: action.refresh,
      };
    default:
      return state;
  }
};

const DashboardType = (state = initialDashboardType, action) => {
  switch (action.type) {
    case "DashboardType":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DashboardTabCounts = (state = initialTabCounts, action) => {
  switch (action.type) {
    case "DashboardTabCounts":
      return {
        value: action.value,
        active: action.value.find((x) => x.alertStatus === "active")?.count || 0,
        open: action.value.find((x) => x.alertStatus === "open")?.count || 0,
        resolved: action.value.find((x) => x.alertStatus === "resolved")?.count || 0,
        expired: action.value.find((x) => x.alertStatus === "expired")?.count || 0,
      };
    default:
      return state;
  }
};

const DashboardColumns = (state = inititalColumns, action) => {
  switch (action.type) {
    case "DashboardColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DashboardHiddenColumns = (state = inititalHiddenColumns, action) => {
  switch (action.type) {
    case "DashboardHiddenColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const IsBatteryIsPartnerIsNull = (
  state = initialIsBatteryIsPartnerIsNull,
  action
) => {
  switch (action.type) {
    case "IsBatteryIsPartnerIsNull":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const DashboardBatteryPartnerSelect = (
  state = initialBatteryPartnerSelect,
  action
) => {
  switch (action.type) {
    case "DashboardBatteryPartnerSelect":
      return {
        value: action.select,
      };
    default:
      return state;
  }
};

const DashboardBatteryID = (state = initialBatteryID, action) => {
  switch (action.type) {
    case "DashboardBatteryID":
      return {
        value: action.batteryID,
      };
    default:
      return state;
  }
};

const DashboardPartner = (state = initialPartner, action) => {
  switch (action.type) {
    case "DashboardPartner":
      return {
        value: action.partner,
      };
    default:
      return state;
  }
};

const DashboardOrganization = (state = initialOrg, action) => {
  switch (action.type) {
    case "DashboardOrganization":
      return {
        value: action.organization,
      };
    default:
      return state;
  }
};

const DashboardCategory = (state = initialCategory, action) => {
  switch (action.type) {
    case "DashboardCategory":
      let category = action.category == 'All' ? "" : action.category;
      return {
        value: category,
      };
    default:
      return state;
  }
};
const DashboardLocation = (state = initialLocation, action) => {
  switch (action.type) {
    case "DashboardLocation":
      return {
        value: action.location,
      };
    default:
      return state;
  }
};
const DashboardStatus = (state = initialStatus, action) => {
  switch (action.type) {
    case "DashboardStatus":
      return {
        value: action.status,
      };
    default:
      return state;
  }
};

const DashboardSeverity = (state = initialSeverity, action) => {
  switch (action.type) {
    case "DashboardSeverity":
      return {
        value: action.severity,
      };
    default:
      return state;
  }
};

const DashboardIOT = (state = initialIot, action) => {
  switch (action.type) {
    case "DashboardIOT":
      return {
        value: action.Iot,
      };
    default:
      return state;
  }
};

const DashboardPack = (state = initialPack, action) => {
  switch (action.type) {
    case "DashboardPack":
      return {
        value: action.pack,
      };
    default:
      return state;
  }
};

const DashboardTabValue = (state = initialTab, action) => {
  switch (action.type) {
    case "DashboardTabValue":
      return {
        value: action.tab,
      };
    default:
      return state;
  }
};

const DashboardAlertTypeIndex = (
  state = initialDashboardAlertTypeIndex,
  action
) => {
  switch (action.type) {
    case "DashboardAlertTypeIndex":
      return {
        value: action.index,
      };
    default:
      return state;
  }
};

const DashboardAlertTypeCode = (
  state = initialDashboardAlertTypeCode,
  action
) => {
  switch (action.type) {
    case "DashboardAlertTypeCode":
      return {
        value: action.code,
      };
    default:
      return state;
  }
};

const DashboardTotal = (state = initialTotal, action) => {
  switch (action.type) {
    case "DashboardTotal":
      return {
        value: action.total,
      };
    default:
      return state;
  }
};
const DashboardStartDate = (state = initialStartDate, action) => {
  switch (action.type) {
    case "DashboardStartDate":
      return {
        value: action.start,
      };
    default:
      return state;
  }
};
const DashboardEndDate = (state = initialEndDate, action) => {
  switch (action.type) {
    case "DashboardEndDate":
      return {
        value: action.end,
      };
    default:
      return state;
  }
};
const DashboardAlertTypeList = (
  state = initialDashboardAlertTypeList,
  action
) => {
  switch (action.type) {
    case "DashboardAlertTypeList":
      return {
        value: action.list,
      };
    default:
      return state;
  }
};
const DashboardListOfBatteries = (
  state = initialDashboardListOfBatteries,
  action
) => {
  switch (action.type) {
    case "DashboardListOfBatteries":
      return {
        value: action.list,
      };
    default:
      return state;
  }
};

const DashboardStack = (state = initialUserState, action) => {
  switch (action.type) {
    case "DashboardStack":
      return {
        ...state,
        arr: action.batteries,
      };
    default:
      return state;
  }
};

const DashboardExistingBatteries = (
  state = initialDashboardExisting,
  action
) => {
  switch (action.type) {
    case "DashboardExistingBatteries":
      return {
        ...state,
        arr: action.batteries,
      };
    default:
      return state;
  }
};

const DashboardColumnSort = (state = initialDashboardColumnSort, action) => {
  switch (action.type) {
    case "DashboardColumnSort":
      return {
        ...state,
        arr: action.sort,
      };
    default:
      return state;
  }
};

const DashboardPageNumber = (state = initialPageNumber, action) => {
  switch (action.type) {
    case "DashboardPageNumber":
      return {
        value: action.page,
      };
    default:
      return state;
  }
};

const DashboardRowsPerPage = (state = initialRowsPerPage, action) => {
  switch (action.type) {
    case "DashboardRowsPerPage":
      return {
        value: action.rows,
      };
    default:
      return state;
  }
};

const DashboardOpenAddSelectedBatteries = (
  state = initialOpenSelectedBatteries,
  action
) => {
  switch (action.type) {
    case "DashboardOpenAddSelectedBatteries":
      return {
        value: action.addBatteries,
      };
    default:
      return state;
  }
};

const DashboardSelectedBatteriesAction = (
  state = initialSelectedBatteriesAction,
  action
) => {
  switch (action.type) {
    case "DashboardSelectedBatteriesAction":
      return {
        value: action.performAction,
      };
    default:
      return state;
  }
};

export {
  SessionExpired,
  TimeZoneChanged,
  RefreshAPICalled,
  DashboardType,
  DashboardColumns,
  DashboardHiddenColumns,
  IsBatteryIsPartnerIsNull,
  DashboardBatteryPartnerSelect,
  DashboardBatteryID,
  DashboardPartner,
  DashboardOrganization,
  DashboardCategory,
  DashboardSeverity,
  DashboardIOT,
  DashboardPack,
  DashboardLocation,
  DashboardStatus,
  DashboardTabValue,
  DashboardAlertTypeIndex,
  DashboardAlertTypeCode,
  DashboardTotal,
  DashboardStartDate,
  DashboardEndDate,
  DashboardAlertTypeList,
  DashboardListOfBatteries,
  DashboardStack,
  DashboardExistingBatteries,
  DashboardColumnSort,
  DashboardPageNumber,
  DashboardRowsPerPage,
  DashboardOpenAddSelectedBatteries,
  DashboardSelectedBatteriesAction,
  DashboardTabCounts,
  DashboardFilters,
};
