import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery20Icon from "@mui/icons-material/Battery20";
import BatteryAlertIcon from "@mui/icons-material/BatteryAlert";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import FlashOffIcon from "@mui/icons-material/FlashOff";
import ElectricMopedIcon from "@mui/icons-material/ElectricMoped";
import NotPinging from "../../../Assets/Icons/Landing/NotPinging.svg";
import ColdCharging from "../../../Assets/Icons/Landing/ColdCharging.svg";
import { ThemeProperties } from "../../../Theme/ThemeProperties";

const setColor = (priority) => {
  // if (priority === 1) return ThemeProperties.dark_red;
  // else if (priority === 2) return ThemeProperties.dark_orange;
  // else return ThemeProperties.dark_yellow;
  return ThemeProperties.white
};

const AlertIcons = [
  {
    id: "OPS-DD-ML",
    icon: (
      <BatteryAlertIcon
        sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 25 },color: setColor(1), }}
      />
    ),
  },
  {
    id: "OPS-DD",
    icon: (
      <Battery0BarIcon
        sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 25 },color: setColor(1) }}
      />
    ),
  },
  {
    id: "SFT-TEMP-IDLE",
    icon: (
      <WarehouseIcon
        sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 25 },color: setColor(1), }}
      />
    ),
  },
  {
    id: "SFT-VOLT",
    icon: (
      <ElectricMeterIcon
        sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 25 },color: setColor(1), }}
      />
    ),
  },
  {
    id: "SFT-TEMP-CHRG",
    icon: (
      <DeviceThermostatIcon
        sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 25 },color: setColor(1), }}
      />
    ),
  },
  {
    id: "OPS-NOT-PING",
    icon: (
      <img
        src={NotPinging}
        className="responsive_Images"
        alt="not pinging"
      />
    ),
  },
  {
    id: "VLN-CC",
    icon: (
      <img
        src={ColdCharging}
        className="responsive_Images"
        alt="cold charging"
      />
    ),
  },
  {
    id: "OPS-NOT-CHRG",
    icon: (
      <FlashOffIcon
        sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 25 },color: setColor(3), }}
      />
    ),
  },
  {
    id: "OPS-LOW-SOC-PRK",
    icon: (
        <ElectricMopedIcon
        sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 25 },color: setColor(2) }}
      />
    ),
  },
  {
    id: "ELSE-BATTERY-20",
    icon: (
        <Battery20Icon
        sx={{fontSize: { xs: 12, sm: 16, md: 25, lg: 25 },color: setColor(2) }}
      />
    ),
  }
];

export {AlertIcons}