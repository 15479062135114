import React, { useState, useEffect } from "react";
import SpecificBatteryMain from "./SpecificBatteryMain";
import { getPagesComponents } from "../../Api/Api";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useLocation } from "react-router-dom";
import Loader from "../../Helper/Loaders/Loader";
import { SetSessionExpired } from "../../Actions";
import { useDispatch } from "react-redux";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";

function SpecificBattery(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = decryptTheParams();


  const [pagesContent, setPagesContent] = useState({
    batteryID: "",
    partner: "",
    tabValue: 0,
    realtimeStatus: {},
    pageHeader: "",
    datePreset: null,
    metaInfo: {
      tabs: [],
      realtimeKPI: [],
    },
    mounted: false,
  });

  useEffect(() => {
    

    setTimeout(() => {
      queryParams &&
        getPagesComponents("Specific Battery").then((res) => {
          if (res.responseStatus.code === 200) {
            const finalId = queryParams["batterySearchedGlobal"] === "true" ? queryParams["batteryIDGlobal"] : queryParams["batteryID"];
            const batteryDeviceSearch = queryParams["batterySearchedGlobal"] === "true" ? "Battery" : "Device";

            let newRealtimeKPI = [],
              newItem;
              res.response.response.metaInfo.realtimeKPI.forEach((item) => {//res.response.response.metaInfo.realtimeKPI
              newItem = Object.assign({}, item, {
                value: "...",
              });
              newRealtimeKPI.push(newItem);
            });
            setPagesContent({
              ...pagesContent,
              batteryID: finalId,
              searchType: batteryDeviceSearch,
              partner: "partner",
              tabName: queryParams["tabName"],
              tabValue: 0,
              pageHeader: res.response.response.textInfo,
              // datePreset: location.state.date ? location.state.date : null,
              metaInfo: {
                editFlag: res.response.response.metaInfo.editFlag,
                tabs: [
                  {
                    "id": 0,
                    "name": "Alerts",
                    "color": "#000",
                    "components": [
                      "Alerts Distribution",
                      "Alerts' Timeline"
                    ]
                  },
                  {
                    "id": 1,
                    "name": "Battery Vitals",
                    "color": "#000",
                    "components": [
                      "Battery Pack Metrics",
                      "Battery Status"
                    ]
                  },
                  {
                    "id": 2,
                    "name": "Health",
                    "color": "#000",
                    "components": [
                      "SOH and Equivalent Cycles"
                    ]
                  },
                  {
                    "id": 3,
                    "name": "Warranty Claims",
                    "color": "#000",
                    "components": [
                      "SOH and Equivalent Cycles"
                    ]
                  }
                ],
                realtimeKPI: [
                  {
                    "key": "alertCount",
                    "name": "Active Alerts",
                    "index": 0,
                    "tabName": "Alerts",
                    "isActive": true,
                    "tabValue": 0,
                    "formatter": ""
                  },
                  {
                    "key": "location",
                    "name": "City",
                    "index": 10,
                    "tabName": "Alerts",
                    "isActive": false,
                    "tabValue": 0,
                    "formatter": ""
                  },
                  {
                    "key": "soc",
                    "name": "SOC",
                    "index": 1,
                    "tabName": "Battery Vitals",
                    "isActive": true,
                    "tabValue": 2,
                    "formatter": "%"
                  },
                  {
                    "key": "soh",
                    "name": "SOH",
                    "index": 2,
                    "tabName": "Health",
                    "isActive": true,
                    "tabValue": 3,
                    "formatter": "%"
                  },
                  {
                    "key": "capacityConf",
                    "name": "Capacity",
                    "index": 2,
                    "tabName": "Health",
                    "isActive": true,
                    "tabValue": 3,
                    "formatter": " Ah"
                  },
                  {
                    "key": "dod",
                    "name": "DOD",
                    "index": 2,
                    "tabName": "Health",
                    "isActive": true,
                    "tabValue": 3,
                    "formatter": "%"
                  },
                  {
                    "key": "iotVendor",
                    "name": "IOT Provider",
                    "index": 5,
                    "tabName": "Alerts",
                    "isActive": false,
                    "tabValue": 0,
                    "formatter": ""
                  },
                  {
                    "key": "zone",
                    "name": "Zone",
                    "index": 11,
                    "tabName": "Alerts",
                    "isActive": false,
                    "tabValue": 0,
                    "formatter": ""
                  },
                  {
                    "key": "batteryStatus",
                    "name": "Status",
                    "index": 7,
                    "tabName": "Battery Vitals",
                    "isActive": true,
                    "tabValue": 2,
                    "formatter": ""
                  },
                  {
                    "key": "cycles",
                    "name": "Eq. Cycles",
                    "index": 8,
                    "tabName": "Health",
                    "isActive": true,
                    "tabValue": 3,
                    "formatter": ""
                  },
                  {
                    "key": "lastPingedTime",
                    "name": "Last Pinged",
                    "index": 6,
                    "tabName": "Alerts",
                    "isActive": false,
                    "tabValue": 0,
                    "formatter": "date"
                  },
                  {
                    "key": "batteryVendor",
                    "name": "Manufacturer",
                    "index": 9,
                    "tabName": "Configuration",
                    "isActive": false,
                    "tabValue": 4,
                    "formatter": ""
                  },


                ]
              },
              mounted: true,
            });
          } else if (res.responseStatus.code === -2) {
            dispatch(SetSessionExpired(true));
          }
        });
    }, 2);

    return () => {
      setPagesContent({
        ...pagesContent,
        batteryID: "",
        partner: "",
        tabValue: 0,
        pageHeader: "",
        datePreset: null,
        metaInfo: {
          tabs: [],
          realtimeKPI: [],
        },
        mounted: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.["batteryIDGlobal"]]);

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <SpecificBatteryMain
            batteryID={pagesContent.batteryID}
            searchType={pagesContent.searchType}
            tabValue={pagesContent.tabValue}
            tabName={pagesContent.tabName}
            pagesContent={pagesContent}
          />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}

export default SpecificBattery;
