import React, { useState } from "react";
import {
  Autocomplete,
  InputAdornment,
  Typography,
  TextField,
} from "@mui/material";
import { CustomTextField } from "../../Components/CustomTextField";
import { CustomChip } from "../../Components/CustomChip";

function ToField(props) {
  const [cc, setCc] = useState(false);
  const [bcc, setBcc] = useState(false);
  const [bccafter, setBccAfter] = useState(false);
  const [ccafter, setCcAfter] = useState(false);

  const handleCc = () => {
    setCc(!cc);
  };
  const handleBcc = () => {
    setBcc(!bcc);
  };
  const handleBccAfter = () => {
    setBccAfter(true);
  };
  const handleCcAfter = () => {
    setCcAfter(true);
  };

  const changeTo = (value) => {
    if (value === "") props.setHelperText(true);
    else props.setHelperText(false);

    if (props.actual_to.includes(value)) {
      props.setTo([value]);
      props.setHelperText(false);
    }
  };
  const onTagsChangeTo = (event, values) => {
    if (values.length === 0) props.setHelperText(true);
    else props.setHelperText(false);
    props.setTo(values);
  };

  const changeCc = (value) => {
    if (props.actual_to.includes(value)) {
      props.setCC(value);
    }
  };
  const onTagsChangeCC = (event, values) => {
    props.setCC(values);
  };

  const onTagsChangeBCC = (event, values) => {
    props.setBCC(values);
  };
  const changeBcc = (value) => {
    if (props.actual_to.includes(value)) {
      props.setBCC(value);
    }
  };

  return (
    <div>
      <div
        style={{
          padding: "5px 0px",
        }}
      >
        {props.helperText === true ? (
          <Autocomplete
            multiple
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            id="tags-outlined-error"
            options={props.actual_to}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomChip {...getTagProps({ index })} label={option} />
              ));
            }}
            onChange={onTagsChangeTo}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="standard"
                placeholder="Select from dropdown list"
                error={true}
                helperText="Must contain atleast one mail"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                      <Typography variant="textSubtitle">To:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                FormHelperTextProps={{
                  style:{
                    fontSize:10
                  }
                }}
                sx={{ input: { fontSize:12 }}} 
              />
            )}
          />
        ) : (
          <Autocomplete
            multiple
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            id="tags-outlined"
            options={props.actual_to}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomChip {...getTagProps({ index })} label={option} />
              ));
            }}
            onChange={onTagsChangeTo}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                variant="standard"
                placeholder="Select from dropdown list"
                onChange={(event) => {
                  changeTo(event.target.value);
                }}
                
                sx={{ fontSize: 15, fontWeight: 500, fontFamily: "Roboto", input: { fontSize:12 } }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <Typography variant="textSubtitle">To:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {!cc && !bcc && (
                        <div style={{ display: "flex"}}>
                          <Typography variant="textSubtitle" onClick={handleCc} sx={{cursor:"pointer"}}>
                            Cc
                          </Typography>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Typography
                            variant="textSubtitle"
                            onClick={handleBcc}
                            sx={{cursor:"pointer"}}
                          >
                            Bcc
                          </Typography>
                        </div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}
      </div>

      {ccafter && (
        <div
          style={{
            padding: "5px 0px",
          }}
        >
          <Autocomplete
            multiple
            id="tags-outlined"
            options={props.actual_to}
            sx={{ input: { fontSize:12 }}} 
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomChip {...getTagProps({ index })} label={option} />
              ));
            }}
            onChange={onTagsChangeCC}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select from dropdown list"
                onChange={(event) => {
                  changeTo(event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  //className: formClasses.input,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                      <Typography variant="textSubtitle">Cc:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
      )}

      {cc && !ccafter && (
        <div
          style={{
            padding: "5px 0px",
          }}
        >
          <Autocomplete
            multiple
            id="tags-outlined"
            sx={{ input: { fontSize:12 }}} 
            options={props.actual_to}
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomChip {...getTagProps({ index })} label={option} />
              ));
            }}
            onChange={onTagsChangeCC}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select from dropdown list"
                onChange={(event) => {
                  changeCc(event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  //className: formClasses.input,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                      <Typography variant="textSubtitle">Cc:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {!bcc && (
                        <div style={{ display: "flex",}}>
                          <Typography
                          variant="textSubtitle"
                            onClick={() => {
                              handleBccAfter();
                              handleBcc();
                            }}
                            sx={{cursor:"pointer"}}
                          >
                            Bcc
                          </Typography>
                        </div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
      )}

      {bcc && !bccafter && (
        <div
          style={{
            padding: "5px 0px",
          }}
        >
          <Autocomplete
            multiple
            id="tags-outlined"
            options={props.actual_to}
            sx={{ input: { fontSize:12 }}} 
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomChip {...getTagProps({ index })} label={option} />
              ));
            }}
            onChange={onTagsChangeBCC}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select from dropdown list"
                onChange={(event) => {
                  changeBcc(event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                      <Typography variant="textSubtitle">Bcc:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {!cc && (
                        <div style={{ display: "flex" }}>
                          <Typography
                          variant="textSubtitle"
                            onClick={() => {
                              handleCcAfter();
                              handleCc();
                            }}
                            sx={{cursor:"pointer"}}
                          >
                            Cc
                          </Typography>
                        </div>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
      )}

      {bccafter && (
        <div
          style={{
            padding: "5px 0px",
          }}
        >
          <Autocomplete
            multiple
            id="tags-outlined"
            options={props.actual_to}
            sx={{ input: { fontSize:12 }}} 
            ListboxProps={{
              sx: { fontSize: 13, fontWeight: 500 },
            }}
            componentsProps={{
              paper: {
                sx: {
                  width: 300,
                },
              },
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option, index) => (
                <CustomChip {...getTagProps({ index })} label={option} />
              ));
            }}
            onChange={onTagsChangeBCC}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select from dropdown list"
                onChange={(event) => {
                  changeBcc(event.target.value);
                }}
                InputProps={{
                  ...params.InputProps,
                  //className: formClasses.input,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                      <Typography variant="textSubtitle">Bcc:</Typography>
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

export default ToField;
