import { SvgIcon } from "@mui/material";
import React from "react";

const ChartCarouselPreviousArrowIcon = (props) => {
  const { height, width, viewBox, fill, ...other } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
    >
      <g clip-path="url(#clip0_222_16)">
        <path d="M14 21.75H8C2.57 21.75 0.25 19.43 0.25 14V8C0.25 2.57 2.57 0.25 8 0.25H14C19.43 0.25 21.75 2.57 21.75 8V14C21.75 19.43 19.43 21.75 14 21.75ZM8 1.75C3.39 1.75 1.75 3.39 1.75 8V14C1.75 18.61 3.39 20.25 8 20.25H14C18.61 20.25 20.25 18.61 20.25 14V8C20.25 3.39 18.61 1.75 14 1.75H8Z" fill={fill} />
        <g clip-path="url(#clip1_222_16)">
          <path d="M12.2599 15.2797C12.0699 15.2797 11.8799 15.2097 11.7299 15.0597L8.19992 11.5297C7.90992 11.2397 7.90992 10.7597 8.19992 10.4697L11.7299 6.93967C12.0199 6.64967 12.4999 6.64967 12.7899 6.93967C13.0799 7.22967 13.0799 7.70967 12.7899 7.99967L9.78992 10.9997L12.7899 13.9997C13.0799 14.2897 13.0799 14.7697 12.7899 15.0597C12.6499 15.2097 12.4599 15.2797 12.2599 15.2797Z" fill={fill} />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_222_16">
          <rect width={width} height={height} fill="white" />
        </clipPath>
        <clipPath id="clip1_222_16">
          <rect width="6" height="10" fill="white" transform="translate(7 6)" />
        </clipPath>
      </defs>
    </svg>
  )
};

ChartCarouselPreviousArrowIcon.defaultProps = {
  width: "24px",
  height: "24px",
  viewBox: "0 0 24 24",
  fill: "#828A92"
};

export default ChartCarouselPreviousArrowIcon;
