import {
  Box,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import { useSelector } from "react-redux";

function ConfigFormBMS({
  isEdit,
  form,
  handleChangeSelect,
  showBmsOEMTextField,
  handleDiscardToDropdown,
  formValidated,
}) {
  const configFilters = useSelector(
    (state) => state.DigitalTwinsConfigFilters.value
  );
  const bmsFilters = configFilters.find((x) => x.type === "bmsOEM")?.value;

  return (
    <FormGroup>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="space-around"
        margin={3}
        rowGap={2}
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Manufacturer <span style={{ color: "red" }}>*</span>
          </FormLabel>
          {showBmsOEMTextField ? (
            <>
              <TextField
                style={{ width: "300px" }}
                size="small"
                variant="outlined"
                value={form?.bmsOEM}
                name="bmsOEM"
                onChange={handleChangeSelect}
                disabled={!isEdit}
                inputProps={{
                  style: { fontSize: 14, padding: 7, marginLeft: 3 },
                }}
                autoFocus
              />
              <IconButton onClick={() => handleDiscardToDropdown("bmsOEM")}>
                <CloseIcon style={{ fontSize: 16 }} />
              </IconButton>
            </>
          ) : (
            <Select
              style={{ width: "300px", fontSize: "small" }}
              size="small"
              variant="outlined"
              name="bmsOEM"
              onChange={handleChangeSelect}
              disabled={!isEdit}
              value={form?.bmsOEM}
              placeholder="Select"
            >
              {bmsFilters?.map((item) => (
                <MenuItem sx={{ fontSize: "small" }} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem
                sx={{ fontSize: "small", color: ThemeProperties.Purple1 }}
                value="%add%"
              >
                Add new
              </MenuItem>
            </Select>
          )}
          {!formValidated?.bmsOEM && (
            <Typography color="red">
              {showBmsOEMTextField && bmsFilters?.includes(form?.bmsOEM)
                ? "Value already exists"
                : "This field is required"}
            </Typography>
          )}
        </Box>
      </Box>
    </FormGroup>
  );
}

export default ConfigFormBMS;
