import qs from "qs";
import { GetData } from "../Functions/SettingData";
import {
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
} from "../../Helper/DatePicker/DateConverter";

//Common

async function getAllBatteriesCount(
  date,
  iotVendor,
  batteryVendor,
  alertType,
  granularity
) {
  var rawJson = {
    startDate: convertZonetoUTCStart(date[0]),
    endDate: convertZonetoUTCEnd(date[1]),

    iotVendor: iotVendor,
    batteryVendor: batteryVendor,
    alertType: alertType,
    granularity: granularity,
  };
  var config = {
    method: "get",
    url: `/api/severity_alerts`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };
  let data = GetData(config);
  return data;
}

async function getBatteriesWithMaxAlerts(
  date,
  alertType,
  alertCode,
  iotVendor,
  batteryVendor
) {
  var rawJson = {
    startDate: convertZonetoUTCStart(date[0]),
    endDate: convertZonetoUTCEnd(date[1]),
    alertType: alertType, //Safety Risk
    alertCode: alertCode, //sft_temp_chrg
    iotVendor: iotVendor,
    batteryVendor: batteryVendor,
  };
  var config = {
    method: "get",
    url: `/api/batteries_with_max_alerts`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };
  let data = GetData(config);
  return data;
}

async function getAlertsOverview(date, alertType, iotVendor, batteryVendor) {
  var rawJson = {
    startDate: convertZonetoUTCStart(date[0]),
    endDate: convertZonetoUTCEnd(date[1]),
    alertType: alertType, //Safety Risk
    iotVendor: iotVendor,
    batteryVendor: batteryVendor,
  };
  var config = {
    method: "get",
    url: `/api/alerts_type_severity_distribution`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };
  let data = GetData(config);
  return data;
}

async function getSeverityAlertsDistribution(
  date,
  iotVendor,
  batteryVendor,
  granularity
) {
  var rawJson = {
    startDate: convertZonetoUTCStart(date[0]),
    endDate: convertZonetoUTCEnd(date[1]),

    iotVendor: iotVendor,
    batteryVendor: batteryVendor,
    granularity: granularity,
  };
  var config = {
    method: "get",
    url: `/api/severity_alerts`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };
  let data = GetData(config);
  return data;
}

async function getSafetyAlertsResolution(
  date,
  alertType,
  iotVendor,
  batteryVendor
) {
  var rawJson = {
    startDate: date[0],
    endDate: date[1],
    alertType: alertType,
    iotVendor: iotVendor,
    batteryVendor: batteryVendor,
  };
  var config = {
    method: "get",
    url: `/api/alerts_resolution_time`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };
  let data = GetData(config);
  return data;
}


export {
  getAllBatteriesCount,
  getBatteriesWithMaxAlerts,
  getAlertsOverview,
  getSeverityAlertsDistribution,
  getSafetyAlertsResolution
};
