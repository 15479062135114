import React, { useEffect, useState } from "react";
import {
  GetTheGranularityTrend,
  GetTheMetric,
} from "../_DownloadCSV/CSVDownload";
import { PaperHighlight } from "../../../../Components/CustomPaper";

import Header from "../../../../Features/Header/Header";
import CODE from "../../../../Static/Constants/StatusCodes";
import Events from "../../../../Analytics/Events";
import TimeSeriesSingleAxis from "../../../../Charts/Line/TimeSeriesSingleAxis";
import {
  convertToZoneDate,
  getChartTimeStamp,
} from "../../../../Helper/DatePicker/DateConverter";
import {
  getGranularityData,
  getPackComparision,
} from "../../../../Api/DigitalTwin";
import {
  formatDateWithoutYear,
  monthDictionary,
} from "../../../../Helper/DatePicker/DateFormatters";
import { metrics } from "./_Constants";

function CellImbalance(props) {
  const hierarchy = props.hierarchy;

  const [cellBalanceData, setCellBalanceData] = useState({
    data: { metric: [], timeStamp: [] },
    responseStatus: {
      code: -1,
      msg: "Loading",
    },
  });
  const [cellBalanceCSV, setcellBalanceCSV] = useState({
    data: [],
    responseStatus: { code: 0 },
  });

  function GetCellBalanceCSV() {
    setcellBalanceCSV({
      data: [],
      responseStatus: {
        code: -1,
      },
    });
    let obj =
      props.granular === "Raw Data" &&
      props.startDate.toLowerCase().indexOf(":") === -1
        ? GetTheMetric(
            props.batteryID,
            hierarchy.selectedPack.value?.toUpperCase(),
            hierarchy.selectedModule.value?.toUpperCase(),
            hierarchy.selectedCell.value?.toUpperCase(),
            hierarchy.level,
            "balanceState",
            [props.dateString[0], props.dateString[1]],
            "Cell Imbalance (%)"
          )
        : GetTheGranularityTrend(
            props.batteryID,
            hierarchy.selectedPack.value,
            hierarchy.selectedModule.value,
            hierarchy.selectedCell.value,
            hierarchy.level,
            ["balanceState"],
            [props.startDate.split(":")[0], props.startDate.split(":")[1]],
            props.granular,
            props.operationType,
            "Cell Imbalance (%)",
            "Single"
          );
    obj.then((res) => {
      if (res?.responseStatus?.code === CODE.SUCCESS)
        setcellBalanceCSV({
          data: res?.data,
          responseStatus: {
            code: res?.responseStatus?.code,
          },
        });
      else
        setcellBalanceCSV({
          data: [],
          responseStatus: {
            code: res?.responseStatus?.code,
          },
        });
    });
  }

  useEffect(() => {
    setCellBalanceData({
      data: { metric: [], timeStamp: [] },
      responseStatus: {
        code: -1,
        msg: "Loading",
      },
    });

    if (props.startDate !== "") {
      if (
        props.granular === "Raw Data" &&
        props.startDate.toLowerCase().indexOf(":") === -1
      ) {
        getPackComparision(
          props.batteryID,
          hierarchy.selectedPack.value?.toUpperCase(),
          hierarchy.selectedModule.value?.toUpperCase(),
          hierarchy.selectedCell.value?.toUpperCase(),
          hierarchy.level,
          "balanceState",
          [props.startDate, props.startDate]
        ).then((res) => {
          if (res?.responseStatus?.code === CODE.SUCCESS) {
            let tempData = [];
            res?.response?.batteryData?.map((item) => {
              tempData.push({
                x: getChartTimeStamp(item.batteryDateTime, true),
                y:
                  item["metricsArray"].find((i) => i.type === "balanceState")
                    ?.value === undefined
                    ? null
                    : item["metricsArray"].find(
                        (i) => i.type === "balanceState"
                      )?.value *
                      metrics.find((item) => item.value === "balanceState")
                        ?.multiplyBy,
              });
            });
            setCellBalanceData({
              data: tempData,
              responseStatus: {
                code: res?.responseStatus?.code,
                msg: res?.responseStatus?.msg,
              },
            });
          } else {
            setCellBalanceData({
              data: [],
              responseStatus: {
                code: res?.responseStatus?.code,
                msg: res?.responseStatus?.msg,
              },
            });
          }
        });
      } else if (
        props.granular !== "Raw Data" &&
        props.startDate.toLowerCase().indexOf(":") !== -1
      ) {
        let date = props.startDate.split(":");

        getGranularityData(
          props.batteryID,
          hierarchy.selectedPack.value,
          hierarchy.selectedModule.value,
          hierarchy.selectedCell.value,
          hierarchy.level,
          ["balanceState"],
          [date[0], date[1]],
          props.granular,
          props.operationType
        ).then((res) => {
          let tempData = [];
          res?.response?.batteryData?.map((item) => {
            tempData.push({
              x:
                props.granular === "Daily"
                  ? formatDateWithoutYear(
                      convertToZoneDate(item["range"]["startDate"])
                    )
                  : props.granular === "Monthly"
                  ? monthDictionary.find(
                      (o) => o.name === item["range"]["startDate"].split("-")[1]
                    )["value"]
                  : [
                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["startDate"])
                        ) + " - ",
                      ],

                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["endDate"])
                        ),
                      ],
                    ],
              y:
                res?.responseStatus?.code === CODE.SUCCESS
                  ? item["metricsArray"][0]["value"] *
                    metrics.find((item) => item.value === "balanceState")
                      ?.multiplyBy
                  : null,
            });
          });

          setCellBalanceData({
            data: tempData,
            responseStatus: {
              code: res?.responseStatus?.code,
              msg: res?.responseStatus?.msg,
            },
          });
        });
      }
    }
  }, [
    props.granular,
    props.operationType,
    props.startDate,
    hierarchy.selectedPack.value,
    hierarchy.selectedModule.value,
    hierarchy.selectedCell.value,
    hierarchy.level,
  ]);

  return (
    <PaperHighlight
      elevation={0}
      onMouseEnter={() => Events("SB Hovered Key Metrics Chart")}
    >
      <Header
        heading={props.generateHeading("Cell Imbalance")}
        description="Showing Cell Imbalance"
        showIButton={true}
        showThreeDots={false}
        showCSVDownload={true}
        csvData={cellBalanceCSV}
        SetCSVData={GetCellBalanceCSV}
        setStatusBack={setcellBalanceCSV}
        id="Cell_Imbalance"
        page="specificBattery"
      />
      <div className="section" id="Cell_Imbalance">
        <TimeSeriesSingleAxis
          data={{
            series: [
              {
                name: "Cell Imbalance (%)",
                data: cellBalanceData.data,
              },
            ],
            colors: ["#B2596E"],
            xaxisType: props.granular === "Raw Data" ? "datetime" : "category",

            yAxisTitle: "Cell Imbalance (%)",
            yAxisColor: "#B2596E",
            ymin: 0,
            ymax: 20,

            toolTipDecimalPlaces: 3,

            responseStatus: {
              code: cellBalanceData.responseStatus.code,
              msg: cellBalanceData.responseStatus.msg,
            },
          }}
          startDate={props.startDate}
          granular={props.granular}
        />
      </div>
    </PaperHighlight>
  );
}

export default CellImbalance;
