import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const BasicTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))(`
      color: white;
      background-color: rgb(28,28,28);
      font-size: 14px;
      font-weight:400;
      font-family: Roboto;
      padding: 10px 5px;
  `);

  export {BasicTooltip}