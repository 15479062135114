const inititalColumns = {
  value: [],
};

const inititalHiddenColumns = {
  value: [],
};
const initialState = {
  value: "",
};
const initialVolt = {
  value: ""
};
const initialCity = {
  value: "",
};
const initialZone = {
  value: "",
};
const initialStatus = {
  value: "",
};
const initialIOT = {
  value: "",
};
const initialPack = {
  value: "",
};
const initialSeverity = {
  value: "",
};
const initialBusiness = {
  value: "",
};
const initialMultiplePartners = {
  value: [],
};
const initialMultiplePartnersDropdown = {
  value: [],
};
const initialMultiplePartnersSelectAll = {
  value: false,
};
const initialUserInputBatteries = {
  value: [],
};
const initialFoundBatteries = {
  value: [],
};
const initialNotFoundBatteries = {
  value: [],
};
const initialBatteryPartnerSelect = {
  value: "Battery ID",
};
const initialPage = {
  value: 1,
};

const initialRowsPerPage = {
  value: 20,
};

const inititalAllBatteriesStack = {
  arr: [],
};

const initialAllBatteriesColumnSort = {
  sortBy: "lastPingedTime",
  // orderBy: "asc"
};
const AllBatteriesColumns = (state = inititalColumns, action) => {
  switch (action.type) {
    case "AllBatteriesColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const AllBatteriesHiddenColumns = (state = inititalHiddenColumns, action) => {
  switch (action.type) {
    case "AllBatteriesHiddenColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};
const AllBatteriesState = (state = initialState, action) => {
  switch (action.type) {
    case "AllBatteriesState":
      return {
        value: action.state,
      };
    default:
      return state;
  }
};
const AllBatteriesSeverity = (state = initialSeverity, action) => {
  switch (action.type) {
    case "AllBatteriesSeverity":
      return {
        value: action.severity,
      };
    default:
      return state;
  }
};
const AllBatteriesVolt = (state = initialVolt, action) => {
  switch (action.type) {
    case "AllBatteriesVolt":
      return {
        value: action.volt,
      };
    default:
      return state;
  }
};
const AllBatteriesCity = (state = initialCity, action) => {
  switch (action.type) {
    case "AllBatteriesCity":
      return {
        value: action.city,
      };
    default:
      return state;
  }
};
const AllBatteriesZone = (state = initialZone, action) => {
  switch (action.type) {
    case "AllBatteriesZone":
      return {
        value: action.zone,
      };
    default:
      return state;
  }
};
const AllBatteriesStatus = (state = initialStatus, action) => {
  switch (action.type) {
    case "AllBatteriesStatus":
      return {
        value: action.status,
      };
    default:
      return state;
  }
};
const AllBatteriesIOT = (state = initialIOT, action) => {
  switch (action.type) {
    case "AllBatteriesIOT":
      return {
        value: action.iot,
      };
    default:
      return state;
  }
};
const AllBatteriesPack = (state = initialPack, action) => {
  switch (action.type) {
    case "AllBatteriesPack":
      return {
        value: action.pack,
      };
    default:
      return state;
  }
};
const AllBatteriesBusiness = (state = initialBusiness, action) => {
  switch (action.type) {
    case "AllBatteriesBusiness":
      return {
        value: action.business,
      };
    default:
      return state;
  }
};
const AllBatteriesMultiplePartners = (
  state = initialMultiplePartners,
  action
) => {
  switch (action.type) {
    case "AllBatteriesMultiplePartners":
      return {
        value: action.partners,
      };
    default:
      return state;
  }
};
const AllBatteriesMultiplePartnersDropdown = (
  state = initialMultiplePartnersDropdown,
  action
) => {
  switch (action.type) {
    case "AllBatteriesMultiplePartnersDropdown":
      return {
        value: action.partnersDropdown,
      };
    default:
      return state;
  }
};
const AllBatteriesMultiplePartnersSelectAll = (
  state = initialMultiplePartnersSelectAll,
  action
) => {
  switch (action.type) {
    case "AllBatteriesMultiplePartnersSelectAll":
      return {
        value: action.partnersSelectAll,
      };
    default:
      return state;
  }
};

const AllBatteriesUserInputBatteries = (
  state = initialUserInputBatteries,
  action
) => {
  switch (action.type) {
    case "AllBatteriesUserInputBatteries":
      return {
        value: action.batteries,
      };
    default:
      return state;
  }
};

const AllBatteriesFoundBatteries = (state = initialFoundBatteries, action) => {
  switch (action.type) {
    case "AllBatteriesFoundBatteries":
      return {
        value: action.batteries,
      };
    default:
      return state;
  }
};

const AllBatteriesNotFoundBatteries = (
  state = initialNotFoundBatteries,
  action
) => {
  switch (action.type) {
    case "AllBatteriesNotFoundBatteries":
      return {
        value: action.batteries,
      };
    default:
      return state;
  }
};

const AllBatteriesBatteryPartnerSelect = (
  state = initialBatteryPartnerSelect,
  action
) => {
  switch (action.type) {
    case "AllBatteriesBatteryPartnerSelect":
      return {
        value: action.select,
      };
    default:
      return state;
  }
};

const AllBatteriesPageNumber = (state = initialPage, action) => {
  switch (action.type) {
    case "AllBatteriesPageNumber":
      return {
        value: action.page,
      };
    default:
      return state;
  }
};

const AllBatteriesStack = (state = inititalAllBatteriesStack, action) => {
  switch (action.type) {
    case "AllBatteriesStack":
      return {
        ...state,
        arr: action.batteries,
      };
    default:
      return state;
  }
};

const KPISort = (state = initialAllBatteriesColumnSort, action) => {
  switch (action.type) {
    case "KPIColumnSort":
      return {
        sortBy: action.sort,
      };
    default:
      return state;
  }
};

const AllBatteriesRowsPerPage = (state = initialRowsPerPage, action) => {
  switch (action.type) {
    case "AllBatteriesRowsPerPage":
      return {
        value: action.rows,
      };
    default:
      return state;
  }
};
export {
  AllBatteriesColumns,
  AllBatteriesHiddenColumns,
  AllBatteriesState,
  AllBatteriesSeverity,
  AllBatteriesVolt,
  AllBatteriesCity,
  AllBatteriesZone,
  AllBatteriesStatus,
  AllBatteriesBusiness,
  AllBatteriesIOT,
  AllBatteriesPack,
  AllBatteriesMultiplePartners,
  AllBatteriesMultiplePartnersDropdown,
  AllBatteriesMultiplePartnersSelectAll,
  AllBatteriesUserInputBatteries,
  AllBatteriesFoundBatteries,
  AllBatteriesNotFoundBatteries,
  AllBatteriesBatteryPartnerSelect,
  AllBatteriesPageNumber,
  AllBatteriesStack,
  KPISort,
  AllBatteriesRowsPerPage
};
