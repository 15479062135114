import React from "react";
import { NavLink as NavLinkBase } from "react-router-dom";
import { ListItem, ListItemIcon, Typography } from "@mui/material";
import Events from "../../../Analytics/Events";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { SetDashboardOpenAddSelectedBatteries } from "../../../Actions/Dashboard"
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import CustomIcon from "../../../Components/CustomIcon"

const NavLink = React.forwardRef((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={props.activeClassName}
    exact="true"
  />
));

const MyNavLink = (props) => {
  const dispatch = useDispatch();
  const universal = useSelector((state) => state.DashboardStack.arr);
  const isActivePage = window.location.pathname === props.to || (window.location.pathname === '/' && props.to === '/DigitalTwin')
  return (
    <ListItem
      component={NavLink}
      activeClassName={({ isActive }) =>
        isActive || isActivePage ? "activeClass" : "inactiveClass"
      }
      to={props.to}
      onClick={(event) => {
        let itemIDS = [];
        universal.map((item) => itemIDS.push(...item.itemIDs));

        if (itemIDS.length > 0) {
          event.preventDefault();
          dispatch(SetDashboardOpenAddSelectedBatteries(true));
        }
        Events("clicked " + props.textPrimary);
      }}
      key={props.mykey}
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <Tooltip
        placement="right"
        title={!props.open ? props.textPrimary : ""}
        zIndex={2000}
      >
        <ListItem
          key={props.mykey}
          sx={{
            marginLeft: props.open ? -2.5 : 0.8,
          }}
        >
          <ListItemIcon
            style={{
              marginLeft: props.open ? null : "-10px"
            }}
          >
            <CustomIcon
              name={props.iconName}
              fill={isActivePage ? "#4D44D8" : "#8D9FB2"}
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            />
          </ListItemIcon>
          {props.open && (
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Roboto",
                marginLeft: "-17px",
                lineHeight: "24px",
                fontWeight: isActivePage ? 600 : 400,
                color:
                  isActivePage ? ThemeProperties.purple : ThemeProperties.black,
              }}
            >
              {props.textPrimary}
            </Typography>
          )}
        </ListItem>
      </Tooltip>
    </ListItem>
  );
};

export default MyNavLink;
