import qs from "qs";
import { GetData } from "./Functions/SettingData";
import {
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
} from "../Helper/DatePicker/DateConverter";
import { areArraysEqual } from "../Helper/Operations/ArrayOps";

async function getAlertTypeList(
  tabValue,
  category,
  searchID,
  searchType,
  startDate,
  endDate,
  filters,
  defaultFilterValues={}
) {
  let alertState =
    tabValue === 0
      ? "Active"
      : tabValue === 1
        ? "Open"
        : tabValue === 2
          ? "Resolved"
          : tabValue === 3
            ? "Expired"
            : "Ignored";

  var rawJson = {
    location: getFilterValue("location", filters.location, defaultFilterValues),
    batteryStatus: getFilterValue(
      "batteryStatus",
      filters.batteryStatus,
      defaultFilterValues
    ),
    fleetName: getFilterValue(
      "fleetName",
      filters.fleetName,
      defaultFilterValues
    ),
    severity: getFilterValue(
      "severityName",
      filters.severityName,
      defaultFilterValues
    ),
    iotVendor: getFilterValue("iotOEM", filters.iotOEM, defaultFilterValues),
    pingStatus: getFilterValue(
      "pingStatus",
      filters.pingStatus,
      defaultFilterValues
    ),
    configName: getFilterValue(
      "configName",
      filters.configName,
      defaultFilterValues
    ),
    alertCode: "",
    product: filters.cellModelNumber,
    alertStatus: alertState,
    alertType: category,
    startDate: startDate ? convertZonetoUTCStart(startDate) : null,
    endDate: endDate ? convertZonetoUTCEnd(endDate) : null,
    batteryID: searchID,
    batteryType: searchType,
    selectedFilters: filters,
  };
  var config = {
    method: "get",
    // url: `/api/alert_group_count/v2`,
    url: `/api/alert/alert_code_list_count`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  // let data = alertTypeResponse;

  let data = GetData(config);
  return data;
}

const getFilterValue = (filterKey, filterValue, defaultFilterValues) => {
  let areArraysSame = areArraysEqual(defaultFilterValues?.[filterKey] || [], filterValue)
  
  return areArraysSame ? [] : filterValue
}

async function getTableBatteries(
  id,
  tabValue,
  alertCode,
  category,
  batteryID,
  searchType,
  startDate,
  endDate,
  currentPage,
  sortBy,
  orderBy,
  rowsPerPage,
  batteryType,
  filters,
  defaultFilterValues={}
) {
  let alertState =
    tabValue === 0
      ? "Active"
      : tabValue === 1
        ? "Open"
        : tabValue === 2
          ? "Resolved"
          : tabValue === 3
            ? "Expired"
            : "Ignored";

  var rawJson = {
    id: id,
    location: getFilterValue("location", filters.location, defaultFilterValues),
    batteryStatus: getFilterValue(
      "batteryStatus",
      filters.batteryStatus,
      defaultFilterValues
    ),
    fleetName: getFilterValue(
      "fleetName",
      filters.fleetName,
      defaultFilterValues
    ),
    severity: getFilterValue(
      "severityName",
      filters.severityName,
      defaultFilterValues
    ),
    iotVendor: getFilterValue("iotOEM", filters.iotOEM, defaultFilterValues),
    pingStatus: getFilterValue(
      "pingStatus",
      filters.pingStatus,
      defaultFilterValues
    ),
    configName: getFilterValue(
      "configName",
      filters.configName,
      defaultFilterValues
    ),
    alertStatus: alertState,
    priority: "",
    partner: "",
    alertCode: alertCode,
    alertType: category,
    startDate: startDate === "" ? startDate : convertZonetoUTCStart(startDate),
    endDate: endDate === "" ? endDate : convertZonetoUTCEnd(endDate),
    assetID: "",
    batteryID: batteryID,
    batteryType: batteryType,
    searchType: searchType,
    pageCount: rowsPerPage,
    sortBy: sortBy,
    order: orderBy,
    selectedFilters: filters,
  };

  var config = {
    method: "get",
    url: `/api/alert/alerts_item_list/${currentPage}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  // let data = alertListReponse;
  let data = GetData(config);
  return data;
}

async function getDashboardKPI(batteryType) {
  var config = {
    method: "get",
    url: "/api/battery_count",
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      batteryType: batteryType,
    },
  };
  let data = GetData(config);
  return data;
}

async function getBatteryPartnerList(columnName, search, limit, tabvalue, startDate, endDate) {
  let alertState =
    tabvalue === 0
      ? "Active"
      : tabvalue === 1
        ? "Open"
        : tabvalue === 2
          ? "Resolved"
          : tabvalue === 3
            ? "Expired"
            : "Ignored";

  var rawJson = {
    columnName: columnName,
    columnSearch: search,
    columnLimit: limit,
    alertStatus: alertState,
    startDate: startDate ? convertZonetoUTCStart(startDate) : null,
    endDate: endDate ? convertZonetoUTCEnd(endDate) : null,
  };
  var config = {
    method: "get",
    url: `/api/alert/list_search`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getSelectAllBatteries(
  id,
  location,
  zone,
  volt,
  category,
  alertCode,
  batteryStatus,
  severityName,
  iotOEM,
  batteryVendor,
  tabvalue,
  priority,
  batteryState,
  date,
  batteryID,
  partner,
  searchType,
  defaultFilterValues={},
  selectedFilters,
) {
  let alertState =
    tabvalue === 0
      ? "Active"
      : tabvalue === 1
        ? "Open"
        : tabvalue === 2
          ? "Resolved"
          : tabvalue === 3
            ? "Expired"
            : "Ignored";

  var rawJson = {
    id: id,
    location: getFilterValue('location', location, defaultFilterValues),
    zone: zone,
    voltConf: volt,
    alertCode: alertCode,
    batteryStatus: getFilterValue('batteryStatus', batteryStatus, defaultFilterValues),
    severity: getFilterValue('severityName', severityName, defaultFilterValues),
    iotVendor: getFilterValue('iotOEM', iotOEM, defaultFilterValues),
    batteryVendor:  getFilterValue('packOEM', batteryVendor, defaultFilterValues),
    priority: priority,
    alertStatus: alertState,
    batteryState: "",
    alertType: category,
    startDate: date[0] === "" ? date[0] : convertZonetoUTCStart(date[0]),
    endDate: date[1] === "" ? date[1] : convertZonetoUTCEnd(date[1]),
    assetID: "",
    batteryID: batteryID,
    partner: partner,
    searchType: searchType,
    batteryFlag: [0],
    selectedFilters: selectedFilters
  };
  var config = {
    method: "get",
    url: `/api/alert/item_ids`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getTabCounts(
  startDate, 
  endDate, 
  batteryID, 
  filters, 
  defaultFilterValues
) {
  var rawJson = {
    batteryID,
    startDate: startDate ? convertZonetoUTCStart(startDate) : null,
    endDate: endDate ? convertZonetoUTCEnd(endDate) : null,
    location: getFilterValue("location", filters.location, defaultFilterValues),
    batteryStatus: getFilterValue(
      "batteryStatus",
      filters.batteryStatus,
      defaultFilterValues
    ),
    fleetName: getFilterValue(
      "fleetName",
      filters.fleetName,
      defaultFilterValues
    ),
    severity: getFilterValue(
      "severityName",
      filters.severityName,
      defaultFilterValues
    ),
    iotVendor: getFilterValue("iotOEM", filters.iotOEM, defaultFilterValues),
    pingStatus: getFilterValue(
      "pingStatus",
      filters.pingStatus,
      defaultFilterValues
    ),
    configName: getFilterValue(
      "configName",
      filters.configName,
      defaultFilterValues
    ),
  };
  var config = {
    method: "get",
    url: `/api/alert/state_count`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getDashboardFilters(filtersArray) {
  const rawJson = {
    filters: filtersArray || [
      "alertType"
    ],
  };

  var config = {
    method: "get",
    url: `/api/alert/filter_list`,
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config, true);
  return data;
}

export {
  getTableBatteries,
  getAlertTypeList,
  getDashboardKPI,
  getBatteryPartnerList,
  getSelectAllBatteries,
  getTabCounts,
  getDashboardFilters,
};
