export const SetGlobalBatterySearched = (value) =>
{
    return {
        type: "GLobalBatteryID",
        battery: value
    }
}

export const SetSessionExpired = (value) =>
{
    return {
        type: "SessionExpired",
        isExpired: value
    }
}

export const SetRefreshAPICalled = (refresh) =>
{
    return {
        type: "RefreshAPICalled",
        refresh: refresh
    }
}

export const SetPassChangedSuccess = (value) =>
{
    return {
        type: "PassChangedSuccess",
        passChanged: value
    }
}

export const SetGlobalBatteryTypedValue = (value) =>
{
    return {
        type: "GLobalBatteryTypedValue",
        battery: value
    }
}

export const SetIsDeleted = (value) =>
{
    return {
        type: "IsDeletedFromCart",
        isDeleted: value
    }
}

export const SetTableOrder = (order) =>
{
    return{
        type: "ColumnOrder",
        order:order
    }
}

export const SetTimeZoneChanged = (changed) =>
{
    return{
        type: "TimeZoneChanged",
        changed:changed
    }
}