import { SvgIcon } from "@mui/material"
import React from "react"

const LocationSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 6.72727C15 11.1818 9 15 9 15C9 15 3 11.1818 3 6.72727C3 5.20831 3.63214 3.75155 4.75736 2.67748C5.88258 1.60341 7.4087 1 9 1C10.5913 1 12.1174 1.60341 13.2426 2.67748C14.3679 3.75155 15 5.20831 15 6.72727Z"
          fill="#FAFAFA"
          stroke={stroke}
          stroke-width="0.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9 9C10.1046 9 11 8.10457 11 7C11 5.89543 10.1046 5 9 5C7.89543 5 7 5.89543 7 7C7 8.10457 7.89543 9 9 9Z"
          stroke={stroke}
          stroke-width="0.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

LocationSvg.defaultProps = {
  width: "18",
  height: "16",
  viewBox: "0 0 18 16",
  fill: "none",
  stroke: "#444B51"
}

export default LocationSvg
