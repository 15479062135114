const colors = [
  "#7856FF",
  "#FF7557",
  "#80E1D9",
  "#F8BC3B",
  "#B2596E",
  "#3BA974",
];

const metrics = [
  {
    key: "Current",
    value: "current",
    yAxisTitle: "Current (A)",
    ymin: -20,
    ymax: 20,
    decimalPlaces: 1,
    multiplyBy: 1,
    yAxisDecimalPlaces: 2,
    unit: "A",
  },
  {
    key: "Voltage",
    value: "voltage",
    yAxisTitle: "Voltage (V)",
    ymin: 20,
    ymax: 35,
    decimalPlaces: 3,
    multiplyBy: 1,
    yAxisDecimalPlaces: 0,
    unit: "V",
  },
  {
    key: "SOC",
    value: "soc",
    yAxisTitle: "SOC (%)",
    ymin: 0,
    ymax: 100,
    decimalPlaces: 1,
    multiplyBy: 1,
    yAxisDecimalPlaces: 0,
    unit: "%",
  },
  {
    key: "Temperature",
    value: "temperature",
    yAxisTitle: "Temperature (°C)",
    ymin: -20,
    ymax: 80,
    decimalPlaces: 1,
    multiplyBy: 1,
    yAxisDecimalPlaces: 0,
    unit: "°C",
  },
  {
    key: "Capacity",
    value: "capacity",
    yAxisTitle: "Capacity (Ah)",
    ymin: 0,
    ymax: 125,
    decimalPlaces: 1,
    multiplyBy: 1,
    yAxisDecimalPlaces: 0,
    unit: "Ah",
  },
  {
    key: "Cell Imbalance",
    value: "balanceState",
    yAxisTitle: "Cell Imbalance (%)",
    ymin: 0,
    ymax: 20,
    decimalPlaces: 3,
    multiplyBy: 100,
    yAxisDecimalPlaces: 0,
    unit: "%",
  },
  {
    key: "Energy",
    value: "energy",
    yAxisTitle: "Energy (kWh)",
    ymin: 0,
    ymax: 4,
    decimalPlaces: 3,
    multiplyBy: 0.001,
    yAxisDecimalPlaces: 1,
    unit: "kWh",
  },
];

const thresholds = {
  pack: {
    Voltage: {
      min: 20,
      max: 35,
    },
    Current: {
      min: -20,
      max: 20,
    },
    SOC: {
      min: 0,
      max: 100,
    },
    Temperature: {
      min: -20,
      max: 80,
    },
  },
  module: {
    Voltage: {
      min: 0,
      max: 10,
    },
    Current: {
      min: -20,
      max: 20,
    },
    SOC: {
      min: 0,
      max: 100,
    },
    Temperature: {
      min: -20,
      max: 80,
    },
  },
  cell: {
    Voltage: {
      min: 0,
      max: 5,
    },
    Current: {
      min: -20,
      max: 20,
    },
    SOC: {
      min: 0,
      max: 100,
    },
    Temperature: {
      min: -20,
      max: 80,
    },
  },
};

const chargerVitalThresholds = {
  voltage: {
    min: 0,
    max: 100,
  },
  current: {
    min: -100,
    max: 100,
  },
  power: {
    min: -100,
    max: 300,
  },
  temperature: {
    min: -20,
    max: 80
  }
}

const operations = [
  {
    key: "Min",
    label: "Minimum Values",
    value: "min",
  },
  {
    key: "Avg",
    label: "Average Values",
    value: "avg",
  },
  {
    key: "Max",
    label: "Maximum Values",
    value: "max",
  },
];

const tableFilters = [
  {
    key: "Raw",
    label: "Raw",
    value: "Raw Data",
    dateDiffDisableCondition: 0
  },
  {
    key: "D",
    label: "Day",
    value: "Daily",
    dateDiffDisableCondition: 3
  },
  {
    key: "W",
    label: "Week",
    value: "Weekly",
    dateDiffDisableCondition: 14
  },
  {
    key: "M",
    label: "Month",
    value: "Monthly",
    dateDiffDisableCondition: 31
  },
];


const alertsFilters = [
  {
    key: "Critical Alerts",
    value: "critical_alerts",
  },
  {
    key: "High Severity",
    value: "high_severity",
  },
  {
    key: "Low Severity",
    value: "low_severity",
  },
  {
    key: "Active Alerts",
    value: "active_alerts",
  },
  {
    key: "BMS Errors",
    value: "bms_errors",
  },
]

const alertsSorts = [
  {
    key: "High Priority",
    value: "high_priority"
  },
  {
    key: "Latest Pinged",
    value: "latest_pinged"
  },
  {
    key: "Severity: High to Low",
    value: "severity_high_to_low"
  },
  {
    key: "Severity: Low to High",
    value: "severity_low_to_high"
  },
]

export { colors, metrics, thresholds, operations, tableFilters, alertsFilters, alertsSorts, chargerVitalThresholds };
