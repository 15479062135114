import {
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
} from "../Helper/DatePicker/DateConverter";
import qs from "qs";
import { GetData } from "./Functions/SettingData";

async function getAlertTypeList(IOT, pack, cycle, age, soh) {
  var rawJson = {
    iotVendor: IOT,
    warrantyStatus: "Active",
    batteryVendor: pack,
    eqCycleStart: cycle.split(":")[0],
    eqCycleEnd: cycle.split(":")[1],
    ageStart: age.split(":")[0],
    ageEnd: age.split(":")[1],
    sohStart: soh.split(":")[0],
    sohEnd: soh.split(":")[1],
  };
  var config = {
    method: "get",
    url: `/api/warranty_count`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getTableBatteries(
  warrantyCode,
  IOT,
  pack,
  cycle,
  age,
  soh,
  currentPage,
  rowsPerPage,
  sortChangedTo,
  orderChangedTo
) {
  var rawJson = {
    iotVendor: IOT,
    itemID: [],
    warrantyCode: warrantyCode,
    warrantyStatus: "Active",
    batteryVendor: pack,
    eqCycleStart: cycle.split(":")[0],
    eqCycleEnd: cycle.split(":")[1],
    ageStart: age.split(":")[0],
    ageEnd: age.split(":")[1],
    sohStart: soh.split(":")[0],
    sohEnd: soh.split(":")[1],
    pageCount: rowsPerPage,
    sortBy: sortChangedTo,
    order: orderChangedTo,
  };
  var config = {
    method: "get",
    url: `/api/warranty_list/${currentPage}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getBatteryPartnerList(tabvalue) {
  let anomalyState = tabvalue === 0 ? "Open" : "Resolved";

  var rawJson = {
    alertState: "",
    batteryState: "",
    errorState: "",
    anomalyState: "",
    warrantyState: "True",
  };
  var config = {
    method: "get",
    url: `/api/battery_partner_list`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };
  let data = GetData(config);
  return data;
}

async function getCSVOfBatteries(batteryID, iot, pack, cycle, age, soh,WarrantyCode) {
  var rawJson = {
    // voltConf: "",
    // warrantyCode: "",
    // cellType: "",
    // startDate: "",
    // endDate: "",
    // batteryID: batteryID,
    // partner: "",
    // buildNo: [],
    // batteryType: batteryType,
    // pageCount: batteryID.length,
    // sortBy: "warrantyExpiry",
    // order: "asc",

    iotVendor: iot,
    itemID: batteryID,
    warrantyCode: WarrantyCode,
    warrantyStatus: "",
    batteryVendor: pack,
    eqCycleStart: cycle.split(":")[0],
    eqCycleEnd: cycle.split(":")[1],
    ageStart: age.split(":")[0],
    ageEnd: age.split(":")[1],
    sohStart: soh.split(":")[0],
    sohEnd: soh.split(":")[1],
    pageCount: batteryID.length,
    sortBy: "onboardingDate",
    order: "asc",
  };

  var config = {
    method: "post",
    url: `/api/warranty_list/1`,
    headers: {
      "Content-Type": "application/json",
    },
    data: rawJson,
  };
  let data = GetData(config);
  return data;
}

async function postSelectAllBatteries(
  warrantyCode,
  IOT,
  pack,
  cycle,
  age,
  soh
) {
  var rawJson = {
    iotVendor: IOT,
    warrantyCode: warrantyCode,
    warrantyStatus: "Active",
    batteryVendor: pack,
    eqCycleStart: cycle.split(":")[0],
    eqCycleEnd: cycle.split(":")[1],
    ageStart: age.split(":")[0],
    ageEnd: age.split(":")[1],
    sohStart: soh.split(":")[0],
    sohEnd: soh.split(":")[1],
  };
  var config = {
    method: "get",
    url: `/api/global_selection_warranty`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    // paramsSerializer: (params) =>
    //   qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getBatteryWarrantyDetails(deviceID) {
  var rawJson = {
    iotVendor: "",
    warrantyCode: "",
    warrantyStatus: "Active",
    batteryVendor: "",
    eqCycleStart: "",
    eqCycleEnd: "",
    ageStart: "",
    ageEnd: "",
    sohStart: "",
    sohEnd: "",
    batteryID:[deviceID],
    pageCount: 9999999,
    sortBy: "onboardingDate",
    order: "asc",
  };
  var config = {
    method: "get",
    url: `/api/warranty_list/1`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

export {
  getBatteryPartnerList,
  postSelectAllBatteries,
  getAlertTypeList,
  getTableBatteries,
  getCSVOfBatteries,
  getBatteryWarrantyDetails
};
