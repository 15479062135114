export const SetTableColumns = (data = []) => {
  return {
    type: 'tableColumns',
    value: data,
  };
};

export const SetDialogBoxTableColumns = (data = []) => {
  return {
    type: 'dialogBoxTableColumns',
    value: data,
  };
};

export const SetTablePageNumber = (data = 1) => {
  return {
    type: 'tablePageNumber',
    value: data,
  };
};

export const SetTableRowsPerPage = (data = 20) => {
  return {
    type: 'tableRowsPerPage',
    value: data,
  };
};

export const SetDialogTablePageNumber = (data = 1) => {
  return {
    type: 'dialogTablePageNumber',
    value: data,
  };
};

export const SetDialogTableRowsPerPage = (data = 20) => {
  return {
    type: 'dialogTableRowsPerPage',
    value: data,
  };
};