export const SetIsBatteryIsPartnerIsNullAnomaly = (value) =>
{
    return {
        type: "IsBatteryIsPartnerIsNullAnomaly",
        value: value
    }
}
export const SetAnomalyBatteryID = (batteryID) =>
{
    return {
        type: "AnomalyBatteryID",
        batteryID: batteryID
    }
}
export const SetAnomalyPartner = (partner) =>
{
    return {
        type: "AnomalyPartner",
        partner: partner
    }
}

export const SetAnomalyVolt = (volt) =>
{
    return {
        type: "AnomalyVolt",
        volt: volt
    }
}
export const SetAnomalyCell = (cell) =>
{
    return {
        type: "AnomalyCell",
        cell: cell
    }
}
export const SetAnomalyCity = (city) =>
{
    return {
        type: "AnomalyCity",
        city: city
    }
}
export const SetAnomalyZone = (zone) =>
{
    return {
        type: "AnomalyZone",
        zone: zone
    }
}
export const SetAnomalyVersion = (version) =>
{
    return {
        type: "AnomalyVersion",
        version: version
    }
}
export const SetAnomalyBuildNo = (buildNo) =>
{
    return {
        type: "AnomalyBuildNo",
        payload: buildNo
    }
}
export const SetAnomalyStatus = (status) =>
{
    return {
        type: "AnomalyStatus",
        status: status
    }
}

export const SetAnomalyTabValue = (tab) =>
{
    return {
        type: "AnomalyTabValue",
        tab: tab
    }
}

export const SetAnomalyIndex = (index) =>
{
    return {
        type: "AnomalyIndex",
        index: index
    }
}

export const SetAnomalyCode = (code) =>
{
    return {
        type: "AnomalyCode",
        code: code
    }
}

export const SetAnomalyTotal = (total) =>
{
    return {
        type: "AnomalyTotal",
        total: total
    }
}

export const SetAnomalyStartDate = (start) =>
{
    return {
        type: "AnomalyStartDate",
        start: start
    }
}

export const SetAnomalyEndDate = (end) =>
{
    return {
        type: "AnomalyEndDate",
        end: end
    }
}

export const SetAnomalyList = (list) =>
{
    return {
        type: "AnomalyList",
        list: list
    }
}

export const SetAnomalyListOfBatteries = (list) =>
{
    return {
        type: "AnomalyListOfBatteries",
        list: list
    }
}

export const SetAnomalyStack = (batteries) =>
{
    return{
        type: "AnomalyStack",
        batteries:batteries
    }
}

export const SetAnomalyColumnSort = (sort) =>
{
    return{
        type: "AnomalyColumnSort",
        sort:sort
    }
}
export const SetAnomalyColumns = (columns) =>
{
    return {
        type: "AnomalyColumns",
        value: columns
    }
}
export const SetAnomalyHiddenColumns = (hidden) =>
{
    return {
        type: "AnomalyHiddenColumns",
        value: hidden
    }
}
export const SetAnomalyPageNumber = (page) =>
{
    return {
        type: "AnomalyPageNumber",
        page: page
    }
}

export const SetAnomalyRowsPerPage = (rows) =>
{
    return {
        type: "AnomalyRowsPerPage",
        rows: rows
    }
}