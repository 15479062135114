import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import { AnalyticsFilterContainer, AnalyticsFilterSectionBox, CalendarBox, FilterSectionClearAllText, FilterSectionTitle, FilterTitleContainer, FilterTitleText, GranularityBox, GranularityContainer } from "./Components/StyledComponent"
import AntDatePicker from "../../Helper/DatePicker/AntDatePicker"
import AccordionTypeGenericFilter from "./Components/AccordionTypeGenericFilter"
import { analyticsDisabledDate, getCurrentMonthStartAndEndDate, getDatePickerDate, getPreviousMonthDate } from "../../Helper/DatePicker/DateConverter";
import { decryptTheParams, updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { SetAlertAnalyticsEndDate, SetAlertAnalyticsStartDate } from "../../Actions/AlertAnalytics";
import { useNavigate } from "react-router-dom";
import { SetFilterValues } from "../../Actions/Filters";
import CustomIcon from "../../Components/CustomIcon";
import dayjs from "dayjs";
import { rangePresets } from "../../Static/Data/AlertAnalytics/AlertAnalytics";

function FilterSection({ pagesContent, granularity, setGranularity }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = decryptTheParams()
  const allGranularity = [
    { label: 'Daily', shortLabel: "D", value: 'daily' },
    { label: 'Weekly', shortLabel: "W", value: 'weekly' },
    { label: 'Monthly', shortLabel: "M", value:'monthly' }
  ]
  const [open, setOpen] = useState(false);
  const [isCalendarHover, setIsCalendarHover] = React.useState(false);
  const defaultStartDate = getPreviousMonthDate(getDatePickerDate(undefined, -1))
  const defaultEndDate = getDatePickerDate(undefined, -1)
  const calendarStartDate = useSelector((state) => state.AlertAnalyticsStartDate.value) || defaultStartDate;
  const calendarEndDate = useSelector((state) => state.AlertAnalyticsEndDate.value) || defaultEndDate;
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);

  const handleCalendarOpen = (open) => {
    if (!open) {
      setIsCalendarHover(false);
    }
    setOpen(open)
  }

  const onDateChange = (startDate, endDate) => {
    dispatch(SetAlertAnalyticsStartDate(startDate));
    dispatch(SetAlertAnalyticsEndDate(endDate));
    updateQueryParams(navigate, {'startDate': startDate, 'endDate': endDate});
  }

  const resetFilters = () => {
    dispatch(SetFilterValues(defaultFilterValues))
    dispatch(SetAlertAnalyticsStartDate(defaultStartDate));
    dispatch(SetAlertAnalyticsEndDate(defaultEndDate));
    setGranularity({granularity: 'daily'})
    updateQueryParams(navigate, {
      filters: ""
    })
  }
  return (
    <AnalyticsFilterContainer>
      <FilterTitleContainer>
        <FilterSectionTitle>Filter</FilterSectionTitle>
        <FilterSectionClearAllText onClick={resetFilters}>Clear all</FilterSectionClearAllText>
      </FilterTitleContainer>
      <AnalyticsFilterSectionBox>
        <FilterTitleText>Date Range</FilterTitleText>        
        <CalendarBox className="analytics-drill-down-calendar analytics-calendar analytics-calendar-no-filters">
          <AntDatePicker
            popupClassName="analytics-calendar-popup"
            changeTheDate={onDateChange}
            date={[calendarStartDate, calendarEndDate]}
            allowClear={true}
            onOpenChange={handleCalendarOpen}
            onMouseEnter={() => setIsCalendarHover(true)}
            onMouseLeave={() => setIsCalendarHover(false)}
            disabledDate={analyticsDisabledDate}
            rangePresets={[
              ...rangePresets, 
              {
                label: "Reset",
                value: [dayjs(defaultStartDate), dayjs(defaultEndDate)]
              } 
            ]}
            suffixIcon={<CustomIcon stroke={open || isCalendarHover ? "#6D72F6" : "#444B51"} name="calendar"/>}
          />
        </CalendarBox>
        <GranularityContainer>
          {allGranularity.map((item) => (
            <GranularityBox
              onClick={() => setGranularity({granularity: item.value})}
              isSelected={granularity.granularity === item.value}
            >
              {item.shortLabel}
            </GranularityBox>
          ))}
        </GranularityContainer>
      </AnalyticsFilterSectionBox>
      <AccordionTypeGenericFilter 
        filtersConfig={pagesContent.filterConfigurations?.[queryParams?.chartName]?.filters}
      />
    </AnalyticsFilterContainer>
  )
}

export default FilterSection
