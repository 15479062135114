import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography, Button } from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ReorderRoundedIcon from "@mui/icons-material/ReorderRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { disableMetricsForHierarchyLevel } from "../../PagesContent/DigitalTwinV2/TabSection/_Constants";

const BatteryMetricSelect = (props) => {
  const { availableMetrics, selectedMetrics, onClickAddMetric, onClickRemoveMetric, selectedHierarchyLevel, ...other } =
    props;
  const [isMetricSelectBoxOpen, setIsMetricSelectBoxOpen] = useState(false);
  const [countOfSelectedMetrics, setCountOfSelectedMetrics] = useState(
    selectedMetrics.filter((item) => item?.status === "active").length
  );
  const dropDownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleOnClickRemoveMetric = (item, index) => {
    if (item) {
      onClickRemoveMetric(item, index);
    }
  };

  const handleOnClickAddMetric = (item, index) => {
    if (countOfSelectedMetrics !== 4) {
      onClickAddMetric(item, index);
    }
  };

  useEffect(() => {
    const count = selectedMetrics.filter(
      (item) => item?.status === "active"
    ).length;
    setCountOfSelectedMetrics(count);
  }, [selectedMetrics]);

  // Used to close and open metric select box
  useEffect(() => {
    const handleClickOutside = (event) => {
      const buttonElement = buttonRef?.current;

      if (buttonElement && buttonElement?.contains(event.target)) {
        setIsMetricSelectBoxOpen(!isMetricSelectBoxOpen);
      }

      if (
        dropDownRef?.current &&
        !dropDownRef?.current?.contains(event.target)
      ) {
        setIsMetricSelectBoxOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Box style={{ position: "relative", ...props.style }}>
      <IconButton
        ref={buttonRef}
        style={{
          width: "16px",
          height: "16px",
          border: "1px solid #8F95A1",
          borderRadius: "2px",
          cursor: "pointer",
        }}
        size={"small"}
        children={
          isMetricSelectBoxOpen ? (
            <ArrowDropUpIcon fontSize="small" />
          ) : (
            <ArrowDropDownIcon fontSize="small" />
          )
        }
      />
      {isMetricSelectBoxOpen && (
        <Box
          style={{
            position: "absolute",
            width: "240px",
            border: "1px solid #E3E9F3",
            borderRadius: "4px",
            backgroundColor: "white",
            zIndex: 1,
          }}
          ref={dropDownRef}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "8px 12px",
              fontFamily: "Inter",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "16px",
              color: "#87939D",
              borderBottom: "1px solid #E3E9F3",
            }}
          >
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >
              Selected Metrics
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                fontWeight: 500,
                lineHeight: "16px",
              }}
            >
              Max 4
            </Typography>
          </Box>
          <Box>
            {selectedMetrics.map(
              (item, index) =>
                (item === null || item?.status === "active") && (
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "12px",
                      opacity: item === null ? 0.5 : 1,
                    }}
                    key={`selectedMetric${index}`}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ReorderRoundedIcon
                        style={{
                          fontSize: "16px",
                          color: "#696A73",
                          marginRight: "20px",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "20px",
                        }}
                      >
                        {`${index + 1}. ${item?.label || "Empty"}`}
                      </Typography>
                    </Box>
                    <IconButton
                      style={{
                        padding: 0,
                        margin: 0,
                        cursor: item === null ? "default" : "pointer",
                      }}
                      disableRipple={true}
                      onClick={() => {
                        handleOnClickRemoveMetric(item, index);
                      }}
                      children={
                        <RemoveCircleOutlineRoundedIcon
                          fontSize="large"
                          style={{
                            color: "#586CE9",
                          }}
                        />
                      }
                    />
                  </Box>
                )
            )}
          </Box>
          <Box
            style={{
              marginTop: "5px",
              backgroundColor: "#EDF1F7",
              padding: "4px 12px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#87939D",
              }}
            >
              Others
            </Typography>
          </Box>
          <Box>
            {selectedMetrics.map(
              (item, index) => {
                const isDisabled = disableMetricsForHierarchyLevel[selectedHierarchyLevel]?.includes(item?.slug) || !availableMetrics.includes(item?.slug)
                return item?.status === "inactive" && (
                  <Box
                    key={`nonSelectedMetric${index}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "12px",
                      opacity: isDisabled ? 0.5 : 1,
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      {item?.label}
                    </Typography>
                    <IconButton
                      style={{
                        padding: 0,
                        margin: 0,
                      }}
                      disabled={isDisabled}
                      onClick={() => {
                        if (isDisabled) {
                          return
                        }
                        handleOnClickAddMetric(item, index);
                      }}
                      children={
                        <AddCircleOutlineRoundedIcon
                          fontSize="large"
                          style={{
                            color:
                              (!isDisabled) && countOfSelectedMetrics !== 4
                                ? "#586CE9"
                                : "#87939D",
                            cursor:
                              (!isDisabled) && countOfSelectedMetrics === 4
                                ? "default"
                                : "pointer",
                          }}
                        />
                      }
                    />
                  </Box>
                )
              }
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BatteryMetricSelect;
