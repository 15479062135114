import React from "react";
import { Box, Typography } from "@mui/material";
import { PaperHighlight } from "../../../Components/CustomPaper";
import { SimpleBox } from "../../../Components/CustomBox";
import Header from "../../../Features/Header/Header";
import { useNavigate } from "react-router-dom";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import {
  FindNearestMaxAnalytics,
  FindNextHighestMultipleOfNum,
} from "../../../Helper/Operations/FindNearestMaxAnalytics";
import ScrollToTheElement from "../../../Helper/Operations/ScrollToTheElement";
import TestApexBar from "../../../Charts/Bar/TestApexBar";

function Metrics(text) {
  return (
    <SimpleBox
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: "20px 20px 0px 15px",
      }}
    >
      <SimpleBox sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="textSubtitle">{text}</Typography>
        <Box sx={{ mt: 0.5 }} />
      </SimpleBox>
    </SimpleBox>
  );
}

function LandingComponent(props) {
  const navigate = useNavigate();
  ScrollToTheElement();
  return (
    <PaperHighlight elevation={0} sx={{ height: "480px" }}>
      <Header
        heading={props.name}
        description={props.name}
        showIButton={true}
        showThreeDots={false}
        id={props.id}
        page="Reports"
      />{" "}
      <div
        onClick={() =>
          navigate(props.navigate, {
            state: { detail: props.name, key: props.keyName },
          })
        }
        style={{ cursor: "pointer", height: "100%" }}
      >
        {Metrics(props.description)}
        <SimpleBox sx={{ mt: 1 }} />
        <div
          className="section"
          id={props.id}
          style={{ height: "calc(100% - 110px)" }}
        >
          <TestApexBar
            data={props.data}
            horizontal={false}
            barHeight={"40%"}
            yaxisTitle={props.yAxisTitle}
            xaxisTitle={undefined}
            tickAmount={4}
            granularity={"Weekly"}
            ymax={FindNextHighestMultipleOfNum(
              FindNearestMaxAnalytics(Math.max(...props.data.count)),
              10000
            )}
            columnWidth={"80%"}
            stacked={false}
            showDataLabels={true}
            formatter={false}
            categories={props.data.categories}
            colors={[ThemeProperties.purple, ThemeProperties.Purple4]}
            offsetX={0}
            offsetY={-20}
            series={[
              {
                name: props.yAxisTitle,
                data: props.data.count,
              },
            ]}
            customLegend={false}
            width={"108%"}
          />
        </div>
      </div>
    </PaperHighlight>
  );
}
export default LandingComponent;