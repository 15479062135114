import Car from "../../../Assets/Icons/DigitalTwinList/Car.svg";
import TwoWheeler from "../../../Assets/Icons/DigitalTwinList/TwoWheeler.svg";
import Bus from "../../../Assets/Icons/DigitalTwinList/Bus.svg";
import EVCharger from "../../../Assets/Icons/DigitalTwinList/EVCharger.svg";
import BatterySwapping from "../../../Assets/Icons/DigitalTwinList/BatterySwapping.svg";

export const batteryConfigurations = [
  {
    id: 1,
    value: "2w",
    configName: "2 Wheeler",
    icon: TwoWheeler,
  },
  {
    id: 2,
    value: "bus",
    configName: "Bus",
    icon: Bus,
  },
  {
    id: 3,
    value: "4w",
    configName: "4 Wheeler",
    icon: Car,
  },
  {
    id: 4,
    value: "es",
    configName: "Energy Storage",
    icon: EVCharger,
  },
  {
    id: 5,
    value: "bs-2w",
    configName: "Battery Swapping -2W",
    icon: BatterySwapping,
  },
  {
    id: 6,
    value: "bs-3w",
    configName: "Battery Swapping -3W",
    icon: BatterySwapping,
  },
];

export const defaultConfigList = [
  {
    configID: "4W Wheeler 60-4-2",
    application: "Samsung",
    buildingBlockType: "PCM",
    packCount: 4,
    moduleCount: 4,
    cellCount: 4,
    seriesPacks: 2,
    parallelPacks: 6,
    seriesModulesInPack: 2,
    parallelModulesInPack: 2,
    seriesCellsInModule: 2,
    parallelCellsInModule: 2,
    cellOEM: "Samsung",
    cellModelNumber: "14151",
    cellChemistry: "Chem",
    ratedNominalVoltCell: 12,
    ratedMaxVoltCell: 23,
    ratedMinVoltCell: 12,
    ratedCapacityCell: 32,
    ratedEnergyCell: 23,
    formFactorCell: "Small",
    packOEM: "Samsung",
    ratedCapacityPack: 40,
    ratedVoltagePack: 12,
    applicationOEM: "Samsung",
    ratedCapacitySystem: 26,
    ratedVoltageSystem: 45,
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    bmsHardwareID: "14g1gg",
    bmsFirmwareID: "31gg",
    addedBy: "Adam Clark",
    addedOn: "2023-01-01T00:00:000",
    lastUpdateBy: "Adam",
    lastUpdateOn: "2023-01-01T00:00:000",
  },
  {
    configID: "4W Wheeler 60-4-2",
    application: "Samsung",
    buildingBlockType: "PCM",
    packCount: 4,
    moduleCount: 4,
    cellCount: 4,
    seriesPacks: 2,
    parallelPacks: 6,
    seriesModulesInPack: 2,
    parallelModulesInPack: 2,
    seriesCellsInModule: 2,
    parallelCellsInModule: 2,
    cellOEM: "Samsung",
    cellModelNumber: "14151",
    cellChemistry: "Chem",
    ratedNominalVoltCell: 12,
    ratedMaxVoltCell: 23,
    ratedMinVoltCell: 12,
    ratedCapacityCell: 32,
    ratedEnergyCell: 23,
    formFactorCell: "Small",
    packOEM: "Samsung",
    ratedCapacityPack: 40,
    ratedVoltagePack: 12,
    applicationOEM: "Samsung",
    ratedCapacitySystem: 26,
    ratedVoltageSystem: 45,
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    bmsHardwareID: "14g1gg",
    bmsFirmwareID: "31gg",
    addedBy: "Adam Clark",
    addedOn: "2023-01-01T00:00:000",
    lastUpdateBy: "Adam",
    lastUpdateOn: "2023-01-01T00:00:000",
  },
  {
    configID: "4W Wheeler 60-4-2",
    application: "Samsung",
    buildingBlockType: "PCM",
    packCount: 4,
    moduleCount: 4,
    cellCount: 4,
    seriesPacks: 2,
    parallelPacks: 6,
    seriesModulesInPack: 2,
    parallelModulesInPack: 2,
    seriesCellsInModule: 2,
    parallelCellsInModule: 2,
    cellOEM: "Samsung",
    cellModelNumber: "14151",
    cellChemistry: "Chem",
    ratedNominalVoltCell: 12,
    ratedMaxVoltCell: 23,
    ratedMinVoltCell: 12,
    ratedCapacityCell: 32,
    ratedEnergyCell: 23,
    formFactorCell: "Small",
    packOEM: "Samsung",
    ratedCapacityPack: 40,
    ratedVoltagePack: 12,
    applicationOEM: "Samsung",
    ratedCapacitySystem: 26,
    ratedVoltageSystem: 45,
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    bmsHardwareID: "14g1gg",
    bmsFirmwareID: "31gg",
    addedBy: "Adam Clark",
    addedOn: "2023-01-01T00:00:000",
    lastUpdateBy: "Adam",
    lastUpdateOn: "2023-01-01T00:00:000",
  },
  {
    configID: "4W Wheeler 60-4-2",
    application: "Samsung",
    buildingBlockType: "PCM",
    packCount: 4,
    moduleCount: 4,
    cellCount: 4,
    seriesPacks: 2,
    parallelPacks: 6,
    seriesModulesInPack: 2,
    parallelModulesInPack: 2,
    seriesCellsInModule: 2,
    parallelCellsInModule: 2,
    cellOEM: "Samsung",
    cellModelNumber: "14151",
    cellChemistry: "Chem",
    ratedNominalVoltCell: 12,
    ratedMaxVoltCell: 23,
    ratedMinVoltCell: 12,
    ratedCapacityCell: 32,
    ratedEnergyCell: 23,
    formFactorCell: "Small",
    packOEM: "Samsung",
    ratedCapacityPack: 40,
    ratedVoltagePack: 12,
    applicationOEM: "Samsung",
    ratedCapacitySystem: 26,
    ratedVoltageSystem: 45,
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    bmsHardwareID: "14g1gg",
    bmsFirmwareID: "31gg",
    addedBy: "Adam Clark",
    addedOn: "2023-01-01T00:00:000",
    lastUpdateBy: "Adam",
    lastUpdateOn: "2023-01-01T00:00:000",
  },
];

export const defaultBatteryList = [
  {
    batteryID: "351561114688530",
    batterySystemID: "032948756",
    configID: "bwged213213",
    fleetName: "Samsar",
    addedBy: "Adam Clark",
    addedOn: "2024-01-02T00:00:000",
    lastUpdateBy: "2024-01-02T00:00:000",
    lastUpdateOn: "2024-01-02T00:00:000",
    lastGPSPing: "2024-01-02T00:00:000",
    location: "NY",
    lastBMSPing: "2024-01-02T00:00:000",
    packCount: 4,
    ratedCapacitySystem: 40,
    ratedVoltageSystem: 12,
    seriesPacks: 2,
    parallelPacks: 5,
    applicationOEM: "Samsung",
    pingStatus: "Active",
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    packOEM: "Samsung",
    cellOEM: "Samsung",
    bmsHardwareID: "",
    bmsFirmwareID: "",
    maxCurrentPack: 40,
    minCurrentPack: 20,
    maxVoltagePack: 20,
    maxTemperaturePack: 40,
    mintemperaturePack: 20,
    maxVoltageCell: 40,
    minVoltageCell: 20,
  },
  {
    batteryID: "351561114688530",
    batterySystemID: "032948756",
    configID: "bwged213213",
    fleetName: "Samsar",
    addedBy: "Adam Clark",
    addedOn: "2024-01-02T00:00:000",
    lastUpdateBy: "2024-01-02T00:00:000",
    lastUpdateOn: "2024-01-02T00:00:000",
    lastGPSPing: "2024-01-02T00:00:000",
    location: "NY",
    lastBMSPing: "2024-01-02T00:00:000",
    packCount: 4,
    ratedCapacitySystem: 40,
    ratedVoltageSystem: 12,
    seriesPacks: 2,
    parallelPacks: 5,
    applicationOEM: "Samsung",
    pingStatus: "Active",
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    packOEM: "Samsung",
    cellOEM: "Samsung",
    bmsHardwareID: "",
    bmsFirmwareID: "",
    maxCurrentPack: 40,
    minCurrentPack: 20,
    maxVoltagePack: 20,
    maxTemperaturePack: 40,
    mintemperaturePack: 20,
    maxVoltageCell: 40,
    minVoltageCell: 20,
  },
  {
    batteryID: "351561114688530",
    batterySystemID: "032948756",
    configID: "bwged213213",
    fleetName: "Samsar",
    addedBy: "Adam Clark",
    addedOn: "2024-01-02T00:00:000",
    lastUpdateBy: "2024-01-02T00:00:000",
    lastUpdateOn: "2024-01-02T00:00:000",
    lastGPSPing: "2024-01-02T00:00:000",
    location: "NY",
    lastBMSPing: "2024-01-02T00:00:000",
    packCount: 4,
    ratedCapacitySystem: 40,
    ratedVoltageSystem: 12,
    seriesPacks: 2,
    parallelPacks: 5,
    applicationOEM: "Samsung",
    pingStatus: "Active",
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    packOEM: "Samsung",
    cellOEM: "Samsung",
    bmsHardwareID: "",
    bmsFirmwareID: "",
    maxCurrentPack: 40,
    minCurrentPack: 20,
    maxVoltagePack: 20,
    maxTemperaturePack: 40,
    mintemperaturePack: 20,
    maxVoltageCell: 40,
    minVoltageCell: 20,
  },
  {
    batteryID: "351561114688530",
    batterySystemID: "032948756",
    configID: "bwged213213",
    fleetName: "Samsar",
    addedBy: "Adam Clark",
    addedOn: "2024-01-02T00:00:000",
    lastUpdateBy: "2024-01-02T00:00:000",
    lastUpdateOn: "2024-01-02T00:00:000",
    lastGPSPing: "2024-01-02T00:00:000",
    location: "NY",
    lastBMSPing: "2024-01-02T00:00:000",
    packCount: 4,
    ratedCapacitySystem: 40,
    ratedVoltageSystem: 12,
    seriesPacks: 2,
    parallelPacks: 5,
    applicationOEM: "Samsung",
    pingStatus: "Active",
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    packOEM: "Samsung",
    cellOEM: "Samsung",
    bmsHardwareID: "",
    bmsFirmwareID: "",
    maxCurrentPack: 40,
    minCurrentPack: 20,
    maxVoltagePack: 20,
    maxTemperaturePack: 40,
    mintemperaturePack: 20,
    maxVoltageCell: 40,
    minVoltageCell: 20,
  },
  {
    batteryID: "351561114688530",
    batterySystemID: "032948756",
    configID: "bwged213213",
    fleetName: "Samsar",
    addedBy: "Adam Clark",
    addedOn: "2024-01-02T00:00:000",
    lastUpdateBy: "2024-01-02T00:00:000",
    lastUpdateOn: "2024-01-02T00:00:000",
    lastGPSPing: "2024-01-02T00:00:000",
    location: "NY",
    lastBMSPing: "2024-01-02T00:00:000",
    packCount: 4,
    ratedCapacitySystem: 40,
    ratedVoltageSystem: 12,
    seriesPacks: 2,
    parallelPacks: 5,
    applicationOEM: "Samsung",
    pingStatus: "Active",
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    packOEM: "Samsung",
    cellOEM: "Samsung",
    bmsHardwareID: "",
    bmsFirmwareID: "",
    maxCurrentPack: 40,
    minCurrentPack: 20,
    maxVoltagePack: 20,
    maxTemperaturePack: 40,
    mintemperaturePack: 20,
    maxVoltageCell: 40,
    minVoltageCell: 20,
  },
  {
    batteryID: "351561114688530",
    batterySystemID: "032948756",
    configID: "bwged213213",
    fleetName: "Samsar",
    addedBy: "Adam Clark",
    addedOn: "2024-01-02T00:00:000",
    lastUpdateBy: "2024-01-02T00:00:000",
    lastUpdateOn: "2024-01-02T00:00:000",
    lastGPSPing: "2024-01-02T00:00:000",
    location: "NY",
    lastBMSPing: "2024-01-02T00:00:000",
    packCount: 4,
    ratedCapacitySystem: 40,
    ratedVoltageSystem: 12,
    seriesPacks: 2,
    parallelPacks: 5,
    applicationOEM: "Samsung",
    pingStatus: "Active",
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    packOEM: "Samsung",
    cellOEM: "Samsung",
    bmsHardwareID: "",
    bmsFirmwareID: "",
    maxCurrentPack: 40,
    minCurrentPack: 20,
    maxVoltagePack: 20,
    maxTemperaturePack: 40,
    mintemperaturePack: 20,
    maxVoltageCell: 40,
    minVoltageCell: 20,
  },
  {
    batteryID: "351561114688530",
    batterySystemID: "032948756",
    configID: "bwged213213",
    fleetName: "Samsar",
    addedBy: "Adam Clark",
    addedOn: "2024-01-02T00:00:000",
    lastUpdateBy: "2024-01-02T00:00:000",
    lastUpdateOn: "2024-01-02T00:00:000",
    lastGPSPing: "2024-01-02T00:00:000",
    location: "NY",
    lastBMSPing: "2024-01-02T00:00:000",
    packCount: 4,
    ratedCapacitySystem: 40,
    ratedVoltageSystem: 12,
    seriesPacks: 2,
    parallelPacks: 5,
    applicationOEM: "Samsung",
    pingStatus: "Active",
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    packOEM: "Samsung",
    cellOEM: "Samsung",
    bmsHardwareID: "",
    bmsFirmwareID: "",
    maxCurrentPack: 40,
    minCurrentPack: 20,
    maxVoltagePack: 20,
    maxTemperaturePack: 40,
    mintemperaturePack: 20,
    maxVoltageCell: 40,
    minVoltageCell: 20,
  },
  {
    batteryID: "351561114688530",
    batterySystemID: "032948756",
    configID: "bwged213213",
    fleetName: "Samsar",
    addedBy: "Adam Clark",
    addedOn: "2024-01-02T00:00:000",
    lastUpdateBy: "2024-01-02T00:00:000",
    lastUpdateOn: "2024-01-02T00:00:000",
    lastGPSPing: "2024-01-02T00:00:000",
    location: "NY",
    lastBMSPing: "2024-01-02T00:00:000",
    packCount: 4,
    ratedCapacitySystem: 40,
    ratedVoltageSystem: 12,
    seriesPacks: 2,
    parallelPacks: 5,
    applicationOEM: "Samsung",
    pingStatus: "Active",
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    packOEM: "Samsung",
    cellOEM: "Samsung",
    bmsHardwareID: "",
    bmsFirmwareID: "",
    maxCurrentPack: 40,
    minCurrentPack: 20,
    maxVoltagePack: 20,
    maxTemperaturePack: 40,
    mintemperaturePack: 20,
    maxVoltageCell: 40,
    minVoltageCell: 20,
  },
  {
    batteryID: "351561114688530",
    batterySystemID: "032948756",
    configID: "bwged213213",
    fleetName: "Samsar",
    addedBy: "Adam Clark",
    addedOn: "2024-01-02T00:00:000",
    lastUpdateBy: "2024-01-02T00:00:000",
    lastUpdateOn: "2024-01-02T00:00:000",
    lastGPSPing: "2024-01-02T00:00:000",
    location: "NY",
    lastBMSPing: "2024-01-02T00:00:000",
    packCount: 4,
    ratedCapacitySystem: 40,
    ratedVoltageSystem: 12,
    seriesPacks: 2,
    parallelPacks: 5,
    applicationOEM: "Samsung",
    pingStatus: "Active",
    iotOEM: "Samsung",
    bmsOEM: "Samsung",
    packOEM: "Samsung",
    cellOEM: "Samsung",
    bmsHardwareID: "",
    bmsFirmwareID: "",
    maxCurrentPack: 40,
    minCurrentPack: 20,
    maxVoltagePack: 20,
    maxTemperaturePack: 40,
    mintemperaturePack: 20,
    maxVoltageCell: 40,
    minVoltageCell: 20,
  },
];

export const configFilters = [
  "2 Wheeler 60-40-2",
  "4 Wheeler 60-2",
  "4 Wheeler 60-40-2",
  "Battery Swapping 2W 60-4-2",
  "Battery Swapping 2W 60-2",
];
