import React, { useEffect } from "react";
import { Box, Paper, Typography, Button, Grid } from "@mui/material";
import "../../Features/Table/AntDesign/table.css";
import CircularProgress from "@mui/material/CircularProgress";
import {
  OuterBox,
  InnerBox,
  KPIBox,
  KPIBoxWithoutBorder,
} from "../../Components/CustomBox";
// import SimplePageHeader from "../../Features/PageHeader/SimplePageHeader";

import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";
import GenericSelectTest from "../../Features/Menu/GenericSelectKeyValue";
// import CustomDateRangePicker from "../../Helper/DatePicker/CustomDateRangePicker";
import BasicTableWarranty from "../../Features/Table/AntDesign/BasicTableWarranty";

// import TabWithColorWarranty from "../../Features/Tabs/TabWithColorWarranty";
import ListWithSelectionWarranty from "../../Features/Lists/ListWithSelectionWarranty";
import WarrantyTabs from "../../Static/Data/Warranty/WarrantyTabs";
import Events from "../../Analytics/Events";
// import { Checkbox as AntCheckbox } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { getConnectedBatteries } from "../../Api/Api";

import {
  getAlertTypeList,
  getTableBatteries,
  //   getBatteryPartnerList,
  getCSVOfBatteries,
} from "../../Api/WarrantyApi";
import {
  SetWarrantyColumns,
  SetWarrantyPageNumber,
  SetWarrantyTabValue,
  SetWarrantyCode,
  SetWarrantyIndex,
  SetWarrantyTotal,
  SetWarrantyStartDate,
  SetWarrantyEndDate,
  SetWarrantyStack,
  SetWarrantyAge,
  SetWarrantyIOT,
  SetWarrantyPack,
  SetWarrantyCycle,
  SetWarrantySOH,
  SetWarrantyOpenAddSelectedBatteries,
  SetWarrantyColumnSort,
  SetWarrantyColumnSortOrder,
} from "../../Actions/Warranty";
import { SetSessionExpired } from "../../Actions";
import { theme } from "antd";
// import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";

import DownloadCSV from "../../Helper/CSVOperations/DownloadCSV";
import NameTheCSV from "../../Helper/CSVOperations/NameTheCSV";
import useState from "react-usestateref";
import { useNavigate } from "react-router-dom";

import PageHeaderKPI from "../../Features/PageHeader/PageHeaderKPI";
import { dashboardNonClickablekpis } from "../../Static/Data/Dashboard/DashboardKPIS";
import TabWithColorDashboard from "../../Features/Tabs/TabWithColorDashboard";
import RemoveSelectedBatteriesWarranty from "../../Features/Dialog/RemoveSelectedBatteriesWarranty";
import moment from "moment";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import Loader from "../../Helper/Loaders/Loader";
import BasicTableWarranty2 from "../../Features/Table/AntDesign/BasicTableWarranty2";
import { resetTheFilterValues, setTheFilterValues } from "../../Helper/QueryParams/EncryptDecrypt";
const { useToken } = theme;

function WarrantyMain(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useToken();

  const universal = useSelector((state) => state.WarrantyStack);
  const orderChangedTo = useSelector((state) => state.WarrantyColumnSortOrder);
  const sortChangedTo = useSelector((state) => state.WarrantyColumnSort);

  const [resetButton, setResetButton] = useState(false);
  const [existingBatteries, setExistingBatteries] = useState([]);

  const tabvalue = useSelector((state) => state.WarrantyTabValue.value);
  const WarrantyCode = useSelector((state) => state.WarrantyCode.value);
  const total = useSelector((state) => state.WarrantyTotal.value);
  const indexWarrantyType = useSelector((state) => state.WarrantyIndex.value);

  //   const [batteryPartnerList, setBatterypartnerList] = useState([]);
  const [alertTypeList, setAlertTypeList] = useState({
    list: [],
    responseStatus: { code: -1, msg: "" },
  });
  const [listOfBatteries, setListOfBatteries] = useState({
    batteries: [],
    responseStatus: { code: -1, msg: "" },
  });
  const batteryID = useSelector((state) => state.WarrantyBatteryID.value);
  const partner = useSelector((state) => state.WarrantyPartner.value);
  const [mounted, setMounted] = useState(false);
  const [mountedCSVLoader, setMountedCSVLoader] = useState(false);

  const [response, setResponse] = useState({
    code: 0,
    msg: "No data found",
  });

  const IOT = useSelector((state) => state.WarrantyIOT.value);
  const pack = useSelector((state) => state.WarrantyPack.value);
  const cycle = useSelector((state) => state.WarrantyCycle.value);
  const age = useSelector((state) => state.WarrantyAge.value);
  const soh = useSelector((state) => state.WarrantySOH.value);

  const reduxStartDate = useSelector((state) => state.WarrantyStartDate.value);
  const reduxEndDate = useSelector((state) => state.WarrantyEndDate.value);
  //   const [open, setOpen] = useState(false);
  const newColumns = useSelector((state) => state.WarrantyColumns.value);

  //   const columnHide = useSelector((state) => state.WarrantyHiddenColumns.value);

  //   const contentStyle = {
  //     backgroundColor: token.colorBgElevated,
  //     borderRadius: token.borderRadiusLG,
  //     boxShadow: token.boxShadowSecondary,
  //   };
  //   const menuStyle = {
  //     boxShadow: "none",
  //   };
  //   console.log("hidden columns going into items ", columnHide);
  //   const items = [
  //     ...newColumns[tabvalue]["columns"].map((d, i) => {
  //       if (d.name !== "")
  //         if (
  //           d.dataIndex === "batteryID" ||
  //           d.dataIndex === "soc" ||
  //           d.dataIndex === "socBMS" ||
  //           d.dataIndex === "soh"
  //         ) {
  //           return {
  //             key: i + 1,
  //             label: (
  //               <AntCheckbox
  //                 // color="primary"
  //                 key={i + 1}
  //                 checked={true}
  //                 disabled
  //                 // onChange={() => onFilterCheckboxClick(d.uniqueKey)}
  //               >
  //                 {d.name}
  //               </AntCheckbox>
  //             ),
  //           };
  //         } else {
  //           return {
  //             key: i + 1,
  //             label: (
  //               <AntCheckbox
  //                 // color="primary"
  //                 key={i + 1}
  //                 checked={!columnHide.includes(d.uniqueKey)}
  //                 onChange={() => onFilterCheckboxClick(d.uniqueKey)}
  //               >
  //                 {d.name}
  //               </AntCheckbox>
  //             ),
  //           };
  //         }
  //     }),
  //   ];

  const filters = {
    IOT: IOT,
    Pack: pack,
    Cycles: cycle,
    Age: age,
    SOH: soh,
  };

  const pageNumber = useSelector((state) => state.WarrantyPageNumber.value);
  const rowsPerPage = useSelector((state) => state.WarrantyRowsPerPage.value);
  const [connectedBatteries, setConnectedBatteries] = useState(0);

  const openExportCSVConfirm = useSelector(
    (state) => state.WarrantyOpenAddSelectedBatteries.value
  );
  const handleOpenExportCSVConfirm = (event) => {
    dispatch(SetWarrantyOpenAddSelectedBatteries(true));
  };
  const handleCloseExportCSVConfirm = () => {
    dispatch(SetWarrantyOpenAddSelectedBatteries(false));
  };
  //   const onClickApply = () => {
  //     const tempColumns = [...newColumns];
  //     const tempHiddenColumns = [...columnHide];

  //     tempColumns[tabvalue]["columns"].forEach((column, i) => {
  //       if (column.name !== "") {
  //         if (columnHide.includes(column.uniqueKey)) {
  //           column.hidden = true;
  //           // tempHiddenColumns.push(column.uniqueKey)
  //         } else {
  //           column.hidden = false;
  //         }
  //       }
  //     });
  //     console.log("onClickApply called");
  //     console.log("columns are ", tempColumns);
  //     dispatch(SetWarrantyColumns([...tempColumns]));
  //     // dispatch(SetWarrantyHiddenColumns([...tempHiddenColumns]));
  //     setOpen(false);
  //   };

  //   const onClickReset = () => {
  //     const tempColumns = [...newColumns];
  //     const tempHiddenColumns = [...columnHide];

  //     tempColumns[tabvalue]["columns"].forEach((column, i) => {
  //       if (column.name !== "") {
  //         column.hidden = false;
  //         const index = tempHiddenColumns.indexOf(column.uniqueKey);
  //         if (index > -1) {
  //           // only splice array when item is found
  //           tempHiddenColumns.splice(index, 1); // 2nd parameter means remove one item only
  //         }
  //       }
  //     });
  //     // const arr = newColumns[tabvalue]["columns"];
  //     // setColumns([...arr]);
  //     console.log("onClickReset called");
  //     console.log("columns are ", tempColumns);
  //     console.log("hiden columns are ", tempHiddenColumns);
  //     dispatch(SetWarrantyColumns([...tempColumns]));

  //     dispatch(SetWarrantyHiddenColumns([...tempHiddenColumns]));
  //     setOpen(false);
  //   };

  //   const handleOpenChange = () => {
  //     setOpen(!open);
  //   };

  //   const onFilterCheckboxClick = (colName) => {
  //     if (columnHide.includes(colName)) {
  //       // setColumnHide([...columnHide.filter((ele) => ele != colName)]);
  //       dispatch(
  //         SetWarrantyHiddenColumns([
  //           ...columnHide.filter((ele) => ele != colName),
  //         ])
  //       );
  //     } else {
  //       // setColumnHide([...columnHide, colName]);
  //       dispatch(SetWarrantyHiddenColumns([...columnHide, colName]));
  //     }
  //   };

  //   const handleOnOpenChange = () => {
  //     console.log("handleOnOpenChange called");
  //     console.log("column Hide includes ", columnHide);

  //     const arr = [];

  //     newColumns[tabvalue]["columns"].forEach((col) => {
  //       if (col.hidden === true) {
  //         arr.push(col.uniqueKey);
  //       }
  //     });
  //     // setColumnHide([...arr])
  //     dispatch(SetWarrantyHiddenColumns([...arr]));

  //     setOpen(!open);
  //   };

  const ChangeTheTab = (newValue) => {
    setMounted(false);
    dispatch(SetWarrantyTabValue(newValue));
    // dispatch(SetDashboardAlertTypeList([]));
    dispatch(SetWarrantyCode(""));
    // dispatch(SetWarrantyIndex(0));
    dispatch(SetWarrantyTotal(0));
    dispatch(SetWarrantyPageNumber(1));

    setListOfBatteries({
      batteries: [],
      responseStatus: { code: -1, msg: "" },
    });
    setAlertTypeList({
      list: [],
      responseStatus: { code: -1, msg: "" },
    });

    Events(`clicked Warranty Calims Tab`);
  };

  function handleSetFilter(filterName, value) {
    setTheFilterValues(filterName, value, navigate);
    dispatch(SetWarrantyPageNumber(1));

    if (filterName === "IOT") {
      dispatch(SetWarrantyIOT(value));
    } else if (filterName === "Pack") {
      dispatch(SetWarrantyPack(value));
    } else if (filterName === "Cycles") {
      dispatch(SetWarrantyCycle(value));
    } else if (filterName === "Age") {
      dispatch(SetWarrantyAge(value));
    } else if (filterName === "SOH") {
      dispatch(SetWarrantySOH(value));
    }
  }

  function resetTheFilters() {
    // setListOfBatteries({
    //   batteries: [],
    //   responseStatus: { code: -1, msg: "" },
    // });

    dispatch(SetWarrantyIOT(""));
    dispatch(SetWarrantyPack(""));
    dispatch(SetWarrantyCycle(""));
    dispatch(SetWarrantyAge(""));
    dispatch(SetWarrantySOH(""));

    // dispatch(SetWarrantyCode(""));
    // dispatch(SetWarrantyIndex(0));

    dispatch(SetWarrantyStartDate(""));
    dispatch(SetWarrantyEndDate(""));
    dispatch(SetWarrantyPageNumber(1));

    resetTheFilterValues(props.pagesContent.filteredArray, navigate);

  }

  //   function changeTheDate(startDate, endDate) {
  //     dispatch(SetWarrantyCode(""));
  //     dispatch(SetWarrantyIndex(0));
  //     dispatch(SetWarrantyTotal(0));
  //     dispatch(SetWarrantyPageNumber(1));
  //     setListOfBatteries({
  //       batteries: [],
  //       responseStatus: { code: -1, msg: "" },
  //     });
  //     if (startDate === null && endDate === null) {
  //       dispatch(SetWarrantyStartDate(""));
  //       dispatch(SetWarrantyEndDate(""));
  //     } else if (endDate === null) {
  //       dispatch(SetWarrantyStartDate(startDate));
  //       dispatch(SetWarrantyEndDate(startDate));
  //     } else if (startDate === null) {
  //       dispatch(SetWarrantyStartDate(endDate));
  //       dispatch(SetWarrantyEndDate(endDate));
  //     } else {
  //       dispatch(SetWarrantyStartDate(startDate));
  //       dispatch(SetWarrantyEndDate(endDate));
  //     }
  //   }

  function getALertType() {
    alertTypeList.responseStatus.code !== -1 &&
      setAlertTypeList({
        list: [],
        responseStatus: { code: -1, msg: "" },
      });
    getAlertTypeList(IOT, pack, cycle, age, soh).then((res) => {
      if (res.responseStatus.code === 200) {
        setAlertTypeList({
          list: res.response.warrantyList,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
        if (WarrantyCode !== "") {
          // console.log("no warrantyCode is not empty",WarrantyCode);

          if (indexWarrantyType === 0) {
            // console.log("yess index is zero");
            if (res.response.warrantyList[0]["warrantyCode"] !== WarrantyCode) {
              dispatch(
                SetWarrantyCode(res.response.warrantyList[0]["warrantyCode"])
              );
              dispatch(
                SetWarrantyTotal(res.response.warrantyList[0]["warrantyCount"])
              );
            } else {
              dispatch(
                SetWarrantyTotal(res.response.warrantyList[0]["warrantyCount"])
              );
            }
          } else {
            // console.log("no index is not zero");
            var result = res.response.warrantyList.find(
              (item) => item.warrantyCode === WarrantyCode
            );
            var index = res.response.warrantyList.findIndex(
              (item) => item.warrantyCode === WarrantyCode
            );
            if (result === undefined) {
              dispatch(SetWarrantyIndex(0));
              dispatch(
                SetWarrantyCode(res.response.warrantyList[0]["warrantyCode"])
              );
              dispatch(
                SetWarrantyTotal(res.response.warrantyList[0]["warrantyCount"])
              );
            } else {
              dispatch(SetWarrantyTotal(result["warrantyCount"]));
              dispatch(SetWarrantyIndex(index));
            }
          }
        } else {
          // console.log("yes warrantyCode is empty");

          dispatch(
            SetWarrantyCode(res.response.warrantyList[0]["warrantyCode"])
          );
          dispatch(
            SetWarrantyTotal(res.response.warrantyList[0]["warrantyCount"])
          );
        }
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setAlertTypeList({
          list: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
        dispatch(SetWarrantyCode(""));
        dispatch(SetWarrantyIndex(0));
        dispatch(SetWarrantyTotal(0));
      }
    });
  }

  function getListOfBatteries() {
    listOfBatteries.responseStatus.code !== -1 &&
      setListOfBatteries({
        batteries: [],
        responseStatus: { code: -1, msg: "" },
      });
    if (WarrantyCode === "") {
      setListOfBatteries({
        batteries: [],
        responseStatus: {
          code: alertTypeList.responseStatus.code,
          msg: "No Data Found",
        },
      });
    } else {
      alertTypeList.responseStatus.code !== -1 &&
        getTableBatteries(
          WarrantyCode,
          IOT,
          pack,
          cycle,
          age,
          soh,
          pageNumber,
          rowsPerPage,
          sortChangedTo.sortBy,
          orderChangedTo.orderBy
        ).then((res) => {
          if (res.responseStatus.code === 200) {
            setListOfBatteries({
              batteries: res.response.warrantyData,

              responseStatus: {
                code: res.responseStatus.code,
                msg: res.responseStatus.msg,
              },
            });
            setMounted(true);

            // if (batteryID !== null) {
            //   dispatch(
            //   );
            //   dispatch(
            //     SetWarrantyBusiness(res.response.warrantyData[0]["batteryType"])
            //   );
            //   dispatch
            //   );

            //   dispatch(
            //   );
            // }
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));

            setListOfBatteries({
              batteries: [],

              responseStatus: {
                code: res.responseStatus.code,
                msg: res.responseStatus.msg,
              },
            });
          }
        });
    }
  }

  //   function getBatteryPartner() {
  //     getBatteryPartnerList(tabvalue).then((res) => {
  //       if (res.responseStatus.code === 200) {
  //         setBatterypartnerList(res.response.batteries);
  //       } else {
  //         if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

  //         setBatterypartnerList([]);
  //       }
  //     });
  //   }

  function FilterBox() {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Box sx={{ display: "flex" }}>
          {resetButton && batteryID === null && (
            <Button
              sx={{ fontSize: "12px", height: "30px", mt: 1.5, mr: 0.5 }}
              onClick={() => resetTheFilters()}
            >
              Reset
            </Button>
          )}

          {props.pagesContent.filtersWithData.map((item, index) => {
            return (
              <Box key={index}>
                <GenericSelectTest
                  filter={item}
                  handleSetFilter={handleSetFilter}
                  selected={filters[item["itemName"]]} // All,48V,60V
                  maxWidth={"100px"}
                  showClearButton={true}
                  id="overflow-text-1"
                  pageName={"Warranty"}
                />
              </Box>
            );
          })}
          {/* <Box sx={{ pl: 1, pr: 1 }}>
            <CustomDateRangePicker
              changeTheDate={changeTheDate}
              date={[reduxStartDate, reduxEndDate]}
              border={true}
            />
          </Box> */}
          {/* <ConfigProvider
            theme={{
              token: {
                colorPrimary: ThemeProperties.purple,
              },
            }}
          >
            <Dropdown
              menu={{
                items,
              }}
              className="dropDowndDiv"
              // disabled
              open={open}
              onOpenChange={handleOnOpenChange}
              trigger={["click"]}
              dropdownRender={(menu) => (
                <div>
                  <div className="dropDown" style={contentStyle}>
                    <div className="dropDownTop">
                      <Typography variant="tableHead">
                        Select columns to show
                      </Typography>
                    </div>

                    <div className="dropDownMiddle">
                      {React.cloneElement(menu, {
                        style: menuStyle,
                      })}
                    </div>

                    <div className="dropDownBottom">
                      <AntButton onClick={() => onClickReset()}>
                        Reset
                      </AntButton>
                      <AntButton type="primary" onClick={() => onClickApply()}>
                        Apply
                      </AntButton>
                    </div>
                  </div>
                </div>
              )}
            >
              <Space>
                <Avatar
                  variant="square"
                  sx={{
                    bgcolor: "white",
                    border: "1px solid #cecece",
                    borderRadius: "4px",
                    width: 35,
                    height: 35,
                    mt: 0.5,
                    ml: 1,
                  }}
                >
                  <ViewWeekOutlinedIcon
                    onClick={handleOpenChange}
                    sx={{
                      cursor: "pointer",
                      fontSize: "25px",
                      color:
                        newColumns[tabvalue]["columns"].filter(
                          (item) => item.hidden === true
                        ).length > 0
                          ? ThemeProperties.purple
                          : ThemeProperties.greyTextColor,
                    }}
                  />
                </Avatar>
              </Space>
            </Dropdown>
          </ConfigProvider> */}
        </Box>
      </Box>
    );
  }

  function showResetButton() {
    if (IOT !== "" || pack !== "" || cycle !== "" || age !== "" || soh !== "") {
      setResetButton(true);
    } else {
      setResetButton(false);
    }
  }

  function CreateCSV() {
    const newBatteries = [];
    let newInsertionTime, newLastPingedTime, newResolveCustomerDBTime, tab;
    setMountedCSVLoader(true);
    getCSVOfBatteries(
      universal.arr,
      IOT,
      pack,
      cycle,
      age,
      soh,
      WarrantyCode
    ).then((res) => {
      if (res.responseStatus.code === 200) {
        if (WarrantyCode === "W001") {
          res.response.warrantyData.map((row, index) => {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Battery ID": row.assetID,
              "Warranty Condition": row.warrantyCondition,
              "Onboarding Date": moment(
                convertUTCtoZone(row.onboardingDate)
              ).format("DD MMM'YY"),
              "Validity Period": row.warrantyInfo?.validity,
              "Age (Months)": row.age ? row.age / 30 : "Not found",
              Cycles: Math.round(row.cycles),
              "SOH (%)": row.soh,
            });
          });
        } else if (WarrantyCode === "W002") {
          res.response.warrantyData.map((row, index) => {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Battery ID": row.assetID,
              "Warranty Condition": row.warrantyCondition,
              "Onboarding Date": moment(
                convertUTCtoZone(row.onboardingDate)
              ).format("DD MMM'YY"),
              "Accuracy (%)": row.warrantyInfo?.Accuracy,
            });
          });
        } else if (WarrantyCode === "W003") {
          res.response.warrantyData.map((row, index) => {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Battery ID": row.assetID,
              "Warranty Condition": row.warrantyCondition,
              "Onboarding Date": moment(
                convertUTCtoZone(row.onboardingDate)
              ).format("DD MMM'YY"),
              "Last Pinged": row.lastPingedTime ? moment(
                convertUTCtoZone(row.lastPingedTime)
              ).format("DD MMM'YY"):"",
              "Average Ping Frequency (s)": row.warrantyInfo?.pingsPerday,
            });
          });
        } else if (WarrantyCode === "W004") {
          res.response.warrantyData.map((row, index) => {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Battery ID": row.assetID,
              "Warranty Condition": row.warrantyCondition,
              "Onboarding Date": moment(
                convertUTCtoZone(row.onboardingDate)
              ).format("DD MMM'YY"),
              "Correct Ping (%)": row.warrantyInfo?.correctPingFraction,
              "Average Ping Frequency (s)": row.warrantyInfo?.avgPingFrequency,
            });
          });
        } else if (WarrantyCode === "W005") {
          res.response.warrantyData.map((row, index) => {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Battery ID": row.assetID,
              "Warranty Condition": row.warrantyCondition,
              "Onboarding Date": moment(
                convertUTCtoZone(row.onboardingDate)
              ).format("DD MMM'YY"),
              "Highest Voltage Recorded (V)": row.warrantyInfo?.maxPackVolt ? row.warrantyInfo?.maxPackVolt.toFixed(1) : "",
              "Average Stabilisation Time (s)": row.warrantyInfo?.breachStableTime,
            });
          });
        } else if (WarrantyCode === "W006") {
          res.response.warrantyData.map((row, index) => {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Battery ID": row.assetID,
              "Warranty Condition": row.warrantyCondition,
              "Onboarding Date": moment(
                convertUTCtoZone(row.onboardingDate)
              ).format("DD MMM'YY"),
              "Highest Temp Recorded (°C)": row.warrantyInfo?.maxPackTemp,
              "Average Stabilisation Time (s)": row.warrantyInfo?.breachStableTime,
            });
          });
        } else if (WarrantyCode === "W007") {
          res.response.warrantyData.map((row, index) => {
            newBatteries.push({
              "Device ID": row.batteryID,
              "Battery ID": row.assetID,
              "Warranty Condition": row.warrantyCondition,
              "Onboarding Date": moment(
                convertUTCtoZone(row.onboardingDate)
              ).format("DD MMM'YY"),
              "Last Pinged": row.lastPingedTime ? moment(
                convertUTCtoZone(row.lastPingedTime)
              ).format("DD MMM'YY"):"",
              "Average Ping Frequency (s)": row.warrantyInfo?.avgPingFrequency,
            });
          });
        }

        DownloadCSV(NameTheCSV("Warranty", "Claims"), newBatteries);
        setMountedCSVLoader(false);
        dispatch(SetWarrantyStack([]));
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

        setMountedCSVLoader(false);
        dispatch(SetWarrantyStack([]));
      }
    });
  }

  useEffect(() => {
    getConnectedBatteries().then((res) => {
      if (res.responseStatus.code === 200) {
        setConnectedBatteries(res.response.data[0]["count"]);
      } else if (res.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
  }, []);

  useEffect(() => {
    showResetButton();
    getALertType();
  }, [tabvalue, IOT, pack, age, cycle, soh]);

  useEffect(() => {
    setMounted(false);

    const tempColumns = [...newColumns];

    const newOrderChangedTo =
      orderChangedTo.orderBy === "desc" ? "descend" : "ascend";
    let flag = 0;
    if (tempColumns.findIndex((item) => item.name === WarrantyCode) !== -1) {
      tempColumns[tempColumns.findIndex((item) => item.name === WarrantyCode)][
        "columns"
      ].forEach((column, i) => {
        if (column.key === sortChangedTo.sortBy && column.sorter === true) {
          column.defaultSortOrder = newOrderChangedTo;
          flag = 1;
          // console.log("yes...",column.key)
        } else {
          column.defaultSortOrder = "";
        }
      });
      console.log("flagggg is", flag, WarrantyCode);

      if (flag === 0) {
        tempColumns[
          tempColumns.findIndex((item) => item.name === WarrantyCode)
        ]["columns"].forEach((column, i) => {
          if (column.key === "onboardingDate" && column.sorter === true) {
            console.log("yeee");
            column.defaultSortOrder = newOrderChangedTo;
            dispatch(SetWarrantyColumnSort("onboardingDate"));

            // console.log("yes...",column.key)
          } else {
            column.defaultSortOrder = "";
          }
        });
      }

      console.log("main", tempColumns);
      // setTimeout(()=>{
      dispatch(SetWarrantyColumns(tempColumns));
      // },1000)
    } else {
      const tempColumns = [...newColumns];
      tempColumns.map((item) => {
        item["columns"].map((column, index) => {
          if (column.key === "onboardingDate" && column.sorter === true) {
            column.defaultSortOrder = newOrderChangedTo;
            // console.log(item["name"],column.name)
          }
        });
      });
      dispatch(SetWarrantyColumns(tempColumns));
      console.log("-------yohooooo-----");
    }
  }, [WarrantyCode]);

  useEffect(() => {
    getListOfBatteries();
  }, [
    tabvalue,
    alertTypeList,
    WarrantyCode,
    IOT,
    pack,
    cycle,
    soh,
    pageNumber,
    sortChangedTo.sortBy,
    orderChangedTo.orderBy,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (universal.arr.length > 0) {
      handleOpenExportCSVConfirm();
    }
  }, [indexWarrantyType]);

  return (
    <div>
      <OuterBox>
        <InnerBox>
          <PageHeaderKPI
            name={"Warranty"}
            nonClickableKpis={dashboardNonClickablekpis}
            connectedBatteries={connectedBatteries}
          />

          <Box sx={{ mt: 2, position: "relative" }}>
            <Box sx={{ position: "relative" }}>
              {universal.arr.length > 0 ? (
                <Box sx={{ position: "absolute", right: -12, zIndex: 1 }}>
                  {mountedCSVLoader ? (
                    <KPIBoxWithoutBorder // move styles
                      sx={{
                        width: "max-content",
                        pl: 2,
                        pr: 2,
                        opacity: 0.5,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress size="1rem" />
                      <Typography variant="textSubtitle" sx={{ ml: 1, mr: 1 }}>
                        Loading...
                      </Typography>
                    </KPIBoxWithoutBorder>
                  ) : (
                    <KPIBoxWithoutBorder
                      sx={{
                        width: "max-content",
                        pl: 2,
                        pr: 2,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        Events("Warranty download csv");
                        CreateCSV();
                      }}
                    >
                      <Typography variant="textSubtitle">
                        Export csv : <b>{universal.arr.length}</b> batteries
                      </Typography>
                    </KPIBoxWithoutBorder>
                  )}
                </Box>
              ) : (
                <Box sx={{ position: "absolute", right: -12, zIndex: 1 }}>
                  <KPIBoxWithoutBorder
                    sx={{ width: "max-content", pl: 2, pr: 2, opacity: 0.5 }}
                  >
                    <Typography variant="textSubtitle">
                      Export csv : <b>{universal.arr.length}</b> batteries
                    </Typography>
                  </KPIBoxWithoutBorder>
                </Box>
              )}

              <TabWithColorDashboard
                ChangeTheTab={ChangeTheTab}
                tabs={WarrantyTabs}
              />

              {WarrantyTabs.map((item, index) => (
                <BasicTabPanel value={tabvalue} index={index} key={index}>
                  <Paper
                    elevation={0}
                    sx={{
                      height: "100%",
                      borderRadius: "0px 12px 12px",
                      p: 1,
                      border: "1px solid #ececec",
                    }}
                  >
                    {FilterBox()}
                    <hr style={{ borderTop: "1px solid #ececec" }}></hr>
                    <Box sx={{ mt: 1.5 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                          <ListWithSelectionWarranty
                            data={alertTypeList}
                            // setPageNumber={setPageNumber}
                            batteryType={props.pagesContent.batteryType}
                            handleOpenExportCSVConfirm={
                              handleOpenExportCSVConfirm
                            }
                          />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={9} xl={9}>
                          {mounted ? (
                            <BasicTableWarranty
                              data={
                                mounted
                                  ? listOfBatteries
                                  : {
                                      batteries: [],
                                      responseStatus: { code: -1, msg: "" },
                                    }
                              }
                              total={total}
                              columns={newColumns}
                              existingBatteries={existingBatteries}
                              rowSelection={tabvalue === 0 ? true : false}
                              // handleRequestPage={handleRequestPage}

                              warrantyCode={WarrantyCode}
                              iot={IOT}
                              pack={pack}
                              cycle={cycle}
                              age={age}
                              soh={soh}
                              mounted={mounted}
                              // tabvalue={tabvalue}
                              // date={[reduxStartDate, reduxEndDate]}
                              // batteryID={batteryID}
                              // partner={partner}
                              // batteryType={""}
                              handleOpenExportCSVConfirm={
                                handleOpenExportCSVConfirm
                              }
                            />
                          ) : (
                            <div>
                              <BasicTableWarranty2
                                data={{
                                  batteries: [],
                                  responseStatus: { code: listOfBatteries.responseStatus.code === 404 ? 404 : -1, msg: "" },
                                }}
                                total={0}
                                columns={newColumns}
                                existingBatteries={existingBatteries}
                                rowSelection={tabvalue === 0 ? true : false}
                                // handleRequestPage={handleRequestPage}

                                warrantyCode={WarrantyCode}
                                iot={IOT}
                                pack={pack}
                                cycle={cycle}
                                age={age}
                                soh={soh}
                                mounted={mounted}
                                // tabvalue={tabvalue}
                                // date={[reduxStartDate, reduxEndDate]}
                                // batteryID={batteryID}
                                // partner={partner}
                                // batteryType={""}
                                handleOpenExportCSVConfirm={
                                  handleOpenExportCSVConfirm
                                }
                              />
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </BasicTabPanel>
              ))}
            </Box>
          </Box>
        </InnerBox>
      </OuterBox>

      <RemoveSelectedBatteriesWarranty
        openExportCSVConfirm={openExportCSVConfirm}
        handleCloseExportCSVConfirm={handleCloseExportCSVConfirm}
        CreateCSV={CreateCSV}
        page={"Warranty"}
        heading={"Attention! Moving to different Warranty Type"}
      />
    </div>
  );
}

export default WarrantyMain;
