import moment from "moment"
import { Typography } from "@mui/material";
import { getColumnTitleSort } from "../../../Features/Table/AntDesign/TableFunctions";
import { convertUTCtoZone } from "../../../Helper/DatePicker/DateConverter";

const height = "25px"
const columns = [
  {
    title: "Date",
    field: "date",
    minWidth: 130,
    headerStyle: {
      textAlign: "center",
      lineHeight: "34px",
    },
    sorting: false,
    render: (rowData) => (
      <div
        style={{
          height: height,
          lineHeight: height,
          textAlign: "center",
        }}
      >
        {moment(rowData.date).format('DD MMM YYYY')}
      </div>
    ),
  },
  {
    title: "Build Configuration",
    field: "bmsFwBuildNo",
    minWidth: 100,
    headerStyle: {
      textAlign: "center",
    },
    sorting: false,
    render: (rowData) => (
      <div
        style={{
          height: height,
          padding: "8px 0px",
          textAlign: "center",
          // marginLeft:"-25px"
        }}
      >
        {rowData.bmsFwBuildNo}
      </div>
    ),
  },
  {
    title: "FW Version",
    field: "fwversion",
    minWidth: 100,
    headerStyle: {
      textAlign: "center",
    },
    sorting: false,
    render: (rowData) => (
      <div
        style={{
          height: height,
          lineHeight: height,
          textAlign: "center",
          // marginLeft:"-25px"
        }}
      >
        {rowData.fwversion}
      </div>
    ),
  },
]

const columnsNew = [
  {
    // title: "Date",
    title: (titleProps) => getColumnTitleSort(titleProps,"date","Date"),

    dataIndex: "date",
    key: "date",
    showSorterTooltip: false,
    defaultSortOrder: "descend",

    uniqueKey: "date",
    filterSearch: false,
    width: 180,
    sorter: (a, b) => new Date(a.date) - new Date(b.date),
    sortDirections: ["ascend", "descend", "ascend"],
    // sortDirections: ["ascend", "descend", "ascend"],
    // showSorterTooltip: true,
    // maxWidth:"18px",
    render: (date) => (
      <Typography variant="tableRow">{convertUTCtoZone(date)}</Typography>
    ),
  },
  {
    // title: "Build Configuration",
    title: (titleProps) => getColumnTitleSort(titleProps,"bmsFwBuildNo","Build Configuration"),

    dataIndex: "bmsFwBuildNo",
    key: "bmsFwBuildNo",
    showSorterTooltip: false,
    sorter: (a, b) => a.bmsFwBuildNo - b.bmsFwBuildNo,
    sortDirections: ["ascend", "descend", "ascend"],
    uniqueKey: "bmsFwBuildNo",
    align:"center",
    filterSearch: false,
    width: 180,
    // maxWidth:"18px",
    render: (bmsFwBuildNo) => (
      <Typography variant="tableRow">{bmsFwBuildNo}</Typography>
    ),
  },
  {
    title: "FW Version",
    dataIndex: "fwversion",
    key: "fwversion",
    sorter: false,
    uniqueKey: "fwversion",
    align:"center",
    filterSearch: false,
    width: 180,
    // maxWidth:"18px",
    render: (fwversion) => (
      <Typography variant="tableRow">{fwversion}</Typography>
    ),
  },

];


  export{ columns,columnsNew }