export const SetReportsDQIOT = (iot) =>
{
    return {
        type: "ReportsDQIOT",
        iot: iot
    }
}
export const SetReportsDQPack = (pack) =>
{
    return {
        type: "ReportsDQPack",
        pack: pack
    }
}
export const SetReportsDQStartDate = (start) =>
{
    return {
        type: "ReportsDQStartDate",
        start: start
    }
}

export const SetReportsDQEndDate = (end) =>
{
    return {
        type: "ReportsDQEndDate",
        end: end
    }
}

export const SetReportsDQGranularity = (granular) =>
{
    return {
        type: "ReportsDQGranularity",
        granular: granular
    }
}
