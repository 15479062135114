import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography, Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { PTypography } from "../../Components/CustomP";
import moment from "moment";
import { convertAndGetTimeStampInRequiredFormat } from "../../Helper/DatePicker/DateConverter";
// import classes from "./Clintele.module.css";
// import TagAndHeading from "../TextArea/TagAndHeading";
// import dots from "../../../assets/images/Home/Clintele/Dots.svg";

export default class SlickCarouselPackMetrics extends Component {
  state = {
    oldSlide: 0,
    activeSlide: 0,
    activeSlide2: 0,
  };
  render() {
    var settings = {
      dots: false,
      infinite: false,
      // speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      //   responsive: [
      //     {
      //       breakpoint: 800,
      //       settings: {
      //         slidesToShow: 1,
      //         slidesToScroll: 1,
      //         infinite: true,
      //         dots: true,
      //       },
      //     },
      //   ],
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: (current) => this.setState({ activeSlide2: current }),
      //   autoplay: false,
      //   autoplaySpeed: 2000,
      centerMode: false,
    };
    return (
      <div
        style={{
        }}
        className="BatteryPack"
      >
        <Slider {...settings}>
          {this.props.modalData.map((data, index) => (
            <div key={index}>
              <Box sx={{
                zIndex: 0
              }}>
                <Box sx={{
                  mb: 2,
                  width: "100%",
                  padding: "13px 0 12px 17px",
                  borderRadius: "10px 10px 0 0 ",
                  background: "rgba(118, 140, 255, 0.13)",
                  height: "41px",
                  marginBottom: "0",
                  borderBottom: "1px solid rgba(203, 203, 204, 1)",

                }} >
                  <Typography variant="textSubtitleSmall" style={{
                    fontFamily: "Roboto",
                    color: "#1C1C1C",
                    fontSize: "12px",
                    lineHeight: "16px",
                    fontWeight: "400"
                  }}>
                    {convertAndGetTimeStampInRequiredFormat(data.insertionTime, "DD MMM'YY, hh:mm A")}
                  </Typography>
                </Box>
                <Box
                  style={{ padding: "17px" }}
                >
                  <Box style={{ display: "flex" }}>
                    <Box style={{ textAlign: "right", width: "97px" }}>
                      <Typography variant="tableHead"
                        style={{
                          color: "#444B51",
                          fontFamily: "Roboto",
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "400",
                          whiteSpace: 'nowrap'
                        }}>Alert Description :</Typography>
                    </Box>
                    <Box style={{ textAlign: "left", marginLeft: "15px" }}>
                      <Typography variant="textSubtitleSmall"
                        style={{
                          color: "#1C1C1C",
                          fontFamily: "Roboto",
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "600",
                        }}>
                        {data.alertDescription}
                      </Typography>
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", marginTop: "12px" }}>
                    <Box style={{ textAlign: "right", width: "97px" }}>
                      <Typography variant="tableHead"
                        style={{
                          color: "#444B51",
                          fontFamily: "Roboto",
                          fontSize: "12px",
                          lineHeight: "16px",
                          fontWeight: "400",
                          textAlign: "right"
                        }}>
                          Severity :
                        </Typography>
                    </Box>
                    <Box style={{ textAlign: "left" }}>
                      <Typography variant="textSubtitleSmall" style={{
                        color: "#1C1C1C",
                        fontSize: "12px",
                        lineHeight: "16px",
                        fontWeight: "600",
                        marginLeft: "15px"
                      }}>
                        {data.severity}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", marginTop: "12px"}}>
                    <Box style={{ textAlign: "right", width: "97px" }}>
                      <Typography variant="tableHead" style={{
                        color: "#444B51",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                        lineHeight: "16px",
                        fontWeight: "400",
                      }}>Alert Status :</Typography>
                    </Box>
                    <PTypography
                      style={{ marginLeft: "15px" }}
                      mycolor={
                        data.alertStatus === "Active"
                          ? ThemeProperties.red_100
                          : data.alertStatus === "Open"
                            ? ThemeProperties.blue_100
                            : data.alertStatus === "Resolved"
                              ? ThemeProperties.green_100
                              : data.alertStatus === "Ignored"
                                ? ThemeProperties.pink_100
                                : ThemeProperties.blueGrey_100
                      }
                    >
                      <Typography 
                        variant="tableRow" 
                        style={{ 
                          fontFamily: "Roboto",
                          fontWeight: "600",
                          fontSize: "12px",
                          lineHeight: "14px",
                          color: "#1C1C1C",

                        }}
                      >
                        {data.alertStatus}
                      </Typography>
                    </PTypography>
                  </Box>
                </Box>
              </Box>
            </div>
          ))
          }
        </Slider>
      </div >
    );
  }
}
