import React from "react";
// import { Button } from "antd";
import { Dialog, Typography } from "@mui/material";
import { OutlinedButton } from "../../Components/CustomButton";

function MapClearBatteries(props) {
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={props.openClearBatteryPopup}
      maxWidth="xs"
      sx={{ zIndex: 100001 }}
    >
      <div
        style={{
          padding: "16px",
        }}
      >
        <Typography variant="paperHeader">
          Do you want to clear the searched batteries?
        </Typography>
        <br />
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <OutlinedButton
            onClick={() => {
              props.setOpenClearBatteryPopup(false);
            }}
            variant="outlined"
          >
            <Typography variant="textSubtitlePurple">Cancel</Typography>
          </OutlinedButton>
          <OutlinedButton
            variant="contained"
            onClick={() => {
              props.ClearSearchedBatteries();
              props.setOpenClearBatteryPopup(false);
              props.openNotification("bottomLeft",`Setting City filter to Delhi`)
            }}
            sx={{ml:2}}
          >
            <Typography variant="textSubtitleWhite">Clear</Typography>
          </OutlinedButton>
        </div>
      </div>
    </Dialog>
  );
}

export default MapClearBatteries;
