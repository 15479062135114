import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import GenericTable from "../../Features/Table/GenericTable"
import NoData from "../../Helper/Loaders/NoData"
import { FullHeightBox } from "../../Components/CustomBox";
import { Box } from "@mui/material";
import CODE from "../../Static/Constants/StatusCodes";
import { TableSectionContainer } from "../AlertAnalytics/Components/StyledComponent";
import { getMetricTableData } from "../../Api/AlertAnalyticsApi";
import { getCurrentMonthStartAndEndDate } from "../../Helper/DatePicker/DateConverter";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import dayjs from "dayjs";
import { alertAnalyticsChartMeta } from "../../Static/Data/AlertAnalytics/AlertAnalytics";

function TableSection({ pagesContent, chartFilterValue, isCardFilterMounted }) {
  const [tableData, setTableData] = useState({
    data: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
    total: 0
  });
  const queryParams = decryptTheParams()
  const columns = pagesContent?.columns?.find(table => table.name === `${queryParams?.chartName}Table`)?.columns || []
  const pageNumber = useSelector((state) => state.TablePageNumber.value)
  const rowsPerPage = useSelector((state) => state.TableRowsPerPage.value)
  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value);
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const calendarStartDate = useSelector((state) => state.AlertAnalyticsStartDate.value) || getCurrentMonthStartAndEndDate()[0];
  const calendarEndDate = useSelector((state) => state.AlertAnalyticsEndDate.value) || getCurrentMonthStartAndEndDate()[1];
  const isFilterMounted = useSelector((state) => state.IsFiltersMounted.value);
  const sort = {
    sortBy: useSelector((state) => state.TableSortColumn.value) || null,
    order: useSelector((state) => state.TableSortOrder.value)
  }

  useEffect(() => {
    if (isFilterMounted && isCardFilterMounted) {
      fetchTableData()
    }
  }, [
    calendarStartDate,
    calendarEndDate,
    selectedFilters,
    chartFilterValue,
    isFilterMounted,
    isCardFilterMounted,
    pageNumber,
    rowsPerPage,
    sort.sortBy,
    sort.order,
  ])

  const fetchTableData = async () => {
    setTableData({...tableData, responseStatus: {
      code: CODE.LOADING,
      msg: ""
    }})
    const res = await getMetricTableData(pagesContent?.pageMeta?.metricNames?.[`${queryParams?.chartName}Table`], calendarStartDate, calendarEndDate, {...selectedFilters, ...chartFilterValue}, defaultFilterValues, pageNumber, rowsPerPage, sort)

    if (res?.responseStatus?.code === CODE.SUCCESS) {
      setTableData({
        data: res.response.data, 
        responseStatus: {
          code: CODE.SUCCESS,
          msg: ""
        },
        total: res.response.total.total
      })
    } else {
      setTableData({...tableData, responseStatus: {
        code: CODE.URL_NODATA,
        msg: ""
      }})
    }
  }

  const EmptyTextBox = ({}) => {
    return <Box style={{minHeight: "500px", height: "500px"}}>
      <FullHeightBox>
        {[CODE.URL_NODATA, CODE.SESSION_EXPIRED, 1999].includes(tableData.responseStatus.code) && <NoData text="No Data Found" />}
      </FullHeightBox>
    </Box>
  }

  return (
    <TableSectionContainer>
      <GenericTable 
        showTitle
        title={`${alertAnalyticsChartMeta[queryParams?.chartName]?.label} •`}
        secondaryText={`${dayjs(calendarStartDate).format("D MMM, YYYY")} - ${dayjs(calendarEndDate).format("D MMM, YYYY")}`}
        emptyTextBox={<EmptyTextBox />}
        tableData={tableData}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        totalRecords={tableData.total}
        isRowSelectable={false} 
        numberOfFixedColumns={0}
        spinning={tableData.responseStatus.code === CODE.LOADING}
        generateRowId={(record) => record.batteryID}
      />
    </TableSectionContainer>
  )
}

export default TableSection
