import React from 'react'
import { Box } from '@mui/material'

const BMSErrorLogTab = (props) => {
    const { ...other } = props
    return (
        <Box>BMSErrorLogTab</Box>
    )
}

export default BMSErrorLogTab