import { styled } from "@mui/material/styles";
import { Select } from "@mui/material";
const BasicSelect = styled(Select)(({ theme, myColor }) => ({
  backgroundColor: myColor,
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${myColor}`,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&.Mui-focused": {
      borderColor: "white",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: myColor,
  },
}));

const AnalyticsBasicSelect = styled(Select)(({ theme, myColor }) => ({
  backgroundColor: myColor,
  padding: 0,
  minHeight: "32px",
  maxHeight: "32px",
  borderRadius: "10px",

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${myColor}`,
  },
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&.Mui-focused": {
      borderColor: "white",
    },
    "&.MuiSelect-select": {
      padding: "0px !important",
    }
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: myColor,
  }
}));

const AnalyticsCardSelect = styled(AnalyticsBasicSelect)(({ cardColor }) => ({
  backgroundColor: cardColor,
  padding: 0,
  minHeight: "32px",
  maxHeight: "32px",
  borderRadius: "5px",

  "&:hover": {
    "&& fieldset": {
      border: "1px solid #E9E9E9",
    }
  },

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E9E9E9",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&.Mui-focused": {
      borderColor: "white",
    },
    "&.MuiSelect-select": {
      padding: "0px !important",
    }
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E9E9E9",
  }
}));

export { BasicSelect, AnalyticsBasicSelect, AnalyticsCardSelect };
