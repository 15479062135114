import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Box, Link, Typography } from "@mui/material"
import { CustomHeaderContainer } from "../../../Components/CustomBox"
import BatteryOnline from "../../../Assets/Icons/DigitalTwin/Battery_Online_2.svg"
import LiveMetricSection from "./LiveMetricSection/LiveMetricSection"
import PulseLoader from "react-spinners/MoonLoader"
import CustomIcon from "../../../Components/CustomIcon"
import FindTheStatus from "../../../Helper/Operations/FindTheStatus"
import Events from "../../../Analytics/Events"
import { Tooltip } from "antd"
import TextWithCopyIcon from "../../../Helper/Operations/TextWithCopyIcon"

function PageHeaderSection(props) {
  const liveMetricData = useSelector((state) => state.BatteryLiveMetric)
  const [isLiveMetricShown, setIsLiveMetricShown] = useState(false)

  let batteryIcon
  switch (FindTheStatus(liveMetricData?.status)) {
    case "Charging":
      batteryIcon = "batteryChargingState"
      break
    case "Discharging":
      batteryIcon = "batteryDischargingState"
      break
    default:
      batteryIcon = "batteryIdleState"
      break
  }

  const toggleLiveMetric = () => {
    Events(
      "On digital twin page, live metric button is clicked. The live metric is turned " +
      (isLiveMetricShown ? "Off" : "On")
    )
    setIsLiveMetricShown(!isLiveMetricShown)
  }

  return (
    <Box style={props.style}>
      <CustomHeaderContainer>
        <Tooltip
          placement="right"
          title={FindTheStatus(liveMetricData?.status)}
          zIndex={2000}
        >
          <Box style={{ position: "relative" }}>
            <CustomIcon
              name={batteryIcon}
              fill={"white"}
            />
            <CustomIcon
              name={liveMetricData.batteryPingedStatus === "Online" ? "online" : "offline"}
              style={{
                position: "absolute",
                bottom: "8px",
                right: "-3px",
                width: "14px",
                height: "10px"
              }}
            />
          </Box>
        </Tooltip>
        {TextWithCopyIcon(props.deviceID, "Device ID", {
          marginLeft: "10px",
          fontFamily: "Inter",
          fontWeight: 600,
          fontSize: "18px",
          color: "#2E3336",
          lineHeight: "24px"
        }, "bottom")}
        <Typography
          sx={{
            ml: "8px",
            pl: "6px",
            pr: "6px",
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "16px",
            borderRadius: "4px",
            color: props.batteryLevelAlertCount ? "#EE534F" : "#3CA967",
            backgroundColor: props.batteryLevelAlertCount ? "rgba(238, 83, 79, 0.16)" : "rgba(60, 169, 103, 0.16)",
            textAlign: "center"
          }}
        >
          {props.batteryLevelAlertCount || 0}
        </Typography>
        <Link
          sx={{
            ml: "10px",
            mr: "5px",
            fontFamily: "Roboto",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "16px",
            color: isLiveMetricShown ? "#768CFF" : "#87939D"
          }}
          href="#"
          underline="always"
          onClick={() => {
            toggleLiveMetric()
          }}
        >
          View {isLiveMetricShown ? "Less" : "More"}
        </Link>
        {liveMetricData === undefined && (
          <PulseLoader color={"blue"} size={15} speedMultiplier={1} />
        )}
        {isLiveMetricShown && liveMetricData && (
          <LiveMetricSection
            metricData={liveMetricData}
            style={{ marginLeft: "21px" }}
          />
        )}
      </CustomHeaderContainer>
    </Box>
  )
}

export default PageHeaderSection
