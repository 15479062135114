import React from "react"
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon
} from "@mui/material"
import { Tooltip } from "antd"
import WarningIcon from "../../../../Assets/Icons/DigitalTwin/Warning.svg"
import WarningIconSelected from "../../../../Assets/Icons/DigitalTwin/WarningSelected.svg"
import BatteryWithLine from "../../../../Assets/Icons/DigitalTwin/BatteryWithLine.svg"
import BatteryWithLineSelected from "../../../../Assets/Icons/DigitalTwin/BatteryWithLineSelected.svg"
import AlertTab from "./TabSection/AlertTab"
import BatterySystemTab from "./TabSection/BatterySystemTab"

const Sidebar = (props) => {
  const {
    isDrawerOpen,
    tabValue,
    pagesContent,
    handleTabChange,
    batteryID,
    selectedAlertID,
    alertsFilters,
    alertsSorts,
    alertList,
    getAlerts,
    hierarchy,
    hierarchySequence,
    selectedHierarchy,
    defaultHierarchy,
    defaultHierarchyLevel,
    selectedHierarchyLevel,
    handleHierarchyItemSelect,
    alertsCountHierarchical,
    isAlertFetching,
    handleClickOnAlert,
    hierarchyLiveMetricData
  } = props

  const enabledTab = pagesContent?.metaInfo?.enabledBatteryVitalsTabs || ['alertTab', 'batterySystemTab']

  return (
    <Box
      sx={{
        overflow: "none"
      }}
    >
      <Box
        style={{
          display: isDrawerOpen ? "block" : "none"
        }}
      >
        <Box
          style={{
            display: "flex",
            paddingLeft: "13px",
            paddingTop: "3.5px",
            borderTop: "1px solid #CDDEEF",
            borderBottom: "1px solid #CDDEEF"
          }}
        >
          <Tooltip placement="top" title={"Active Alerts"} zIndex={2000}>
            <Box
              style={{
                width: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom:
                  tabValue === "alertTab"
                    ? "1px solid #586CE9"
                    : "1px solid transparent",
                marginRight: "20px",
                opacity: enabledTab.includes('alertTab') ? 1 : 0.5,
                cursor: enabledTab.includes('alertTab') ? 'default' : 'not-allowed',
              }}
            >
              <IconButton
                disabled={!enabledTab.includes('alertTab')}
                onClick={() => {
                  handleTabChange("alertTab")
                }}
                children={
                  <img
                    src={
                      tabValue === "alertTab"
                        ? WarningIconSelected
                        : WarningIcon
                    }
                  />
                }
                style={{
                  padding: 0
                }}
              />
            </Box>
          </Tooltip>
          <Tooltip placement="top" title={"Battery config."} zIndex={2000}>
            <Box
              style={{
                width: "28px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom:
                  tabValue === "batterySystemTab"
                    ? "1px solid #586CE9"
                    : "1px solid transparent",
                marginRight: "20px",
                opacity: enabledTab.includes('batterySystemTab') ? 1 : 0.5,
                cursor: enabledTab.includes('batterySystemTab') ? 'default' : 'not-allowed',
              }}
            >
              <IconButton
                disabled={!enabledTab.includes('batterySystemTab')}
                onClick={() => {
                  handleTabChange("batterySystemTab")
                }}
                children={
                  <img
                    src={
                      tabValue === "batterySystemTab"
                        ? BatteryWithLineSelected
                        : BatteryWithLine
                    }
                  />
                }
                style={{
                  padding: 0
                }}
              />
            </Box>
          </Tooltip>
          {/* Disabling Temporary until designs are ready for this tab*/}
          {/* <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottom:
                    tabValue === "predictiveAlertsTab"
                      ? "1px solid #586CE9"
                      : "1px solid transparent",
                  width: "28px"
                }}
              >
                <IconButton
                  onClick={() => {
                    handleTabChange("predictiveAlertsTab")
                  }}
                  children={
                    <img
                      src={
                        tabValue === "predictiveAlertsTab"
                          ? PredictiveAlertsSelected
                          : PredictiveAlerts
                      }
                    />
                  }
                  style={{
                    padding: 0
                  }}
                />
              </Box> */}
        </Box>
        <Box>
          {enabledTab.includes('alertTab') && <AlertTab
            style={{
              paddingTop: "9px",
              display: tabValue === "alertTab" ? "block" : "none"
            }}
            selectedAlertID={selectedAlertID}
            alertsFilters={alertsFilters}
            alertsSorts={alertsSorts}
            alertList={alertList}
            getAlerts={getAlerts}
            batteryID={batteryID}
            onClickAlert={handleClickOnAlert}
            isAlertFetching={isAlertFetching}
          />}
          {/* <PredictiveAlertsTab 
                style={{ 
                  display: tabValue === "predictiveAlertsTab" ? "block" : "none"
                }}
              /> */}
          {enabledTab.includes('batterySystemTab') && <BatterySystemTab
            style={{
              display: tabValue === "batterySystemTab" ? "block" : "none",
              paddingTop: "9px",
              paddingLeft: "8px",
              paddingRight: "2px"
            }}
            tabValue={tabValue}
            batteryID={batteryID}
            hierarchy={hierarchy}
            hierarchySequence={hierarchySequence}
            hierarchyLiveMetricData={hierarchyLiveMetricData}
            defaultHierarchy={defaultHierarchy}
            defaultHierarchyLevel={defaultHierarchyLevel}
            selectedHierarchy={selectedHierarchy}
            selectedHierarchyLevel={selectedHierarchyLevel}
            onSelectHierarchy={handleHierarchyItemSelect}
            alertsCountHierarchical={alertsCountHierarchical}
          />}
        </Box>
      </Box>
      <List
        sx={{
          display: isDrawerOpen ? "none" : "block",
          pt: 0
        }}
      >
        <ListItem key={"activeAlerts"} disablePadding>
          <ListItemButton
            onClick={() => {
              handleTabChange("alertTab")
            }}
          >
            <ListItemIcon
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "15px"
              }}
            >
              <img
                src={
                  tabValue === "alertTab" ? WarningIconSelected : WarningIcon
                }
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem key={"batterySystem"} disablePadding>
          <ListItemButton
            onClick={() => {
              handleTabChange("batterySystemTab")
            }}
          >
            <ListItemIcon
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingLeft: "15px"
              }}
            >
              <img
                src={
                  tabValue === "batterySystemTab"
                    ? BatteryWithLineSelected
                    : BatteryWithLine
                }
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        {/* Disabling Temporary until designs are ready for this tab */}
        {/* <ListItem key={"predictiveAlerts"} disablePadding>
              <ListItemButton
                onClick={() => {
                  handleTabChange("predictiveAlertsTab")
                }}
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingLeft: "15px"
                  }}
                >
                  <img src={PredictiveAlerts} />
                </ListItemIcon>
              </ListItemButton>
            </ListItem> */}
      </List>
    </Box>
  )
}

export default Sidebar
