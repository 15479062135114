import qs from "qs";
import { GetData } from "./Functions/SettingData";
import { combineWithUnderscore } from "../Helper/Formatters/TextFormatter";
import { convertZonetoUTCEnd, convertZonetoUTCStart } from "../Helper/DatePicker/DateConverter";

async function getBatteryHierarchy(batteryID) {
  var config = {
    method: "get",
    url: `/api/digital_twin/battery_config/v2/${batteryID}`,
    headers: {
      "Content-Type": "application/json",
    }
  };

  let data = GetData(config);
  return data;
}

async function getRealtimeKPI(batteryID, pack, module, cell, level) {
  let rawJson = {
      batteryID: batteryID,
      packID: level === "pack" ? combineWithUnderscore(batteryID, pack, module, cell) : "",
      moduleID: level === "module" ? combineWithUnderscore(batteryID, pack, module, cell) : "",
      cellID: level === "cell" ? combineWithUnderscore(batteryID, pack, module, cell) : "",
      level: level
    }; 
  var config = {
    method: "get",
    url: `/api/digital_twin/real_time`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson
  };

  let data = GetData(config);
  return data;
}

async function getChargerData(batteryID) {
  let rawJson = {
    batteryID: batteryID
  }
  var config = {
    method: "get",
    url: `/api/digital_twin/charger_data`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getBatteryVitals(batteryID, pack, module, cell, level,date) {
  let rawJson = {
      batteryID: batteryID,
      packID: level === "pack" ? combineWithUnderscore(batteryID, pack, module, cell) : "",
      moduleID: level === "module" ? combineWithUnderscore(batteryID, pack, module, cell) : "",
      cellID: level === "cell" ? combineWithUnderscore(batteryID, pack, module, cell) : "",
      level: level,
      startTime : convertZonetoUTCStart(date[0]),
      endTime : convertZonetoUTCEnd(date[1])
    }; 
  var config = {
    method: "get",
    url: `/api/digital_twin/battery_vitals`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    // paramsSerializer: (params) =>
    //   qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getPackComparision(batteryID, pack, module, cell, level, value, date) {
  let rawJson = {
    "type":level ,
    "columns":[value],
    cellNo: cell ? cell.substring(1) : "",
    packNo: pack ? pack.substring(1) : "",
    moduleNo:  module ? module.substring(1) : "",
    //  packID: level === "pack" ? combineWithUnderscore(batteryID, pack, module, cell) : "",
    //  moduleID: level === "module" ? combineWithUnderscore(batteryID, pack, module, cell) : "",
    //  cellID: level === "cell" ? combineWithUnderscore(batteryID, pack, module, cell) : "",
    "batteryID":batteryID,
    startTime : convertZonetoUTCStart(date[0]),
    endTime : convertZonetoUTCEnd(date[1]),
    "resampleSeconds":300
  };
  var config = {
    method: "get",
    url: `/api/digital_twin/battery_metrics_combined/v3`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getPackComparisionV4(batteryID, systemID, level, value, date) {
  let rawJson = {
    "type":level ,
    "columns":[value],
    cellNo: level === "cell" ? systemID : null,
    packNo: level === "pack" ? systemID : null,
    moduleNo: level === "module" ? systemID : null,
    "batteryID":batteryID,
    startTime : convertZonetoUTCStart(date[0]),
    endTime : convertZonetoUTCEnd(date[1]),
    "resampleSeconds":300
  };

  var config = {
    method: "get",
    url: `/api/digital_twin/battery_metrics_combined/v4`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getChargerVitals(batteryID, value, date) {
  let rawJson = {

    "columns":value,
    "batteryID":batteryID,
    "resampleSeconds":300,
    startTime : convertZonetoUTCStart(date[0]),
    endTime : convertZonetoUTCEnd(date[1])
  };
  var config = {
    method: "get",
    url: `/api/digital_twin/charger_vitals`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getColorMapping(batteryID) {
  let rawJson = {

    "batteryID":batteryID,
  };
  var config = {
    method: "get",
    url: `/api/digital_twin/battery_info`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getGranularityData(
  batteryID,
  pack,
  module,
  cell,
  level,
  value,
  date,
  granular,
  operation
) {
  let rawJson = {
    type: level,
    cellNo: cell?.substring(1),
    packNo: pack?.substring(1),
    moduleNo:  module?.substring(1),
    columns: value,
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
    batteryID: batteryID,
    granularity: granular,
    operation: operation,
  };
  var config = {
    method: "get",
    url: `/api/digital_twin/battery_vitals_trend`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config,true);
  return data;
}

async function getGranularityDataV2(
  batteryID,
  systemID,
  level,
  value,
  date,
  granular,
  operation
) {
  let rawJson = {
    type: level,
    cellNo: level === "cell" ? systemID : null,
    packNo: level === "pack" ? systemID : null,
    moduleNo: level === "module" ? systemID : null,
    columns: value,
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
    batteryID: batteryID,
    granularity: granular,
    operation: operation,
  };
  var config = {
    method: "get",
    url: `/api/digital_twin/battery_vitals_trend/v2`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config,true);
  return data;
}

async function getChargerGranularityData(
  batteryID,
  columns,
  date,
  granular,
  operation
) {
  //batteryID,pack,module,cell,level,date,granular
  let rawJson = {
    columns: columns,
    startTime: convertZonetoUTCStart(date[0]),
    endTime: convertZonetoUTCEnd(date[1]),
    batteryID: batteryID,
    granularity: granular,
    operation: operation,
  };
  var config = {
    method: "get",
    url: `/api/digital_twin/charger_vitals_trend`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config,true);
  return data;
}

async function getBatteryHierarchicalAlertsCount(batteryID) {
  var rawJson = {
    startDate: null,
    endDate: null,
  };

  var config = {
    method: "get",
    url: `/api/digital_twin/dashboard/${batteryID}/hierarchical_alerts_count`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

async function getAlertsByGroup(fields, batteryID) {
  var rawJson = {
    status: fields?.status === "" ? "" : fields?.status || "",
    severity: fields?.severity === "" ? "" : fields?.severity || "",
    order: fields?.order === "" ? "" : fields?.order || "",
    sortBy: fields?.sortBy === "" ? "" : fields?.sortBy || "",
    startDate: fields?.startDate === "" ? "" : convertZonetoUTCStart(fields?.startDate) || "",
    endDate: fields?.endDate === "" ? "" : convertZonetoUTCEnd(fields?.endDate) || "",
  };

  var config = {
    method: "get",
    url: `api/digital_twin/dashboard/${batteryID}/digital_twin_alerts`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

export {
  getBatteryHierarchy,
  getRealtimeKPI,
  getBatteryVitals,
  getPackComparision,
  getPackComparisionV4,
  getChargerData,
  getChargerVitals,
  getColorMapping,
  getGranularityData,
  getGranularityDataV2,
  getChargerGranularityData,
  getBatteryHierarchicalAlertsCount,
  getAlertsByGroup
};
