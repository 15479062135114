import React from "react";
import { Dialog, Typography, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { OutlinedButton } from "../../Components/CustomButton";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { SetWarrantyStack } from "../../Actions/Warranty";
import { SetDiagnosticsStack } from "../../Actions/Diagnostics";

function RemoveSelectedBatteriesWarranty(props) {
  const dispatch = useDispatch();

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={props.openExportCSVConfirm}
      maxWidth="xs"
      sx={{ zIndex: 100001 }}
    >
      <div
        style={{
          padding: "16px",
          display: "inline",
          textAlign: "center",
          marginTop: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 0px",
          }}
        >
          <Typography variant="paperHeader"></Typography>
          <InfoIcon
            sx={{ fontSize: "20px", color: ThemeProperties.purple, ml: 4 }}
          />
          <IconButton
            onClick={() => {
              props.handleCloseExportCSVConfirm();
              if (props.page === "Warranty") dispatch(SetWarrantyStack([]));
              else if (props.page === "Diagnostics")
                dispatch(SetDiagnosticsStack([]));
            }}
            fontSize="small"
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
        </div>

        <br />
        <Typography variant="paperHeader">
          {props.heading}
        </Typography>
        <br />

        <Typography variant="textSubtitle">
          Selected batteries information is not downloaded. Do you want to
          download it?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          <OutlinedButton
            onClick={() => {
              props.handleCloseExportCSVConfirm();
              if (props.page === "Warranty") dispatch(SetWarrantyStack([]));
              else if (props.page === "Diagnostics")
                dispatch(SetDiagnosticsStack([]));
            }}
            variant="outlined"
          >
            <Typography variant="textSubtitlePurple">
              Remove selection
            </Typography>
          </OutlinedButton>
          <OutlinedButton
            variant="contained"
            onClick={() => {
              props.handleCloseExportCSVConfirm();
              props.CreateCSV();
            }}
            sx={{ ml: 2 }}
          >
            <Typography variant="textSubtitleWhite">Export CSV</Typography>
          </OutlinedButton>
        </div>
      </div>
    </Dialog>
  );
}

export default RemoveSelectedBatteriesWarranty;
