const initialSort = {
  value: "alertTime",
  // orderBy: "asc"
};

const initialRowsPerPage = {
  value: 20,
};

const initialOrder = {
    value: "desc"
}

const StackTableOrder = (state = initialOrder, action) => {
  switch (action.type) {
    case "StackTableOrder":
      return {
        value: action.order,
      };
    default:
      return state;
  }
};

const StackTableSort = (state = initialSort, action) => {
  switch (action.type) {
    case "StackTableSort":
      return {
        value: action.sort,
      };
    default:
      return state;
  }
};

const StackTableRowsPerPage = (state = initialRowsPerPage, action) => {
  switch (action.type) {
    case "StackTableRowsPerPage":
      return {
        value: action.rows,
      };
    default:
      return state;
  }
};

export {
    StackTableOrder,
    StackTableSort,
    StackTableRowsPerPage
};
