import React, { useState, useEffect, useMemo } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Typography, Box, Popover } from "@mui/material";
import Offline from "../../Assets/Icons/DigitalTwin/Battery_Offline.svg";
import Online from "../../Assets/Icons/DigitalTwin/Battery_Online.svg";

import Idle from "../../Assets/Icons/DigitalTwin/Battery_Idle.svg";
import Charging from "../../Assets/Icons/DigitalTwin/Battery_Charging.svg";
import Discharging from "../../Assets/Icons/DigitalTwin/Battery_Discharging.svg";
import Error from "../../Assets/Icons/DigitalTwin/Battery_Error.svg";

import { Tooltip } from "antd";
import CODE from "../../Static/Constants/StatusCodes";
import MoonLoader from "react-spinners/MoonLoader";
import FindTheStatus from "../../Helper/Operations/FindTheStatus";

function getCurrentPingStatus(pingStatus) {
  return (
    <>
      {pingStatus.responseStatus.code === CODE.SUCCESS ? (
        <>
          <Tooltip title={pingStatus.ping}>
            <img
              src={pingStatus.ping === "Online" ? Online : Offline}
              alt="not found"
              height={"24px"}
            />
          </Tooltip>
          <Tooltip title={FindTheStatus(pingStatus.status)}>
            <img
              src={
                pingStatus.status === 0
                  ? Idle
                  : pingStatus.status === 4
                  ? Charging
                  : pingStatus.status === 2
                  ? Discharging
                  : Error
              }
              alt="not found"
              height={"24px"}
            />
          </Tooltip>
        </>
      ) : (
        <>
          {pingStatus.responseStatus.code === CODE.LOADING ? (
            <MoonLoader size={15} />
          ) : (
            <Tooltip title={"Offline"}>
              <img src={Offline} alt="not found" height={"24px"} />
            </Tooltip>
          )}
        </>
      )}
    </>
  );
}

function HeaderWithIcons(props) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E7E5E5",
        p: "0px 12px",
        height: "50px",
      }}
    >
      <Typography variant="paperHeader">{props.heading}</Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {getCurrentPingStatus(props.pingStatus)}
      </Box>
    </Box>
  );
}

export default HeaderWithIcons;
