const initialBatteryDateDisable = {
  value: false,
};

const initialBatteryDateInvalid = {
  value: false,
}

const initialBatteryCarouselDate = {
  value: "",
};

const SpecificBatteryDateDisable = (
  state = initialBatteryDateDisable,
  action
) => {
  switch (action.type) {
    case "SpecificBatteryDateDisable":
      return {
        value: action.disabled,
      };
    default:
      return state;
  }
};

const SpecificBatteryDateInvalid = (
  state = initialBatteryDateInvalid,
  action
) => {
  switch (action.type) {
    case "SpecificBatteryDateInvalid":
      return {
        value: action.invalid,
      };
    default:
      return state;
  }
};

const SpecificBatteryCarouselDate = (
  state = initialBatteryCarouselDate,
  action
) => {
  switch (action.type) {
    case "SpecificBatteryCarouselDate":
      return {
        value: action.date,
      };
    default:
      return state;
  }
};

export { SpecificBatteryDateDisable, SpecificBatteryCarouselDate, SpecificBatteryDateInvalid };
