import { areArraysEqual } from "./ArrayOps";

const GetFilterKeyValue = (data) => {
  if ((!data) || data.length < 1) {
    return []
  }
  let MapLocationFilter = data.split(",");
  let mapFilterItems = [];

  MapLocationFilter.forEach((item) => {
    mapFilterItems.push({
      value: item,
      id: item,
    });
  });

  return mapFilterItems;
}

const extractArrayValuesForFilters = (filters) => {
  const result = {}
  for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
        result[key] = filters[key].map(item => item.id);
    }
  }
  return result
}

const nonMatchingFilters = (selectedFilters, defaultFilterValues) => {
  if (!defaultFilterValues) {
    return selectedFilters
  }

  return Object.keys(selectedFilters).reduce((result, key) => {
    if (!Array.isArray(selectedFilters[key])) {
      result[key] = selectedFilters[key]
      return result;
    }
    const isDifferent = !areArraysEqual(selectedFilters[key], defaultFilterValues[key])
  
    if (isDifferent) {
        result[key] = selectedFilters[key];
    }
  
    return result;
  }, {});
}


export {
  GetFilterKeyValue,
  nonMatchingFilters,
  extractArrayValuesForFilters
};
