import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Typography } from "@mui/material";
import { InnerBox, OuterBox } from "../../Components/CustomBox";
import {
  PaperHighlight,
  PaperWithBottomRadius,
} from "../../Components/CustomPaper";
import AssemblyDetails from "../../PagesContent/DigitalTwin/Assembly/AssemblyDetails";
import VitalsSection from "../../PagesContent/DigitalTwin/VitalsKPI/VitalsSection";

import BasicTabs from "../../Features/Tabs/BasicTabs";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";

import {
  decryptTheParams,
  encryptTheParamsForOldDigitalTwinPage,  // Temporary Changes to access old Digital Twin Page
} from "../../Helper/QueryParams/EncryptDecrypt";
import CODE from "../../Static/Constants/StatusCodes";
import AntDatePicker from "../../Helper/DatePicker/AntDatePicker";
import { notification } from "antd";

import Alerts from "../../PagesContent/DigitalTwin/TabsSection/Alerts/Alerts";
import {
  getBatteryHierarchy,
  getColorMapping,
  getRealtimeKPI,
} from "../../Api/DigitalTwin";

import ScrollToTheElement from "../../Helper/Operations/ScrollToTheElement";
import HeaderWithIcons from "../../Features/Header/HeaderWithIcons";
import { getDatePickerDate } from "../../Helper/DatePicker/DateConverter";
import Events from "../../Analytics/Events";
import { useSelector } from "react-redux";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import BatteryVitalsMain from "../../PagesContent/DigitalTwin/TabsSection/BatteryVitals/BatteryVitalsMain";
import ChargerVitalsMain from "../../PagesContent/DigitalTwin/TabsSection/ChargerVitals/ChargerVitalsMain";

const Context = React.createContext({
  name: "Default",
});

const returnValue = (value) => {
  if (value && value !== "null") return value;
  else return null;
};

const getComponentColor = (selectedKPI, value) => {
  if (selectedKPI === "soc") {
    if (value < 30) return ThemeProperties.assembly_low;
    else if (value >= 30 && value < 40) return ThemeProperties.assembly_medium;
    else return ThemeProperties.assembly_high;
  } else if (selectedKPI === "temperature") {
    if (value >= 45) return ThemeProperties.assembly_low;
    else if (value >= 30 && value < 45) return ThemeProperties.assembly_medium;
    else return ThemeProperties.assembly_high;
  } else if (selectedKPI === "voltage") {
    if (value < 20) return ThemeProperties.assembly_low;
    else if (value >= 20 && value < 40) return ThemeProperties.assembly_medium;
    else return ThemeProperties.assembly_high;
  }
};

function DigitalTwinMain(props) {
  const queryParams = decryptTheParams();
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();
  const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);

  const openNotification = (placement, text) => {
    api.info({
      // message: `Notification ${placement}`,s
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const [currentPing, setCurrentPing] = useState({
    ping: "Not found",
    status: "Not found",
    responseStatus: {
      code: CODE.LOADING,
      msg: "Loading",
    },
  });

  const [tabvalue, setTabValue, refTabValue] = useState(
    +queryParams["tabValue"]
  );

  const [hierarchy, setHierarchy] = useState({
    p: 0,
    m: 0,
    c: 0,
    selectedPack: {
      key: returnValue(queryParams["selectedPackKey"]),
      value: returnValue(queryParams["selectedPackValue"]),
    },
    selectedModule: {
      key: returnValue(queryParams["selectedModuleKey"]),
      value: returnValue(queryParams["selectedModuleValue"]),
    },
    selectedCell: {
      key: returnValue(queryParams["selectedCellKey"]),
      value: returnValue(queryParams["selectedCellValue"]),
    },
    selectedKPI: {
      value: null,
    },
    level: queryParams["level"] ? queryParams["level"] : "battery",
    packHierarchy: [],

    responseStatus: {
      code: -1,
      msg: "",
    },
  });

  const [initialColorCoding, setInitialColorCoding] = useState(null);

  const [hierarchyColorCoding, setHierarchyColorCoding] = useState({
    data: [],
    responseStatus: {
      code: -1,
      msg: "",
    },
  });

  const [view, setView] = useState(
    queryParams["selectedView"] ? queryParams["selectedView"] : "Battery"
  );

  console.log(queryParams, 'Query Parameters');

  let start = queryParams["startDateSB"]
    ? queryParams["startDateSB"]
    : getDatePickerDate();
  let end = queryParams["endDateSB"]
    ? queryParams["endDateSB"]
    : getDatePickerDate();

  const [date, setDate] = useState([start, end]);
  const [dateString, setDateString] = useState(["", ""]);

  const generateHierarchy = (p, m, c) => {
    let finalObj = [];
    for (let i = 0; i < p; i++) {
      let tempModule = [];
      for (let j = 0; j < m; j++) {
        let tempCell = [];
        for (let k = 0; k < c; k++) {
          let key = "C" + (k + 1);
          let value = "c" + (k + 1);
          tempCell.push({
            key: key,
            value: value,
            color: ThemeProperties.purple,
          });
        }
        tempModule.push({
          key: "M" + (j + 1),
          value: "m" + (j + 1),
          content: tempCell,
          color: "rgb(184, 184, 255)",
        });
      }
      finalObj.push({
        key: "P" + (i + 1),
        value: "p" + (i + 1),
        content: tempModule,
        color: "#e0e0e0",
      });
    }
    return finalObj;
  };
  const generateColoredHierarchy = (selectedKPI) => {
    const { p, m, c } = hierarchy;
    const finalObj = [];

    for (let i = 0; i < p; i++) {
      const tempModule = [];
      const curr_pack_name = `${props.batteryID}_P${i + 1}`;
      const curr_pack = hierarchyColorCoding.data.childData[i]?.[curr_pack_name];

      for (let j = 0; j < m; j++) {
        const tempCell = [];
        const curr_mod_name = `${curr_pack_name}_M${j + 1}`;
        const curr_mod = curr_pack?.childData?.[j]?.[curr_mod_name];

        for (let k = 0; k < c; k++) {
          const curr_cell_name = `${curr_mod_name}_C${k + 1}`;
          const curr_cell = curr_mod?.childData?.[k]?.[curr_cell_name];
          const key = `C${k + 1}`;
          const value = `c${k + 1}`;
          const color = curr_cell ? getComponentColor(selectedKPI, curr_cell[selectedKPI]) : undefined;

          tempCell.push({ key, value, color });
        }

        tempModule.push({
          key: `M${j + 1}`,
          value: `m${j + 1}`,
          content: tempCell,
          color: curr_mod ? getComponentColor(selectedKPI, curr_mod[selectedKPI]) : undefined,
        });
      }

      finalObj.push({
        key: `P${i + 1}`,
        value: `p${i + 1}`,
        content: tempModule,
        color: curr_pack ? getComponentColor(selectedKPI, curr_pack[selectedKPI]) : undefined,
      });
    }

    return finalObj;
  };
  const handleSetHierarchy = (p, m, c, level, kpi) => {
    if (p.value !== null || m.value !== null || c.value !== null)
      handleChangeView("Battery");

    if (kpi.value === null)
      setHierarchy((prev) => ({
        ...prev,
        selectedPack: p,
        selectedModule: m,
        selectedCell: c,
        level: level,
        selectedKPI: kpi,
        packHierarchy: initialColorCoding,
      }));
    else
      setHierarchy((prev) => ({
        ...prev,
        selectedPack: p,
        selectedModule: m,
        selectedCell: c,
        level: level,
        selectedKPI: kpi,
        packHierarchy: generateColoredHierarchy(kpi.value),
      }));

    let queryParams = decryptTheParams();
    let temp = {
      ...queryParams,
      selectedPackKey: p.key,
      selectedPackValue: p.value,
      selectedModuleKey: m.key,
      selectedModuleValue: m.value,
      selectedCellKey: c.key,
      selectedCellValue: c.value,
      level: level,
      id: "",
    };
    encryptTheParamsForOldDigitalTwinPage(temp, navigate, true); // Temporary Changes to access old Digital Twin Page
  };

  const ChangeTheTab = (newValue) => {
    let temp, tabValue, tabName;

    tabValue = props.pagesContent.metaInfo.tabs.find(
      (o) => o.name === newValue
    ).id;
    tabName = props.pagesContent.metaInfo.tabs.find(
      (o) => o.name === newValue
    ).name;
    setTabValue(tabValue);
    temp = {
      ...queryParams,
      tabName: tabName,
      tabValue: tabValue,
      granularity: "Raw Data",
    };
    encryptTheParamsForOldDigitalTwinPage(temp, navigate, true); // Temporary Changes to access old Digital Twin Page
  };

  const handleChangeView = (value) => {
    setView(value);
    let queryParams = decryptTheParams();
    let temp = {
      ...queryParams,
      selectedView: value,
    };
    encryptTheParamsForOldDigitalTwinPage(temp, navigate, true); // Temporary Changes to access old Digital Twin Page
  };

  const ChangeTheDate = (startDate, endDate) => {
    Events("changed Battery Details date");

    let temp;

    if (startDate === null && endDate === null) {
      setDate(["", ""]);
      temp = {
        ...queryParams,
        startDateSB: "",
        endDateSB: "",
        batteryStatus: "",
        supplementVitals: "",
      };
    } else if (endDate === null) {
      setDate([startDate, startDate]);
      temp = {
        ...queryParams,
        startDateSB: startDate,
        endDateSB: startDate,
        batteryStatus: "",
        supplementVitals: "",
      };
    } else if (startDate === null) {
      setDate([endDate, endDate]);
      temp = {
        ...queryParams,
        startDateSB: endDate,
        endDateSB: endDate,
        batteryStatus: "",
        supplementVitals: "",
      };
    } else {
      setDate([startDate, endDate]);
      temp = {
        ...queryParams,
        startDateSB: startDate,
        endDateSB: endDate,
        batteryStatus: "",
        supplementVitals: "",
      };
    }

    encryptTheParamsForOldDigitalTwinPage(temp, navigate, true); // Temporary Changes to access old Digital Twin Page
    // setDate([startDate, endDate]);
  };

  const SwitchTheTab = (tabvalue) => {
    switch (tabvalue) {
      case "Alerts":
        ScrollToTheElement();
        return (
          <Alerts
            date={date}
            dateString={dateString}
            ChangeTheDate={ChangeTheDate}
            ChangeTheTab={ChangeTheTab}
            openNotification={openNotification}
            hierarchy={hierarchy}
          />
        );
      case "Battery Vitals":
        ScrollToTheElement();
        return (
          <BatteryVitalsMain
            date={date}
            dateString={dateString}
            ChangeTheDate={ChangeTheDate}
            ChangeTheTab={ChangeTheTab}
            openNotification={openNotification}
            hierarchy={hierarchy}
            batteryID={props.batteryID}
            filter={props.pagesContent.metaInfo.filters.granularity}
          />
        );
      case "Charger Vitals":
        ScrollToTheElement();
        return (
          <ChargerVitalsMain
            date={date}
            dateString={dateString}
            ChangeTheDate={ChangeTheDate}
            ChangeTheTab={ChangeTheTab}
            openNotification={openNotification}
            hierarchy={hierarchy}
            batteryID={props.batteryID}
            filter={props.pagesContent.metaInfo.filters.granularity}
          />
        );
    }
  };

  useEffect(() => {
    setHierarchy({
      ...hierarchy,
      selectedPack: {
        key: returnValue(queryParams["selectedPackKey"]),
        value: returnValue(queryParams["selectedPackValue"]),
      },
      level: queryParams["level"] ? queryParams["level"] : "battery",
    });
  }, [queryParams["selectedPackKey"]]);

  useEffect(() => {
    setHierarchy({
      p: 0,
      m: 0,
      c: 0,
      selectedPack: {
        key: returnValue(queryParams["selectedPackKey"]),
        value: returnValue(queryParams["selectedPackValue"]),
      },
      selectedModule: {
        key: returnValue(queryParams["selectedModuleKey"]),
        value: returnValue(queryParams["selectedModuleValue"]),
      },
      selectedCell: {
        key: returnValue(queryParams["selectedCellKey"]),
        value: returnValue(queryParams["selectedCellValue"]),
      },
      selectedKPI: {
        value: null,
      },
      level: queryParams["level"] ? queryParams["level"] : "battery",
      packHierarchy: [],

      responseStatus: {
        code: CODE.LOADING,
        msg: "Loading",
      },
    });

    setCurrentPing({
      ping: "Not found",
      status: "Not found",
      responseStatus: {
        code: CODE.LOADING,
        msg: "Loading",
      },
    });

    setHierarchyColorCoding({
      data: [],
      responseStatus: {
        code: CODE.LOADING,
        msg: "Loading",
      },
    });

    getBatteryHierarchy(props.batteryID).then((res) => {
      if (res.responseStatus.code === CODE.SUCCESS) {
        let p = res.response.batteryconfig.packCount,
          m = res.response.batteryconfig.moduleCountPerPack,
          c = res.response.batteryconfig.cellCountPerModule;

        let finalObj = generateHierarchy(p, m, c);

        setInitialColorCoding(finalObj);

        setHierarchy((prev) => ({
          ...prev,
          p: res.response.batteryconfig.packCount,
          m: res.response.batteryconfig.moduleCountPerPack,
          c: res.response.batteryconfig.cellCountPerModule,
          packHierarchy: finalObj, //res.response.batteryStructure
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        }));
      } else {
        setHierarchy((prev) => ({
          ...prev,
          packHierarchy: [],
          responseStatus: {
            code: CODE.NODATA,
            msg: res.responseStatus.msg,
          },
        }));
      }
    });

    getRealtimeKPI(props.batteryID, "", "", "", "battery").then((res) => {
      if (res.responseStatus.code === CODE.SUCCESS) {
        setCurrentPing({
          ping: res.response.batteryData[0]["batteryPingedStatus"],
          status: res.response.batteryData[0]["status"],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      } else {
        setCurrentPing({
          ping: "Not found",
          status: "Not found",
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });

    getColorMapping(props.batteryID).then((res) => {
      if (res.responseStatus.code === CODE.SUCCESS) {
        setHierarchyColorCoding({
          data: res.response.batteryData,
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      } else {
        setHierarchyColorCoding({
          data: [],
          responseStatus: {
            code: res.responseStatus.code,
            msg: res.responseStatus.msg,
          },
        });
      }
    });
    setTabValue(
      props.pagesContent.metaInfo.tabs.find((o) => o.name === props.tabName)?.id
    );
  }, [timezoneChanged]); //creating structure

  useEffect(() => {
    let finalDate = ["", ""];
    finalDate[0] = date[0];
    finalDate[1] = date[1];

    setDateString([finalDate[0], finalDate[1]]);
  }, [date, timezoneChanged]); // handling dateString change

  return (
    <div>
      <OuterBox>
        <InnerBox>
          <Context.Provider value={contextValue}>
            {contextHolder}
            <Box
              sx={{
                margin: "5px 0px 20px 2px",
              }}
            >
              <Typography variant="textSubtitleBold">
                Battery Details
              </Typography>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={6} md={6} lg={4} xl={4}>
                <PaperHighlight elevation={0} sx={{ height: "100%" }}>
                  <HeaderWithIcons
                    heading={`Device ID : ${props.batteryID}`}
                    description="What's Happening in Fleet"
                    pingStatus={currentPing}
                    showIButton={false}
                    showThreeDots={false}
                  />
                  <div className="section">
                    <AssemblyDetails
                      hierarchy={hierarchy}
                      hierarchyColorCoding={hierarchyColorCoding}
                      handleSetHierarchy={handleSetHierarchy}
                    />
                  </div>
                </PaperHighlight>
              </Grid>
              <Grid item xs={6} md={6} lg={8} xl={8}>
                <PaperHighlight elevation={0} sx={{ height: "100%" }}>
                  <Box sx={{ p: "0px 8px 0px 8px" }}>
                    <VitalsSection
                      view={view}
                      hierarchy={hierarchy}
                      batteryID={props.batteryID}
                      handleChangeView={handleChangeView}
                      handleSetHierarchy={handleSetHierarchy}
                      packHierarchy={props.pagesContent.metaInfo.packAssembly}
                      charger={props.pagesContent.metaInfo.charger}
                      hierarchyColorCoding={hierarchyColorCoding}
                    />
                  </Box>
                </PaperHighlight>
              </Grid>

              <Grid item xs={12} md={12} lg={12} xl={12}>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{ position: "absolute", right: 0, top: 15, zIndex: 99 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <AntDatePicker
                        page="digitalTwin"
                        changeTheDate={ChangeTheDate}
                        date={[date[0], date[1]]}
                        allowClear={false}
                      />
                    </Box>
                  </Box>

                  <BasicTabs
                    ChangeTheTab={ChangeTheTab}
                    tabs={props.pagesContent.metaInfo.tabs}
                    tabValue={refTabValue}
                  />
                  <br></br>

                  <BasicTabPanel
                    value={tabvalue}
                    index={tabvalue}
                    key={tabvalue}
                  >
                    <PaperWithBottomRadius
                      elevation={0}
                      sx={{ border: "1px solid #ececec" }}
                    >
                      {SwitchTheTab(
                        props.pagesContent.metaInfo.tabs.find(
                          (o) => o.id === tabvalue
                        )?.name
                      )}
                    </PaperWithBottomRadius>
                  </BasicTabPanel>
                </Box>
              </Grid>
            </Grid>
          </Context.Provider>
        </InnerBox>
      </OuterBox>
    </div>
  );
}

export default DigitalTwinMain;
