import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/material';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 19,
  height: 11,
  padding: 0,
  display: "flex",
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(8px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#768CFF"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    width: 9,
    height: 9,
    borderRadius: 6,
  }
}))

function CustomSwitch(props) {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.onChange(event.target.checked)
  };

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])

  return (
    <Box
      style={props.style}
    >
      <AntSwitch 
        checked={checked}
        onChange={handleChange}
      />
    </Box>
  )
}

export default CustomSwitch
