const inititalColumns = {
  value: [],
};

const inititalHiddenColumns = {
  value: [],
};
const initialIsBatteryIsPartnerIsNullWarranty = {
  value: null,
};
const initialBatteryID = {
  value: null,
};
const initialPartner = {
  value: null,
};

const initialVolt = {
  value: "",
};
const initialCity = {
  value: "",
};
const initialCell = {
  value: "",
};
const initialStatus = {
  value: "",
};

const initialZone = {
  value: "",
};

const initialVersion = {
  value: "",
};
const initialIOT = {
  value: "",
};
const initialPack = {
  value: "",
};
const initialCycle = {
  value: "",
};
const initialAge = {
  value: "",
};
const initialSOH = {
  value: "",
};

const initialTab = {
  value: 0,
};
const initialWarrantyIndex = {
  value: 0,
};
const initialWarrantyCode = {
  value: "",
};
const initialTotal = {
  value: 0,
};
const initialStartDate = {
  value: "",
};
const initialEndDate = {
  value: "",
};
const initialWarrantyList = {
  value: [],
};
const initialWarrantyListOfBatteries = {
  value: [],
};
const initialBuildNo = {
  value: [],
};

const initialWarrantyStack = {
  arr: [],
};

const initialWarrantyColumnSort = {
  sortBy: "onboardingDate",
  // orderBy: "asc"
};
const initialWarrantyColumnSortOrder = {
  orderBy: "desc",
};
const initialPageNumber = {
  value: 1,
};

const initialRowsPerPage = {
  value: 20,
};

const initialOpenSelectedBatteries = {
  value: false,
};

const WarrantyColumns = (state = inititalColumns, action) => {
  switch (action.type) {
    case "WarrantyColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const WarrantyHiddenColumns = (state = inititalHiddenColumns, action) => {
  switch (action.type) {
    case "WarrantyHiddenColumns":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const IsBatteryIsPartnerIsNullWarranty = (
  state = initialIsBatteryIsPartnerIsNullWarranty,
  action
) => {
  switch (action.type) {
    case "IsBatteryIsPartnerIsNullWarranty":
      return {
        value: action.value,
      };
    default:
      return state;
  }
};

const WarrantyBatteryID = (state = initialBatteryID, action) => {
  switch (action.type) {
    case "WarrantyBatteryID":
      return {
        value: action.batteryID,
      };
    default:
      return state;
  }
};

const WarrantyPartner = (state = initialPartner, action) => {
  switch (action.type) {
    case "WarrantyPartner":
      return {
        value: action.partner,
      };
    default:
      return state;
  }
};

const WarrantyVolt = (state = initialVolt, action) => {
  switch (action.type) {
    case "WarrantyVolt":
      return {
        value: action.volt,
      };
    default:
      return state;
  }
};
const WarrantyCity = (state = initialCity, action) => {
  switch (action.type) {
    case "WarrantyCity":
      return {
        value: action.city,
      };
    default:
      return state;
  }
};
const WarrantyCell = (state = initialCell, action) => {
  switch (action.type) {
    case "WarrantyCell":
      return {
        value: action.cell,
      };
    default:
      return state;
  }
};
const WarrantyBusiness = (state = initialZone, action) => {
  switch (action.type) {
    case "WarrantyBusiness":
      return {
        value: action.business,
      };
    default:
      return state;
  }
};
const WarrantyVersion = (state = initialVersion, action) => {
  switch (action.type) {
    case "WarrantyVersion":
      return {
        value: action.version,
      };
    default:
      return state;
  }
};
const WarrantyBuildNo = (state = initialBuildNo, action) => {
  switch (action.type) {
    case "WarrantyBuildNo":
      return {
        // ...state,
        value: action.payload,
      };
    default:
      return state;
  }
};
const WarrantyStatus = (state = initialStatus, action) => {
  switch (action.type) {
    case "WarrantyStatus":
      return {
        value: action.status,
      };
    default:
      return state;
  }
};

const WarrantyIOT = (state = initialIOT, action) => {
  switch (action.type) {
    case "WarrantyIOT":
      return {
        value: action.iot,
      };
    default:
      return state;
  }
};
const WarrantyPack = (state = initialPack, action) => {
  switch (action.type) {
    case "WarrantyPack":
      return {
        value: action.pack,
      };
    default:
      return state;
  }
};
const WarrantyCycle = (state = initialCycle, action) => {
  switch (action.type) {
    case "WarrantyCycle":
      return {
        value: action.cycle,
      };
    default:
      return state;
  }
};
const WarrantyAge = (state = initialAge, action) => {
  switch (action.type) {
    case "WarrantyAge":
      return {
        value: action.age,
      };
    default:
      return state;
  }
};
const WarrantySOH = (state = initialSOH, action) => {
  switch (action.type) {
    case "WarrantySOH":
      return {
        value: action.soh,
      };
    default:
      return state;
  }
};

const WarrantyTabValue = (state = initialTab, action) => {
  switch (action.type) {
    case "WarrantyTabValue":
      return {
        value: action.tab,
      };
    default:
      return state;
  }
};

const WarrantyIndex = (state = initialWarrantyIndex, action) => {
  switch (action.type) {
    case "WarrantyIndex":
      return {
        value: action.index,
      };
    default:
      return state;
  }
};

const WarrantyCode = (state = initialWarrantyCode, action) => {
  switch (action.type) {
    case "WarrantyCode":
      return {
        value: action.code,
      };
    default:
      return state;
  }
};

const WarrantyTotal = (state = initialTotal, action) => {
  switch (action.type) {
    case "WarrantyTotal":
      return {
        value: action.total,
      };
    default:
      return state;
  }
};
const WarrantyStartDate = (state = initialStartDate, action) => {
  switch (action.type) {
    case "WarrantyStartDate":
      return {
        value: action.start,
      };
    default:
      return state;
  }
};
const WarrantyEndDate = (state = initialEndDate, action) => {
  switch (action.type) {
    case "WarrantyEndDate":
      return {
        value: action.end,
      };
    default:
      return state;
  }
};
const WarrantyList = (state = initialWarrantyList, action) => {
  switch (action.type) {
    case "WarrantyList":
      return {
        value: action.list,
      };
    default:
      return state;
  }
};
const WarrantyListOfBatteries = (
  state = initialWarrantyListOfBatteries,
  action
) => {
  switch (action.type) {
    case "WarrantyListOfBatteries":
      return {
        value: action.list,
      };
    default:
      return state;
  }
};

const WarrantyStack = (state = initialWarrantyStack, action) => {
  switch (action.type) {
    case "WarrantyStack":
      return {
        ...state,
        arr: action.batteries,
      };
    default:
      return state;
  }
};

const WarrantyColumnSort = (state = initialWarrantyColumnSort, action) => {
  switch (action.type) {
    case "WarrantyColumnSort":
      return {
        sortBy: action.sort,
      };
    default:
      return state;
  }
};

const WarrantyColumnSortOrder = (
  state = initialWarrantyColumnSortOrder,
  action
) => {
  switch (action.type) {
    case "WarrantyColumnSortOrder":
      return {
        orderBy: action.sortOrder,
      };
    default:
      return state;
  }
};

const WarrantyPageNumber = (state = initialPageNumber, action) => {
  switch (action.type) {
    case "WarrantyPageNumber":
      return {
        value: action.page,
      };
    default:
      return state;
  }
};

const WarrantyRowsPerPage = (state = initialRowsPerPage, action) => {
  switch (action.type) {
    case "WarrantyRowsPerPage":
      return {
        value: action.rows,
      };
    default:
      return state;
  }
};

const WarrantyOpenAddSelectedBatteries = (state = initialOpenSelectedBatteries, action) => {
  switch (action.type) {
    case "WarrantyOpenAddSelectedBatteries":
      return {
        value: action.open,
      };
    default:
      return state;
  }
};

export {
  WarrantyColumns,
  WarrantyHiddenColumns,
  IsBatteryIsPartnerIsNullWarranty,
  WarrantyBatteryID,
  WarrantyPartner,
  WarrantyVolt,
  WarrantyCity,
  WarrantyCell,
  WarrantyBusiness,
  WarrantyIOT,
  WarrantyAge,
  WarrantyCycle,
  WarrantyPack,
  WarrantySOH,
  WarrantyBuildNo,
  WarrantyVersion,
  WarrantyStatus,
  WarrantyTabValue,
  WarrantyIndex,
  WarrantyCode,
  WarrantyTotal,
  WarrantyStartDate,
  WarrantyEndDate,
  WarrantyList,
  WarrantyListOfBatteries,
  WarrantyStack,
  WarrantyColumnSort,
  WarrantyColumnSortOrder,
  WarrantyPageNumber,
  WarrantyRowsPerPage,
  WarrantyOpenAddSelectedBatteries
};
