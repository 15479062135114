import React, { useEffect, useState } from "react";
// import useState from "react-usestateref";
// import "./table.css";
import { Table, Pagination } from "antd";
import ReactDragListView from "react-drag-listview";
import { ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  SetAllBatteriesStack,
  SetKPIColumnSort,
  SetAllBatteriesPageNumber,
  SetAllBatteriesRowsPerPage,
  SetAllBatteriesColumns,
} from "../../../Actions/AllBatteries";
import { SetSessionExpired } from "../../../Actions";
import { SetTableOrder } from "../../../Actions";
import { getSelectAllBatteries } from "../../../Api/AllBatteriesApi";
import { useNavigate,useLocation } from "react-router-dom";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import NoData from "../../../Helper/Loaders/NoData";
import CODE from "../../../Static/Constants/StatusCodes";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
import Events from "../../../Analytics/Events";

export default function BasicTableAllBatteries(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // ############  Search  ###########
  //   const preColumns = props.columns.map((column) => {
  //     return { ...column };
  //   });
  const [columns, setColumns] = useState(props.columns[0]["columns"]);

  const queryParams = decryptTheParams();

  const [list, setList] = useState({
    batteries: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });
  const universal = useSelector((state) => state.AllBatteriesStack.arr);
  const pageNumber = useSelector((state) => state.AllBatteriesPageNumber.value);
  const rowsPerPage = useSelector(
    (state) => state.AllBatteriesRowsPerPage.value
  );

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let dragIndex = props.rowSelection
        ? fromIndex > 1 && toIndex > 1
        : fromIndex > 0 && toIndex > 0;

      if (dragIndex) {
        const newColumns = [...columns];
        const item = props.rowSelection
          ? newColumns.splice(fromIndex - 1, 1)[0]
          : newColumns.splice(fromIndex, 1)[0];
        props.rowSelection
          ? newColumns.splice(toIndex - 1, 0, item)
          : newColumns.splice(toIndex, 0, item);

        setColumns(newColumns);

        const finalColumns = [...props.columns];
        finalColumns[0]["columns"] = newColumns;

        dispatch(SetAllBatteriesColumns(finalColumns));
      }
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  const rowSelection = {
    selectedRowKeys: universal,

    onSelectAll: function (selected, selectedRows, changeRows) {
      clickedSelectAllCheckbox(selected);
    },

    onSelect: function (selected, selectedRows, changeRows) {
      if (selectedRows) {
        let uniqueChars = [...universal, selected.batteryID];
        dispatch(SetAllBatteriesStack(uniqueChars));
      } else {
        let uniqueChars = universal.filter((key) => key !== selected.batteryID);
        dispatch(SetAllBatteriesStack(uniqueChars));
      }
    },
    renderCell: (a, b, c, d) =>
      d.props.disabled ? (
        <IndeterminateCheckBoxIcon
          sx={{ fontSize: "20px", color: "#d8d8d8", cursor: "no-drop" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ) : (
        d
      ),
    getCheckboxProps: (record) => ({
      // disabled: existingBatteries.includes(record.itemID),
      // Column configuration not to be checked
    }),
  };

  function hideColumns(columns) {
    return columns.filter((item) => !item.hidden);
  }

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.column) {
      let order = sorter.order === "ascend" ? "asc" : "desc";
      dispatch(SetKPIColumnSort(sorter.columnKey));
      dispatch(SetTableOrder(order));
    }
  };

  const handlePageChange = (page, pageSize) => {
    dispatch(SetAllBatteriesPageNumber(page));
  };

  const onShowSizeChange = (current, pageSize) => {
    dispatch(SetAllBatteriesPageNumber(1));

    dispatch(SetAllBatteriesRowsPerPage(pageSize));
  };

  function clickedSelectAllCheckbox(selectAll) {
    let newtempIds = [];
    getSelectAllBatteries(
      props.severity,
      props.city,
      props.zone,
      props.volt,
      props.batterystatus,
      props.iotVendor,
      props.packVendor,

      props.batteryState,
      props.batteryID,
      props.partner,
      props.batteryType
    ).then((res) => {
      if (res.responseStatus.code === 200) {
        newtempIds = selectAll ? res.response.listOfBatteries : [];
        let tempSelected = res.response.listOfBatteries;

        let finalArr = tempSelected.filter((n) => !newtempIds.includes(n));

        let final2 = universal.filter((n) => !finalArr.includes(n));

        let final3 = final2.concat(newtempIds);
        let final4 = final3.filter((n) => !props.existingBatteries.includes(n));

        let uniqueChars = [...new Set(final4)];
        // console.log("tempIds.length === refList.current.length",uniqueChars)
        dispatch(SetAllBatteriesStack(uniqueChars));
      } else {
        if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
        dispatch(SetAllBatteriesStack([]));
      }
    });
    Events("clicked global select Advance search")
  }

  useEffect(() => {
    if (props.data && props.data.batteries.length > 0) {
      // props.data.responseStatus.code !== 1999/404
      let newData = props.data.batteries.map((row) => ({
        ...row,
        checked: false,
      }));

      setList({
        batteries: newData,
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    } else {
      setList({
        batteries: [],
        responseStatus: {
          code: props.data.responseStatus.code,
          msg: props.data.responseStatus.msg,
        },
      });
    }
  }, [props.data]);

  return (
    <>
      {list.responseStatus.code === CODE.SUCCESS ||
      list.responseStatus.code === CODE.LOADING ? (
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: ThemeProperties.purple,
            },
          }}
        >
          <ReactDragListView.DragColumn {...dragProps}>
            <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    let temp;
                    if (
                    event.target.classList[1] === "ant-table-selection-column" ||
                    event.target.classList[0] === "ant-checkbox-inner" ||
                    event.target.classList[2] === "ant-checkbox-checked" ||
                    event.target.classList[0] === "ant-checkbox-wrapper" ||
                    event.target.classList[0] === "ant-checkbox"
                    ) {
                    } else {
                      temp = {
                        ...queryParams,
                        batteryID: record.batteryID,
                        batterySearchedGlobal:"false",
                        tabValue: 0,
                        tabName: "Alerts",
                        prevLink: location.pathname+location.search
                      };
                      encryptTheParams(temp, navigate, true);
                    }
                  }, // click row
                };
              }}
              className={
                props.rowSelection
                  ? "LandingPageTable Selection"
                  : "LandingPageTable NoSelection"
              }
              locale={{
                emptyText: (
                  <span>
                    <p style={{ minHeight: "500px", color: "white" }}></p>
                  </span>
                ),
              }}
              columns={hideColumns(columns)}
              rowSelection={props.rowSelection && rowSelection}
              rowKey={(record) => record.batteryID}
              rowClassName="rowClass"
              dataSource={
                list.responseStatus.code === CODE.LOADING ? [] : list.batteries
              }
              pagination={false}
              loading={list.responseStatus.code === CODE.LOADING ? true : false}
              onChange={handleTableChange}
              style={{ height: "575px" }}
              scroll={{ y: 500 }}
              bordered
              size="middle"
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Pagination
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                pageSize={rowsPerPage}
                pageSizeOptions={[10, 20, 30, 100]}
                current={pageNumber}
                onChange={handlePageChange}
                total={props.total}
                showTotal={(total, range) => `${range[0]}-${range[1]}/${total}`}            
                />
            </div>
          </ReactDragListView.DragColumn>
        </ConfigProvider>
      ) : (
        <div style={{ height: "605px" }}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: ThemeProperties.purple,
              },
            }}
          >
            <ReactDragListView.DragColumn {...dragProps}>
              <Table
                className={
                  props.rowSelection
                    ? "LandingPageTable Selection"
                    : "LandingPageTable NoSelection"
                }
                locale={{
                  emptyText: (
                    <div style={{ minHeight: "500px", height: "500px" }}>
                      <div style={{ height: "100%" }}>
                        <NoData text={"No batteries found"} />
                      </div>
                    </div>
                  ),
                }}
                columns={hideColumns(columns)}
                rowSelection={props.rowSelection && rowSelection}
                rowKey={(record) => record.batteryID}
                rowClassName="rowClass"
                dataSource={[]}
                pagination={false}
                loading={false}
                onChange={handleTableChange}
                style={{ height: "575px" }}
                scroll={{ y: 500 }}
                bordered
                size="middle"
              />
            </ReactDragListView.DragColumn>
          </ConfigProvider>
        </div>
      )}
    </>
  );
}
