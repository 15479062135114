import { SvgIcon } from "@mui/material"
import React from "react"

const AlertFilterSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.32052 11.6319C4.07036 11.6319 3.82541 11.5664 3.59609 11.4354C3.13746 11.1678 2.86124 10.6819 2.86124 10.1359V7.24203C2.86124 6.96903 2.68925 6.55952 2.52769 6.35204L0.578502 4.18984C0.250163 3.84586 0 3.25617 0 2.8139V1.55808C0 0.684464 0.630619 0.00195312 1.43322 0.00195312H8.3127C9.10489 0.00195312 9.74593 0.673544 9.74593 1.50348V2.7047C9.74593 3.27801 9.41759 3.92776 9.1101 4.2499L6.85342 6.34112C6.63453 6.53222 6.46254 6.95265 6.46254 7.29117V9.63901C6.46254 10.125 6.17068 10.6874 5.80586 10.9167L5.08665 11.4026C4.85212 11.5555 4.58632 11.6319 4.32052 11.6319ZM1.43322 0.820967C1.0684 0.820967 0.781759 1.14311 0.781759 1.55808V2.8139C0.781759 3.01592 0.938111 3.40905 1.13616 3.61653L3.12182 5.80603C3.38762 6.15002 3.64821 6.72333 3.64821 7.23657V10.1304C3.64821 10.4853 3.88274 10.6601 3.98176 10.7147C4.20065 10.8402 4.46645 10.8402 4.66971 10.7092L5.39414 10.2232C5.54007 10.1304 5.68599 9.83558 5.68599 9.63901V7.29117C5.68599 6.70694 5.957 6.06265 6.34788 5.71867L8.5785 3.64929C8.7557 3.46365 8.96938 3.02138 8.96938 2.69924V1.50348C8.96938 1.12673 8.67752 0.820967 8.31792 0.820967H1.43322Z" fill={fill}/>
    <path d="M1.74626 5.13444C1.6733 5.13444 1.60555 5.1126 1.53779 5.07438C1.35538 4.95426 1.29805 4.69764 1.41271 4.50653L3.98209 0.19306C4.09675 0.00195677 4.33649 -0.0581043 4.5189 0.0620178C4.70131 0.18214 4.75864 0.433304 4.64398 0.624407L2.0746 4.93788C2.00164 5.06346 1.87656 5.13444 1.74626 5.13444Z" fill={fill}/>
      </svg>
    </SvgIcon>   
  )
}

AlertFilterSvg.defaultProps = {
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "#6D72F6",
  stroke: ""
}

export default AlertFilterSvg
