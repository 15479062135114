import React from "react"
import { KPIContainer } from "./Components/StyledComponent"
import { geDateRangesForCk } from "../../Helper/DatePicker/DateConverter";
import AnalyticsKPICard from "../../Features/Cards/AnalyticsKPICard";
import { getMetricInfo } from "../../Api/ChargingAnalytics";


function KPISection({ pagesContent }) {
  const calendarStartDate = geDateRangesForCk()[0];
  const calendarEndDate = geDateRangesForCk()[1];

  return (
    <KPIContainer>
      {pagesContent?.pageMeta?.availableKPIs?.map(kpiData => <AnalyticsKPICard metricName={kpiData.name} startDate={calendarStartDate} endDate={calendarEndDate} decimalPlaces={kpiData.decimalPlaces} getMetricInfo={getMetricInfo}/>)}
    </KPIContainer>
  )
}

export default KPISection
