export const SetSpecificBatteryDateDisable = (disabled) => {
  return {
    type: "SpecificBatteryDateDisable",
    disabled: disabled,
  };
};

export const SetSpecificBatteryDateInvalid = (invalid) => {
  return {
    type: "SpecificBatteryDateInvalid",
    invalid: invalid,
  };
};

export const SetSpecificBatteryCarouselDate = (date) => {
  return {
    type: "SpecificBatteryCarouselDate",
    date: date,
  };
};
