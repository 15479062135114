import React, { useState, useEffect } from "react";
import { getPagesComponents } from "../../Api/Api";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useLocation } from "react-router-dom";
import Loader from "../../Helper/Loaders/Loader";
import { SetSessionExpired } from "../../Actions";
import { useDispatch } from "react-redux";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import DigitalTwinMain from "./DigitalTwinMain";

function DigitalTwin(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = decryptTheParams();


  const [pagesContent, setPagesContent] = useState({
    batteryID: "",
    partner: "",
    tabValue: 0,
    realtimeStatus: {},
    pageHeader: "",
    datePreset: null,
    metaInfo: {
      tabs: [],
      realtimeKPI: [],
    },
    mounted: false,
  });

  useEffect(() => {
    

    setTimeout(() => {
      if(queryParams)
      {
      // queryParams &&
        // getPagesComponents("Specific Battery").then((res) => {
        //   if (res.responseStatus.code === 200) {
            const finalId =
              queryParams["batterySearchedGlobal"] === "true"
                ? queryParams["batteryIDGlobal"]
                : queryParams["batteryID"];
            const batteryDeviceSearch =
              queryParams["batterySearchedGlobal"] === "true"
                ? "Battery"
                : "Device";

            // let newRealtimeKPI = [],
            //   newItem;
            // res.response.response.metaInfo.realtimeKPI.forEach((item) => {
            //   //res.response.response.metaInfo.realtimeKPI
            //   newItem = Object.assign({}, item, {
            //     value: "...",
            //   });
            //   newRealtimeKPI.push(newItem);
            // });
            setPagesContent({
              ...pagesContent,
              batteryID: finalId,
              searchType: batteryDeviceSearch,
              partner: "partner",
              tabName: queryParams["tabName"],
              tabValue: 0,
              pageHeader: "Map", //res.response.response.textInfo,
              // datePreset: location.state.date ? location.state.date : null,
              metaInfo: {
                editFlag: false, //res.response.response.metaInfo.editFlag,
                packAssembly: {
                  assembly: [
                    {
                      name: "Current",
                      key: "current",
                      unit: "A",
                      type: "float",
                      decimalPlace: 1,
                      clickable: false
                    },
                    {
                      name: "Voltage",
                      key: "voltage",
                      unit: "V",
                      type: "float",
                      decimalPlace: 1,
                      doubleMetrics: true,
                      minKeyValue: "minVoltage",
                      maxKeyValue: "maxVoltage",
                      clickable: false
                    },
                    {
                      name: "Temperature",
                      key: "temperature",
                      unit: "°C",
                      type: "float",
                      decimalPlace: 1,
                      doubleMetrics: true,
                      minKeyValue: "minTemperature",
                      maxKeyValue: "maxTemperature",
                      clickable: true
                    },
                    {
                      name: "SOC",
                      key: "soc",
                      unit: "%",
                      type: "float",
                      decimalPlace: 1,
                      doubleMetrics: true,
                      minKeyValue: "minSOC",
                      maxKeyValue: "maxSOC",
                      clickable: true
                    },
                    {
                      name: "Capacity",
                      key: "capacity",
                      unit: "Ah",
                      type: "float",
                      decimalPlace: 1,
                      doubleMetrics: true,
                      minKeyValue: "minCapacity",
                      maxKeyValue: "maxCapacity",
                      clickable: false
                    },
                    {
                      name: "Lat/Long",
                      key: "latitude",
                      unit: "",
                      type: "string",
                      latLong: true,
                      lat: "latitude",
                      long: "longitude",
                      clickable: false
                    },
                    {
                      name: "Last Pinged",
                      key: "lastPingedTime",
                      unit: "",
                      type: "date",
                      clickable: false
                    },
                  ],
                  pack: [
                    {
                      name: "Current",
                      key: "current",
                      unit: "A",
                      type: "float",
                      decimalPlace: 1,
                      clickable: false
                    },
                    {
                      name: "Voltage",
                      key: "voltage",
                      unit: "V",
                      type: "float",
                      decimalPlace: 3,
                      clickable: false
                    },
                    {
                      name: "Temperature",
                      key: "temperature",
                      unit: "°C",
                      type: "float",
                      decimalPlace: 1,
                      clickable: true
                    },
                    {
                      name: "SOC",
                      key: "soc",
                      unit: "%",
                      type: "float",
                      decimalPlace: 1,
                      clickable: false
                    },
                    {
                      name: "Cell Imbalance",
                      key: "balanceState",
                      unit: "%",
                      type: "float",
                      decimalPlace: 3,
                      clickable: false
                    },
                    {
                      name: "Capacity",
                      key: "capacity",
                      unit: "Ah",
                      type: "float",
                      decimalPlace: 1,
                      clickable: false
                    },
                  ],
                  module: [
                    {
                      name: "Current",
                      key: "current",
                      unit: "A",
                      type: "float",
                      decimalPlace: 1,
                      clickable: false
                    },
                    {
                      name: "Voltage",
                      key: "voltage",
                      unit: "V",
                      type: "float",
                      decimalPlace: 3,
                      clickable: false
                    },
                    {
                      name: "Temperature",
                      key: "temperature",
                      unit: "°C",
                      type: "float",
                      decimalPlace: 1,
                      clickable: true
                    },
                    {
                      name: "Cell Imbalance",
                      key: "balanceState",
                      unit: "%",
                      type: "float",
                      decimalPlace: 3,
                      clickable: false
                    },
                  ],
                  cell: [
                    {
                      name: "Current",
                      key: "current",
                      unit: "A",
                      type: "float",
                      decimalPlace: 1,
                      clickable: false
                    },
                    {
                      name: "Voltage",
                      key: "voltage",
                      unit: "V",
                      type: "float",
                      decimalPlace: 3,
                      clickable: false
                    },
                    {
                      name: "Temperature",
                      key: "temperature",
                      unit: "°C",
                      type: "float",
                      decimalPlace: 1,
                      clickable: false
                    },
                  ],
                },
                charger: [
                  {
                    name: "Input Current",
                    key: "solarInputCurrent",
                    unit: "A",
                    type: "float",
                    decimalPlace: 1,
                    clickable: false
                  },
                  {
                    name: "Input Voltage",
                    key: "solarInputVoltage",
                    unit: "V",
                    type: "float",
                    decimalPlace: 3,
                    clickable: false
                  },
                  {
                    name: "Input Power",
                    key: "solarInputPower",
                    unit: "W",
                    type: "float",
                    decimalPlace: 1,
                    clickable: false
                  },
                  {
                    name: "Load On",
                    key: "loadState",
                    unit: "",
                    type: "mapping",
                    mappingType : "findLoadState",
                    clickable: false
                  },
                  {
                    name: "Charge State",
                    key: "solarChargeState",
                    unit: "",
                    type: "mapping",
                    mappingType : "findChargeState",
                    clickable: false
                  },
                  {
                    name: "Controller Brand",
                    key: "chargerBrand",
                    unit: "",
                    type: "string",
                    clickable: false
                  },
                  {
                    name: "Time of Day",
                    key: "timeOfDay",
                    unit: "",
                    type: "string",
                    clickable: false
                  },
                  {
                    name: "Last Pinged",
                    key: "lastUpdatedCustomerDBTime",
                    unit: "",
                    type: "date",
                    clickable: false
                  },
                ],
                hierarchy: [
                  {
                    name: "Pack1",
                    content: [
                      {
                        name: "M1",
                        content: ["C1", "C2"],
                      },
                      {
                        name: "M2",
                        content: ["C3", "C4"],
                      },
                      {
                        name: "M3",
                        content: ["C5", "C6"],
                      },
                      {
                        name: "M4",
                        content: ["C7", "C8"],
                      },
                    ],
                  },
                  {
                    name: "Pack2",
                    content: [
                      {
                        name: "M1",
                        content: ["C1", "C2"],
                      },
                      {
                        name: "M2",
                        content: ["C3", "C4"],
                      },
                      {
                        name: "M3",
                        content: ["C5", "C6"],
                      },
                      {
                        name: "M4",
                        content: ["C7", "C8"],
                      },
                    ],
                  },
                  {
                    name: "Pack3",
                    content: [
                      {
                        name: "M1",
                        content: ["C1", "C2"],
                      },
                      {
                        name: "M2",
                        content: ["C3", "C4"],
                      },
                      {
                        name: "M3",
                        content: ["C5", "C6"],
                      },
                      {
                        name: "M4",
                        content: ["C7", "C8"],
                      },
                    ],
                  },
                  {
                    name: "Pack4",
                    content: [
                      {
                        name: "M1",
                        content: ["C1", "C2"],
                      },
                      {
                        name: "M2",
                        content: ["C3", "C4"],
                      },
                      {
                        name: "M3",
                        content: ["C5", "C6"],
                      },
                      {
                        name: "M4",
                        content: ["C7", "C8"],
                      },
                    ],
                  },
                  {
                    name: "Pack5",
                    content: [
                      {
                        name: "M1",
                        content: ["C1", "C2"],
                      },
                      {
                        name: "M2",
                        content: ["C3", "C4"],
                      },
                      {
                        name: "M3",
                        content: ["C5", "C6"],
                      },
                      {
                        name: "M4",
                        content: ["C7", "C8"],
                      },
                    ],
                  },
                  {
                    name: "Pack6",
                    content: [
                      {
                        name: "M1",
                        content: ["C1", "C2"],
                      },
                      {
                        name: "M2",
                        content: ["C3", "C4"],
                      },
                      {
                        name: "M3",
                        content: ["C5", "C6"],
                      },
                      {
                        name: "M4",
                        content: ["C7", "C8"],
                      },
                    ],
                  },
                ],
                tabs: [
                  {
                    "id": 0,
                    "name": "Alerts",
                    "color": "#000",
                    "components": [
                      "Alerts Distribution",
                      "Alerts' Timeline"
                    ]
                  },
                  {
                    "id": 1,
                    "name": "Battery Vitals",
                    "color": "#000",
                    "components": [
                      "Battery Pack Metrics",
                      "Battery Status"
                    ]
                  },
                  {
                    "id": 2,
                    "name": "Charger Vitals",
                    "color": "#000",
                    "components": [
                      
                    ]
                  },
                  // {
                  //   "id": 3,
                  //   "name": "Warranty Claims",
                  //   "color": "#000",
                  //   "components": [
                  //     "SOH and Equivalent Cycles"
                  //   ]
                  // }
                ],
                filters: {
                  granularity: {
                    itemName: "Granularity",
                    items: [
                      {
                        key: "Raw Data",
                        value: "Raw Data",
                      },
                      {
                        key: "Daily",
                        value: "Daily",
                      },
                      {
                        key: "Weekly",
                        value: "Weekly",
                      },
                      {
                        key: "Monthly",
                        value: "Monthly",
                      },
                    ],
                  },
                },
              },
              mounted: true,
            });
        //   } else if (res.responseStatus.code === -2) {
        //     dispatch(SetSessionExpired(true));
        //   }
        // });
      }
    }, 2);

    return () => {
      setPagesContent({
        ...pagesContent,
        batteryID: "",
        partner: "",
        tabValue: 0,
        pageHeader: "",
        datePreset: null,
        metaInfo: {
          tabs: [],
          realtimeKPI: [],
          packAssembly: [],
        },
        mounted: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams?.["batteryIDGlobal"]]);

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <DigitalTwinMain
            batteryID={pagesContent.batteryID}
            searchType={pagesContent.searchType}
            tabValue={pagesContent.tabValue}
            tabName={pagesContent.tabName}
            pagesContent={pagesContent}
          />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}

export default DigitalTwin;
