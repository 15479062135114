import { SvgIcon } from "@mui/material"
import React from "react"

const NavbarMapIcon = (props) => {
  const { height, width, viewBox, fill, stroke, style, ...other } = props
  return (
    <SvgIcon
      style={style}
    >
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
      >
        <path d="M11.7373 16.0575C11.4148 16.0575 11.0923 15.99 10.8298 15.8625L6.89228 13.89C6.66728 13.7775 6.22479 13.785 6.00729 13.9125L4.23729 14.925C3.47229 15.36 2.68479 15.42 2.09229 15.0675C1.49229 14.7225 1.15479 14.0175 1.15479 13.1325V5.8425C1.15479 5.16 1.60479 4.3875 2.19729 4.05L5.44479 2.19C5.99229 1.875 6.82479 1.8525 7.38729 2.1375L11.3248 4.11C11.5498 4.2225 11.9848 4.2075 12.2098 4.0875L13.9723 3.0825C14.7373 2.6475 15.5248 2.5875 16.1173 2.94C16.7173 3.285 17.0548 3.99 17.0548 4.875V12.1725C17.0548 12.855 16.6048 13.6275 16.0123 13.965L12.7648 15.825C12.4798 15.975 12.1048 16.0575 11.7373 16.0575ZM6.47979 12.69C6.80229 12.69 7.12479 12.7575 7.38729 12.885L11.3248 14.8575C11.5498 14.97 11.9848 14.955 12.2098 14.835L15.4573 12.975C15.6973 12.84 15.9298 12.435 15.9298 12.165V4.8675C15.9298 4.395 15.7948 4.0425 15.5473 3.9075C15.3073 3.7725 14.9323 3.825 14.5273 4.0575L12.7648 5.0625C12.2173 5.3775 11.3848 5.4 10.8223 5.115L6.88479 3.1425C6.65979 3.03 6.22479 3.045 5.99979 3.165L2.75229 5.025C2.51229 5.16 2.27979 5.565 2.27979 5.8425V13.14C2.27979 13.6125 2.41479 13.965 2.65479 14.1C2.89479 14.2425 3.26979 14.1825 3.68229 13.95L5.44479 12.945C5.73729 12.7725 6.11229 12.69 6.47979 12.69Z" fill={fill} />
        <path d="M6.41992 13.3125C6.11242 13.3125 5.85742 13.0575 5.85742 12.75V3C5.85742 2.6925 6.11242 2.4375 6.41992 2.4375C6.72742 2.4375 6.98242 2.6925 6.98242 3V12.75C6.98242 13.0575 6.72742 13.3125 6.41992 13.3125Z" fill={fill} />
        <path d="M11.7974 15.5625C11.4899 15.5625 11.2349 15.3075 11.2349 15V4.965C11.2349 4.6575 11.4899 4.4025 11.7974 4.4025C12.1049 4.4025 12.3599 4.6575 12.3599 4.965V15C12.3599 15.3075 12.1049 15.5625 11.7974 15.5625Z" fill={fill} />
      </svg>
    </SvgIcon >

  )
}

NavbarMapIcon.defaultProps = {
  width: "18",
  height: "18",
  viewBox: "0 0 18 18",
  fill: "#8D9FB2",
  stroke: ""
}

export default NavbarMapIcon
