import { SvgIcon } from "@mui/material"
import React from "react"

const ChargingFrequencySvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_340_9270)">
        <path d="M6 0.390625L6 4.6875L0.374999 4.6875C0.168749 4.6875 -8.80204e-07 4.86328 -8.70813e-07 5.07813C-8.61422e-07 5.29297 0.168749 5.46875 0.374999 5.46875L6 5.46875L6 12.1094L0.374999 12.1094C0.168749 12.1094 -5.55783e-07 12.2852 -5.46392e-07 12.5C-5.37001e-07 12.7148 0.168749 12.8906 0.375 12.8906L6 12.8906L6 19.5312L0.375 19.5312C0.16875 19.5312 -2.31363e-07 19.707 -2.21972e-07 19.9219C-2.12581e-07 20.1367 0.16875 20.3125 0.375 20.3125L6 20.3125L6 24.6094C6 24.8242 6.16875 25 6.375 25C6.58125 25 6.75 24.8242 6.75 24.6094L6.75 20.3125L23.625 20.3125C23.8313 20.3125 24 20.1367 24 19.9219C24 19.707 23.8313 19.5312 23.625 19.5312L6.75 19.5312L6.75 12.8906L23.625 12.8906C23.8313 12.8906 24 12.7148 24 12.5C24 12.2852 23.8313 12.1094 23.625 12.1094L6.75 12.1094L6.75 5.46875L23.625 5.46875C23.8313 5.46875 24 5.29297 24 5.07813C24 4.86328 23.8313 4.6875 23.625 4.6875L6.75 4.6875L6.75 0.390625C6.75 0.175781 6.58125 -2.87676e-07 6.375 -2.7866e-07C6.16875 -2.69645e-07 6 0.175781 6 0.390625Z" fill="#444B51"/>
        <path d="M7.87498 22.6564C7.87498 23.301 8.38123 23.8283 8.99998 23.8283C9.61873 23.8283 10.125 23.301 10.125 22.6564C10.125 22.0119 9.61873 21.4846 8.99998 21.4846C8.38123 21.4846 7.87498 22.0119 7.87498 22.6564ZM9.37498 22.6564C9.37498 22.8713 9.20623 23.0471 8.99998 23.0471C8.79373 23.0471 8.62498 22.8713 8.62498 22.6564C8.62498 22.4416 8.79373 22.2658 8.99998 22.2658C9.20623 22.2658 9.37498 22.4416 9.37498 22.6564Z" fill="#444B51"/>
        <path d="M7.87498 16.211C7.87498 16.8555 8.38123 17.3829 8.99998 17.3829C9.61873 17.3829 10.125 16.8555 10.125 16.211C10.125 15.5665 9.61873 15.0391 8.99998 15.0391C8.38123 15.0391 7.87498 15.5665 7.87498 16.211ZM9.37498 16.211C9.37498 16.4258 9.20623 16.6016 8.99998 16.6016C8.79373 16.6016 8.62498 16.4258 8.62498 16.211C8.62498 15.9962 8.79373 15.8204 8.99998 15.8204C9.20623 15.8204 9.37498 15.9962 9.37498 16.211Z" fill="#444B51"/>
        <path d="M11.25 16.211C11.25 16.8555 11.7563 17.3829 12.375 17.3829C12.9938 17.3829 13.5 16.8555 13.5 16.211C13.5 15.5665 12.9937 15.0391 12.375 15.0391C11.7562 15.0391 11.25 15.5665 11.25 16.211ZM12.75 16.211C12.75 16.4258 12.5813 16.6016 12.375 16.6016C12.1688 16.6016 12 16.4258 12 16.211C12 15.9962 12.1687 15.8204 12.375 15.8204C12.5813 15.8204 12.75 15.9962 12.75 16.211Z" fill="#444B51"/>
        <path d="M7.87498 8.78922C7.87498 9.43375 8.38123 9.96109 8.99998 9.96109C9.61873 9.96109 10.125 9.43375 10.125 8.78921C10.125 8.14468 9.61873 7.61734 8.99998 7.61734C8.38123 7.61734 7.87498 8.14468 7.87498 8.78922ZM9.37498 8.78922C9.37498 9.00406 9.20623 9.17984 8.99998 9.17984C8.79373 9.17984 8.62498 9.00406 8.62498 8.78922C8.62498 8.57437 8.79373 8.39859 8.99998 8.39859C9.20623 8.39859 9.37498 8.57437 9.37498 8.78922Z" fill="#444B51"/>
        <path d="M11.25 8.78922C11.25 9.43375 11.7563 9.96109 12.375 9.96109C12.9938 9.96109 13.5 9.43375 13.5 8.78921C13.5 8.14468 12.9937 7.61734 12.375 7.61734C11.7562 7.61734 11.25 8.14468 11.25 8.78922ZM12.75 8.78922C12.75 9.00406 12.5813 9.17984 12.375 9.17984C12.1688 9.17984 12 9.00406 12 8.78922C12 8.57437 12.1687 8.39859 12.375 8.39859C12.5813 8.39859 12.75 8.57437 12.75 8.78922Z" fill="#444B51"/>
        <path d="M14.625 8.78922C14.625 9.43375 15.1313 9.96109 15.75 9.96109C16.3688 9.96109 16.875 9.43375 16.875 8.78921C16.875 8.14468 16.3688 7.61734 15.75 7.61734C15.1313 7.61734 14.625 8.14468 14.625 8.78922ZM16.125 8.78922C16.125 9.00406 15.9563 9.17984 15.75 9.17984C15.5438 9.17984 15.375 9.00406 15.375 8.78922C15.375 8.57437 15.5438 8.39859 15.75 8.39859C15.9563 8.39859 16.125 8.57437 16.125 8.78922Z" fill="#444B51"/>
        <path d="M7.87498 2.34375C7.87498 2.98828 8.38123 3.51562 8.99998 3.51562C9.61873 3.51562 10.125 2.98828 10.125 2.34375C10.125 1.69922 9.61873 1.17187 8.99998 1.17187C8.38123 1.17187 7.87498 1.69922 7.87498 2.34375ZM9.37498 2.34375C9.37498 2.55859 9.20623 2.73437 8.99998 2.73437C8.79373 2.73437 8.62498 2.55859 8.62498 2.34375C8.62498 2.12891 8.79373 1.95312 8.99998 1.95312C9.20623 1.95312 9.37498 2.12891 9.37498 2.34375Z" fill="#444B51"/>
        <path d="M11.25 2.34375C11.25 2.98828 11.7563 3.51562 12.375 3.51562C12.9938 3.51562 13.5 2.98828 13.5 2.34375C13.5 1.69922 12.9937 1.17187 12.375 1.17187C11.7562 1.17187 11.25 1.69922 11.25 2.34375ZM12.75 2.34375C12.75 2.55859 12.5813 2.73437 12.375 2.73437C12.1688 2.73437 12 2.55859 12 2.34375C12 2.12891 12.1687 1.95312 12.375 1.95312C12.5813 1.95312 12.75 2.12891 12.75 2.34375Z" fill="#444B51"/>
        <path d="M14.625 2.34375C14.625 2.98828 15.1313 3.51562 15.75 3.51562C16.3688 3.51562 16.875 2.98828 16.875 2.34375C16.875 1.69922 16.3688 1.17187 15.75 1.17187C15.1313 1.17187 14.625 1.69922 14.625 2.34375ZM16.125 2.34375C16.125 2.55859 15.9563 2.73437 15.75 2.73437C15.5438 2.73437 15.375 2.55859 15.375 2.34375C15.375 2.12891 15.5438 1.95312 15.75 1.95312C15.9563 1.95312 16.125 2.12891 16.125 2.34375Z" fill="#444B51"/>
        <path d="M18 2.34375C18 2.98828 18.5062 3.51562 19.125 3.51562C19.7437 3.51562 20.25 2.98828 20.25 2.34375C20.25 1.69922 19.7437 1.17187 19.125 1.17187C18.5062 1.17187 18 1.69922 18 2.34375ZM19.5 2.34375C19.5 2.55859 19.3312 2.73437 19.125 2.73437C18.9187 2.73437 18.75 2.55859 18.75 2.34375C18.75 2.12891 18.9187 1.95312 19.125 1.95312C19.3312 1.95312 19.5 2.12891 19.5 2.34375Z" fill="#444B51"/>
        </g>
        <defs>
        <clipPath id="clip0_340_9270">
        <rect width="25" height="30" fill="white" transform="translate(0 25) rotate(-90)"/>
        </clipPath>
        </defs>
      </svg>

    </SvgIcon>
  )
}

ChargingFrequencySvg.defaultProps = {
  width: "30",
  height: "25",
  viewBox: "0 0 30 25",
  fill: "none",
  stroke: ""
}

export default ChargingFrequencySvg
