import * as React from "react";
const BatteryChargingStateIcon = (props) => {
  const { height, width, viewBox, fill, ...other } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <circle cx={29} cy={29} r={3} fill={fill} />
      <circle cx={16} cy={16} r={11} stroke="#586CE9" />
      <rect
        x={12.7002}
        y={10.5}
        width={6.38}
        height={4.73}
        rx={0.3}
        fill={"#586CE9"}
      />
      <path
        d="M17.0996 10.5V8.85C17.0996 8.54624 17.3459 8.3 17.6496 8.3C17.9534 8.3 18.1996 8.54624 18.1996 8.85V10.5H17.0996Z"
        fill="#586CE9"
      />
      <path
        d="M13.7998 10.5V8.85C13.7998 8.54624 14.046 8.3 14.3498 8.3C14.6536 8.3 14.8998 8.54624 14.8998 8.85V10.5H13.7998Z"
        fill="#586CE9"
      />
      <path
        d="M16.5498 14.9004V22.6004C16.5498 22.9041 16.3036 23.1504 15.9998 23.1504C15.696 23.1504 15.4498 22.9041 15.4498 22.6004V14.9004H16.5498Z"
        fill="#586CE9"
      />
      <path
        d="M17.1004 11.5996H15.7385L14.9004 13.5157H15.529L14.9004 14.8996L16.7861 12.9835H16.0528L17.1004 11.5996Z"
        fill="white"
      />
    </svg>
  )
}
BatteryChargingStateIcon.defaultProps = {
  width: "34",
  height: "34",
  viewBox: "0 0 34 34",
  fill: "#3CA967"
};
export default BatteryChargingStateIcon;
