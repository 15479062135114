import React, { useEffect } from "react";
import useState from "react-usestateref";

import BasicTabs from "../../Features/Tabs/BasicTabs";
import BasicTabPanel from "../../Features/Tabs/BasicTabPanel";
import { PaperWithBottomRadius } from "../../Components/CustomPaper";

import ScrollToTheElement from "../../Helper/Operations/ScrollToTheElement";

import { Box, Typography } from "@mui/material";
import { getBatteryWarrantyDetails } from "../../Api/WarrantyApi";
import { useDispatch } from "react-redux";
import { SetSessionExpired } from "../../Actions";
import { groupByKey } from "../../Helper/Operations/GroupByKey";
import CODE from "../../Static/Constants/StatusCodes";
import BasicTableNoPagination from "../../Features/Table/AntDesign/BasicTableNoPagination";
import { getColumnTitleSort } from "../../Features/Table/AntDesign/TableFunctions";
import moment from "moment";

function WarrantyTabs(props) {
  const dispatch = useDispatch();
  const tabs = [
    {
      id: 0,
      name: "Battery",
      color: "#000",
      components: ["Alerts Distribution", "Alerts' Timeline"],
    },
    {
      id: 1,
      name: "IOT",
      color: "#000",
      components: ["Battery Pack Metrics", "Battery Status"],
    },
    {
      id: 2,
      name: "BMS",
      color: "#000",
      components: ["SOH and Equivalent Cycles"],
    },
  ];

  const [batteryWarranty, setBatteryWarranty] = useState({
    Battery: {
      data: [],
      responseStatus: { code: -1, msg: "" },
    },
    IOT: {
      data: [],
      responseStatus: { code: -1, msg: "" },
    },
    BMS: {
      data: [],
      responseStatus: { code: -1, msg: "" },
    },
  });

  const [tabvalue, setTabValue, refTabValue] = useState(0);

  const ChangeTheTab = (newValue) => {
    let tabValue;
    tabValue = tabs.find((o) => o.name === newValue).id;
    setTabValue(tabValue);
  };

  useEffect(() => {
    setBatteryWarranty({
      Battery: {
        data: [],
        responseStatus: { code: -1, msg: "" },
      },
      IOT: {
        data: [],
        responseStatus: { code: -1, msg: "" },
      },
      BMS: {
        data: [],
        responseStatus: { code: -1, msg: "" },
      },
    });

    props.batteryID &&
      getBatteryWarrantyDetails(props.batteryID).then((res) => {
        if (res.responseStatus.code === 200) {
          let gy = groupByKey(res.response.warrantyData, "warrantyType");

          if (gy.find((item) => item.key === "Battery"))
            setBatteryWarranty((prevData) => ({
              ...prevData,
              Battery: {
                data: gy.find((item) => item.key === "Battery")["values"],
                responseStatus: {
                  code: res.responseStatus.code,
                  msg: res.responseStatus.msg,
                },
              },
            }));
          else
            setBatteryWarranty((prevData) => ({
              ...prevData,
              Battery: {
                data: [],
                responseStatus: {
                  code: CODE.NODATA,
                  msg: "No Data Found",
                },
              },
            }));

          if (gy.find((item) => item.key === "IOT"))
            setBatteryWarranty((prevData) => ({
              ...prevData,
              IOT: {
                data: gy.find((item) => item.key === "IOT")["values"],
                responseStatus: {
                  code: res.responseStatus.code,
                  msg: res.responseStatus.msg,
                },
              },
            }));
          else
            setBatteryWarranty((prevData) => ({
              ...prevData,
              IOT: {
                data: [],
                responseStatus: {
                  code: CODE.NODATA,
                  msg: "No Data Found",
                },
              },
            }));

          if (gy.find((item) => item.key === "BMS"))
            setBatteryWarranty((prevData) => ({
              ...prevData,
              BMS: {
                data: gy.find((item) => item.key === "BMS")["values"],
                responseStatus: {
                  code: res.responseStatus.code,
                  msg: res.responseStatus.msg,
                },
              },
            }));
          else
            setBatteryWarranty((prevData) => ({
              ...prevData,
              BMS: {
                data: [],
                responseStatus: {
                  code: CODE.NODATA,
                  msg: "No Data Found",
                },
              },
            }));
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          setBatteryWarranty({
            Battery: {
              data: [],
              responseStatus: { code: CODE.NODATA, msg: "No data found" },
            },
            IOT: {
              data: [],
              responseStatus: { code: CODE.NODATA, msg: "No data found" },
            },
            BMS: {
              data: [],
              responseStatus: { code: CODE.NODATA, msg: "No data found" },
            },
          });
        }
      });
  }, [props.batteryID]);

  function SwitchTheTab(tabvalue) {
    switch (tabvalue) {
      case "Battery":
        ScrollToTheElement();
        return (
          <>
            <Box sx={{ mt: 2 }}></Box>
            <Box sx={{ height: "400px" }}>
            <BasicTableNoPagination
              data={batteryWarranty.Battery}
              columns={[
                {
                  title: (
                    <Typography variant="tableHeadBold">
                      Warranty Condition
                    </Typography>
                  ),
                  name: "warrantyCondition",
                  dataIndex: "warrantyCondition",
                  key: "warrantyCondition",
                  sorter: false,
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "warrantyConditionActive",
                  render: (warrantyCondition) => (
                    <Typography variant="tableRow">
                      {warrantyCondition}
                    </Typography>
                  ),
                },
                {
                  title: (titleProps) =>
                    getColumnTitleSort(
                      titleProps,
                      "onboardingDate",
                      "Onboarding Date"
                    ),
                  name: "onboardingDate",
                  dataIndex: "onboardingDate",
                  key: "onboardingDate",
                  sorter: true,
                  sortDirections: ["ascend", "descend", "ascend"],
                  defaultSortOrder: "descend",
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "onboardingDate",
                  render: (onboardingDate) => (
                    <Typography variant="tableRow">
                      {moment(new Date(onboardingDate)).format("DD MMM'YY")}
                    </Typography>
                  ),
                },
                {
                  title: (titleProps) =>
                    getColumnTitleSort(titleProps, "age", "Age (months)"),
                  name: "age",
                  dataIndex: "age",
                  key: "age",
                  sorter: true,
                  sortDirections: ["ascend", "descend", "ascend"],
                  defaultSortOrder: "",
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 130,
                  uniqueKey: "age",
                  render: (age) => (
                    <Typography variant="tableRow">
                      {age ? Math.round(age / 30) : ""}
                    </Typography>
                  ),
                },
                {
                  title: (titleProps) =>
                    getColumnTitleSort(titleProps, "cycles", "Cycle"),
                  name: "cycles",
                  dataIndex: "cycles",
                  key: "cycles",
                  sorter: true,
                  sortDirections: ["ascend", "descend", "ascend"],
                  defaultSortOrder: "",
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 130,
                  uniqueKey: "cycles",
                  render: (cycles) => (
                    <Typography variant="tableRow">
                      {cycles ? Math.round(cycles) : ""}
                    </Typography>
                  ),
                },
                {
                  title: (titleProps) =>
                    getColumnTitleSort(titleProps, "soh", "SOH (%)"),
                  name: "soh",
                  dataIndex: "soh",
                  key: "soh",
                  sorter: true,
                  sortDirections: ["ascend", "descend", "ascend"],
                  defaultSortOrder: "",
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 130,
                  uniqueKey: "soh",
                  render: (soh) => (
                    <Typography variant="tableRow">{soh}</Typography>
                  ),
                },
                {
                  title: (
                    <Typography variant="tableHeadBold">
                      Accuracy (%)
                    </Typography>
                  ),
                  name: "accuracy",
                  dataIndex: "warrantyInfo",
                  key: "warrantyInfo",
                  sorter: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "accuracy",
                  render: (warrantyInfo) => (
                    <Typography variant="tableRow">
                      {warrantyInfo?.Accuracy}
                    </Typography>
                  ),
                },
              ]}
              noDataMsg={"No warranty claims found"}
              dateString={["", ""]}
              setBatteryConfigList={""}
              changeTheDate={""}
              ChangeTheTab={""}
              openNotification={""}
              onClickColumn={""}
              height={340}
              pagePerSize={8}
              id="CannotBeClicked"
            />
            </Box>
          </>
        );
      case "IOT":
        ScrollToTheElement();
        return (
          <>
            <Box sx={{ mt: 2 }}></Box>
            <Box sx={{ height: "400px" }}>
            <BasicTableNoPagination
              data={batteryWarranty.IOT}
              columns={[
                {
                  title: (
                    <Typography variant="tableHeadBold">
                      Warranty Condition
                    </Typography>
                  ),
                  name: "Battery ID",
                  dataIndex: "warrantyCondition",
                  key: "warrantyCondition",
                  fixed: "left",
                  sorter: false,
                  uniqueKey: "warrantyCondition",
                  filterSearch: false,
                  width: 200,
                  className: "undragableColumn",
                  // maxWidth:"18px",
                  render: (warrantyCondition) => (
                    <div className="renderID">{warrantyCondition}</div>
                  ),
                },
                {
                  title: (titleProps) =>
                    getColumnTitleSort(
                      titleProps,
                      "onboardingDate",
                      "Onboarding Date"
                    ),
                  name: "onboardingDate",
                  dataIndex: "onboardingDate",
                  key: "onboardingDate",
                  sorter: true,
                  sortDirections: ["ascend", "descend", "ascend"],
                  defaultSortOrder: "descend",
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "onboardingDate",
                  render: (onboardingDate) => (
                    <Typography variant="tableRow">
                      {moment(new Date(onboardingDate)).format("DD MMM'YY")}
                    </Typography>
                  ),
                },
                {
                  title: (
                    <Typography variant="tableHeadBold">Last Pinged</Typography>
                  ),
                  name: "lastPinged",
                  dataIndex: "lastPinged",
                  key: "lastPinged",
                  sorter: false,
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "lastPinged",
                  render: (warrantyInfo) => (
                    <Typography variant="tableRow">
                      {warrantyInfo?.lastPinged}
                    </Typography>
                  ),
                },
                {
                  title: (
                    <Typography variant="tableHeadBold">
                      Avg ping frequency
                    </Typography>
                  ),
                  name: "pingsPerDay",
                  dataIndex: "warrantyInfo",
                  key: "warrantyInfo",
                  sorter: false,
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "warrantyInfoPings",
                  render: (warrantyInfo) => (
                    <Typography variant="tableRow">
                      {warrantyInfo?.pingsPerday}
                    </Typography>
                  ),
                },
                {
                  title: (
                    <Typography variant="tableHeadBold">
                      Correct Ping (%)
                    </Typography>
                  ),
                  name: "correctPingFraction",
                  dataIndex: "warrantyInfo",
                  key: "warrantyInfo",
                  sorter: false,
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "warrantyInfoPings",
                  render: (warrantyInfo) => (
                    <Typography variant="tableRow">
                      {warrantyInfo?.correctPingFraction}
                    </Typography>
                  ),
                },
              ]}
              noDataMsg={"No warranty claims found"}
              dateString={["", ""]}
              setBatteryConfigList={""}
              changeTheDate={""}
              ChangeTheTab={""}
              openNotification={""}
              onClickColumn={""}
              height={340}
              pagePerSize={8}
              id="CannotBeClicked"
            />
            </Box>
          </>
        );
      case "BMS":
        ScrollToTheElement();
        return (
          <>
            <Box sx={{ mt: 2 }}></Box>
            <Box sx={{ height: "400px" }}>
            <BasicTableNoPagination
              data={batteryWarranty.BMS}
              columns={[
                {
                  title: (
                    <Typography variant="tableHeadBold">
                      Warranty Condition
                    </Typography>
                  ),
                  name: "Battery ID",
                  dataIndex: "warrantyCondition",
                  key: "warrantyCondition",
                  fixed: "left",
                  sorter: false,
                  uniqueKey: "warrantyCondition",
                  filterSearch: false,
                  width: 200,
                  className: "undragableColumn",
                  // maxWidth:"18px",
                  render: (warrantyCondition) => (
                    <div className="renderID">{warrantyCondition}</div>
                  ),
                },
                {
                  title: (titleProps) =>
                    getColumnTitleSort(
                      titleProps,
                      "onboardingDate",
                      "Onboarding Date"
                    ),
                  name: "onboardingDate",
                  dataIndex: "onboardingDate",
                  key: "onboardingDate",
                  sorter: true,
                  sortDirections: ["ascend", "descend", "ascend"],
                  defaultSortOrder: "descend",
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "onboardingDate",
                  render: (onboardingDate) => (
                    <Typography variant="tableRow">
                      {moment(new Date(onboardingDate)).format("DD MMM'YY")}
                    </Typography>
                  ),
                },
                {
                  title: (
                    <Typography variant="tableHeadBold">
                      Highest Volt Recorded
                    </Typography>
                  ),
                  name: "maxPackVolt",
                  dataIndex: "warrantyInfo",
                  key: "warrantyInfo",
                  sorter: false,
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "warrantyInfoPings",
                  render: (warrantyInfo) => (
                    <Typography variant="tableRow">
                      {warrantyInfo?.maxPackVolt}
                    </Typography>
                  ),
                },
                {
                  title: (
                    <Typography variant="tableHeadBold">
                      Volt Stabilisation Time
                    </Typography>
                  ),
                  name: "breachStableTime",
                  dataIndex: "warrantyInfo",
                  key: "warrantyInfo",
                  sorter: false,
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "warrantyInfoPings",
                  render: (warrantyInfo) => (
                    <Typography variant="tableRow">
                      {warrantyInfo?.breachStableTime}
                    </Typography>
                  ),
                },
                {
                  title: (
                    <Typography variant="tableHeadBold">
                      Highest Temp Recorded
                    </Typography>
                  ),
                  name: "maxPackTemp",
                  dataIndex: "warrantyInfo",
                  key: "warrantyInfo",
                  sorter: false,
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "warrantyInfoPings",
                  render: (warrantyInfo) => (
                    <Typography variant="tableRow">
                      {warrantyInfo?.maxPackTemp}
                    </Typography>
                  ),
                },
                {
                  title: (
                    <Typography variant="tableHeadBold">
                      Temp Stabilisation Time
                    </Typography>
                  ),
                  name: "breachStableTime",
                  dataIndex: "warrantyInfo",
                  key: "warrantyInfo",
                  sorter: false,
                  showSorterTooltip: false,
                  checkedOptions: [],
                  width: 160,
                  uniqueKey: "warrantyInfoPings",
                  render: (warrantyInfo) => (
                    <Typography variant="tableRow">
                      {warrantyInfo?.breachStableTime}
                    </Typography>
                  ),
                },
              ]}
              noDataMsg={"No warranty claims found"}
              dateString={["", ""]}
              setBatteryConfigList={""}
              changeTheDate={""}
              ChangeTheTab={""}
              openNotification={""}
              onClickColumn={""}
              height={340}
              pagePerSize={8}
              id="CannotBeClicked"
            />
            </Box>
          </>
        );
    }
  }

  return (
      <Box>
        <BasicTabs
          ChangeTheTab={ChangeTheTab}
          tabs={tabs}
          tabValue={refTabValue}
        />

        <BasicTabPanel value={tabvalue} index={tabvalue} key={tabvalue}>
          <PaperWithBottomRadius
            elevation={0}
            sx={{ border: "1px solid #ececec" }}
          >
            <>{SwitchTheTab(tabs.find((o) => o.id === tabvalue).name)}</>
          </PaperWithBottomRadius>
        </BasicTabPanel>
      </Box>
  );
}

export default WarrantyTabs;
