import React, { useState, useEffect } from "react";
import CODE from "../../../../Static/Constants/StatusCodes";
import { Brightness1 } from "@mui/icons-material";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { colors, metrics } from "./_Constants";
import TimeSeriesSingleAxis from "../../../../Charts/Line/TimeSeriesSingleAxis";
import {
  getGranularityData,
  getPackComparision,
} from "../../../../Api/DigitalTwin";
import {
  convertToZoneDate,
  convertUTCtoZone,
  getChartTimeStamp,
} from "../../../../Helper/DatePicker/DateConverter";
import { Tooltip } from "antd";
import { PaperHighlight } from "../../../../Components/CustomPaper";
import Header from "../../../../Features/Header/Header";
import Events from "../../../../Analytics/Events";
import {
  formatDateWithoutYear,
  monthDictionary,
} from "../../../../Helper/DatePicker/DateFormatters";

const radioSize = {
  "& .MuiSvgIcon-root": {
    fontSize: 16,
  },
};

function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}

function countTrueProperties(obj) {
  let count = 0;
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key].isActive === true) {
      count++;
    }
  }
  return count;
}

function ComparisionChart(props) {
  const [dynamicObjects, setDynamicObjects] = useState({});
  const [finalObject, setFinalObject] = useState({
    data: [],
    responseStatus: {
      code: -1,
      msg: "No data",
    },
  });

  const [dynamicObjectsCSV, setDynamicObjectsCSV] = useState({});
  const [finalObjectCSV, setFinalObjectCSV] = useState({
    data: [],
    responseStatus: {
      code: 0,
    },
  });

  const [value, setValue] = React.useState("current");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const updateActiveState = (pack, state) => {
    setFinalObject((prev) => ({
      ...prev,
      responseStatus: {
        code: -1,
      },
    }));

    const newData = finalObject.data;

    let allActive = Object.values(newData).every(
      (value) => value.isActive === true
    );

    let oneActive = countTrueProperties(newData);

    if (allActive) {
      Object.keys(newData).forEach((item) => {
        if (newData[item]["name"] === pack) {
          newData[item]["isActive"] = true;
        } else {
          newData[item]["isActive"] = false;
        }
      });
    } else if (oneActive === 1) {
      let activeKey = Object.keys(newData).find(
        (key) => newData[key]["isActive"] === true
      );
      if (activeKey === pack) {
      } else {
        newData[pack]["isActive"] = !newData[pack]["isActive"];
      }
    } else {
      newData[pack]["isActive"] = !newData[pack]["isActive"];
    }

    setTimeout(() => {
      setFinalObject((prev) => ({
        ...prev,
        data: newData,
        responseStatus: {
          code: 200,
        },
      }));
    }, 0.1);
  };

  const convertStateToSeries = () => {
    let seriesObj = [],
      colors = [],
      finalObj = {};

    Object.values(finalObject.data).map((item, index) => {
      if (item["isActive"]) {
        const unit = metrics.find((item) => item.value === value)["unit"];
        seriesObj.push({
          name: item["name"].toUpperCase() + " (" + unit + ")",
          data: item["isPresent"] ? item["data"]["metric"] : 0,
        });
        let tempColor = item["isPresent"] ? item["color"] : "#ececec";
        colors.push(tempColor);
      }
    });
    let tempSeriesObj = [];
    seriesObj.map((item) => {
      if (item.data === 0) {
        tempSeriesObj.push({
          name: item.name,
          data: [],
        });
      } else {
        tempSeriesObj.push({
          name: item.name,
          data: item.data,
        });
      }
    });

    finalObj = {
      series: tempSeriesObj,
      colors: colors,
    };

    return finalObj;
  };

  function GetComparisionCSV() {
    setFinalObjectCSV({
      data: [],
      responseStatus: {
        code: -1,
      },
    });

    let arr = [];
    props.hierarchy.packHierarchy.forEach((item) => arr.push(item.value));

    const objects = {};
    arr.forEach((item, index) => {
      let pitem = finalObject?.data[item];
      let isActive = pitem?.isActive ?? true;
      objects[item] = {
        data: {
          metric: [],
          xaxis: [],
        },
        name: item,
        key: props.batteryID + "_" + item,
        color: colors[index],
        isActive: isActive,
        responseStatus: {
          code: -1,
          msg: "",
        },
      };
    });
    setDynamicObjectsCSV(objects);

    if (
      props.granular === "Raw Data" &&
      props.startDate.toLowerCase().indexOf(":") === -1 &&
      value !== "energy"
    ) {
      Object.keys(objects).forEach((item) => {
        getPackComparision(
          props.batteryID,
          item.toUpperCase(),
          props.hierarchy.selectedModule.value,
          props.hierarchy.selectedCell.value,
          "pack",
          value,
          [props.dateString[0], props.dateString[1]]
        ).then((res) => {
          if (res?.responseStatus?.code === CODE.SUCCESS) {
            let tempData = [];
            res?.response?.batteryData?.map((item) => {
              tempData.push({
                x: getChartTimeStamp(item.batteryDateTime, true),
                y: item["metricsArray"][0]["value"],
              });
            });
            setDynamicObjectsCSV((prev) => ({
              ...prev,

              [item]: {
                ...prev[item],
                data: {
                  ...prev[item]["data"],
                  metric: tempData,
                },
                responseStatus: {
                  code: CODE.SUCCESS,
                  msg: res?.responseStatus?.msg,
                },
              },
            }));
          } else {
            setDynamicObjectsCSV((prev) => ({
              ...prev,
              [item]: {
                ...prev[item],
                data: {
                  ...prev[item]["data"],
                  metric: [],
                },
                responseStatus: {
                  code: CODE.NODATA,
                  msg: res?.responseStatus?.msg,
                },
              },
            }));
          }
        });
      });
    } else if (
      props.granular !== "Raw Data" &&
      props.startDate.toLowerCase().indexOf(":") !== -1
    ) {
      let date = props.startDate.split(":");

      Object.keys(objects).forEach((item) => {
        getGranularityData(
          props.batteryID,
          item.toUpperCase(),
          props.hierarchy.selectedModule.value,
          props.hierarchy.selectedCell.value,
          "pack",
          [value],
          [date[0], date[1]],
          props.granular,
          props.operationType
        ).then((res) => {
          if (res.responseStatus.code === CODE.SUCCESS) {
            let tempData = [];
            res?.response?.batteryData?.map((item) => {
              tempData.push({
                x:
                  props.granular === "Daily"
                    ? formatDateWithoutYear(
                        new Date(item["range"]["startDate"])
                      )
                    : props.granular === "Monthly"
                      ? monthDictionary.find(
                          (o) =>
                            o.name === item["range"]["startDate"].split("-")[1]
                        )["value"]
                      : formatDateWithoutYear(
                          new Date(item["range"]["startDate"])
                        ) +
                        " - " +
                        formatDateWithoutYear(
                          new Date(item["range"]["endDate"])
                        ),
                y:
                  res?.responseStatus?.code === CODE.SUCCESS
                    ? item["metricsArray"][0]["value"]
                    : null,
              });
            });
            setDynamicObjectsCSV((prev) => ({
              ...prev,
              [item]: {
                ...prev[item],
                data: {
                  ...prev[item]["data"],
                  metric: tempData,
                },
                responseStatus: {
                  code: res?.responseStatus?.code,
                  msg: res?.responseStatus?.msg,
                },
              },
            }));
          } else {
            setDynamicObjects((prev) => ({
              ...prev,
              [item]: {
                ...prev[item],
                data: {
                  ...prev[item]["data"],
                  metric: [],
                },
                responseStatus: {
                  code: CODE.NODATA,
                  msg: res.responseStatus.msg,
                },
              },
            }));
          }
        });
      });
    }
  }

  useEffect(() => {
    setFinalObject({
      data: [],
      responseStatus: {
        code: CODE.LOADING,
        msg: "Loading",
      },
    });

    if (props.hierarchy.responseStatus.code === CODE.SUCCESS) {
      let arr = [];
      props.hierarchy.packHierarchy.forEach((item) => arr.push(item.value));

      const objects = {};
      arr.forEach((item, index) => {
        let pitem = finalObject?.data[item];
        let isActive = pitem?.isActive ?? true;
        objects[item] = {
          data: {
            metric: [],
            xaxis: [],
          },
          name: item,
          key: props.batteryID + "_" + item,
          color: colors[index],
          isActive: isActive,
          isPresent: true,
          responseStatus: {
            code: -1,
            msg: "",
          },
        };
      });
      setDynamicObjects(objects);
      if (
        props.granular === "Raw Data" &&
        props.startDate.toLowerCase().indexOf(":") === -1
      ) {
        Object.keys(objects).forEach((item) => {
          getPackComparision(
            props.batteryID,
            item.toUpperCase(),
            props.hierarchy.selectedModule.value,
            props.hierarchy.selectedCell.value,
            "pack",
            value,
            [props.startDate, props.startDate]
          ).then((res) => {
            if (res?.responseStatus?.code === CODE.SUCCESS) {
              let tempData = [];
              res?.response?.batteryData?.map((item) => {
                tempData.push({
                  x: getChartTimeStamp(item?.batteryDateTime),
                  y:
                    item["metricsArray"][0]["value"] *
                    metrics.find((item) => item.value === value)?.multiplyBy,
                });
              });
              setDynamicObjects((prev) => ({
                ...prev,
                [item]: {
                  ...prev[item],
                  data: {
                    ...prev[item]["data"],
                    metric: tempData,
                  },
                  responseStatus: {
                    code: res?.responseStatus?.code,
                    msg: res?.responseStatus?.msg,
                  },
                },
              }));
            } else {
              setDynamicObjects((prev) => ({
                ...prev,
                [item]: {
                  ...prev[item],
                  data: {
                    ...prev[item]["data"],
                    metric: [],
                  },
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: res?.responseStatus?.msg,
                  },
                },
              }));
            }
          });
        });
      } else if (
        props.granular !== "Raw Data" &&
        props.startDate.toLowerCase().indexOf(":") !== -1
      ) {
        let date = props.startDate.split(":");

        Object.keys(objects).forEach((item) => {
          getGranularityData(
            props.batteryID,
            item.toUpperCase(),
            props.hierarchy.selectedModule.value,
            props.hierarchy.selectedCell.value,
            "pack",
            [value],
            [date[0], date[1]],
            props.granular,
            props.operationType
          ).then((res) => {
            if (res.responseStatus.code === CODE.SUCCESS) {
              let tempData = [];
              res?.response?.batteryData?.map((item) => {
                tempData.push({
                  x:
                    props.granular === "Daily"
                      ? formatDateWithoutYear(
                          convertToZoneDate(item["range"]["startDate"])
                        )
                      : props.granular === "Monthly"
                        ? monthDictionary.find(
                            (o) =>
                              o.name ===
                              item["range"]["startDate"].split("-")[1]
                          )["value"]
                        : [
                            [
                              formatDateWithoutYear(
                                convertToZoneDate(item["range"]["startDate"])
                              ) + " - ",
                            ],

                            [
                              formatDateWithoutYear(
                                convertToZoneDate(item["range"]["endDate"])
                              ),
                            ],
                          ],
                  y:
                    res?.responseStatus?.code === CODE.SUCCESS
                      ? item["metricsArray"][0]["value"] *
                        metrics.find((item) => item.value === value)?.multiplyBy
                      : null,
                });
              });
              setDynamicObjects((prev) => ({
                ...prev,
                [item]: {
                  ...prev[item],
                  data: {
                    ...prev[item]["data"],
                    metric: tempData,
                  },
                  responseStatus: {
                    code: res?.responseStatus?.code,
                    msg: res?.responseStatus?.msg,
                  },
                },
              }));
            } else {
              setDynamicObjects((prev) => ({
                ...prev,
                [item]: {
                  ...prev[item],
                  data: {
                    ...prev[item]["data"],
                    metric: [],
                  },
                  responseStatus: {
                    code: CODE.NODATA,
                    msg: res.responseStatus.msg,
                  },
                },
              }));
            }
          });
        });
      }

      setDynamicObjects(objects);
    }
  }, [
    props.hierarchy.responseStatus.code,
    value,
    props.startDate,
    props.granular,
    props.operationType,
  ]);

  useEffect(() => {
    if (
      Object.values(dynamicObjects).every(
        (value) => value.responseStatus.code === CODE.SUCCESS
      ) &&
      !isEmpty(dynamicObjects)
    ) {
      setFinalObject({
        data: dynamicObjects,
        responseStatus: {
          code: CODE.SUCCESS,
          msg: "Success",
        },
      });
    } else if (
      Object.values(dynamicObjects).every(
        (value) => value.responseStatus.code === 1999
      ) &&
      !isEmpty(dynamicObjects)
    ) {
      setFinalObject({
        data: dynamicObjects,
        responseStatus: {
          code: 1999,
          msg: "No data found",
        },
      });
    } else if (
      Object.values(dynamicObjects).every(
        (value) => value.responseStatus.code === CODE.NODATA
      ) &&
      !isEmpty(dynamicObjects)
    ) {
      setFinalObject({
        data: [],
        responseStatus: {
          code: CODE.NODATA,
          msg: "No data found",
        },
      });
    } else if (
      Object.values(dynamicObjects).every(
        (value) => value.responseStatus.code === CODE.LOADING
      ) &&
      !isEmpty(dynamicObjects)
    ) {
      setFinalObject({
        data: [],
        responseStatus: {
          code: CODE.LOADING,
          msg: "Loading",
        },
      });
    } else if (
      Object.values(dynamicObjects).some(
        (value) => value.responseStatus.code === CODE.SUCCESS
      ) &&
      Object.values(dynamicObjects).some(
        (value) => value.responseStatus.code === CODE.NODATA
      ) &&
      Object.values(dynamicObjects).every(
        (value) => value.responseStatus.code !== CODE.LOADING
      ) &&
      !isEmpty(dynamicObjects)
    ) {
      let tempObj = dynamicObjects;

      Object.values(dynamicObjects).map((item) => {
        if (item.responseStatus.code === CODE.NODATA)
          tempObj[item.name] = Object.assign(item, { isPresent: false });
      });

      setFinalObject({
        data: tempObj,
        responseStatus: {
          code: CODE.SUCCESS,
          msg: "Success",
        },
      });
    }
  }, [dynamicObjects]);

  function getYMinMax() {
    let ymin = Infinity;
    let ymax = -Infinity;
    let data = [];
    convertStateToSeries().series?.forEach((pack) => {
      let packs = [];
      packs.push(pack);
      packs.forEach((p) => {
        let yAxisData = p.data.map((d) => d.y);
        data.push(yAxisData);
      });
    });
    for (let i = 0; i < data.length; i++) {
      let y = data[i].enegy;
      if (y < ymin) ymin = y;
      if (y > ymax) ymax = y;
    }
    return { ymin, ymax };
  }

  useEffect(() => {
    if (props.tab === "Battery Vitals") {
      if (
        Object.values(dynamicObjectsCSV).every(
          (value) => value.responseStatus.code === CODE.SUCCESS
        ) &&
        !isEmpty(dynamicObjectsCSV)
      ) {
        let tempCSV = [];
        console.log(dynamicObjectsCSV);
        Object.values(dynamicObjectsCSV).map((item, index) => {
          item.data.metric.map((item2, index2) => {
            tempCSV.push({
              Metric: metrics.find((i) => i.value === value)["yAxisTitle"],
              Pack: item.name.toUpperCase(),
              Timestamp:
                item2.x && props.granular === "Raw Data"
                  ? getChartTimeStamp(item2.x, true).replace(/,/g, "")
                  : item2.x && props.granular !== "Raw Data"
                    ? item2.x
                    : "",
              Value: item2.y,
              "Operation Type": props.operationType,
            });
          });
        });

        setFinalObjectCSV({
          data: tempCSV,
          responseStatus: {
            code: CODE.SUCCESS,
          },
        });
      }
      if (
        (Object.values(dynamicObjectsCSV).every(
          (value) => value.responseStatus.code === CODE.NODATA
        ) ||
          Object.values(dynamicObjectsCSV).every(
            (value) => value.responseStatus.code === 1999
          )) &&
        !isEmpty(dynamicObjectsCSV)
      ) {
        setFinalObjectCSV((prev) => ({
          ...prev,
          data: [],
          responseStatus: {
            code: CODE.NODATA,
          },
        }));
      }
    }
  }, [dynamicObjectsCSV]);

  return (
    <PaperHighlight
      elevation={0}
      onMouseEnter={() => Events("SB Hovered Pack Comaprision Chart")}
    >
      <Header
        heading="Pack Comparison Chart"
        description="Showing how battery signals (Voltage, Current, Temperature, SOC) are fluctuating over time within a day"
        showIButton={true}
        showThreeDots={false}
        showCSVDownload={true}
        csvData={finalObjectCSV}
        SetCSVData={GetComparisionCSV}
        setStatusBack={setFinalObjectCSV}
        id="Comparision_Chart"
        page="specificBattery"
      />
      <div className="section" id="Battery_Pack_Metrics">
        <div>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              {metrics.map((item, index) => (
                <FormControlLabel
                  key={index}
                  value={item.value}
                  control={<Radio sx={radioSize} />}
                  label={
                    <Typography variant="textSubtitle">{item.key}</Typography>
                  }
                />
              ))}
            </RadioGroup>
          </FormControl>

          <>
            <TimeSeriesSingleAxis
              data={{
                series: convertStateToSeries().series,
                colors: convertStateToSeries().colors,
                xaxisType:
                  props.granular === "Raw Data" ? "datetime" : "category",

                yAxisTitle: `${
                  metrics.find((item) => item.value === value)["yAxisTitle"]
                }`,
                yAxisColor: "#000",
                ymin: metrics.find((item) => item.value === value)["ymin"],
                ymax: metrics.find((item) => item.value === value)["ymax"],
                yAxisDecimalPlaces: metrics.find((item) => item.value === value)
                  ?.yAxisDecimalPlaces,

                toolTipDecimalPlaces: metrics.find(
                  (item) => item.value === value
                )["decimalPlaces"],

                responseStatus: {
                  code: finalObject.responseStatus.code,
                  msg: finalObject.responseStatus.msg,
                },
              }}
              granular={props.granular}
              startDate={props.startDate}
            />

            <div
              style={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
                marginTop: "10px",
                marginLeft: "20px",
              }}
            >
              {Object.values(finalObject.data).map((item, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: 2,
                      opacity: item["isActive"] ? 1 : 0.4,
                      cursor: item["isPresent"] ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (item["isPresent"])
                        updateActiveState(item["name"], item["isActive"]);
                    }}
                    key={index}
                  >
                    {item["isPresent"] ? (
                      <Brightness1
                        sx={{
                          color: item["isPresent"] ? item["color"] : "#ececec",
                          mr: 0.5,
                        }}
                      />
                    ) : (
                      <Tooltip title="Data not found">
                        <Brightness1
                          sx={{
                            color: item["isPresent"]
                              ? item["color"]
                              : "#ececec",
                            mr: 0.5,
                          }}
                        />
                      </Tooltip>
                    )}
                    <Typography sx={{ color: "black", fontSize: 12 }}>
                      {item["name"].toUpperCase()}
                    </Typography>
                  </Box>
                );
              })}
            </div>
          </>
        </div>
      </div>
    </PaperHighlight>
  );
}

export default ComparisionChart;
