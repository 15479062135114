import qs from "qs";
import { GetData } from "../Functions/SettingData";
import {
  convertZonetoUTCStart,
  convertZonetoUTCEnd,
} from "../../Helper/DatePicker/DateConverter";

async function getNonPingingBatteries(
    date,
    iotVendor,
    batteryVendor,
    alertType,
    granularity
  ) {
    var rawJson = {
      startDate: convertZonetoUTCStart(date[0]),
      endDate: convertZonetoUTCEnd(date[1]),
  
      iotVendor: iotVendor,
      batteryVendor: batteryVendor,
      granularity: granularity,
    };
    var config = {
      method: "get",
      url: `/api/not_pinging_devices`,
      headers: {
        "Content-Type": "application/json",
      },
      params: rawJson,
    };
    let data = GetData(config);
    return data;
  }

  async function getTimelinessLatency(
    date,
    iotVendor,
    batteryVendor,
    granularity
  ) {
    var rawJson = {
      startDate: convertZonetoUTCStart(date[0]),
      endDate: convertZonetoUTCEnd(date[1]),
  
      iotVendor: iotVendor,
      batteryVendor: batteryVendor,
      granularity: granularity,
    };
    var config = {
      method: "get",
      url: `/api/ping_frequency`,
      headers: {
        "Content-Type": "application/json",
      },
      params: rawJson,
    };
    let data = GetData(config);
    return data;
  }

export {
    getNonPingingBatteries,
    getTimelinessLatency
}