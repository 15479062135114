import { SvgIcon } from "@mui/material"
import React from "react"

const NavbarAlertIcon = (props) => {
  const { height, width, viewBox, fill, style, stroke, ...other } = props
  return (
    <SvgIcon
      style={style}
    >
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
      >
        <path d="M7.99813 10.6653C7.69326 10.6653 7.44043 10.3976 7.44043 10.0748V6.13777C7.44043 5.81493 7.69326 5.54722 7.99813 5.54722C8.30301 5.54722 8.55584 5.81493 8.55584 6.13777V10.0748C8.55584 10.3976 8.30301 10.6653 7.99813 10.6653Z" fill={fill} />
        <path d="M7.99849 13.2244C7.95387 13.2244 7.90182 13.2166 7.84977 13.2087C7.80515 13.2008 7.76053 13.1851 7.71592 13.1614C7.6713 13.1457 7.62669 13.1221 7.58207 13.0906C7.54489 13.0591 7.50771 13.0276 7.47053 12.9961C7.33668 12.8465 7.25488 12.6418 7.25488 12.437C7.25488 12.2323 7.33668 12.0276 7.47053 11.878C7.50771 11.8465 7.54489 11.815 7.58207 11.7835C7.62669 11.752 7.6713 11.7284 7.71592 11.7126C7.76053 11.689 7.80515 11.6732 7.84977 11.6654C7.94644 11.6418 8.05054 11.6418 8.13977 11.6654C8.19183 11.6732 8.23644 11.689 8.28106 11.7126C8.32568 11.7284 8.37029 11.752 8.41491 11.7835C8.45209 11.815 8.48927 11.8465 8.52645 11.878C8.6603 12.0276 8.74209 12.2323 8.74209 12.437C8.74209 12.6418 8.6603 12.8465 8.52645 12.9961C8.48927 13.0276 8.45209 13.0591 8.41491 13.0906C8.37029 13.1221 8.32568 13.1457 8.28106 13.1614C8.23644 13.1851 8.19183 13.2008 8.13977 13.2087C8.09516 13.2166 8.0431 13.2244 7.99849 13.2244Z" fill={fill} />
        <path d="M12.5047 16.5H3.49221C2.04218 16.5 0.934209 15.9409 0.369069 14.9331C-0.188635 13.9252 -0.114275 12.626 0.592151 11.2795L5.0984 2.69685C5.84201 1.27953 6.86818 0.5 7.99846 0.5C9.12874 0.5 10.1549 1.27953 10.8985 2.69685L15.4048 11.2874C16.1112 12.6339 16.193 13.9252 15.6279 14.9409C15.0627 15.9409 13.9547 16.5 12.5047 16.5ZM7.99846 1.6811C7.29947 1.6811 6.61536 2.24803 6.07252 3.27165L1.57371 11.8622C1.06806 12.8228 0.986262 13.7047 1.33576 14.3425C1.68525 14.9803 2.4586 15.3268 3.49965 15.3268H12.5121C13.5532 15.3268 14.3191 14.9803 14.676 14.3425C15.033 13.7047 14.9437 12.8307 14.4381 11.8622L9.9244 3.27165C9.38157 2.24803 8.69745 1.6811 7.99846 1.6811Z" fill={fill} />
      </svg>
    </SvgIcon>

  )
}

NavbarAlertIcon.defaultProps = {
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "#8D9FB2",
  stroke: ""
}

export default NavbarAlertIcon
