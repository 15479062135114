import React from "react"
import HeartBeat from "../../../Assets/Icons/BatteryHealth/HeartBeat.png"

const HeartWithLine = (props) => {
  return (
    <img 
      src={HeartBeat} 
      alt="Description" 
      style={{ width: props.width, height: props.height }}
    />
  )
}

HeartWithLine.defaultProps = {
  width: "19px",
  height: "19px",
}

export default HeartWithLine
