import React from "react"
import { SvgIcon } from "@mui/material"

const NavbarDigitalTwinIcon = (props) => {
  const { height, width, viewBox, fill, style, stroke, ...other } = props
  return (
    <SvgIcon
      style={style}
    >
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={'transparent'}
      >
        <path d="M2.3 17.75H9.7C10.2799 17.75 10.75 17.2799 10.75 16.7V4.3C10.75 3.7201 10.2799 3.25 9.7 3.25H8.25V2.3C8.25 1.7201 7.7799 1.25 7.2 1.25H4.8C4.2201 1.25 3.75 1.7201 3.75 2.3V3.25H2.3C1.7201 3.25 1.25 3.7201 1.25 4.3V16.7C1.25 17.2799 1.7201 17.75 2.3 17.75Z" stroke={fill} stroke-width="1.5" />
        <circle cx="6" cy="10" r="2" fill={fill} />
      </svg>
    </SvgIcon>

  )
}

NavbarDigitalTwinIcon.defaultProps = {
  width: "12",
  height: "19",
  viewBox: "0 0 12 19",
  fill: "#8D9FB2",
  stroke: ""
}

export default NavbarDigitalTwinIcon
