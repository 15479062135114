import { PaperHighlight } from "../../Components/CustomPaper"
import GenericHeader from "../Header/GenericHeader"
import {
  CardBodyContainer,
  CardFooterContainer,
} from "../../Components/CustomBox";
import { useEffect } from "react";
import ScrollToTheElement from "../../Helper/Operations/ScrollToTheElement";

const CardWithHeader = ({
  id,
  pageLabel,
  headerClass,
  title,
  subtitle,
  cardStyle,
  bodyStyle,
  iconElement,
  csvName,
  csvData,
  generateCsvData,
  onMouseEnter,
  openNotification,
  showCSVDownload,
  closeNotification,
  children,
  hasFilter,
  filterData,
  onFilterChange,
  onClickHeader,
  clickableHeader
}) => {
  useEffect(() => {
    ScrollToTheElement()
  }, []);

  return (
    <PaperHighlight
      id={id}
      elevation={0}
      onMouseEnter={onMouseEnter}
      sx={{ height: "540px", ...cardStyle }}
    >
      <GenericHeader
        id={id}
        headerClass={headerClass}
        iconElement={iconElement}
        heading={title}
        description={subtitle}
        pageLabel={pageLabel}
        csvName={csvName}
        csvData={csvData}
        showCSVDownload={showCSVDownload}
        generateCsvData={generateCsvData}
        openNotification={openNotification}
        closeNotification={closeNotification}
        hasFilter={hasFilter}
        filterData={filterData}
        onFilterChange={onFilterChange}
        onClickHeader={onClickHeader}
        clickableHeader={clickableHeader}
      />
      <CardBodyContainer sx={bodyStyle}>{children}</CardBodyContainer>
    </PaperHighlight>
  );
}

CardWithHeader.defaultProps = {
  id: "",
  pageLabel: "",
  title: "",
  subtitle: null,
  iconElement: null,
  csvName: "",
  showCSVDownload: false,
  cardStyle: {
    height: "540px",
  },
  hasFilter: false,
  filterData: {},
  headerClass: "",
  clickableHeader: false,
  onClickHeader: () => {},
  onFilterChange: () => {},
  openNotification: () => {},
  closeNotification: () => {},
  generateCsvData: () => {}
}

export default CardWithHeader
