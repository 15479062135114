import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Box, Button, ButtonGroup, Checkbox, Typography } from "@mui/material"

import PulseLoader from "react-spinners/MoonLoader"
import {
  LiveMetricsBox,
  DigitalTwinChartLoader,
  ChartFilterBox,
  ChartFilter
} from "../../../../Components/CustomBox.js"
import DigitalTwinChargerVitalsChart from "../../../../Charts/Line/DigitalTwinChargerVitalsChart"
import SlickCarousel from "../../../../Features/Carousel/SlickCarousel"
import {
  checkBoxIcon,
  checkedIcon
} from "../../../../Assets/Icons/DigitalTwin/CheckBoxIcon"

import {
  operations,
  tableFilters
} from "../../../DigitalTwin/TabsSection/BatteryVitals/_Constants"

import {
  decryptTheParams,
  encryptTheParams
} from "../../../../Helper/QueryParams/EncryptDecrypt.js"
import { useNavigate } from "react-router-dom"
import {
  DateDiffInDays,
  formatDate,
  formatDateNumeric,
  formatDateWithoutYear,
  getArrayOfFormattedDates,
  getArrayOfNumericDates,
  getWeekNumber,
} from "../../../../Helper/DatePicker/DateFormatters.js"
import { toTitleCase } from "../../../../Helper/Formatters/TextFormatter"
import {
  convertAndGetTimeStampInRequiredFormat,
  convertToZoneDate,
  convertUTCtoZone
} from "../../../../Helper/DatePicker/DateConverter.js"
import CustomIcon from "../../../../Components/CustomIcon.js"
import moment from "moment"
import { Tooltip } from "antd"
import Events from "../../../../Analytics/Events.js"
import {
  getChargerGranularityData,
  getChargerVitals,
  getChargerData
} from "../../../../Api/DigitalTwin.js"
import { SetSessionExpired } from "../../../../Actions/index.js"
import { calculateDynamicRange, getXAxisLabelForWeeklyGranularity } from "../../../../Helper/Charts/ChartHelper.js"

const ChargerVitalsTab = (props) => {
  const { hierarchy, batteryID, dateFilters, pagesContent, ...other } = props

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryParams = decryptTheParams()
  const liveMetricData = useSelector((state) => state.BatteryLiveMetric)
  const [isChartEmpty, setIsChartEmpty] = useState(true)
  const [isFetchingChartData, setIsFetchingChartData] = useState(false)
  const [optionData, setOptionData] = useState({})
  const [liveMetric, setLiveMetric] = useState([null, null, null, null, null, null, null, null, null])

  const [operationType, setOperationType] = useState(
    queryParams?.operationType || "avg"
  )
  const [dateType, setDateType] = useState(queryParams?.dateType || "Raw Data")
  const [arrayOfDates, setArrayOfDatesFormatted] = useState([])
  const [arrayOfDatesNumeric, setArrayOfDatesNumeric] = useState([])
  const [xAxis, setXAxis] = useState(null);

  const [carousalStartDate, setCarousalStartDate] = useState({
    dateString: queryParams?.batteryPackMetrics
  })

  const defaultSelectedMetricsData = [
    {
      label: "Current (A)",
      slug: "current",
      status: "active",
      checked: true,
      yAxisDecimalPlaces: 1,
      color: "#7855FF",
      unit: "A",
      yMin: 0,
      yMax: 25
    },
    {
      label: "Voltage (V)",
      slug: "voltage",
      status: "active",
      checked: true,
      yAxisDecimalPlaces: 1,
      color: "#FF7557",
      unit: "V",
      yMin: 0,
      yMax: 100
    },
    {
      label: "Power (W)",
      slug: "power",
      status: "active",
      checked: true,
      yAxisDecimalPlaces: 0,
      color: "#93E89D",
      unit: "W",
      yMin: 0,
      yMax: 400
    },
    {
      label: "Temperature (°C)",
      slug: "temperature",
      status: "active",
      checked: true,
      yAxisDecimalPlaces: 1,
      color: "#229087",
      unit: "°C",
      yMin: -20,
      yMax: 80
    }
  ]
  const [selectedMetrics, setSelectedMetrics] = useState(
    defaultSelectedMetricsData
  )

  const [chartMetricsValue, setChartMetricValue] = useState({})

  const [dataSeriesForChart, setDataSeriesForChart] = useState({})

  const [calendarDateDiff, setCalendarDateDiff] = useState(0)

  const findChargeState = (value) => {
    if (value === 0)
      return "Not Charging"
    else if (value === 1)
      return "Charging"
    else
      return ""
  }

  const findLoadState = (value) => {
    if (value === 0)
      return "No"
    else if (value === 1)
      return "Yes"
    else
      return ""
  }

  const onMetricFilterCheck = (value, index) => {
    setSelectedMetrics((prevState) => {
      const newItems = [...prevState]
      return newItems.map((data, i) => {
        if (i === index) {
          Events(
            `On digital twin page under charger vital tab, ${value ? "show" : "hide"
            } ${data.label} metric for Vital section chart.`
          )
          return { ...data, checked: value }
        }

        return data
      })
    })
  }

  const getLiveMetricSection = () => {
    return liveMetric?.map((metric) => {
      const renderValue = () => {
        return (
          <Typography
            style={{
              fontFamily: "Roboto",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#444B51",
              whiteSpace: "nowrap",
              textAlign: "left"
            }}
          >
            {Number.isFinite(metric?.value) || metric?.value ? metric?.value : "NA"}
          </Typography>
        )
      }

      return (
        <LiveMetricsBox color={"#CBCBCC"}>
          {metric ? (
            <Box
              style={{
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "rgba(68, 75, 81, 1)"
                }}
              >
                <Tooltip
                  placement="top"
                  title={liveMetricData.batteryPingedStatus === "Online" ? "Live Signal Values" : "Offline"}
                  zIndex={2000}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "7px"
                    }}
                  >
                    <CustomIcon
                      name={liveMetricData.batteryPingedStatus === "Online" ? "online" : "offline"}
                      style={{
                        width: "14px",
                        height: "10px"
                      }}
                    />
                  </Box>
                </Tooltip>
                <Typography
                  style={{
                    marginLeft: "3px",
                    fontFamily: "Roboto",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#828A92",
                    whiteSpace: "nowrap",
                    marginBottom: "4px"
                  }}
                >
                  {metric?.label}
                </Typography>
              </Box>
              {renderValue()}
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "between",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <PulseLoader color={"#213444"} size={20} speedMultiplier={1} />
            </Box>
          )}
        </LiveMetricsBox>
      )
    })
  }

  const handleDateTypeChange = (value) => {
    if (dateType === value) {
      return
    }
    Events(
      `On digital twin page under charger vital tab, date type filter is set to ${value} for the chart.`
    )
    setIsFetchingChartData(true)
    setDateType(value)
    const temp = {
      ...queryParams,
      dateType: value,
      batteryPackMetrics: ""
    }
    setCarousalDate("", temp)
  }

  const handleOperationTypeChange = (value) => {
    Events(
      `On digital twin page under charger vital tab, operation type filter is set to ${value} for the chart.`
    )
    setOperationType(value)
    const temp = {
      ...queryParams,
      operationType: value,
      batteryPackMetrics: carousalStartDate.dateString
    }
    encryptTheParams(temp, navigate, true)
  }

  const lastSunday = (year, month) => {
    var date = new Date(year, month, 1, 12)
    let weekday = date.getDay()
    let dayDiff = weekday === 0 ? 7 : weekday
    date.setDate(date.getDate() - dayDiff)
    return date
  }

  const setCarousalDate = (dateString, params = {}) => {
    const temp = {
      ...queryParams,
      ...params,
      batteryPackMetrics: dateString
    }
    setCarousalStartDate({ dateString })
    encryptTheParams(temp, navigate, true)
  }

  const updateMetricData = (metricsData) => {
    for (let key in metricsData) {
      if (key === "ranges") {
        continue
      }
      let yAxisValues = metricsData[key].map((data) => {
        if (data && typeof data === "object") {
          return data.y
        } else {
          return data
        }
      })

      yAxisValues = yAxisValues.filter((item) => item !== undefined && item !== null)

      const thresholds = pagesContent.metaInfo?.chargerVitalsRanges?.[key]
      let minYValueSlice, maxYValueSlice

      if (thresholds) {
        minYValueSlice = thresholds.min
        maxYValueSlice = thresholds.max
      }

      const minYValue = Math.min(...yAxisValues)
      const maxYValue = Math.max(...yAxisValues)

      if (minYValue < minYValueSlice || maxYValue > maxYValueSlice) {
        const dynamicRange = calculateDynamicRange(minYValue, maxYValue, minYValueSlice, maxYValueSlice)
        minYValueSlice = dynamicRange.min
        maxYValueSlice = dynamicRange.max
      }

      metricsData.ranges = {
        ...metricsData.ranges,
        [key]: {
          min: minYValueSlice,
          max: maxYValueSlice,
        },
      };
    }
    setChartMetricValue(metricsData)
  }

  const getChartData = (startDate = null, endDate = null) => {
    if (!startDate || !endDate) {
      return
    }
    setIsFetchingChartData(true)
    const metricsData = {
      power: [],
      current: [],
      voltage: [],
      temperature: [],
      ranges: {
        power: {
          min: 0,
          max: 100
        },
        current: {
          min: -20,
          max: 20
        },
        voltage: {
          min: 20,
          max: 35
        },
        temperature: {
          min: -20,
          max: 80
        }
      }
    }
    if (dateType === "Raw Data") {
      getChargerVitals(
        batteryID,
        [
          "solarInputVoltage",
          "solarInputPower",
          "solarInputCurrent",
          "chargerTemperature",
          "timeOfDay",
        ],
        [startDate, endDate]
      ).then((res) => {
        if (res?.responseStatus?.code === 200) {
          res?.response?.batteryData?.forEach((item) => {
            metricsData.current.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y: item.metricsArray.find((i) => i.type === "solarInputCurrent")[
                "value"
              ]
            })
            metricsData.voltage.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y: item.metricsArray.find((i) => i.type === "solarInputVoltage")[
                "value"
              ]
            })
            metricsData.power.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y: item.metricsArray.find((i) => i.type === "solarInputPower")[
                "value"
              ]
            })
            metricsData.temperature.push({
              x: convertUTCtoZone(item.batteryDateTime, true),
              y: item.metricsArray.find((i) => i.type === "chargerTemperature")[
                "value"
              ]
            })
          })
          setIsChartEmpty(false)
        } else if (res?.responseStatus?.code === -2) {
          dispatch(SetSessionExpired(true))
        } else {
          setIsChartEmpty(true)
        }
        updateMetricData(metricsData)
        setOptionData({ dateType })
        setIsFetchingChartData(false)
      })
    } else if (dateType !== "Raw Data") {
      let columnsArr = [
        "solarInputVoltage",
        "solarInputPower",
        "solarInputCurrent"
      ]
      getChargerGranularityData(
        batteryID,
        columnsArr,
        [startDate, endDate],
        dateType,
        operationType
      ).then((res) => {
        if (res?.responseStatus?.code === 200) {
          res?.response?.batteryData?.forEach((item) => {
            let tempX
            switch (dateType) {
              case "Daily":
                const date = new Date(item?.range?.startDate)
                tempX = formatDateWithoutYear(date)
                break

              case "Weekly":
                const startDate = new Date(item?.range?.startDate)
                const endDate = new Date(item?.range?.endDate)
                const seriesLength = res?.response?.batteryData?.length
                tempX = getXAxisLabelForWeeklyGranularity(startDate, endDate, seriesLength)
                break

              case "Monthly":
                const month = moment(item?.range?.startDate, "YYYY-MM")
                if (
                  carousalStartDate?.dateString.substring(0, 4) ===
                  `${month.year()}`
                ) {
                  tempX = moment(month, "YYYY-MM").format("MMM")
                }
            }

            metricsData.current.push({
              x: tempX,
              y:
                item["metricsArray"].find((i) => i.type === "solarInputCurrent")
                  ?.value === null
                  ? null
                  : item["metricsArray"].find(
                    (i) => i.type === "solarInputCurrent"
                  )?.value
            })
            metricsData.power.push({
              x: tempX,
              y:
                item["metricsArray"].find((i) => i.type === "solarInputPower")
                  ?.value === undefined
                  ? null
                  : item["metricsArray"].find(
                    (i) => i.type === "solarInputPower"
                  )?.value
            })
            metricsData.voltage.push({
              x: tempX,
              y:
                item["metricsArray"].find((i) => i.type === "solarInputVoltage")
                  ?.value === undefined
                  ? null
                  : item["metricsArray"].find(
                    (i) => i.type === "solarInputVoltage"
                  )?.value
            })
          })
          setIsChartEmpty(false)
        } else if (res?.responseStatus?.code === -2) {
          dispatch(SetSessionExpired(true))
        } else {
          setIsChartEmpty(true)
          res?.response?.batteryData?.forEach((item) => {
            let tempX
            switch (dateType) {
              case "Daily":
                const date = new Date(item?.range?.startDate)
                tempX = formatDateWithoutYear(date)
                break

              case "Weekly":
                const startDate = new Date(item?.range?.startDate)
                const endDate = new Date(item?.range?.endDate)
                const seriesLength = res?.response?.batteryData?.length
                tempX = getXAxisLabelForWeeklyGranularity(startDate, endDate, seriesLength)
                break

              case "Monthly":
                const month = moment(item?.range?.startDate, "YYYY-MM")
                if (
                  carousalStartDate?.dateString.substring(0, 4) ===
                  `${month.year()}`
                ) {
                  tempX = moment(month, "YYYY-MM").format("MMM")
                }
            }

            metricsData.current.push({
              x: tempX,
              y: null
            })
            metricsData.voltage.push({
              x: tempX,
              y: null
            })
            metricsData.power.push({
              x: tempX,
              y: null
            })
          })
        }
        updateMetricData(metricsData)
        setOptionData({ dateType })
        setIsFetchingChartData(false)
      })
    }
  }

  const getSectionData = () => {
    getChargerData(batteryID).then((res) => {
      formatRealtimeChargerData(res.response?.batteryData)
    })
  }

  const formatRealtimeChargerData = async (chargerData) => {
    const chartLiveMetricData = [
      {
        label: "Input Current (A)",
        value: chargerData?.solarInputCurrent
      },
      {
        label: "Input Voltage (V)",
        value: chargerData?.solarInputVoltage
      },
      {
        label: "Input Power (W)",
        value: chargerData?.solarInputPower
      },
      {
        label: "Charger Temp. (°C)",
        value: chargerData?.chargerTemperature
      },
      {
        label: "Load On",
        value: findLoadState(chargerData?.loadState)
      },
      {
        label: "Charge State",
        value: findChargeState(chargerData?.solarChargeState)
      },
      {
        label: "Controller Brand",
        value: chargerData?.chargerBrand
      },
      {
        label: "Time of Day",
        value: toTitleCase(chargerData?.timeOfDay)
      },
      {
        label: "Last Pinged",
        value: convertAndGetTimeStampInRequiredFormat(chargerData?.lastUpdatedCustomerDBTime, "DD MMM'YY hh:mm A")
      }
    ]

    setLiveMetric(chartLiveMetricData)
  }

  const createCarousalDateRanges = () => {
    if (dateType === "Raw Data") {
      if (dateFilters?.startDate || dateFilters?.endDate) {
        var tempFormatted = getArrayOfFormattedDates(
          dateFilters?.startDate,
          dateFilters?.endDate
        )
        var tempNumeric = getArrayOfNumericDates(
          dateFilters?.startDate,
          dateFilters?.endDate
        )

        setArrayOfDatesFormatted(tempFormatted)
        setArrayOfDatesNumeric(tempNumeric)
        setCarousalDate(carousalStartDate.dateString || tempNumeric[0])
      }
      return
    }

    if (dateType === "Daily") {
      var tempFormatted = getArrayOfFormattedDates(
        dateFilters?.startDate,
        dateFilters?.endDate
      )
      var tempNumeric = getArrayOfNumericDates(
        dateFilters?.startDate,
        dateFilters?.endDate
      )

      var buckets = [],
        tempDatesToShow = [],
        tempDatesToSave = []
      for (var i = 0; i < tempFormatted.length; i = i + 15) {
        var tempStart = tempFormatted[i],
          tempEnd =
            tempFormatted[i + 14] === undefined
              ? tempFormatted[tempFormatted.length - 1]
              : tempFormatted[i + 14],
          tempStartSave = tempNumeric[i],
          tempEndSave =
            tempNumeric[i + 14] === undefined
              ? tempNumeric[tempNumeric.length - 1]
              : tempNumeric[i + 14]

        buckets.push({
          start: tempStart,
          end: tempEnd,
          textToShow: tempStart + " to " + tempEnd,
          textToSave: tempStartSave + ":" + tempEndSave
        })
        tempDatesToShow.push(tempStart + " to " + tempEnd)
        tempDatesToSave.push(tempStartSave + ":" + tempEndSave)
      }

      setArrayOfDatesFormatted(tempDatesToShow)
      setArrayOfDatesNumeric(tempDatesToSave)
      setCarousalDate(carousalStartDate?.dateString || tempDatesToSave[0])
    } else if (dateType === "Weekly") {
      let diffInDays = DateDiffInDays(
        dateFilters?.startDate,
        dateFilters?.endDate
      )
      if (diffInDays > 105) {
        var myStart = formatDate(convertToZoneDate(dateFilters?.startDate))
        var myEnd = formatDate(convertToZoneDate(dateFilters?.endDate))

        var tempDateRanges = []

        var tempStart = new Date(myStart)
        let tempEnd = new Date(tempStart.setDate(tempStart.getDate() + 105))

        tempDateRanges.push({
          start: new Date(myStart),
          end: lastSunday(tempEnd.getUTCFullYear(), tempEnd.getUTCMonth() + 1),
          startWeek: getWeekNumber(new Date(myStart)),
          endWeek: getWeekNumber(
            lastSunday(tempEnd.getUTCFullYear(), tempEnd.getUTCMonth() + 1)
          )
        })

        var chi = new Date(tempDateRanges[0]["end"])
        var nextStartDate = new Date(chi.setDate(chi.getDate() + 1))

        var i = 1
        while (nextStartDate < new Date(myEnd)) {
          let confusious = new Date(
            nextStartDate.setDate(nextStartDate.getDate() + 105)
          )

          tempDateRanges.push({
            start: new Date(
              tempDateRanges[i - 1]["end"].getTime() + 24 * 60 * 60 * 1000
            ),
            end: lastSunday(
              confusious.getUTCFullYear(),
              confusious.getUTCMonth() + 1
            )
          })

          i++
        }

        tempDateRanges[tempDateRanges.length - 1]["end"] = new Date(myEnd)

        let finalRanges = []
        tempDateRanges.map((item) => {
          let tempStart = getWeekNumber(new Date(item.start)),
            tempEnd = getWeekNumber(new Date(item.end))
          finalRanges.push({
            start: formatDateNumeric(item.start),
            end: formatDateNumeric(item.end),
            startWeek: tempStart,
            endWeek: tempEnd,
            textToShow:
              moment(item.start).format("DD MMM, YY") +
              " - " +
              moment(item.end).format("DD MMM, YY"),
            textToSave:
              formatDateNumeric(item.start) + ":" + formatDateNumeric(item.end)
          })
        })
        let tempDatesToShow = [],
          tempDatesToSave = []

        finalRanges.map((item) => {
          tempDatesToShow.push(item.textToShow)
          tempDatesToSave.push(item.textToSave)
        })

        setArrayOfDatesFormatted(tempDatesToShow)
        setArrayOfDatesNumeric(tempDatesToSave)
        setCarousalDate(carousalStartDate?.dateString || tempDatesToSave[0])
      } else {
        setArrayOfDatesFormatted([
          moment(dateFilters?.startDate).format("DD MMM, YY") +
          " - " +
          moment(dateFilters?.endDate).format("DD MMM, YY")
        ])
        setArrayOfDatesNumeric([
          formatDateNumeric(convertToZoneDate(dateFilters?.startDate)) +
          ":" +
          formatDateNumeric(convertToZoneDate(dateFilters?.endDate))
        ])
        setCarousalDate(
          carousalStartDate?.dateString ||
          formatDateNumeric(convertToZoneDate(dateFilters?.startDate)) +
          ":" +
          formatDateNumeric(convertToZoneDate(dateFilters?.endDate))
        )
      }
    } else if (dateType === "Monthly") {
      let tempStartYear = convertToZoneDate(
        dateFilters?.startDate
      ).getFullYear()
      let tempEndYear = convertToZoneDate(dateFilters?.endDate).getFullYear()
      let tempYears = []
      for (var i = tempStartYear; i <= tempEndYear; i++) tempYears.push(i)

      let tempDateRanges = []
      tempYears.map((item) => {
        var theFirst = new Date(item, 0, 1).toDateString()
        var theLast = new Date(item, 11, 31).toDateString()
        tempDateRanges.push({
          start: theFirst,
          end: theLast,
          year: item
        })
      })

      tempDateRanges[0]["start"] = convertToZoneDate(dateFilters?.startDate)
      tempDateRanges[tempDateRanges.length - 1]["end"] = convertToZoneDate(
        dateFilters?.endDate
      )

      let finalRanges = [],
        tempDatesToShow = [],
        tempDatesToSave = []
      tempDateRanges.map((item) => {
        var tempYear = item.year,
          tempShow =
            formatDateNumeric(new Date(item.start)) +
            ":" +
            formatDateNumeric(new Date(item.end))

        finalRanges.push({
          start: item.start,
          end: item.end,
          textToShow: tempYear,
          textToSave: tempShow
        })

        tempDatesToShow.push(tempYear)
        tempDatesToSave.push(tempShow)
      })

      setArrayOfDatesFormatted(tempDatesToShow)
      setArrayOfDatesNumeric(tempDatesToSave)
      setCarousalDate(carousalStartDate?.dateString || tempDatesToSave[0])
    }
  }

  useEffect(() => {
    getSectionData()
    // const interval = setInterval(getSectionData, 60 * 1000)
    // return () => clearInterval(interval)
  }, [])

  // Old Code. Need to refactor it later.
  useEffect(() => {
    const data = {
      ...optionData,
      dateType
    }
    setOptionData(data)
    createCarousalDateRanges()
  }, [dateFilters, dateType, operationType])

  // Old Code. Need to refactor it later.
  useEffect(() => {
    if (dateFilters?.startDate && dateFilters?.endDate) {
      const dateDiff = DateDiffInDays(
        dateFilters?.startDate,
        dateFilters?.endDate
      )
      const temp = { ...queryParams, selectedAlertID: "" }
      if (
        queryParams?.startDateSB !== dateFilters?.startDate ||
        queryParams?.endDateSB !== dateFilters?.endDate
      ) {
        temp["dateType"] = "Raw Data"
        temp["batteryPackMetrics"] = ""
        temp["startDateSB"] = dateFilters?.startDate
        temp["endDateSB"] = dateFilters?.endDate
        handleOperationTypeChange("avg")
        handleDateTypeChange("Raw Data")
        setCarousalDate(dateFilters?.startDate, temp)
      } else {
        encryptTheParams(temp, navigate, true)
      }
      setCalendarDateDiff(dateDiff)
    }
  }, [dateFilters])

  useEffect(() => {
    const dateString =
      carousalStartDate?.dateString || queryParams?.batteryPackMetrics
    if (!dateString || !pagesContent.mounted) {
      return
    }
    if (dateType === "Raw Data" && operationType != "avg") {
      setOperationType("avg")
      return
    }
    const dates = dateString?.split(":")
    const startDate = dates?.[0] || null
    const endDate = dates?.[1] || dates?.[0]
    getChartData(startDate, endDate)
  }, [carousalStartDate, operationType, pagesContent.mounted])

  // Old Code. Need to refactor it later.
  useEffect(() => {
    const dataArray = []
    for (let i = 0; i < 4; i++) {
      let newData = {
        name: selectedMetrics?.[i]?.label,
        data: selectedMetrics?.[i]?.checked
          ? chartMetricsValue?.[selectedMetrics?.[i]?.slug] || []
          : [],
        color: selectedMetrics?.[i]?.color,
        yMin: chartMetricsValue?.ranges?.[selectedMetrics?.[i]?.slug]?.min,
        yMax: chartMetricsValue?.ranges?.[selectedMetrics?.[i]?.slug]?.max
      }

      if (selectedMetrics?.[i]?.status !== "active") {
        dataArray.push(newData)
        continue
      }

      if (selectedMetrics?.[i]?.checked) {
        newData["data"] = chartMetricsValue?.[selectedMetrics?.[i]?.slug] || []
      } else {
        newData["data"] = chartMetricsValue?.[selectedMetrics?.[i]?.slug]?.map(
          (item) => {
            return item?.x ? { x: item.x, y: null } : null
          }
        )
      }
      dataArray.push(newData)
    }

    setDataSeriesForChart({ dataArray, dateType })
  }, [chartMetricsValue, selectedMetrics])

  // Need to create styled components for the elements to remove styling from this file
  const renderKPIs = () => {
    return (
      <Box
        style={{
          width: "100%",
          marginLeft: "-10px",
          paddingBottom: "14px",
          marginTop: "14px",
          borderBottom: "1px solid #E9EFF5"
        }}
      >
        <Box
          ml={2.5}>
          <Typography
            style={{
              color: "#444B51",
              fontFamily: "Roboto",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "14.06px",
              textAlign: "left",
            }}
          >
            Last Pinged Metrics
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-end",
            justifyContent: "flex-start"
          }}
        >
          {getLiveMetricSection()}
        </Box>
      </Box >
    )
  }

  const renderMatrixSelectionArea = () => {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "38px",
          marginBottom: "12px"
        }}
      >
        <Box>
          <Box
            style={{
              display: "flex",
              paddingLeft: "44px",
              alignItems: "start"
            }}
          >
            <ChartFilterBox>
              {selectedMetrics?.map((item, index) => {
                return (
                  item?.status === "active" && (
                    <ChartFilter
                      control={
                        <Checkbox
                          key={`filterMetric${index}`}
                          style={{
                            width: "16px",
                            height: "16px",
                            padding: 0,
                            marginBottom: "2px"
                          }}
                          icon={checkBoxIcon(item.color, "15px", "15px")}
                          checkedIcon={checkedIcon(item.color, "15px", "15px")}
                          checked={item.checked}
                          onChange={(e) => {
                            onMetricFilterCheck(e.target.checked, index)
                          }}
                        />
                      }
                      label={
                        <Typography
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "16px",
                            marginLeft: "8px"
                          }}
                        >
                          {item.label}
                        </Typography>
                      }
                    />
                  )
                )
              })}
            </ChartFilterBox>
          </Box>
        </Box>
        {renderChartTopToolbar()}
      </Box>
    )
  }

  // Need to create styled components for the elements to remove styling from this file
  const renderChartTopToolbar = () => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          marginBottom: "7px",
          paddingRight: "38px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            marginRight: "11px"
          }}
        >
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {tableFilters.map((item, index) => {
              const isActive = dateType === item?.value
              const isDisabled =
                isFetchingChartData ||
                calendarDateDiff < item?.dateDiffDisableCondition
              return (
                <Tooltip placement="top" title={item.label} zIndex={2000}>
                  <Button
                    sx={{ textTransform: "none" }}
                    style={{
                      padding: 0,
                      margin: 0,
                      background:
                        calendarDateDiff < item?.dateDiffDisableCondition
                          ? "#F3F5F6"
                          : "transparent",
                      borderColor: "#E9EFF5",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderLeftWidth: index === 0 ? "1px" : "0"
                    }}
                    key={`calendarGroupingFilter${index}`}
                    onClick={() => handleDateTypeChange(item?.value)}
                    disabled={isDisabled}
                  >
                    <Box
                      style={{
                        width: "100%",
                        paddingTop: "6px",
                        paddingBottom: isActive && !isDisabled ? "1px" : "4px",
                        borderBottom: isActive && !isDisabled ? "3px solid #6D72F6" : "none",
                        borderRadius:
                          index === 0
                            ? "0 0 0 3px"
                            : index === tableFilters.length - 1
                              ? "0 0 3px 0"
                              : "none"
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: isActive
                            ? "#444B51"
                            : "#828A92",
                          lineHeight: "20px"
                        }}
                      >
                        {item.key}
                      </Typography>
                    </Box>
                  </Button>
                </Tooltip>
              )
            })}
          </ButtonGroup>
        </Box>
        <Box
          sx={{
            display: "flex",
            marginTop: "13px",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            {operations.map((item, index) => {
              const isActive = operationType === item?.value
              const isDisabled = isFetchingChartData || dateType === "Raw Data"
              return (
                <Tooltip placement="top" title={item.label} zIndex={2000}>
                  <Button
                    style={{
                      padding: 0,
                      margin: 0,
                      borderColor: "#E9EFF5",
                      background:
                        dateType === "Raw Data" ? "#F3F5F6" : "transparent",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderLeftWidth: index === 0 ? "1px" : "0"
                    }}
                    sx={{ textTransform: "none" }}
                    key={`operationsFilter${index}`}
                    onClick={() => handleOperationTypeChange(item?.value)}
                    disabled={isDisabled}
                  >
                    <Box
                      style={{
                        width: "100%",
                        paddingTop: "6px",
                        paddingBottom: isActive && !isDisabled ? "1px" : "4px",
                        borderBottom: isActive && !isDisabled ? "3px solid #6D72F6" : "none",
                        borderRadius:
                          index === 0
                            ? "0 0 0 3px"
                            : index === operations.length - 1
                              ? "0 0 3px 0"
                              : "none"
                      }}
                    >
                      <Typography
                        style={{
                          paddingLeft: "12px",
                          paddingRight: "12px",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: isDisabled
                            ? "#828A92"
                            : "#444B51",
                          lineHeight: "20px"
                        }}
                      >
                        {item.key}
                      </Typography>
                    </Box>
                  </Button>
                </Tooltip>
              )
            })}
          </ButtonGroup>
        </Box>
      </Box>
    )
  }

  const renderLoadingChartState = () => {
    return (
      <DigitalTwinChartLoader>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <PulseLoader color={"#B2AFC3"} size={30} speedMultiplier={1} />
        </Box>
        <Typography
          style={{
            color: "#B2AFC3",
            marginTop: "10px",
            fontSize: "14px"
          }}
        >
          Loading
        </Typography>
      </DigitalTwinChartLoader>
    )
  }

  const renderChartArea = () => {
    return (
      <DigitalTwinChargerVitalsChart
        xAxis={xAxis}
        setXAxis={setXAxis}
        isFetchingChartData={isFetchingChartData}
        isChartEmpty={isChartEmpty}
        dataSeries={dataSeriesForChart}
        selectedMetrics={selectedMetrics}
        optionData={optionData}
        startDate={carousalStartDate?.dateString}
        selectedAlertAnnotationID={null}
      />
    )
  }

  // Need to create styled components for the elements to remove styling from this file
  const TabContainer = ({ children, ...props }) => {
    return (
      <Box
        class-name="tab-container"
        sx={{
          ...props.style,
          borderBottom: "1px solid #CDDEEF",
          width: "100%",
          height: "100%",
          display: "flex"
        }}
      >
        <Box
          className="digital-twin-chart-section"
          style={{
            width: "100%",
            height: "100%",
            paddingBottom: "20px",
            marginLeft: "10px",
            zIndex: 0
          }}
        >
          {children}
        </Box>
      </Box>
    )
  }

  return (
    <TabContainer>
      <Box>
        {renderKPIs()}
        {renderMatrixSelectionArea()}

        <Box style={{ height: "457px" }}>
          {isFetchingChartData ? renderLoadingChartState() : renderChartArea()}

          {!isFetchingChartData && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-5px"
              }}
            >
              <Box style={{ width: "60vw" }}>
                <SlickCarousel
                  Data={arrayOfDates}
                  actualDates={arrayOfDatesNumeric}
                  setTheDate={setCarousalDate}
                  startDate={carousalStartDate?.dateString}
                  slidesToShow={dateType === "Raw Data" ? 7 : 4}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </TabContainer>
  )
}

export default ChargerVitalsTab
