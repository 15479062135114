import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPagesMetaData } from "../Api/Api";
import CODE from "../Static/Constants/StatusCodes";
import { SetSessionExpired } from "../Actions";
import { convertUTCtoZoneYYYYMMDD, getDatePickerDate, getMetricTimeStamp } from "../Helper/DatePicker/DateConverter";
import TextWithCopyIcon from "../Helper/Operations/TextWithCopyIcon";
import TextHyperLinkWithCopyIcon from "../Helper/Operations/TextHyperLinkWithCopyIcon";
import { Box, Tooltip, IconButton, Typography } from "@mui/material";
import CustomIcon from "../Components/CustomIcon"
import info from "../Assets/Images/Common/info.png";
import { getColumnTitleSort } from "../Features/Table/AntDesign/TableFunctions";
import { SetSearchConfig, SetSearchType } from "../Actions/Search";
import { decryptTheParams, encryptTheParams, updateQueryParams } from "../Helper/QueryParams/EncryptDecrypt";
import { getFormattedResolutionTime } from "../Helper/DatePicker/DateFormatters";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

export const usePageMeta = (pageName) => {    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pagesContent, setPagesContent] = useState({
    pageName,
    batteryType: "",
    mounted: false,
  });

  useEffect(() => {
    getPageMeta();
  }, []);

  const getPageMeta = async () => {
    const res = await getPagesMetaData(pageName);
    let filterConfigurations = {};
    let columns = [];
    let searchSelectConfig = {};
    let pageHeader = "";
    let tabsConfig = []
    const queryParams = decryptTheParams()
    let pageMeta = {}
    if (res && res?.responseStatus?.code === CODE.SUCCESS) {
      const pageData = res.response?.response
      pageMeta = pageData?.metaInfo || {}
      const tablesData = pageData?.metaInfo?.table
      filterConfigurations = pageData.metaInfo?.filterConfigurations
      pageHeader = pageData.metaInfo?.pageHeader
      tabsConfig = pageData.metaInfo?.tabsConfig || []
      searchSelectConfig = pageData.metaInfo?.searchSelectOptions
      if (searchSelectConfig) {
        dispatch(SetSearchConfig(searchSelectConfig))
        dispatch(SetSearchType(queryParams.selectedSearchType || searchSelectConfig?.defaultValue))
      }
      columns = []
      if (tablesData) {
        columns = initializeTableColumns(tablesData)
      }
    } else if (res?.responseStatus?.code === CODE.SESSION_EXPIRED) {
      SetSessionExpired(true);
      return null;
    }

    setPagesContent({
      ...pagesContent,
      pageMeta: pageMeta,
      pageHeader, pageHeader,
      filterConfigurations: filterConfigurations,
      searchSelectConfig: searchSelectConfig,
      columns: columns,
      tabsConfig: tabsConfig,
      mounted: true
    });
  };

  function initializeTableColumns(tablesData) {
    const columnsConfig = [];
    Object.keys(tablesData).map((tableKey) => {
      const config = tablesData[tableKey] || [];
      columnsConfig.push({
        name: tableKey,
        columns: getColumnsConfig(config)
      })
    })

    return columnsConfig
  }

  const getColumnsConfig = (tableData) => {
    const columnOrdering = tableData?.columnOrdering || []
    const columnsConfig = []
    columnOrdering.map((columnKey) => {
      const config = tableData.columnsConfig?.[columnKey]
      if (!config) {
        return null
      }
      let sorting = config?.sorter
      if (config.enableDefaultSorting) {
        sorting = (a, b) => new Date(a[config.dataIndex]) - new Date(b[config.dataIndex])
      }
      columnsConfig.push({
        dataIndex: config?.dataIndex,
        title: titleProps => getColumnTitleFromTitleType(
          config.title, 
          config?.subtitle,
          titleProps, 
          config.titleRenderingType, 
          config?.dataIndex, 
          config?.sorter,
          config?.icon
        ),
        fixed: config?.fixed,
        key: config?.key || columnKey,
        sorter: sorting || false,
        defaultSortOrder: config?.defaultSortOrder || "",
        showSorterTooltip: config?.showSorterTooltip || false,
        sortDirections: config?.sortDirections,
        filterMode: config?.filterMode || "tree",
        filterSearch: config?.filterSearch || false,
        decimalPlaces: config?.decimalPlaces || 0,
        uniqueKey: columnKey+"UniqueKey",
        dbColumnKey: config?.dbColumnKey,
        align: config?.align || "left",
        width: config?.width || 170,
        render: (value, record) => getColumnValueFromValueType(
          record,
          config
        ),
        show: config?.tabs,
      })
    })

    return columnsConfig
  }

  const getCallbackFunction = (columnType) => {
    if (pageName === 'alerts' && columnType === 'digital-twin-link') {
      return (record) => {
        const cookies = new Cookies()
        let newQueryParams;
        newQueryParams = {
          batteryID: record.batteryID,
          deviceID: record?.deviceID,
          batterySearchedGlobal: "false",
          hierarchyLevel: "pack",
          startDateSB: convertUTCtoZoneYYYYMMDD(record.startTime).substring(0, 10),
          tabValue: "batteryVitals",
          endDateSB: getDatePickerDate(),
          oldTimeZone: cookies.get('timeZone'),
          sidebarTabValue: "alertTab",
          showAnnotation: "true",
          navigateFromTab: true,
          selectedAlertAnnotationColor: 'red'
        };

        // Todo: Make it generic
        if (cookies.get('orgName') !== 'Vammo') {
          newQueryParams = {
            ...newQueryParams,
            selectedHierarchy: "battery",
            hierarchyLevel: "battery",
          }
        }

        if (record.alertStatus === 'resolved') {
          let timestamp = new Date(
            convertUTCtoZoneYYYYMMDD(record.startTime)
          ).getTime();
          const intervalInMinutes = 30 * 60 * 1000;
          const alertAnnotationID =
            Math.floor(timestamp / intervalInMinutes) * intervalInMinutes;

          let selectedHierarchy = ""

          switch (record.hierarchy) {
            case "module":
              selectedHierarchy = record.modules?.sort()?.slice(0, 20)?.join(",");
              break;
            case "cell":
              selectedHierarchy = record.cells?.sort()?.slice(0, 20)?.join(",");
              break;
            case "battery":
              selectedHierarchy = ['battery']
          }

          newQueryParams = {
           ...newQueryParams,
            selectedHierarchy: selectedHierarchy,
            hierarchyLevel: record.hierarchy,
            alertAnnotationID: alertAnnotationID,
            selectedAlertAnnotationColor: '#229087'
          }
        }
    
        const expandAccordionTabForStatus = ['active', 'open']
        if (expandAccordionTabForStatus.includes(record.alertStatus?.toLowerCase())) {
          newQueryParams = {
            ...newQueryParams,
            expandedAccordionName: 'alertGroup' + record?.alertDescription?.toLowerCase()?.replace(/ /g, "-"),
            redirectedFromAlertPage: "true"
          }
        }
    
        updateQueryParams(navigate, newQueryParams, true);
      } 
    }
    
    // Handle the case when alerts tab is disabled on the digital twin page
    if (pageName === 'alerts' && columnType === 'digital-twin-link-no-alerts') {
      return (record) => {
        const cookies = new Cookies()
        let newQueryParams;
        newQueryParams = {
          batteryID: record.batteryID,
          deviceID: record?.deviceID,
          tabValue: "batteryVitals",
          startDateSB: record.startTime.substring(0, 10),
          oldTimeZone: cookies.get('timeZone'),
          endDateSB: getDatePickerDate(),
          navigateFromTab: true,
        };
    
        updateQueryParams(navigate, newQueryParams, true);
      } 
    }

    if (columnType === 'digital-twin-link') {
      return (record) => {
        const cookies = new Cookies()
        let newQueryParams;
        newQueryParams = {
          batteryID: record.batteryID,
          deviceID: record?.deviceID,
          tabValue: "batteryVitals",
          oldTimeZone: cookies.get('timeZone'),
          navigateFromTab: true
        };
    
        encryptTheParams(newQueryParams, navigate, true);
      } 
    }

    return () => {}
  }

  const getColumnTitleFromTitleType = (title, subtitle, titleProps, type, key, sorter, icon) => {
    if (type === 'bold-text' && sorter === true) {
      return getColumnTitleSort(titleProps, key, title)
    }

    if (type === "text-with-subtitle" && sorter === true) {
      return getColumnTitleSort(
        titleProps,
        key,
        title,
        subtitle
      )
    }
    
    if (type === "text-with-subtitle") {
      return <Box>
        <Typography variant="tableHeadBold">{title}</Typography>
        <Typography
          style={{
            fontSize: "11px",
            color: "#828A92"
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {subtitle}
        </Typography>
      </Box>
    }
    
    if (type === 'bold-text') {
      return <Typography variant="tableHeadBold">{title}</Typography>
    }

    if (type === "text-with-icon") {
      let iconComponent
      switch (icon?.type) {
        case "info":
        default:
          iconComponent = info
      }
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="tableHeadBold">{title}</Typography>
          <Tooltip
            placement={icon?.tooltip?.placement}
            title={
              <Box display="flex" flexDirection="column">
                {icon?.tooltip?.textLines?.map((line) => (
                  <h2>{line}</h2>
                ))}
              </Box>
            }
            zIndex={2000}
          >
            <IconButton>
              <img src={iconComponent} height={15} width={15} />
            </IconButton>
          </Tooltip>
        </Box>
      )
    }
  }

  const getColumnValueFromValueType = (record, config) => {
    const value = record?.[config?.dataIndex];
    const decimalPlaces = config?.decimalPlaces
    const type = config?.valueRenderingType
    const copyToClipBoardTypeName = config?.copyToClipBoardTypeName

    switch(type) {
      case 'ping-status-icon':
        return <CustomIcon name={value === "Active" ? "online" : "offline"} style={{ width: "24px" }} />;
      case 'text':
        return <Typography variant="tableRow">{value}</Typography>;
      case 'text-with-copy':
        return <div className="renderID">{TextWithCopyIcon(value, copyToClipBoardTypeName)}</div>;
      case 'number':
        return <Typography variant="tableRow">{value?.toFixed(decimalPlaces)}</Typography>;
      case 'timestamp':
        return <Typography variant="tableRow">{value ? getMetricTimeStamp(value) : ""}</Typography>;
      case 'digital-twin-list-series-parallel':
        const level = config?.seriesParallelConfigLevel
        const tableValue = level === "pack" 
                            ? `${record?.seriesPacks}S / ${record?.parallelPacks}P` 
                            : level === "module"
                              ? `${record?.seriesModulesInPack}S / ${record?.parallelModulesInPack}P`
                              : `${record?.seriesModulesInPack * record?.seriesCellsInModule}S / ${record?.parallelModulesInPack * record?.parallelCellsInModule}P`
        return <Typography variant="tableRow">{tableValue}</Typography>;
      case 'digital-twin-list-edit':
        return getCallbackFunction(type);
      case 'battery-configuration-edit':
        return getCallbackFunction(type);
      case 'digital-twin-link':
        return <div className="renderID">{TextHyperLinkWithCopyIcon(value, "Device ID", getCallbackFunction(type), record)}</div>;
      case 'digital-twin-link-no-alerts':
        return <div className="renderID">{TextHyperLinkWithCopyIcon(value, "Device ID", getCallbackFunction(type), record)}</div>;
      case 'battery-config-link':
        return <div className="renderID">{TextHyperLinkWithCopyIcon(value, "Config Name", getCallbackFunction(type), record)}</div>;
      case 'age':
        return <Typography variant="tableRow">{Number.isFinite(value) ? (value/30).toFixed(decimalPlaces) : ""}</Typography>
      case 'duration':
        return <Typography variant="tableRow">{getFormattedResolutionTime(value)}</Typography>
      case 'hours':
        return <Typography variant="tableRow">{Number.isFinite(value) ? (value/60).toFixed(decimalPlaces) : ""}</Typography>
      default:
        return null;
    }
  }

  return { pagesContent };
};
