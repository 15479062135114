const initialPassChanged = {
  value: false,
};

const PassChangedSuccess = (state = initialPassChanged, action) => {
  switch (action.type) {
    case "PassChangedSuccess":
      return {
        value: action.passChanged,
      };
    default:
      return state;
  }
};

export { PassChangedSuccess };
