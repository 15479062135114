import { ThemeProperties } from "../../../Theme/ThemeProperties"
const Tabs = [
    {
        id:1,
        name:"Active",
        count:351,
        color:ThemeProperties.red_400
    },
    // {
    //     id:2,
    //     name:"Open Errors",
    //     count:65,
    //     color:ThemeProperties.blue_400
    // },
    {
        id:2,
        name:"Resolved",
        count:65,
        color:ThemeProperties.green_400
    },
    {
        id:3,
        name:"Expired",
        count:65,
        color:ThemeProperties.pink_100
    },

    // {
    //     id:4,
    //     name:"Analytics",
    //     count:65,
    //     color:ThemeProperties.purple
    // }
]

export default Tabs