import { Box, Dialog, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OutlinedButton } from "../../../Components/CustomButton";

function DigitalTwinConfirmDialog({
  open,
  onConfirm,
  handleCloseDialog,
  message,
  confirmButtonText,
  confirmButtonColor,
  discardButtonText,
  confirmConfig,
  handleChangeSelect,
  form,
}) {
  return (
    <div>
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        width={500}
        // anchorEl={props.anchorElSendFeedbackDialog}
        // container={props.anchorElSendFeedbackDialog.parentNode}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 12,
            }}
          >
            <Box mx={5} mt={2}>
              <Typography variant="paperHeader" fontSize={15}>
                {message}
              </Typography>
            </Box>
          </div>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              py: 2,
              pr: 2,
              gap: 2,
            }}
          >
            <OutlinedButton variant="outlined" onClick={handleCloseDialog}>
              <Typography variant="textSubtitle">
                {discardButtonText}
              </Typography>
            </OutlinedButton>
            <OutlinedButton
              variant="contained"
              color={confirmButtonColor}
              onClick={onConfirm}
            >
              <Typography variant="textSubtitleWhite">
                {confirmButtonText}
              </Typography>
            </OutlinedButton>
          </Box>
        </div>
      </Dialog>
    </div>
  );
}

export default DigitalTwinConfirmDialog;
