import { convertZonetoUTCEnd, convertZonetoUTCStart } from "../Helper/DatePicker/DateConverter"
import { nonMatchingFilters } from "../Helper/Operations/GetFilterKeyValue";
import { GetData } from "./Functions/SettingData"
import qs from "qs";

const getMetricInfo = async (metricName, startDate, endDate, selectedFilters, defaultFilterValues) => {
  var config = {
    method: "get",
    url: `/api/alert_analytics/metrics_info`,
    headers: {
      "Content-Type": "application/json"
    },
    params: {
      metricName,
      startDate: convertZonetoUTCStart(startDate),
      endDate: convertZonetoUTCEnd(endDate),
      filters: nonMatchingFilters(selectedFilters, defaultFilterValues)
    },
  }

  let data = GetData(config)
  return data
}

const getMetricTrendData = async (metricName, startDate, endDate, granularity, selectedFilters, defaultFilterValues) => {
  const filterKeys = Object.keys(selectedFilters)
  if (filterKeys.includes('safetyAlerts') || filterKeys.includes('violationAlerts') || filterKeys.includes('operationalAlerts')){
    selectedFilters = {
      ...selectedFilters,
      alertMetaID: [
        ...(selectedFilters?.['safetyAlerts'] || []), 
        ...(selectedFilters?.['violationAlerts'] || []), 
        ...(selectedFilters?.['operationalAlerts'] || []), 
      ]
    }
  }
 
  var config = {
    method: "get",
    url: `/api/alert_analytics/metrics_trend`,
    headers: {
      "Content-Type": "application/json"
    },
    params: {
      metricName: Array.isArray(metricName) ? metricName : [metricName],
      granularity,
      startDate: convertZonetoUTCStart(startDate),
      endDate: convertZonetoUTCEnd(endDate),
      filters: JSON.stringify(nonMatchingFilters(selectedFilters, defaultFilterValues))
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  }

  let data = GetData(config)
  return data
}

const getMetricTableData = async (metricNames, startDate, endDate, selectedFilters, defaultFilterValues, pageNumber, rowsPerPage, sort) => {
  const filterKeys = Object.keys(selectedFilters)
  if (filterKeys.includes('safetyAlerts') || filterKeys.includes('violationAlerts') || filterKeys.includes('operationalAlerts')){
    selectedFilters = {
      ...selectedFilters,
      alertMetaID: [
        ...(selectedFilters?.['safetyAlerts'] || []), 
        ...(selectedFilters?.['violationAlerts'] || []), 
        ...(selectedFilters?.['operationalAlerts'] || []), 
      ]
    }
  }
  
  var config = {
    method: "get",
    url: `/api/alert_analytics/battery_alerts/${pageNumber}`,
    headers: {
      "Content-Type": "application/json"
    },
    params: {
      metricsGroups: Array.isArray(metricNames) ? metricNames : [metricNames],
      startDate: convertZonetoUTCStart(startDate),
      endDate: convertZonetoUTCEnd(endDate),
      filters: JSON.stringify(nonMatchingFilters(selectedFilters, defaultFilterValues)),
      pageCount: rowsPerPage,
      sort: JSON.stringify(sort)
    },
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  }

  let data = GetData(config)
  return data
}

export {
  getMetricInfo,
  getMetricTrendData,
  getMetricTableData
}
