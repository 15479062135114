import React, { useEffect, useState } from "react";
import {
  GetTheGranularityTrend,
  GetTheMetric,
} from "../_DownloadCSV/CSVDownload";
import { PaperHighlight } from "../../../../Components/CustomPaper";

import Header from "../../../../Features/Header/Header";
import CODE from "../../../../Static/Constants/StatusCodes";
import Events from "../../../../Analytics/Events";
import TimeSeriesSingleAxis from "../../../../Charts/Line/TimeSeriesSingleAxis";
import {
  convertToZoneDate,
  getChartTimeStamp,
} from "../../../../Helper/DatePicker/DateConverter";
import {
  getGranularityData,
  getPackComparision,
} from "../../../../Api/DigitalTwin";
import {
  formatDateWithoutYear,
  monthDictionary,
} from "../../../../Helper/DatePicker/DateFormatters";
import { ThemeProperties } from "../../../../Theme/ThemeProperties";
import { metrics } from "./_Constants";

function Energy(props) {
  const hierarchy = props.hierarchy;

  const [energyData, setEnergyData] = useState({
    data: { metric: [], timeStamp: [] },
    responseStatus: {
      code: -1,
      msg: "Loading",
    },
  });
  const [energyCSV, setEnergyCSV] = useState({
    data: [],
    responseStatus: { code: 0 },
  });

  function GetEnergyCSV() {
    setEnergyCSV({
      data: [],
      responseStatus: {
        code: -1,
      },
    });
    let obj =
      props.granular === "Raw Data" &&
      props.startDate.toLowerCase().indexOf(":") === -1
        ? GetTheMetric(
            props.batteryID,
            hierarchy.selectedPack.value?.toUpperCase(),
            hierarchy.selectedModule.value?.toUpperCase(),
            hierarchy.selectedCell.value?.toUpperCase(),
            hierarchy.level,
            "energy",
            [props.dateString[0], props.dateString[1]],
            "Energy (kWh)"
          )
        : GetTheGranularityTrend(
            props.batteryID,
            hierarchy.selectedPack.value,
            hierarchy.selectedModule.value,
            hierarchy.selectedCell.value,
            hierarchy.level,
            ["energy"],
            [props.startDate.split(":")[0], props.startDate.split(":")[1]],
            props.granular,
            props.operationType,
            "Energy (kWh)",
            "Single"
          );
    obj.then((res) => {
      if (res?.responseStatus?.code === CODE.SUCCESS)
        setEnergyCSV({
          data: res?.data,
          responseStatus: {
            code: res?.responseStatus?.code,
          },
        });
      else
        setEnergyCSV({
          data: [],
          responseStatus: {
            code: res?.responseStatus?.code,
          },
        });
    });
  }

  useEffect(() => {
    setEnergyData({
      data: { metric: [], timeStamp: [] },
      responseStatus: {
        code: -1,
        msg: "Loading",
      },
    });

    if (props.startDate !== "") {
      if (
        props.granular === "Raw Data" &&
        props.startDate.toLowerCase().indexOf(":") === -1
      ) {
        getPackComparision(
          props.batteryID,
          hierarchy.selectedPack.value?.toUpperCase(),
          hierarchy.selectedModule.value?.toUpperCase(),
          hierarchy.selectedCell.value?.toUpperCase(),
          hierarchy.level,
          "energy",
          [props.startDate, props.startDate]
        ).then((res) => {
          if (res?.responseStatus?.code === CODE.SUCCESS) {
            let tempData = [];
            res?.response.batteryData?.map((item) => {
              tempData.push({
                x: getChartTimeStamp(item.batteryDateTime, true),
                y:
                  item["metricsArray"].find((i) => i.type === "energy")
                    ?.value === undefined
                    ? null
                    : item["metricsArray"].find((i) => i.type === "energy")
                        ?.value *
                      metrics.find((item) => item.value === "energy")
                        ?.multiplyBy,
              });
            });
            setEnergyData({
              data: tempData,
              responseStatus: {
                code: res?.responseStatus?.code,
                msg: res?.responseStatus?.msg,
              },
            });
          } else {
            setEnergyData({
              data: [],
              responseStatus: {
                code: res?.responseStatus?.code,
                msg: res?.responseStatus?.msg,
              },
            });
          }
        });
      } else if (
        props.granular !== "Raw Data" &&
        props.startDate.toLowerCase().indexOf(":") !== -1
      ) {
        let date = props.startDate.split(":");

        getGranularityData(
          props.batteryID,
          hierarchy.selectedPack.value,
          hierarchy.selectedModule.value,
          hierarchy.selectedCell.value,
          hierarchy.level,
          ["energy"],
          [date[0], date[1]],
          props.granular,
          props.operationType
        ).then((res) => {
          let tempData = [];
          res?.response?.batteryData?.map((item) => {
            tempData.push({
              x:
                props.granular === "Daily"
                  ? formatDateWithoutYear(
                      convertToZoneDate(item["range"]["startDate"])
                    )
                  : props.granular === "Monthly"
                  ? monthDictionary.find(
                      (o) => o.name === item["range"]["startDate"].split("-")[1]
                    )["value"]
                  : [
                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["startDate"])
                        ) + " - ",
                      ],

                      [
                        formatDateWithoutYear(
                          convertToZoneDate(item["range"]["endDate"])
                        ),
                      ],
                    ],
              y:
                res?.responseStatus?.code === CODE.SUCCESS
                  ? item["metricsArray"][0]["value"] *
                    metrics.find((item) => item.value === "energy")?.multiplyBy
                  : null,
            });
          });

          setEnergyData({
            data: tempData,
            responseStatus: {
              code: res?.responseStatus?.code,
              msg: res?.responseStatus?.msg,
            },
          });
        });
      }
    }
  }, [
    props.granular,
    props.operationType,
    props.startDate,
    hierarchy.selectedPack.value,
    hierarchy.selectedModule.value,
    hierarchy.selectedCell.value,
    hierarchy.level,
  ]);

  return (
    <PaperHighlight
      elevation={0}
      onMouseEnter={() => Events("SB Hovered Energy Chart")}
    >
      <Header
        heading={props.generateHeading("Energy")}
        description="Showing Energy"
        showIButton={true}
        showThreeDots={false}
        showCSVDownload={true}
        csvData={energyCSV}
        SetCSVData={GetEnergyCSV}
        setStatusBack={setEnergyCSV}
        id="Energy"
        page="specificBattery"
      />
      <div className="section" id="Energy">
        <TimeSeriesSingleAxis
          data={{
            series: [
              {
                name: "Energy (kWh)",
                data: energyData.data,
              },
            ],
            colors: [ThemeProperties.Energy],
            xaxisType: props.granular === "Raw Data" ? "datetime" : "category",

            yAxisTitle: "Energy (kWh)",
            yAxisColor: ThemeProperties.Energy,
            ymin: metrics.find((item) => item.value === "energy")?.ymin,
            ymax: metrics.find((item) => item.value === "energy")?.ymax,
            yAxisDecimalPlaces: metrics.find((item) => item.value === "energy")
              ?.yAxisDecimalPlaces,

            toolTipDecimalPlaces: 3,

            responseStatus: {
              code: energyData.responseStatus.code,
              msg: energyData.responseStatus.msg,
            },
          }}
          startDate={props.startDate}
          granular={props.granular}
        />
      </div>
    </PaperHighlight>
  );
}

export default Energy;
