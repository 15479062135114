import { useSelector } from "react-redux";
import {
  Box,
  FormGroup,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { OutlinedInputNumber } from "../../../Components/CustomTextField";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import "../Form.css";

function ConfigFormBatteryStructure({
  isEdit,
  selectedConfig,
  form,
  handleChangeSelect,
  formValidated,
}) {
  const buildingBlockTypes = useSelector(
    (state) => state.DigitalTwinsConfigFilters.buildingBlockType
  );
  return (
    <FormGroup>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="space-around"
        margin={3}
        rowGap={2}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: "auto",
              fontWeight: "bold",
              color: ThemeProperties.black,
            }}
          >
            What are the building blocks to your Energy Storage?{" "}
            <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Select
            style={{ width: "400px", fontSize: "small" }}
            size="small"
            variant="outlined"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            value={form?.buildingBlockType}
            name="buildingBlockType"
          >
            <MenuItem sx={{ fontSize: "small" }} value={"pmc"}>
              {"Cells > Modules > Packs > Energy storage"}
            </MenuItem>
            <MenuItem sx={{ fontSize: "small" }} value={"pc"}>
              {"Cells > Packs > Energy storage"}
            </MenuItem>
          </Select>
          {!formValidated?.buildingBlockType && (
            <Typography color="red">This field is required</Typography>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 300,
              fontWeight: "bold",
              color: ThemeProperties.black,
            }}
          >
            Let's define count of the building blocks{" "}
            <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Box display="flex" flexDirection="row" gap={3}>
            <Box display="flex" flexDirection="column" gap={1}>
              <label># cells</label>
              <TextField
                style={{ width: "150px" }}
                inputProps={{
                  style: { fontSize: 14, padding: 7, marginLeft: 3 },
                  min: 0,
                }}
                size="small"
                type="number"
                variant="outlined"
                name="cellCount"
                value={form?.cellCount}
                onChange={handleChangeSelect}
                disabled={!isEdit}
              />
              {!formValidated?.cellCount && (
                <Typography color="red">This field is required</Typography>
              )}
            </Box>
            {form?.buildingBlockType !== "pc" && (
              <Box display="flex" flexDirection="column" gap={1}>
                <label># modules</label>
                <TextField
                  style={{ width: "150px" }}
                  size="small"
                  type="number"
                  variant="outlined"
                  name="moduleCount"
                  value={form?.moduleCount}
                  onChange={handleChangeSelect}
                  disabled={!isEdit}
                  inputProps={{
                    style: { fontSize: 14, padding: 7, marginLeft: 3 },
                    min: 0,
                  }}
                />
                {!formValidated?.moduleCount && (
                  <Typography color="red">This field is required</Typography>
                )}
              </Box>
            )}
            <Box display="flex" flexDirection="column" gap={1}>
              <label># packs</label>
              <TextField
                style={{ width: "150px" }}
                size="small"
                type="number"
                variant="outlined"
                name="packCount"
                value={form?.packCount}
                onChange={handleChangeSelect}
                disabled={!isEdit}
                inputProps={{
                  style: { fontSize: 14, padding: 7, marginLeft: 3 },
                  min: 0,
                }}
              />
              {!formValidated?.packCount && (
                <Typography color="red">This field is required</Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 300,
              fontWeight: "bold",
              color: ThemeProperties.black,
            }}
          >
            How are they arranged in each level ?{" "}
            <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Box display="flex" flexDirection="row" gap={3}>
            <Box display="flex" flexDirection="column" gap={1}>
              <label>
                Cells in a{" "}
                {form?.buildingBlockType === "pmc" ? "module" : "pack"}
              </label>
              <Box display="flex" flexDirection="row" gap={1}>
                <Box>
                  <OutlinedInputNumber
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment position="end">S</InputAdornment>
                    }
                    inputProps={{
                      "aria-label": "S",
                      style: { fontSize: 14, padding: 7, marginLeft: 3 },
                      min: 0,
                    }}
                    size="small"
                    style={{ width: "70px" }}
                    type="number"
                    name="seriesCellsInModule"
                    value={form?.seriesCellsInModule}
                    onChange={handleChangeSelect}
                    disabled={!isEdit}
                  />
                </Box>
                <OutlinedInputNumber
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">P</InputAdornment>
                  }
                  inputProps={{
                    "aria-label": "P",
                    style: { fontSize: 14, padding: 7, marginLeft: 3 },
                    min: 0,
                  }}
                  size="small"
                  style={{ width: "70px" }}
                  type="number"
                  name="parallelCellsInModule"
                  value={form?.parallelCellsInModule}
                  onChange={handleChangeSelect}
                  disabled={!isEdit}
                />
              </Box>
              {(!formValidated?.seriesCellsInModule ||
                !formValidated.parallelCellsInModule) && (
                <Typography color="red">This field is required</Typography>
              )}
            </Box>
            {form?.buildingBlockType !== "pc" && (
              <Box display="flex" flexDirection="column" gap={1}>
                <label>Modules in a pack</label>
                <Box display="flex" flexDirection="row" gap={1}>
                  <OutlinedInputNumber
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment position="end">S</InputAdornment>
                    }
                    inputProps={{
                      "aria-label": "S",
                      style: { fontSize: 14, padding: 7, marginLeft: 3 },
                      min: 0,
                    }}
                    size="small"
                    style={{ width: "70px" }}
                    type="number"
                    name="seriesModulesInPack"
                    value={form?.seriesModulesInPack}
                    onChange={handleChangeSelect}
                    disabled={!isEdit}
                  />
                  <OutlinedInputNumber
                    id="outlined-adornment-weight"
                    endAdornment={
                      <InputAdornment position="end">P</InputAdornment>
                    }
                    inputProps={{
                      "aria-label": "P",
                      style: { fontSize: 14, padding: 7, marginLeft: 3 },
                      min: 0,
                    }}
                    size="small"
                    style={{ width: "70px" }}
                    type="number"
                    name="parallelModulesInPack"
                    value={form?.parallelModulesInPack}
                    onChange={handleChangeSelect}
                    disabled={!isEdit}
                  />
                </Box>
                {(!formValidated?.parallelModulesInPack ||
                  !formValidated.seriesModulesInPack) && (
                  <Typography color="red">This field is required</Typography>
                )}
              </Box>
            )}
            <Box display="flex" flexDirection="column" gap={1}>
              <label>Pack in the Energy Storage</label>
              <Box display="flex" flexDirection="row" gap={1}>
                <OutlinedInputNumber
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">S</InputAdornment>
                  }
                  inputProps={{
                    "aria-label": "S",
                    style: { fontSize: 14, padding: 7, marginLeft: 3 },
                    min: 0,
                  }}
                  size="small"
                  style={{ width: "70px" }}
                  type="number"
                  name="seriesPacks"
                  value={form?.seriesPacks}
                  onChange={handleChangeSelect}
                  disabled={!isEdit}
                />
                <OutlinedInputNumber
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">P</InputAdornment>
                  }
                  inputProps={{
                    "aria-label": "P",
                    style: { fontSize: 14, padding: 7, marginLeft: 3 },
                    min: 0,
                  }}
                  size="small"
                  style={{ width: "70px" }}
                  type="number"
                  name="parallelPacks"
                  value={form?.parallelPacks}
                  onChange={handleChangeSelect}
                  disabled={!isEdit}
                />
              </Box>
              {(!formValidated?.parallelPacks ||
                !formValidated.seriesPacks) && (
                <Typography color="red">This field is required</Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </FormGroup>
  );
}

export default ConfigFormBatteryStructure;
