import { SvgIcon } from "@mui/material"
import React from "react"

const ChartSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" fill="none" />
        <path
          d="M3.33325 17.4238H18.1817"
          stroke="#F89F35"
          stroke-width="1.09091"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.08716 4.06052V17.4241H12.4281V4.06052C12.4281 3.24386 12.094 2.57568 11.0917 2.57568H10.4235C9.42125 2.57568 9.08716 3.24386 9.08716 4.06052Z"
          stroke="#F89F35"
          stroke-width="1.09091"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.04537 8.51511C7.04537 7.69845 6.7484 7.03027 5.85749 7.03027H5.26356C4.37265 7.03027 4.07568 7.69845 4.07568 8.51511V17.4242H7.04537V11.4774"
          stroke="#F89F35"
          stroke-width="1.09091"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.4697 12.2275V17.4245H17.4394V12.2275C17.4394 11.4109 17.1424 10.7427 16.2515 10.7427H15.6576C14.7667 10.7427 14.4697 11.4109 14.4697 12.2275Z"
          stroke="#F89F35"
          stroke-width="1.09091"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

ChartSvg.defaultProps = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  stroke: ""
}

export default ChartSvg
