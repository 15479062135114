import { SvgIcon } from "@mui/material"
import React from "react"

const CalendarIconSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.8123 2.83341H11.6873V1.77091C11.6873 1.67698 11.65 1.5869 11.5836 1.52048C11.5172 1.45406 11.4271 1.41675 11.3332 1.41675C11.2392 1.41675 11.1492 1.45406 11.0827 1.52048C11.0163 1.5869 10.979 1.67698 10.979 1.77091V2.83341H6.02067V1.77091C6.02067 1.67698 5.98336 1.5869 5.91694 1.52048C5.85052 1.45406 5.76044 1.41675 5.6665 1.41675C5.57257 1.41675 5.48249 1.45406 5.41607 1.52048C5.34965 1.5869 5.31234 1.67698 5.31234 1.77091V2.83341H3.18734C2.71786 2.83398 2.26776 3.02073 1.93579 3.3527C1.60382 3.68467 1.41707 4.13477 1.4165 4.60425V13.8126C1.41707 14.2821 1.60382 14.7322 1.93579 15.0641C2.26776 15.3961 2.71786 15.5829 3.18734 15.5834H13.8123C14.2819 15.583 14.7321 15.3963 15.0641 15.0643C15.3961 14.7323 15.5828 14.2821 15.5832 13.8126V4.60425C15.5828 4.13471 15.3961 3.68451 15.0641 3.35249C14.7321 3.02048 14.2819 2.83379 13.8123 2.83341ZM14.8748 13.8126C14.8748 14.0944 14.7629 14.3646 14.5636 14.5639C14.3644 14.7631 14.0941 14.8751 13.8123 14.8751H3.18734C2.90554 14.8751 2.63529 14.7631 2.43604 14.5639C2.23678 14.3646 2.12484 14.0944 2.12484 13.8126V7.79175H14.8748V13.8126ZM14.8748 7.08342H2.12484V4.60425C2.12484 4.01775 2.59942 3.54175 3.18734 3.54175H5.31234V4.60425C5.31234 4.69818 5.34965 4.78826 5.41607 4.85468C5.48249 4.9211 5.57257 4.95842 5.6665 4.95842C5.76044 4.95842 5.85052 4.9211 5.91694 4.85468C5.98336 4.78826 6.02067 4.69818 6.02067 4.60425V3.54175H10.979V4.60425C10.979 4.69818 11.0163 4.78826 11.0827 4.85468C11.1492 4.9211 11.2392 4.95842 11.3332 4.95842C11.4271 4.95842 11.5172 4.9211 11.5836 4.85468C11.65 4.78826 11.6873 4.69818 11.6873 4.60425V3.54175H13.8123C14.0941 3.54175 14.3644 3.65369 14.5636 3.85295C14.7629 4.0522 14.8748 4.32246 14.8748 4.60425V7.08342Z" fill={stroke}/>
      </svg>
    </SvgIcon>
  )
}

CalendarIconSvg.defaultProps = {
  width: "17",
  height: "17",
  viewBox: "0 0 17 17",
  fill: "none",
  stroke: "#444B51"
}

export default CalendarIconSvg
