import Cookies from "universal-cookie";

const cookies = new Cookies();

export const RemoveCookies = () => {
  cookies.remove("name", { path: "/" });
  cookies.remove("sessionId", { path: "/" });
  cookies.remove("access_token", { path: "/" });
  cookies.remove("pages", { path: "/" });
  cookies.remove("pagesMeta", { path: "/" });
  cookies.remove("navigationCard", { path: "/" });
  cookies.remove("orgName", { path: "/" });
  cookies.remove("amplitudeID", { path: "/" });
  cookies.remove("timeZone", { path: "/" });
  cookies.remove("isAutomaticTimezone", { path: "/" });
  cookies.remove("fleetID", { path: "/" });
  cookies.remove("finalCall", { path: "/" });
  cookies.remove("locationFilter", { path: "/" });
  cookies.remove("orgFilter", { path: "/" });
  localStorage.removeItem("LocationFilter");
  localStorage.removeItem("OrgFilter");
};

export const NavigationFunctionality = () => {
  let result, p;
  if (window.performance.getEntriesByType("navigation")) {
    p = window.performance.getEntriesByType("navigation")[0].type;

    if (p === "navigate") {
      result = "navigate";
    }
    if (p === "reload") {
      result = "reloady";
    }
    if (p === "back_forward") {
      result = "back_forward";
    }
    if (p === "prerender") {
      result = "prerender";
    } //3 is my invention!
  }
  return result;
};

export const IsSessionExpired = () =>
{
  const data = NavigationFunctionality();
    if (data === "navigate") {
      RemoveCookies();
      window.location.reload();
    }
}
