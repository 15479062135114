const initialUserState = {
    // sortBy: "socBMS",
    orderBy: "desc"
  };
  
  const CustomOrder = (state = initialUserState, action) => {
      
    switch (action.type) {
      case "ColumnOrder":
        return {
          orderBy: action.order,
      };
      default:
        return state;
    }
  };
  
  
  export default CustomOrder
