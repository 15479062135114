import SOC_Low from "../../Assets/Icons/Map/Icons/Tower_low_soc.png";
import SOC_Medium from "../../Assets/Icons/Map/Icons/Tower_medium_soc.png";
import SOC_High from "../../Assets/Icons/Map/Icons/Tower_high_soc.png";
import TowerAlert from "../../Assets/Icons/Map/Icons/Tower_bms_error.png";

function FindMapIcon(alert, soc) {
  let finalIcon;

  if (soc < 30) finalIcon = SOC_Low;
  else if (soc >= 30 && soc < 50) finalIcon = SOC_Medium;
  else if (soc >= 50) finalIcon = SOC_High;

  if(alert>0)
  finalIcon = TowerAlert

  return finalIcon
}
export default FindMapIcon;