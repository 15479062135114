import { decryptTheParams } from "../Helper/QueryParams/EncryptDecrypt";

const initialTableColumn = {
  value: [],
};

const queryParamsPageNumber = decryptTheParams().pageNumber
const initialPageNumber = {
  value: queryParamsPageNumber ? parseInt(queryParamsPageNumber) : 1,
};

const queryParamsRowsPerPage = decryptTheParams().rowsPerPage
const initialRowsPerPage = {
  value: queryParamsRowsPerPage ? parseInt(queryParamsRowsPerPage) : 20
};

const queryParamsDialogTablePageNumber = decryptTheParams().dialogTablePageNumber
const initialDialogTablePageNumber = {
  value: queryParamsDialogTablePageNumber ? parseInt(queryParamsDialogTablePageNumber) : 1,
};

const queryParamsDialogTableRowsPerPage = decryptTheParams().dialogTableRowsPerPage
const initialDialogTableRowsPerPage = {
  value: queryParamsRowsPerPage ? parseInt(queryParamsDialogTableRowsPerPage) : 20
};

const TableColumns = (state = initialTableColumn, action) => {
  switch (action.type) {
    case "tableColumns":
      return {
        value: action.value
      }
    default:
      return state
  }
}

const DialogBoxTableColumns = (state = initialTableColumn, action) => {
  switch (action.type) {
    case "dialogBoxTableColumns":
      return {
        value: action.value
      }
    default:
      return state
  }
}

const TablePageNumber = (state = initialPageNumber, action) => {
  switch (action.type) {
    case "tablePageNumber":
      return {
        value: action.value
      }
    default:
      return state
  }
}

const TableRowsPerPage = (state = initialRowsPerPage, action) => {
  switch (action.type) {
    case "tableRowsPerPage":
      return {
        value: action.value
      }
    default:
      return state
  }
}

const DialogTablePageNumber = (state = initialDialogTablePageNumber, action) => {
  switch (action.type) {
    case "dialogTablePageNumber":
      return {
        value: action.value
      }
    default:
      return state
  }
}

const DialogTableRowsPerPage = (state = initialDialogTableRowsPerPage, action) => {
  switch (action.type) {
    case "dialogTableRowsPerPage":
      return {
        value: action.value
      }
    default:
      return state
  }
}

export { 
  TableColumns,
  DialogBoxTableColumns,
  TablePageNumber,
  TableRowsPerPage,
  DialogTablePageNumber,
  DialogTableRowsPerPage
}