import React, { useState, useEffect, useMemo } from "react";
import Chip from "@mui/material/Chip";
import { CustomTextField } from "../../../Components/CustomTextField";
import { CustomAutocomplete } from "../../../Components/CustomAutocomplete";
import { IconButton, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import SimplePopover from "../../Popover/SimplePopover";
import CloseIcon from "@mui/icons-material/Close";
import { BasicTooltip } from "../../../Components/CustomTooltip";
import Events from "../../../Analytics/Events";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  SetMapCity,
  SetMapSearch,
  SetMapSeverity,
  SetMapStatus,
  SetMapZone,
  SetMapAlertType,
  SetMapIotVendor,
  SetMapBatteryVendor,
  SetMapFoundBatteries,
  SetMapNotFoundBatteries,
} from "../../../Actions/Map";
import { notification } from "antd";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
const Context = React.createContext({
  name: "Default",
});

function MultipleSearchAutocomplete(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      // message: `Notification ${placement}`,s
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  const [foundBatteries, setFoundBatteries] = useState([]);
  const [notFoundBatteries, setNotFoundBatteries] = useState([]);
  const [showElement, setShowElement] = React.useState(true);

  const handleOpenToast = () => {
    openNotification("bottomLeft", "Top 100 batteries are selected");
  };

  const [anchorElProfilePopup, setAnchorElProfilePopup] = React.useState(null);
  const openProfilePopup = Boolean(anchorElProfilePopup);
  const idProfilePopup = openProfilePopup
    ? "simple-popover-profile"
    : undefined;
  const handleOpenProfilePopup = (event) => {
    setShowElement(false);
    setAnchorElProfilePopup(event.currentTarget);
    Events(props.type + " clicked not found batteries icon on ");
  };
  const handleCloseProfilePopup = () => {
    setAnchorElProfilePopup(null);
  };

  useEffect(() => {
    setShowElement(true);
    setFoundBatteries(props.foundBatteries);
    setNotFoundBatteries(props.notFoundBatteries);
    if (
      props.notFoundBatteries !== undefined &&
      props.notFoundBatteries.length > 0
    ) {
      const timer = setTimeout(function () {
        setShowElement(false);
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [props.foundBatteries, props.notFoundBatteries]);

  function removeDuplicates(arr) {
    let isHavingDuplicates = hasDuplicates(arr);
    if (isHavingDuplicates) {
      openNotification("bottomLeft", "Duplicates are not allowed");
    }

    let myarr = arr.filter((item, index) => arr.indexOf(item) === index);
    return myarr;
  }

  function hasDuplicates(array) {
    var valuesSoFar = Object.create(null);
    for (var i = 0; i < array.length; ++i) {
      var value = array[i];
      if (value in valuesSoFar) {
        return true;
      }
      valuesSoFar[value] = true;
    }
    return false;
  }

  return (
    <>
      <Context.Provider value={contextValue}>
        {contextHolder}
        <CustomAutocomplete
          readOnly={props.readOnly}
          multiple
          className="multiple_Auto"
          options={[]}
          value={foundBatteries}
          limitTags={props.limitTags}
          onChange={(e, value) => {
            let batteryIds = [];
            value.forEach((battery, index) => {
              const str = battery;
              const replaced = str.replaceAll(/[\W_]+/g, " ");
              // console.log(replaced);
              const finalArr = replaced.split(" ");
              const finalArr2 = finalArr.filter(String); //(value)=> value.length!==0
              // console.log(finalArr2)
              if (finalArr2.length > 1) {
                finalArr2.map((v, i) => batteryIds.push(v));
              } else if (finalArr2.length === 1) {
                batteryIds.push(finalArr2[0]);
              }
            });
            let arrAfterDup = removeDuplicates(batteryIds);

            if (arrAfterDup.length > 100) {
              const newBatteries = arrAfterDup.slice(0, 100);
              props.handleInputBatteries(newBatteries);
              handleOpenToast();
            } else {
              props.handleInputBatteries(arrAfterDup);
            }
            // Events(props.type + " Searched Multiple Batteries");
          }}
          freeSolo
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => {
              return (
                <Chip
                  variant="outlined"
                  color={"primary"}
                  label={
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: 400,
                        fontFamily: "Roboto",
                        color: "black",
                      }}
                    >
                      {option}
                    </Typography>
                  }
                  {...getTagProps({ index })}
                />
              );
            });
          }}
          renderInput={(params) => {
            const { InputProps, ...restParams } = params;
            const { startAdornment, ...restInputProps } = InputProps;
            return (
              <CustomTextField
                {...restParams}
                label={
                  props.select === "Device ID"
                    ? "Search device"
                    : "Search battery"
                }
                placeholder={
                  props.select === "Device ID"
                    ? "Search single/multiple devices (upto 100) and press enter"
                    : "Search single/multiple batteries (upto 100) and press enter"
                }
                InputProps={{
                  ...restInputProps,
                  startAdornment: (
                    <div
                      style={{
                        maxHeight: props.maxHeight,
                        overflowY: "auto",
                      }}
                    >
                      {startAdornment}
                    </div>
                  ),
                  endAdornment: (
                    <>
                      {notFoundBatteries !== undefined &&
                        notFoundBatteries.length > 0 && (
                          <BasicTooltip
                            title={
                              "Some of the batteries are not found. Click this icon to see."
                            }
                            arrow
                            open={showElement}
                            placement="top"
                            disableFocusListener
                            sx={{
                              wordWrap: "break-word",
                              maxWidth: "200px",
                              "& .MuiTooltip-arrow": {
                                color: "rgb(28,28,28)",
                                border: "none",
                                // "&::before": {
                                //   border: `1px solid ${ThemeProperties.purple}`,
                                // },
                              },
                              zIndex: 1,
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={handleOpenProfilePopup}
                              // sx={{ mr: 1 }}
                            >
                              <WarningIcon
                                fontSize="medium"
                                sx={{
                                  color: "rgba(211, 47, 47, 0.7)",
                                  fontSize: "18.5px",
                                }}
                              />
                            </IconButton>
                          </BasicTooltip>
                        )}
                      <IconButton
                        size="small"
                        onClick={() => {
                          props.handleInputBatteries([]);
                          if (
                            props.type === "Map" &&
                            foundBatteries.length > 0
                          ) {
                            props.ClearSearchedBatteries();
                            // props.openNotification(
                            //   "bottomLeft",
                            //   "Setting City filter to Delhi"
                            // );
                          }
                        }}
                        sx={{ mr: 0.5 }}
                      >
                        <CloseIcon
                          fontSize="medium"
                          sx={{ color: "grey", fontSize: "18.5px" }}
                        />
                      </IconButton>
                    </>
                  ),
                }}
              />
            );
          }}
          sx={{ width: "100%" }}
          ListboxProps={{
            sx: {
              fontSize: 13,
              fontWeight: 500,
              fontFamily: "Roboto",
              pl: 1,
              width: 170,
              // display: "none",
            },
          }}
          componentsProps={{
            paper: {
              sx: {
                width: 175,
              },
            },
          }}
        />
        {openProfilePopup && (
          <SimplePopover
            idProfilePopup={idProfilePopup}
            openProfilePopup={openProfilePopup}
            anchorElProfilePopup={anchorElProfilePopup}
            handleCloseProfilePopup={handleCloseProfilePopup}
            data={notFoundBatteries}
            text={"Batteries not found"}
          />
        )}

        {/* <div style={{ position: "absolute", top: "70px", right: "20px" }}>
        {openToast && (
          <Box
            sx={{
              bgcolor: "white",
              p: 1,
              display: "flex",
              alignItems: "center",
              borderRadius: "12px",
              border: `1px solid ${ThemeProperties.purple}`,
            }}
          >
            <Typography variant="textSubtitle">
              Top 100 batteries are selected
            </Typography>
            <IconButton
              size="small"
              aria-label="close"
              // color="inherit"
              onClick={handleCloseToast}
            >
              <CloseIcon fontSize="medium" sx={{ color: "black" }} />
            </IconButton>
          </Box>
        )}
      </div> */}

        {/* <div
        style={{
          position: "absolute",
          top: "70px",
          right: openToast ? "270px" : "20px",
        }}
      >
        {showDuplicateMssg && (
          <Box
            sx={{
              bgcolor: "white",
              p: 1,
              display: "flex",
              alignItems: "center",
              borderRadius: "12px",
              border: `1px solid ${ThemeProperties.purple}`,
            }}
          >
            <Typography variant="textSubtitle">
              Duplicates are not allowed
            </Typography>
            <IconButton
              size="small"
              aria-label="close"
              // color="inherit"
              onClick={() => handleCloseDuplicatesMssg()}
              sx={{ visibility: "hidden" }}
            >
              <CloseIcon fontSize="medium" sx={{ color: "black" }} />
            </IconButton>
          </Box>
        )}
      </div> */}
      </Context.Provider>
    </>
  );
}

export default MultipleSearchAutocomplete;
