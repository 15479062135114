import { notification } from 'antd';
import React, { createContext, useMemo, useContext } from 'react';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement, text, time=4.5, type='info') => {
    api[type]({
      message: `${text}`,
      placement,
      duration: time,
      className: "antnoti",
    });
  };

  const closeNotification = () => {
    api.destroy();
  };

  const contextValue = useMemo(() => ({ openNotification, closeNotification }), [api]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};
