import * as React from "react";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { Typography } from "@mui/material";
import {
  StyledTabContainer,
  StyledIndividualTab,
} from "../../Components/CustomTab";
import { useSelector } from "react-redux";
export default function TabWithColorDigitalTwins(props) {
  const value = useSelector((state) => state.DigitalTwinsTabValue.value);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    props.ChangeTheTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <StyledTabContainer
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
        elevation={1}
        myColor={ThemeProperties.purple}
      >
        {props.tabs.map((item, index) => (
          <StyledIndividualTab
            key={index}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="specificTabBold"
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <Box>{item.name}</Box>
                  <Box
                    borderRadius={1}
                    border={1}
                    sx={{
                      borderColor: "lightgray", // Light border color
                      borderWidth: 1, // Border width
                      padding: "2px 4px", // Adjust padding as needed
                    }}
                    fontSize={12}
                  >
                    {item.count}
                  </Box>
                </Typography>
              </div>
            }
            myColor={ThemeProperties.purple}
          />
        ))}
      </StyledTabContainer>
    </Box>
  );
}
