import { SvgIcon } from "@mui/material"
import React from "react"

const DotStatusSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg
        width={props.width}
        height={props.height}
        viewBox={props.viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="2.5" cy="2.70587" r="2.5" fill={props.fill} />
      </svg>
    </SvgIcon>
  )
}

DotStatusSvg.defaultProps = {
  width: "5",
  height: "6",
  viewBox: "0 0 5 6",
  fill: "#73B77B",
  stroke: ""
}

export default DotStatusSvg
