import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";
import Loader from "../../Helper/Loaders/Loader";
import NoData from "../../Helper/Loaders/NoData";

import { ThemeProperties } from "../../Theme/ThemeProperties";
import NumberFormatter from "../../Helper/Formatters/NumberFormatter";

const ApexBasicBar = (props) => {
  const [chartState, setChartState] = useState({
    text: "",
    series: [],
    options: {},
  });

  function mountTheChart(description, errorCount, errorCode) {
    // var optimalColumnWidthPercent =
    //   20 + 40 / (1 + 30 * Math.exp(-errorCount.length / 3));
    setChartState({
      series: [
        {
          name: "Count",
          data: errorCount,
        },
      ],
      options: {
        chart: {
          type: "bar",
          // offsetX: -20,
          offsetY:-22,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: props.colors,
        plotOptions: {
          bar: {
            distributed: true, // this line is mandatory
            borderRadius: 0,
            barHeight: 70 + "%",
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ["#333"],
            },
            // offsetX: 100
          },
        },
        dataLabels: {
          enabled: true,
          distributed: true,
          textAnchor: "start",
          offsetX: Math.max(...errorCount) <10?15:10,
          style: {
            fontSize: ThemeProperties.c_axis_labels,
            fontWeight: 600,
            colors: [ThemeProperties.c_axis_labels_color],
            fontFamily: "Roboto",
          },
          formatter: function (val, opts) {
            return val > 1000
              ? `${"\xa0".repeat(3)}` + NumberFormatter(val, 1)
              : val >= 10
              ? `${"\xa0".repeat(2)}` + NumberFormatter(val, 0)
              : NumberFormatter(val, 0);
          },
        },
        xaxis: {
          categories: description,
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            datetimeUTC: false,
            rotateAlways: false,
            // align: "right",
            style: {
              color: ThemeProperties.c_axis_labels_color,
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            offsetY: 0,
          },
        },
        yaxis: {
          max: Math.max(...errorCount) + 0.15 * Math.max(...errorCount),
          labels: {
            show: true,
            datetimeUTC: false,
            rotateAlways: false,
            offsetX: 5,
            // align: "right",
            style: {
              color: ThemeProperties.c_axis_labels_color,
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            offsetY: 0,
          },
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: false,
          followCursor: false,
          intersect:false,
        //   fixed: {
        //     enabled: true,
        //     position: 'topRight',
        //     offsetX: 0,
        //     offsetY: 0,
        // },
          // intersect: false,
          // inverseOrder: false,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return (
              '<div class="tooltip_container">' +
              
              `<span class="flex_center">` +
              `<span class="dot-bmsError-${errorCount[dataPointIndex]>0?dataPointIndex:-1}"></span>` +
              `<span class="text_container">Count <b>: ` +
              errorCount[dataPointIndex] +
              "</b></span>" +
              "</span>" +

              '<hr class="horizontal_rule"></hr>' +

              `<span class="time_container">Error Description <b>: ` +
              description[dataPointIndex] +
              "</b></span>" +
              `<br></br>` +
              `<span class="time_container">Error Code <b>: ` +
              errorCode[dataPointIndex] +
              "</b></span>" +
              "</div>"
            );
          },
          // fillSeriesColor: false,
          // theme: false,
          // style: {
          //   fontSize: '12px',
          //   fontFamily: "Roboto"
          // },
          // onDatasetHover: {
          //     highlightDataSeries: false,
          // },
          // x: {
          //     show: true,
          // },
          // y: {
          //     formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          //       return value.toFixed()
          //     },
          //     title: {
          //         formatter: (seriesName) => seriesName+": ",
          //     },
          // },
          // z: {
          //     formatter: undefined,
          //     title: 'Size: '
          // },
          // marker: {
          //     show: true,
          // },
          // items: {
          //    display: "flex",
          // }
      },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
      },
    });
  }

  useEffect(() => {
    if (
      props.data.errorCount !== undefined &&
      props.data.errorCount.length > 0
    ) {
      mountTheChart(props.data.errorDescription, props.data.errorCount, props.data.errorCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(
    () => () => {
      props.setBatteryErrorsHistoric({
        errorDescription: [],
        errorCount: [],
        responseStatus: { code: -1, msg: "" },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      style={{
        height: props.height,
        maxHeight: props.height,
        overflowY: "auto",
        overflowX: "hidden",
        paddingLeft: "20px",
      }}
    >
      {props.data.responseStatus.code === 200 ? (
        <Chart
          options={chartState.options}
          series={chartState.series}
          type="bar"
          height={
            props.data.errorCount.length <= 4
              ? 350
              : props.data.errorCount.length * 40
          }
          width="100%"
          // id="lastLabel"
        />
      ) : (
        <div style={{ height: "100%" }}>
          {props.data.responseStatus.code === -1 ? (
            <Loader />
          ) : 
          props.data.responseStatus.code === 1999 ? (
            <div style={{ position: "relative", height: "380px" }}>
              <NoData />
              <div
                style={{
                  position: "absolute",
                  // left: "42%",
                  top: "60%",
                  width:"100%",
                }}
              >
                <div
                  style={{
                    padding: "5px",
                    display:"flex",
                    justifyContent:"center"
                  }}
                >
                  <Typography variant="textSubtitle">
                    No data found
                  </Typography>
                </div>
              </div>
              </div>
            
          ) : 
          (
            <div style={{ position: "relative", height: "380px" }}>
              <div
                style={{
                  position: "absolute",
                  left: "37%",
                  top: "42%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ececec",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                >
                  <Typography variant="textSubtitle">
                    No historical errors found
                  </Typography>
                </div>
              </div>
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    zoom: {
                      enabled: false,
                    },
                    toolbar: {
                      show: false,
                    },
                  },
                  colors: [ThemeProperties.grey],
                  plotOptions: {
                    bar: {
                      distributed: true, // this line is mandatory
                      borderRadius: 4,
                      horizontal: true,
                      dataLabels: {
                        position: "top",
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      style: {
                        colors: ["#333"],
                      },
                      // offsetX: 100
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    distributed: true,
                    offsetX: 30,
                    style: {
                      fontSize: ThemeProperties.c_axis_labels,
                      fontWeight: ThemeProperties.c_axis_labels_weight,
                      colors: [ThemeProperties.c_axis_labels_color],
                      fontFamily: "Roboto",
                    },
                  },
                  grid: {
                    show: false,
                  },
                  xaxis: {
                    categories: [""],
                    axisTicks: {
                      show: false,
                    },
                    labels: {
                      show: false,
                    },
                  },
                  yaxis: {
                    labels: {
                      show: true,
                      datetimeUTC: false,
                      rotateAlways: false,
                      // align: "right",
                      style: {
                        colors: [ThemeProperties.c_axis_labels_color],
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },

                      offsetY: 0,
                    },
                  },
                  legend: {
                    show: false,
                  },
                }}
                series={[
                  {
                    name: "Count",
                    data: [null],
                  },
                ]}
                type="bar"
                height="100%"
                width="100%"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ApexBasicBar;
