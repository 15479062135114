import { styled } from "@mui/material/styles"
import { Box, Typography } from "@mui/material"
import { CustomText, FlexBox, FlexCenterBox, FlexSpaceBetweenBox, FlexStartBox, FullWidthBox, OuterBox } from "../../../Components/CustomBox"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { PageContainer } from "../../AlertAnalytics/Components/StyledComponent";

const DrillDownPageContainer = styled(PageContainer)({
  height: "calc(100vh - 180px)",
  overflowY: "auto",
})

const AnalyticsFilterContainer = styled(Box)({
  backgroundColor: "#FFF",
  paddingTop: "54px",
  minWidth: "262px",
  border: "1px solid #E9E9E9",
  borderBottom: 0,
  height: "calc(100vh - 60px)",
  overflowY: "auto",
  overflowX: "hidden"
})

const AnalyticsFilterSectionBox = styled(Box)({
  padding: "16px 24px",
  borderBottom: "1px solid #E9E9E9",
});

const FilterTitleContainer = styled(AnalyticsFilterSectionBox)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: "16px",
  borderBottom: 0
})

const AnalyticsDrillDownPageTitleText = styled(CustomText)({
  fontWeight: "500",
  fontSize: "18px",
  color: "#1C1C1C",
})

const FilterSectionTitle = styled(CustomText)({
  fontWeight: "600",
  fontSize: "16px",
  color: "#1C1C1C",
})

const FilterSectionClearAllText = styled(CustomText)({
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  cursor: "pointer",
  color: "#6D72F6",
})

const CalendarBox = styled(Box)({
  paddingTop: "11px",
  paddingBottom: "11px",
})

const FilterTitleText = styled(CustomText)(({ color }) => {
  return {
    fontWeight: "500",
    fontSize: "14px",
    color: color || "#1C1C1C",
    lineHeight: "16px"
  }
})

const GranularityContainer = styled(FlexBox)({
  borderRadius: "4px",
  marginTop: "-3px",
  border: "1px solid #E9EFF5",
  width: 'fit-content'
})

const GranularityBox = styled(Box)(({ isSelected }) => {
  return {
    borderRadius: "3px",
    backgroundColor: isSelected ? '#EEEFFF' : '#FFF',
    padding: "5px 17px",
    color: isSelected ? '#6D72F6' : '#444B51',
    cursor: "pointer",
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "17px",
  }
})

const FilterClearAllButton = styled(Box)(({ color }) => {
  return {
    marginRight: "4px",
    cursor: "pointer",
    color: color || "#6D72F6",
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "16px"
  }
})

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: "1px solid #E9E9E9",
  width: "262px",
  borderLeft: 0,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(
  ({ isExpanded, selected, filterOptions, ...props }) => (
    <MuiAccordionSummary
      expandIcon={
        <ExpandMoreIcon
          sx={{
            height: isExpanded
              ? selected !== filterOptions
                ? "6.3rem"
                : "6.4rem"
              : selected !== filterOptions
              ? "5rem"
              : "6.8rem",
            fontSize: "1.5rem",
          }}
        />
      }
      {...props}
    />
  )
)(({ isExpanded }) => ({
  backgroundColor: isExpanded ? "#EEEFFF" : "#FFF",
  alignItems: "flex-start",
  padding: "0 24px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    margin: 0,
    color: "#6D72F6",
    transform: "rotate(180deg)",
    backgroundColor: "transparent",
  },
  "& .MuiAccordionSummary-content": {
    margin: isExpanded ? "25px 0" : "17px 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "8px 16px",
  borderTop: "1px solid #E9E9E9",
}));

const FilterMenuOptionText = styled(Typography)(({ color }) => {
  return {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#444B51"
  }
})

const ChartSectionContainer = styled(Box)({
  padding: "16px 24px",
});

const FilterDoneButtonBox = styled(FlexBox)(({ isEnabled }) => {
  return {
    justifyContent: 'flex-end',
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "24px",
    color: isEnabled ? "#6D72F6" : "#828A92",
    cursor: isEnabled ? "pointer" : "default",
  }
})

const FilterAllSelectedText = styled(CustomText)({
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "16px",
  color: "#828A92",
  marginTop: "2px",
})

const SelectedOptionPillsContainer = styled(FlexStartBox)({
  padding: "24px 0 4px 0",
  gap: "4px",
})

const PillBox = styled(FlexStartBox)({
  alignItems: 'center',
  backgroundColor: "#EEEFFF",
  padding: "2px 8px",
  borderRadius: "15px"
})

const PillText = styled(CustomText)({
  cursor: "default",
  color: "#5B60F8",
  fontSize: "12px",
  fontWeight: 400,
})

const FilterPillCrossButtonBox = styled(Box)({
  marginTop: "2px",
  marginLeft: "4px",
  cursor: "pointer",
})

export { 
  AnalyticsFilterSectionBox,
  AnalyticsFilterContainer,
  FilterSectionTitle,
  FilterTitleContainer,
  FilterSectionClearAllText,
  DrillDownPageContainer,
  CalendarBox,
  FilterTitleText,
  GranularityBox,
  GranularityContainer,
  FilterClearAllButton,
  FilterMenuOptionText,
  AnalyticsDrillDownPageTitleText,
  Accordion,
  ChartSectionContainer,
  AccordionSummary,
  AccordionDetails,
  FilterDoneButtonBox,
  FilterAllSelectedText,
  PillBox,
  PillText,
  FilterPillCrossButtonBox,
  SelectedOptionPillsContainer
}
