import React from "react";
import { Tooltip } from "antd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { Typography, IconButton } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Events from "../../Analytics/Events";

function TextWithCopyIcon(text, type, textStyle={}, toolTipPlacement="top", label=null, iconStyle={}, iconButtonStyle={}, showLabelTooltip=false) {
  return (
    <>
      {showLabelTooltip 
      ? <Tooltip
          placement="top"
          title={text}
          zIndex={2000}
        >
          <Typography style={textStyle} variant="tableRow">{label || text}</Typography>
        </Tooltip>
      : <Typography style={textStyle} variant="tableRow">{label || text}</Typography>
      }

      <Tooltip title={"Click to copy"} placement={toolTipPlacement}>
        <IconButton
          style={{ 
            ...iconButtonStyle
           }}
          onClick={(event) => {
            event.stopPropagation();
            copy(text);
            Events(`Copied ${type}`);
          }}
        >
          <ContentCopyIcon
            style={{
              fontSize: "13px",
              cursor: "pointer",
              "&:hover": {
                color: ThemeProperties.purple,
              },
              ...iconStyle
            }}
          />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default TextWithCopyIcon;
