import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";

import Loader from "../../Helper/Loaders/Loader";
import "./ApexMultiYAxis.css";
import "./charts.css";

import CODE from "../../Static/Constants/StatusCodes";
import { DateFormatString } from "../../Helper/DatePicker/DateFormatters";

function TimeSeriesSingleAxis(props) {
  const [chartState, setChartState] = useState({
    text: "",
    series: [],
    options: {},
  });

  const [mounted, setMounted] = useState(false);

  function mountTheChart(series, colors, granular) {
    var my_date = DateFormatString(props.startDate);
    setChartState({
      text: "",
      series: series,
      options: {
        chart: {
          animations: {
            enabled: false,
          },
          type: "line",
          zoom: {
            enabled: true,
            type: "x",
            minZoom: 10,
          },
          resetIcon: {
            offsetX: -10,
            offsetY: 5,
            fillColor: "#fff",
            strokeColor: "#37474F",
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: [],
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ",",
                headerCategory: "category",
                headerValue: "value",
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString();
                },
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              },
            },
            autoSelected: "zoom",
            // autoSelected: false,
          },
          events: {
            beforeZoom: function (chart, { xaxis }) {
              if (granular === "Raw Data") {
                const MIN_ZOOM_RANGE = 15 * 60 * 1000; // 15 minutes in milliseconds
                let min = xaxis.min;
                let max = xaxis.max;

                if (max - min >= 86400000) {
                  return {
                    xaxis: {
                      min: new Date(props.startDate).getTime() - 19800000,
                      max:
                        new Date(props.startDate).getTime() +
                        86400000 -
                        19800000,
                      labels: {
                        format: "h TT",
                      },
                    },
                  };
                }

                if (max - min < MIN_ZOOM_RANGE) {
                  let newmax = min + MIN_ZOOM_RANGE;

                  return {
                    xaxis: {
                      min: min,
                      max: newmax,
                      labels: {
                        format: "h:mm TT",
                      },
                    },
                  };
                } else {
                  return {
                    xaxis: {
                      min: min,
                      max: max,
                      labels: {
                        format: "h:mm TT",
                      },
                    },
                  };
                }
              }
            },

            beforeResetZoom: function (chartContext, opts) {
              if (granular === "Raw Data")
                return {
                  xaxis: {
                    min: new Date(props.startDate).getTime() - 19800000,
                    max:
                      new Date(props.startDate).getTime() + 86400000 - 19800000,
                    labels: {
                      show: true,
                      datetimeUTC: false,
                      rotate: 0,
                      rotateAlways: false,
                      style: {
                        colors: ThemeProperties.c_axis_labels_color,
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },
                      offsetX: 0,
                      offsetY: 0,
                      format: "h TT",
                    },
                  },
                };
            },
            click: function (event, chartContext, config) {},
          },
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2.5,
          curve: "smooth",
        },
        grid: {
          padding: {
            left: 13,
            right: granular === "Raw Data" ? 30 : 0,
          },
        },
        title: {
          text: undefined,
        },
        colors: colors,
        markers: {
          size: 0,
          strokeWidth: 0,
          hover: {
            size: 4,
            sizeOffset: 1,
          },
        },
        yaxis: {
          axisTicks: {
            show: true,
            borderType: "solid",
            color: ThemeProperties.c_axis_labels_color,
          },
          min: props.data.ymin,
          max: props.data.ymax,
          tickAmount: 5,
          axisBorder: {
            show: true,
            color: ThemeProperties.c_axis_labels_color,
            // offsetX: 3,
          },
          labels: {
            style: {
              colors: [ThemeProperties.c_axis_labels_color],
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            formatter: function (value) {
              // return Math.round(value);
              return value.toFixed(props.data.yAxisDecimalPlaces);
            },
            // offsetX: -20,
          },
          title: {
            text: props.data.yAxisTitle,
            offsetX: -2,
            style: {
              color: props.data.yAxisColor,
              fontSize: ThemeProperties.c_axis_title,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_title_weight,
            },
          },
          tooltip: {
            enabled: false,
          },
        },

        xaxis: {
          type: props.data.xaxisType,
          // tickAmount:10,

          // categories: categories,
          tooltip: {
            enabled: false,
          },
          title: {
            text: undefined,
          },
          labels: {
            show: true,
            datetimeUTC: false,
            rotate: 0,
            rotateAlways: false,
            style: {
              colors: ThemeProperties.c_axis_labels_color,
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            offsetX: 0,
            offsetY: 0,
            format: granular === "Raw Data" ? "h TT" : undefined,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: ThemeProperties.c_axis_labels_color,
          },
          axisBorder: {
            show: true,
            color: ThemeProperties.c_axis_labels_color,
            height: 0.8,
          },
          min:
            granular === "Raw Data" ? new Date(my_date).getTime() : undefined,
          max:
            granular === "Raw Data"
              ? new Date(my_date).getTime() + 86400000
              : undefined,
          // min: props.data.xaxisType === "datetime" ? 1682879400000 : undefined,
          // max: props.data.xaxisType === "datetime" ? 1682965800000 : undefined,
        },
        tooltip: {
          followCursor: true,
          // custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          //   const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
          //   const hoverIndexes = w.globals.seriesX.map(seriesX => {
          //     return seriesX.findIndex(xData => xData === hoverXaxis);
          //   });

          //   let hoverList = '';
          //   hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
          //     if (hoverIndex >= 0) {
          //       hoverList += `<span>${w.globals.seriesNames[seriesEachIndex]}: ${series[seriesEachIndex][hoverIndex]}</span><br />`;
          //     }
          //   });
          //   const formatHoverX = new Date(hoverXaxis);

          //   return `<div class="card">
          //     <div class="card-header p-1">${formatHoverX}</div>
          //     <div class="card-body p-1">
          //       ${hoverList}
          //     </div>
          //   </div>`;
          // },
          // shared: true,
          x: {
            // format("")
            show: true,
            format:
              granular === "Raw Data" ? "dd MMM'yy, hh:mm:ss TT" : undefined,
            formatter: undefined,
          },

          y: {
            title: {
              formatter: (seriesName) => `${seriesName} :`,
            },
            formatter: function (
              value,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              return value?.toFixed(props.data.toolTipDecimalPlaces);
            },
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        legend: {
          show: false,
        },
      },
    });

    setMounted(true);
  }

  useEffect(() => {
    setMounted(false);
    setChartState({
      text: "",
      series: [],
      options: {},
    });
    if (
      props.data !== undefined &&
      (props.data?.responseStatus.code === CODE.SUCCESS ||
        props.data?.responseStatus.code === 1999)
    ) {
      mountTheChart(props.data.series, props.data.colors, props.granular);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.responseStatus.code]);

  useEffect(
    () => () => {
      setChartState({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      {console.log(props.data.responseStatus.code)}
      {props.data.responseStatus.code === CODE.SUCCESS && mounted ? (
        <div style={{ height: "400px" }}>
          <Chart
            options={chartState.options}
            series={chartState.series}
            type="line"
            height="100%"
            width="100%"
            id={
              props.granular === "Raw Data" ? "hideAlternateLabel" : "donthide"
            }
          />
        </div>
      ) : props.data.responseStatus.code === 1999 && mounted ? (
        <div style={{ position: "relative", height: "400px" }}>
          <div
            style={{
              position: "absolute",
              left: "41.5%",
              top: "44%",
            }}
          >
            <div
              style={{
                backgroundColor: "#ececec",
                padding: "10px",
                borderRadius: "20px",
              }}
            >
              <Typography variant="textSubtitle">
                No data found for current selection
              </Typography>
            </div>
          </div>
          <Chart
            options={chartState.options}
            series={chartState.series}
            type="line"
            height="100%"
            width="100%"
            id={
              props.granular === "Raw Data" ? "hideAlternateLabel" : "donthide"
            }
          />
        </div>
      ) : (
        <div style={{ height: "400px" }}>
          {props.data.responseStatus.code === -1 ? (
            <Loader />
          ) : (
            <div style={{ position: "relative", height: "400px" }}>
              <div
                style={{
                  position: "absolute",
                  left: "41.5%",
                  top: "44%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ececec",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                >
                  <Typography variant="textSubtitle">
                    No data found for selected date
                  </Typography>
                </div>
              </div>
              <Chart
                series={[
                  {
                    name: "cell balance",
                    data: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                    ],
                  },
                ]}
                options={{
                  chart: {
                    type: "line",
                    zoom: {
                      enabled: false,
                    },
                    toolbar: {
                      show: false,
                      offsetY: -20,
                      tools: {
                        download: false,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        customIcons: [],
                      },
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  stroke: {
                    width: 0,
                    curve: "smooth",
                    // dashArray: [0, 2, 5, 8],
                  },
                  grid: {
                    padding: {
                      left: 13,
                      right: 30,
                    },
                  },
                  title: {
                    text: undefined,
                  },
                  colors: props.data.colors,
                  markers: {
                    size: 0,
                  },
                  yaxis: {
                    axisTicks: {
                      show: true,
                      borderType: "solid",
                      color: ThemeProperties.c_axis_labels_color,
                    },
                    min: props.data.ymin,
                    max: props.data.ymax,
                    tickAmount: 5,
                    axisBorder: {
                      show: true,
                      color: ThemeProperties.c_axis_labels_color,
                      // offsetX: 3,
                    },
                    labels: {
                      style: {
                        colors: [ThemeProperties.c_axis_labels_color],
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },
                      formatter: function (value) {
                        // return Math.round(value);
                        return value.toFixed(1);
                      },
                      // offsetX: -20,
                    },
                    title: {
                      text: props.data.yAxisTitle,
                      offsetX: -2,
                      style: {
                        color: props.data.yAxisColor,
                        fontSize: ThemeProperties.c_axis_title,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_title_weight,
                      },
                    },
                    tooltip: {
                      enabled: false,
                    },
                  },
                  xaxis: {
                    categories: [
                      "12 AM",
                      "1 AM",
                      "2 AM",
                      "3 AM",
                      "4 AM",
                      "5 AM",
                      "6 AM",
                      "7 AM",
                      "8 AM",
                      "9 AM",
                      "10 AM",
                      "11 AM",
                      "12 PM",
                      "1 PM",
                      "2 PM",
                      "3 PM",
                      "4 PM",
                      "5 PM",
                      "6 PM",
                      "7 PM",
                      "8 PM",
                      "9 PM",
                      "10 PM",
                      "11 PM",
                      "12 AM",
                    ],

                    // tickAmount:10,

                    tooltip: {
                      enabled: false,
                    },
                    title: {
                      text: undefined,
                    },
                    labels: {
                      show: true,
                      datetimeUTC: false,
                      rotateAlways: false,
                      // align: "center",
                      style: {
                        colors: ThemeProperties.c_axis_labels_color,
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },
                      offsetX: 0,
                      offsetY: 0,
                      format: "h TT",
                    },
                    axisTicks: {
                      show: true,
                      borderType: "solid",
                      color: ThemeProperties.c_axis_labels_color,
                    },
                    axisBorder: {
                      show: true,
                      color: ThemeProperties.c_axis_labels_color,
                      height: 0.8,
                    },
                  },
                  tooltip: {
                    enabled: false,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: false,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    theme: false,
                    style: {
                      style: {
                        colors: [ThemeProperties.c_axis_labels_color],
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },
                    },
                    onDatasetHover: {
                      highlightDataSeries: false,
                    },

                    marker: {
                      show: true,
                    },

                    fixed: {
                      enabled: false,
                      position: "topRight",
                      offsetX: 0,
                      offsetY: 0,
                    },
                  },
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                  legend: {
                    show: false,
                  },
                }}
                type="line"
                height="100%"
                width="100%"
                id="hideAlternateLabel"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default TimeSeriesSingleAxis;
