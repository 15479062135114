import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Loader from "../../Helper/Loaders/Loader";
import { ThemeProperties } from "../../Theme/ThemeProperties";

const ApexBasicPie = (props) => {

  const [chartState, setChartState] = useState({
    text: "",
    series: [],
    options: {},
  });

  function mountTheChart(number, labels, colors) {
    setChartState({
      text: "",
      series: number,
      options: {
        chart: {
          width: 380,
          type: "pie",
        },
        states: {
          hover: {
            filter: {
              type: "none",
              value: 0.15,
            },
          },
        },
        colors: colors,
        dataLabels: {
          style: {
            fontSize: "13px",
            fontFamily: "Roboto",
            fontWeight: ThemeProperties.c_axis_title_weight,
            // colors: props.dataLabelsColors,

          },
          dropShadow: {
            enabled: false,
          },
          formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex];
          },
        },
        tooltip: {
          fillSeriesColor: false,
          theme: "light",
          x: {
            show: true,
            format: "dd MMM",
            formatter: undefined,
          },
          y: {
            formatter: function (value) {
              return ": " + value + " " + props.unit;
            },
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
        labels: labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          show: props.showLegends,
          offsetX: -5,
          offsetY: -5,
          colors: [ThemeProperties.c_legend_labels_color],
          fontSize: ThemeProperties.c_legend_labels,
          fontFamily: "Roboto",
          fontWeight: ThemeProperties.c_legend_labels_weight,
          position: "right",
          itemMargin: {
            horizontal: 0,
            vertical: 4,
          },
          width: 180,
        },
      },
    });
  }

  useEffect(() => {
    if (props.data.alerts !== undefined && props.data.alerts.length > 0) {
      let alerts = props.data.alerts.filter((item) => item.alertCount > 0);
      let ad = [],
        ac = [],
        colors = [];
        alerts.forEach((item) => {
        ad.push(item["alertDescription"]);
        ac.push(item["alertCount"]);
        colors.push(item["color"])
      });

      mountTheChart(ac, ad, colors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);
  useEffect(
    () => () => {
      props.setBatteryAlerts({
        number: [],
        labels: [],
        responseStatus: { code: -1, msg: "" },
      });

      if (props.data.alerts !== undefined && props.data.alerts.length > 0) {
        let alerts = props.data.alerts.filter((item) => item.alertCount > 0);
        let ad = [],
          ac = [],
          colors = [];
          alerts.forEach((item) => {
          ad.push(item["alertDescription"]);
          ac.push(item["alertCount"]);
          colors.push(item["color"])
        });
  
        mountTheChart(ac, ad, colors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.dateString[0], props.dateString[1]]
  );

  useEffect(
    () => () => {
      props.setBatteryAlerts({
        number: [],
        labels: [],
        responseStatus: { code: -1, msg: "" },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div id="pie-legends-largeWidth" style={{ height: "400px" }}>
      {props.data.responseStatus.code === 200 ? (
        <Chart
          options={chartState.options}
          series={chartState.series}
          type="pie"
          height={props.height}
          width="100%"
        />
      ) : (
        <div style={{ height: "100%" }}>
          {props.data.responseStatus.code === -1 ? (
            <Loader />
          ) : (
            <Chart
              options={{
                chart: {
                  width: 380,
                  type: "pie",
                },
                states: {
                  hover: {
                    filter: {
                      type: "none",
                      value: 0.15,
                    },
                  },
                },
                colors: [
                  props.data.responseStatus.msg === "No Data"
                    ? ThemeProperties.grey
                    : ThemeProperties.green_100,
                ],
                dataLabels: {
                  style: {
                    fontSize: "13px",
                    fontFamily: "Roboto",
                    fontWeight: ThemeProperties.c_axis_title_weight,
                    colors: ["transparent"],
                  },
                  dropShadow: {
                    enabled: false,
                  },
                  formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex];
                  },
                },
                tooltip: {
                  enabled: false,
                  fillSeriesColor: false,
                  theme: "light",
                  x: {
                    show: true,
                    format: "dd MMM",
                    formatter: undefined,
                  },
                  y: {
                    formatter: function (value) {
                      return ": " + value + " " + props.unit;
                    },
                    title: {
                      formatter: (seriesName) => seriesName,
                    },
                  },
                },
                labels: [`${props.data.responseStatus.msg}`],
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200,
                      },
                      legend: {
                        position: "bottom",
                      },
                    },
                  },
                ],
                legend: {
                  offsetX: -5,
                  offsetY: -5,
                  colors: [ThemeProperties.c_legend_labels_color],
                  fontSize: ThemeProperties.c_legend_labels,
                  fontFamily: "Roboto",
                  fontWeight: ThemeProperties.c_legend_labels_weight,
                  position: "right",
                  itemMargin: {
                    horizontal: 0,
                    vertical: 4,
                  },
                  width: 160,
                },
              }}
              series={[1]}
              type="pie"
              height="100%"
              width="100%"
            />
          )}
        </div>
      )}
    </div>
  );
};
export default ApexBasicPie;
