import Cookies from "universal-cookie";

function removeAccessToken() {
    const cookies = new Cookies();

    cookies.remove("access_token",{ path: '/' });
    window.location.reload();
  }

export default removeAccessToken

