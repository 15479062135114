import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomIcon from '../../../../Components/CustomIcon'

const AlertLogTab = (props) => {
    const { ...other } = props
    return (
        <Box>
            AlertLogTab
        </Box>
    )
}

export default AlertLogTab