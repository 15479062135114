import React, { useEffect } from "react"
import Loader from "../../Helper/Loaders/Loader"
import { usePageMeta } from "../../Hooks/usePageMeta"
import { BatteryHealthLoaderBox, PageContainer } from "../../Components/CustomBox"
import { NotificationProvider } from "../../Hooks/useNotification"
import ChargingAnalyticsMain from "./ChargingAnalyticsMain"

function ChargingAnalytics(props) {
  const { pagesContent } = usePageMeta("chargingAnalytics")

  useEffect(() => {
    pagesContent["mounted"] = true
  }, [pagesContent])

  return (
    <PageContainer>
      {pagesContent?.mounted ? (
        <NotificationProvider>
          <ChargingAnalyticsMain pagesContent={pagesContent} />
        </NotificationProvider>
      ) : (
        <BatteryHealthLoaderBox>
          <Loader />
        </BatteryHealthLoaderBox>
      )}
    </PageContainer>
  )
}

export default ChargingAnalytics
