import qs from "qs";
import { GetData } from "./Functions/SettingData";

async function getKPIBatteries(
  severity,
  batteryState,
  volt,
  city,
  zone,
  batterystatus,
  iotVendor,
  packVendor,
  batteryID,
  partner,
  batteryType,
  currentPage,
  sortBy,
  orderBy,
  batteriesPerPage
) {
  var rawJson = {
    severity: severity,
    location: city,
    zone: zone,
    voltConf: volt,
    iotVendor: iotVendor,
    batteryVendor: packVendor,
    batteryStatus: batterystatus,
    batteryState: batteryState,
    assetID: batteryID.length>0 ? [batteryID] : [],
    batteryID: [],
    partner: partner.length>0 ? [partner] : [],
    batteryType: batteryType,
    pageCount: batteriesPerPage,
    sortBy: sortBy,
    order: orderBy,
    batteryFlag:[0]
  };
  var config = {
    method: "get",
    url: `/api/batteries/${currentPage}`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

async function getBatteryPartnerListKPI(batteryType) {
  var rawJson = {
    alertState: "",
    allState: "All",
    errorState: "",
    batteryType: batteryType,
  };
  var config = {
    method: "get",
    url: `/api/battery_partner_list`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getPartnerList(columnName, columnSearch, columnLimit) {
  var rawJson = {
    columnName: columnName,
    columnSearch: columnSearch,
    columnLimit: 10,
  };
  var config = {
    method: "get",
    url: `/api/filter_all_batteries`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getFoundAndNotFoundBatteries(
  batteryID
) {
  var rawJson = {
    batteryID: [],
    assetID: batteryID
  };
  var config = {
    method: "get",
    url: `/api/not_found_assetID`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

async function getSelectAllBatteries(
  severity,
  city,
  zone,
  volt,
  batterystatus,
  iotVendor,
  packVendor,

  batteryState,
  batteryID,
  partner,
  batteryType
) {
  var rawJson = {
    severity: severity,
    location: city,
    zone: zone,
    voltConf: volt,
    batteryStatus: batterystatus,
    iotVendor: iotVendor,
    batteryVendor: packVendor,
    batteryState: batteryState,
    assetID: batteryID === null ? "" : batteryID,
    batteryID: [],
    partner: partner === null ? "" :partner,
    batteryType: batteryType,
    batteryFlag:[0]
  };
  var config = {
    method: "get",
    url: `/api/global_selection_battery`,
    headers: {
      "Content-Type": "application/json",
    },
    params: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };
  let data = GetData(config);
  return data;
}

async function getCSVOfBatteries(batteryID, batteryType) {
  var rawJson = {
    location: "",
    zone: "",
    voltConf: "",
    batteryStatus: "",
    batteryState: "",
    iotVendor: "",
    batteryVendor: "",
    severity: "",
    assetID: [],
    batteryID: batteryID.length>0 ? batteryID : [],
    partner: [],
    batteryType: batteryType,
    pageCount: batteryID.length,
    sortBy: "lastPingedTime",
    order: "asc",
    batteryFlag:[0]
  };

  var config = {
    method: "post",
    url: `/api/batteries/1`,
    headers: {
      "Content-Type": "application/json",
    },
    data: rawJson,
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  };

  let data = GetData(config);
  return data;
}

export {
  getKPIBatteries,
  getBatteryPartnerListKPI,
  getFoundAndNotFoundBatteries,
  getSelectAllBatteries,
  getCSVOfBatteries,
  getPartnerList
};
