import React from "react";
import { Dialog, Typography, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { OutlinedButton } from "../../Components/CustomButton";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  SetDashboardOpenAddSelectedBatteries,
  SetDashboardSelectedBatteriesAction,
} from "../../Actions/Dashboard";

function RemoveSelectedBatteries(props) {
  const dispatch = useDispatch();
  const openRemoveSelectedBatteries = useSelector(
    (state) => state.DashboardOpenAddSelectedBatteries.value
  );

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openRemoveSelectedBatteries}
      maxWidth="xs"
      sx={{ zIndex: 100001 }}
    >
      <div
        style={{
          padding: "16px",
          display: "inline",
          textAlign: "center",
          marginTop: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 0px",
          }}
        >
          <Typography variant="paperHeader"></Typography>
          <InfoIcon sx={{ fontSize: "20px", color: ThemeProperties.purple,ml:4 }} />
          <IconButton
            onClick={() =>
              dispatch(SetDashboardOpenAddSelectedBatteries(false))
            }
            fontSize="small"
          >
            <CloseIcon style={{ fontSize: "18px" }} />
          </IconButton>
        </div>

        <br />
        <Typography variant="paperHeader">
          Attention! Moving to different Page/Tab
        </Typography>
        <br />

        <Typography variant="textSubtitle">
          Batteries selected are not added to take action. Do you want to add
          them?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "40px",
          }}
        >
          <OutlinedButton
            onClick={() => {
              // props.removeSelection();
              // props.handleCloseRemoveSelectedBatteries();
              dispatch(SetDashboardSelectedBatteriesAction("Remove"));
              dispatch(SetDashboardOpenAddSelectedBatteries(false));
            }}
            variant="outlined"
          >
            <Typography variant="textSubtitlePurple">
              Remove selection
            </Typography>
          </OutlinedButton>
          <OutlinedButton
            variant="contained"
            onClick={() => {
              // props.addToCart();
              // props.handleCloseRemoveSelectedBatteries();
              dispatch(SetDashboardSelectedBatteriesAction("Add"));
              dispatch(SetDashboardOpenAddSelectedBatteries(false));
            }}
            sx={{ ml: 2 }}
          >
            <Typography variant="textSubtitleWhite">Add batteries</Typography>
          </OutlinedButton>
        </div>
      </div>
    </Dialog>
  );
}

export default RemoveSelectedBatteries;
