import React, { useEffect, useState, useRef } from "react";
import WarrantyMain from "./WarrantyMain";
import { getPagesComponents } from "../../Api/Api";
import { Tooltip } from "antd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import copy from "copy-to-clipboard";
import { Box, Typography, IconButton } from "@mui/material";
import { Button, Input, Space, Checkbox } from "antd";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import { useDispatch } from "react-redux";
import {
  SetWarrantyColumns,
  SetWarrantyHiddenColumns,
  SetWarrantyIOT,
  SetWarrantyPack,
  SetWarrantyCycle,
  SetWarrantyAge,
  SetWarrantySOH,
} from "../../Actions/Warranty";
import { SetSessionExpired } from "../../Actions";
import Events from "../../Analytics/Events";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import moment from "moment";
import { getColumnTitleSort } from "../../Features/Table/AntDesign/TableFunctions";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";

function Warranty(props) {
  const dispatch = useDispatch();

  const [pagesContent, setPagesContent] = useState({
    pageHeader: "",
    filtersWithData: [],
    filteredArray: [],
    batteryType: "",
    mounted: false,
  });
  const searchInput = useRef(null);

  function renderBatteryIDCopy(item, name) {
    return (
      <>
        {item && (
          <>
            <Typography variant="tableRow">{item}</Typography>
            <Tooltip title={"Click to copy"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  copy(item);
                  Events(`Copied ${name}`);
                }}
              >
                <ContentCopyIcon
                  sx={{
                    fontSize: "13px",
                    cursor: "pointer",
                    "&:hover": {
                      color: ThemeProperties.purple,
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        )}
      </>
    );
  }

  useEffect(() => {
    setTimeout(() => {
      //   getPagesComponents("Warranty").then((res) => {
      // if (res.responseStatus.code === 200) {
      const filters = [
        {
          itemName: "IOT",
          items: [
            {
              key: "All",
              value: "",
            },
            {
              key: "Wynncom",
              value: "Wynncom",
            },
            {
              key: "Intellicar",
              value: "INTELLICAR",
            },
          ],
        },
        {
          itemName: "Pack",
          items: [
            {
              key: "All",
              value: "",
            },
            {
              key: "Livguard",
              value: "Livguard",
            },
          ],
        },
        {
          itemName: "Cycles",
          items: [
            { key: "All", value: "" },
            { key: "Less than 100", value: "0:100" },
            { key: "Less than 500", value: "0:500" },
            { key: "Less than 1000", value: "0:1000" },
            { key: "Less than 1500", value: "0:1500" },
            { key: "Greater than 1500", value: "1500:''" },
          ],
        },
        {
          itemName: "Age",
          items: [
            { key: "All", value: "" },
            { key: "Less than 1 year", value: "0:365" },
            { key: "Less than 2 year", value: "0:730" },
            { key: "Less than 3 year", value: "0:1095" },
            { key: "Less than 4 year", value: "0:1406" },
            { key: "Greater than 4 year", value: "1406:''" },
          ],
        },
        {
          itemName: "SOH",
          items: [
            { key: "All", value: "" },
            { key: "Less than 80%", value: "0:80" },
            { key: "Less than 85%", value: "0:85" },
            { key: "Less than 90%", value: "0:90" },
            { key: "Less than 95%", value: "0:95" },
            { key: "Greater than 95%", value: "95:''" },
          ],
        },
      ];
      let newFilterWithData = [];
      filters.map(
        (item) => newFilterWithData.push(item["itemName"]) //res.response.response.filter.filtersWithData
      );
      const filteredArray = newFilterWithData;

      const queryParams = decryptTheParams();

      for (var value of Object.keys(queryParams)) {
        if (filteredArray.includes(value)) {
          if (value === "IOT") {
            dispatch(SetWarrantyIOT(queryParams[value]));
          } else if (value === "Pack") {
            dispatch(SetWarrantyPack(queryParams[value]));
          } else if (value === "Cycles") {
            dispatch(SetWarrantyCycle(queryParams[value]));
          } else if (value === "Age") {
            dispatch(SetWarrantyAge(queryParams[value]));
          } else if (value === "SOH") {
            dispatch(SetWarrantySOH(queryParams[value]));
          }
        }
      }

      setPagesContent({
        ...pagesContent,
        pageHeader: "Warranty",
        filtersWithData: filters,
        filteredArray: filteredArray,
        batteryType: "",
        mounted: true,
      });
      // }
      // else if(res.responseStatus.code === -2)
      // {
      //   dispatch(SetSessionExpired(true))
      // }
      //   });

      //   const handleSearch = (
      //     selectedKeys,
      //     confirm,
      //     dataIndex,
      //     tabvalue,
      //     close
      //   ) => {
      //     const tempColumns = [...columns];
      //     tempColumns[tabvalue]["columns"].forEach((col) => {
      //       if (col.dataIndex === dataIndex) {
      //         col.checkedOptions = [...selectedKeys];
      //       }
      //     });
      //     dispatch(SetWarrantyColumns([...tempColumns]));
      //     close();
      //   };
      //   const handleReset = (
      //     setSelectedKeys,
      //     confirm,
      //     filters,
      //     dataIndex,
      //     tabvalue,
      //     close
      //   ) => {
      //     const tempColumns = [...columns];
      //     filters.forEach((ele) => {
      //       ele.isChecked = false;
      //     });
      //     tempColumns[tabvalue]["columns"].forEach((col) => {
      //       if (col.dataIndex === dataIndex) {
      //         col.checkedOptions = [];
      //       }
      //     });
      //     setSelectedKeys([]);
      //     dispatch(SetWarrantyColumns([...tempColumns]));
      //     close();
      //   };
      //   const onChangeFilter = (
      //     ele,
      //     dataIndex,
      //     selectedKeys,
      //     setSelectedKeys,
      //     filters
      //   ) => {
      //     if (ele.isChecked) {
      //       filters.forEach((element) => {
      //         if (element == ele) {
      //           ele.isChecked = false;
      //         }
      //       });

      //       const arr =
      //         selectedKeys.length > 0
      //           ? selectedKeys.filter((item) => item != ele.value)
      //           : [];

      //       setSelectedKeys(arr);
      //     } else {
      //       filters.forEach((element) => {
      //         if (element == ele) {
      //           ele.isChecked = true;
      //         }
      //       });

      //       if (selectedKeys[0] == undefined) {
      //         setSelectedKeys([(selectedKeys[0] = "")]);
      //       }
      //       setSelectedKeys([...selectedKeys, ele.value]);
      //     }
      //     var isFilterEmpty = true;
      //     filters.forEach((ele) => {
      //       if (ele.isChecked == true) {
      //         isFilterEmpty = false;
      //       }
      //     });

      //     if (isFilterEmpty) {
      //       setSelectedKeys([]);
      //     }
      //   };

      //   const handleSelectAllFilters = (
      //     e,
      //     filters,
      //     selectedKeys,
      //     setSelectedKeys,
      //     dataIndex
      //   ) => {
      //     if (e.target.checked) {
      //       const arr = [];

      //       if (selectedKeys[0] == undefined) {
      //         setSelectedKeys([(selectedKeys[0] = "")]);
      //       }
      //       filters
      //         .filter((ele) => ele.value.includes(selectedKeys[0]))
      //         .forEach((ele, key) => {
      //           ele.isChecked = true;
      //           arr.push(ele.value);
      //         });

      //       setSelectedKeys([...selectedKeys, ...arr]);
      //     } else {
      //       filters.forEach((ele) => {
      //         ele.isChecked = false;
      //       });
      //       setSelectedKeys([]);
      //     }
      //   };

      //   let checker = (arr) => arr.every((v) => v === true);

      //   const getColumnSearchProps = (dataIndex, tabvalue) => ({
      //     filterDropdown: ({
      //       setSelectedKeys,
      //       selectedKeys,
      //       confirm,
      //       clearFilters,
      //       close,
      //       filters,
      //     }) => (
      //       <div
      //         style={{
      //           padding: 8,
      //         }}
      //         // onKeyDown={(e) => e.stopPropagation()}
      //         onClick={(e) => e?.stopPropagation()}
      //       >
      //         <Input
      //           ref={searchInput}
      //           placeholder={`Search ${dataIndex}`}
      //           value={selectedKeys[0]}
      //           onChange={(e) =>
      //             setSelectedKeys([
      //               (selectedKeys[0] = e.target.value),
      //               ...selectedKeys.splice(1),
      //             ])
      //           }
      //           style={{
      //             marginBottom: 8,
      //             display: "block",
      //           }}
      //         />

      //         {filters.filter((ele) =>
      //           ele.value.includes(
      //             selectedKeys[0] === undefined ? "" : selectedKeys[0]
      //           )
      //         ).length != 0 ? (
      //           <div>
      //             <Checkbox
      //               onClick={(e) =>
      //                 handleSelectAllFilters(
      //                   e,
      //                   filters,
      //                   selectedKeys,
      //                   setSelectedKeys,
      //                   dataIndex
      //                 )
      //               }
      //               checked={
      //                 filters.filter((ele) =>
      //                   ele.value.includes(
      //                     selectedKeys[0] === undefined ? "" : selectedKeys[0]
      //                   )
      //                 ).length != 0
      //                   ? checker(
      //                       filters
      //                         .filter((ele) =>
      //                           ele.value.includes(
      //                             selectedKeys[0] === undefined
      //                               ? ""
      //                               : selectedKeys[0]
      //                           )
      //                         )
      //                         .map((ele) => ele.isChecked == true)
      //                     )
      //                   : false
      //               }
      //             >
      //               Select all Items
      //             </Checkbox>

      //             <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
      //               {console.log(filters, selectedKeys)}
      //               {filters
      //                 .filter((ele) =>
      //                   ele.value.includes(
      //                     selectedKeys[0] === undefined ? "" : selectedKeys[0]
      //                   )
      //                 )
      //                 .map((ele, key) => {
      //                   return (
      //                     <>
      //                       <div>
      //                         <Checkbox
      //                           key={key}
      //                           checked={ele.isChecked}
      //                           onChange={(e) =>
      //                             onChangeFilter(
      //                               ele,
      //                               dataIndex,
      //                               selectedKeys,
      //                               setSelectedKeys,
      //                               filters
      //                             )
      //                           }
      //                         >
      //                           {ele.value}
      //                         </Checkbox>
      //                         <br></br>
      //                       </div>
      //                     </>
      //                   );
      //                 })}
      //             </div>

      //             <div className="dropDownBottom">
      //               <Button
      //                 onClick={() =>
      //                   handleReset(
      //                     setSelectedKeys,
      //                     confirm,
      //                     filters,
      //                     dataIndex,
      //                     tabvalue,
      //                     close
      //                   )
      //                 }
      //               >
      //                 Reset
      //               </Button>
      //               <Button
      //                 type="primary"
      //                 // disabled={selectedKeys.length>1?false:true}
      //                 onClick={() =>
      //                   handleSearch(
      //                     selectedKeys,
      //                     confirm,
      //                     dataIndex,
      //                     tabvalue,
      //                     close
      //                   )
      //                 }
      //               >
      //                 Apply
      //               </Button>
      //             </div>
      //           </div>
      //         ) : (
      //           <p style={{ textAlign: "center" }}>No Options Found</p>
      //         )}
      //       </div>
      //     ),

      //     filterMultiple: true,

      //     onFilter: (value, record) => {
      //       if (value.length != 0) {
      //         return record[dataIndex]
      //           .toString()
      //           .toLowerCase()
      //           .includes(value.toLowerCase());
      //       }
      //     },

      //     onFilterDropdownOpenChange: (visible) => {
      //       const tempColumns = [...columns];
      //       console.log(tempColumns[tabvalue]["columns"]);
      //       if (!visible) {
      //         tempColumns[tabvalue]["columns"].forEach((col) => {
      //           if (col.dataIndex === dataIndex) {
      //             col.filters.forEach((item) => {
      //               if (col.checkedOptions.includes(item.text)) {
      //                 item.isChecked = true;
      //               } else {
      //                 item.isChecked = false;
      //               }
      //             });
      //           }
      //         });
      //         dispatch(SetWarrantyColumns([...tempColumns]));
      //       } else {
      //         setTimeout(() => searchInput.current?.select(), 100);
      //       }
      //     },
      //     filterResetToDefaultFilteredValue: true,
      //     filterIcon: (filtered) => (
      //       <FilterAltIcon
      //         style={{
      //           color:
      //             columns[tabvalue]["columns"].filter(
      //               (element) => element.dataIndex === dataIndex
      //             )[0]["checkedOptions"].length > 0
      //               ? ThemeProperties.purple
      //               : undefined,
      //         }}
      //       />
      //     ),
      //     render: (text) => text,
      //   });

      const columns = [
        {
          name: "W001",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(batteryID, "Device ID")}
                </div>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Battery ID</Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(assetID, "Battery ID")}
                </div>
              ),
            },

            {
              title: (
                <Typography variant="tableHeadBold">
                  Warranty Condition
                </Typography>
              ),
              name: "warrantyCondition",
              dataIndex: "warrantyCondition",
              key: "warrantyCondition",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 160,
              uniqueKey: "warrantyConditionActive",
              render: (warrantyCondition) => (
                <Typography variant="tableRow">{warrantyCondition}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "onboardingDate",
                  "Onboarding Date"
                ),
              name: "onboardingDate",
              dataIndex: "onboardingDate",
              key: "onboardingDate",
              sorter: true,
              align: "center",
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              checkedOptions: [],
              width: 160,
              uniqueKey: "onboardingDate",
              render: (onboardingDate) => (
                <Typography variant="tableRow">
                  {moment(convertUTCtoZone(onboardingDate)).format("DD MMM'YY")}
                </Typography>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Validity Period</Typography>
              ),
              name: "validity",
              dataIndex: "warrantyInfo",
              key: "validity",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 160,
              uniqueKey: "validity",
              render: (warrantyInfo) => (
                <Typography variant="tableRow">
                  {warrantyInfo?.validity}
                </Typography>
              ),
            },
            {
              // title: (titleProps) =>
              //   getColumnTitleSort(titleProps, "age", "Age (months)"),
              title: (
                <Typography variant="tableHeadBold">Age (months)</Typography>
              ),
              name: "age",
              dataIndex: "age",
              key: "age",
              sorter: false,
              // align:"center",
              // sortDirections: ["ascend", "descend", "ascend"],
              // defaultSortOrder: "",
              // showSorterTooltip: false,
              checkedOptions: [],
              width: 130,
              uniqueKey: "age",
              render: (age) => (
                <Typography variant="tableRow">
                  {age ? Math.round(age / 30) : ""}
                </Typography>
              ),
            },
            {
              // title: (titleProps) =>
              //   getColumnTitleSort(titleProps, "cycles", "Cycles"),
              title: <Typography variant="tableHeadBold">Cycles</Typography>,
              name: "cycles",
              dataIndex: "cycles",
              key: "cycles",
              sorter: false,
              // align:"center",
              // sortDirections: ["ascend", "descend", "ascend"],
              // defaultSortOrder: "",
              // showSorterTooltip: false,
              checkedOptions: [],
              width: 100,
              uniqueKey: "cycles",
              render: (cycles) => (
                <Typography variant="tableRow">
                  {cycles ? Math.round(cycles) : ""}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">SOH (%)</Typography>,
              // title: (titleProps) =>
              //   getColumnTitleSort(titleProps, "soh", "SOH (%)"),
              name: "soh",
              dataIndex: "soh",
              key: "soh",
              sorter: false,
              // align:"center",
              // sortDirections: ["ascend", "descend", "ascend"],
              // defaultSortOrder: "",
              // showSorterTooltip: false,
              checkedOptions: [],
              width: 100,
              uniqueKey: "soh",
              render: (soh) => (
                <Typography variant="tableRow">{soh}</Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },
        {
          name: "W002",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(batteryID, "Device ID")}
                </div>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Battery ID</Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              width: 120,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(assetID, "Battery ID")}
                </div>
              ),
            },

            {
              title: (
                <Typography variant="tableHeadBold">
                  Warranty Condition
                </Typography>
              ),
              name: "warrantyCondition",
              dataIndex: "warrantyCondition",
              key: "warrantyCondition",
              sorter: false,
              showSorterTooltip: false,
              width: 160,
              uniqueKey: "warrantyConditionActive",
              render: (warrantyCondition) => (
                <Typography variant="tableRow">{warrantyCondition}</Typography>
              ),
            },
            {
              title: (titleProps) => {
                console.log(titleProps);
                return getColumnTitleSort(
                  titleProps,
                  "onboardingDate",
                  "Onboarding Date"
                );
              },
              name: "onboardingDate",
              dataIndex: "onboardingDate",
              key: "onboardingDate",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              sortColumn: "onboardingDate",
              align: "center",
              // checkedOptions: [],
              width: 160,
              uniqueKey: "onboardingDate",
              render: (onboardingDate) => (
                <Typography variant="tableRow">
                  {moment(convertUTCtoZone(onboardingDate)).format("DD MMM'YY")}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "$.accuracy", "Accuracy (%)"),
              name: "accuracy",
              dataIndex: "warrantyInfo",
              key: "$.accuracy",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              align: "center",
              width: 130,
              uniqueKey: "$.accuracy",
              render: (warrantyInfo) => (
                <Typography variant="tableRow">
                  {Math.round(warrantyInfo?.accuracy)}
                </Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },
        {
          name: "W003",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(batteryID, "Device ID")}
                </div>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Battery ID</Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              width: 120,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(assetID, "Battery ID")}
                </div>
              ),
            },

            {
              title: (
                <Typography variant="tableHeadBold">
                  Warranty Condition
                </Typography>
              ),
              name: "warrantyCondition",
              dataIndex: "warrantyCondition",
              key: "warrantyCondition",
              sorter: false,
              showSorterTooltip: false,
              width: 160,
              uniqueKey: "warrantyConditionActive",
              render: (warrantyCondition) => (
                <Typography variant="tableRow">{warrantyCondition}</Typography>
              ),
            },
            {
              title: (titleProps) => {
                console.log(titleProps);
                return getColumnTitleSort(
                  titleProps,
                  "onboardingDate",
                  "Onboarding Date"
                );
              },
              name: "onboardingDate",
              dataIndex: "onboardingDate",
              key: "onboardingDate",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              align: "center",
              width: 160,
              uniqueKey: "onboardingDate",
              render: (onboardingDate) => (
                <Typography variant="tableRow">
                  {moment(convertUTCtoZone(onboardingDate)).format("DD MMM'YY")}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "lastPingedTime",
                  "Last Pinged"
                ),
              name: "lastPingedTime",
              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "ascend",
              showSorterTooltip: false,
              align: "center",
              showSorterTooltip: false,
              width: 160,
              uniqueKey: "lastPingedTime",
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "$.avgPingFrequency",
                  "Average Ping Frequency (s)"
                ),
              name: "avgPingFrequency",
              dataIndex: "warrantyInfo",
              key: "$.avgPingFrequency",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              align: "center",
              showSorterTooltip: false,
              width: 220,
              uniqueKey: "avgPingFrequency",
              render: (warrantyInfo) => (
                <Typography variant="tableRow">
                  {warrantyInfo?.avgPingFrequency}
                </Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },
        {
          name: "W004",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(batteryID, "Device ID")}
                </div>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Battery ID</Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(assetID, "Battery ID")}
                </div>
              ),
            },

            {
              title: (
                <Typography variant="tableHeadBold">
                  Warranty Condition
                </Typography>
              ),
              name: "warrantyCondition",
              dataIndex: "warrantyCondition",
              key: "warrantyCondition",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 160,
              uniqueKey: "warrantyConditionActive",
              render: (warrantyCondition) => (
                <Typography variant="tableRow">{warrantyCondition}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "onboardingDate",
                  "Onboarding Date"
                ),
              name: "onboardingDate",
              dataIndex: "onboardingDate",
              key: "onboardingDate",
              sorter: true,
              align: "center",
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              checkedOptions: [],
              width: 160,
              uniqueKey: "onboardingDate",
              render: (onboardingDate) => (
                <Typography variant="tableRow">
                  {moment(convertUTCtoZone(onboardingDate)).format("DD MMM'YY")}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "$.correctPingFraction",
                  "Correct Ping (%)"
                ),
              name: "correctPingFraction",
              dataIndex: "warrantyInfo",
              key: "$.correctPingFraction",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              align: "center",

              checkedOptions: [],
              width: 160,
              uniqueKey: "correctPingFraction",
              render: (warrantyInfo) => (
                <Typography variant="tableRow">
                  {warrantyInfo?.correctPingFraction}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "$.avgPingFrequency",
                  "Average Ping Frequency (s)"
                ),
              name: "avgPingFrequency",
              dataIndex: "warrantyInfo",
              key: "$.avgPingFrequency",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              align: "center",
              showSorterTooltip: false,
              checkedOptions: [],
              width: 220,
              uniqueKey: "avgPingFrequency",
              render: (warrantyInfo) => (
                <Typography variant="tableRow">
                  {warrantyInfo?.avgPingFrequency}
                </Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },
        {
          name: "W005",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(batteryID, "Device ID")}
                </div>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Battery ID</Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(assetID, "Battery ID")}
                </div>
              ),
            },

            {
              title: (
                <Typography variant="tableHeadBold">
                  Warranty Condition
                </Typography>
              ),
              name: "warrantyCondition",
              dataIndex: "warrantyCondition",
              key: "warrantyCondition",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 160,
              uniqueKey: "warrantyConditionActive",
              render: (warrantyCondition) => (
                <Typography variant="tableRow">{warrantyCondition}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "onboardingDate",
                  "Onboarding Date"
                ),
              name: "onboardingDate",
              dataIndex: "onboardingDate",
              key: "onboardingDate",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              align: "center",

              checkedOptions: [],
              width: 160,
              uniqueKey: "onboardingDate",
              render: (onboardingDate) => (
                <Typography variant="tableRow">
                  {moment(convertUTCtoZone(onboardingDate)).format("DD MMM'YY")}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "$.maxPackVolt",
                  "Highest Voltage Recorded (V)"
                ),
              name: "maxPackVolt",
              dataIndex: "warrantyInfo",
              key: "$.maxPackVolt",

              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              align: "center",

              checkedOptions: [],
              width: 240,
              uniqueKey: "maxPackVolt",
              render: (warrantyInfo) => (
                <Typography variant="tableRow">
                  {warrantyInfo.maxPackVolt
                    ? warrantyInfo.maxPackVolt.toFixed(1)
                    : ""}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "$.breachStableTime",
                  "Average Stabilisation Time (s)"
                ),
              name: "breachStableTime",
              dataIndex: "warrantyInfo",
              key: "$.breachStableTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              align: "center",
              checkedOptions: [],
              width: 240,
              uniqueKey: "breachStableTime",
              render: (warrantyInfo) => (
                <Typography variant="tableRow">
                  {warrantyInfo?.breachStableTime}
                </Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },
        {
          name: "W006",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(batteryID, "Device ID")}
                </div>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Battery ID</Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(assetID, "Battery ID")}
                </div>
              ),
            },

            {
              title: (
                <Typography variant="tableHeadBold">
                  Warranty Condition
                </Typography>
              ),
              name: "warrantyCondition",
              dataIndex: "warrantyCondition",
              key: "warrantyCondition",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 160,
              uniqueKey: "warrantyConditionActive",
              render: (warrantyCondition) => (
                <Typography variant="tableRow">{warrantyCondition}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "onboardingDate",
                  "Onboarding Date"
                ),
              name: "onboardingDate",
              dataIndex: "onboardingDate",
              key: "onboardingDate",
              sorter: true,
              align: "center",
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              checkedOptions: [],
              width: 160,
              uniqueKey: "onboardingDate",
              render: (onboardingDate) => (
                <Typography variant="tableRow">
                  {moment(convertUTCtoZone(onboardingDate)).format("DD MMM'YY")}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "$.maxPackTemp",
                  "Highest Temp Recorded (°C)"
                ),
              name: "maxPackTemp",
              dataIndex: "warrantyInfo",
              key: "$.maxPackTemp",
              sorter: true,
              align: "center",
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              checkedOptions: [],
              width: 230,
              uniqueKey: "maxPackTemp",
              render: (warrantyInfo) => (
                <Typography variant="tableRow">
                  {warrantyInfo?.maxPackTemp}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "$.breachStableTime",
                  "Average Stabilisation Time (s)"
                ),
              name: "breachStableTime",
              dataIndex: "warrantyInfo",
              key: "$.breachStableTime",
              sorter: true,
              align: "center",
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              checkedOptions: [],
              width: 240,
              uniqueKey: "breachStableTime",
              render: (warrantyInfo) => (
                <Typography variant="tableRow">
                  {warrantyInfo?.breachStableTime}
                </Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },
        {
          name: "W007",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              fixed: "left",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(batteryID, "Device ID")}
                </div>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Battery ID</Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(assetID, "Battery ID")}
                </div>
              ),
            },

            {
              title: (
                <Typography variant="tableHeadBold">
                  Warranty Condition
                </Typography>
              ),
              name: "warrantyCondition",
              dataIndex: "warrantyCondition",
              key: "warrantyCondition",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 160,
              uniqueKey: "warrantyConditionActive",
              render: (warrantyCondition) => (
                <Typography variant="tableRow">{warrantyCondition}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "onboardingDate",
                  "Onboarding Date"
                ),
              name: "onboardingDate",
              dataIndex: "onboardingDate",
              key: "onboardingDate",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              align: "center",
              checkedOptions: [],
              width: 160,
              uniqueKey: "onboardingDate",
              render: (onboardingDate) => (
                <Typography variant="tableRow">
                  {moment(convertUTCtoZone(onboardingDate)).format("DD MMM'YY")}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "lastPingedTime",
              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              align: "center",
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              checkedOptions: [],
              width: 160,
              uniqueKey: "lastPingedTime",
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(
                  titleProps,
                  "$.avgPingFrequency",
                  "Average Ping Frequency (s)"
                ),
              name: "avgPingFrequency",
              dataIndex: "warrantyInfo",
              key: "$.avgPingFrequency",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder: "",
              showSorterTooltip: false,
              align: "center",
              showSorterTooltip: false,
              checkedOptions: [],
              width: 220,
              uniqueKey: "avgPingFrequency",
              render: (warrantyInfo) => (
                <Typography variant="tableRow">
                  {warrantyInfo?.avgPingFrequency}
                </Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },
      ];

      dispatch(SetWarrantyColumns(columns));
      dispatch(SetWarrantyHiddenColumns([]));
    }, 2);

    return () => {
      setPagesContent({
        ...pagesContent,
        pageHeader: "",
        filtersWithData: [],
        filteredArray: [],
        batteryType: "",
        mounted: false,
      });
    };
  }, []);

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <WarrantyMain pagesContent={pagesContent} />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}

export default Warranty;
