import * as React from "react";
const ImageIcon = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.2499 2.25H3.74999C2.92157 2.25 2.25 2.92157 2.25 3.74999V14.2499C2.25 15.0783 2.92157 15.7499 3.74999 15.7499H14.2499C15.0783 15.7499 15.7499 15.0783 15.7499 14.2499V3.74999C15.7499 2.92157 15.0783 2.25 14.2499 2.25Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.75 11.25L12 7.5L3.75 15.75" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.37513 7.50026C6.99652 7.50026 7.50026 6.99652 7.50026 6.37513C7.50026 5.75374 6.99652 5.25 6.37513 5.25C5.75374 5.25 5.25 5.75374 5.25 6.37513C5.25 6.99652 5.75374 7.50026 6.37513 7.50026Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
ImageIcon.defaultProps = {
  width: "18",
  height: "18",
  viewBox: "0 0 18 18",
  fill: null,
  stroke: "#444B51",
};
export default ImageIcon;

