const combineWithUnderscore = (str1, str2, str3, str4) => {
  const result = [str1, str2, str3, str4].filter(Boolean).join('_');

  return result;

}

const toTitleCase = (text) => {
  if (!text) {
    return text
  }

  return text.toLowerCase().split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
}

const getTruncatedName = (name, characterLimit, leftSideLength=5, rightSideLength=12) => {
  return name?.length > characterLimit
        ? name.substring(0, leftSideLength) +
        "..." +
        name.substring(name.length - rightSideLength)
        : name;
}

export { combineWithUnderscore, toTitleCase, getTruncatedName }