import {
  Box,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { ThemeProperties } from "../../../Theme/ThemeProperties";

function ConfigFormCellDetails({
  isEdit,
  form,
  formValidated,
  handleChangeSelect,
  showCellChemistryTextField,
  showCellOEMTextField,
  showCellModelNumberTextField,
  showCellFormFactorTextField,
  handleDiscardToDropdown,
}) {
  const configFilters = useSelector(
    (state) => state.DigitalTwinsConfigFilters.value
  );

  const cellOEMFilters = configFilters.find((x) => x.type === "cellOEM")?.value;
  const cellModelNumberFilters = configFilters.find(
    (x) => x.type === "cellModelNumber"
  )?.value;
  const cellChemistryFilters = configFilters.find(
    (x) => x.type === "cellChemistry"
  )?.value;
  const cellFormFactorFilters = configFilters.find(
    (x) => x.type === "formFactorCell"
  )?.value;

  const fields = [
    {
      key: "cellOEM",
      label: "Cell Manufacturer",
      type: "c-dropdown",
      state: showCellOEMTextField,
      required: false,
      onChangeFieldState: () => handleDiscardToDropdown("cellOEM"),
    },
  ];

  return (
    <FormGroup>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="space-around"
        margin={3}
        rowGap={2}
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Cell Manufacturer
          </FormLabel>
          {showCellOEMTextField ? (
            <>
              <TextField
                style={{ width: "300px" }}
                inputProps={{
                  style: { fontSize: 14, padding: 7, marginLeft: 3 },
                }}
                size="small"
                variant="outlined"
                value={form?.cellOEM}
                name="cellOEM"
                onChange={handleChangeSelect}
                disabled={!isEdit}
                autoFocus
              />
              {!formValidated?.cellOEM && (
                <Typography color="red">
                  {showCellOEMTextField &&
                  cellOEMFilters.includes(form?.cellOEM)
                    ? "Value already exists"
                    : "This field is required"}
                </Typography>
              )}
              <IconButton onClick={() => handleDiscardToDropdown("cellOEM")}>
                <CloseIcon style={{ fontSize: 16 }} />
              </IconButton>
            </>
          ) : (
            <Select
              style={{ width: "300px", fontSize: "small" }}
              size="small"
              variant="outlined"
              name="cellOEM"
              onChange={handleChangeSelect}
              disabled={!isEdit}
              value={form?.cellOEM}
            >
              {cellOEMFilters?.map((item) => (
                <MenuItem sx={{ fontSize: "small" }} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem
                sx={{ fontSize: "small", color: ThemeProperties.Purple1 }}
                value="%add%"
              >
                Add new
              </MenuItem>
            </Select>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Cell Model Number
          </FormLabel>
          {showCellModelNumberTextField ? (
            <>
              <TextField
                style={{ width: "300px" }}
                inputProps={{
                  style: { fontSize: 14, padding: 7, marginLeft: 3 },
                }}
                size="small"
                variant="outlined"
                value={form?.cellModelNumber}
                name="cellModelNumber"
                onChange={handleChangeSelect}
                disabled={!isEdit}
                autoFocus
              />
              {!formValidated?.cellModelNumber && (
                <Typography color="red">
                  {showCellModelNumberTextField &&
                  cellModelNumberFilters.includes(form?.cellModelNumber)
                    ? "Value already exists"
                    : "This field is required"}
                </Typography>
              )}
              <IconButton
                onClick={() => handleDiscardToDropdown("cellModelNumber")}
              >
                <CloseIcon style={{ fontSize: 16 }} />
              </IconButton>
            </>
          ) : (
            <Select
              style={{ width: "300px" }}
              sx={{ fontSize: "small" }}
              size="small"
              variant="outlined"
              name="cellModelNumber"
              onChange={handleChangeSelect}
              disabled={!isEdit}
              value={form?.cellModelNumber}
            >
              {cellModelNumberFilters?.map((item) => (
                <MenuItem sx={{ fontSize: "small" }} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem
                sx={{ fontSize: "small", color: ThemeProperties.Purple1 }}
                value="%add%"
              >
                Add new
              </MenuItem>
            </Select>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Cell Chemistry
          </FormLabel>
          {showCellChemistryTextField ? (
            <>
              <TextField
                style={{ width: "300px" }}
                inputProps={{
                  style: { fontSize: 14, padding: 7, marginLeft: 3 },
                }}
                size="small"
                variant="outlined"
                value={form?.cellChemistry}
                name="cellChemistry"
                onChange={handleChangeSelect}
                disabled={!isEdit}
                autoFocus
              />
              {!formValidated?.cellChemistry && (
                <Typography color="red">
                  {showCellChemistryTextField &&
                  cellChemistryFilters.includes(form?.cellChemistry)
                    ? "Value already exists"
                    : "This field is required"}
                </Typography>
              )}
              <IconButton
                onClick={() => handleDiscardToDropdown("cellChemistry")}
              >
                <CloseIcon style={{ fontSize: 16 }} />
              </IconButton>
            </>
          ) : (
            <Select
              style={{ width: "300px" }}
              sx={{ fontSize: "small" }}
              size="small"
              variant="outlined"
              name="cellChemistry"
              onChange={handleChangeSelect}
              disabled={!isEdit}
              value={form?.cellChemistry}
            >
              {cellChemistryFilters?.map((item) => (
                <MenuItem sx={{ fontSize: "small" }} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem
                sx={{ fontSize: "small", color: ThemeProperties.Purple1 }}
                value="%add%"
              >
                Add new
              </MenuItem>
            </Select>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Nominal Voltage (V)
          </FormLabel>
          <TextField
            style={{ width: "300px" }}
            size="small"
            type="number"
            variant="outlined"
            value={form?.ratedNominalVoltCell}
            name="ratedNominalVoltCell"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            inputProps={{
              style: { fontSize: 14, padding: 7, marginLeft: 3 },
            }}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Max Voltage (V)
          </FormLabel>
          <TextField
            style={{ width: "300px" }}
            size="small"
            type="number"
            variant="outlined"
            value={form?.ratedMaxVoltCell}
            name="ratedMaxVoltCell"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            inputProps={{
              style: { fontSize: 14, padding: 7, marginLeft: 3 },
            }}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Min Voltage (V)
          </FormLabel>
          <TextField
            style={{ width: "300px" }}
            size="small"
            type="number"
            variant="outlined"
            value={form?.ratedMinVoltCell}
            name="ratedMinVoltCell"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            inputProps={{
              style: { fontSize: 14, padding: 7, marginLeft: 3 },
            }}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Energy (Wh)
          </FormLabel>
          <TextField
            style={{ width: "300px" }}
            size="small"
            type="number"
            variant="outlined"
            value={form?.ratedEnergyCell}
            name="ratedEnergyCell"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            inputProps={{
              style: { fontSize: 14, padding: 7, marginLeft: 3 },
            }}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Capacity (Ah)
          </FormLabel>
          <TextField
            style={{ width: "300px" }}
            size="small"
            type="number"
            variant="outlined"
            value={form?.ratedCapacityCell}
            name="ratedCapacityCell"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            inputProps={{
              style: { fontSize: 14, padding: 7, marginLeft: 3 },
            }}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Form Factor
          </FormLabel>
          {showCellFormFactorTextField ? (
            <>
              <TextField
                style={{ width: "300px" }}
                inputProps={{
                  style: { fontSize: 14, padding: 7, marginLeft: 3 },
                }}
                size="small"
                variant="outlined"
                value={form?.formFactorCell}
                name="formFactorCell"
                onChange={handleChangeSelect}
                disabled={!isEdit}
                autoFocus
              />
              <IconButton
                onClick={() => handleDiscardToDropdown("formFactorCell")}
              >
                <CloseIcon style={{ fontSize: 16 }} />
              </IconButton>
              {!formValidated?.formFactorCell && (
                <Typography color="red">
                  {showCellFormFactorTextField &&
                  cellFormFactorFilters.includes(form?.formFactorCell)
                    ? "Value already exists"
                    : "This field is required"}
                </Typography>
              )}
            </>
          ) : (
            <Select
              style={{ width: "300px", fontSize: "small" }}
              size="small"
              variant="outlined"
              name="formFactorCell"
              onChange={handleChangeSelect}
              disabled={!isEdit}
              value={form?.formFactorCell}
            >
              {cellFormFactorFilters?.map((item) => (
                <MenuItem sx={{ fontSize: "small" }} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem
                sx={{ fontSize: "small", color: ThemeProperties.Purple1 }}
                value="%add%"
              >
                Add new
              </MenuItem>
            </Select>
          )}
        </Box>
        {/* <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel sx={{ fontSize: 14, paddingTop: 0.7, width: 200,
          color: ThemeProperties.black, }}>
            Add Data Sheet
          </FormLabel>
          <SecondaryButton variant="outlined" component="label">
            Add Data Sheet
            <input
              type="file"
              hidden
              accept=".csv"
              onChange={handleFileUpload}
            />
          </SecondaryButton>
        </Box> */}
      </Box>
    </FormGroup>
  );
}

export default ConfigFormCellDetails;
