import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import Events from "../../../Analytics/Events";
import { toTitleCase } from "../../../Helper/Formatters/TextFormatter";
import CustomIcon from "../../../Components/CustomIcon";
import CardWithHeader from "../../../Features/Cards/CardWithHeader";
import { useNotification } from "../../../Hooks/useNotification";
import { getMetricTrendData } from "../../../Api/AlertAnalyticsApi";
import CODE from "../../../Static/Constants/StatusCodes";
import { findYAxisPartitionSizeForPositiveAxis, getXAxisDateString, getXAxisLabelForWeeklyGranularity, nextDivisibleBy } from "../../../Helper/Charts/ChartHelper";
import AnalyticsStackBarPlusLine from "../../../Charts/Mixed/AnalyticsStackBarPlusLine";
import { formatDateWithoutYear } from "../../../Helper/DatePicker/DateFormatters";
import { encryptTheParams } from "../../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate } from "react-router-dom";

const defaultChartState = {
  series: {
    line: [{x: null, y: 1}, {x: null, y: 1}, {x: null, y: 2}, {x: null, y: 3}, {x: null, y: 4}, {x: null, y: 5}], 
    column:[{x: null, y: 1}, {x: null, y: 1}, {x: null, y: 2}, {x: null, y: 3}, {x: null, y: 4}, {x: null, y: 5}]
  },
  statusCode: CODE.LOADING
}

const numberOfYAxisPartition = 5

const linePlotYAxisSetting = {
  show: false
}

const FleetAlertDistributionChart = ({ filters, startDate, endDate, granularity, cardSubtitleTimePeriodText, clickableHeader, chartSelectedFilter }) => {
  const navigate = useNavigate()
  const { openNotification, closeNotification } = useNotification();
  const [chartState, setChartState] = useState(defaultChartState);
  const [safetyAlertCount, setSafetyAlertCount] = useState(0)
  const [operationalAlertCount, setOperationalAlertCount] = useState(0)
  const [violationAlertCount, setViolationAlertCount] = useState(0)
  const defaultFilterValues = useSelector((state) => state.SelectedFilterDefaultValues.value);
  const yAxisPartitionSize = findYAxisPartitionSizeForPositiveAxis(safetyAlertCount + operationalAlertCount + violationAlertCount)
  const isFilterMounted = useSelector((state) => state.IsFiltersMounted.value);
  const barPlotYAxisSetting = {
    title: {
      text: 'Alert Count',
      style: {
        color: "#87939D",
        fontSize: '12px',
        fontFamily: 'Inter, Helvetica, Arial, sans-serif',
        fontWeight: 500,
      },
    },
    showAlways: true,
    axisBorder: {
      show: false,
      color: '#87939D',
      offsetX: 2.5,
      offsetY: 0
    },
    labels: {
      style: {
          colors: '#87939D',
          fontSize: '12px',
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          fontWeight: 400,
      },
    },
    tickAmount: 5,
    max: nextDivisibleBy(safetyAlertCount + operationalAlertCount + violationAlertCount, yAxisPartitionSize * numberOfYAxisPartition)
  }
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })

  useEffect(() => {
    if (isFilterMounted) {
      fetchData();
      chartSelectedFilter(true, null, null)
    }
  }, [
    granularity,
    filters,
    isFilterMounted
  ])

  const fetchData = async () => {
    setChartState(defaultChartState);
    const res = await getMetricTrendData(["safety", "operational", "violation"], startDate, endDate, granularity.granularity, filters, defaultFilterValues)
    const { responseStatus, response } = res;
    
    if (responseStatus.code !== CODE.SUCCESS) {
      setChartState({
        ...defaultChartState,
        statusCode: CODE.NODATA
      });
      return
    }

    generateChartData(response, responseStatus);
  }

  const generateChartData = (response, responseStatus) => {
    const dataLength = response.data?.length;
    let maximumSafetyAlertCount = 0;
    let maximumOperationalAlertCount = 0;
    let maximumViolationAlertCount = 0;
    const series = {
      column: [
        {
          name: "Safety",
          type: "bar",
          stacked: true,
          color: "#CF0001",
          data: response.data?.map(data => {
              const value = data.metricsArray.find(item => item.type == 'safety')?.value
              maximumSafetyAlertCount = Math.max(maximumSafetyAlertCount, value)
              
              let x, dateRangeString
              if (granularity.granularity === 'daily') {
                const date = new Date(data.range.startDate);
                x = formatDateWithoutYear(date)
                dateRangeString = x
              } else {
                const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
                x = result.x;
                dateRangeString = result.dateRangeString;
              }

              return {
                x,
                y: value,
                dateRangeString
              }
          })
        },
        {
          name: "Operational",
          type: "bar",
          stacked: true,
          color: "#FBC562",
          data: response.data?.map(data => {
              const value = data.metricsArray.find(item => item.type == 'operational')?.value
              maximumOperationalAlertCount = Math.max(maximumOperationalAlertCount, value)
              
              let x, dateRangeString
              if (granularity.granularity === 'daily') {
                const date = new Date(data.range.startDate);
                x = formatDateWithoutYear(date)
                dateRangeString = x
              } else {
                const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
                x = result.x;
                dateRangeString = result.dateRangeString;
              }

              return {
                x,
                y: value,
                dateRangeString
              }
          })
        },
        {
          name: "Violation",
          type: "bar",
          stacked: true,
          color: "#D3A1B5",
          data: response.data?.map(data => {
              const value = data.metricsArray.find(item => item.type == 'violation')?.value
              maximumViolationAlertCount = Math.max(maximumViolationAlertCount, value)
              
              let x, dateRangeString
              if (granularity.granularity === 'daily') {
                const date = new Date(data.range.startDate);
                x = formatDateWithoutYear(date)
                dateRangeString = x
              } else {
                const result = getXAxisDateString(data.range.startDate, data.range.endDate, dataLength, granularity.granularity, true)
                x = result.x;
                dateRangeString = result.dateRangeString;
              }

              return {
                x,
                y: value,
                dateRangeString
              }
          })
        }
      ],
      line: null,
    };

    setSafetyAlertCount(Math.round(maximumSafetyAlertCount * 100) / 100)
    setOperationalAlertCount(Math.round(maximumOperationalAlertCount * 100) / 100)
    setViolationAlertCount(Math.round(maximumViolationAlertCount * 100) / 100)

    setChartState({
      series: series, 
      statusCode: responseStatus.code
    })
  }

  const generateCsvData = () => {
    const formatItem = (safetyAlertData, operationalAlertData, violationAlertData) => ({
      "Date Range": safetyAlertData.dateRangeString,
      "Safety Alert Count": safetyAlertData.y,
      "Operational Alert Count": operationalAlertData.y,
      "Violation Alert Count": violationAlertData.y
    });

    if (chartState.statusCode !== CODE.SUCCESS) {
      return
    }
    const data = [
      ...chartState.series.column[0].data.map((safetyAlertDataRow, index) => formatItem(
        safetyAlertDataRow, 
        chartState.series.column[1].data[index],
        chartState.series.column[2].data[index]
      )),
    ];

    setCsvData({
      data,
      responseStatus: { code: CODE.SUCCESS }
    });
  }

  const onClickHeader = () => {
    const temp = {
      chartName: 'fleetAlertDistribution',
      startDate,
      endDate 
    };
    encryptTheParams(temp, navigate, false, '/AlertDrillDown', true);
  }
  
  return (
    <CardWithHeader
      id="fleetAlertDistributionChart"
      headerClass="header-hover"
      title="Fleet Alert Distribution"
      subtitle={`${cardSubtitleTimePeriodText}`}
      iconElement={<CustomIcon name="barGraph" style={{ width: 21, height: 21 }}/>}
      pageLabel="Alert Analytics"
      showCSVDownload
      csvData={csvData}
      csvName={"FleetAlertDistributionChart"}
      generateCsvData={generateCsvData}
      onMouseEnter={() =>
        Events("Alert Analytics: Hover over Fleet Alert Distribution Chart")
      }
      openNotification={openNotification}
      closeNotification={closeNotification}
      cardStyle={{ height: "464px" }}
      bodyStyle={{
        height: "358px",
        position: "relative",
        paddingLeft: 0,
        paddingRight: "8px",
      }}
      clickableHeader={clickableHeader}
      onClickHeader={onClickHeader}
    >
      <AnalyticsStackBarPlusLine
        hideAlternateLabels={granularity.granularity === "daily"  && chartState.series?.column[0]?.data?.length > 15}
        state={chartState.statusCode}
        granularity={toTitleCase(granularity.granularity)}
        stackBarPlotDataSeriesList={chartState.series.column}
        linePlotDataSeries={chartState.series.line}
        barPlotYAxisSetting={barPlotYAxisSetting}
        linePlotYAxisSetting={linePlotYAxisSetting}
      />
    </CardWithHeader>
  );
}

FleetAlertDistributionChart.defaultProps = {
  clickableHeader: false,
  chartSelectedFilter: () => {}
}

export default FleetAlertDistributionChart;
