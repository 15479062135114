const CODE = {
    LOADING : -1,
    SESSION_EXPIRED : -2,
    SUCCESS : 200,
    NODATA : 404,
    UNAUTHORIZED : 401,
    FORBIDDEN: 403,
    EXCEPTION_EARLY_REFRESH : 1995,
    EXCEPTION_EXPIRED_REFRESH : 1994,
    ERR_MSSG : "Error : Could not find token in any of the given locations: ['cookie', 'header'] (401)",

    //Original response from URL

    URL_NODATA : 404,
    URL_CREATED : 201


}

export default CODE