import React from "react"
import PageHeaderSection from "../../PagesContent/ChargingAnalytics/PageHeaderSection"
import { FullWidthSeparator, InnerBox, OuterBox } from "../../Components/CustomBox"
import KPISection from "../../PagesContent/ChargingAnalytics/KPISection"
import ChartsSection from "../../PagesContent/ChargingAnalytics/ChartsSection"
import { PageContainer, PageSectionContainer } from "../../PagesContent/ChargingAnalytics/Components/StyledComponent"

function ChargingAnalyticsMain({ pagesContent }) {
  return (
    <PageContainer>
      <PageSectionContainer>
        <PageHeaderSection pagesContent={pagesContent}/>
        <KPISection pagesContent={pagesContent}/>
      </PageSectionContainer>
      <FullWidthSeparator/>
      <PageSectionContainer>
        <ChartsSection pagesContent={pagesContent}/>
      </PageSectionContainer>
    </PageContainer>
  )
}

export default ChargingAnalyticsMain
