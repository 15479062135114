import {
  getBatteryVitals,
  getPackComparision,
  getChargerVitals,
  getGranularityData,
  getChargerGranularityData,
} from "../../../../Api/DigitalTwin";
import { convertUTCtoZone } from "../../../../Helper/DatePicker/DateConverter";
import { formatDate } from "../../../../Helper/DatePicker/DateFormatters";
import CODE from "../../../../Static/Constants/StatusCodes";

const returnGranularity = (granular, startDate, endDate) => {
  return granular === "Daily"
    ? formatDate(new Date(startDate))
    : granular === "Monthly"
      ? new Date(startDate).toLocaleString("en-us", {
          month: "long",
        })
      : formatDate(new Date(startDate)) + " - " + formatDate(new Date(endDate));
};

async function BatteryPackMetrics(
  batteryID,
  pack,
  module,
  cell,
  level,
  statusClicked,
  date
) {
  let obj = {
    data: [],
    responseStatus: {
      code: -1,
    },
  };
  return getBatteryVitals(batteryID, pack, module, cell, level, [
    date[0],
    date[1],
  ]).then((res) => {
    let csvData = [];
    if (res.responseStatus.code === 200) {
      res.response?.batteryData?.forEach((item) => {
        const row = {};
        if (statusClicked.Current) row["Current (A)"] = item.current;
        if (statusClicked.Voltage) row["Voltage (V)"] = item.voltage;
        if (statusClicked.SOC) row["SOC (%)"] = item.soc;
        if (statusClicked.Temperature)
          row["Temperature (°C)"] = item.temperature;

        row.Status = item.batteryStatus;
        row.Timestamp = item.batteryDateTime
          ? convertUTCtoZone(item.batteryDateTime, true).replace(/,/g, "")
          : "";
        csvData.push(row);
      });
      obj = {
        data: csvData,
        responseStatus: {
          code: res.responseStatus.code,
        },
      };
      return obj;
    } else {
      obj = {
        data: [],
        responseStatus: {
          code: res.responseStatus.code,
        },
      };
      return obj;
    }
  });
}

async function GetTheMetric(
  batteryID,
  pack,
  module,
  cell,
  level,
  type,
  date,
  columnName
) {
  let obj = {
    data: [],
    responseStatus: {
      code: -1,
    },
  };
  return getPackComparision(batteryID, pack, module, cell, level, type, [
    date[0],
    date[1],
  ]).then((res) => {
    let csvData = [];
    if (res.responseStatus.code === 200) {
      res.response?.batteryData?.forEach((item) => {
        csvData.push({
          Timestamp: item.batteryDateTime
            ? convertUTCtoZone(item.batteryDateTime, true).replace(/,/g, "")
            : "",
          [columnName]: Math.round(item[type]),
        });
      });
      obj = {
        data: csvData,
        responseStatus: {
          code: res.responseStatus.code,
        },
      };
      return obj;
    } else {
      obj = {
        data: [],
        responseStatus: {
          code: res.responseStatus.code,
        },
      };
      return obj;
    }
  });
}

async function GetTheGranularityTrend(
  batteryID,
  pack,
  module,
  cell,
  level,
  value,
  date,
  granular,
  operation,
  statusClicked,
  columnName,
  type
) {
  let obj = {
    data: [],
    responseStatus: {
      code: -1,
    },
  };
  return getGranularityData(
    batteryID,
    pack,
    module,
    cell,
    level,
    value,
    date,
    granular,
    operation
  ).then((res) => {
    let csvData = [];
    if (res.responseStatus.code === 200) {
      if (type === "Single") {
        res?.response?.batteryData?.forEach((item) => {
          csvData.push({
            Date: returnGranularity(
              granular,
              item["range"]["startDate"],
              item["range"]["endDate"]
            ),
            [columnName]: item["metricsArray"][0]["value"],

            "Operation Type": operation,
          });
        });
      } else if (type === "Battery Key Metrics") {
        res?.response?.batteryData?.forEach((item) => {
          const row = {
            Date: returnGranularity(
              granular,
              item["range"]["startDate"],
              item["range"]["endDate"]
            ),
          };
          if (statusClicked.SOC) {
            row["SOC (%)"] = item["metricsArray"].find(
              (i) => i.type === "soc"
            )?.value;
          }
          if (statusClicked.Temperature) {
            row["Temperature (°C)"] = item["metricsArray"].find(
              (i) => i.type === "temperature"
            )?.value;
          }
          if (statusClicked.Current) {
            row["Current (A)"] = item["metricsArray"].find(
              (i) => i.type === "current"
            )?.value;
          }
          if (statusClicked.Voltage) {
            row["Voltage (V)"] = item["metricsArray"].find(
              (i) => i.type === "voltage"
            )?.value;
          }
          row["Operation Type"] = operation;
          csvData.push(row);
        });
      }
      console.log(csvData);
      obj = {
        data: csvData,
        responseStatus: {
          code: res.responseStatus.code,
        },
      };
      return obj;
    } else {
      obj = {
        data: [],
        responseStatus: {
          code: res.responseStatus.code,
        },
      };
      return obj;
    }
  });
}

async function ChargerVitals(batteryID, level, date) {
  let obj = {
    data: [],
    responseStatus: {
      code: -1,
    },
  };
  return getChargerVitals(batteryID, level, [date[0], date[1]]).then((res) => {
    let csvData = [];
    if (res.responseStatus.code === 200) {
      res.response?.batteryData?.forEach((item) => {
        const row = {};
        if (level.includes("solarInputCurrent"))
          row["Current (A)"] = item.metricsArray
            .find((i) => i.type === "solarInputCurrent")
            ["value"]?.toFixed(2);
        if (level.includes("solarInputVoltage"))
          row["Voltage (V)"] = item.metricsArray
            .find((i) => i.type === "solarInputVoltage")
            ["value"]?.toFixed(3);
        if (level.includes("solarInputPower"))
          row["Power (W)"] = item.metricsArray
            .find((i) => i.type === "solarInputPower")
            ["value"]?.toFixed(1);

        row.Timestamp = item.batteryDateTime
          ? convertUTCtoZone(item.batteryDateTime, true).replace(/,/g, "")
          : "";

        csvData.push(row);
      });
      obj = {
        data: csvData,
        responseStatus: {
          code: res.responseStatus.code,
        },
      };
      return obj;
    } else {
      obj = {
        data: [],
        responseStatus: {
          code: res.responseStatus.code,
        },
      };
      return obj;
    }
  });
}

async function GetTheChargerGranularityTrend(
  batteryID,
  columns,
  date,
  granular,
  operation
) {
  let obj = {
    data: [],
    responseStatus: {
      code: -1,
    },
  };
  return getChargerGranularityData(
    batteryID,
    columns,
    date,
    granular,
    operation
  ).then((res) => {
    let csvData = [];
    if (res.responseStatus.code === 200) {
      let tempCurrent, tempVoltage, tempPower;
      res.response?.batteryData?.forEach((item) => {
        const row = {
          Date: returnGranularity(
            granular,
            item["range"]["startDate"],
            item["range"]["endDate"]
          ),
        };
        if (columns.includes("solarInputCurrent")) {
          tempCurrent = item.metricsArray.find(
            (i) => i.type === "solarInputCurrent"
          )["value"];
          row["Current (A)"] = tempCurrent;
        }
        if (columns.includes("solarInputVoltage")) {
          tempVoltage = item.metricsArray.find(
            (i) => i.type === "solarInputVoltage"
          )["value"];
          row["Voltage (V)"] = tempVoltage;
        }
        if (columns.includes("solarInputPower")) {
          tempPower = item.metricsArray.find(
            (i) => i.type === "solarInputPower"
          )["value"];
          row["Power (W)"] = tempPower;
        }
        row["Operation Type"] = operation;
        csvData.push(row);
      });
      obj = {
        data: csvData,
        responseStatus: {
          code: res.responseStatus.code,
        },
      };
      return obj;
    } else {
      obj = {
        data: [],
        responseStatus: {
          code: res.responseStatus.code,
        },
      };
      return obj;
    }
  });
}

export {
  BatteryPackMetrics,
  GetTheMetric,
  ChargerVitals,
  GetTheGranularityTrend,
  GetTheChargerGranularityTrend,
};
