import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 262;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: "104px",
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function CustomMiniDrawer(props) {
  const handleDrawerState = () => {
    props.onDrawerStateChange(!props.open)
  };

  return (
    <Box style={{
      height: '100%',
      ...props.style
    }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={props.open}
        PaperProps={{
          sx: {
            position: 'relative',
            height: '100%',
            zIndex: 1,
          }
        }}
      >
        <DrawerHeader
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            m: 0,
            pl: "16px",
            pt: "11px",
            pb: "7px",
            minHeight: 0
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "24px",
              color: "#444B51"
            }}
          >
            Overview
          </Typography>
          <IconButton onClick={handleDrawerState}>
            {props.open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Box>
          {props.drawerBody}
        </Box>
      </Drawer>
    </Box>
  );
}

export default CustomMiniDrawer