import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import EnergyConsumedChart from "./Charts/EnergyConsumedChart";
import { FilterAndCalendarContainer, ChartSectionContainer } from "./Components/StyledComponent";
import TotalChargingTimeChart from "./Charts/TotalChargingTimeChart";
import EnergyEfficiencyChart from "./Charts/EnergyEfficiencyChart";
import ChargingFrequencyChart from "./Charts/ChargingFrequencyChart";
import dayjs from 'dayjs';
import moment from "moment";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { SetChargingAnalyticsStartDate, SetChargingAnalyticsEndDate } from "../../Actions/ChargingAnalytics";
import { geDateRangesForCk, getDatePickerDate } from "../../Helper/DatePicker/DateConverter";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import FilterWithCalendar from "../../Components/FilterWithCalendar";
import { rangePresets } from "../../Static/Data/AlertAnalytics/AlertAnalytics";

function ChartsSection({ pagesContent }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const calendarStartDate = useSelector((state) => state.ChargingAnalyticsStartDate.value) || geDateRangesForCk()[0];
  const calendarEndDate = useSelector((state) => state.ChargingAnalyticsEndDate.value) || geDateRangesForCk()[1];

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault(cookies.get("timeZone"));

  const selectedFilters = useSelector((state) => state.SelectedFilterValues.value)
  const [granularity, setGranularity] = useState({granularity: 'weekly'})
  const [cardSubtitleTimePeriodText, setCardSubtitleTimePeriodText] = useState("")

  useEffect(() => {
    const startDate = dayjs(calendarStartDate);
    const endDate = dayjs(calendarEndDate);
    const differenceInDays = endDate.diff(startDate, 'day');
    setGranularity({granularity: differenceInDays > 31 ? 'monthly' : 'weekly'})
    calculateCardSubtitleTimePeriodText()
  },[calendarStartDate, calendarEndDate])

  const calculateCardSubtitleTimePeriodText = () => {
    const matchedPreset = rangePresets.find((preset) => {
      return preset.value[0].format("YYYY-MM-DD") === calendarStartDate && preset.value[1].format("YYYY-MM-DD") === calendarEndDate
    })

    if (matchedPreset) {
      setCardSubtitleTimePeriodText(`${matchedPreset?.label}`)
    } else {
      setCardSubtitleTimePeriodText(`${dayjs(calendarStartDate).format("D MMM, YYYY")} - ${dayjs(calendarEndDate).format("D MMM, YYYY")}`)
    }
  }

  const disabledDate = (current) => {
    if (!current) {
      return false
    }
    current = current.format('YYYY-MM-DD')
    const minDate = '2024-01-01'
    const maxDate = getDatePickerDate()
   
    return current < minDate || current >= maxDate
  };

  const onDateChange = (startDate, endDate) => {
    dispatch(SetChargingAnalyticsStartDate(startDate));
    dispatch(SetChargingAnalyticsEndDate(endDate));
    updateQueryParams(navigate, {'startDate': startDate, 'endDate': endDate});
  }

  return (
    <ChartSectionContainer>
      <FilterAndCalendarContainer>
        <FilterWithCalendar 
          filtersConfig={pagesContent.filterConfigurations?.chargingAnalyticsPage?.filters}
          onSelectFilter={() => {}}
          onResetFilters={() => {}}
          disabledDate={disabledDate}
          onDateChange={onDateChange}
          date={[calendarStartDate, calendarEndDate]}
          allowClear={false}
          page={'chargingAnalytics'}
          rangePresets={[
            ...rangePresets, 
            {
              label: "Reset",
              value: [dayjs(geDateRangesForCk()[0]), dayjs(geDateRangesForCk()[1])]
            } 
          ]}
        />
      </FilterAndCalendarContainer>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <EnergyConsumedChart cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
        <Grid item xs={6}>
          <EnergyEfficiencyChart cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
        <Grid item xs={6}>
          <TotalChargingTimeChart cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
        <Grid item xs={6}>
          <ChargingFrequencyChart cardSubtitleTimePeriodText={cardSubtitleTimePeriodText} filters={selectedFilters} startDate={calendarStartDate} endDate={calendarEndDate} granularity={granularity}/>
        </Grid>
      </Grid>
    </ChartSectionContainer>
  )
}

export default ChartsSection
