import React from "react"
import { PageHeaderContainer } from "./Components/StyledComponent"
import { PageHeadingText } from "../../Components/CustomBox"

function PageHeaderSection({ pagesContent }) {
  return (
    <PageHeaderContainer>
      <PageHeadingText>
        {pagesContent.pageHeader}
      </PageHeadingText>
    </PageHeaderContainer>
  )
}

export default PageHeaderSection
