import { styled } from "@mui/material"
import { CustomText, FlexSpaceBetweenBox } from "./CustomBox"

const LastUpdatedTimeBox = styled(FlexSpaceBetweenBox)({
  alignItems: "center",
  marginRight: "8px"
})

const LastUpdatedTimeText = styled(CustomText)({
  fontSize: "12px",
  fontWeight: "600",
  lineHeight: "18px",
  color: "#1C1C1C"
})

const LastUpdatedTimeStampText = styled(CustomText)({
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "18px",
  color: "#1C1C1C",
  marginLeft: "7px"
})

export { 
  LastUpdatedTimeText,
  LastUpdatedTimeBox,
  LastUpdatedTimeStampText
}
