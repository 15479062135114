import React from "react";
import { timezones } from "../../Static/Data/_Common/Timezones";

function FindLocalTimezone() {
  const d = new Date();
  let diff = d.getTimezoneOffset();

  let findLocalTimeZone = timezones.find((item) => item.offsetNumeric === diff);
  return findLocalTimeZone
}

export default FindLocalTimezone;
