import {
  Box,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import { useSelector } from "react-redux";

function ConfigFormPackDetails({
  isEdit,
  form,
  handleChangeSelect,
  showPackOEMTextField,
  handleDiscardToDropdown,
  formValidated,
}) {
  const configFilters = useSelector(
    (state) => state.DigitalTwinsConfigFilters.value
  );

  const packFilters = configFilters.find((x) => x.type === "packOEM")?.value;

  return (
    <FormGroup>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="space-around"
        margin={3}
        rowGap={2}
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Pack Manufacturer <span style={{ color: "red" }}>*</span>
          </FormLabel>
          {showPackOEMTextField ? (
            <>
              <TextField
                style={{ width: "300px" }}
                size="small"
                variant="outlined"
                value={form?.packOEM}
                name="packOEM"
                onChange={handleChangeSelect}
                disabled={!isEdit}
                inputProps={{
                  style: { fontSize: 14, padding: 7, marginLeft: 3 },
                }}
                autoFocus
              />
              <IconButton onClick={() => handleDiscardToDropdown("packOEM")}>
                <CloseIcon style={{ fontSize: 16 }} />
              </IconButton>
            </>
          ) : (
            <Select
              style={{ width: "300px", fontSize: "small" }}
              size="small"
              variant="outlined"
              name="packOEM"
              onChange={handleChangeSelect}
              disabled={!isEdit}
              value={form?.packOEM}
            >
              {packFilters?.map((item) => (
                <MenuItem sx={{ fontSize: "small" }} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem
                sx={{ fontSize: "small", color: ThemeProperties.Purple1 }}
                value="%add%"
              >
                Add new
              </MenuItem>
            </Select>
          )}
          {!formValidated?.packOEM && (
            <Typography color="red">
              {showPackOEMTextField && packFilters.includes(form?.packOEM)
                ? "Value already exists"
                : "This field is required"}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Voltage (V)
          </FormLabel>
          <TextField
            style={{ width: "300px" }}
            size="small"
            type="number"
            variant="outlined"
            value={form?.ratedVoltagePack}
            name="ratedVoltagePack"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            inputProps={{
              style: { fontSize: 14, padding: 7, marginLeft: 3 },
            }}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Max Charge Current (A)
          </FormLabel>
          <TextField
            style={{ width: "300px" }}
            size="small"
            type="number"
            variant="outlined"
            value={form?.maxChargingCurrentPack}
            name="maxChargingCurrentPack"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            inputProps={{
              style: { fontSize: 14, padding: 7, marginLeft: 3 },
            }}
          />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Capacity (Ah)
          </FormLabel>
          <TextField
            style={{ width: "300px" }}
            size="small"
            type="number"
            variant="outlined"
            value={form?.ratedCapacityPack}
            name="ratedCapacityPack"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            inputProps={{
              style: { fontSize: 14, padding: 7, marginLeft: 3 },
            }}
          />
        </Box>
        {/* <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel sx={{ fontSize: 14, paddingTop: 0.7, width: 200, color: ThemeProperties.black, }}>
            Add Data Sheet
          </FormLabel>
          <SecondaryButton variant="outlined" component="label">
            Add Data Sheet
            <input
              type="file"
              hidden
              accept=".csv"
              onChange={handleFileUpload}
            />
          </SecondaryButton>
        </Box> */}
      </Box>
    </FormGroup>
  );
}

export default ConfigFormPackDetails;
