import React from "react"
import { KPIContainer } from "./Components/StyledComponent"
import { getCurrentMonthStartAndEndDate } from "../../Helper/DatePicker/DateConverter";
import AnalyticsKPICard from "../../Features/Cards/AnalyticsKPICard";
import { getMetricInfo } from "../../Api/AlertAnalyticsApi";

function KPISection({ pagesContent }) {
  const calendarStartDate = getCurrentMonthStartAndEndDate()[0];
  const calendarEndDate = getCurrentMonthStartAndEndDate()[1];

  return (
    <KPIContainer>
      {pagesContent?.pageMeta?.availableKPIs?.map(kpiData => <AnalyticsKPICard 
        labelStyle={{ fontSize: "14px" }}
        metricName={kpiData.name} 
        startDate={calendarStartDate} 
        endDate={calendarEndDate} 
        decimalPlaces={kpiData.decimalPlaces} 
        getMetricInfo={getMetricInfo} 
      />)}
    </KPIContainer>
  )
}

export default KPISection
