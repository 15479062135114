export const SetChargingAnalyticsStartDate = (start) => {
  return {
    type: "ChargingAnalyticsStartDate",
    start: start,
  };
};

export const SetChargingAnalyticsEndDate = (end) => {
  return {
    type: "ChargingAnalyticsEndDate",
    end: end,
  };
};