import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";
import Loader from "../../Helper/Loaders/Loader";

import { ThemeProperties } from "../../Theme/ThemeProperties";
import NumberFormatter from "../../Helper/Formatters/NumberFormatter";
import CODE from "../../Static/Constants/StatusCodes";

import {
  encryptTheParams,
  decryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

const TestApexBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = decryptTheParams();

  const [chartState, setChartState] = useState({
    text: "",
    series: [],
    options: {},
  });

  function mountTheChart(series, colors, ymax) {
    setChartState({
      series: series,
      options: {
        chart: {
          type: "bar",
          height: 430,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
          stacked: props.stacked,
          events: {
            dataPointSelection: (event, chartContext, config) => {
              if (props.clickable) {
                const batteryId = props.batteries[config.dataPointIndex];

                let temp;
                temp = {
                  ...queryParams,
                  batteryID: batteryId,
                  batterySearchedGlobal: "false",
                  tabValue: 0,
                  tabName: "Alerts",
                  prevLink: location.pathname + location.search,
                };
                encryptTheParams(temp, navigate, true);
              }
            },
          },
        },
        states: {
          normal: {
              filter: {
                  type: 'none',
                  value: 0,
              }
          },
          hover: {
              filter: {
                  type: 'none',
                  value: 0.15,
              }
          },
          active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                  type: 'none',
                  value: 0.35,
              }
          },
      },
        plotOptions: {
          bar: {
            horizontal: props.horizontal,
            borderRadiusApplication: "end",
            borderRadius: 0,
            columnWidth: props.columnWidth,
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: colors,
        dataLabels: {
          enabled: props.showDataLabels,
          offsetY: props.offsetY,
          offsetX: props.offsetX,
          formatter: function (value) {
            if (props.formatter) {
              var val = Math.abs(value);
              if (val >= 100000) {
                val = `${"\xa0".repeat(12)}` + (val / 1000).toFixed(0) + "K";
              } else if (val >= 50000) {
                val = `${"\xa0".repeat(10)}` + (val / 1000).toFixed(0) + "K";
              } else if (val >= 10000) {
                val = `${"\xa0".repeat(8)}` + (val / 1000).toFixed(0) + "K";
              } else if (val >= 5000) {
                val = `${"\xa0".repeat(5)}` + (val / 1000).toFixed(0) + "K";
              } else if (val >= 1000) {
                val = `${"\xa0".repeat(5)}` + (val / 1000).toFixed(1) + "K";
              } else if (val >= 100) {
                val = `${"\xa0".repeat(2)}` + val;
              }
              return `${"\xa0"}` + val;
            } else {
              return NumberFormatter(value, 1);
            }
          },
          // offsetY:-20,
          style: {
            fontSize: ThemeProperties.c_axis_labels,
            fontWeight: 600,
            fontFamily: "Roboto",
            colors: ["#000"],
          },
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
          followCursor: true,
          custom: props.customTooltip
            ? function ({ series, seriesIndex, dataPointIndex, w }) {
                var data =
                  w.globals.initialSeries[seriesIndex].data[dataPointIndex];

                var batteryID = data.batteryID ? data.batteryID : "Not found";

                return (
                  "<div>" +
                  '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">' +
                  data.x +
                  "</div>" +
                  '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
                  '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">' +
                  '<div class="apexcharts-tooltip-y-group">' +
                  '<span class="apexcharts-tooltip-text-y-label"> Battery ID: </span>' +
                  '<span class="apexcharts-tooltip-text-y-value">' +
                  batteryID +
                  "</span>" +
                  "</div>" +
                  '<div class="apexcharts-tooltip-goals-group">' +
                  '<span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value"></span>' +
                  "</div>" +
                  '<div class="apexcharts-tooltip-z-group">' +
                  '<span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span>' +
                  "</div>" +
                  "</div>" +
                  "</div>" +
                  '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
                  `<span class="apexcharts-tooltip-marker" style="background-color: ${props.rgbColor}"></span>` +
                  '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px">' +
                  '<div class="apexcharts-tooltip-y-group">' +
                  '<span class="apexcharts-tooltip-text-y-label"> Number of alerts: </span>' +
                  '<span class="apexcharts-tooltip-text-y-value">' +
                  data.y +
                  "</span>" +
                  "</div>" +
                  '<div class="apexcharts-tooltip-goals-group">' +
                  '<span class="apexcharts-tooltip-text-goals-label"></span><span class="apexcharts-tooltip-text-goals-value"></span>' +
                  "</div>" +
                  '<div class="apexcharts-tooltip-z-group">' +
                  '<span class="apexcharts-tooltip-text-z-label"></span><span class="apexcharts-tooltip-text-z-value"></span>' +
                  "</div>" +
                  "</div>" +
                  "</div>" +
                  "</div>"
                );
              }
            : undefined,
        },
        legend: {
          show: false,
          fontSize: "11px",
          color: "#22105C",
          fontFamily: "Roboto",
          fontWeight: 400,
          position: "bottom",
          itemMargin: {
            horizontal: 10,
            vertical: 7,
          },
          markers: {
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 14,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
        },
        xaxis: {
          type: "category",
          categories: props.categories,
          tickPlacement: "on", // or "on"
          // max:
          //   props.xmax < 10 ? 10 : FindNextHighestMultipleOfNum(props.xmax, 25),
          tickAmount: props.tickAmount,
          title: {
            text: props.xaxisTitle,
            offsetY: -10,
            style: {
              color: ThemeProperties.black,
              fontSize: "13px",
              fontFamily: "Roboto",
              fontWeight: 500,
            },
          },
          labels: {
            rotate: 0,
            formatter: function (value) {
              if (props.granularity === "Daily")
                return moment(new Date(value)).format("DD MMM");
              else return value;
            },
            style: {
              colors: ThemeProperties.black,
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
          },
          axisBorder: {
            show: true,
            color: ThemeProperties.black,
          },
          axisTicks: {
            show: true,
            color: ThemeProperties.black,
          },
        },
        yaxis: {
          max: props.customLegend ? ymax : props.ymax,
          tickAmount: 4,
          title: {
            text: props.yaxisTitle,
            style: {
              color: ThemeProperties.black,
              fontSize: ThemeProperties.c_axis_title,
              fontFamily: "Roboto",
              fontWeight: 500,
            },
          },
          labels: {
            style: {
              colors: ThemeProperties.black,
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: "Roboto",
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
          },
          axisBorder: {
            show: true,
            color: ThemeProperties.black,
          },
          axisTicks: {
            show: true,
            color: ThemeProperties.black,
          },
        },
      },
    });
  }

  useEffect(() => {
    setChartState({
      text: "",
      series: [],
      options: {},
    });
    if (
      props.data !== undefined &&
      props.data?.responseStatus.code === CODE.SUCCESS
    ) {
      if (props.customLegend) {
        let finalData = props.handleLegendClick();

        mountTheChart(finalData[0], finalData[1], finalData[2]);
      } else {
        mountTheChart(props.series, props.colors);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.statusClicked]);

  useEffect(
    () => () => {
      setChartState({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {chartState.series.length > 0 ? (
        <Chart
          options={chartState.options}
          series={chartState.series}
          type="bar"
          width={props.width}
          height={"100%"}
          // id="lastLabel"
        />
      ) : (
        <div style={{ height: "100%" }}>
          {props.data?.responseStatus.code === CODE.LOADING ? (
            <Loader />
          ) : (
            <div style={{ position: "relative", height: "380px" }}>
              <div
                style={{
                  position: "absolute",
                  left: "45%",
                  top: "42%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ececec",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                >
                  <Typography variant="textSubtitle">No data found</Typography>
                </div>
              </div>
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    zoom: {
                      enabled: false,
                    },
                    toolbar: {
                      show: false,
                    },
                  },
                  colors: [ThemeProperties.grey],
                  plotOptions: {
                    bar: {
                      distributed: true, // this line is mandatory
                      borderRadius: 4,
                      horizontal: true,
                      dataLabels: {
                        position: "top",
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      style: {
                        colors: ["#333"],
                      },
                      // offsetX: 100
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    distributed: true,
                    offsetX: 30,
                    style: {
                      fontSize: ThemeProperties.c_axis_labels,
                      fontWeight: ThemeProperties.c_axis_labels_weight,
                      colors: [ThemeProperties.c_axis_labels_color],
                      fontFamily: "Roboto",
                    },
                  },
                  grid: {
                    show: false,
                  },
                  xaxis: {
                    categories: [""],
                    axisTicks: {
                      show: false,
                    },
                    labels: {
                      show: false,
                    },
                  },
                  yaxis: {
                    labels: {
                      show: true,
                      datetimeUTC: false,
                      rotateAlways: false,
                      // align: "right",
                      style: {
                        colors: [ThemeProperties.c_axis_labels_color],
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: "Roboto",
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },

                      offsetY: 0,
                    },
                  },
                  legend: {
                    show: false,
                  },
                }}
                series={[
                  {
                    name: "Count",
                    data: [null],
                  },
                ]}
                type="bar"
                height="100%"
                width="100%"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TestApexBar;
