import React, { useEffect, useState } from "react";

import { Grid, Breadcrumbs, Typography, Box, Button } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import Loader from "../../../Helper/Loaders/Loader";
import { getMetricTimeStamp } from "../../../Helper/DatePicker/DateConverter";
import NoData from "../../../Helper/Loaders/NoData";
import { getChargerData, getRealtimeKPI } from "../../../Api/DigitalTwin";
import CODE from "../../../Static/Constants/StatusCodes";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import Events from "../../../Analytics/Events";


function findChargeState(value)
{
  if(value === 0)
    return "Not Charging"
  else if(value === 1)
    return "Charging"
  else
    return ""
}

function findLoadState(value)
{
  if(value === 0)
    return "No"
  else if(value === 1)
    return "Yes"
  else
    return ""
}
function VitalsSection(props) {
  const [data, setData] = useState({
    data: [],
    responseStatus: {
      code: -1,
      msg: "",
    },
  });

  const assemblyColorCoding = props.hierarchyColorCoding.responseStatus;

  const handleBreadCrumbClick = (type) => {
    if (type === "pack")
      props.handleSetHierarchy(
        props.hierarchy.selectedPack,
        { key: null, value: null },
        { key: null, value: null },
        "pack",
        {
          value: null,
        }
      );
    else if (type === "module")
      props.handleSetHierarchy(
        props.hierarchy.selectedPack,
        props.hierarchy.selectedModule,
        { key: null, value: null },
        "module",
        {
          value: null,
        }
      );
    else if (type === "cell")
      props.handleSetHierarchy(
        props.hierarchy.selectedPack,
        props.hierarchy.selectedModule,
        props.hierarchy.selectedCell,
        "cell",
        {
          value: null,
        }
      );
    else if (type === "assembly")
      props.handleSetHierarchy(
        { key: null, value: null },
        { key: null, value: null },
        { key: null, value: null },
        "battery",
        {
          value: null,
        }
      );
  };

  const displayBreadCrumbs = () => {
    return (
      <Box sx={{ ml: 1.5 }}>
        {props.hierarchy.selectedCell.value ? (
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="large" color="primary" />}
          >
            [{returnLabel("Tower", "assembly")},
            {returnLabel(props.hierarchy.selectedPack.key, "pack")},
            {returnLabel(props.hierarchy.selectedModule.key, "module")},
            {returnLabel(props.hierarchy.selectedCell.key, "cell")}]
          </Breadcrumbs>
        ) : props.hierarchy.selectedModule.value ? (
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="large" color="primary" />}
          >
            [{returnLabel("Tower", "assembly")},
            {returnLabel(props.hierarchy.selectedPack.key, "pack")},
            {returnLabel(props.hierarchy.selectedModule.key, "module")}]
          </Breadcrumbs>
        ) : props.hierarchy.selectedPack.value ? (
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="large" color="primary" />}
          >
            [{returnLabel("Tower", "assembly")},
            {returnLabel(props.hierarchy.selectedPack.key, "pack")}]
          </Breadcrumbs>
        ) : (
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="large" color="primary" />}
          >
            [{returnLabel("Tower", "assembly", true)}]
          </Breadcrumbs>
        )}
      </Box>
    );
  };

  const returnLabel = (label, type) => {
    return (
      <Typography
        variant="textSubtitle"
        sx={{
          fontWeight: 500,
          cursor: "pointer",
          "&:hover": {
            color: ThemeProperties.purple,
          },
        }}
        onClick={() => handleBreadCrumbClick(type)}
      >
        {label}
      </Typography>
    );
  };

  const getActualValue = (value, type, decimalPlace, mappingType) => {
    
    if (value !== "Not known") {
      if (type === "number") {
        return value !== undefined && value !== null ? Math.round(value) : "";
      } else if (type === "float") {
        return value !== undefined && value !== null ? value.toFixed(decimalPlace) : "";
      } else if (type === "date") {
        return value !== undefined && value !== null ? getMetricTimeStamp(value) : "";
      } else if (type === "string") {
        return value !== undefined && value !== null ? value : "";
      } else if (type === "mapping") {
        if(mappingType === "findLoadState")
         return findLoadState(value);
        else if(mappingType === "findChargeState")
          return findChargeState(value)
        return value !== undefined && value !== null ? value : "";
      }
    } else {
      return "-";
    }
  };

  useEffect(() => {
    setData({
      data: [],
      responseStatus: {
        code: -1,
        msg: "",
      },
    });
    // setView("Battery")

    if (props.view === "Battery") {
      let tempData = props.hierarchy.selectedCell.value
          ? props.packHierarchy.cell
          : props.hierarchy.selectedModule.value
          ? props.packHierarchy.module
          : props.hierarchy.selectedPack.value
          ? props.packHierarchy.pack
          : props.packHierarchy.assembly,
        newData = [];
      getRealtimeKPI(
        props.batteryID,
        props.hierarchy.selectedPack.value,
        props.hierarchy.selectedModule.value,
        props.hierarchy.selectedCell.value,
        props.hierarchy.level
      ).then((res) => {
        if (res.responseStatus.code === 200) {
          tempData.map((item) => {
            newData.push({
              name: item.name,
              key: item.key,
              unit: item.unit,
              type: item.type,
              decimalPlace: item.decimalPlace,
              doubleMetrics: item.doubleMetrics,
              latLong: item.latLong,
              clickable: item.clickable,
              value:
                res.response.batteryData[0][item.key] !== undefined &&
                item.name === "Cell Imbalance"
                  ? res.response.batteryData[0][item.key] * 100
                  : res.response.batteryData[0][item.key] !== undefined
                  ? res.response.batteryData[0][item.key]
                  : "Not known",

              minKeyValue:
                res.response.batteryData[0][item.minKeyValue] !== undefined
                  ? res.response.batteryData[0][item.minKeyValue]
                  : "Not known",
              maxKeyValue:
                res.response.batteryData[0][item.maxKeyValue] !== undefined
                  ? res.response.batteryData[0][item.maxKeyValue]
                  : "Not known",
              latitude: res.response.batteryData[0]["latitude"],
              longitude: res.response.batteryData[0]["longitude"],
            });
          });

          setData({
            data: newData,
            responseStatus: {
              code: res.responseStatus.code,
              msg: "msg",
            },
          });
        } else {
          tempData.map((item) => {
            newData.push({
              name: item.name,
              value: "...",
              key: item.key,
              unit: item.unit,
              type: item.type,
            });
          });
          setData({
            data: newData,
            responseStatus: {
              code: CODE.NODATA,
              msg: "msg",
            },
          });
        }
      });
    } else {
      let tempData = props.charger,
        newData = [];
      getChargerData(props.batteryID).then((res) => {
        if (res.responseStatus.code === 200) {

          tempData.map((item) => {
            newData.push({
              name: item.name,
              key: item.key,
              unit: item.unit,
              type: item.type,
              decimalPlace: item.decimalPlace,
              mappingType: item.mappingType,
              clickable: item.clickable,
              value:
                res.response.batteryData[item.key] !== undefined
                  ? res.response.batteryData[item.key]
                  : "Not known",
            });
          });
          setData({
            data: newData,
            responseStatus: {
              code: res.responseStatus.code,
              msg: "msg",
            },
          });
        } else {
          tempData.map((item) => {
            newData.push({
              name: item.name,
              value: "...",
              key: item.key,
              unit: item.unit,
              type: item.type,
            });
          });
          setData({
            data: newData,
            responseStatus: {
              code: CODE.NODATA,
              msg: "msg",
            },
          });
        }
      });
    }

    // if (props.realTimeStatus.responseStatus.code === 200) {

    // } else {

    // }
  }, [
    props.view,
    props.hierarchy.selectedCell.value,
    props.hierarchy.selectedModule.value,
    props.hierarchy.selectedPack.value,
  ]);

  return (
    <div style={{opacity:assemblyColorCoding.code === CODE.SUCCESS ? 1 : 0.5,pointerEvents:assemblyColorCoding.code === CODE.SUCCESS ? "auto" : "none"}}>
      <Box sx={{ m: "16px 8px", display: "flex" }}>
        <Box
          sx={{
            p: "8px 16px",
            borderRadius: "10px",
            borderBottom:
              props.view === "Battery"
                ? `2px solid #6D72F6`
                : `2px solid transparent`,
            boxShadow:
              props.view === "Battery"
                ? "0px 2px 40px 0px rgba(17, 6, 38, 0.08)"
                : undefined,
            height: "30px",
            lineHeight: "30px",

            // bgcolor: props.view === "Battery" ? "#EEEFFF" : "white",
            textTransform: "none",
            cursor: "pointer",
            "&:hover": {
              opacity: props.view === "Charger" ? 0.7 : 1,
            },
          }}
          onClick={() => {Events("Instantaneous Battery KPI clicked"); props.handleChangeView("Battery")}}
        >
          <Typography variant="textSubtitle" sx={{ fontWeight: 700 }}>
            Instantaneous Battery KPI
          </Typography>{" "}
        </Box>
        <Box
          sx={{
            p: "8px 16px",
            borderBottom:
              props.view === "Charger"
                ? `2px solid #6D72F6`
                : `2px solid transparent`,
            boxShadow:
              props.view === "Charger"
                ? "0px 2px 40px 0px rgba(17, 6, 38, 0.08)"
                : undefined,
            ml: 1,
            height: "30px",
            lineHeight: "30px",
            // bgcolor: props.view === "Charger" ? "#EEEFFF" : "white",
            borderRadius: "10px",
            textTransform: "none",
            cursor: "pointer",
            "&:hover": {
              opacity: props.view === "Battery" ? 0.7 : 1,
            },
          }}
          onClick={() => {
            Events("Instantaneous PV Array clicked")
            props.handleChangeView("Charger");
            props.handleSetHierarchy(
              { key: null, value: null },
              { key: null, value: null },
              { key: null, value: null },
              "battery",
              {
                value: null,
              }
            );
          }}
        >
          <Typography variant="textSubtitle" sx={{ fontWeight: 700 }}>
            Instantaneous PV Array
          </Typography>{" "}
        </Box>
      </Box>

      {props.view === "Battery" && (
        <>
          {displayBreadCrumbs()}
          <Box sx={{ mt: 1.5 }}></Box>
        </>
      )}

      {data.responseStatus.code === 200 ? (
        <>
          {props.view === "Battery" ? (
            <>
              <Grid container spacing={2}>
                {data.data.map((item, index) => (
                  <Grid item xs={6} sm={6} md={4} lg={3} xl={3} key={index}>
                    <Box
                      sx={{
                        border:
                          props.hierarchy.selectedKPI.value === item.key &&
                          item.clickable
                            ? `2px solid ${ThemeProperties.purple}`
                            : item.clickable
                            ? `1px solid ${ThemeProperties.purple}`
                            : "1px solid #EEEFFF",
                        borderRadius: "8px",
                        height: "40px",
                        margin: "3px 3px",
                        padding: "10px",
                        cursor: item.clickable ? "pointer" : "default",
                        boxShadow:
                          props.hierarchy.selectedKPI.value === item.key
                            ? "0px 6px 10px 0px rgba(76, 83, 96, 0.15), 0px 2px 3px 0px rgba(76, 83, 96, 0.30)"
                            : "none",
                        "&:hover": {
                          backgroundColor: item.clickable
                            ? ThemeProperties.hoverPurple
                            : ThemeProperties.white,
                        },
                      }}
                      onClick={() => {
                        if (item.clickable) {
                          props.handleSetHierarchy(
                            props.hierarchy.selectedPack,
                            props.hierarchy.selectedModule,
                            props.hierarchy.selectedCell,
                            props.hierarchy.level,
                            {
                              value:
                                props.hierarchy.selectedKPI.value === item.key
                                  ? null
                                  : item.key,
                            }
                          );
                        }
                      }}
                    >
                      <Typography
                        variant="textSubtitle"
                        style={{ fontWeight: 600 }}
                      >
                        {item.name}&nbsp;
                        {item.unit && `${"(" + item.unit + ")"}`}
                      </Typography>
                      <Box sx={{ mt: 1.2 }}></Box>

                      {item.doubleMetrics ? (
                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                          <Typography variant="textSubtitle">
                            {getActualValue(
                              item.minKeyValue,
                              item.type,
                              item.decimalPlace
                            )}
                          </Typography>
                          {item.minKeyValue!==null && item.minKeyValue!==undefined && <Typography variant={"textDescriptionActivePurple"}>
                            (min)
                          </Typography>}
                          <Typography variant="textSubtitle" sx={{ ml: 1 }}>
                            {getActualValue(
                              item.maxKeyValue,
                              item.type,
                              item.decimalPlace
                            )}
                          </Typography>
                          {item.maxKeyValue!==null && item.maxKeyValue!==undefined && <Typography variant={"textDescriptionActivePurple"}>
                            (max)
                          </Typography>}
                        </Box>
                      ) : item.latLong ? (
                        <Box sx={{ display: "flex" }}>
                          <Typography variant="textSubtitle">
                            {getActualValue(
                              +item.latitude?.toFixed(4),
                              item.type
                            )}
                          </Typography>

                          <Typography variant="textSubtitle">
                          {(item.latitude!==null || item.longitude!==null) && "/"}
                            {getActualValue(
                              +item.longitude?.toFixed(4),
                              item.type
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          <Typography variant="textSubtitle">
                            {getActualValue(
                              item.value,
                              item.type,
                              item.decimalPlace,
                            )}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <>
              <Box sx={{ mt: 2 }}></Box>

              <Grid container spacing={2}>
                {data.data.map((item, index) => (
                  <Grid item xs={6} sm={4} md={4} lg={4} xl={4} key={index}>
                    <Box
                      sx={{
                        border: "1px solid #EEEFFF",
                        borderRadius: "8px",
                        height: "40px",
                        margin: "3px 3px",
                        padding: "10px",
                      }}
                    >
                      <Typography
                        variant="textSubtitle"
                        style={{ fontWeight: 600 }}
                      >
                        {item.name}&nbsp;
                        {item.unit && `${"(" + item.unit + ")"}`}
                      </Typography>
                      <Box sx={{ mt: 1.2 }}></Box>
                      <Typography variant="textSubtitle">
                        {getActualValue(
                          item.value,
                          item.type,
                          item.decimalPlace,
                          item.mappingType
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      ) : (
        <Box sx={{ height: "200.5px" }}>
          {data.responseStatus.code === -1 ? (
            <Loader />
          ) : (
            <NoData text={"No data found"} />
          )}
        </Box>
      )}
    </div>
  );
}

export default VitalsSection;
