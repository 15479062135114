import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowContainer } from "../../Components/CustomBox.js"
import { KPIBoxCustomWidth } from "../../Components/CustomBox";
import { Typography, Box } from "@mui/material";
import CustomIcon from "../../Components/CustomIcon.js";

function CarouselSlider({ disabled, position, onClick }) {
  return (
    <ArrowContainer
      disabled={disabled}
      onClick={onClick}
      left={position === "left" ? "-25px" : null}
      right={position === "right" ? "-25px" : null}

    >
      <CustomIcon
        name={
          position === "left" ? "chartCarouselPreviousArrow"
            : "chartCarouselNextArrow"
        }
        width={22}
        height={22}
        viewBox="0 0 22 22"
        fill="#828A92"
      />
    </ArrowContainer >
  );
}

const MultipleItems = ({
  Data,
  slidesToShow,
  setTheDate,
  actualDates,
  startDate,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);
  const initialSlide = actualDates.findIndex((date) => date === startDate);
  const startIndexOfSlide = parseInt(initialSlide / slidesToShow) * slidesToShow;
  const [disablePreviousButton, setDisablePreviousButton] = useState(startIndexOfSlide === 0)
  const [disableNextButton, setDisableNextButton] = useState(startIndexOfSlide + slidesToShow >= actualDates.length)

  useEffect(() => {
    if (initialSlide >= 0 && initialSlide < Data.length) {
      sliderRef.current.slickGoTo(startIndexOfSlide);
    }
  }, [initialSlide, Data.length]);

  useEffect(() => {
    const startIndex = parseInt(activeSlide / slidesToShow) * slidesToShow;
    setDisablePreviousButton(startIndex === 0)
    setDisableNextButton(startIndex + slidesToShow >= actualDates.length)
  }, [activeSlide])

  const sliderRef = React.createRef();

  var settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: Data.length < slidesToShow ? Data.length : slidesToShow,
    slidesToScroll: Data.length < slidesToShow ? Data.length : slidesToShow,
    initialSlide: 0,
    beforeChange: (current, next) => setActiveSlide(next),
    afterChange: (current) => setActiveSlide2(current),
    centerMode: false,
    nextArrow: <CarouselSlider position={"right"} disabled={disableNextButton} />,
    prevArrow: <CarouselSlider position={"left"} disabled={disablePreviousButton} />,
  };

  return (
    <div style={{ margin: "0px 10px 0px 10px" }}>
      <Slider {...settings} ref={sliderRef}>
        {Data.map((slide, index) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            <KPIBoxCustomWidth
              onClick={() => {
                setTheDate(actualDates[index]);
              }}
              sx={{
                boxShadow:
                  actualDates[index] === startDate
                    ? "0px 4px 4px 0px rgba(118, 140, 255, 0.1)"
                    : "",
                bgcolor: "white",
                marginBottom: "10px",
                mt: 0,
                border:
                  actualDates[index] === startDate
                    ? "3px solid rgba(118, 140, 255, 0.5)"
                    : "3px solid #E9EFF5",
                "&:hover": {
                  border: "1px solid rgba(118, 140, 255, 0.5)",
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box>
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "20px",
                      color:
                        actualDates[index] === startDate
                          ? "#444B51"
                          : "#828A92",
                    }}
                  >
                    {slide}
                  </Typography>
                </Box>
              </Box>
            </KPIBoxCustomWidth>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MultipleItems;
