import React from "react";
import Popover from "@mui/material/Popover";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { useState } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { IconButton, Typography, ListItemIcon } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";

export default function AlertsInfoPopover(props) {
  const [selectedItem, setSelectedItem] = useState(0);
  const [subSelectedItem, setSubSelectedItem] = useState(null);

  const handleItemClick = (index) => {
    setSelectedItem(index);
    setSubSelectedItem(index === 1 ? 0 : null);
  };
  const handleSubItemClick = (index) => {
    setSubSelectedItem(index === subSelectedItem ? null : index);
    setSelectedItem(1);
  };

  const itemList = ["Alert Category", "Alert Type"];
  const subItemList = ["Safety Risk", "Operational Issue", "Violation"];
  const subItemList2 = [
    ["Active", "An Active error is an alert that requires immediate attention"],
    ["Open", "An Open error is an alert on which action has been taken"],
    ["Resolved", "An Resolved error is an alert that has been resolved"],
    ["Ignored", "An Ignored error is an alert that has been open for >7days"],
    ["Expired", "An Expired error is an alert that needs no more altering"],
  ];

  const subItemList3 = [
    [
      "High tempreture during charging",
      "If the system detects that the battery is getting too hot while charging,it will automatically slow down the rate at which car charges.",
    ],
    [
      "High tempreture inside vechicle",
      "If the system detects that the battery is getting too hot while charging,it will automatically slow down the rate at which car charges.",
    ],
    [
      "High tempreture in warehouse",
      "If the system detects that the battery is getting too hot while charging,it will automatically slow down the rate at which car charges.",
    ],
  ];

  return (
    <Popover
      id={""}
      open={props.openPopup}
      anchorEl={props.anchorElProfilePopup}
      onClose={props.onClosePopup}
      container={props.anchorElProfilePopup.parentNode}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      elevation={2}
    >
      <Box
        sx={{
          p: 1,
          maxHeight: 1000,
          display: "flex",
          height: "330px",
          width: "700px",
          padding: "0",
          fontFamily: "Helvetica",
          borderRadius: "14px",
        }}
      >
        <Box
          sx={{
            width: "40%",
            borderRight: "1px solid #E5E4E2",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ height: "60%", borderBottom: "1px solid #E5E4E2" }}>
            <Box sx={{ height: "10%" }}></Box>
            <List sx={{ display: "flex", flexDirection: "column", padding: 0 }}>
              {itemList.map((item, index) => (
                <Box key={index}>
                  <ListItem
                    button
                    selected={selectedItem === index}
                    onClick={() => handleItemClick(index)}
                    sx={{
                      backgroundColor: "transparent",
                      color: selectedItem === index ? "#4f44e0" : "grey",
                      fontSize: "medium",
                      borderTop: "1px solid #E5E4E2",
                      fontWeight: "bold",
                    }}
                  >
                    {item}
                  </ListItem>
                  {item === "Alert Type" && (
                    <List
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 0,
                      }}
                    >
                      {subItemList.map((subItem, subIndex) => (
                        <ListItem
                          button
                          selected={subSelectedItem === subIndex}
                          onClick={() => handleSubItemClick(subIndex)}
                          sx={{
                            backgroundColor:
                              subSelectedItem === subIndex
                                ? "lightgrey"
                                : "transparent",
                            color:
                              subSelectedItem === subIndex ? "#4f44e0" : "grey",
                            fontSize: "small",
                            paddingLeft: "30px",
                          }}
                        >
                          {subItem}
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Box>
              ))}
            </List>
          </Box>
          <Box sx={{ height: "50%" }}></Box>
        </Box>
        <Box
          sx={{
            width: "70%",
            display: "flex",
            padding: "2%",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "scroll",
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
              "&::-webkit-scrollbar": { display: "none" },
            }}
          >
            {selectedItem !== null && (
              <Box
                sx={{
                  textAlign: "left",
                  width: "100%",
                  fontFamily: ThemeProperties.primaryFont,
                  fontSize: "2rem",
                  fontWeight: 400,
                  color: ThemeProperties.purple,
                  marginLeft: "16px",
                }}
              >
                {subSelectedItem !== null && subItemList[subSelectedItem]}
              </Box>
            )}

            {selectedItem === 0 && (
              <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                <List>
                  {subItemList2.map((subItem, subIndex) => (
                    <ListItem
                      key={subIndex}
                      style={{
                        textAlign: "left",
                        width: "100%",
                        fontFamily: ThemeProperties.primaryFont,
                        fontSize: "1.6rem",
                        fontWeight: 400,
                        color: ThemeProperties.purple,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <ul
                          style={{
                            listStyleType: "disc",
                            width: "100%",
                          }}
                        >
                          <li
                            style={{
                              marginLeft: "-26px",
                              fontSize: 17,
                              fontWeight: "bold",
                            }}
                          >
                            {subItemList2[subIndex][0]}
                          </li>
                        </ul>
                      </Box>
                      <Typography style={{ color: "black", fontSize: 14 }}>
                        {subItemList2[subIndex][1]}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
            {selectedItem === 1 && (
              <Box sx={{ width: "95%" }}>
                {subItemList3.map((subItem, subIndex) => (
                  <ListItem
                    key={subIndex}
                    style={{
                      textAlign: "left",
                      width: "100%",
                      fontFamily: ThemeProperties.primaryFont,
                      fontSize: "1.6rem",
                      fontWeight: 400,
                      color: ThemeProperties.purple,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      borderBottom: "1px solid #E5E4E2",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <ul
                        style={{
                          listStyleType: "disc",
                        }}
                      >
                        <li style={{ marginLeft: "-26px", fontSize: 18 }}>
                          {subItemList3[subIndex][0]}
                        </li>
                      </ul>
                    </Box>
                    <Typography style={{ color: "black", fontSize: 13 }}>
                      {subItemList3[subIndex][1]}
                    </Typography>
                  </ListItem>
                ))}
              </Box>
            )}
          </Box>

          <IconButton
            style={{ height: "2rem", width: "2rem" }}
            onClick={props?.onClosePopup}
          >
            <HighlightOffOutlinedIcon
              style={{ height: "2rem", width: "2rem" }}
            />
          </IconButton>
        </Box>
      </Box>
    </Popover>
  );
}
