import React, { useState } from "react";
import { OutlinedButton } from "../../Components/CustomButton";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  FormControlLabel,
  IconButton,
  Typography,
  Checkbox,
  FormGroup,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { timezones } from "../../Static/Data/_Common/Timezones";
import Cookies from "universal-cookie";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { BasicSelect } from "../../Components/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { SetTimeZoneChanged } from "../../Actions";
import FindLocalTimezone from "../../Helper/Operations/FindLocalTimezone";
import { putUserPrefrenceTimezone } from "../../Api/Api";
import Loader from "../../Helper/Loaders/Loader";

const cookies = new Cookies();

function ChangeTimeZone(props) {
  const dispatch = useDispatch();

  const [timezone, setTimezone] = useState(cookies.get("timeZone"));

  const isAutomatic = +cookies.get("isAutomaticTimezone") === 1 ? true : false;
  const [checked, setChecked] = useState(isAutomatic);

  const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);
  const [loader, setLoader] = useState(false);

  const handleCheckbox = (event) => {
    setChecked(event.target.checked);

    // if (event.target.checked) {
    //   let findLocalTimeZone = FindLocalTimezone();

    //   if (findLocalTimeZone !== undefined)
    //     setTimezone(findLocalTimeZone["name"]);
    // } else {
    //   setTimezone(cookies.get("timeZone"));
    // }
  };

  const handleChange = (event) => {
    setChecked(false);
    setTimezone(event.target.value);
  };

  const handleClickApply = () => {
    setLoader(true);
    let isChecked = checked === true ? 1 : 0,
      findTimezone = "";

    if (isChecked) {
      let findLocalTimeZone = FindLocalTimezone();

      if (findLocalTimeZone !== undefined)
        findTimezone = findLocalTimeZone["name"];
    } else {
      findTimezone = timezone;
    }

    putUserPrefrenceTimezone(findTimezone, isChecked).then((res) => {
      setLoader(false);

      props.openNotification(
        "bottomLeft",
        `Timezone successfully set to ${res.response.response.timezonename}`
      );
      setTimeout(() => {
        cookies.set("timeZone", res.response.response.timezonename, {
          path: "/",
        });
        cookies.set(
          "isAutomaticTimezone",
          res.response.response.isAutomaticTime,
          {
            path: "/",
          }
        );

        let tempChecked =
          res.response.response.isAutomaticTime === 1 ? true : false;
        setTimezone(res.response.response.timezonename);
        setChecked(tempChecked);

        dispatch(SetTimeZoneChanged(!timezoneChanged));
        props.handleCloseChangeTimezone();
      }, 100);
    });
  };

  return (
    <div>
      <Dialog
        onClose={props.handleCloseChangeTimezone}
        aria-labelledby="customized-dialog-title"
        open={props.openChangeTimezone}
      >
        <div style={{ padding: "16px", height: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 0px",
            }}
          >
            <Typography variant="paperHeader">
              {<>Change Timezone</>}
            </Typography>
            <IconButton
              onClick={props.handleCloseChangeTimezone}
              fontSize="small"
            >
              <CloseIcon style={{ fontSize: "18px" }} />
            </IconButton>
          </div>

          <div>
            <Typography style={{ width: "500px" }}></Typography>
          </div>

          {!loader ? (
            <div id="main_body">
              <div>
                <div style={{ width: "100%" }}>
                  <FormGroup sx={{ mb: "8px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleCheckbox}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: 13,
                            fontWeight: 500,
                            fontFamily: "Roboto",
                          }}
                        >
                          Set time zone automatically
                        </Typography>
                      }
                      sx={{ pt: 0 }}
                    />
                  </FormGroup>
                  <br></br>
                  <FormControl
                    sx={{ minWidth: 120, width: "100%" }}
                    size="small"
                    disabled={checked}
                  >
                    <InputLabel
                      id="demo-select-small"
                      sx={{ maxWidth: "100%" }}
                    >
                      <Typography
                        sx={{
                          fontSize: 13,
                          fontWeight: 500,
                          fontFamily: "Roboto",
                          mt: 0.4,
                          pr: 0.5,
                          pl: 0.5,
                          bgcolor: ThemeProperties.backgroundPurple,
                        }}
                      >
                        Timezone
                      </Typography>
                    </InputLabel>
                    <BasicSelect
                      variant="outlined"
                      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                      labelId="demo-select-small-label-timezone"
                      id="demo-select-small"
                      value={timezone}
                      label="Timezone"
                      onChange={handleChange}
                      sx={{
                        "&:hover": {
                          "&& fieldset": {
                            border: `1px solid ${ThemeProperties.purpleSelected}`,
                          },
                        },
                      }}
                    >
                      {timezones.map((item, index) => (
                        <MenuItem value={item.name} key={index}>
                          <Typography
                            sx={{
                              fontSize: 13,
                              fontWeight: 500,
                              fontFamily: "Roboto",
                            }}
                          >
                            ({item.offset}) {item.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </BasicSelect>
                  </FormControl>
                </div>

                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <OutlinedButton
                    variant="contained"
                    disabled={
                      timezone === cookies.get("timeZone") &&
                      checked === isAutomatic
                    }
                    onClick={() => handleClickApply()}
                  >
                    <Typography variant="textSubtitleWhite">Apply</Typography>
                  </OutlinedButton>
                </Box>
              </div>
            </div>
          ) : (
            <div style={{ height: "150px", width: "500px" }}>
              {loader && <Loader />}
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default ChangeTimeZone;
