import React, { useEffect, useState } from "react";
import AllBatteriesMain from "./AllBatteriesMain";
import { getPagesComponents } from "../../Api/Api";
import { Tooltip } from "antd";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
import copy from "copy-to-clipboard";
import { Box, Typography, IconButton } from "@mui/material";
// import { Button, Input, Checkbox } from "antd";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  SetAllBatteriesBatteryPartnerSelect,
  SetAllBatteriesCity,
  SetAllBatteriesColumns,
  SetAllBatteriesHiddenColumns,
  SetAllBatteriesIOT,
  SetAllBatteriesMultiplePartners,
  SetAllBatteriesPack,
  SetAllBatteriesSeverity,
  SetAllBatteriesStatus,
  SetAllBatteriesUserInputBatteries,
  SetAllBatteriesVolt,
  SetAllBatteriesZone,
} from "../../Actions/AllBatteries";
import { SetSessionExpired } from "../../Actions";
import Events from "../../Analytics/Events";
import { convertUTCtoZone } from "../../Helper/DatePicker/DateConverter";
import { getColumnTitleSort } from "../../Features/Table/AntDesign/TableFunctions";
import { decryptTheParams } from "../../Helper/QueryParams/EncryptDecrypt";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import FindTheColor from "../../Helper/Operations/FindTheColor";
import AllBatteriesSeverity from "../../Features/Dialog/AllBatteriesSeverity";
import FindTheSeverity from "../../Helper/Operations/FindTheSeverity";

function AllBatteries(props) {
  const dispatch = useDispatch();
  const orderChangedTo = useSelector((state) => state.CustomOrder.orderBy);
  const sortChangedTo = useSelector((state) => state.KPISort.sortBy);
  const newOrderChangedTo = orderChangedTo === "desc" ? "descend" : "ascend";
  const [pagesContent, setPagesContent] = useState({
    pageHeader: "",
    filtersWithData: [],
    searchTypeFilter: {},
    filteredArray: [],
    batteryType: "",
    mounted: false,
  });
  // const searchInput = useRef(null);

  const [modal, setModal] = useState({
    batteryID: "",
    open: false,
  });
  const handleModalClose = () => {
    setModal({
      batteryID: "",
      open: false,
    });
  };
  function renderBatteryIDCopy(batteryID, severity) {
    return (
      <>
     {batteryID && <>
        <Typography variant="tableRow" sx={{ mr: 0.5 }}>
          {batteryID}
        </Typography>
        <Tooltip title={"Click to copy"}>
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              copy(batteryID);
              Events("Copied battery id");
            }}
          >
            <ContentCopyIcon
              sx={{
                fontSize: "13px",
                cursor: "pointer",
                "&:hover": {
                  color: ThemeProperties.purple,
                },
              }}
            />
          </IconButton>
        </Tooltip>
        </>}
      </>
    );
  }
  function renderSeverity(batteryID, severity, deviceID, lat, long) {
    return (
      <>
        <CircleRoundedIcon
          sx={{ color: FindTheColor(severity), fontSize: "14px", mr: 1 }}
          onClick={(event) => {
            event.stopPropagation();
            setModal({
              batteryID: batteryID,
              deviceID: deviceID,
              lat: lat,
              long: long,
              open: true,
            });
          }}
        />
        <Typography
          variant="tableRow"
          sx={{ mr: 0.5 }}
          onClick={(event) => {
            event.stopPropagation();
            setModal({
              batteryID: batteryID,
              deviceID: deviceID,
              lat: lat,
              long: long,
              open: true,
            });
          }}
        >
          {FindTheSeverity(severity)}
        </Typography>
      </>
    );
  }
  useEffect(() => {
    setTimeout(() => {
      getPagesComponents("All Batteries").then((res) => {
        if (res.responseStatus.code === 200) {
          const filters = [
            {
              itemName: "Severity",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Super Critical",
                  value: "4",
                },
                {
                  key: "Critical",
                  value: "3",
                },
                {
                  key: "High",
                  value: "2",
                },
                {
                  key: "Low",
                  value: "1",
                },
              ],
            },
            {
              itemName: "Status",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Idle",
                  value: "0",
                },
                {
                  key: "Charging",
                  value: "4",
                },
                {
                  key: "Discharging",
                  value: "2",
                },
              ],
            },
            {
              itemName: "City",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Delhi",
                  value: "delhi",
                },
                {
                  key: "Faridabad",
                  value: "faridabad",
                },
                {
                  key: "Ghaziabad",
                  value: "ghaziabad",
                },
                {
                  key: "Gurgaon",
                  value: "gurgaon",
                },
                {
                  key: "Jaipur",
                  value: "jaipur",
                },
                {
                  key: "Kanpur",
                  value: "Kanpur",
                },
                {
                  key: "Karnal",
                  value: "Karnal",
                },
                {
                  key: "Lucknow",
                  value: "Lucknow",
                },
                {
                  key: "Meerut",
                  value: "Meerut",
                },
                {
                  key: "Noida",
                  value: "Noida",
                },
                {
                  key: "Panipat",
                  value: "Panipat",
                },
                {
                  key: "Sonipat",
                  value: "Sonipat",
                },
              ],
            },
            {
              itemName: "Zone",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Mayur Vihar",
                  value: "Mayur Vihar",
                },
                {
                  key: "Noida",
                  value: "Noida",
                },
                {
                  key: "South Delhi",
                  value: "South Delhi",
                },
                {
                  key: "Jankapuri Kirtinagar",
                  value: "Jankapuri Kirtinagar",
                },
                {
                  key: "Rohini",
                  value: "Rohini",
                },
                {
                  key: "Jahagirpuri",
                  value: "Jahagirpuri",
                },
                {
                  key: "Bawana",
                  value: "Bawana",
                },
                {
                  key: "Central Delhi",
                  value: "Central Delhi",
                },
                {
                  key: "Faridabad",
                  value: "Faridabad",
                },
                {
                  key: "Shahadra",
                  value: "Shahadra",
                },
                {
                  key: "Anand Vihar",
                  value: "Anand Vihar",
                },
                {
                  key: "Gurgaon",
                  value: "Gurgaon",
                },
              ],
            },
            {
              itemName: "IOT",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Wynncom",
                  value: "Wynncom",
                },
                {
                  key: "Intellicar",
                  value: "INTELLICAR",
                },
              ],
            },
            {
              itemName: "Pack",
              items: [
                {
                  key: "All",
                  value: "",
                },
                {
                  key: "Livguard",
                  value: "Livguard",
                },
              ],
            },
          ];

          let newFilterWithData = [];
          filters.map((item) => newFilterWithData.push(item["itemName"]));
          const filteredArray = newFilterWithData;
          const queryParams = decryptTheParams();

          for (var value of Object.keys(queryParams)) {
            if (filteredArray.includes(value)) {
              if (value === "Volt") {
                dispatch(SetAllBatteriesVolt(+queryParams[value]));
              } else if (value === "City") {
                dispatch(SetAllBatteriesCity(queryParams[value]));
              } else if (value === "Zone") {
                dispatch(SetAllBatteriesZone(queryParams[value]));
              } else if (value === "Status") {
                dispatch(SetAllBatteriesStatus(queryParams[value]));
              } else if (value === "IOT") {
                dispatch(SetAllBatteriesIOT(queryParams[value]));
              } else if (value === "Pack") {
                dispatch(SetAllBatteriesPack(queryParams[value]));
              } else if (value === "Severity") {
                dispatch(SetAllBatteriesSeverity(queryParams[value]));
              }
            } else {
              if (value === "batteryPartnerSelect") {
                dispatch(
                  SetAllBatteriesBatteryPartnerSelect(queryParams[value])
                );
              } else if (value === "userInputAllBatteries") {
                let arr =
                  queryParams[value].length === 0
                    ? []
                    : queryParams[value].split(",");
                dispatch(SetAllBatteriesUserInputBatteries(arr));
              } else if (value === "multipleAllPartners") {
                let arr =
                  queryParams[value].length === 0
                    ? []
                    : queryParams[value].split(",");
                dispatch(SetAllBatteriesMultiplePartners(arr));
              }
            }
          }

          setPagesContent({
            ...pagesContent,
            pageHeader: res.response.response.textInfo["pageHeader"],
            filtersWithData: filters,
            searchTypeFilter: res.response.response.filter.searchTypeFilter,
            filteredArray: filteredArray,
            batteryType: "",
            mounted: true,
          });
        } else if (res.responseStatus.code === -2) {
          dispatch(SetSessionExpired(true));
        }
      });

      // const handleSearch = (
      //   selectedKeys,
      //   confirm,
      //   dataIndex,

      //   close
      // ) => {
      //   const tempColumns = [...columns];
      //   tempColumns[0]["columns"].forEach((col) => {
      //     if (col.dataIndex === dataIndex) {
      //       col.checkedOptions = [...selectedKeys];
      //     }
      //   });
      //   dispatch(SetAllBatteriesColumns([...tempColumns]));
      //   close();
      // };
      // const handleReset = (
      //   setSelectedKeys,
      //   confirm,
      //   filters,
      //   dataIndex,

      //   close
      // ) => {
      //   const tempColumns = [...columns];
      //   filters.forEach((ele) => {
      //     ele.isChecked = false;
      //   });
      //   tempColumns[0]["columns"].forEach((col) => {
      //     if (col.dataIndex === dataIndex) {
      //       col.checkedOptions = [];
      //     }
      //   });
      //   setSelectedKeys([]);
      //   dispatch(SetAllBatteriesColumns([...tempColumns]));
      //   close();
      // };
      // const onChangeFilter = (
      //   ele,
      //   dataIndex,
      //   selectedKeys,
      //   setSelectedKeys,
      //   filters
      // ) => {
      //   if (ele.isChecked) {
      //     filters.forEach((element) => {
      //       if (element === ele) {
      //         ele.isChecked = false;
      //       }
      //     });

      //     const arr =
      //       selectedKeys.length > 0
      //         ? selectedKeys.filter((item) => item !== ele.value)
      //         : [];

      //     setSelectedKeys(arr);
      //   } else {
      //     filters.forEach((element) => {
      //       if (element === ele) {
      //         ele.isChecked = true;
      //       }
      //     });

      //     if (selectedKeys[0] === undefined) {
      //       setSelectedKeys([(selectedKeys[0] = "")]);
      //     }
      //     setSelectedKeys([...selectedKeys, ele.value]);
      //   }
      //   var isFilterEmpty = true;
      //   filters.forEach((ele) => {
      //     if (ele.isChecked === true) {
      //       isFilterEmpty = false;
      //     }
      //   });

      //   if (isFilterEmpty) {
      //     setSelectedKeys([]);
      //   }
      // };

      // const handleSelectAllFilters = (
      //   e,
      //   filters,
      //   selectedKeys,
      //   setSelectedKeys,
      //   dataIndex
      // ) => {
      //   if (e.target.checked) {
      //     const arr = [];

      //     if (selectedKeys[0] === undefined) {
      //       setSelectedKeys([(selectedKeys[0] = "")]);
      //     }
      //     filters
      //       .filter((ele) => ele.value.includes(selectedKeys[0]))
      //       .forEach((ele, key) => {
      //         ele.isChecked = true;
      //         arr.push(ele.value);
      //       });

      //     setSelectedKeys([...selectedKeys, ...arr]);
      //   } else {
      //     filters.forEach((ele) => {
      //       ele.isChecked = false;
      //     });
      //     setSelectedKeys([]);
      //   }
      // };

      // let checker = (arr) => arr.every((v) => v === true);

      // const getColumnSearchProps = (dataIndex) => ({
      //   filterDropdown: ({
      //     setSelectedKeys,
      //     selectedKeys,
      //     confirm,
      //     clearFilters,
      //     close,
      //     filters,
      //   }) => (
      //     <div
      //       style={{
      //         padding: 8,
      //       }}
      //       // onKeyDown={(e) => e.stopPropagation()}
      //       onClick={(e) => e?.stopPropagation()}
      //     >
      //       <Input
      //         ref={searchInput}
      //         placeholder={`Search ${dataIndex}`}
      //         value={selectedKeys[0]}
      //         onChange={(e) =>
      //           setSelectedKeys([
      //             (selectedKeys[0] = e.target.value),
      //             ...selectedKeys.splice(1),
      //           ])
      //         }
      //         style={{
      //           marginBottom: 8,
      //           display: "block",
      //         }}
      //       />

      //       {filters.filter((ele) =>
      //         ele.value.includes(
      //           selectedKeys[0] === undefined ? "" : selectedKeys[0]
      //         )
      //       ).length != 0 ? (
      //         <div>
      //           <Checkbox
      //             onClick={(e) =>
      //               handleSelectAllFilters(
      //                 e,
      //                 filters,
      //                 selectedKeys,
      //                 setSelectedKeys,
      //                 dataIndex
      //               )
      //             }
      //             checked={
      //               filters.filter((ele) =>
      //                 ele.value.includes(
      //                   selectedKeys[0] === undefined ? "" : selectedKeys[0]
      //                 )
      //               ).length != 0
      //                 ? checker(
      //                     filters
      //                       .filter((ele) =>
      //                         ele.value.includes(
      //                           selectedKeys[0] === undefined
      //                             ? ""
      //                             : selectedKeys[0]
      //                         )
      //                       )
      //                       .map((ele) => ele.isChecked == true)
      //                   )
      //                 : false
      //             }
      //           >
      //             Select all Items
      //           </Checkbox>

      //           <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
      //             {filters
      //               .filter((ele) =>
      //                 ele.value.includes(
      //                   selectedKeys[0] === undefined ? "" : selectedKeys[0]
      //                 )
      //               )
      //               .map((ele, key) => {
      //                 return (
      //                   <>
      //                     <div>
      //                       <Checkbox
      //                         key={key}
      //                         checked={ele.isChecked}
      //                         onChange={(e) =>
      //                           onChangeFilter(
      //                             ele,
      //                             dataIndex,
      //                             selectedKeys,
      //                             setSelectedKeys,
      //                             filters
      //                           )
      //                         }
      //                       >
      //                         {ele.value}
      //                       </Checkbox>
      //                       <br></br>
      //                     </div>
      //                   </>
      //                 );
      //               })}
      //           </div>

      //           <div className="dropDownBottom">
      //             <Button
      //               onClick={() =>
      //                 handleReset(
      //                   setSelectedKeys,
      //                   confirm,
      //                   filters,
      //                   dataIndex,

      //                   close
      //                 )
      //               }
      //             >
      //               Reset
      //             </Button>
      //             <Button
      //               type="primary"
      //               // disabled={selectedKeys.length>1?false:true}
      //               onClick={() =>
      //                 handleSearch(
      //                   selectedKeys,
      //                   confirm,
      //                   dataIndex,

      //                   close
      //                 )
      //               }
      //             >
      //               Apply
      //             </Button>
      //           </div>
      //         </div>
      //       ) : (
      //         <p style={{ textAlign: "center" }}>No Options Found</p>
      //       )}
      //     </div>
      //   ),

      //   filterMultiple: true,

      //   onFilter: (value, record) => {
      //     if (value.length != 0) {
      //       return record[dataIndex]
      //         .toString()
      //         .toLowerCase()
      //         .includes(value.toLowerCase());
      //     }
      //   },

      //   onFilterDropdownOpenChange: (visible) => {
      //     const tempColumns = [...columns];
      //     if (!visible) {
      //       tempColumns[0]["columns"].forEach((col) => {
      //         if (col.dataIndex === dataIndex) {
      //           col.filters.forEach((item) => {
      //             if (col.checkedOptions.includes(item.text)) {
      //               item.isChecked = true;
      //             } else {
      //               item.isChecked = false;
      //             }
      //           });
      //         }
      //       });
      //       dispatch(SetAllBatteriesColumns([...tempColumns]));
      //     } else {
      //       setTimeout(() => searchInput.current?.select(), 100);
      //     }
      //   },
      //   filterResetToDefaultFilteredValue: true,
      //   filterIcon: (filtered) => (
      //     <>
      //       <FilterAltIcon
      //         style={{
      //           color:
      //             columns[0]["columns"].filter(
      //               (element) => element.dataIndex === dataIndex
      //             )[0]["checkedOptions"].length > 0
      //               ? ThemeProperties.purple
      //               : undefined,
      //         }}
      //       />
      //     </>
      //   ),
      //   render: (text) => text,
      // });

      const columns = [
        {
          name: "Columns",
          columns: [
            {
              title: <Typography variant="tableHeadBold">Device ID</Typography>,
              name: "Battery ID",
              dataIndex: "batteryID",
              key: "batteryID",
              sorter: false,
              uniqueKey: "batteryIDActive",
              filterSearch: false,
              fixed: "left",
              width: 170,
              className: "undragableColumn",
              // maxWidth:"18px",
              render: (batteryID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(batteryID, "Battery ID")}
                </div>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Battery ID</Typography>
              ),
              name: "Asset ID",
              dataIndex: "assetID",
              key: "assetID",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 120,
              uniqueKey: "assetIDActive",
              render: (assetID) => (
                <div className="renderID">
                  {renderBatteryIDCopy(assetID, "Asset ID")}
                </div>
              ),
            },
            {
              title: (
                <Typography variant="tableHeadBold">Operating State</Typography>
              ),
              name: "Operating State",
              dataIndex: "batteryFlag",
              key: "batteryFlag",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 140,
              uniqueKey: "batteryFlag",
              render: (batteryFlag) => (
                <div className="renderID">
                  <Typography variant="tableRow">{batteryFlag === 0 ?"Operational" : batteryFlag === 1 ?  "Out of Service" : batteryFlag === 2 ? "Under Maintenance": "Isolated" }</Typography>
                </div>
              ),
            },

            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "socBMS", "SOC (%)"),
              name: "SOC (%)",
              dataIndex: "socBMS",
              key: "socBMS",
              sorter: true,
              align: "center",
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder:
                sortChangedTo === "socBMS" ? newOrderChangedTo : "",
              showSorterTooltip: false,
              filterSearch: false,
              // filterMode: "tree",
              filters: false,
              // ...getColumnSearchProps("socBMS"),
              checkedOptions: [],
              width: 120,
              uniqueKey: "socActive",
              render: (socBMS) => (
                <Typography variant="tableRow">{socBMS}</Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Severity</Typography>,
              name: "Severity",
              dataIndex: "severity",
              key: "severity",
              sorter: false,
              showSorterTooltip: false,
              checkedOptions: [],
              width: 130,
              uniqueKey: "severityActive",
              // render: (severity) => (
              //   <Typography variant="tableRow">
              //     {FindTheSeverity(severity)}
              //   </Typography>
              // ),
              render: (text, record) => (
                <div className="renderID">
                  {renderSeverity(
                    record.assetID,
                    record.severity,
                    record.batteryID,
                    record.latitude,
                    record.longitude
                  )}
                </div>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "sohBMS", "SOH (%)"),
              name: "SOH (%)",
              dataIndex: "sohBMS",
              key: "sohBMS",
              sorter: true,
              align: "center",

              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder:
                sortChangedTo === "sohBMS" ? newOrderChangedTo : "",
              showSorterTooltip: false,
              uniqueKey: "sohBMS",
              filterSearch: false,
              width: 100,
              // maxWidth:"18px",
              render: (sohBMS) => (
                <Typography variant="tableRow">{sohBMS?.toFixed(0)}</Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "eqCycleBMS", "Eq. Cycles"),
              name: "Eq. Cycles",
              dataIndex: "eqCycleBMS",
              key: "eqCycleBMS",
              sorter: true,
              align: "center",

              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder:
                sortChangedTo === "eqCycleBMS" ? newOrderChangedTo : "",
              showSorterTooltip: false,
              uniqueKey: "eqCycleBMS",
              filterSearch: false,
              width: 140,
              // maxWidth:"18px",
              render: (eqCycleBMS) => (
                <Typography variant="tableRow">
                  {eqCycleBMS?.toFixed(0)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Volt (V)</Typography>,
              name: "Volt (V)",
              dataIndex: "voltagePack",
              key: "voltagePack",
              align: "center",

              sorter: false,

              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "voltagePack",
              // width: "30%",
              width: 80,
              render: (voltagePack) => (
                <Typography variant="tableRow">
                  {(voltagePack / 1000).toFixed(1)}
                </Typography>
              ),
            },
            {
              title: (titleProps) =>
                getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
              name: "Last Pinged",

              dataIndex: "lastPingedTime",
              key: "lastPingedTime",
              sorter: true,
              sortDirections: ["ascend", "descend", "ascend"],
              defaultSortOrder:
                sortChangedTo === "lastPingedTime" ? newOrderChangedTo : "",
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "lastPingedTimeActive",
              // width: "30%",
              width: 160,
              render: (lastPingedTime) => (
                <Typography variant="tableRow">
                  {convertUTCtoZone(lastPingedTime)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Lat/Long</Typography>,
              name: "Lat/Long",
              dataIndex: "latitude",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filterSearch: false,
              uniqueKey: "latitudeActive",
              // width: "20%",
              width: 160,
              render: (text, record) => (
                <Typography variant="tableRow">
                  {record.latitude?.toFixed(6)}/{record.longitude?.toFixed(6)}
                </Typography>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">Occupant</Typography>,
              name: "Partner",
              dataIndex: "partner",
              sorter: false,
              showSorterTooltip: false,
              filterMode: "tree",
              filters: false,
              // ...getColumnSearchProps("partner"),
              checkedOptions: [],
              filterSearch: false,
              // width: "30%",
              width: 180,
              ellipsis: {
                showTitle: false,
              },
              uniqueKey: "partnerActive",
              render: (partner) => (
                <Tooltip placement="top" title={partner}>
                  <Typography variant="tableRow">{partner}</Typography>
                </Tooltip>
              ),
            },
            {
              title: <Typography variant="tableHeadBold">State</Typography>,
              name: "State",
              dataIndex: "batteryState",
              key: "batteryState",
              sorter: false,
              showSorterTooltip: false,
              filterSearch: false,
              uniqueKey: "batteryState",
              // width: "30%",
              width: 120,
              render: (batteryState) => (
                <Typography variant="tableRow">{batteryState}</Typography>
              ),
            },
            {
              name: "",
            },
          ],
        },
      ];

      dispatch(SetAllBatteriesColumns(columns));
      dispatch(SetAllBatteriesHiddenColumns([]));
    }, 2);

    return () => {
      setPagesContent({
        ...pagesContent,
        pageHeader: "",
        filtersWithData: [],
        filteredArray: [],
        batteryType: "",
        mounted: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {pagesContent.mounted ? (
        <>
          <AllBatteriesMain pagesContent={pagesContent} />
          <AllBatteriesSeverity
            modal={modal}
            handleModalClose={handleModalClose}
          />
        </>
      ) : (
        <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker,
          }}
        >
          <Loader />
        </Box>
      )}
    </div>
  );
}
export default AllBatteries;
