import React, { useEffect, useState, useMemo } from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import MapContent from "../../PagesContent/Map/MapContent";

import { OuterBox, InnerBox } from "../../Components/CustomBox";
import PageHeaderKPI from "../../Features/PageHeader/PageHeaderKPI";
import MultipeSearchCheckbox from "../../Features/Autocomplete/MultipeSearchCheckbox";
import MultipleSearchState from "../../Features/Autocomplete/MultipleSearchTags/MultipleSearchState";

import GenericSelectRedux from "../../Features/Menu/GenericSelectRedux";

import { getConnectedBatteries } from "../../Api/Api";
import { SetSessionExpired } from "../../Actions";
import { MapNonClickablekpis } from "../../Static/Data/Map/MapKPIS";


import {
  SetMapBatteryPartnerSelect,
  SetMapCity,
  SetMapStatus,
  SetMapSeverity,
  SetMapAlertType,
  SetMapZone,
  SetMapFoundBatteries,
  SetMapNotFoundBatteries,
  SetMapSearchedBattery,
  SetMapSearch,
  SetMapClusterClick,
  SetMapIotVendor,
  SetMapBatteryVendor,
  SetMapUserInputBatteries,
  SetMapMultiplePartners,
  SetOrgFilter,
} from "../../Actions/Map";
import { getMapBatteries, getFoundNotFoundBatteries } from "../../Api/MapApi";

import { useDispatch, useSelector } from "react-redux";

import {
  newIOT,
  newPack,
  newStatus,
} from "../../Static/Data/Dashboard/DashboardFilters";

import "../../PagesContent/Map/map.css";
import CODE from "../../Static/Constants/StatusCodes";
import Events from "../../Analytics/Events";
import { useNavigate } from "react-router-dom";

import { OutlinedButton } from "../../Components/CustomButton";
import MapClearBatteries from "../../Features/Dialog/MapClearBatteries";
import { notification } from "antd";
import {
  decryptTheParams,
  encryptTheParams,
  resetTheFilterValues,
  setTheFilterValues,
} from "../../Helper/QueryParams/EncryptDecrypt";
import GenericMultipleSelect from "../../Features/Menu/GenericMultipleSelect";
import { areArraysEqual } from "../../Helper/Operations/ArrayOps";
const Context = React.createContext({
  name: "Default",
});

function MapMain(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newCity = localStorage.getItem("LocationFilter")?.split(",");
  const newOrg = localStorage.getItem("OrgFilter")?.split(",");
  const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.info({
      // message: `Notification ${placement}`,s
      message: <Context.Consumer>{({ name }) => `${text}`}</Context.Consumer>,
      placement,
      duration: 4.5,
      className: "antnoti",
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Toast",
    }),
    []
  );

  // const [listOfPartners, setListOfPartners] = useState([]);
  const [connectedBatteries, setConnectedBatteries] = useState(0);
  const [resetButton, setResetButton] = useState(false);
  const [clickedMapBattery, setClickedMapBattery] = useState(false);
  const [openClearBatteryPopup, setOpenClearBatteryPopup] = useState(false);

  const select = useSelector((state) => state.MapBatteryPartnerSelect.value);
  const volt = useSelector((state) => state.MapVolt.value);
  const city = useSelector((state) => state.MapCity.value);
  const org = useSelector((state) => state.OrgFilter.value);

  const batterystatus = useSelector((state) => state.MapStatus.value);
  const severity = useSelector((state) => state.MapSeverity.value);
  const zone = useSelector((state) => state.MapZone.value);
  const alertType = useSelector((state) => state.MapAlertType.value);
  const iotVendor = useSelector((state) => state.MapIotVendor.value);
  const batteryVendor = useSelector((state) => state.MapBatteryVendor.value);

  const mapFullScreen = useSelector((state) => state.MapFullScreen.value);

  const mapSearch = useSelector((state) => state.MapSearch.value);
  const mapFoundBatteries = useSelector(
    (state) => state.MapFoundBatteries.value
  );

  const multipePartners = useSelector(
    (state) => state.MapMultiplePartners.value
  );
  const userInputBatteries = useSelector(
    (state) => state.MapUserInputBatteries.value
  );

  const [batteries, setBatteries] = useState({
    data: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });
  const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState("");
  const [openPartnerMarker, setOpenPartnerMarker] = useState("");
  const [openOEMMarker, setOpenOEMMarker] = useState("");

  const filters = {
    Volt: volt,
    Organization: org,
    Location: city,
    Status: batterystatus,
    Select: select,
    Severity: severity,
    Zone: zone,
    "Alert Type": alertType,
    IOT: iotVendor,
    "Product ID": batteryVendor,
  };

  const filters2 = {
    Organization: org,
    Location: city,
    Status: batterystatus,
    IOT: iotVendor,
    "Product ID": batteryVendor,
  };
  const sideDivStyle = {
    position: "fixed",
    right: 0,
    top: 0,
    transition: "all 0.5s ease",
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 10001,
    backgroundColor: "white",
    width: "100%",
    padding: "5px 5px 5px 15px",
    pointerEvents: batteries.responseStatus.code === -1 ? "none" : "auto",
  };
  const sideDivStyle2 = {
    position: "relative",
    display: "flex",
    pointerEvents: batteries.responseStatus.code === -1 ? "none" : "auto",
    opacity: batteries.responseStatus.code === -1 ? 0.7 : 1,
  };

  function showResetButton() {
    if (
      !areArraysEqual(org, newOrg) ||
      !areArraysEqual(city, newCity) ||
      !areArraysEqual(batterystatus, newStatus) ||
      !areArraysEqual(iotVendor, newIOT) ||
      !areArraysEqual(batteryVendor, newPack)
    ) {
      setResetButton(true);
    } else {
      let temp;
      const queryParams = decryptTheParams();

      setResetButton(false);
      // userInputBatteries.length === 0 &&
      //   openNotification(
      //     "bottomLeft",
      //     "All filters are removed. Page might become heavy to function. Consider selecting some relevant filters for smooth experience"
      //   );
      // temp = {
      //   ...queryParams,

      //   Status: ,
      //   City: "",
      //   Zone: "",
      //   IOT: "",
      //   Cell: "",
      //   vaidehi:""
      // };
      // encryptTheParams(temp, navigate);
    }
  }

  function handleSetSelect(name, value) {
    ClearSearchedBatteries();
    setOpenClearBatteryPopup(false);

    let temp;
    const queryParams = decryptTheParams();

    temp = {
      ...queryParams,
      batteryPartnerSelect: value,
    };
    dispatch(SetMapBatteryPartnerSelect(value));
    dispatch(SetMapUserInputBatteries([]));
    dispatch(SetMapMultiplePartners([]));

    encryptTheParams(temp, navigate);
  }

  function handleSetFilter(filterName, value) {
    Events("changed Map " + filterName);

    setTheFilterValues(filterName, value, navigate);

    dispatch(SetMapClusterClick(false));

    if (filterName === "Location") {
      dispatch(SetMapCity(value));
    } else if (filterName === "Organization") {
      dispatch(SetOrgFilter(value));
    } else if (filterName === "Status") {
      dispatch(SetMapStatus(value));
    } else if (filterName === "IOT") {
      dispatch(SetMapIotVendor(value));
    } else if (filterName === "Product ID") {
      dispatch(SetMapBatteryVendor(value));
    } else if (filterName === "Select") {
      dispatch(SetMapBatteryPartnerSelect(value));
    }
  }
  function resetTheFilters() {
    // dispatch(SetMapVolt(""));
    dispatch(SetMapCity(newCity));
    dispatch(SetOrgFilter(newOrg));
    dispatch(SetMapStatus(newStatus));
    dispatch(SetMapIotVendor(newIOT));
    dispatch(SetMapBatteryVendor(newPack));
    dispatch(SetMapClusterClick(false));
    resetTheFilterValues(props.pagesContent.filteredArray, navigate);

    Events("Clicked Map Reset Filter");
  }
  
  function getTheBatteriesInMap(
    volt,
    org,
    batterystatus,
    city,
    severity,
    zone,
    alertType,
    iotVendor,
    batteryVendor
  ) {
    dispatch(SetMapSearchedBattery(""));
    setOpenInfoWindowMarkerId("");
    setOpenPartnerMarker("");
    setOpenOEMMarker("");
    showResetButton();

    setBatteries({
      data: [],
      responseStatus: { code: CODE.LOADING, msg: "" },
    });

    if (
      org.length > 0 &&
      batterystatus.length > 0 &&
      iotVendor.length > 0 &&
      batteryVendor.length > 0
    ) {
      getMapBatteries(
        volt,
        org,
        city,
        zone,
        batterystatus,
        select === "Battery ID" ? [] : multipePartners,
        select === "Battery ID" || select === "Device ID"
          ? userInputBatteries
          : [],
        alertType,
        severity,
        iotVendor,
        batteryVendor,
        select
      ).then((res) => {
        if (res.responseStatus.code === CODE.SUCCESS) {
          setBatteries({
            data: res.response.mapData,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          setBatteries({
            data: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
    } else {
      setBatteries({
        data: [],
        responseStatus: {
          code: CODE.NODATA,
          msg: "No data found",
        },
      });
    }

    if (
      (select === "Battery ID" || select === "Device ID") &&
      mapFoundBatteries.length > 0
    ) {
      dispatch(SetMapSearch(true));
    }
  }

  function ClearSearchedBatteries() {
    let queryParams = decryptTheParams();
    dispatch(SetMapFoundBatteries([]));
    dispatch(SetMapSearch(false));
    dispatch(SetMapNotFoundBatteries([]));
    dispatch(SetMapUserInputBatteries([]));
    dispatch(SetMapCity(newCity));
    dispatch(SetOrgFilter(newOrg));
    dispatch(SetMapSeverity(""));
    dispatch(SetMapStatus(newStatus));
    dispatch(SetMapZone(""));
    dispatch(SetMapAlertType(""));
    dispatch(SetMapIotVendor(newIOT));
    dispatch(SetMapBatteryVendor(newPack));
    localStorage.removeItem("InputMapBatteries");

    let temp = {
      ...queryParams,
      // userInputMapBatteries: [],
      Location: newCity,
      Organization: newOrg,
      Status: newStatus,
      IOT: newIOT,
      "Product ID": newPack,
    };
    encryptTheParams(temp, navigate);
  }

  function FilterBox() {
    return (
      <div style={mapFullScreen === true ? sideDivStyle : sideDivStyle2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "felx-start",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "felx-start",
                ml: 1,
              }}
            >
              <Typography variant="textSubtitle">
                Batteries shown in map : <b>{batteries.data.length}</b>
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div>
              {resetButton && (
                <Button
                  sx={{ fontSize: "12px", height: "30px", mr: 0.5 }}
                  onClick={() => {
                    resetTheFilters(true);
                    // openNotification(
                    //   "bottomLeft",
                    //   "All filters are removed. Page might become heavy to function. Consider selecting some relevant filters for smooth experience"
                    // );
                  }}
                >
                  Reset
                </Button>
              )}
            </div>
            <div
              style={{
                display: "flex",
                opacity: batteries.responseStatus.code === -1 ? 0.7 : 1,
              }}
            >
              {/* {props.pagesContent.filtersWithData.map((item, index) => {
                // if (
                //   props.pagesContent.filteredArray.includes(item["itemName"])
                // ) {
                  return (
                    <Box key={index}>
                      <GenericMultipleSelect
                        filter={item}
                        handleSetFilter={handleSetFilter}
                        selected={filters[item["itemName"]]}
                        maxWidth={"100px"}
                        showClearButton={true}
                        id="overflow-text-1"
                        pageName={"Map"}
                      />
                    </Box>
                  );
                //}
              })} */}
              {props.pagesContent.filtersWithData.map(
                ({ itemName, items }, index) => {
                  return (
                    <Box key={index}>
                      <GenericMultipleSelect
                        label={itemName}
                        options={items}
                        maxWidth={"100px"}
                        optionValue={filters2[itemName]}
                        handleSetFilter={handleSetFilter}
                        id="overflow-text-3"
                      />
                    </Box>
                  );
                }
              )}
            </div>
          </Box>
        </Box>
      </div>
    );
  }

  useEffect(() => {
    getConnectedBatteries().then((res) => {
      if (res.responseStatus.code === 200) {
        setConnectedBatteries(res.response.data[0]["count"]);
      } else if (res.responseStatus.code === -2) {
        dispatch(SetSessionExpired(true));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezoneChanged]);

  useEffect(() => {
    getTheBatteriesInMap(
      volt,
      org,
      batterystatus,
      city,
      severity,
      zone,
      alertType,
      iotVendor,
      batteryVendor
    );

    return () => {
      setBatteries({
        data: [],
        responseStatus: { code: CODE.LOADING, msg: "" },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timezoneChanged,
    volt,
    batterystatus,
    org,
    city,
    severity,
    zone,
    alertType,
    iotVendor,
    batteryVendor,
    // userInputBatteries,
    // multipePartners,
    // select,
  ]);

  useEffect(() => {
    dispatch(SetMapSearchedBattery(""));

    if (userInputBatteries.length > 0) {
      getFoundNotFoundBatteries(userInputBatteries, select).then((res) => {
        if (
          (res && res.responseStatus.code === 200) ||
          res.responseStatus.code === 204 ||
          res.responseStatus.code === 1999 ||
          res.responseStatus.code === 404
        ) {
          let found = [];
          res.response.mapData?.forEach((item) => {
            if (select === "Device ID") found.push(item.batteryID);
            else found.push(item.assetID);
          });
          dispatch(SetMapFoundBatteries(found));
          const foundBatteriesSet = new Set(found);
          const notFoundBatteriesSet = userInputBatteries.filter((name) => {
            return !foundBatteriesSet.has(name);
          });
          dispatch(SetMapNotFoundBatteries(notFoundBatteriesSet));
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));

          dispatch(SetMapFoundBatteries([]));
          dispatch(SetMapNotFoundBatteries([]));
        }
      });
    } else {
      getTheBatteriesInMap(
        volt,
        org,
        batterystatus,
        city,
        severity,
        zone,
        alertType,
        iotVendor,
        batteryVendor
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezoneChanged, userInputBatteries]);

  return (
    <OuterBox style={{ position: "relative" }}>
      <InnerBox>
        <Context.Provider value={contextValue}>
          {contextHolder}
          <PageHeaderKPI
            name={"Map"}
            clickableKpis={[]}
            clickableValues={0}
            nonClickableKpis={MapNonClickablekpis}
            nonClickableValues={{
              first: 0,
            }}
            connectedBatteries={connectedBatteries}
          />
          <Box sx={{ mt: 2 }}></Box>
          <Paper
            elevation={0}
            sx={{
              height: "100%",
              borderRadius: "12px",
              pl: 0.5,
              // mt: 2.5,
              display: "flex",
              alignItems: "center",
              pointerEvents:
                batteries.responseStatus.code === -1 ? "none" : "auto",
              opacity: batteries.responseStatus.code === -1 ? 0.7 : 1,
              // border: "1px solid #ececec",
            }}
          >
            <GenericSelectRedux
              filter={props.pagesContent.searchTypeFilter}
              handleSetFilter={handleSetSelect}
              selected={filters.Select}
              maxWidth={"max-content"}
              showClearButton={false}
              id="batteryState"
            />
            {filters.Select === "Battery ID" ||
            filters.Select === "Device ID" ? (
              <MultipleSearchState
                type="Map"
                limitTags={5}
                maxHeight={200}
                readOnly={batteries.responseStatus.code === -1 ? true : false}
                openNotification={openNotification}
                ClearSearchedBatteries={ClearSearchedBatteries}
                select={select}
              />
            ) : (
              <MultipeSearchCheckbox data={[]} type="Map" />
            )}

            <OutlinedButton
              variant="contained"
              sx={{ ml: 1.5, pt: -0.5, height: "40px", borderRadius: "6px" }}
              onClick={() => {
                dispatch(SetMapCity(newCity));
                dispatch(SetOrgFilter(newOrg));
                resetTheFilters();
                getTheBatteriesInMap(
                  [],
                  newOrg,
                  newStatus,
                  newCity,
                  [],
                  [],
                  [],
                  newIOT,
                  newPack
                );
                // let queryParams = decryptTheParams();
                // let temp = {
                //   ...queryParams,
                //   Location: newCity,
                // };
                // encryptTheParams(temp, navigate);
                let text =
                  select === "Battery ID"
                    ? "Map batteries searched"
                    : select === "Device ID"
                    ? "Map batteries searched"
                    : "Map partners searched";
                Events(text);
              }}
              disabled={
                mapFoundBatteries.length === 0 && multipePartners.length === 0
              }
            >
              <Typography variant="textSubtitleWhite">Search</Typography>
            </OutlinedButton>
          </Paper>

          <Paper
            elevation={0}
            sx={{
              height: "100%",
              borderRadius: "12px",
              p: 1,
              mt: 0.8,
              border: "1px solid #ececec",
            }}
          >
            {FilterBox()}
            <hr style={{ borderTop: "1px solid #ececec" }}></hr>

            {/* {loader ? (
            <div style={{ height: "100vh" }}>
              <Loader />
            </div>
          ) : ( */}
            <div style={{ position: !mapFullScreen ? "relative" : "unset" }}>
              <MapContent
                data={batteries}
                volt={volt}
                severity={severity}
                openInfoWindowMarkerId={openInfoWindowMarkerId}
                setOpenInfoWindowMarkerId={setOpenInfoWindowMarkerId}
                openPartnerMarker={openPartnerMarker}
                setOpenPartnerMarker={setOpenPartnerMarker}
                openOEMMarker={openOEMMarker}
                setOpenOEMMarker={setOpenOEMMarker}
                clickedMapBattery={clickedMapBattery}
                setClickedMapBattery={setClickedMapBattery}
              />
              {/* )} */}
              <div
                className={mapSearch ? "mapSlider_open" : "mapSlider_close"}
                style={{
                  position: "absolute",
                  top: mapFullScreen ? "6.5rem" : 0,
                  left: 0,

                  width: mapSearch ? 220 : 0,
                  zIndex: !mapFullScreen ? 1 : 100001,
                  // top: !mapFullScreen ? 0 : "60px",
                  // left: !mapFullScreen ? 0 : "70px",
                  backgroundColor: "white",
                }}
              >
                {" "}
                {/* {mapSearch && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "14px 0px 5px 8px",
                      }}
                    >
                      <Box>
                        <OutlinedButton
                          variant="outlined"
                          onClick={() => {
                            setOpenClearBatteryPopup(true);
                          }}
                        >
                          <Typography variant="textSubtitlePurple">
                            Clear Search
                          </Typography>
                        </OutlinedButton>
                      </Box>
                      <KeyboardDoubleArrowLeftIcon
                        onClick={() => {
                          dispatch(SetMapSearch(false));
                          setClickedMapBattery(false);
                          Events("closed Map expand search batteries");
                        }}
                        style={{
                          fontSize: "20px",
                          border: `1px solid ${ThemeProperties.purple}`,
                          backgroundColor: ThemeProperties.purple,
                          cursor: "pointer",
                          padding: "5px",
                          borderRadius: "50%",
                          margin: "0px 12px 5px 15px",
                          color: ThemeProperties.white,

                          //pointerEvents: mapFoundBatteries.length > 0 ? "auto" : "none",
                        }}
                      />{" "}
                    </Box>

                    <Box
                      sx={{
                        overflowY:
                          mapFoundBatteries.length > 0 ? "scroll" : "hidden",
                        maxHeight: mapFullScreen
                          ? "calc(94vh - 6.5rem)"
                          : "94vh",
                        height: "100vh",
                      }}
                      id="scrolling_div"
                    >
                      {mapFoundBatteries.length > 0 ? (
                        <>
                          {batteries.data.length > 0 ? (
                            <>
                              {batteries.data
                                .sort(function (a, b) {
                                  return (
                                    parseFloat(+b.severity) -
                                    parseFloat(+a.severity)
                                  );
                                })
                                .filter((item) =>
                                  mapFoundBatteries.find(
                                    (item2) => item2 === item.assetID || item2 === item.batteryID
                                  )
                                )
                                .map((item) => (
                                  <ListMapFoundBatteries
                                    data={item}
                                    setClickedMapBattery={setClickedMapBattery}
                                    clickedMapBattery={clickedMapBattery}
                                    select={select}
                                  />
                                ))}
                            </>
                          ) : (
                            <>
                              {batteries.responseStatus.code === CODE.NODATA ? (
                                <NoData text={"No batteries found"} />
                              ) : (
                                <Loader />
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <NoData text={"No batteries searched"} />
                      )}
                    </Box>
                  </>
                )} */}
              </div>
            </div>
          </Paper>
          {/* {openClearBatteryPopup && (
            <MapClearBatteries
              openClearBatteryPopup={openClearBatteryPopup}
              setOpenClearBatteryPopup={setOpenClearBatteryPopup}
              ClearSearchedBatteries={ClearSearchedBatteries}
              openNotification={openNotification}
            />
          )} */}
        </Context.Provider>
      </InnerBox>
    </OuterBox>
  );
}

export default MapMain;
