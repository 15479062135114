import React, { useState } from "react"
import { PageSectionContainer } from "../../PagesContent/AlertAnalytics/Components/StyledComponent"
import { FlexBox } from "../../Components/CustomBox"
import { AnalyticsDrillDownPageTitleText, DrillDownPageContainer } from "../../PagesContent/AlertDrillDown/Components/StyledComponent"
import FilterSection from "../../PagesContent/AlertDrillDown/FilterSection"
import TableSection from "../../PagesContent/AlertDrillDown/TableSection"
import ChartSection from "../../PagesContent/AlertDrillDown/ChartSection"

function AlertDrillDownMain({ pagesContent }) {
  const [granularity, setGranularity] = useState({granularity: 'daily'})
  const [chartFilterValue, setChartFilterValue] = useState({})
  const [isCardFilterMounted, setIsCardFilterMounted] = useState(false)
  const handleChartFilterValue = (filterMounted, value, label) => {
    if (label) {
      setChartFilterValue({[label]: [value]})
    }
    setIsCardFilterMounted(filterMounted)
  }
  return (
    <FlexBox>
      <DrillDownPageContainer>
          <PageSectionContainer>
            <AnalyticsDrillDownPageTitleText>
              {pagesContent.pageHeader}
            </AnalyticsDrillDownPageTitleText>
          </PageSectionContainer>
          <ChartSection granularity={granularity} setGranularity={setGranularity} handleChartFilter={handleChartFilterValue}/>
          <TableSection pagesContent={pagesContent} chartFilterValue={chartFilterValue} isCardFilterMounted={isCardFilterMounted}/>
      </DrillDownPageContainer>
      <FilterSection pagesContent={pagesContent} granularity={granularity} setGranularity={setGranularity}/>
    </FlexBox>
  )
}

export default AlertDrillDownMain
