import { PTypography } from "../../../Components/CustomP";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import { Typography } from "@mui/material";
import { Tooltip } from "antd";
import { getColumnTitleSort } from "../../../Features/Table/AntDesign/TableFunctions";
import { convertUTCtoZone } from "../../../Helper/DatePicker/DateConverter";


const columnsNew = [
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "anomalySeen", "Anomaly First Seen"),

    dataIndex: "anomalySeen",
    key: "anomalySeen",
    defaultSortOrder: "descend",
    uniqueKey: "anomalySeen",
    filterSearch: false,
    width: 175,
    sorter: (a, b) => new Date(a.anomalySeen) - new Date(b.anomalySeen),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
    // maxWidth:"18px",
    render: (anomalySeen) => (
      <Typography variant="tableRow">
        {convertUTCtoZone(anomalySeen)}
      </Typography>
    ),
  },
  {
    title: (
      <Typography variant="tableHeadBold">
        Anomaly Type
      </Typography>
    ),
    dataIndex: "anomalyDescription",
    key: "anomalyDescription",
    sorter: false,
    uniqueKey: "anomalyDescription",
    filterSearch: false,
    width: 180,
    // maxWidth:"18px",
    render: (anomalyDescription) => (
      <Tooltip placement="top" title={anomalyDescription}>
        <Typography variant="tableRow">{anomalyDescription}</Typography>
      </Tooltip>
    ),
  },
  {
    title: (
      <Typography variant="tableHeadBold">
        Status
      </Typography>
    ),
    dataIndex: "anomalyStatus",
    key: "anomalyStatus",
    sorter: false,
    align: "left",
    uniqueKey: "anomalyStatus",
    filterSearch: false,
    width: 100,
    // maxWidth:"18px",
    render: (anomalyStatus) => (
      <PTypography
        mycolor={
          anomalyStatus === "Active"
            ? ThemeProperties.red_100
            : anomalyStatus === "Open"
            ? ThemeProperties.blue_100
            : anomalyStatus === "Resolved"
            ? ThemeProperties.green_100
            : ThemeProperties.white
        }
      >
        <Typography variant="tableRow">
          {anomalyStatus}
        </Typography>
      </PTypography>
    ),
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "expiredTime", "Expired On"),

    dataIndex: "expiredTime",
    key: "expiredTime",

    uniqueKey: "expiredTime",
    filterSearch: false,
    width: 160,
    sorter: (a, b) => new Date(a.expiredTime) - new Date(b.expiredTime),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
    // maxWidth:"18px",
    render: (expiredTime) => (
      <Typography variant="tableRow">
        {expiredTime !== null && convertUTCtoZone(expiredTime)}
      </Typography>
    ),
  },
];

export {  columnsNew };
