import React, { useEffect, useState } from "react";
import {
  BatteryPackMetrics,
  GetTheGranularityTrend,
} from "../_DownloadCSV/CSVDownload";
import { PaperHighlight } from "../../../../Components/CustomPaper";

import Header from "../../../../Features/Header/Header";
import CODE from "../../../../Static/Constants/StatusCodes";
import ApexMultiYAxis from "../../../../Charts/Line/ApexMultiYAxis";
import FindTheThresholdMetrics from "../../../../Helper/Operations/FindTheThresholdMetrics";
import Events from "../../../../Analytics/Events";

function KeyMetrics(props) {
  const hierarchy = props.hierarchy;

  const [metricsCSV, setMetricsCSV] = useState({
    data: [],
    responseStatus: { code: 0 },
  });

  const [statusClicked, setStatusClicked] = useState({
    Voltage: true,
    Current: true,
    SOC: true,
    Temperature: true,
  });

  function GetMetricsCSV() {
    setMetricsCSV({
      data: [],
      responseStatus: {
        code: -1,
      },
    });
    let obj =
      props.granular === "Raw Data" &&
      props.startDate.toLowerCase().indexOf(":") === -1
        ? BatteryPackMetrics(
            props.batteryID,
            hierarchy.selectedPack.value,
            hierarchy.selectedModule.value,
            hierarchy.selectedCell.value,
            hierarchy.level,
            statusClicked,
            [(props.dateString[0], props.dateString[1])]
          )
        : GetTheGranularityTrend(
            props.batteryID,
            hierarchy.selectedPack.value,
            hierarchy.selectedModule.value,
            hierarchy.selectedCell.value,
            hierarchy.level,
            ["SOC", "temperature", "CURRENT", "VOLTAGE"],
            [props.startDate.split(":")[0], props.startDate.split(":")[1]],
            props.granular,
            props.operationType,
            statusClicked,
            "Column",
            "Battery Key Metrics"
          );
    obj.then((res) => {
      if (res.responseStatus.code === CODE.SUCCESS)
        setMetricsCSV({
          data: res.data,
          responseStatus: {
            code: res.responseStatus.code,
          },
        });
      else
        setMetricsCSV({
          data: [],
          responseStatus: {
            code: res.responseStatus.code,
          },
        });
    });
  }

  useEffect(() => {}, [
    props.startDate,

    hierarchy.selectedPack.value,
    hierarchy.selectedModule.value,
    hierarchy.selectedCell.value,
    hierarchy.level,
  ]);

  return (
    <PaperHighlight
      elevation={0}
      onMouseEnter={() => Events("SB Hovered Key Metrics Chart")}
    >
      <Header
        heading={props.generateHeading("Key Metrics")}
        description="Showing how battery signals (Voltage, Current, Temperature, SOC) are fluctuating over time within a day"
        showIButton={true}
        showThreeDots={false}
        showCSVDownload={true}
        csvData={metricsCSV}
        SetCSVData={GetMetricsCSV}
        setStatusBack={setMetricsCSV}
        id="Battery_Pack_Metrics"
        page="specificBattery"
      />
      <div className="section" id="Battery_Pack_Metrics">
        <ApexMultiYAxis
          startDate={props.startDate}
          hierarchy={hierarchy}
          batteryID={props.batteryID}
          alertFirstSeenOn={props.queryParams["alertFirstSeenOn"]}
          current={FindTheThresholdMetrics(hierarchy.level, "Current")}
          voltage={FindTheThresholdMetrics(hierarchy.level, "Voltage")}
          soc={FindTheThresholdMetrics(hierarchy.level, "SOC")}
          temperature={FindTheThresholdMetrics(hierarchy.level, "Temperature")}
          granular={props.granular}
          operationType={props.operationType}
          statusClicked={statusClicked}
          setStatusClicked={setStatusClicked}
        />
      </div>
    </PaperHighlight>
  );
}

export default KeyMetrics;
