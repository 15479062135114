import { PTypography } from "../../../Components/CustomP";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import { Typography } from "@mui/material";
import { getColumnTitleSort } from "../../../Features/Table/AntDesign/TableFunctions";
import { convertUTCtoZone } from "../../../Helper/DatePicker/DateConverter";

const columnsNew = [
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "insertionTime", "Error First Seen"),

    dataIndex: "insertionTime",
    key: "insertionTime",
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
    uniqueKey: "insertionTime",
    defaultSortOrder: "descend",
    filterSearch: false,
    width: 160,
    sorter: (a, b) => new Date(a.insertionTime) - new Date(b.insertionTime),
    // maxWidth:"18px",
    render: (insertionTime) => (
      <Typography variant="tableRow">
        {convertUTCtoZone(insertionTime)}
      </Typography>
    ),
  },
  {
    title: (
      <Typography variant="tableHeadBold">
        Error Type
      </Typography>
    ),
    dataIndex: "errorCode",
    key: "errorCode",
    sorter: false,
    uniqueKey: "errorCode",
    filterSearch: false,
    width: 140,
    // maxWidth:"18px",
    render: (errorCode) => (
      <Typography variant="tableRow">{errorCode}</Typography>
    ),
  },
  {
    title: (
      <Typography variant="tableHeadBold">
        Status
      </Typography>
    ),
    dataIndex: "errorStatus",
    key: "errorStatus",
    sorter: false,
    align: "left",
    uniqueKey: "errorStatus",
    filterSearch: false,
    width: 100,
    // maxWidth:"18px",
    render: (errorStatus) => (
      <PTypography
        mycolor={
          errorStatus === "Active"
            ? ThemeProperties.red_100
            : errorStatus === "Open"
            ? ThemeProperties.blue_100
            : errorStatus === "Resolved"
            ? ThemeProperties.green_100
            : errorStatus === "Transformed"
            ? ThemeProperties.grey
            : ThemeProperties.white
        }
      >
        <Typography variant="tableRow">
          {errorStatus !== null && errorStatus}
        </Typography>
      </PTypography>
    ),
  },
  {
    title: (titleProps) =>
      getColumnTitleSort(titleProps, "resolveCustomerDBTime", "Resolved On"),
    dataIndex: "resolveCustomerDBTime",
    key: "resolveCustomerDBTime",
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
    sorter: (a, b) =>
      new Date(a.resolveCustomerDBTime) - new Date(b.resolveCustomerDBTime),
    uniqueKey: "resolveCustomerDBTime",
    filterSearch: false,
    width: 160,
    // maxWidth:"18px",
    render: (resolveCustomerDBTime) => (
      <Typography variant="tableRow">
        {resolveCustomerDBTime !== null
          && convertUTCtoZone(resolveCustomerDBTime)}
      </Typography>
    ),
  },
];

export { columnsNew };
