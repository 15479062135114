import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const SecondaryButton = styled(Button)({
  // "& .MuiButtonBase-root": {
    justifyContent: "flex-start",
    textTransform: "none",
    width:"max-content",
    minWidth:"max-content"
  // },
});

const OutlinedButton = styled(Button)({
  // "& .MuiButtonBase-root": {
    justifyContent: "flex-start",
    textTransform: "none",
    width:"max-content",
    minWidth:"max-content",
    borderRadius:"8px",
    height:"33px"
  // },
});

export { SecondaryButton, OutlinedButton };
