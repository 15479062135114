export const SetIsBatteryIsPartnerIsNullWarranty = (value) =>
{
    return {
        type: "IsBatteryIsPartnerIsNullWarranty",
        value: value
    }
}
export const SetWarrantyBatteryID = (batteryID) =>
{
    return {
        type: "WarrantyBatteryID",
        batteryID: batteryID
    }
}
export const SetWarrantyPartner = (partner) =>
{
    return {
        type: "WarrantyPartner",
        partner: partner
    }
}

export const SetWarrantyVolt = (volt) =>
{
    return {
        type: "WarrantyVolt",
        volt: volt
    }
}
export const SetWarrantyCity = (city) =>
{
    return {
        type: "WarrantyCity",
        city: city
    }
}
export const SetWarrantyCell = (cell) =>
{
    return {
        type: "WarrantyCell",
        cell: cell
    }
}
export const SetWarrantyBusiness = (business) =>
{
    return {
        type: "WarrantyBusiness",
        business: business
    }
}
export const SetWarrantyVersion = (version) =>
{
    return {
        type: "WarrantyVersion",
        version: version
    }
}
export const SetWarrantyBuildNo = (buildNo) =>
{
    return {
        type: "WarrantyBuildNo",
        payload: buildNo
    }
}
export const SetWarrantyStatus = (status) =>
{
    return {
        type: "WarrantyStatus",
        status: status
    }
}





export const SetWarrantyIOT = (iot) =>
{
    return {
        type: "WarrantyIOT",
        iot: iot
    }
}
export const SetWarrantyPack = (pack) =>
{
    return {
        type: "WarrantyPack",
        pack: pack
    }
}
export const SetWarrantyCycle = (cycle) =>
{
    return {
        type: "WarrantyCycle",
        cycle: cycle
    }
}
export const SetWarrantyAge = (age) =>
{
    return {
        type: "WarrantyAge",
        age: age
    }
}
export const SetWarrantySOH = (soh) =>
{
    return {
        type: "WarrantySOH",
        soh: soh
    }
}





export const SetWarrantyTabValue = (tab) =>
{
    return {
        type: "WarrantyTabValue",
        tab: tab
    }
}

export const SetWarrantyIndex = (index) =>
{
    return {
        type: "WarrantyIndex",
        index: index
    }
}

export const SetWarrantyCode = (code) =>
{
    return {
        type: "WarrantyCode",
        code: code
    }
}

export const SetWarrantyTotal = (total) =>
{
    return {
        type: "WarrantyTotal",
        total: total
    }
}

export const SetWarrantyStartDate = (start) =>
{
    return {
        type: "WarrantyStartDate",
        start: start
    }
}

export const SetWarrantyEndDate = (end) =>
{
    return {
        type: "WarrantyEndDate",
        end: end
    }
}

export const SetWarrantyList = (list) =>
{
    return {
        type: "WarrantyList",
        list: list
    }
}

export const SetWarrantyListOfBatteries = (list) =>
{
    return {
        type: "WarrantyListOfBatteries",
        list: list
    }
}

export const SetWarrantyStack = (batteries) =>
{
    return{
        type: "WarrantyStack",
        batteries:batteries
    }
}

export const SetWarrantyColumnSort = (sort) =>
{
    return{
        type: "WarrantyColumnSort",
        sort:sort
    }
}

export const SetWarrantyColumnSortOrder = (sortOrder) =>
{
    return{
        type: "WarrantyColumnSortOrder",
        sortOrder:sortOrder
    }
}
export const SetWarrantyColumns = (columns) =>
{
    return {
        type: "WarrantyColumns",
        value: columns
    }
}
export const SetWarrantyHiddenColumns = (hidden) =>
{
    return {
        type: "WarrantyHiddenColumns",
        value: hidden
    }
}
export const SetWarrantyPageNumber = (page) =>
{
    return {
        type: "WarrantyPageNumber",
        page: page
    }
}

export const SetWarrantyRowsPerPage = (rows) =>
{
    return {
        type: "WarrantyRowsPerPage",
        rows: rows
    }
}
export const SetWarrantyOpenAddSelectedBatteries = (open) =>
{
    return {
        type: "WarrantyOpenAddSelectedBatteries",
        open: open
    }
}