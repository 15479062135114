import React from "react"
import { SvgIcon } from "@mui/material"

const AlertAnalyticsSvg = (props) => {
  const { height, width, viewBox, fill, stroke, style, ...other } = props
  return (
    <SvgIcon
      style={style}
    >
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_568_8042)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.369 21.357C19.8227 21.357 20.1904 20.9892 20.1904 20.5356L20.1904 7.61546C20.1904 7.16179 19.8227 6.79403 19.369 6.79403L16.659 6.79404C16.2054 6.79404 15.8376 7.16179 15.8376 7.61546L15.8376 20.5356C15.8376 20.9892 16.2054 21.357 16.659 21.357L19.369 21.357ZM13.7484 21.357C14.202 21.357 14.5698 20.9892 14.5698 20.5356L14.5698 11.7733C14.5698 11.3196 14.202 10.9519 13.7484 10.9519L11.0384 10.9519C10.5847 10.9519 10.2169 11.3196 10.2169 11.7733L10.2169 20.5356C10.2169 20.9892 10.5847 21.357 11.0384 21.357L13.7484 21.357ZM8.93351 20.5356C8.93351 20.9892 8.56574 21.357 8.11208 21.357L5.40201 21.357C4.94841 21.357 4.58058 20.9892 4.58058 20.5356L4.58058 1.64273C4.58058 1.18913 4.94841 0.821297 5.402 0.821297L8.11208 0.821297C8.56574 0.821297 8.93351 1.18913 8.93351 1.64273L8.93351 20.5356Z" fill={fill}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.1786 20.9464C22.1786 20.2659 21.627 19.7142 20.9465 19.7142L3.28576 19.7142L3.28576 2.05351C3.28576 1.37304 2.73409 0.821372 2.05362 0.821372C1.37315 0.821372 0.821477 1.37304 0.821477 2.05351L0.821478 20.9464C0.821478 21.6269 1.37315 22.1785 2.05362 22.1785L20.9465 22.1785C21.627 22.1785 22.1786 21.6269 22.1786 20.9464Z" fill="#BBC5FF"/>
        </g>
        <defs>
        <clipPath id="clip0_568_8042">
        <rect width="23" height="23" fill="white" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 23 23)"/>
        </clipPath>
        </defs>
      </svg>

    </SvgIcon>
  )
}

AlertAnalyticsSvg.defaultProps = {
  width: "23",
  height: "23",
  viewBox: "0 0 23 23",
  fill: "#8D9FB2",
  stroke: ""
}

export default AlertAnalyticsSvg
