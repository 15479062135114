import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import CODE from "../../Static/Constants/StatusCodes";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import DigitalTwinMain from "./DigitalTwinMain";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../Helper/QueryParams/EncryptDecrypt";
import { getRealtimeKPI, getBatteryHierarchy, getColorMapping, getAlertsByGroup, getBatteryHierarchicalAlertsCount } from "../../Api/DigitalTwin";
import { SetBatteryLiveMetric } from "../../Actions/BatteryHealth";
import { useNavigate } from "react-router-dom";
import {
  convertAndGetTimeStampInRequiredFormat,
  getDatePickerDate,
} from "../../Helper/DatePicker/DateConverter";
import { getDashboardFilters } from "../../Api/DashboardApi";
import Events from "../../Analytics/Events";
import Cookies from "universal-cookie";
import { getPagesMetaData } from "../../Api/Api";

const cookies = new Cookies();

function DigitalTwin(props) {
  const navigate = useNavigate();
  const queryParams = decryptTheParams();
  const timezoneChanged = useSelector((state) => state.TimeZoneChanged.value);
  const dispatch = useDispatch();
  const batteryID = queryParams?.batteryID;
  const deviceID = queryParams?.deviceID;
  const level = queryParams.level || "battery";
  const packID = queryParams.packID || "";
  const moduleID = queryParams.moduleID || "";
  const cellID = queryParams.cellID || "";
  const [alertsCountHierarchical, setAlertsCountHierarchical] = useState(null);
  const [alertsForAnnotation, setAlertsForAnnotation] = useState([]);
  const [dateFilters, setDateFilters] = useState({
    startDate:
      queryParams?.startDateSB ||
      getDatePickerDate(),
    endDate: queryParams?.endDateSB || getDatePickerDate(),
  });
  const [liveMetricDataForBattery, setLiveMetricDataForBattery] = useState(null)
  const [hierarchyLiveMetricData, setHierarchyLiveMetricData] = useState(null);
  const [hierarchySequence, setHierarchySequence] = useState({
    pack: [],
    module: [],
    cell: []
  });

  const [batteryHierarchy, setBatteryHierarchy] = useState(null);
  const realTimeKPI = useSelector((state) => state.BatteryLiveMetric);
  const [alertList, setAlertsList] = useState([]);
  const [alertsFilters, setAlertsFilters] = useState([]);
  const [alertsSorts, setAlertsSorts] = useState([]);
  const [isAlertFetching, setIsAlertFetching] = useState(true);
  const [isRealTimeKPIFetching, setIsRealTimeKPIFetching] = useState(true);
  const [isFetchingFilters, setIsFetchingFilters] = useState(true);
  const [isHierarchyFetching, setIsHierarchyFetching] = useState(true);
  const [isHierarchyAlertsCountFetching, setIsHierarchyAlertsCountFetching] =
    useState(true);

  const [pagesContent, setPagesContent] = useState({
    batteryID: "",
    partner: "",
    tabValue: 0,
    pageHeader: "",
    mounted: false,
  });

  const getStructuredData = (realTimeData, level) => {
    const isBatteryLevel = level === "battery";
    const newRealTimeData = { ...realTimeKPI };
    newRealTimeData.soh.value = realTimeData?.soh || "NA";
    newRealTimeData.lastPingedTime.value =
      convertAndGetTimeStampInRequiredFormat(
        realTimeData?.lastPingedTime,
        "DD MMM'YY, hh:mm A"
      ) || "NA";
    newRealTimeData.formattedLatitudeLongitude.value =
      `${realTimeData.latitude}/${realTimeData.longitude}` || "NA";
    newRealTimeData.location.value = realTimeData?.location || "NA";
    newRealTimeData.eqCycle.value = realTimeData?.eqCycles || "NA";
    newRealTimeData.current.value = realTimeData?.current || "NA";
    newRealTimeData.status = realTimeData?.status;
    newRealTimeData.batteryPingedStatus = realTimeData?.batteryPingedStatus;
    newRealTimeData.voltage.value = isBatteryLevel
      ? { min: realTimeData.minVoltage, max: realTimeData.maxVoltage }
      : Number.isFinite(realTimeData.voltage)
      ? realTimeData.voltage
      : "NA";
    newRealTimeData.soc.value = isBatteryLevel
      ? { min: realTimeData.minSOC, max: realTimeData.maxSOC }
      : Number.isFinite(realTimeData.soc)
      ? realTimeData.soc
      : "NA";
    newRealTimeData.temperature.value = isBatteryLevel
      ? { min: realTimeData.minTemperature, max: realTimeData.maxTemperature }
      : Number.isFinite(realTimeData.temperature)
      ? realTimeData.temperature
      : "NA";
    newRealTimeData.capacity.value = isBatteryLevel
      ? { min: realTimeData.minCapacity, max: realTimeData.maxCapacity }
      : Number.isFinite(realTimeData.capacity)
      ? realTimeData.capacity
      : "NA";
    return newRealTimeData;
  };

  const fetchRealTimeKPI = async () => {
    setIsRealTimeKPIFetching(true);
    const res = await getRealtimeKPI(
      batteryID,
      packID,
      moduleID,
      cellID,
      level
    );

    if (res?.responseStatus?.code === CODE.SUCCESS) {
      const newKPI = getStructuredData(res.response.batteryData[0], level);
      dispatch(SetBatteryLiveMetric(newKPI));
      setLiveMetricDataForBattery(res?.response?.batteryData?.[0])
    }
    setIsRealTimeKPIFetching(false);
  };

  const fetchBatteryHierarchy = async () => {
    setIsHierarchyFetching(true);
    const res = await getBatteryHierarchy(batteryID);
    if (res?.responseStatus?.code === CODE.SUCCESS) {
      setBatteryHierarchy(res.response);
    } else {
      setBatteryHierarchy({
        responseStatus: {
          code: res?.responseStatus?.code,
          msg: res?.responseStatus?.msg,
        },
      });
    }
    setIsHierarchyFetching(false);
  };

  const getAnnotationData = async () => {
    const params = {
      status: ['active', 'open', 'resolved'],
      startDate: dateFilters?.startDate,
      endDate: dateFilters?.endDate,
    }
    const res = await getAlertsByGroup(params, batteryID);

    if (res?.responseStatus?.code === CODE.SUCCESS) {
      const alertsData = res?.response?.data?.flatMap(
        (item) => item.alertState
      );
      setAlertsForAnnotation(alertsData);
      return;
    }
    setAlertsForAnnotation([]);
  };

  const fetchRealTimeKPIForHierarchy = async () => {
    const res = await getColorMapping(batteryID);

    let dataObject = {}
    let sequence = {}
    if (res.responseStatus.code === CODE.SUCCESS) {
      const data = res.response.batteryData;
      sequence['pack'] = []
      sequence['module'] = []
      sequence['cell'] = []
      dataObject[batteryID] = {
        last_pinged_time: data.last_pinged_time,
        current: data.current,
        voltage: data.voltage,
        soc: data.soc,
        energy: data.energy,
        temperature: data.temperature,
        pingStatus: data.pingStatus
      }
      data?.childData?.forEach((pack) => {
        const packName = Object.keys(pack)[0];
        sequence['pack'].push(packName.toLowerCase())
        dataObject[packName.toLowerCase()] = pack[packName];
        pack?.[packName]?.childData?.forEach((module) => {
          const moduleName = Object.keys(module)[0];
          sequence['module'].push(moduleName.toLowerCase())
          dataObject[moduleName.toLowerCase()] = module[moduleName];

          module?.[moduleName]?.childData?.forEach((cell) => {
            const cellName = Object.keys(cell)[0];
            sequence['cell'].push(cellName.toLowerCase())
            dataObject[cellName.toLowerCase()] = cell[cellName];
          });
        });
      });
      setHierarchySequence(sequence)
      setHierarchyLiveMetricData(dataObject);
    }
  };

  useEffect(() => {
    Events("Digital Twin page opened for particular battery ID");
    fetchRealTimeKPI();
    fetchBatteryHierarchy();
    setFilters();
    setAlertCounts();
    getAlerts();
    fetchRealTimeKPIForHierarchy();
    getPageMeta();

    // commenting out for now. Might be need later
    // const interval = setInterval(() => {
    //   fetchRealTimeKPI();
    // }, 60 * 1000);
    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const temp = { ...decryptTheParams() };
    temp["startDateSB"] = dateFilters?.startDate;
    temp["endDateSB"] = dateFilters?.endDate;
    temp["oldTimeZone"] = cookies.get("timeZone");
    encryptTheParams(temp, navigate, true);
    setFilters();
    getAnnotationData();
  }, [dateFilters?.startDate, dateFilters?.endDate]);

  useEffect(() => {
    fetchRealTimeKPIForHierarchy();
    fetchRealTimeKPI();
    getAnnotationData();
  }, [timezoneChanged])

  useEffect(() => {
    if (
      isFetchingFilters ||
      isHierarchyAlertsCountFetching ||
      isHierarchyFetching ||
      isRealTimeKPIFetching
    ) {
      return;
    }
  }, [
    isFetchingFilters,
    isHierarchyAlertsCountFetching,
    isHierarchyFetching,
    isRealTimeKPIFetching,
  ]);

  async function getPageMeta() {
    const pageName = 'digitalTwin'
    const res = await getPagesMetaData(pageName)

    if (res && res?.responseStatus?.code === CODE.SUCCESS) {
      const pageData = res.response?.response?.metaInfo

      setPagesContent({
        ...pagesContent,
        metaInfo: pageData,
        mounted: true
      })
    }

  }

  const setAlertCounts = async () => {
    setIsHierarchyAlertsCountFetching(true);
    const res = await getBatteryHierarchicalAlertsCount(batteryID);

    setAlertsCountHierarchical(res?.response);
    setIsHierarchyAlertsCountFetching(false);
  };

  const getAlerts = async (value, type) => {
    setIsAlertFetching(true);
    let status, severity, sortBy, order, startDate, endDate;

    status =
      value?.status === ""
        ? ""
        : value?.status || queryParams?.alertFilterStatusValue || "";
    sortBy = value?.order || queryParams?.alertSortBy || "";
    order = value?.sortBy || queryParams?.alertSortOrder || "";
    severity =
      value?.severity === ""
        ? ""
        : value?.severity || queryParams?.alertFilterSeverityValue || "";

    const fields = {
      status: ['active', 'open'],
      severity,
      sortBy,
      order,
    };

    const res = await getAlertsByGroup(fields, batteryID);
    if (res?.responseStatus?.code === CODE.SUCCESS) {
      const alertTabData = res?.response?.data?.filter(alertMeta => {
        return alertMeta.alertState.filter(alertState => alertState.alertStatus !== 'Resolved').length > 0
      }).map(alertMeta => {
        return {
          alertMeta: alertMeta.alertMeta,
          alertState: alertMeta.alertState.filter(alertState => alertState.alertStatus !== 'Resolved')
        }
      })

      setAlertsList(alertTabData);
    } else {
      setAlertsList([]);
    }
    setIsAlertFetching(false);
  };

  const setFilters = async () => {
    setIsFetchingFilters(true);
    const filtersArray = ["severityName"];
    const res = await getDashboardFilters(filtersArray);
    if (res?.responseStatus?.code === CODE.SUCCESS) {
      const alertFiltersData = [
        ...res?.response?.data?.severityName?.filter(
          (filter) => filter.key !== "Expired"
        ),
        {
          key: "Active Alerts",
          value: "active",
        },
      ];
      setAlertsFilters(alertFiltersData);
    }
    setAlertsSorts([
      {
        key: "Latest Pinged",
        value: "latest_pinged",
      },
      {
        key: "Severity: High to Low",
        value: "high_to_low",
      },
      {
        key: "Severity: Low to High",
        value: "low_to_high",
      },
    ]);
    setIsFetchingFilters(false);
  };

  return (
    <div>
      {pagesContent.mounted || <Box
          sx={{
            height: "100vh",
            bgcolor: ThemeProperties.backgroundPurpleDarker
          }}
        >
          <Loader />
        </Box>
      }
      <DigitalTwinMain
        style={{
          opacity: pagesContent.mounted ? 1 : 0,
          display: pagesContent.mounted ? "block" : "none"
        }}
        pagesContent={pagesContent}
        batteryID={batteryID}
        deviceID={deviceID}
        hierarchy={batteryHierarchy}
        alertsCountHierarchical={alertsCountHierarchical}
        hierarchySequence={hierarchySequence}
        alertList={alertList}
        getAlerts={getAlerts}
        alertsFilters={alertsFilters}
        alertsSorts={alertsSorts}
        isAlertFetching={isAlertFetching}
        dateFilters={dateFilters}
        setDateFilters={(dates) => {
          setDateFilters(dates)
        }}
        alertsForAnnotation={alertsForAnnotation}
        liveMetricDataForBattery={liveMetricDataForBattery}
        hierarchyLiveMetricData={hierarchyLiveMetricData}
      />
    </div>
  );
}

export default DigitalTwin;
