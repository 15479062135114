import React, { useState, useEffect } from "react";
import Confirm from "../../Helper/Loaders/Confirm";
import Loader from "../../Helper/Loaders/Loader";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Cookies from "universal-cookie";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { OutlinedTextField } from "../../Components/CustomTextField";
import { OutlinedButton } from "../../Components/CustomButton";
import { sendRequestforProfilePassReset } from "../../Api/Api";
import { useDispatch } from "react-redux";

import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  IconButton,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { SetSessionExpired } from "../../Actions";
import { ThemeProperties } from "../../Theme/ThemeProperties";

function PasswordReset(props) {
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [showOldPassword, setShowOldPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleClickOldShowPassword = () => setShowOldPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [error, setError] = useState();
  const [oldError, setOldError] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      confirmation && props.handleClosePasswordReset();
    }, 5000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation]);

  const validatePassword = (password) => {
    return String(password).match(
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    );
  };

  const handleChangePassword = () => {
    if (!oldPassword) {
      setOldError("Password must not be empty");
      return;
    }
    if (!password || !confirmPassword) {
      setError("Password must not be empty");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords doesn't match");

      return;
    } else {
      if (!validatePassword(password)) {
        setError("Entered password isn't valid");
      } else {
        setError("");
        setOldError("");

        setLoader(true);

        sendRequestforProfilePassReset(oldPassword, password).then((res) => {
          if (res.responseStatus.code === 200) {
            setConfirmation(true);
            setLoader(false);
          } else if (
            res.responseStatus.code === 1998 ||
            res.responseStatus.code === 404
          ) {
            setLoader(false);
            setOldError("Please enter correct old password");
          } else if (res.responseStatus.code === 1996) {
            setLoader(false);
            setOldError(true);
            setError("Old password and new password can't be same");
          } else {
            if (res.responseStatus.code === -2)
              dispatch(SetSessionExpired(true));

            setError("Something went wrong! Please try again later");
            setLoader(false);
          }
        });
      }
    }
  };

  return (
    <div>
      <Dialog
        onClose={props.handleClosePasswordReset}
        aria-labelledby="customized-dialog-title"
        open={props.openPasswordReset}
        anchorEl={props.anchorElPasswordReset}
        container={props.anchorElPasswordReset.parentNode}
      >
        <div style={{ padding: "16px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 0px",
            }}
          >
            <Typography variant="paperHeader">
              {" "}
              {!confirmation && <>Reset Your Password</>}
            </Typography>
            <IconButton
              onClick={props.handleClosePasswordReset}
              fontSize="small"
            >
              <CloseIcon style={{ fontSize: "18px" }} />
            </IconButton>
          </div>
          <br></br>

          {!loader && !confirmation ? (
            <Box
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "400px",
                border: "1px solid #ececec",
                borderRadius: "4px",
                //backgroundColor: "#FBFBFF",
                padding: "56px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <>
                  <Typography variant="textSubtitle" align="center">
                    Change password for
                  </Typography>
                  <Typography variant="textPasswordReset" align="center">
                    <b>{cookies.get("name")}</b>
                  </Typography>

                  <FormControl
                    className="formBox"
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                  >
                    <OutlinedTextField
                      className="input"
                      id="new-password"
                      type={showOldPassword ? "text" : "password"}
                      value={oldPassword}
                      error={oldError}
                      onChange={(e) => {
                        setOldPassword(e.target.value.trim());
                        setOldError("");
                      }}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickOldShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showOldPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="Old Password"
                    />
                  </FormControl>
                  <div className="forgot">
                    <Typography variant="textSubtitleRed">
                      {oldError}
                    </Typography>
                  </div>

                  <FormControl
                    className="formBox"
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                  >
                    <OutlinedTextField
                      className="input"
                      id="new-password"
                      name="new-password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      error={error}
                      onChange={(e) => {
                        setPassword(e.target.value.trim());
                        setError("");
                      }}
                      inputProps={{
                        autoComplete: "new-password",
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label=" New Password"
                    />
                  </FormControl>

                  <FormControl
                    className="formBox"
                    sx={{ m: 1, width: "100%" }}
                    variant="outlined"
                  >
                    <OutlinedTextField
                      className="input"
                      error={error}
                      id="outlined-adornment-password"
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value.trim());
                        setError("");
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickConfirmShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label=" Confirm Password"
                    />
                  </FormControl>
                  <div className="forgot">
                    <Typography variant="textSubtitleRed">{error}</Typography>
                  </div>

                  <div className={`messageBox`}>
                    <InfoIcon
                      sx={{ fontSize: "20px", color: ThemeProperties.purple }}
                    />
                    <br></br>
                    <Typography variant="textPasswordReset" align="center">
                      Make sure your new password has atleast 8 characters which
                      includes an upper case letter, a lower case letter, a
                      number and a special character
                    </Typography>
                  </div>

                  <Box>
                    {loader ? (
                      <OutlinedButton
                        variant="contained"
                        fullWidth
                        sx={{ mt: 3 }}
                        disabled
                      >
                        <CircularProgress />
                      </OutlinedButton>
                    ) : (
                      <OutlinedButton
                        variant="contained"
                        fullWidth
                        sx={{ mt: 3 }}
                        onClick={() => handleChangePassword()}
                      >
                        <Typography variant="textSubtitleWhite">
                          Change Password
                        </Typography>
                      </OutlinedButton>
                    )}
                  </Box>
                </>
              </Box>
            </Box>
          ) : (
            <div style={{ height: "227px", width: "500px" }}>
              {loader && <Loader />}
              {confirmation && (
                <Confirm text={"Password changed successfully"} />
              )}
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default PasswordReset;
