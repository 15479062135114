import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Checkbox, ConfigProvider } from "antd";

import ListItemIcon from "@mui/material/ListItemIcon";
import { AnalyticsBasicSelect } from "./CustomSelect";
import { Button, Typography } from "@mui/material";
import { Box, IconButton } from "@mui/material";
import { ThemeProperties } from "../Theme/ThemeProperties";
import { MoonLoader } from "react-spinners";
import { CustomText, FilterArrowBox, FilterLoaderBox, FlexStartBox } from "./CustomBox";
import CustomIcon from "./CustomIcon";
import { getTruncatedName } from "../Helper/Formatters/TextFormatter";

function getArrayOfValues(arr) {
  let temp = [];
  arr.map((item) => {
    temp.push(item.id);
  });
  return temp;
}

export default function CustomMultiSelectDropdown(props) {
  const options = props.options;
  const [selected, setSelected] = useState(props.optionValue);
  const isAllSelected =
    options?.length > 0 && selected?.length === options?.length;
  const [open, setOpen] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "All") {
      let newValue =
        selected?.length === options?.length ? [] : getArrayOfValues(options);
      setSelected(newValue);
      return;
    }
    setSelected(value);
  };

  const getRenderValue = (selected) => {
    if (selected?.length === options?.length) return "All";

    const selectedOption = options?.find((option) => option.id === selected[0]);

    if (selected?.length === 1) {
      return selectedOption ? selectedOption.value : "";
    }

    if (selected?.length > 1) {
      return selectedOption
        ? `${getTruncatedName(selectedOption.value, 5, 5, 0)} +${selected?.length - 1}`
        : "";
    }
  };

  useEffect(() => {
    setSelected(props.optionValue);
  }, [props.optionValue]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: ThemeProperties.dropDownPurple,
        },
      }}
    >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #E4E3E3",
        borderRight: 0,
        borderRadius: props.isFirstFilter ? "10px 0 0 10px" : "0px 0px 0px 0px",
        width: "129px",
        p: 0,
        mt: 1,
        mb: 1,
        height: "32px",
        bgcolor: "#FAFAFA"
      }}
    >
      <FormControl sx={{ m: 0, minWidth: 125, maxWidth: 125 }} size="small">
        {options?.length > 0 || options !== undefined 
          ? <></>
          : <FilterLoaderBox><MoonLoader size={20}/></FilterLoaderBox>
        }
        {(options?.length > 0 || options !== undefined) && <AnalyticsBasicSelect
          className="analytics-select"
          multiple
          value={selected}
          onChange={handleChange}
          renderValue={(selected) => {
            return (
              <FlexStartBox>
                <Box sx={{ mt: "1px" }}>
                  <CustomIcon stroke={isHover || open ? "#6D72F6" : "#444B51"} name={props.iconName}/>
                </Box>
                <CustomText
                  sx={{ 
                    fontWeight: 400,
                    fontSize: "14px",
                    marginLeft: "8px",
                    color: open || isHover ? "#6D72F6" : "#444B51"
                  }}
                >                  
                  {getRenderValue(selected)}
                </CustomText>
              </FlexStartBox>
              
            );
          }}
          open={open}
          onClose={() => {
            handleClose();
            setIsHover(false)
            setSelected(props.optionValue);
          }}
          onOpen={handleOpen}
          onMouseEnter={() => {setIsHover(true)}}
          onMouseLeave={() => {setIsHover(false)}}
          IconComponent={() => <FilterArrowBox onClick={() => setOpen(true)}>
            <CustomIcon 
              stroke={open || isHover ? "#6D72F6" : "#444B51"}
              viewBox={"0 -3 20 12"} 
              name={open ? "upArrow" : "downArrow"}
            />
          </FilterArrowBox>}
          MenuProps={{
            style: { zIndex: 10001, marginTop: "5px", marginLeft: "9px" },
          }}
          myColor={"#FAFAFA"}
          sx={{
            "&:hover": {
              "&& fieldset": {
                border: "none",
              },
            },
          }}
        >
          <MenuItem value="All" sx={{ pl: "24px", pr: "24px" }}>
            <ListItemIcon>
              <Checkbox
                className="analytics-select-checkbox"
                checked={isAllSelected}
                indeterminate={
                  selected?.length > 0 && selected?.length < options?.length
                }
              />
            </ListItemIcon>
            <Typography
              sx={{ fontSize: 14, fontWeight: 500, fontFamily: "Inter" }}
            >
              Select All
            </Typography>
          </MenuItem>

          {options?.map(({ id, value }) => (
            <MenuItem key={id} value={id} sx={{ pl: "24px", pr: "24px" }}>
              <ListItemIcon>
                <Checkbox
                  className="analytics-select-checkbox"
                  color={ThemeProperties.dropDownPurple}
                  checked={
                    selected?.some((option) => option === id)
                  }
                />
              </ListItemIcon>
              <Typography
                sx={{ fontSize: 14, fontWeight: 500, fontFamily: "Inter" }}
              >
                {value}
              </Typography>
            </MenuItem>
          ))}

          <Box sx={{ margin: "8px 20px 0px 20px" }}>
            <Button
              variant="contained"
              onClick={() => {
                props.handleSetFilter(props.label, selected);
                handleClose();
              }}
              fullWidth
              style={{ textTransform: "none", backgroundColor: selected?.length ? ThemeProperties.dropDownPurple : "rgba(0, 0, 0, 0.12)" }}
              disabled={selected?.length === 0}
            >
              <Typography sx={{ fontSize: "12px", color: "white" }}>
                Apply
              </Typography>
            </Button>
          </Box>

          {/* </Box>     */}
        </AnalyticsBasicSelect>}
      </FormControl>
    </Box>
    </ConfigProvider>
  );
}
