import React, { useEffect, useState } from "react";
import { Table, theme, Pagination } from "antd";
import ReactDragListView from "react-drag-listview";
import { ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  SetDigitalTwinsColumnSort,
  SetDigitalTwinsPageNumber,
  SetDigitalTwinsRowsPerPage,
  SetDigitalTwinsColumns,
} from "../../../Actions/DigitalTwinsList";
import { SetTableOrder } from "../../../Actions";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import NoData from "../../../Helper/Loaders/NoData";
import CODE from "../../../Static/Constants/StatusCodes";
import { handleScroll } from "./TableFunctions";
import {
  decryptTheParams,
  encryptTheParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
import Loader from "../../../Helper/Loaders/Loader";
import Cookies from "universal-cookie";

export default function BasicTableDigitalTwinsList(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();

  const [columns, setColumns] = useState([]);
  const columnsSelect = useSelector(
    (state) => state.DigitalTwinsColumnsSelect?.value
  );
  const [digitalTwinsColumns, setDigitalTwinsColumns] = useState(
    props.columns[props.tabvalue]["columns"]
  );

  const batteriesList = useSelector(
    (state) => state.DigitalTwinsListOfBatteries.value
  );

  const tabvalue = useSelector((state) => state.DigitalTwinsTabValue.value);

  const queryParams = decryptTheParams();

  const [list, setList] = useState({
    batteries: {},
    pages: {
      start: 1,
      end: 0,
      total: 0,
    },
    responseStatus: { code: CODE.LOADING, msg: "" },
  });

  useEffect(() => {
    setList(props.data);
  }, [props.data]);

  const pageNumber = useSelector((state) => state.DigitalTwinsPageNumber.value);
  const rowsPerPage = useSelector(
    (state) => state.DigitalTwinsRowsPerPage.value
  );
  const total = useSelector((state) => state.DigitalTwinsTotal.value);
  const sortChangedTo = useSelector(
    (state) => state.DigitalTwinsColumnSort.arr
  );

  useEffect(() => {
    if (tabvalue === 0) {
      setColumns(hideColumns(digitalTwinsColumns));
    } else {
      setColumns(digitalTwinsColumns);
    }
  }, [columnsSelect, digitalTwinsColumns]);

  useEffect(() => {
    setDigitalTwinsColumns(props.columns[tabvalue]["columns"]);
  }, [tabvalue]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let dragIndex = props.rowSelection
        ? fromIndex > 1 && toIndex > 1
        : fromIndex > 0 && toIndex > 0;
      if (dragIndex) {
        const newColumns = [...columns];
        const item = props.rowSelection
          ? newColumns.splice(fromIndex - 1, 1)[0]
          : newColumns.splice(fromIndex, 1)[0];
        props.rowSelection
          ? newColumns.splice(toIndex - 1, 0, item)
          : newColumns.splice(toIndex, 0, item);

        setColumns(newColumns);

        const finalColumns = [...props.columns];
        finalColumns[props.tabvalue]["columns"] = newColumns;

        dispatch(SetDigitalTwinsColumns(finalColumns));
      }
    },
    nodeSelector: "th",
    enableScroll: true,
    scrollSpeed: 20,
  };

  function hideColumns(columns) {
    return columns.filter((item) => item.show?.includes(columnsSelect));
  }

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.column) {
      let order = sorter.order === "ascend" ? "asc" : "desc";
      const tempSort = [...sortChangedTo];
      tempSort[props.tabvalue]["sortBy"] = sorter.columnKey;
      tempSort[props.tabvalue]["orderBy"] = order;

      dispatch(SetDigitalTwinsColumnSort(tempSort));
      dispatch(SetTableOrder(order));
    }
  };

  const handlePageChange = (page, pageSize) => {
    dispatch(SetDigitalTwinsPageNumber(page));
  };

  const onShowSizeChange = (current, pageSize) => {
    dispatch(SetDigitalTwinsPageNumber(1));
    dispatch(SetDigitalTwinsRowsPerPage(pageSize));
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: ThemeProperties.purple,
        },
      }}
    >
      <ReactDragListView.DragColumn {...dragProps}>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {}, // click row
            };
          }}
          locale={{
            emptyText: (
              <div style={{ minHeight: "500px", height: "500px" }}>
                {list.responseStatus.code === 1999 ? (
                  <div style={{ height: "100%" }}>
                    <NoData text={"No records found"} />
                  </div>
                ) : (
                  <p style={{ height: "100%", color: "white" }}></p>
                )}
              </div>
            ),
          }}
          columns={columns}
          rowKey={(record) => record.itemID}
          rowClassName="rowClass"
          dataSource={
            list.responseStatus.code === CODE.LOADING
              ? []
              : list.responseStatus.code === 404
                ? []
                : list.batteries
          }
          pagination={false}
          loading={{
            spinning:
              list.responseStatus.code === CODE.LOADING
                ? true
                : list.responseStatus.code === CODE.NODATA
                  ? false
                  : false,
            indicator: (
              <>
                <div style={{ height: "130px" }}></div>
                <Loader />
              </>
            ),
          }}
          onChange={handleTableChange}
          style={{ height: "575px" }}
          scroll={handleScroll([...columns])}
          bordered
          size="middle"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Pagination
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            pageSize={rowsPerPage}
            pageSizeOptions={[10, 20, 30]}
            current={pageNumber}
            onChange={handlePageChange}
            total={total}
            showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
            // showQuickJumper
          />
        </div>
      </ReactDragListView.DragColumn>
    </ConfigProvider>
  );
}
