import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux";
import PulseLoader from "react-spinners/MoonLoader";
import CODE from "../../Static/Constants/StatusCodes";
import moment from "moment";
import { KPICardContainer, KPIDate, KPILabel, KPILoaderBox, KPIValue } from "../../PagesContent/ChargingAnalytics/Components/StyledComponent";

function AnalyticsKPICard({ metricName, startDate, endDate, decimalPlaces, getMetricInfo, containerStyle, labelStyle, valueStyle }) {
  const [metricData, setMetricData] = useState({
    data: {},
    responseStatus: { code: CODE.LOADING, msg: "" },
  })
  const Loader = (props) => {
    return <KPILoaderBox>
      <PulseLoader
        color={"#B2AFC3"}
        size={30}
        speedMultiplier={1}
      />
    </KPILoaderBox>
  }

  const fetchMetricData = async () => {
    setMetricData({
      data: {},
      responseStatus: {
        code: CODE.LOADING,
        msg: "",
      },
    });

    try {
      const res = await getMetricInfo(
        metricName,
        startDate,
        endDate,
        {},
        {}
      );

      if (!res || typeof res !== "object") {
        throw new Error("Invalid response from getMetricInfo");
      }

      const { responseStatus, response } = res;

      if (responseStatus?.code !== CODE.SUCCESS) {
        setMetricData({
          data: {},
          responseStatus: {
            code: responseStatus?.code || CODE.ERROR,
            msg: responseStatus?.msg || "Failed to fetch metric data",
          },
        });
        return;
      }

      setMetricData({
        data: response?.data || {},
        responseStatus: {
          code: responseStatus?.code,
          msg: responseStatus?.msg,
        },
      });
    } catch (error) {
      console.error("Error fetching metric data:", error);

      setMetricData({
        data: {},
        responseStatus: {
          code: CODE.ERROR,
          msg: error.message || "An unexpected error occurred",
        },
      });
    }
  };


  useEffect(() => {
    fetchMetricData(metricName, startDate, endDate)
  }, [])

  return (
    <KPICardContainer sx={{ ...containerStyle }}>
      {metricData.responseStatus.code === CODE.LOADING 
        ? <Loader/>
        : <>
            <KPILabel sx={{ ...labelStyle }}>
                {metricData.data?.label}
            </KPILabel>
            <KPIValue sx={{ ...valueStyle }}>
                {Number.isFinite(metricData.data?.value) ? metricData.data?.value?.toFixed(decimalPlaces) : 0} {metricData.data.unit}
            </KPIValue>
            <KPIDate>
              {moment(startDate).format('MMMM YYYY')}
            </KPIDate>
          </>
      }
    </KPICardContainer>
  )
}

AnalyticsKPICard.defaultProps = {
  decimalPlaces: 0,
  containerStyle: {},
  labelStyle: {},
  valueStyle: {},
}

export default AnalyticsKPICard
