import { SvgIcon } from "@mui/material"
import React from "react"

const BarGraphSvg = (props) => {
  const { height, width, viewBox, fill, stroke, ...other } = props
  return (
    <SvgIcon style={{ ...props.style }}>
      <svg width={width} height={height} viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M1.31959 1.78125H3.65648C3.83289 1.78125 3.97607 1.91736 3.97607 2.08507V15.6962C3.97607 15.8639 3.83289 16 3.65648 16H1.31959C1.14317 16 0.999988 15.8639 0.999988 15.6962V2.08507C0.999988 1.91736 1.14317 1.78125 1.31959 1.78125ZM1.63918 15.3924H3.33691V2.38885H1.63918V15.3924Z" fill={stroke} stroke={stroke} stroke-width="0.25"/>
        <path d="M6.29566 6.09998H8.63255C8.80897 6.09998 8.95215 6.21273 8.95215 6.35165V15.7483C8.95215 15.8872 8.80897 16 8.63255 16H6.29566C6.11924 16 5.97606 15.8872 5.97606 15.7483V6.35165C5.97606 6.21273 6.11924 6.09998 6.29566 6.09998ZM6.61526 15.4966H8.31298V6.6033H6.61526V15.4966Z" fill={stroke} stroke={stroke} stroke-width="0.25"/>
        <path d="M11.2718 11.58H13.6087C13.7851 11.58 13.9283 11.6911 13.9283 11.828V15.752C13.9283 15.8889 13.7851 16 13.6087 16H11.2718C11.0954 16 10.9522 15.8889 10.9522 15.752V11.828C10.9522 11.6911 11.0954 11.58 11.2718 11.58ZM11.5914 15.5041H13.2891V12.0759H11.5914V15.5041Z" fill={stroke} stroke={stroke} stroke-width="0.25"/>
      </svg>

    </SvgIcon>
  )
}
BarGraphSvg.defaultProps = {
  width: "15",
  height: "21",
  viewBox: "0 0 15 21",
  fill: "none",
  stroke: "#6D72F6"
}

export default BarGraphSvg
