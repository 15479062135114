import React, { useState, useEffect } from "react";
import {
  ChargerVitals,
  GetTheChargerGranularityTrend,
} from "../_DownloadCSV/CSVDownload";
import { PaperHighlight } from "../../../../Components/CustomPaper";

import Header from "../../../../Features/Header/Header";
import CODE from "../../../../Static/Constants/StatusCodes";
import Events from "../../../../Analytics/Events";

import Apex3YAxis from "../../../../Charts/Line/Apex3YAxis";

function KeyMetrics(props) {
  const hierarchy = props.hierarchy;

  const [chargerCSV, setChargerCSV] = useState({
    data: [],
    responseStatus: { code: 0 },
  });

  const [statusClicked, setStatusClicked] = useState({
    Voltage: true,
    Current: true,
    Temperature: true,
  });

  function GetChargerCSV() {
    setChargerCSV({
      data: [],
      responseStatus: {
        code: -1,
      },
    });
    let columnsArr = [];
    if (statusClicked.Current) columnsArr.push("solarInputCurrent");
    if (statusClicked.Voltage) columnsArr.push("solarInputVoltage");
    if (statusClicked.Temperature) columnsArr.push("solarInputPower");
    let obj =
      props.granular === "Raw Data" &&
      props.startDate.toLowerCase().indexOf(":") === -1
        ? ChargerVitals(props.batteryID, columnsArr, [
            props.dateString[0],
            props.dateString[1],
          ])
        : GetTheChargerGranularityTrend(
            props.batteryID,
            columnsArr,
            [props.dateString[0], props.dateString[1]],
            props.granular,
            props.operationType
          );
    obj.then((res) => {
      if (res.responseStatus.code === CODE.SUCCESS)
        setChargerCSV({
          data: res.data,
          responseStatus: {
            code: res.responseStatus.code,
          },
        });
      else
        setChargerCSV({
          data: [],
          responseStatus: {
            code: res.responseStatus.code,
          },
        });
    });
  }

  useEffect(() => {}, [
    props.startDate,

    hierarchy.selectedPack.value,
    hierarchy.selectedModule.value,
    hierarchy.selectedCell.value,
    hierarchy.level,
  ]);

  return (
    <>
      <PaperHighlight
        elevation={0}
        onMouseEnter={() => Events("SB Hovered Key Metrics Chart")}
      >
        <Header
          heading={"Key Metrics"}
          description="Showing how battery signals (Voltage, Current, Temperature, SOC) are fluctuating over time within a day"
          showIButton={true}
          showThreeDots={false}
          showCSVDownload={true}
          csvData={chargerCSV}
          SetCSVData={GetChargerCSV}
          setStatusBack={setChargerCSV}
          id="Charger_Data"
          page="specificBattery"
        />
        <div className="section" id="Charger_Data">
          <Apex3YAxis
            startDate={props.startDate}
            hierarchy={hierarchy}
            batteryID={props.batteryID}
            alertFirstSeenOn={props.queryParams["alertFirstSeenOn"]}
            granular={props.granular}
            operationType={props.operationType}
            statusClicked={statusClicked}
            setStatusClicked={setStatusClicked}
          />
        </div>
      </PaperHighlight>
    </>
  );
}

export default KeyMetrics;
