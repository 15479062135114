import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { Typography } from "@mui/material";
import {
  StyledTabContainer,
  StyledIndividualTab,
} from "../../Components/CustomTab";

export default function TabWithColor(props) {
  const { changeTheTab, selectedTabValue, ...other } = props
  
  const [value, setValue] = useState(selectedTabValue);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    changeTheTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
        elevation={1}
        myColor={ThemeProperties.purple_tabs}
        TabIndicatorProps={{
          style: { display: 'none' }
        }}
        sx={{ minHeight: '36px', height: '36px' }}
        >
        {props.tabs.map((item, index) => (
          <Tab
            key={index}
            style={{ 
              minHeight: '36px', 
              height: '36px',
              backgroundColor: index == value ? ThemeProperties.purple_tabs : null,
              padding: 0,
              borderRadius: 2,
              borderBottom: index == value ? "solid 1px rgba(88, 108, 233, 1)" : null,
              textTransform: 'none',
            }}
            disabled={item.disabled}
            label={
              <div 
                style={{ 
                  display: "flex", 
                  alignItems: "center", 
                  justifyContent: "center",
                  borderRight: index == props.tabs.length - 1 ? null : "1px solid rgba(217, 217, 217, 0.87)",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    lineHeight: "24px",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                    color: index == value ? "#586CE9" : item.disabled ? "#A1A1A0" : "#1C1C1C"
                  }}
                >
                  {item.name} 
                </Typography>
              </div>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
}
