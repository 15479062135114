export const SetSearchType = (data = null) => {
  return {
    type: 'selectedSearchType',
    value: data,
  };
};

export const SetSearchID = (data = null) => {
  return {
    type: 'selectedSearchID',
    value: data,
  };
};

export const SetSearchConfig = (data = {}) => {
  return {
    type: 'selectedSearchConfig',
    value: data,
  };
};