const initialUserState = {
    battery: "",
  };
  
  const GlobalBatterySearch = (state = initialUserState, action) => {
      
    switch (action.type) {
      case "GLobalBatteryID":
        return {
            ...state,
          battery: action.battery,
      };
      default:
        return state;
    }
  };
  
  
  export default GlobalBatterySearch;
  