import { styled } from "@mui/material/styles"
import {
  Box,
} from "@mui/material"

const DigitalTwinListPageButtonBox = styled(Box)({
  position: "absolute", 
  right: 0, 
  paddingTop: "8px", 
  zIndex: 1
})

export {
  DigitalTwinListPageButtonBox
}