import * as React from "react";
const BatteryDischargingStateIcon = (props) => {
  const { height, width, viewBox, fill, ...other } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <circle cx={29} cy={29} r={3} fill={fill} />
      <path
        d="M10.3 28H21.7C22.1418 28 22.5 27.6418 22.5 27.2V8.3C22.5 7.85817 22.1418 7.5 21.7 7.5H18.75V5.3C18.75 4.85817 18.3918 4.5 17.95 4.5H14.05C13.6082 4.5 13.25 4.85817 13.25 5.3V7.5H10.3C9.85817 7.5 9.5 7.85817 9.5 8.3V27.2C9.5 27.6418 9.85817 28 10.3 28Z"
        stroke="#586CE9"
      />
      <rect x={11.2002} y={14.6001} width={9.6} height={12} fill="#586CE9" />
      <path
        d="M15 18.6001V14.6001H17V18.6001H19L16 21.6001L13 18.6001H15Z"
        fill="white"
      />
    </svg>
  );
}
BatteryDischargingStateIcon.defaultProps = {
  width: "34",
  height: "34",
  viewBox: "0 0 34 34",
  fill: "#3CA967"
};
export default BatteryDischargingStateIcon;
