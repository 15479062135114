import { SvgIcon } from "@mui/material"
import React from "react"

const NavbarBatteryHealthIcon = (props) => {
  const { height, width, viewBox, fill, style, stroke, ...other } = props
  return (
    <SvgIcon
      style={style}
    >
      <svg 
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.50004 3.99545C7.18944 1.02575 4.13641 0.790903 2.71216 2.05605C1.55307 3.06363 1.00004 5.58636 2.05307 7.88181C3.87883 11.8288 8.50004 13.9424 8.50004 13.9424C8.50004 13.9424 13.1213 11.8667 14.947 7.91969C16 5.64696 15.447 3.13181 14.2879 2.09393C12.8637 0.790903 9.81065 1.02575 8.50004 3.99545Z" stroke={fill} stroke-width="1.13636" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.93945 7.60906H5.46976L7.74248 9.88179L10.0152 5.33633L11.5304 7.60906H15.0607" stroke={fill} stroke-width="1.13636" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </SvgIcon>
  )
}

NavbarBatteryHealthIcon.defaultProps = {
  width: "17",
  height: "15",
  viewBox: "0 0 17 15",
  fill: "#8D9FB2",
  stroke: ""
}

export default NavbarBatteryHealthIcon
