import {
  Box,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeProperties } from "../../../Theme/ThemeProperties";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

function ConfigFormEnergyStorage({
  isEdit,
  form,
  handleChangeSelect,
  showApplicationOEMTextField,
  handleDiscardToDropdown,
  formValidated,
}) {
  const configFilters = useSelector(
    (state) => state.DigitalTwinsConfigFilters.value
  );
  const appFilters = configFilters.find(
    (x) => x.type === "applicationOEM"
  )?.value;

  return (
    <FormGroup>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        justifyContent="space-around"
        margin={3}
        rowGap={2}
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Manufacturer <span style={{ color: "red" }}>*</span>
          </FormLabel>
          {showApplicationOEMTextField ? (
            <>
              <TextField
                style={{ width: "300px" }}
                size="small"
                variant="outlined"
                value={form?.applicationOEM}
                name="applicationOEM"
                onChange={handleChangeSelect}
                disabled={!isEdit}
                inputProps={{
                  style: { fontSize: 14, padding: 7, marginLeft: 3 },
                }}
                autoFocus
              />
              <IconButton
                onClick={() => handleDiscardToDropdown("applicationOEM")}
              >
                <CloseIcon style={{ fontSize: 16 }} />
              </IconButton>
            </>
          ) : (
            <Select
              style={{ width: "300px", fontSize: "small" }}
              size="small"
              variant="outlined"
              name="applicationOEM"
              onChange={handleChangeSelect}
              disabled={!isEdit}
              value={form?.applicationOEM}
            >
              {appFilters?.map((item) => (
                <MenuItem sx={{ fontSize: "small" }} value={item}>
                  {item}
                </MenuItem>
              ))}
              <MenuItem
                sx={{ fontSize: "small", color: ThemeProperties.Purple1 }}
                value="%add%"
              >
                Add new
              </MenuItem>
            </Select>
          )}
          {!formValidated?.applicationOEM && (
            <Typography color="red">
              {showApplicationOEMTextField &&
              appFilters.includes(form?.applicationOEM)
                ? "Value already exists"
                : "This field is required"}
            </Typography>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Related Capacity (Ah) <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <TextField
            style={{ width: "300px" }}
            size="small"
            type="number"
            variant="outlined"
            value={form?.ratedCapacitySystem}
            name="ratedCapacitySystem"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            inputProps={{
              style: { fontSize: 14, padding: 7, marginLeft: 3 },
            }}
          />
          {!formValidated?.ratedCapacitySystem && (
            <Typography color="red">This field is required</Typography>
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <FormLabel
            sx={{
              fontSize: 14,
              paddingTop: 0.7,
              width: 200,
              color: ThemeProperties.black,
            }}
          >
            Related Voltage (V) <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <TextField
            style={{ width: "300px" }}
            size="small"
            type="number"
            variant="outlined"
            value={form?.ratedVoltageSystem}
            name="ratedVoltageSystem"
            onChange={handleChangeSelect}
            disabled={!isEdit}
            inputProps={{
              style: { fontSize: 14, padding: 7, marginLeft: 3 },
            }}
          />
          {!formValidated?.ratedVoltageSystem && (
            <Typography color="red">This field is required</Typography>
          )}
        </Box>
      </Box>
    </FormGroup>
  );
}

export default ConfigFormEnergyStorage;
