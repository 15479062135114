import React from "react";
import { Dialog} from "@mui/material";
import MapInfoWindow from "../../PagesContent/Map/MapInfoWindow";

function AllBatteriesSeverity(props) {

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={props.modal.open}
      onClose={props.handleModalClose}
      maxWidth="sm"
    >
      <MapInfoWindow
      searchType={"Device"}
      batteryID={props.modal.deviceID}
      textToShow={props.modal.batteryID}

      handleToggleClose={props.handleModalClose}
      lat={props.modal.lat}
      lng={props.modal.long}
      />
    </Dialog>
  );
}

export default AllBatteriesSeverity;
