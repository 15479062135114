import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { Typography, Box } from "@mui/material";

function CartLoader(props) {
  return (
    <div className="mytable">
      <div className="cell">
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <PulseLoader color={"#B2AFC3"} size={10} speedMultiplier={1} />
        </Box>
        <Typography style={{ color: "#B2AFC3", marginTop: "10px",fontSize:"14px"  }}>
          {props.text}
        </Typography>{" "}
      </div>
    </div>
  );
}

export default CartLoader;
