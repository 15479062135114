import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const PTypography = styled((props) => <Typography {...props}/>)(({ theme, mycolor }) => ({
    backgroundColor: mycolor,
    padding: "2px 11px",
    border: "2px solid transparent",
    borderRadius: "20px",
    lineHeight: "14px",
    transition: "background-color 300ms linear",
    width:"max-content",
    overflowX: "hidden",
}))

export {PTypography}