import { stackResponse } from "../Static/Data/Dashboard/DashboardResponses";
import { GetData } from "./Functions/SettingData";
async function postAddToCart(itemID) {
  var config = {
    method: "post",
    url: "/api/alert/battery_stack",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      ids: itemID,
      actionType: "push",
    },
  };
  let data = GetData(config);
  return data;
}
async function postPopFromCart(itemID) {
  var config = {
    method: "post",
    url: "/api/alert/battery_stack",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      ids: itemID,
      actionType: "pop",
    },
  };
  let data = GetData(config);
  return data;
}

//get number of Batteries present in cart
async function getCartBatteries(pageNumber, sort, order, rowsPerPage) {
  var config = {
    method: "get",
    url: `/api/alert/stack_battery_list/${pageNumber}`,
    headers: {
      "Content-Type": "application/json",
    },

    params: {
      pageCount: rowsPerPage,
      sortBy: sort,
      order: order,
    },
  };
  let data = GetData(config);
  // const data = stackResponse;
  return data;
}

async function sendPostTakeAction(to, cc, bcc, selectedBatteries) {
  var config = {
    method: "post",
    url: `/api/alert/action/mail`,
    data: {
      to: to,
      cc: cc,
      bcc: bcc,
      ids: selectedBatteries,
    },
  };
  let data = GetData(config);
  return data;
}

async function getSelectAllBatteries() {
  var config = {
    method: "get",
    url: `/api/alert/battery_stack`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let data = GetData(config);
  return data;
}

async function getCSVOfBatteries(tempIDs) {
  var rawJson = {
    ids: tempIDs,
  };

  var config = {
    method: "post",
    url: `/api/alert/action/download_csv`,
    headers: {
      "Content-Type": "application/json",
    },
    data: rawJson,
  };

  let data = GetData(config);
  return data;
}

async function getEmailIDList() {
  var config = {
    method: "get",
    url: `/api/org_mail_recipient_list`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  let data = GetData(config);
  return data;
}

export {
  postAddToCart,
  postPopFromCart,
  getCartBatteries,
  sendPostTakeAction,
  getSelectAllBatteries,
  getCSVOfBatteries,
  getEmailIDList,
};
