import React, { useEffect, useState } from "react";
import DashboardMain from "./DashboardMain";
import { Box } from "@mui/material";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import Loader from "../../Helper/Loaders/Loader";
import { useDispatch } from "react-redux";
import {
  SetDashboardFilters,
} from "../../Actions/Dashboard";
import { SetSessionExpired } from "../../Actions";
import { getDashboardFilters } from "../../Api/DashboardApi";
import CODE from "../../Static/Constants/StatusCodes";
import { usePageMeta } from "../../Hooks/usePageMeta";
import { SetDialogBoxTableColumns, SetTableColumns } from "../../Actions/Table";

function Dashboard(props) {
  const dispatch = useDispatch();
  const { pagesContent } = usePageMeta("alerts")
  const [alertTypes, setAlertTypes] = useState([])

  useEffect(() => {
    GetFilters();
  }, []);

  useEffect(() => {
    const alertTableColumns = pagesContent.columns?.find(table => table.name === "alertsMainTable")?.columns || []
    const cartTableColumns = pagesContent.columns?.find(table => table.name === "alertsCartTable")?.columns || []
    dispatch(SetTableColumns(alertTableColumns || []))
    dispatch(SetDialogBoxTableColumns(cartTableColumns || []))
    pagesContent["alertType"] = alertTypes
  }, [pagesContent, alertTypes])

  const GetFilters = async () => {
    await getDashboardFilters().then((res) => {
      if (res && res?.responseStatus?.code === CODE.SUCCESS) {
        const filterData = res.response?.data
        dispatch(SetDashboardFilters(filterData));
        setAlertTypes(filterData?.alertType)
        return filterData;
      } else if (res?.responseStatus?.code === CODE.SESSION_EXPIRED) {
        SetSessionExpired(true);
        return null;
      }
    });
  };

  return pagesContent?.mounted ? (
    <DashboardMain pagesContent={pagesContent} />
  ) : (
    <Box
      sx={{
        height: "100vh",
        bgcolor: ThemeProperties.backgroundPurpleDarker,
      }}
    >
      <Loader />
    </Box>
  )
}

export default Dashboard;
