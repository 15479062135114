import React from "react";
import { Typography, Box } from "@mui/material";
import NoDataSVG from "../../Assets/Images/Common/NotFound.svg";

function NoData(props) {
  return (
    <div className="mytable">
      <div className="cell">
        <div >
        <Typography style={{ color: "#909090", fontSize: "16px",marginLeft:"20px", fontWeight:600 }}>
          {props.text}
        </Typography>
        <Box sx={{mt:1}}>
          {/* <img src={NoDataSVG} alt="not found" height={150}></img> */}
        </Box>
        </div>
      </div>
    </div>
  );
}

export default NoData;
