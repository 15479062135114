import { ThemeProperties } from '../../Theme/ThemeProperties';

function FindTheColor(severity) {
    let tempColor =
      severity === 0
        ? ThemeProperties.purple
        : severity === 1
        ? ThemeProperties.map_low
        : severity === 2
        ? ThemeProperties.map_medium
        : severity === 3
        ? ThemeProperties.map_high
        : ThemeProperties.map_critical;
    return tempColor
}

export default FindTheColor;