function FindNextHighestMultipleOfNum(number,highestMultipleOf) {
  const quotient = Math.floor(number / highestMultipleOf);
  const nextMultiple = (quotient + 1) * highestMultipleOf;
  return nextMultiple;
}

const FindNearestMaxAnalytics = (max) => {

    let num = max;

    return num * 1.25;
    
  };

export {FindNearestMaxAnalytics,FindNextHighestMultipleOfNum}