import { SvgIcon } from "@mui/material";
import React from "react";

const ChartCarouselNextArrowIcon = (props) => {
  const { height, width, viewBox, fill, ...other } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_222_8)">
        <path d="M8 0.25H14C19.43 0.25 21.75 2.57 21.75 8V14C21.75 19.43 19.43 21.75 14 21.75H8C2.57 21.75 0.25 19.43 0.25 14V8C0.25 2.57 2.57 0.25 8 0.25ZM14 20.25C18.61 20.25 20.25 18.61 20.25 14V8C20.25 3.39 18.61 1.75 14 1.75H8C3.39 1.75 1.75 3.39 1.75 8V14C1.75 18.61 3.39 20.25 8 20.25H14Z" fill={fill} />
        <g clip-path="url(#clip1_222_8)">
          <path d="M9.74008 6.72033C9.93008 6.72033 10.1201 6.79033 10.2701 6.94033L13.8001 10.4703C14.0901 10.7603 14.0901 11.2403 13.8001 11.5303L10.2701 15.0603C9.98008 15.3503 9.50008 15.3503 9.21008 15.0603C8.92008 14.7703 8.92008 14.2903 9.21008 14.0003L12.2101 11.0003L9.21008 8.00033C8.92008 7.71033 8.92008 7.23033 9.21008 6.94033C9.35008 6.79033 9.54008 6.72033 9.74008 6.72033Z" fill={fill} />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_222_8">
          <rect width={width} height={height} fill="white" transform="matrix(-1 0 0 -1 22 22)" />
        </clipPath>
        <clipPath id="clip1_222_8">
          <rect width="6" height="10" fill="white" transform="matrix(-1 0 0 -1 15 16)" />
        </clipPath>
      </defs>
    </svg>
  );
};

ChartCarouselNextArrowIcon.defaultProps = {
  width: "22",
  height: "22",
  viewBox: "0 0 22 22",
  fill: "#828A92"
};

export default ChartCarouselNextArrowIcon;
