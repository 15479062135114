import {
  formatDateNumeric,
  returnLastSunday,
} from "../../Helper/DatePicker/DateFormatters";
const initialIOT = {
  value: "",
};
const initialPack = {
  value: "",
};

const initialGran = {
  value: "Weekly",
};

const initialStartDate = {
  value: formatDateNumeric(
    new Date(new Date().setDate(new Date().getDate() - 34))
  ),
};
const initialEndDate = {
  value: formatDateNumeric(new Date(returnLastSunday())),
};

const ReportsDQIOT = (state = initialIOT, action) => {
  switch (action.type) {
    case "ReportsDQIOT":
      return {
        value: action.iot,
      };
    default:
      return state;
  }
};
const ReportsDQPack = (state = initialPack, action) => {
  switch (action.type) {
    case "ReportsDQPack":
      return {
        value: action.pack,
      };
    default:
      return state;
  }
};

const ReportsDQStartDate = (state = initialStartDate, action) => {
  switch (action.type) {
    case "ReportsDQStartDate":
      return {
        value: action.start,
      };
    default:
      return state;
  }
};
const ReportsDQEndDate = (state = initialEndDate, action) => {
  switch (action.type) {
    case "ReportsDQEndDate":
      return {
        value: action.end,
      };
    default:
      return state;
  }
};

const ReportsDQGranularity = (state = initialGran, action) => {
  switch (action.type) {
    case "ReportsDQGranularity":
      return {
        value: action.granular,
      };
    default:
      return state;
  }
};

export {
  ReportsDQIOT,
  ReportsDQPack,
  ReportsDQStartDate,
  ReportsDQEndDate,
  ReportsDQGranularity,
};
