import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { Typography } from '@mui/material'
import Loader from '../../Helper/Loaders/Loader'
import { ThemeProperties } from '../../Theme/ThemeProperties'
import { formatDate } from '../../Helper/DatePicker/DateFormatters'

function closestMultiple(n, x) {
  if (x > n) return x
  let num = n
  while (num % x !== 0) {
    num += 1
  }
  return num
}

function previousMultiple(n, x) {
  // 92,5
  if (x > n) return x
  let num = n
  while (num % x !== 0) {
    num -= 1
  }
  return num
}

function ApexBasicMultiLine(props) {
  const [chartState, setChartState] = useState({
    text: '',
    series: [],
    options: {},
  })

  function mountTheChart(xmax, ymin, data, data2) {
    setChartState({
      text: '',
      series: [
        {
          name: 'SOH (%)',
          data,
        },
        {
          name: 'Forecasted SOH (%)',
          data: data2,
        },
      ],
      options: {
        chart: {
          type: 'line',
          zoom: {
            enabled: true,
            type: 'x',
            minZoom: 10,
          },
          resetIcon: {
            offsetX: -10,
            offsetY: 5,
            fillColor: '#fff',
            strokeColor: '#37474F',
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">', // eslint-disable-line
              customIcons: [],
            },
            export: {
              csv: {
                filename: undefined,
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                },
              },
              svg: {
                filename: undefined,
              },
              png: {
                filename: undefined,
              },
            },
            autoSelected: 'zoom',
            // autoSelected: false,
          },
          events: {
            beforeZoom(chart, { xaxis }) {
              return {
                xaxis: {
                  min: xaxis.min,
                  max: xaxis.max / 30,

                  labels: {
                    formatter(value) {
                      return value?.toFixed(1)
                    },
                  },
                },
              }
            },
            beforeResetZoom(chartContext, opts) {
              return {
                xaxis: {
                  min: 0,
                  max: xmax,
                  tickAmount: 5,
                },
              }
            },
          },
          animations: {
            enabled: false,
          },
        },
        stroke: {
          width: 3,
          curve: 'straight',
          dashArray: [0, 4],
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
            sizeOffset: 1,
          },
        },
        // labels: newLabels,
        title: {
          text: undefined,
        },
        colors: props.colors,
        fill: {
          opacity: 1,
        },
        xaxis: {
          type: 'numeric',
          min: 0,
          max: xmax,
          tickAmount: 5,
          // tickAmount: 30,
          // tickAmount: props.filter === "Last 30 Days" ? 30 : newLabels.length,

          labels: {
            rotate: 0,

            style: {
              colors: [ThemeProperties.c_axis_labels_color],
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: 'Roboto',
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            formatter(value) {
              if (value !== undefined) return value?.toFixed(0)
            },
            offsetX: 0,
          },
          title: {
            text: props.xTitle,
            offsetX: 3,
            offsetY: -5,
            style: {
              color: ThemeProperties.black,
              fontSize: ThemeProperties.c_axis_title,
              fontFamily: 'Roboto',
              fontWeight: ThemeProperties.c_axis_title_weight,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          min: ymin === 100 ? 95 : ymin,
          max: 120,
          tickAmount: 5,
          axisBorder: {
            show: true,
            color: ThemeProperties.c_axis_labels_color,
            offsetX: -3,
          },
          labels: {
            style: {
              colors: [ThemeProperties.c_axis_labels_color],
              fontSize: ThemeProperties.c_axis_labels,
              fontFamily: 'Roboto',
              fontWeight: ThemeProperties.c_axis_labels_weight,
            },
            formatter(value) {
              return Math.round(value)
            },
            offsetX: 0,
          },
          title: {
            text: props.yTitle,
            offsetX: -2,
            style: {
              color: props.yTitleColor,
              fontSize: ThemeProperties.c_axis_title,
              fontFamily: 'Roboto',
              fontWeight: ThemeProperties.c_axis_title_weight,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {
          shared: false,
          intersect: false,
          followCursor: true,
          // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          //   var data = w.globals;
          //   return newLabels[dataPointIndex];
          // },
          custom({ series, seriesIndex, dataPointIndex, w }) {
            const data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex]
            const ageInMonths = props.cycleAge ? data.secondaryX : data.x
            const eqCycles = props.cycleAge ? data.x : data.secondaryX
            const newDate = data.date
              ? formatDate(new Date(data.date))
              : 'Unknown'
            return (
              '<div class="tooltip_container">' +
              '<span class="flex_center">' +
              // `<span class="dot_purple"></span>` +
              `<span class="text_container">SOH (%) <b>: ${data.y?.toFixed(
                1
              )}</b></span>` +
              '</span>' +
              '<hr class="horizontal_rule"></hr>' +
              `<span class="time_container">Age (months) <b>: ${ageInMonths?.toFixed(
                1
              )}</b></span>` +
              '<br></br>' +
              `<span class="time_container">Eq. Cycle <b>: ${eqCycles?.toFixed(
                1
              )}</b></span>` +
              '<br></br>' +
              `<span class="time_container">Date <b>: ${newDate}</b></span>` +
              '</div>'
            )
          },
        },
        legend: {
          show: false,
          // width: "200px",
        },
      },
    })
  }

  useEffect(() => {
    if (props.data.data !== undefined && props.data.data.length > 0) {
      let xmax = 0
      let ymin = 100

      props.data.data.forEach((item) => {
        if (item.x > xmax) xmax = item.x
        if (item.y < ymin) ymin = item.y
      })

      const newXmax = xmax ? Math.ceil(xmax) : 0
      const newYmin = ymin ? Math.floor(ymin) : 100

      let xmax2 = newXmax
      let ymin2 = newYmin

      props.data.data2.forEach((item) => {
        if (item.x > xmax2) xmax2 = item.x
        if (item.y < ymin2) ymin2 = item.y
      })

      const newXmax2 = xmax2 ? Math.ceil(xmax2) : newXmax
      const newYmin2 = ymin2 ? Math.floor(ymin2) : newYmin

      const newData = [...props.data.data]
      const newd = []
      newData.forEach((item) => {
        newd.push({
          date: item.date ? item.date : 0,
          secondaryX: item.secondaryX ? item.secondaryX : 0,
          x: item.x ? item.x : 0,
          y: item.y ? item.y : 0,
        })
      })
      mountTheChart(
        closestMultiple(newXmax2, 5),
        previousMultiple(newYmin2, 5),
        newd,
        props.data.data2
      )
    }
  }, [props.data])

  useEffect(
    () => () => {
      props.setData({
        series: [],
        labels: [],
        responseStatus: { code: -1, msg: '' },
      })
    },

    []
  )

  return (
    <div style={{ height: '100%' }}>
      {props.data.responseStatus.code === 200 ? (
        <Chart
          options={chartState.options}
          series={chartState.series}
          type="line"
          height="100%"
          width="100%"
        />
      ) : (
        <div style={{ height: '100%' }}>
          {props.data.responseStatus.code === -1 ? (
            <Loader />
          ) : (
            <div style={{ position: 'relative', height: '400px' }}>
              <div
                style={{
                  position: 'absolute',
                  left: '1%',
                  top: '40%',
                  zIndex: 9,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#ececec',
                    padding: '10px',
                    borderRadius: '20px',
                  }}
                >
                  <Typography variant="textSubtitle">
                    SOHc for this battery is not computed
                  </Typography>
                </div>
              </div>
              <Chart
                options={{
                  chart: {
                    type: 'line',
                    zoom: {
                      enabled: false,
                    },
                    toolbar: {
                      show: false,
                    },
                    animations: {
                      enabled: false,
                    },
                  },
                  stroke: {
                    width: 3,
                    curve: 'straight',
                  },
                  markers: {
                    size: 4,
                    hover: {
                      size: 5,
                      sizeOffset: 1,
                    },
                  },
                  labels: [1, 2, 3, 4, 5],
                  title: {
                    text: undefined,
                  },
                  colors: props.colors,
                  fill: {
                    opacity: 1,
                  },
                  xaxis: {
                    type: 'numeric',

                    // tickAmount: props.filter === "Last 30 Days" ? 30 : newLabels.length,

                    labels: {
                      rotate: 0,

                      style: {
                        colors: [ThemeProperties.c_axis_labels_color],
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: 'Roboto',
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },
                      // formatter: function (value) {
                      //   if(props.type === "VS"||props.type==="NotVS")
                      //   {
                      //   if(props.filter === "Last 6 Months")
                      //   {
                      //   if (value !== undefined) return value.substring(3, 6);
                      //   }
                      //   else
                      //   {
                      //   if (value !== undefined) return value.substring(3,11);
                      //   }
                      // }
                      // },
                      offsetX: 0,
                    },
                    title: {
                      text: props.xTitle,
                      offsetX: 3,
                      offsetY: -7,
                      style: {
                        color: ThemeProperties.black,
                        fontSize: ThemeProperties.c_axis_title,
                        fontFamily: 'Roboto',
                        fontWeight: ThemeProperties.c_axis_title_weight,
                      },
                    },
                    tooltip: {
                      enabled: false,
                    },
                  },
                  yaxis: {
                    axisBorder: {
                      show: true,
                      color: ThemeProperties.c_axis_labels_color,
                      offsetX: -3,
                    },
                    labels: {
                      style: {
                        colors: [ThemeProperties.c_axis_labels_color],
                        fontSize: ThemeProperties.c_axis_labels,
                        fontFamily: 'Roboto',
                        fontWeight: ThemeProperties.c_axis_labels_weight,
                      },
                      formatter(value) {
                        return Math.round(value)
                      },
                      offsetX: 0,
                    },
                    title: {
                      text: props.yTitle,
                      offsetX: -2,
                      style: {
                        color: props.yTitleColor,
                        fontSize: ThemeProperties.c_axis_title,
                        fontFamily: 'Roboto',
                        fontWeight: ThemeProperties.c_axis_title_weight,
                      },
                    },
                    tooltip: {
                      enabled: false,
                    },
                  },
                  legend: {
                    width: '200px',
                  },
                }}
                series={[]}
                type="line"
                height="100%"
                width="100%"
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default ApexBasicMultiLine
