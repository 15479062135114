import React, { useState,useEffect } from "react";
import Confirm from "../../Helper/Loaders/Confirm";
import Loader from "../../Helper/Loaders/Loader";
import { OutlinedButton } from "../../Components/CustomButton";
import {  useDispatch } from "react-redux";

import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  IconButton,
  Typography,
  TextField,
  Box
} from "@mui/material";
import { sendPostSendFeedback } from "../../Api/Api";
import { SetSessionExpired } from "../../Actions";

function SubmitFeedback(props) {
  const dispatch = useDispatch();

  const val = "";
  const [description, setDescription] = useState("");
  const [helperTextDescription, setHelperTextDescription] = useState(false);
  const [loader, setLoader] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  useEffect(()=>{
    const timer = setTimeout(() => {
      confirmation && props.handleCloseSendFeedbackDialog();
    }, 5000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
},[confirmation])

  return (
    <div>
      <Dialog
        onClose={props.handleCloseSendFeedbackDialog}
        aria-labelledby="customized-dialog-title"
        open={props.openSendFeedbackDialog}
        anchorEl={props.anchorElSendFeedbackDialog}
        container={props.anchorElSendFeedbackDialog.parentNode}
      >
        <div style={{ padding: "16px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 0px",
            }}
          >
            <Typography variant="paperHeader">{!confirmation && <>Submit Your Feedback</>}</Typography>
            <IconButton
              onClick={props.handleCloseSendFeedbackDialog}
              fontSize="small"
            >
              <CloseIcon style={{ fontSize: "18px" }} />
            </IconButton>
          </div>

          <div>
            <Typography style={{ width: "500px" }}></Typography>
          </div>

          {!loader && !confirmation ? (
            <div id="main_body">
              <div
                style={{
                  padding: "8px 0px",
                }}
              >
                <div
                  style={{
                    display: "inline",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {helperTextDescription ? (
                    <TextField
                      error={true}
                      helperText="Please add your feedback"
                      id="outlined-multiline-static"
                      multiline
                      onChange={(event) => {
                        setDescription(event.target.value);
                        setHelperTextDescription(false);
                      }}
                      fullWidth
                      rows={8}
                      inputProps={{ style: { fontSize: 13 } }}
                      placeholder="Please share your valuable feedback with us"
                      defaultValue={val}
                    />
                  ) : (
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      onChange={(event) => {
                        setDescription(event.target.value);
                      }}
                      fullWidth
                      rows={8}
                      inputProps={{ style: { fontSize: 13 } }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      placeholder="Please share your valuable feedback with us"
                      defaultValue={val}
                    />
                  )}
                </div>
              </div>
              <br></br>
              <Box sx={{display:"flex",justifyContent:"center"}}>

              <OutlinedButton
                variant="contained"
                onClick={() => {
                  if (description.length === 0) {
                    setHelperTextDescription(true);
                  } else {
                    setLoader(true);
                    sendPostSendFeedback(description).then((res)=>{
                      if(res.responseStatus.code === 200)
                      {
                        setConfirmation(true);
                        setLoader(false);
                      }
                      else
                      {
                        if(res.responseStatus.code === -2)
                        dispatch(SetSessionExpired(true))
                        
                      }
                    })
                    // Events("Collective Mail Sent Dashboard")
                  }
                }}
              >
                <Typography variant="textSubtitleWhite">Submit</Typography>
              </OutlinedButton>
              </Box>
            </div>
          ) : (
            <div style={{ height: "227px",width: "500px" }}>
              {loader && <Loader />}
              {confirmation && <Confirm text={"Feedback submitted successfully"}/>}
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default SubmitFeedback;
